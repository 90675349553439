import { ChildRule } from '../ChildRule';
import { ScreenIds } from '../constants';
import { RuleDetails } from '../RuleDetails';
import { Rules } from '../Rules';
import { RulesEditor } from '../RulesEditor';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'Rules',
    Component: Rules,
  },
  [ScreenIds.RULES_EDITOR]: {
    id: ScreenIds.RULES_EDITOR,
    title: 'Rules Editor',
    Component: RulesEditor,
  },
  [ScreenIds.RULE_DETAILS]: {
    id: ScreenIds.RULE_DETAILS,
    title: 'Rules Details',
    Component: RuleDetails,
  },
  [ScreenIds.CHILD_RULE]: {
    id: ScreenIds.CHILD_RULE,
    title: 'Rule',
    Component: ChildRule,
  },
};
