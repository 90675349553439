import { CLOUDFLARE_VARIANTS_ENUM } from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { observer } from 'mobx-react-lite';

import {
  MESSAGE_EDITOR_EVENTS_ENUM,
  messageEditorStore,
} from './MessageEditor.store';
import { UserSuggestionItem } from './suggestions/UserSuggestionItem';

import { SuggestionsGlobalMention } from '../editor-area/suggestions/SuggestionsGlobalMention';
import { SuggestionsHeader } from '../editor-area/suggestions/SuggestionsHeader';
import { SuggestionsSeparator } from '../editor-area/suggestions/SuggestionsSeparator';

import { InfinityScroller } from '@10x/foundation/src/components/scrollers';

import { useMinLoadingMinStart } from '@foundationPathAlias/components/loaders';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { UserModel } from '@mainApp/src/stores/User.model';

function _UserSuggestions() {
  const communityStore = useInjection(IOC_TOKENS.communityStore);

  const members = communityStore.members.data?.valuesArray;

  const isLoading = useMinLoadingMinStart(communityStore.members.loading);
  const isExisting = Boolean(members?.length);

  return (
    <div className="relative h-full">
      <SuggestionsHeader title="Members" />
      <InfinityScroller
        maxHeight={180}
        threshold={0.1}
        onScrollEndChange={(isEnd) => {
          if (isEnd) {
            communityStore.fetchMoreMembers();
          }
        }}
        onContentResize={(isScrollBarAppeared) => {
          // should do something only if it's the initial render without scrollbar
          if (isScrollBarAppeared) return;
          communityStore.fetchMoreMembers();
        }}
      >
        <div className="relative min-h-[30px]">
          {isExisting && members ? (
            <ul>
              {members.map((userModel: UserModel) => {
                const { id, username, displayName, numericId } =
                  userModel.serverData;
                return (
                  <UserSuggestionItem
                    key={id}
                    avatarSrc={userModel.getAvatar(
                      CLOUDFLARE_VARIANTS_ENUM.PUBLIC
                    )}
                    displayName={displayName as string}
                    username={username ?? ''}
                    numericId={numericId as string}
                    onClick={() => {
                      messageEditorStore.hideDropup();
                      messageEditorStore.selectOptionAndCleanUp({
                        id: userModel.serverData.id,
                        // should be display name probably?
                        label: displayName,
                      });
                    }}
                  />
                );
              })}
            </ul>
          ) : (
            !isLoading && 'No result'
          )}

          {isLoading && (
            <div className="absolute bottom-[6px] flex w-full justify-center ">
              <SmallLoader />
            </div>
          )}
        </div>
      </InfinityScroller>
      <div>
        <SuggestionsSeparator />
        <SuggestionsGlobalMention
          tag="@admin"
          description="Notify users with this role who have permission to view this channel"
          onClick={() => {
            messageEditorStore.hideDropup();
            messageEditorStore.notify(
              MESSAGE_EDITOR_EVENTS_ENUM.SELECT_MENTION_ADMIN,
              {
                label: '@admin',
              }
            );
          }}
        />
      </div>
    </div>
  );
}

export const UserSuggestions = observer(_UserSuggestions);
