type Props = {
  className?: string;
};
export function GoogleLogoSVG(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fill="#4285F4"
        fillRule="evenodd"
        d="M23.52 12.273c0-.851-.076-1.67-.218-2.455H12v4.642h6.458a5.52 5.52 0 01-2.394 3.622v3.01h3.878c2.269-2.088 3.578-5.165 3.578-8.82z"
        clipRule="evenodd"
      />
      <path
        fill="#34A853"
        fillRule="evenodd"
        d="M12 24c3.24 0 5.956-1.075 7.942-2.907l-3.879-3.011c-1.074.72-2.449 1.145-4.063 1.145-3.126 0-5.771-2.11-6.715-4.947H1.276v3.11A11.996 11.996 0 0012 24z"
        clipRule="evenodd"
      />
      <path
        fill="#FBBC05"
        fillRule="evenodd"
        d="M5.285 14.28A7.213 7.213 0 014.91 12c0-.79.136-1.56.376-2.28V6.611H1.276A11.995 11.995 0 000 12.001c0 1.935.464 3.768 1.276 5.388l4.01-3.109z"
        clipRule="evenodd"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M12 4.773c1.762 0 3.343.605 4.587 1.794l3.442-3.442C17.95 1.19 15.234 0 11.999 0 7.31 0 3.252 2.69 1.277 6.61l4.01 3.11C6.228 6.884 8.873 4.773 12 4.773z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default GoogleLogoSVG;
