import { classNames } from '@foundationPathAlias/utilities';
import { useSidebarModalContext } from './data//SidebarModalContext';

type Props = {
  cn?: string;
  /** second div after the root. Wraps stack and the actions panel */
  wrapperCn?: string;
  stackWrapperCn?: string;
  isMobile: boolean;
  desktopSidebarMenu: JSX.Element;
  actionsPanel: JSX.Element;
};

export function SidebarModalAnimatedStack(props: Props) {
  const {
    isMobile,
    desktopSidebarMenu,
    actionsPanel,
    cn,
    wrapperCn,
    stackWrapperCn,
  } = props;
  const sidebarModalCtx = useSidebarModalContext();

  const animatedStack = sidebarModalCtx.animatedStack;

  const stackState = animatedStack.state;

  const stackVisibleItem = stackState.visibleItem;
  const stackPrevItem = stackState.prevItem;
  const stackNextItem = stackState.nextItem;

  return (
    <div className={classNames('flex flex-1', cn)}>
      {!isMobile && desktopSidebarMenu}
      <div
        className={classNames(
          'flex flex-1 flex-col  pb-[24px] pt-[20px]',
          wrapperCn
        )}
      >
        <div
          className={classNames('relative flex w-full flex-1', stackWrapperCn)}
        >
          {stackPrevItem.Component && (
            <stackPrevItem.Component key={stackPrevItem.id} />
          )}
          {stackVisibleItem.Component && (
            <stackVisibleItem.Component
              key={stackVisibleItem.id}
              startLeaving={stackState.startLeaving}
              onLeaveEnd={animatedStack.onLeavingAnimationEnd}
            />
          )}

          {stackNextItem.Component && (
            <stackNextItem.Component
              isNext={true}
              startEntering={stackState.startEntering}
              key={stackNextItem.id}
              onEnterEnd={animatedStack.onEnterAnimationEnd}
            />
          )}
        </div>
        {actionsPanel}
      </div>
    </div>
  );
}
