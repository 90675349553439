import { Container } from 'inversify';
import React from 'react';

export const InversifyContext = React.createContext<{
  container: Container | null;
}>({
  container: null,
});

type Props = {
  container: Container;
  children: React.ReactNode;
};

export const IOCProvider: React.FC<Props> = (props) => {
  return (
    <InversifyContext.Provider value={{ container: props.container }}>
      {props.children}
    </InversifyContext.Provider>
  );
};
