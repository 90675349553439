import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { format } from 'date-fns';
import { useMemo } from 'react';


export function useGetSubscriptionExpirationDate() {
    const userStore = useInjection(
        IOC_TOKENS.userStore,
    );
    const expirationDate = useMemo(() => {
        const expiresAt = userStore.proSubscription?.expiresAt;

        if (!expiresAt) return null;
        const date = new Date(expiresAt);

        return format(date, 'MMM dd, yyyy');
    }, [userStore.proSubscription?.expiresAt]);

    return expirationDate;

}