import {
  Channel_Member_Action,
  Channel_Member_Type,
  Channel_Sort_Types,
  Channel_Types,
  ChannelAddMemberInput,
  Role_List_Types,
} from '@10x/foundation/types';
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type {
  IChannelStore,
  // IChannelStore,
  ICommunityStore,
  ISystemStore,
} from '@mainApp/src/stores';

import { CommunityRoleModel } from '@mainApp/src/stores/CommunityRole.model';

import { CommonApiDataShapeType } from '@mainApp/src/stores/ApiBase';
import { MemberData } from '@mainApp/src/stores/CreateChannel.types';

import { debouncePromisify } from '@foundationPathAlias/utilities';
import { ICommunityRepository } from '@mainApp/src/repositories';
import { ApiBase } from '@mainApp/src/stores/ApiBase';

const DEBOUCE_TIMEOUT = 500;
enableStaticRendering(typeof window === 'undefined');

export class PermissionsStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements ISidebarModalChildScreen<ScreenIdsValuesType>
{
  systemStore: ISystemStore = iocContainer.get(IOC_TOKENS.systemStore);
  communityStore: ICommunityStore = iocContainer.get(IOC_TOKENS.communityStore);
  communityRepository: ICommunityRepository = iocContainer.get(
    IOC_TOKENS.communityRepository
  );

  private showActionReactionDisposer: IReactionDisposer;

  channelModel: ChannelModel | null = null;
  private channelStore: IChannelStore = iocContainer.get(
    IOC_TOKENS.channelStore
  );
  private apiBaseInstance: ApiBase = new ApiBase(
    iocContainer.get(IOC_TOKENS.toastStore)
  );

  selectedRole: CommunityRoleModel | null = null;

  addedMembers: MemberData[] = [];
  addedRoles: MemberData[] = [];

  roles: CommonApiDataShapeType<CommunityRoleModel[] | null> =
    ApiBase.generateCommonApiDataShape();

  search: CommonApiDataShapeType<string | null> =
    ApiBase.generateCommonApiDataShape(false, '');

  isPrivate = false;

  debouncedLoadMoreMembers: () => Promise<void>;

  get actionsPanelData() {
    return {
      cancelAction: () => {
        runInAction(() => {
          this.showActionPanel = false;
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: async () => {
        const isError = await this.updateChannel();
        if (!isError) {
          this.showActionPanel = false;
          this.isDirty = false;
        }
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw this.apiBaseInstance.handleError(
        'Error',
        'There is no active community id'
      );
    }
    return id;
  }

  get rolesList(): MemberData[] {
    const roles = !this.roles?.data
      ? []
      : this.roles.data.map((role) => {
          const { roleId, totalMemberCount, name } = role;

          const description = this.systemStore.i18n?.t(
            'channel:modal.roleMembers',
            {
              roleName: totalMemberCount,
            }
          ) as string;

          return {
            id: roleId,
            description,
            name: name!,
            memberType: Channel_Member_Type.Role,
            totalMemberCount: totalMemberCount,
          };
        });

    return roles;
  }

  get filteredRoles(): MemberData[] {
    const roles = this.rolesList.filter((item) => {
      return (
        !this.addedRoles.some((role) => role.id === item.id) &&
        item.name.toLocaleLowerCase().includes(this.search.data as string)
      );
    });
    return roles;
  }

  get filteredMembers(): MemberData[] {
    return this.membersList.filter(
      (item) => !this.addedMembers.some((member) => member.id === item.id)
    );
  }

  get membersList(): MemberData[] {
    const members = (this.communityStore.members.data?.valuesArray ?? []).map(
      (member) => {
        const { id, online, displayName, avatarUrl, standardOrProUsername } =
          member;
        const description = `@${standardOrProUsername}`;

        return {
          id,
          description,
          avatarSrc: avatarUrl,
          name: displayName,
          isOnline: online,
          memberType: Channel_Member_Type.User,
        };
      }
    );

    return members;
  }

  get addedMembersAndRolesList(): MemberData[] {
    return [...this.addedMembers, ...this.addedRoles];
  }

  constructor() {
    super(ScreenIds, screensConfig);

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,
      filteredRoles: computed,
      rolesList: computed,
      membersList: computed,

      isDirty: observable,
      isLoading: observable,
      showActionPanel: observable,

      addedMembersAndRolesList: computed,

      channelModel: observable,
      isPrivate: observable,
      search: observable,
      addedRoles: observable,
      addedMembers: observable,

      activeScreenId: observable,
      roles: observable,

      setSearch: action,
      loadMoreMembers: action,
      loadCommunityRoles: action,

      addRoleOrMember: action,
      removeRoleOrMember: action,

      setIsPrivate: action,
      resetData: action,
      setChannelModel: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    this.debouncedLoadMoreMembers = debouncePromisify(
      this.loadMoreMembers,
      DEBOUCE_TIMEOUT
    );

    this.showActionReactionDisposer = reaction(
      () => {
        const isMemberListDirty = this.addedMembers.length > 0;
        const isRoleListDirty = this.addedRoles.length > 0;

        return isMemberListDirty || isRoleListDirty;
      },
      (isShowPanel) => {
        runInAction(() => {
          this.isDirty = isShowPanel;
          this.showActionPanel = isShowPanel;
        });
      }
    );
  }

  setChannelModel = (channelModel: ChannelModel | null) => {
    this.channelModel = channelModel;
  };

  setIsPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate;
  };

  loadMoreMembers = async () => {
    this.communityStore.getCommunityMembers({
      communityId: this.activeCommunityId,
      search: this.search.data as string,
    });
  };

  addRoleOrMember = (memberOrRoleId: string, type: Channel_Member_Type) => {
    if (type === Channel_Member_Type.User) {
      const member = this.membersList.find(
        (member) => member.id === memberOrRoleId
      );

      if (member) {
        this.addedMembers = [...this.addedMembers, member];
      }
    } else {
      const role = this.rolesList.find((role) => role.id === memberOrRoleId);

      if (role) {
        this.addedRoles = [...this.addedRoles, role];
      }
    }
  };

  setSearch = async (val: string) => {
    this.search = {
      ...this.search,
      data: val,
      loading: true,
    };

    await this.debouncedLoadMoreMembers();
    this.search = {
      ...this.search,
      loading: false,
    };
  };

  onViewRoleMembers = async (roleId: string) => {
    const foundRole = (this.roles.data ?? []).find(
      (role) => role.roleId === roleId
    );

    if (!foundRole) return;

    this.selectedRole = foundRole;

    await this.selectedRole.getRoleMembers({});

    this.setNextScreenId(ScreenIds.MEMBERS_VIEW);
  };

  removeRoleOrMember = (memberOrRoleId: string, type: Channel_Member_Type) => {
    if (type === Channel_Member_Type.User) {
      this.addedMembers = this.addedMembers.filter(
        (member) => member.id !== memberOrRoleId
      );
    } else {
      this.addedRoles = this.addedRoles.filter(
        (role) => role.id !== memberOrRoleId
      );
    }
  };

  setActiveScreenId = (id: string) => {
    if (this.activeScreenId === id) return;
    this.activeScreenId = id;
    //   'temporary. its when user added roles, goes to the view member (need to hide the pagen) and the returns back - need to render it again. I believe it can be done by even listeners or override setActive screen id'
    runInAction(() => {
      if (id === ScreenIds.MEMBERS_VIEW) {
        this.isDirty = false;
        this.showActionPanel = false;
      } else {
        // tmeporary
        if (this.addedMembers.length || this.addedRoles.length) {
          this.isDirty = true;
          this.showActionPanel = true;
        }
      }
    });
  };

  loadCommunityRoles = async (
    communityId: string,
    listType: Role_List_Types = Role_List_Types.All
  ) => {
    this.roles = {
      ...this.roles,
      loading: true,
    };

    const { data = [], error } =
      await this.communityRepository.getCommunityRoles(communityId, {
        listType,
      });
    if (error) {
      this.apiBaseInstance.handleError('Community Roles', error.message);
      return;
    }

    this.roles = {
      ...this.roles,
      data: !data
        ? null
        : data.map(
            (role) =>
              new CommunityRoleModel({
                toastStore: this.apiBaseInstance.toastStore,
                communityRepository: this.communityRepository,
                communityRolePayload: {
                  ...role,
                  communityId: this.activeCommunityId,
                },
              })
          ),
      loading: false,
      error: error,
    };
  };

  updateChannel = async () => {
    const activeChannelServerData = this.channelModel?.serverData;
    // const activeChannelServerData = this.channelStore.activeChannel?.data?.serverData;
    const activeChannelId = activeChannelServerData?.id;
    if (!activeChannelId) {
      throw new Error('activeChannelId is not defined');
    }

    this.isLoading = true;

    const communityId = activeChannelServerData.communityId;
    const channelGroupId = activeChannelServerData.parentId;
    const id = activeChannelServerData.id;

    const members: ChannelAddMemberInput[] | undefined = this.isPrivate
      ? this.addedMembersAndRolesList.map((roleAndMember) => {
          return {
            action: Channel_Member_Action.Add,
            id: roleAndMember.id,
            type: roleAndMember.memberType,
          };
        })
      : undefined;

    const { error } = await this.channelStore.updateChannel(communityId, id, {
      channelGroupId,
      members: members,
      private: false,
      option: {
        sort: Channel_Sort_Types.New,
      },
      channelType: Channel_Types.Messages,
    });
    this.isLoading = false;

    return Boolean(error);
  };

  dispose = () => {
    this.showActionReactionDisposer();
  };

  //   reset = () => {
  //     this.isPrivate = false;
  //     this.selectedRole = null;
  //     this.search = '';
  //     this.addedMembers = [];
  //     this.addedRoles = [];
  //   };
}

export interface IPermissionsStore extends PermissionsStore {
  isPrivate: boolean;
  setIsPrivate: (isPrivate: boolean) => void;
}
