import { useTranslation } from 'react-i18next';

export function CommunityPreviewHeader({ communityName }: any) {
  const { t } = useTranslation(['common']);
  return (
    <div className="flex justify-between ">
      <h5 className="themed-text text-body18SB">
        {communityName || t('yourCommunity')}
      </h5>
      <button className="flex items-center space-x-[3px]">
        <span className="block h-[3px] w-[3px] rounded-full bg-text-primary dark:bg-text-primary-dark" />
        <span className="block h-[3px] w-[3px] rounded-full bg-text-primary dark:bg-text-primary-dark" />
        <span className="block h-[3px] w-[3px] rounded-full bg-text-primary dark:bg-text-primary-dark" />
      </button>
    </div>
  );
}
