import { classNames } from '@foundationPathAlias/utilities';
import { ButtonBase, ButtonBasePropsType } from '../ButtonBase';

export type IconButtonPropsType = {
  contentLeft?: React.ReactNode;
  contentRight?: React.ReactNode;
  /** classnames for the p element that wraps images  */
  iconWrapperElLeftCn?: string;
  iconWrapperElRightCn?: string;
  contentWrapperElCn?: string;
} & ButtonBasePropsType;

export function IconButton(props: IconButtonPropsType) {
  const {
    cn,
    children,
    contentLeft,
    iconWrapperElLeftCn,
    iconWrapperElRightCn,
    contentWrapperElCn,
    contentRight,
    ...otherProps
  } = props;
  return (
    <ButtonBase
      cn={classNames('text-body16R py-[10px] px-[16px] border-[1px]', cn)}
      {...otherProps}
    >
      <div className={classNames('flex items-center', contentWrapperElCn)}>
        {Boolean(contentLeft) && (
          <p
            className={classNames(
              'mr-[16px] flex w-[20px] items-center',
              iconWrapperElLeftCn
            )}
          >
            {contentLeft}
          </p>
        )}
        {children}
        {Boolean(contentRight) && (
          <p
            className={classNames(
              'ml-[16px] flex w-[20px] items-center',
              iconWrapperElRightCn
            )}
          >
            {contentRight}
          </p>
        )}
      </div>
    </ButtonBase>
  );
}
