type Props = {
  badge: JSX.Element;
  description: JSX.Element | string;
  cost: string;
};
export function SubscriptionInfo(props: Props) {
  const { badge, description, cost } = props;
  return (
    <div>
      <div className="flex w-full">
        <div className="flex flex-1 flex-col space-y-[4px]">
          <h6 className={'themed-text text-sm14SB'}>Pro subscription</h6>
          <p className="themed-text-secondary text-sm13D">{cost}</p>
          <p className="themed-text-secondary text-sm13D">{description}</p>
        </div>

        <div>{badge}</div>
      </div>
      <hr className="mt-[16px] h-[1px] w-full bg-element-subtle dark:bg-element-subtle-dark" />
    </div>
  );
}
