


import { MenuItemsEnum } from "./types";

import { SidebarMenu } from "@mainApp/src/modules/account-settings/sidebar-menu/SidebarMenu";

export const menuItemsComponents = {
    [MenuItemsEnum.INITIAL]: {
        id: MenuItemsEnum.INITIAL,
        title: 'settings',
        Component: SidebarMenu
    },

}