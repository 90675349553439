import { gql } from '@urql/core';

import {
  CommunityBlockedMembersFragment,
  CommunityFragment,
  CommunityMemberFragment,
  CommunityRuleFragment,
  PageInfoFragment,
} from './fragments';

export const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($data: CreateCommunityInput!) {
    createCommunity(data: $data) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GET_COMMUNITY_RULES = gql`
  query CommunityRules($communityId: String!, $option: ContentRuleListOption) {
    communityRules(communityId: $communityId, option: $option) {
      ...CommunityRuleFragment
    }
  }
  ${CommunityRuleFragment}
`;

export const CREATE_COMMUNITY_RULE = gql`
  mutation CreateCommunityRule(
    $communityId: String!
    $data: ContentRuleInput!
  ) {
    createCommunityRule(communityId: $communityId, data: $data) {
      ...CommunityRuleFragment
    }
  }
  ${CommunityRuleFragment}
`;

export const UPDATE_COMMUNITY_RULE = gql`
  mutation UpdateCommunityRule(
    $communityId: String!
    $id: String!
    $data: ContentRuleInput!
  ) {
    updateCommunityRule(communityId: $communityId, id: $id, data: $data) {
      ...CommunityRuleFragment
    }
  }
  ${CommunityRuleFragment}
`;

export const UPDATE_COMMUNITY_RULES_SORT = gql`
  mutation UpdateCommunityRulesSort(
    $communityId: String!
    $rulesId: [String!]!
  ) {
    updateCommunityRulesSort(communityId: $communityId, rulesId: $rulesId) {
      id
      title
      rawJSON
      communityId
    }
  }
`;

export const DELETE_COMMUNITY_RULE = gql`
  mutation DeleteCommunityRule($communityId: String!, $id: String!) {
    deleteCommunityRule(communityId: $communityId, id: $id) {
      ...CommunityRuleFragment
    }
  }
  ${CommunityRuleFragment}
`;

export const UPDATE_COMMUNITY_PROFILE = gql`
  mutation UpdateCommunityProfile(
    $communityId: String!
    $data: UpdateCommunityProfileDataInput!
  ) {
    updateCommunityProfile(communityId: $communityId, data: $data) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GET_COMMUNITY_BY_SLUG_NAME = gql`
  query CommunityBySlugName($slugName: String!) {
    communityBySlugName(slugName: $slugName) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GET_COMMUNITY = gql`
  query Community($communityId: String!) {
    community(communityId: $communityId) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GET_COMMUNITIES = gql`
  query Communities(
    $before: String
    $after: String
    $first: Int
    $sort: String
    $search: String
    $excludeFeatured: Boolean
  ) {
    communities(
      before: $before
      after: $after
      first: $first
      sort: $sort
      search: $search
      excludeFeatured: $excludeFeatured
    ) {
      edges {
        cursor
        node {
          ...CommunityFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        count
        __typename
      }
      __typename
    }
  }
  ${CommunityFragment}
`;

export const GET_FEATURED_COMMUNITIES = gql`
  query GetFeaturedCommunities(
    $limit: Int
    $sortType: FEATURED_COMMUNITIES_SETTING_SORT_TYPE
  ) {
    featuredCommunities(limit: $limit, sortType: $sortType) {
      id
      communityId
      community {
        ...CommunityFragment
      }
    }
  }
  ${CommunityFragment}
`;

export const GET_USER_COMMUNITIES = gql`
  query UserCommunities(
    $before: String
    $first: Int
    $last: Int
    $after: String
    $sort: String
    $search: String
    $excludeFeatured: Boolean
    $ownerId: String
  ) {
    userCommunities(
      before: $before
      first: $first
      last: $last
      after: $after
      sort: $sort
      search: $search
      excludeFeatured: $excludeFeatured
      ownerId: $ownerId
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...CommunityFragment
        }
        cursor
      }
    }
  }
  ${CommunityFragment}
  ${PageInfoFragment}
`;

export const GET_COMMUNITY_ONLINE_MEMBERS_COUNT = gql`
  query GetCommunityOnlineMemberCounts($communityId: String!) {
    communityOnlineMemberCounts(communityId: $communityId)
  }
`;

export const GET_COMMUNITY_MEMBERS_COUNT = gql`
  query GetCommunityMemberCounts($communityId: String!) {
    communityMemberCounts(communityId: $communityId)
  }
`;

export const JOIN_COMMUNITY = gql`
  mutation JoinCommunity($communityId: String!) {
    joinCommunity(communityId: $communityId) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;
export const LEAVE_COMMUNITY = gql`
  mutation LeaveCommunity($communityId: String!) {
    leaveCommunity(communityId: $communityId) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GET_COMMUNITY_ONLINE_MEMBERS = gql`
  query GetCommunityOnlineMembers(
    $communityId: String!
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
  ) {
    communityOnlineMembers(
      communityId: $communityId
      before: $before
      first: $first
      after: $after
      sort: $sort
      search: $search
    ) {
      edges {
        node {
          id
          email
          username
        }
      }
      totalCount
    }
  }
`;

export const GET_COMMUNITY_MEMBERS = gql`
  query CommunityMembers(
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
    $communityId: String!
  ) {
    communityMembers(
      before: $before
      first: $first
      after: $after
      sort: $sort
      search: $search
      communityId: $communityId
    ) {
      edges {
        cursor
        node {
          ...CommunityMemberFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
  ${CommunityMemberFragment}
  ${PageInfoFragment}
`;

export const GET_COMMUNITY_BLOCKED_MEMBERS = gql`
  query CommunityBlockedMembers(
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
    $communityId: String!
  ) {
    communityBlockedMembers(
      before: $before
      first: $first
      after: $after
      sort: $sort
      search: $search
      communityId: $communityId
    ) {
      edges {
        cursor
        node {
          ...CommunityBlockedMembersFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
  ${CommunityBlockedMembersFragment}
  ${PageInfoFragment}
`;

export const GET_COMMUNITY_ROLES = gql`
  query CommunityRoles(
    $communityId: String!
    $option: CommunityRoleListOption!
  ) {
    communityRoles(communityId: $communityId, option: $option) {
      id
      name
      description
      level
      authorId
      totalMemberCount
    }
    __typename
  }
`;

export const GET_COMMUNITY_ROLE_MEMBERS = gql`
  query CommunityRoleMembers(
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
    $communityId: String!
    $roleId: String!
  ) {
    communityRoleMembers(
      before: $before
      first: $first
      after: $after
      sort: $sort
      search: $search
      communityId: $communityId
      roleId: $roleId
    ) {
      edges {
        cursor
        node {
          id
          roleId
          user {
            id
            firstName
            email
            displayName
            username
            online
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
    __typename
  }
  ${PageInfoFragment}
`;

export const ADD_COMMUNITY_ROLES_TO_MEMBER = gql`
  mutation AddCommunityRolesToMember(
    $communityId: String!
    $roleIds: [String!]!
    $userId: String!
  ) {
    addCommunityRolesToMember(
      communityId: $communityId
      roleIds: $roleIds
      userId: $userId
    ) {
      user {
        id
      }
    }
  }
`;

export const REMOVE_COMMUNITY_ROLES_TO_MEMBER = gql`
  mutation RemoveCommunityRolesToMember(
    $communityId: String!
    $roleIds: [String!]!
    $userId: String!
  ) {
    removeCommunityRolesToMember(
      communityId: $communityId
      roleIds: $roleIds
      userId: $userId
    ) {
      user {
        id
      }
    }
  }
`;
