import { RichViewer, withAnimatedStack } from '@foundationPathAlias/main';
import { useScreenRules } from '../ScreenRules.context';

const _RulesDetailsScreen = () => {
  const { currentRuleDetails } = useScreenRules();

  return (
    <div className="overflow-auto px-[16px] py-[24px] md:px-[24px]">
      <RichViewer
        predefinedEditorStateJsonString={currentRuleDetails?.rawJson ?? ''}
      />
    </div>
  );
};

export const RulesDetailsScreen = withAnimatedStack(_RulesDetailsScreen);
