import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { BottomSheetConfig } from '../data';
const BottomSheetFnd = dynamic(
  () =>
    import('@10x/foundation/src/components/bottomsheet/BottomSheet').then(
      (mod) => mod.BottomSheet
    ),
  {
    ssr: false,
  }
);

import type { BottomSheetProps } from '@10x/foundation/src/components/bottomsheet/BottomSheet';
import { classNames } from '@foundationPathAlias/utilities';

type StateType = {
  open: null | (() => void);
  close: null | (() => void);
  isAnimationrunning: false;
};

type Props = {
  isMobile: boolean;
  bottomSheetConfig: BottomSheetConfig | void;
  onClose: () => void;
  bottomsheetCn?: string;
  bottomSheetProps?: Partial<BottomSheetProps>;
};

export const BottomSheet = (props: Props) => {
  const {
    isMobile,
    onClose,
    bottomSheetConfig,
    bottomsheetCn,
    bottomSheetProps = {},
  } = props;

  const [bottomSheetActions, setBottomsheetActions] = useState<StateType>({
    open: null,
    close: null,
    isAnimationrunning: false,
  });

  useEffect(() => {
    if (bottomSheetConfig?.show) {
      bottomSheetActions?.open?.();
    } else {
      bottomSheetActions?.close?.();
    }
  }, [bottomSheetConfig?.show]);

  useEffect(() => {
    if (!isMobile && bottomSheetConfig?.show) {
      onClose();
    }
  }, [isMobile, bottomSheetConfig?.show]);

  if (!bottomSheetConfig) return null;

  return (
    <BottomSheetFnd
      className={classNames('themed-layout flex', bottomsheetCn)}
      getActions={(open, close) => {
        setBottomsheetActions({
          open,
          close,
          isAnimationrunning: false,
        });
      }}
      onClose={() => {
        onClose?.();
        bottomSheetConfig?.onClose?.();
      }}
      {...bottomSheetProps}
    >
      {bottomSheetConfig.content}
    </BottomSheetFnd>
  );
};
