import { inject, injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import type { ChangesAlertProps as ChangesAlertPropsType } from '@foundationPathAlias/components';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import type { IDimensionsStore, IToastStore } from '@mainApp/src/stores/';
import { ApiBase } from '@mainApp/src/stores/ApiBase';
enableStaticRendering(typeof window === 'undefined');

import {
  AnimatedStackStore,
  IAnimatedStackStore,
} from '@mainApp/src/components/common';
import { AboutStore } from '../about/data';
import { AccountStore } from '../account/data/Account.store';
import { SettingIds } from '../common/types';
import { MenuItemsEnum } from '../data/types';
import { DisplayStore } from '../display/data/Display.store';
import { LanguageStore } from '../language/data/Language.store';
import { SubscriptionStore } from '../subscription/data';
import { menuItemsComponents } from './menuComponents';

import { IAccountStore } from '../account/data/Account.store.types';
import {
  DiscardActions,
  DiscardActionsEnum,
  IAccountSettingsStore,
  SettingsStores,
} from './AccountSettings.store.types';

const settingsStoreClassesRegistry = {
  account: AccountStore,
  display: DisplayStore,
  subscription: SubscriptionStore,
  about: AboutStore,
  language: LanguageStore,
};

@injectable()
export class AccountSettingsStore
  extends ApiBase
  implements IAccountSettingsStore
{
  changesAlertConfig: Partial<ChangesAlertPropsType> = {
    show: false,
    title: '',
    description: '',
  };

  discardActions = {
    [DiscardActionsEnum.BACK]: null,
    [DiscardActionsEnum.GO_TO_MENU]: {
      savedMenuItemId: null,
    },
  };
  // used to save a trigger action before the changes alert showng and clicking on the discard. It should close the alert and move back/or to another menu etc.
  activeDiscardAction: DiscardActionsEnum | null = null;

  dimensionStore: IDimensionsStore;
  // should be active on Mobile
  activeSettingId: keyof SettingsStores | null = null;

  stackStore: IAnimatedStackStore;
  settingStores: SettingsStores = {
    account: null,
    display: null,
    subscription: null,
    about: null,
    language: null,
  };

  show = false;
  showChangesAlert = false;

  showSuccessProSubscriptionAlert = false;
  processingSubscription = false;

  isFirstRenderCompleted = false;
  wasLayoutMobile = false;
  bottomSheetConfig = {
    content: null,
    show: false,
    onClose: null,
  };

  get activeSettingStore() {
    return this.settingStores[this.activeSettingId as keyof SettingsStores];
  }

  get isMobile() {
    return this.dimensionStore.isMobile;
  }

  get isActiveSettingStoreDirty() {
    if (this.activeSettingId === 'account') {
      return (this.activeSettingStore as IAccountStore)?.isDirty;
    }
    return false;
  }

  get isInitialScreen() {
    const activeStore = this.activeSettingStore;

    let res = true;
    if (activeStore) {
      res = activeStore?.isInitialScreen;
    }
    return res;
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.dimensionsStore) dimensionStore: IDimensionsStore
  ) {
    super(toastStore);
    this.dimensionStore = dimensionStore;

    makeObservable(this, {
      activeSettingId: observable,
      show: observable,
      bottomSheetConfig: observable,
      changesAlertConfig: observable,
      isMobile: computed,

      processingSubscription: observable,
      showChangesAlert: observable,
      showSuccessProSubscriptionAlert: observable,
      setActiveSettingId: action,
      setBottomSheetConfig: action,
      setChangesAlertConfig: action,
      completeSubscription: action,
      setShowSuccessProSubscriptionAlert: action,
      setShow: action,
    });

    // TODO: maybe init stackStore at the top of the whole application?
    // should check how it works with other modules

    // init it at the top level of the account settings
    this.stackStore = new AnimatedStackStore();
  }

  setChangesAlertConfig = (config: Partial<ChangesAlertPropsType>) => {
    this.changesAlertConfig = {
      ...config,
    };
  };

  setActiveSettingId = (id: keyof SettingsStores) => {
    // the active store has some unsaved changes and need just to show the changes alert
    if (this.isActiveSettingStoreDirty) {
      this.showChangesAlertAndSaveAction(DiscardActionsEnum.GO_TO_MENU, {
        savedMenuItemId: id,
      });
      return;
    }

    // clearing all the possible reactions in the old store if exist. Only in the account store;
    // @ts-ignore
    this.activeSettingStore?.dispose?.();

    this.activeSettingId = id;
    this.isFirstRenderCompleted = true;
    const StoreClass = settingsStoreClassesRegistry[id];
    if (this.activeSettingId === (MenuItemsEnum.INITIAL as SettingIds)) {
      {
        this.renderInitialScreen();
        return;
      }
    }

    if (!StoreClass) {
      // shouldn't happen. Just for devs
      throw new Error(`There is no such store class for this ID: ${id}`);
    }

    const nextStore = new StoreClass(this.stackStore);

    // @ts-ignore
    this.settingStores[id] = nextStore;

    /** if mobile the stack should be controlled from this store
     * because settings menu is becoming stack entry point and all other screens
     * slides in over it. On desktop settings menu renders at the side and
     * the entry point will be every menu item initial screen
     * */
    if (this.isMobile) {
      const stackStore = this.stackStore;
      // shouldn't do anything if the animation is running
      if (stackStore.isAnimationRunning) {
        return;
      }
      const nextItem = nextStore.activeSetting;
      stackStore.next(nextItem.id, nextItem.Component);
    } else {
      nextStore.reset();
    }
  };

  setShowSuccessProSubscriptionAlert = (show: boolean) => {
    this.showSuccessProSubscriptionAlert = show;
  };

  setBottomSheetConfig = (data = {}) => {
    this.bottomSheetConfig = {
      ...this.bottomSheetConfig,
      ...data,
    };
  };

  completeSubscription = async (proUsername: string, failed = false) => {
    this.show = true;

    let accountStore = this.settingStores.account;
    // on mobile the first screen is AccountSettings instead of Account store so should handle this case and manually create it if the user had been redirected after the auth subscription
    if (!accountStore) {
      accountStore = this.settingStores.account =
        new settingsStoreClassesRegistry['account'](this.stackStore);
    }

    this.processingSubscription = true;
    this.setShowSuccessProSubscriptionAlert(true);

    await accountStore.completeSubscription(proUsername, failed);
    this.processingSubscription = false;
  };

  showChangesAlertAndSaveAction = (
    activeDiscardAction: DiscardActionsEnum,
    val: DiscardActions[DiscardActionsEnum] = null
  ) => {
    this.discardActions = {
      ...this.discardActions,
      [activeDiscardAction]: val,
    };
    this.activeDiscardAction = activeDiscardAction;

    const alertConfig = {
      show: true,
      title: 'discardChanges',
      description: 'discardChangesDescr',
      onFirstBtnClick: () => {
        this.setChangesAlertConfig({
          show: false,
        });
      },
      onSecondBtnClick: this.executeSavedDiscardAction,
      firstBtnText: 'keepEditing',
      secondBtnText: 'closeAndDiscard',
    };

    this.setChangesAlertConfig(alertConfig);
  };
  executeSavedDiscardAction = () => {
    // it's a string because the type is generic based on SettingsStores. Ts checks it and even provides suggestion
    if (this.activeSettingId === 'account') {
      (this.activeSettingStore as IAccountStore).resetData();
    }

    this.setChangesAlertConfig({
      show: false,
    });

    switch (this.activeDiscardAction) {
      case DiscardActionsEnum.BACK:
        (this.activeSettingStore || this).back();
        break;
      case DiscardActionsEnum.GO_TO_MENU:
        this.setActiveSettingId(
          this.discardActions[DiscardActionsEnum.GO_TO_MENU]
            .savedMenuItemId as any
        );
        break;
      default:
        break;
    }
    this.activeDiscardAction = null;
  };

  setShow = (val: boolean) => {
    this.show = val;
  };

  back = () => {
    const stackStore = this.stackStore;
    // shouldn't do anything if the animation is running
    if (stackStore.isAnimationRunning) {
      return;
    }

    let prevSettingId = stackStore.back();

    // I don't want to get any extra validation as it could only happen when the user switches from desktop to mobile and press the button back on the intial screen of a settings store to go to the settings menu (on desktop it hadn't be added to history so just do it manually). Once it will be moved to the settings menu the back button won't be visible at all so this action shouldn't happen anymore
    if (!prevSettingId) {
      const initialMobileSettingScreen =
        menuItemsComponents[MenuItemsEnum.INITIAL];
      stackStore.setPrevItem(
        initialMobileSettingScreen.id,
        initialMobileSettingScreen.Component
      );
      stackStore.back();
      prevSettingId = MenuItemsEnum.INITIAL;
    }

    this.activeSettingId = prevSettingId as keyof SettingsStores;
  };

  setInitialSetting = () => {
    if (this.isFirstRenderCompleted) {
      // if there is non-initial settings screen active no need to
      // re-render it when the window size is changing as the layout is not significantly different

      // also shouldn't render settings menu if there is a change from desktop layout to mobile as some menu initial screen is active on desktop and should it keep persistent on mobile as well
      if (
        !this.isInitialScreen ||
        (!this.wasLayoutMobile &&
          this.activeSettingId !==
            (MenuItemsEnum.INITIAL as keyof SettingsStores))
      ) {
        return;
      }
    }

    let activeSettingId;

    if (this.dimensionStore.isMobile) {
      // on mobile the first rendered is sidebar screen
      activeSettingId = MenuItemsEnum.INITIAL;
      this.wasLayoutMobile = true;
    } else {
      this.wasLayoutMobile = false;
      // on desktop the first initial should be the account but if there is a mobile mode and the user switches back to desktop and if there is opened non default initial account screen but display etc. should preserve it and render on the desktop as well
      activeSettingId =
        this.activeSettingId &&
        this.activeSettingId !== (MenuItemsEnum.INITIAL as keyof SettingsStores)
          ? this.activeSettingId
          : MenuItemsEnum.ACCOUNT;
    }

    this.setActiveSettingId(activeSettingId as any);
  };

  renderInitialScreen = () => {
    const initialMobileSettingScreen =
      menuItemsComponents[MenuItemsEnum.INITIAL];

    this.stackStore.reset();
    this.stackStore.setVisible(
      initialMobileSettingScreen.id,
      initialMobileSettingScreen.Component
    );
  };
}
