import { CheckboxCommon } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  checked: boolean;
  label: string;
  price: number;
  onChange: () => void;
  yearly?: boolean;
};

export function SubscriptionOption(props: Props) {
  const { checked, yearly, label, price, onChange } = props;
  return (
    <div
      className={classNames(
        'animation-classic grid cursor-pointer grid-cols-[18px,1fr,auto]  items-center rounded-[5px] px-[16px]  hover:bg-hovered-selected dark:hover:bg-hovered-selected-dark',
        checked && 'cursor-default bg-hovered-selected'
      )}
      onClick={onChange}
    >
      <div className="flex items-center">
        <CheckboxCommon checked={checked} onChange={onChange} />
      </div>
      <div className="themed-text flex justify-start pl-[12px]">
        {label}
        {yearly && (
          <span className="ml-[12px] inline-block rounded-[12px] bg-primary-100 px-[8px] py-[2px] text-sm14T text-text-primary-dark">
            -20%
          </span>
        )}
      </div>
      <div className="themed-text">{price}$</div>
    </div>
  );
}
