import { createPubSub } from '@foundationPathAlias/utilities';
import axios from 'axios';
import { Container } from 'inversify';

import IOC_TOKENS from './ioc-tokens';

import {
  AuthStore,
  ChannelStore,
  CommunityStore,
  CreateChannelStore,
  CreateCommunityStepperStore,
  DimensionsStore,
  InteractiveStore,
  MessageStore,
  PlatformStore,
  RulesStore,
  SystemStore,
  ToastStore,
  UserStore,
} from '@mainApp/src/stores';

import { PostAuthOnboardingStore } from '@mainApp/src/components/post-auth-onboarding/PostAuthOnboarding.store';

import {
  AttachmentsMediator,
  AttachmentsProgressBarViewStore,
  FileUploadService,
} from '@mainApp/src/stores/attachments';

import { AccountSettingsStore } from '@mainApp/src/modules/account-settings/data/AccountSettings.store';

import { urqlClient } from '@mainApp/src/config/urql.config';
import {
  AttachmentsRepository,
  AuthRepository,
  ChannelRepository,
  CommunityRepository,
  MessageRepository,
  OnboardingRepository,
  PlatformRepository,
  UserRepository,
} from '@mainApp/src/repositories';

import { eventBus } from '@mainApp/src/events';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';

import { DoubleLinkedRegistryQueue } from '@mainApp/src/stores/attachments/DoubleLinkedRegistryQueue';
import { OptimisticQueueService } from '@mainApp/src/stores/attachments/OptimisticQueue.service';
import { RetryService } from '@mainApp/src/stores/attachments/Retry.service';

import { ChannelSettingsStore } from '@mainApp/src/modules/channel/channel-settings/data';
import { CAPStore } from '@mainApp/src/modules/customer-admin-panel/data/CAP.store';
import { RedirectService, StorageService } from '@mainApp/src/services';

const iocContainer = new Container({
  defaultScope: 'Singleton',
  skipBaseClassChecks: true,
});

iocContainer.bind(IOC_TOKENS.graphqlClient).toConstantValue(urqlClient);
iocContainer
  .bind<any>(IOC_TOKENS.eventBusFactory)
  .toConstantValue(createPubSub);
iocContainer.bind<any>(IOC_TOKENS.eventBus).toConstantValue(eventBus);
iocContainer.bind(IOC_TOKENS.http).toConstantValue(axios);

iocContainer
  .bind(IOC_TOKENS.fileUploadService)
  .to(FileUploadService)
  .inTransientScope();

iocContainer.bind(IOC_TOKENS.attachmentsMediator).to(AttachmentsMediator);

iocContainer
  .bind(IOC_TOKENS.attachmentsProgressBarViewStore)
  .to(AttachmentsProgressBarViewStore);

// --- stores
iocContainer.bind(IOC_TOKENS.systemStore).to(SystemStore);
iocContainer.bind(IOC_TOKENS.dimensionsStore).to(DimensionsStore);
iocContainer.bind(IOC_TOKENS.toastStore).to(ToastStore);
iocContainer.bind(IOC_TOKENS.authStore).to(AuthStore);
iocContainer.bind(IOC_TOKENS.accountSettingsStore).to(AccountSettingsStore);

iocContainer
  .bind(IOC_TOKENS.createCommunityStepperStore)
  .to(CreateCommunityStepperStore);
iocContainer
  .bind(IOC_TOKENS.postAuthOnboardingStore)
  .to(PostAuthOnboardingStore);
iocContainer.bind(IOC_TOKENS.communityStore).to(CommunityStore);
iocContainer.bind(IOC_TOKENS.channelStore).to(ChannelStore);
iocContainer.bind(IOC_TOKENS.userStore).to(UserStore);
iocContainer.bind(IOC_TOKENS.interactiveStore).to(InteractiveStore);
iocContainer.bind(IOC_TOKENS.messageStore).to(MessageStore);
iocContainer.bind(IOC_TOKENS.capStore).to(CAPStore);
iocContainer.bind(IOC_TOKENS.platformStore).to(PlatformStore);
iocContainer.bind(IOC_TOKENS.rulesStore).to(RulesStore);
iocContainer.bind(IOC_TOKENS.createChannelStore).to(CreateChannelStore);
iocContainer.bind(IOC_TOKENS.channelSettingsStore).to(ChannelSettingsStore);

// --- repository
// TODO: maybe they shouldn't be singletones? eg. why do I need onboarding repo on Channel?

iocContainer.bind(IOC_TOKENS.authRepository).to(AuthRepository);
iocContainer.bind(IOC_TOKENS.communityRepository).to(CommunityRepository);
iocContainer.bind(IOC_TOKENS.onboardingRepository).to(OnboardingRepository);
iocContainer.bind(IOC_TOKENS.attachmentsRepository).to(AttachmentsRepository);
iocContainer.bind(IOC_TOKENS.channelRepository).to(ChannelRepository);
iocContainer.bind(IOC_TOKENS.userRepository).to(UserRepository);
iocContainer.bind(IOC_TOKENS.messageRepository).to(MessageRepository);
iocContainer.bind(IOC_TOKENS.platformRepository).to(PlatformRepository);

iocContainer.bind(IOC_TOKENS.MessageModel).toConstructor(MessageModel);
iocContainer.bind(IOC_TOKENS.AttachmentModel).toConstructor(AttachmentModel);

iocContainer
  .bind(IOC_TOKENS.DoubleLinkedRegistryQueue)
  .toConstructor(DoubleLinkedRegistryQueue);

iocContainer.bind(IOC_TOKENS.retryService).to(RetryService).inTransientScope();
iocContainer
  .bind(IOC_TOKENS.optimisticQueueService)
  .to(OptimisticQueueService)
  .inTransientScope();
iocContainer.bind(IOC_TOKENS.redirectService).to(RedirectService);
iocContainer.bind(IOC_TOKENS.storageService).to(StorageService);
export { iocContainer };
