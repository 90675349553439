import { useTranslation } from 'react-i18next';

import { TextInput, validators } from '@foundationPathAlias/components';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { AuthContentTitle, AuthContentTopSection } from './common';

import { useRef, useState } from 'react';

import { Code } from './code';

import { PrimaryButton } from '@foundationPathAlias/components';

type Props = {
  onCloseBtnClick: () => void;
  onBackClick: () => void;
  emailFormData: any;
  loginMode: boolean;
  setEmailForm: any;
  resetEmailForm: any;
  dedicatedPageMode: boolean;
};

export function EmailForm(props: Props) {
  const {
    onCloseBtnClick,
    onBackClick,
    setEmailForm,
    emailFormData,
    resetEmailForm,
    loginMode,
    dedicatedPageMode,
  } = props;

  const [error, setError] = useState(null);

  const { t } = useTranslation(['auth', 'common']);
  const emailValidatorRef = useRef(
    validators.getEmailValidator(
      t('common:errors.invalidEmailFormat') as string
    )
  );

  const authStore = useInjection(IOC_TOKENS.authStore);

  const close = () => {
    onCloseBtnClick();
    setTimeout(() => {
      resetEmailForm();
    }, 1000);
  };

  let title = t('signUp') + ' ' + t('withEmail');
  let subHeading = t('signUpCodeDescription');
  let action = t('backToSignUp');

  if (loginMode) {
    title = t('logIn') + ' ' + t('withEmail');
    subHeading = t('logInCodeDescription');
    action = t('backToLogIn');
  }

  // should show completed UI
  if (emailFormData.completed) {
    return (
      <Code
        loginMode={loginMode}
        dedicatedPageMode={dedicatedPageMode}
        email={emailFormData.email}
        onCloseBtnClick={close}
      />
    );
  }

  return (
    <>
      <AuthContentTopSection
        onCloseBtnClick={onCloseBtnClick}
        dedicatedPageMode={dedicatedPageMode}
      />
      <AuthContentTitle title={title} />
      <div className="mt-[8px] flex flex-1 flex-col px-[24px]">
        <p className="themed-text-secondary mb-[22px] text-center text-body16R">
          {subHeading}
        </p>

        <form
          className="mb-[24px] flex flex-1 flex-col justify-between"
          action="#"
          method="POST"
        >
          <div>
            <div className="mt-1 flex flex-1">
              <TextInput
                disabled={false}
                placeholder={t('yourEmail') as string}
                onChange={(val) => {
                  if (error) {
                    // reset the validation error if exists because of the typing start
                    setError(null);
                  }
                  setEmailForm({
                    ...emailFormData,
                    email: val,
                  });
                }}
                outerError={error}
                errorTextCn="text-left text-element-error dark:text-element-error-dark"
                rightSideContent={
                  <span className="h-[22px] w-[22px] bg-background-primary-dark"></span>
                }
              />
            </div>
          </div>

          <div className="mt-[32px]">
            <PrimaryButton
              type="submit"
              disabled={Boolean(error)}
              onClick={(e) => {
                e.preventDefault();

                const emailError = emailValidatorRef.current(
                  emailFormData.email
                );
                if (emailError) {
                  setError(emailError);
                } else {
                  const action = loginMode
                    ? authStore.signInViaEmail
                    : authStore.signUpViaEmail;
                  action(emailFormData.email);
                }
              }}
              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t('Continue')}
            </PrimaryButton>
          </div>
        </form>
        <div className="themed-text text-center text-body16R">
          <span
            className="text-action text-body16SB  text-primary-100"
            onClick={onBackClick}
          >
            {action}
          </span>
        </div>
      </div>
    </>
  );
}
