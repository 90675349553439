import { useRef } from 'react';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useHoverDirty } from 'react-use';

import {
  ArrowUpIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

type Props = {
  id: string;
  previewSrc: string;
  name: string;
  loading: boolean;
  failed?: boolean;
  onRemove: (id: string) => void;
  onReUpload?: (id: string) => void;
  isDarkMode: boolean;
};

// export function _AttachmentPreview(props: Props) {
export function AttachmentPreview(props: Props) {
  const {
    previewSrc,
    loading,
    failed,
    name,
    isDarkMode,
    id,
    onRemove,
    onReUpload,
  } = props;

  const buttonElRef = useRef<HTMLButtonElement | null>(null);
  const isHovering = useHoverDirty(buttonElRef);

  let btnIcon;

  if (loading && !isHovering) {
    btnIcon = (
      <SmallLoader
        width={12}
        height={12}
        cn="text-color-10 dark:text-10-dark"
        fill={isDarkMode ? '#000000' : '#FFFFFF'}
      />
    );
  } else if (failed) {
    btnIcon = <ArrowUpIcon className="h-[14px] w-[14px] text-red-500" />;
  } else {
    btnIcon = (
      <XMarkIcon className="h-[12px] w-[12px] text-text-primary-dark dark:text-text-primary" />
    );
  }

  return (
    <figure className="relative flex h-[62px] w-[62px] items-end justify-start rounded-[5px]">
      <button
        ref={buttonElRef}
        onClick={() => {
          if (failed) {
            onReUpload?.(id);
          } else {
            onRemove(id);
          }
        }}
        className="absolute -right-[3px] top-0 z-2 flex h-[22px] w-[22px] items-center justify-center rounded-full border-[2px] border-background-primary bg-background-primary-dark dark:border-background-primary-dark dark:bg-background-primary"
      >
        {btnIcon}
      </button>
      <img
        className="h-[55px] w-[55px] rounded-[5px] object-cover"
        src={previewSrc}
        alt={name}
      />

      {failed && (
        <div className="absolute z-1 h-[55px] w-[55px] overflow-hidden rounded-[5px]">
          <span className="absolute z-0 h-[55px] w-[55px] bg-black opacity-60" />
          <ExclamationTriangleIcon className="absolute left-[16px]  top-[16px] z-1 h-[24px] w-[24px] text-red-500" />
        </div>
      )}
    </figure>
  );
}

// export const AttachmentPreview = observer(_AttachmentPreview);
