
import { Subscription } from "../Subscription"

import { SubscriptionSettingsEnum } from "@mainApp/src/modules/account-settings/data/types"

export const subscriptionSettingsComponents = {
    [SubscriptionSettingsEnum.INITIAL]: {
        id: SubscriptionSettingsEnum.INITIAL,
        title: 'accountSettings.manageSubscription',
        Component: Subscription
    },

}