import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepsWrapper } from '../StepsWrapper';

import { StepNamesEnum } from '@mainApp/src/components/post-auth-onboarding/PostAuthOnboarding.store.types';
import { SubscriptionList } from './SubscriptionList';

export function _Subscription() {
  const { t } = useTranslation(['create-community', 'common']);
  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);
  const subscriptionStep =
    postOnboardingStore.steps[StepNamesEnum.SUBSCRIPTION];
  return (
    <StepsWrapper
      title={t('secureProUsername')}
      description={t('secureProUsernameDescr')}
      descriptionCn="min-h-0"
      rootCn="relative flex flex-col"
    >
      <>
        <SubscriptionList />
        {Boolean(subscriptionStep.error) && (
          <div className="flex h-[32px] w-full items-center  justify-center rounded-[10px] bg-element-low-contrast px-[12px] pb-[2px] text-sm14R text-text-primary-dark dark:bg-element-low-contrast-dark md:absolute md:-bottom-[160px] md:left-[50%] md:-translate-x-[25%] md:transform">
            {t('errors.stripePaymentError', { ns: 'common' })}
          </div>
        )}
      </>
    </StepsWrapper>
  );
}

export const Subscription = observer(_Subscription);
