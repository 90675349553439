// TODO: check if it's possible to replace all SmallLoader usage to the progress loader
import { classNames } from '@foundationPathAlias/utilities';

export type ProgressLoaderPropsType = {
  /** fill color of the big circle stroke */
  mainStrokeFill?: string;
  mainStrokeFillOpacity?: number;
  /** fill color of the small circle part stroke */
  secondaryStrokeFill?: string;
  width?: number;
  height?: number;
  cn?: string;
};

export function ProgressLoader({
  mainStrokeFill = '#1C64F2',
  mainStrokeFillOpacity = 0.9,
  secondaryStrokeFill = '#FFFFFF',
  width = 16,
  height = 16,
  cn,
}: ProgressLoaderPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('inline animate-spin', cn)}
    >
      <g id="Circle-progress" clipPath="url(#clip0_18941_109229)">
        <path
          id="Loading Small"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7647 3.12941C9.6941 2.52186 8.45315 2.28399 7.23378 2.45259C6.01441 2.62119 4.88456 3.18687 4.019 4.06213C3.15343 4.93739 2.60037 6.07347 2.44537 7.29464C2.29036 8.51581 2.54204 9.75404 3.16148 10.8178C3.78092 11.8816 4.7336 12.7116 5.87218 13.1795C7.01075 13.6474 8.27178 13.7271 9.46021 13.4063C10.6486 13.0855 11.6983 12.382 12.4467 11.4047C13.1952 10.4275 13.6008 9.23078 13.6008 7.99981H15.2008C15.2008 9.58248 14.6793 11.1211 13.717 12.3776C12.7547 13.6341 11.4052 14.5385 9.87719 14.951C8.34921 15.3635 6.72789 15.261 5.264 14.6594C3.80012 14.0578 2.57524 12.9906 1.77882 11.6229C0.982396 10.2552 0.658808 8.66324 0.858103 7.09316C1.0574 5.52309 1.76847 4.06242 2.88134 2.93708C3.99421 1.81174 5.44687 1.08444 7.01463 0.867669C8.58239 0.650897 10.1779 0.956731 11.5544 1.73787L10.7647 3.12941Z"
          fill={mainStrokeFill}
          fillOpacity={mainStrokeFillOpacity}
        />
      </g>
      <defs>
        <clipPath id="clip0_18941_109229">
          <rect width={width} height={height} fill={secondaryStrokeFill} />
        </clipPath>
      </defs>
    </svg>
  );
}
