import { Locales, defaultLocale } from '@mainApp/src/config/locales.config';
export type SSRHubServiceDataType = {
  lang: Locales;
};

// TODO: Test. Experimental hub for sharing data at the SRR stage

export class SSRHubService {
  data: SSRHubServiceDataType = {
    lang: defaultLocale,
  };

  setDataProp = (
    propName: keyof SSRHubServiceDataType,
    val: SSRHubServiceDataType[keyof SSRHubServiceDataType]
  ) => {
    this.data[propName] = val;
    return this;
  };

  getDataProp = (propName: keyof SSRHubServiceDataType) => {
    return this.data[propName];
  };
}

export const ssrHub = new SSRHubService();
