import { v4 as uuidv4 } from 'uuid';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type { IUserStore } from '../User.store.types';

const User = {
  id: 'optimistic_user_id',
  username: 'optimistic_username',
  email: 'optiistic_email@gmail.com',
  imageUrls: [],
  __typename: 'User',
};

// TODO: type
export const generateUser = (payload = {}) => {
  const userStore = iocContainer.get<IUserStore>(IOC_TOKENS.userStore);
  const id = uuidv4();

  return {
    ...User,
    id: `optimistic_user-${id}`,
    imageUrls: userStore.me?.serverData.imageUrls,
    ...payload,
  };
};
