import { eventBus } from './EventBus.singletone';
import { GlobalEvents } from './events';

import { IOC_TOKENS, iocContainer } from '../ioc';
import {
  IAuthStore,
  IInteractiveStore,
  IToastStore,
  InteractiveContentTypesEnum,
  ToastTypesEnum,
} from '../stores';

//Attention! Urql CombinedError somehow can't provide the error message from the response message field. It contains only the `error` field and the statusCode.

// they're permanent so they shouldn't be disposed
eventBus.on(GlobalEvents.URQL_GLOBAL_ERROR, (combinedError) => {
  const toastStore = iocContainer.get<IToastStore>(IOC_TOKENS.toastStore);
  // in the case of cors error there is no response from server
  // and the only deafult graphql `Failed to fetch` message
  if (!combinedError.response && combinedError.networkError) {
    let msg =
      combinedError.networkError.reason ||
      combinedError.message ||
      combinedError.networkError.message;

    const isWs = combinedError.networkError.target instanceof WebSocket;

    console.error(combinedError, combinedError.networkError.message);

    // means there is no connection in WS
    if (msg === '[Network] undefined') {
      msg = '[Network]: Websocket connection error';
    }

    // TODO: temporary till some Sentry will be available.
    // this error happens when auth and redirection to the caller page because the urql mutatio is interrapting due to new page opening
    toastStore.show({
      type: ToastTypesEnum.ERROR,
      title: isWs ? 'Websocket Error' : 'CORS Error',
      content: msg || 'Failed to fetch. Looks like it is a CORS error',
    });
    return;
  }

  const error = combinedError?.graphQLErrors[0];

  // skip for now all the forbidden errors till the complete vision of all the protected parts of the app will be done. They must be handled differently than other and in some cases they're allowed and there should be shown the auth UI
  if (error.extensions.originalError.statusCode === 403) {
    return;
  }

  toastStore.show({
    type: ToastTypesEnum.ERROR,
    title: combinedError.name,
    content: error ? error.message : combinedError.message,
  });
});

eventBus.on(GlobalEvents.URQL_AUTH_TOKEN_REFRESH, () => {
  const authStore = iocContainer.get<IAuthStore>(IOC_TOKENS.authStore);
  const interactiveStore = iocContainer.get<IInteractiveStore>(
    IOC_TOKENS.interactiveStore
  );
  // the token might be deprecated so just cleanup everything as it's obsolete anyway
  authStore.logout();
  authStore.setLoginMode(true);
  interactiveStore.setActiveContentType(InteractiveContentTypesEnum.AUTH, true);
});
