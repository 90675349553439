'use client';
import { RichEditor, validators } from '@foundationPathAlias/components';
import { EditorState } from 'lexical';
import { observer } from 'mobx-react-lite';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { classNames } from '@foundationPathAlias/utilities';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { FormFieldInput } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { extractPlainText } from '@mainApp/src/utils/lexical';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRulesStoreLocal } from './data/Rules.store.local';

const _RulesEditor = () => {
  const { t } = useTranslation(['cap']);

  const {
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.capStore, IOC_TOKENS.dimensionsStore]);

  const [initialValueToEdit, setInitialValueToEdit] = useState('');

  const rulesStoreLocal = capStore.activeChildScreen as IRulesStoreLocal;

  const isToEdit = Boolean(rulesStoreLocal.selectedRuleIdToEdit);

  // Solution to solve focus out of RichEditor, but I don't think is the best one
  useEffect(() => {
    if (isToEdit && rulesStoreLocal.ruleDescription.value) {
      setInitialValueToEdit(rulesStoreLocal.ruleDescription.value);
    } else {
      setInitialValueToEdit('');
    }

    return () => {
      setInitialValueToEdit('');
    };
  }, [isToEdit]);

  return (
    <ScreenWrapper
      title={t(isToEdit ? 'cap:rules.editRule' : 'cap:rules.createNewRule')}
      sidebarModalController={capStore}
      rootCn="pb-[20px]"
      ScreenHeaderProps={{
        childrenWrapperCn: classNames({
          'text-start': isMobile,
        }),
      }}
      showBackBtn
    >
      <div className="flex w-full flex-col gap-[24px]">
        <div className="flex flex-grow flex-col items-start">
          <div className="inline-grid w-full ">
            <label
              className="text-left text-body16SB text-text-primary dark:text-text-primary-dark"
              htmlFor="title"
            >
              {t('cap:rules.editorFields.title')}
            </label>

            <FormFieldInput
              cnWrapper="mt-2"
              onChange={(val) => {
                rulesStoreLocal.setRuleName({
                  value: val,
                  error: rulesStoreLocal.ruleName.error,
                });
              }}
              onError={(error) => {
                if (rulesStoreLocal.ruleName.error === error) return;

                rulesStoreLocal.setRuleName({
                  value: rulesStoreLocal.ruleName.value,
                  error: error,
                });
              }}
              value={rulesStoreLocal.ruleName.value}
              placeholder={
                t('cap:rules.editorFields.titlePlaceholder') as string
              }
              maxCharsLimit={25}
              errorTextCn="mb-4 text-element-error"
              validators={[validators.getEmptyFieldValidation()]}
              inputName="ruleTitle"
            />
          </div>

          <div className="flex w-full flex-grow flex-col items-start">
            <label
              className="mb-2 text-body16SB text-text-primary dark:text-text-primary-dark"
              htmlFor="description"
            >
              {t('cap:rules.editorFields.description')}
            </label>

            <div className=" relative flex w-full flex-grow flex-col text-start">
              <RichEditor
                containerCn="h-auto"
                mentionsProvider={() => <></>}
                predefinedStateJsonStr={
                  isToEdit ? initialValueToEdit : undefined
                }
                onChange={(editorState: EditorState) => {
                  const editorStateJSON = editorState.toJSON();
                  const stringifiedStateJSON = JSON.stringify(editorStateJSON);
                  const plainText = extractPlainText(stringifiedStateJSON);

                  rulesStoreLocal.setRuleDescription({
                    value: stringifiedStateJSON,
                    error: !plainText?.trim()
                      ? 'This field cannot be empty.'
                      : null,
                  });
                }}
              />

              {rulesStoreLocal.ruleDescription.error && (
                <p className="text-sm14R text-element-error">
                  {rulesStoreLocal.ruleDescription.error}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export const RulesEditor = withAnimatedStack(observer(_RulesEditor));
