export enum LAYOUT_TYPES_ENUM {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
export enum SCREEN_SIZES_ENUM {
  'xs' = 375,
  'sm' = 640,
  'md' = 1024,
  'lg' = 1440,
}

export enum ScreenSizeEnum {
  EXTRA_SMALL = 'xs',
  // '≥640px'
  SMALL = 'sm',
  // '≥1044px'
  MEDIUM = 'md',
  // '1440px'
  LARGE = 'lg',
  //
}

function getCurrentScreenSize(windowWidth: number) {
  let res: ScreenSizeEnum = ScreenSizeEnum.EXTRA_SMALL;

  if (windowWidth < SCREEN_SIZES_ENUM.sm) {
    res = ScreenSizeEnum.EXTRA_SMALL;
  }
  if (windowWidth >= SCREEN_SIZES_ENUM.sm) {
    res = ScreenSizeEnum.SMALL;
  }
  if (windowWidth >= SCREEN_SIZES_ENUM.md) {
    res = ScreenSizeEnum.MEDIUM;
  }
  if (windowWidth >= SCREEN_SIZES_ENUM.lg) {
    res = ScreenSizeEnum.LARGE;
  }
  return res;
}

export enum CLOUDFLARE_VARIANTS_ENUM {
  ICON = 'ICON',
  PUBLIC = 'PUBLIC',
  MEDIUM = 'MEDIUM',
  MEDIUM_BLURRED = 'MEDIUM_BLURRED',
  LARGE = 'LARGE',
  SQUARE = 'SQUARE',
}

const CLOUDFLARE_VARIANTS = {
  [CLOUDFLARE_VARIANTS_ENUM.ICON]: '20x20',
  [CLOUDFLARE_VARIANTS_ENUM.PUBLIC]: 'public',
  [CLOUDFLARE_VARIANTS_ENUM.MEDIUM]: '1280x720',
  [CLOUDFLARE_VARIANTS_ENUM.MEDIUM_BLURRED]: '1280x720blurred',
  [CLOUDFLARE_VARIANTS_ENUM.LARGE]: '2560x1440',
  [CLOUDFLARE_VARIANTS_ENUM.SQUARE]: '1080x1080',
};

// TOOD: unit tests
export function getCloudflareSizeRecognition(
  urls: string[],
  prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
) {
  if (!urls) {
    return undefined;
  }

  // by default md
  const screenWidth = global?.window?.innerWidth || 1024;

  const variantCriteria = prefferedSize
    ? CLOUDFLARE_VARIANTS[prefferedSize]
    : getVariantCriteria(screenWidth);

  const res = urls.find((url) =>
    new RegExp(`${variantCriteria}(?!blurred)`).test(url)
  );

  return res || urls[0];
}

function getVariantCriteria(screenWidth: number) {
  const currentScreenSize = getCurrentScreenSize(screenWidth);
  let actualVariantCriteria: string;

  switch (currentScreenSize) {
    case ScreenSizeEnum.LARGE:
      actualVariantCriteria =
        CLOUDFLARE_VARIANTS[CLOUDFLARE_VARIANTS_ENUM.LARGE];
      break;
    case ScreenSizeEnum.MEDIUM:
    default:
      actualVariantCriteria =
        CLOUDFLARE_VARIANTS[CLOUDFLARE_VARIANTS_ENUM.MEDIUM];
      break;
  }

  return actualVariantCriteria;
}
