import {
  SortingState,
  Table,
  TableHeaderSortable,
  createColumnHelper,
  getFilteredRowModel,
} from '@foundationPathAlias/components/table';
import { TextInput } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UsersTableCustomMobileRow,
  UsersTableDisplayNameCell,
  UsersTableFilterEmptyState,
  UsersTableNoMembers,
  UsersTableOptionsCell,
} from '.';
import { formatDate, fuzzyFilter } from '../data/helpers';
import { BannedUser } from '../data/types';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { type IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { UserModel } from '@mainApp/src/stores/User.model';
import { AvailableActionsEnum } from './tooltip-contents';

const _BannedUsersTable = () => {
  const { t } = useTranslation();
  const {
    capStore,
    dimensionsStore: { isMobile },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.communityStore,
  ]);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const blockedMembersList =
    communityStore.blockedMembers.data?.valuesArray || [];
  const isLoading = communityStore.blockedMembers.loading;
  const hasNextPage = Boolean(
    communityStore.blockedMembers?.meta?.pageInfo?.hasNextPage
  );

  const { blockedMemberFilter, setBlockedMemberFilter, unBanMember } =
    usersStoreLocal || {};

  const columnHelper = createColumnHelper<Partial<BannedUser>>();

  const data = blockedMembersList || [];

  const columns = [
    columnHelper.accessor('displayName', {
      id: 'displayName',
      enableGlobalFilter: true,
      filterFn: 'fuzzy',
      header: ({ header }) => {
        return (
          <TableHeaderSortable
            label={t('cap:users.table.columns.name')}
            header={header}
          />
        );
      },
      cell: ({ row }) => <UsersTableDisplayNameCell userData={row.original} />,
    }),
    columnHelper.accessor('author', {
      id: 'author',
      enableGlobalFilter: true,
      filterFn: 'fuzzy',
      header: ({ header }) => {
        return (
          <TableHeaderSortable
            label={t('cap:users.table.columns.bannedBy')}
            header={header}
          />
        );
      },
      cell: ({ row }) => (
        <UsersTableDisplayNameCell
          userData={new UserModel(row.original.author)}
        />
      ),
    }),
    columnHelper.accessor('createdAt', {
      enableGlobalFilter: false,
      header: ({ header }) => (
        <TableHeaderSortable
          label={t('cap:users.table.columns.bannedDate')}
          header={header}
        />
      ),
      cell: ({ row }) => (
        <>
          {row.original.createdAt ? formatDate(row.original.createdAt) : null}
        </>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('id', {
      enableGlobalFilter: false,
      // TODO: adjust any type
      cell: (info) => (
        <UsersTableOptionsCell
          row={info.row}
          availableActions={[AvailableActionsEnum.UnBan]}
          onUnBan={() => unBanMember(info.row.original.id as string)}
          hovered={Boolean((info as any).hovered)}
        />
      ),
      header: () => <></>,
    }),
  ];

  const [sorting, setSorting] = useState<SortingState>([]);

  const handleLoadMore = () => {
    if (!hasNextPage) return;

    communityStore.fetchMoreMembers();
  };

  return (
    <>
      <div className="flex items-center gap-[8px] pb-[16px] md:pb-[24px] md:pt-[16px]">
        <TextInput
          leftSideContent={
            <MagnifyingGlassIcon
              className="text-element-normal dark:text-element-normal-dark"
              width={20}
              height={20}
            />
          }
          InputProps={{
            defaultValue: blockedMemberFilter,
            onKeyDown: (evt) => {
              if (evt.key === 'Enter') {
                setBlockedMemberFilter(evt.currentTarget.value);
              }
            },
          }}
          placeholder={t<string>('common:search')}
          rootCn="flex-1"
        />
      </div>

      <div className="flex-1 overflow-y-auto scrollbar-thin">
        <Table
          dataSource={data}
          columns={columns}
          useReactTableOptions={{
            onSortingChange: setSorting,
            filterFns: {
              fuzzy: fuzzyFilter,
            },
            state: { sorting },
            globalFilterFn: 'fuzzy',
            getFilteredRowModel: getFilteredRowModel(),
          }}
          cnContainer="w-full"
          customRenderRow={
            !isMobile
              ? undefined
              : (row) => {
                  return (
                    <UsersTableCustomMobileRow
                      row={row}
                      hideRolesSection
                      availableActions={[AvailableActionsEnum.UnBan]}
                      gridItems={[
                        {
                          label: t('cap:users.table.columns.bannedBy'),
                          value: (
                            <p className="text-start">
                              <p>{row.original.author?.displayName}</p>
                              <p>@{row.original.author?.username}</p>
                            </p>
                          ),
                        },
                        {
                          label: t('cap:users.table.columns.bannedDate'),
                          value: formatDate(row.original.createdAt),
                        },
                      ]}
                    />
                  );
                }
          }
          cnTHeaderCell={(_cell, idx) =>
            classNames({
              'themed-layout sticky left-0 z-2 bg-background-primary dark:bg-background-primary-dark':
                idx === 0,
            })
          }
          cnTCell={(_cell, cellIdx) => {
            return classNames({
              'text-start px-[16px]': cellIdx === 2,
            });
          }}
          cnTHead="hidden md:table-header-group dark:bg-background-tetriary-dark"
          hasMorePages={hasNextPage}
          loadMore={handleLoadMore}
          loading={isLoading}
          customRender={({ table }) => {
            if (isLoading) return;

            if (!data.length) {
              return <UsersTableNoMembers />;
            }

            if (table.getRowCount() < 1) {
              return <UsersTableFilterEmptyState />;
            }

            return;
          }}
        />
      </div>
    </>
  );
};

export const BannedUsersTable = observer(_BannedUsersTable);
