import '@mainApp/src/events/global-subscribers';
import cookie from 'js-cookie';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useSyncLanguage } from 'ni18n';
import { ComponentType } from 'react';

const PostAuthOnboardingNoSSR = dynamic(
  () => import('../src/components/post-auth-onboarding/PostAuthOnboarding'),
  { ssr: false }
);
const CreateCommunityStepperModal: ComponentType<any> = dynamic(
  async () => {
    const mod = await import(
      '../src/components/common/create-community-stepper'
    );
    return mod.CreateCommunityStepperModal;
  },
  { ssr: false }
);
const Modal: ComponentType<any> = dynamic(
  async () => {
    const mod = await import('../src/components/common/Modal');
    return mod.Modal;
  },
  { ssr: false }
);
const BottomSheet: ComponentType<any> = dynamic(
  async () => {
    const mod = await import('../src/components/common/BottomSheet');
    return mod.BottomSheet;
  },
  { ssr: false }
);
const ToastManager: ComponentType<any> = dynamic(
  async () => {
    const mod = await import('../src/components/common');
    return mod.ToastManager;
  },
  { ssr: false }
);

export default function AppWrapper({ Component, pageProps }: AppProps & any) {
  const locale = typeof window !== 'undefined' && cookie.get('user_locale');

  // localStorage could return null but the useSyncLanguage hook requires eiter string or undefined
  useSyncLanguage(locale || 'en');

  if (!Component) return null;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page: any) => page);

  return (
    <>
      {getLayout(<Component {...pageProps} />)}
      <Modal />
      <PostAuthOnboardingNoSSR />
      <CreateCommunityStepperModal />
      <BottomSheet />
      <ToastManager />
    </>
  );
}
