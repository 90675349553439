import { useEffect, useRef, useState } from 'react';

/**
 * controls if the loading process was longer than the minimal
 * allowed time. If the outer loading process was shorter - the returning
 * loading state will continue be true till the minimal allowed time will be elapsed.
 * It's useful to render loaders for some minial time to prevent
 * content blinking
 * @param isOuterLoading {boolean} - loading marked of the external process
 * @param minRenderTime {number} - the time in MS how long do you want
 *  to keep returning loading state as true
 * @returns
 */
export function useMinLoading(isOuterLoading: boolean, minRenderTime = 350) {
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  // to make the updated value available for the timeout callback
  const outerLoadingRef = useRef<null | boolean>(false);

  useEffect(() => {
    outerLoadingRef.current = isOuterLoading;

    if (isOuterLoading) {
      if (!loading) {
        setLoading(true);
      }

      // if doesn't exist timeout should set a new one
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          // if not loading anymore
          if (!outerLoadingRef.current) {
            setLoading(false);
          }
        }, minRenderTime);
      }

      // doesn't loading anymore and the minimim time timer had been fired
    } else if (!timeoutRef.current) {
      setLoading(false);
    }
  }, [isOuterLoading]);

  return loading;
}
