import { ButtonBase, OutlineButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

// import { MessagesEventsEnum } from '@mainApp/src/events';
import { useTranslation } from 'react-i18next';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
  isMobile: boolean;
};

export function DeleteMessageModalActions(props: Props) {
  const { onCancel, onDelete, isMobile } = props;

  const { t } = useTranslation('common');

  return (
    <div
      className={classNames(
        'flex justify-end space-x-[16px] p-[24px]',
        isMobile &&
          'mt-[24px] border-t border-element-subtle dark:border-element-subtle-dark'
      )}
    >
      <OutlineButton cn="w-auto rounded-[5px]" onClick={onCancel}>
        {t('cancel')}
      </OutlineButton>
      <ButtonBase
        className="button-base-interactive themed-layout-error w-auto rounded-[5px] text-background-primary"
        onClick={onDelete}
      >
        {t('delete')}
      </ButtonBase>
    </div>
  );
}
