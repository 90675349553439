import { useMinLoading } from './hooks';

type Props = {
  isLoading: boolean;
};

export function withLoading<P>(
  Component: (props: P) => any,
  Loader: any,
  loaderProps: any = {}
) {
  function WithLoadingComponent({ isLoading, ...props }: P & Props) {
    const finalLoading = useMinLoading(isLoading);

    return !finalLoading ? (
      <Component {...(props as any)} />
    ) : (
      <Loader {...loaderProps} />
    );
  }

  const displayName =
    (Component as any).displayName || Component.name || 'Component';

  WithLoadingComponent.displayName = `WithLoadingComponentHOC(${displayName})`;

  return WithLoadingComponent;
}
