import { RGB } from '@10x/foundation/src/components/color-picker/ColorPicker';
import { classNames, getRgbaString } from '@10x/foundation/src/utilities';

export type TopicType = {
  name: string;
  id: string | number;
};

type TopicPropsType = {
  /** label will be empty at the start on the screen 4 for the new Community */
  name?: string;
  // rbg object from store
  color: RGB;
  active?: boolean;
};

export const Topic = (props: TopicPropsType) => {
  const { name, active, color } = props;
  const bgColor = getRgbaString(color, 0.1);
  const textColor = getRgbaString(color);

  return name ? (
    <span
      className={classNames(
        'dark:color-2 exstraSm12M rounded-[4px] px-[4px] py-[2px] ',
        // 'dark:color-2 exstraSm12M inline-block max-w-[45%] overflow-hidden overflow-ellipsis whitespace-nowrap rounded-[4px] px-[4px] py-[2px] ',
        active
          ? ''
          : // TODO: example community cards BE integration
            ':bg-primary-10 text-primary-100 dark:bg-primary-10-dark  dark:text-color-2'
      )}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {name}
    </span>
  ) : null;
};
