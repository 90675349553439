import { useCallback, useRef } from 'react';

import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { MentionUserNode } from '@mainApp/src/components/editor';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { PopoverTypeEnum } from '../../common/types';
import { messageEditorStore } from '../../editor-area/MessageEditor.store';

export function _MessageEditorNodeEventPlugin() {
  const userStore = useInjection(IOC_TOKENS.userStore);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isPointerOverRef = useRef(false);

  const gethUser = useCallback(
    (id: string, el: HTMLElement) => {
      messageEditorStore.setPopoverData({
        show: true,
        type: PopoverTypeEnum.MENTION_USER,
        triggerEl: el,
        loading: true,
        data: null,
      });

      userStore.getUser(id).then((userModel) => {
        messageEditorStore.setPopoverData({
          show: true,
          type: PopoverTypeEnum.MENTION_USER,
          triggerEl: el,
          loading: false,
          data: userModel,
        });
      });
    },
    [userStore]
  );

  return (
    <>
      <NodeEventPlugin
        nodeType={MentionUserNode as any}
        eventType={'pointerover'}
        eventListener={(e: Event, _editor, _nodeKey) => {
          if (isPointerOverRef.current) return;
          isPointerOverRef.current = true;

          const el: HTMLElement | null = e?.target as any;

          const rect = el?.getBoundingClientRect();
          const virutalEl = { ...el, getBoundingClientRect: () => rect };
          const id = el?.dataset.id;

          if (!id || id === 'undefined') return;

          if (!timeoutRef.current) {
            timeoutRef.current = setTimeout(() => {
              gethUser(id, virutalEl as HTMLElement);

              timeoutRef.current = null;
            }, 350);
          }
        }}
      />
      <NodeEventPlugin
        nodeType={MentionUserNode as any}
        eventType={'pointerout'}
        eventListener={() => {
          isPointerOverRef.current = false;
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
        }}
      />
    </>
  );
}

export const MessageEditorNodeEventPlugin = observer(
  _MessageEditorNodeEventPlugin
);
