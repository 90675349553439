export type DotsBlockPropsType = {
  cn?: string;
  rowsCount: number;
  colsCount: number;
  dotColorTwCn: string;
};
export function DotsBlock(props: DotsBlockPropsType) {
  const { rowsCount, colsCount, dotColorTwCn, cn } = props;
  const num = rowsCount * colsCount;

  return (
    <div
      className={'grid gap-[6px] [&>*]:block ' + cn}
      style={{
        gridTemplateColumns: `repeat(${colsCount}, minmax(0, 1fr))`,
        gridTemplateRows: `repeat(${rowsCount}, minmax(0, 1fr))`,
      }}
    >
      {[...new Array(num)].map((_, index) => (
        <i
          key={`dots-block-key_${index}`}
          className={'h-[6px] w-[6px] rounded-full ' + dotColorTwCn}
        />
      ))}
    </div>
  );
}
