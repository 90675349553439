import {
  BaseFieldData,
  Channel_Sort_Types,
  Channel_Types,
} from '@10x/foundation/types';

import { debouncePromisify } from '@foundationPathAlias/utilities';

import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';

import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import { IChannelRepository } from '@mainApp/src/repositories';
import type { IChannelStore } from '@mainApp/src/stores';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
enableStaticRendering(typeof window === 'undefined');

export type FieldData = BaseFieldData<string>;

const initialFieldData: FieldData = {
  data: '',
  loading: false,
  error: null,
  successMessage: '',
};

export class OverviewStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements IOverviewStore
{
  private showActionReactionDisposer: IReactionDisposer;
  private generalReactionDisposer: IReactionDisposer;
  private disableProceedActionReactionDisposer: IReactionDisposer;
  private channelStore: IChannelStore = iocContainer.get(
    IOC_TOKENS.channelStore
  );
  private channelRepository: IChannelRepository = iocContainer.get(
    IOC_TOKENS.channelRepository
  );

  isDisableProceedAction = false;

  channelName = {
    ...initialFieldData,
  };
  channelModel: ChannelModel | null = null;

  isChannelNameAvailableDebounced;

  get actionsPanelData() {
    return {
      cancelAction: () => {
        runInAction(() => {
          this.showActionPanel = false;
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: async () => {
        const isError = await this.updateChannel();
        if (!isError) {
          this.showActionPanel = false;
        }
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  constructor() {
    super(ScreenIds, screensConfig);

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,

      isDisableProceedAction: observable,
      activeScreenId: observable,
      // they exists in the parent class
      isDirty: observable,
      isLoading: observable,
      showActionPanel: observable,

      channelName: observable,
      channelModel: observable,
      setChannelName: action,
      setChannelModel: action,
      updateChannel: action,

      resetData: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    this.setupData();

    this.isChannelNameAvailableDebounced = debouncePromisify<boolean>(
      this.isChannelNameAvailable,
      300
    );
    // this.isChannelNameAvailableDebounced = _debounce(
    //   this.isChannelNameAvailable,
    //   300
    // );

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );
    this.disableProceedActionReactionDisposer = reaction(
      () => {
        return [this.channelName.loading, Boolean(this.channelName.error)];
      },
      (values) => {
        const [loading, error] = values;
        const shouldDisable = loading || Boolean(error);
        runInAction(() => {
          this.isDisableProceedAction = shouldDisable;
        });
      }
    );

    this.generalReactionDisposer = reaction(
      () => {
        return this.channelStore.activeChannel?.data?.serverData.name;
      },
      () => {
        this.setupData();
      }
    );
  }

  isChannelNameAvailable = async () => {
    const { communityId, channelGroupId } = this.getCommunityAndChannelData();
    const channelNameVal = this.channelName.data;
    let isAvailable = true;
    // shouldn't handle the empty name
    if (!channelNameVal) return isAvailable;

    const { data } = await this.channelRepository.getChannels({
      communityId: communityId,
      channelGroupId: channelGroupId as string,
      search: channelNameVal,
      noCache: true,
    });

    if (data?.edges) {
      const hasTheSameName = data.edges.some(
        (edge) =>
          edge.node.name.toLocaleLowerCase() ===
          channelNameVal.toLocaleLowerCase()
      );

      isAvailable = !hasTheSameName;
    }

    // get the recent channel groups
    await this.channelStore.getChannelGroups(communityId, true);

    const flattenedChannelModels = this.channelStore.flattenGroupsChannels;

    if (flattenedChannelModels) {
      const hasTheSameName = flattenedChannelModels.some(
        (channelModel) =>
          channelModel.serverData.name.toLocaleLowerCase() ===
          channelNameVal.toLocaleLowerCase()
      );
      isAvailable = !hasTheSameName;
    }

    return isAvailable;
  };

  setChannelModel = (channelModel: ChannelModel | null) => {
    this.channelModel = channelModel;
    if (
      channelModel &&
      channelModel?.serverData.name !== this.channelName.data
    ) {
      const newData = {
        ...this.channelName,
        data: channelModel.serverData.name,
      };
      this.channelName = newData;
    }
  };

  private setupData() {
    runInAction(() => {
      this.channelName.data =
        this.channelStore.activeChannel.data?.serverData.name || '';
    });
  }

  onFirstSidebarModalSet = () => {
    const changesAlertConfig = {
      title: 'discardChanges',
      description: 'discardChangesDescr',
      firstBtnText: 'keepEditing',
      secondBtnText: 'closeAndDiscard',
    };

    this.setChangesAlertConfig(changesAlertConfig);
  };

  closeAndDiscard = () => {
    this.setChangesAlertConfig({
      show: false,
    });
  };

  setChannelName = async (data: string, error: string | null = null) => {
    const newData = { ...this.channelName, data, error: error, loading: true };
    this.channelName = newData;

    const isAvailable = await this.isChannelNameAvailableDebounced();

    let updatedData = {
      ...this.channelName,
      loading: false,
    };

    if (isAvailable) {
      this.showActionPanel = true;
    } else {
      updatedData = {
        ...updatedData,
        error: 'channel:modal.channelAlreadyExists',
      };
    }
    this.channelName = updatedData;
    this.isDirty = true;
  };

  // private proceedChannelNameChange = () => {
  //   this.isChannelNameAvailableDebounced();

  //   this.isDirty = true;
  //   this.showActionPanel = true;
  // }

  private getCommunityAndChannelData() {
    const activeChannelServerData = this.channelModel?.serverData;

    const activeChannelId = activeChannelServerData?.id;
    if (!activeChannelId) {
      throw new Error('activeChannelId is not defined');
    }

    const communityId = activeChannelServerData.communityId;
    const channelGroupId = activeChannelServerData.parentId;

    const isProcessingActiveChannelStore =
      this.channelStore.activeChannel.data?.serverData.id === activeChannelId;

    return {
      activeChannelId,
      communityId,
      channelGroupId,
      isProcessingActiveChannelStore,
    };
  }

  updateChannel = async () => {
    this.isLoading = true;

    const {
      activeChannelId,
      communityId,
      channelGroupId,
      isProcessingActiveChannelStore,
    } = this.getCommunityAndChannelData();

    const { error } = await this.channelStore.updateChannel(
      communityId,
      activeChannelId,
      {
        channelGroupId,
        name: this.channelName.data,
        members: [],
        private: false,
        option: {
          sort: Channel_Sort_Types.New,
        },
        channelType: Channel_Types.Messages,
      },
      // should update the active channel only if it's the same channel
      !isProcessingActiveChannelStore
    );

    this.isLoading = false;

    return Boolean(error);
  };

  dispose = () => {
    this.showActionReactionDisposer();
    this.generalReactionDisposer();
    this.disableProceedActionReactionDisposer();
  };
}

export interface IOverviewStore
  extends ISidebarModalChildScreen<ScreenIdsValuesType> {
  channelName: FieldData;
  channelModel: ChannelModel | null;
  setChannelName: (data: string, error?: string | null) => void;
  setChannelModel: (channelModel: ChannelModel | null) => void;
  closeAndDiscard: () => void;
  isChannelNameAvailable: () => Promise<boolean>;
  // deleteChannel: () => Promise<boolean>;
}
