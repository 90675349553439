import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { SidebarModalController } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import { type IDimensionsStore } from '@mainApp/src/stores';
import { inject, injectable } from 'inversify';
import { childScreensRegistry, ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';
import { ChildScreenClasses, ICAPStore } from './types';

enableStaticRendering(typeof window === 'undefined');

@injectable()
export class CAPStore
  extends SidebarModalController<ChildScreenClasses, ScreenIdsValuesType>
  implements ICAPStore
{
  isMobile = false;
  autorunDisposer: IReactionDisposer;

  constructor(
    @inject(IOC_TOKENS.dimensionsStore) dimensionStore: IDimensionsStore
  ) {
    super({
      childScreenClasses: childScreensRegistry,
      screensConfig,
      screenIds: ScreenIds,
      initialChildScreenId: ScreenIds.USERS,
    });

    makeObservable(this, {
      isMobile: observable,
      activeScreenId: observable,
      setActiveScreenId: action,
    });

    this.autorunDisposer = autorun(() => {
      this.isMobile = dimensionStore.isMobile;
    });
  }

  dispose() {
    this.autorunDisposer();
  }
}
