import { classNames } from '@foundationPathAlias/utilities';

import { SmallLoader } from '../loaders';

import { ImageWithPlaceholder } from '../image/ImageWithPlaceholder';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export type ImageAttachmentProps = {
  src: string;
  alt: string;
  loading: boolean;
  failed?: boolean;
  rootCn?: string;
  imgCn?: string;
  loaderFillColor?: string;
  placeholderSrc?: string;
  /**
   * use children if you want to render image via some Next.js Image etc.
   * If it isn't passed the default html <img /> will be used
   */
  children?: (src: string, alt: string, loading: boolean) => JSX.Element;
};

export function ImageAttachment(props: ImageAttachmentProps) {
  const {
    src,
    placeholderSrc,
    alt,
    loading,
    failed,
    loaderFillColor,
    children,
    rootCn,
    imgCn,
  } = props;

  return children ? (
    children(src, alt, loading)
  ) : (
    <figure
      className={classNames('relative h-full w-auto rounded-[5px]', rootCn)}
    >
      <ImageWithPlaceholder
        src={src}
        placeholderSrc={placeholderSrc as string}
        alt={alt}
        rootCn={classNames('h-full w-auto rounded-[5px]', imgCn)}
      />
      {/* <img
        src={src}
        alt={alt}
        className={classNames('h-full w-auto rounded-[5px]', imgCn)}
      /> */}

      {loading && (
        <div className="f-full absolute left-0 top-0 flex h-full w-full items-center justify-center ">
          <div className="f-full absolute left-0 top-0 flex h-full w-full bg-black opacity-50"></div>
          <SmallLoader
            width={40}
            height={40}
            cn="text-color-6"
            fill={loaderFillColor || 'white'}
          />
        </div>
      )}

      {failed && (
        <div className="f-full absolute left-0 top-0 flex h-full w-full items-center justify-center ">
          <div className="f-full absolute left-0 top-0 flex h-full w-full bg-black opacity-50"></div>
          <ExclamationTriangleIcon className="absolute left-[50%]  top-[50%] z-1 h-[54px] w-[54px] -translate-x-[50%]  -translate-y-[50%] transform text-red-500" />
        </div>
      )}
    </figure>
  );
}
