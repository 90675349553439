import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export function useGetActiveCommunityDefaultChannelRedirect() {
  const router = useRouter();

  const communityStore = useInjection(IOC_TOKENS.communityStore);

  const activeCommunityModel = communityStore.activeCommunity.data;

  const communitySlugName = activeCommunityModel?.serverData?.slugName;

  const defaultChannelSlug = communityStore?.defaultChannel?.slug;

  const redirectToDefaultChannel = useCallback(() => {
    if (!communitySlugName || !defaultChannelSlug) return;
    const path = paths.getChannelPath(communitySlugName, defaultChannelSlug);
    router.push(path);
  }, [communitySlugName, defaultChannelSlug]);

  return redirectToDefaultChannel;
}
