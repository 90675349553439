import React, { createContext, useContext } from 'react';
import { ISidebarModal } from '../data';
import { useSidebarModal } from '../hooks/useSidebarModal';

const SidebarModalContext = createContext<ISidebarModal>(null as any);

type Props = {
  children: React.ReactNode;
};

export const SidebarModalProvider = (props: Props) => {
  const { children } = props;
  const sidebarModal = useSidebarModal();

  return (
    <SidebarModalContext.Provider value={sidebarModal}>
      {children}
    </SidebarModalContext.Provider>
  );
};

export const useSidebarModalContext = () => {
  const context = useContext(SidebarModalContext);
  if (context === undefined) {
    throw new Error(
      'useSidebarModal must be used within a SidebarModalProvider'
    );
  }
  return context;
};
