import { useEffect, useState } from 'react';

type State = {
    event: MouseEvent | TouchEvent | null;
    startLongPress: boolean;
};

export function useLongPress(callback: (e: TouchEvent | MouseEvent) => void, ms = 300) {
    const [state, setState] = useState<State>({
        event: null,
        startLongPress: false,
    });

    useEffect(() => {
        let timerId: NodeJS.Timeout | undefined = undefined;

        if (state.startLongPress) {
            timerId = setTimeout(() => {
                callback(state.event as unknown as MouseEvent | TouchEvent);
            }, ms);
        } else if (timerId) {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [state, callback, ms]);

    return {
        onMouseDown: (e: MouseEvent) =>
            setState({
                event: e,
                startLongPress: true,
            }),
        onMouseUp: (e: MouseEvent) => setState({ event: e, startLongPress: false }),
        onTouchStart: (e: TouchEvent) =>
            setState({ event: e, startLongPress: true }),
        onTouchEnd: (e: TouchEvent) =>
            setState({ event: e, startLongPress: false }),
    };
}