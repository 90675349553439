import { Transition } from '@headlessui/react';

import { PrimaryButton } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { paths } from '@mainApp/src/config';
import Link from 'next/link';
import { useRouter } from 'next/router';

export function NavbarMobileMenu({
  show,
  children,
  isLogged,
  completed,
  dimensionsStore,
  systemStore,
  createCommunityStepperStore,
}: any) {
  const createdCommunitySlug = createCommunityStepperStore;
  const isCommunityCreated = createCommunityStepperStore.isCommunityCreated;
  const router = useRouter();
  return (
    <Transition
      className="themed-layout absolute top-0 z-8 h-full w-full origin-top pt-[100px]"
      show={show}
      enter="transform transition duration-[300ms] 
        ease-[cubic-bezier(1,.01,.27,.36)]"
      enterFrom="scale-y-0"
      enterTo="scale-y-100"
      leave="transform transition duration-300 ease-[cubic-bezier(1,.27,0,.01)]"
      leaveFrom="scale-y-100"
      leaveTo="scale-y-0"
    >
      <Transition.Child
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          id="cy-mobile-menu"
          className="mt-[46px] flex h-full w-full flex-col justify-center"
        >
          <div className="mb-[66px] flex flex-col items-center space-y-[64px]">
            {children}
            <Link
              key={'logIn'}
              href={'/login'}
              locale={'en'}
              className={classNames('', 'nav')}
            >
              {isLogged ? 'Log out' : 'Log in'}
            </Link>
          </div>
          <PrimaryButton
            aria-label="View my community or Create my community"
            cn="m-[24px] w-auto rounded-full"
            onClick={() => {
              // if (isLogged) {
              // when saved model exists, should just redirect
              if (isCommunityCreated && isLogged) {
                router.push(
                  `${paths.home}/${createdCommunitySlug?.createdCommunitySlug}`
                );
              } else {
                if (dimensionsStore.isMobile && systemStore.isMobileNavOpened) {
                  systemStore.setMobileNavOpen(false);
                }
                createCommunityStepperStore.setModal(true);
              }
              // }
            }}
          >
            {completed ? 'View my community' : 'Create my community'}
          </PrimaryButton>
        </div>
      </Transition.Child>
    </Transition>
  );
}
