import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { AvatarPicker, DisplayName, Subscription, Username } from '../steps';
import { Preview } from './Preview';

import { StepNamesEnum } from '../PostAuthOnboarding.store.types';

const stepsComponentsConfig: {
  [K in StepNamesEnum]: React.ComponentType<any>;
} = {
  [StepNamesEnum.USERNAME]: Username,
  [StepNamesEnum.DISPLAY_NAME]: DisplayName,
  [StepNamesEnum.AVATAR]: AvatarPicker,
  [StepNamesEnum.SUBSCRIPTION]: Subscription,
};

function _Content() {
  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);

  const Component =
    stepsComponentsConfig[postOnboardingStore.activeStepName as StepNamesEnum];
  return (
    <div className="===self-center flex flex-1 flex-col px-[16px] pb-[12px] pt-[12px] md:flex-row md:space-x-[24px] md:px-[24px] md:pt-[24px]">
      <div className="md:order-0 ===h-full order-1 flex min-h-[206px] max-w-[370px] flex-1 flex-shrink-0 md:shrink-[2]">
        <Component />
      </div>

      <Preview />
    </div>
  );
}

export const Content = observer(_Content);
