import {
  ArrowRightOnRectangleIcon,
  AtSymbolIcon,
  CreditCardIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MoonIcon,
} from '@heroicons/react/24/outline';

import { MenuListItem, SettingIds } from './types';

export const useSidebarMenu = () => {
  const MENU_DESCRIPTION: { [key in SettingIds]: string } = {
    account: 'account',
    display: 'display',
    language: 'language',
    about: 'about.label',
    subscription: 'subscription',
    logOut: 'access.logout',
  };

  const MENU_LIST: MenuListItem[] = [
    {
      id: 'account',
      label: MENU_DESCRIPTION.account,
      icon: <AtSymbolIcon width={20} />,
    },
    {
      id: 'display',
      label: MENU_DESCRIPTION.display,
      icon: <MoonIcon width={20} />,
    },
    {
      id: 'language',
      label: MENU_DESCRIPTION.language,
      icon: <GlobeAltIcon width={20} />,
    },
    {
      id: 'about',
      label: MENU_DESCRIPTION.about,
      icon: <InformationCircleIcon width={20} />,
    },
    {
      id: 'subscription',
      label: MENU_DESCRIPTION.subscription,
      icon: <CreditCardIcon width={20} />,
    },
    {
      id: 'logOut',
      label: MENU_DESCRIPTION.logOut,
      icon: <ArrowRightOnRectangleIcon width={20} />,
    },
  ];

  return {
    MENU_LIST,
    MENU_DESCRIPTION,
  };
};
