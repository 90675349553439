import { ToastTypesEnum } from '@10x/foundation/src/components';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

@injectable()
export class ToastStore implements IToastStore {
  active = false;
  data: ToastPayload | null = null;

  constructor() {
    makeObservable(this, {
      active: observable,
      data: observable,
      show: action,
      hide: action,
    });
  }

  show = (payload: ToastPayload) => {
    this.data = payload;
    this.active = true;
  };
  hide = () => {
    this.active = false;
  };
}

export { ToastTypesEnum };

export type ToastPayload = {
  type: ToastTypesEnum;
  title: string;
  content: React.ReactNode;
};
export interface IToastStore {
  active: boolean;
  data: ToastPayload | null;

  show: (payload: ToastPayload) => void;
  hide: () => void;
}
