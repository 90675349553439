import { Emoji } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react';
import { popuplarEmojis } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/config/cdnUrls';
import { EmojiClickData } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/types/exposedTypes';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { MessageReactionButton } from './MessageReactionButton';

type Props = {
  getEmoji?: (emoji: EmojiClickData) => void;
  handleReactions?: () => void;
  messageModel: MessageModel;
};

export function ReactionsBar(props: Props) {
  const { getEmoji, handleReactions, messageModel } = props;
  const handleEmojiClick = (emoji: EmojiClickData) => {
    getEmoji?.(emoji);
  };

  const handleReactionsIconClick = () => {
    handleReactions?.();
  };

  const bgClass =
    'bg-color-14 flex h-[44px] w-[44px] items-center justify-center rounded-[12px]';

  return (
    <div className="flex w-full flex-row justify-between  px-[24px]">
      {popuplarEmojis.map((item: EmojiClickData) => {
        return (
          <div
            key={item.unified}
            className={bgClass}
            onClick={() => handleEmojiClick(item)}
          >
            <Emoji unified={item.unified} size={24} />
          </div>
        );
      })}
      <div className={bgClass} onClick={handleReactionsIconClick}>
        <MessageReactionButton messageModel={messageModel} />
      </div>
    </div>
  );
}
