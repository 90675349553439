import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PopoverPlacementEnum, PopoverTypeEnum } from '../common/types';

import {
  MentionAdminNode,
  MentionChannelNode,
  MentionUserNode,
  exampleTheme,
} from '@mainApp/src/components/editor';

import { CirclePlusButton } from '@10x/foundation/src/components';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { messageEditorStore } from './MessageEditor.store';
import {
  MessageEditorComposerContent,
  Props,
} from './MessageEditorComposerContent';

import { MessageEditorGuestPanel } from './MessageEditorGuestPanel';

const editorConfig = {
  theme: exampleTheme,
  namespace: 'message-editor',
  onError(error: Error) {
    throw error;
  },
  nodes: [MentionUserNode, MentionChannelNode, MentionAdminNode],
};

export function _MessageEditor(props: Props) {
  const { onSubmit, onMentionQueryChange } = props;
  const authStore = useInjection(IOC_TOKENS.authStore);

  const content = authStore.logged ? (
    <>
      <div className="flex items-center">
        <CirclePlusButton
          onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
            const el = e.currentTarget;
            const rect = el?.getBoundingClientRect();
            const virutalEl = { ...el, getBoundingClientRect: () => rect };

            messageEditorStore.setPopoverData({
              show: true,
              type: PopoverTypeEnum.ATTACHMENTS,
              triggerEl: virutalEl,
              loading: true,
              data: null,
              PopoverProps: {
                placement: PopoverPlacementEnum.TopStart,
                offsetConfig: {
                  alignmentAxis: -25,
                  mainAxis: 10,
                },
              },
            });
          }}
        />
      </div>

      <LexicalComposer initialConfig={editorConfig as any}>
        <MessageEditorComposerContent
          onSubmit={onSubmit}
          onMentionQueryChange={onMentionQueryChange}
        />
      </LexicalComposer>
    </>
  ) : (
    <MessageEditorGuestPanel />
  );

  return (
    <div className="relative flex flex-1 flex-col pt-[4px]">
      <div className="flex min-h-[40px] flex-1 pb-[8px]">{content}</div>
    </div>
  );
}

export const MessageEditor = observer(_MessageEditor);
