import { BackButton, CloseButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

type BackButtonPropsType = React.ComponentProps<typeof BackButton>;

export type ScreenHeaderPropsType = {
  children: React.ReactNode;
  isShowBackBtn: boolean;
  onBackBtnPress: () => void;
  onCloseBtnPress: () => void;
  cn?: string;
  childrenWrapperCn?: string;
  BackButtonProps?: BackButtonPropsType;
  CloseButtonProps?: React.ComponentProps<typeof CloseButton>;
  hideCloseButton?: boolean;
};

export function ScreenHeader(props: ScreenHeaderPropsType) {
  const {
    children,
    isShowBackBtn,
    onBackBtnPress,
    onCloseBtnPress,
    cn,
    childrenWrapperCn,
    BackButtonProps = {},
    CloseButtonProps = {},
    hideCloseButton,
  } = props;
  return (
    <h5
      className={classNames(
        'themed-text relative flex items-center border-b-[1px] border-b-element-subtle px-[16px] pb-[10px] text-center text-miniHeadD font-bold md:px-[24px] md:pb-[20px] md:text-left',
        cn
      )}
    >
      {isShowBackBtn && (
        <BackButton
          className={classNames(
            'mr-[12px]',
            (BackButtonProps as BackButtonPropsType).className
          )}
          onClick={onBackBtnPress}
          {...BackButtonProps}
        />
      )}
      <span
        className={classNames(
          'themed-text flex-1 pr-[38px] text-center md:pr-[0] md:text-left',
          childrenWrapperCn
        )}
      >
        {children}
      </span>

      {!hideCloseButton && (
        <CloseButton onClick={onCloseBtnPress} {...CloseButtonProps} />
      )}
    </h5>
  );
}
