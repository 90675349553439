import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { Input } from '../common/Input';

import { StepsWrapper } from './StepsWrapper';

import { validators } from '@foundationPathAlias/components';
import { StepNamesEnum } from '../PostAuthOnboarding.store.types';

export function _Username() {
  const { t } = useTranslation('common');
  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);
  const usernameStep = postOnboardingStore.steps[StepNamesEnum.USERNAME];
  const successMsg = usernameStep.successMessage;

  useDebounce(postOnboardingStore.checkUsernameAvailability, 300, [
    postOnboardingStore.steps[StepNamesEnum.USERNAME].data.value,
  ]);

  const proToken = postOnboardingStore.proMode ? (
    <span className="block h-[20px] w-[37px] rounded-[10px] bg-primary-100-dark text-center text-sm14T text-text-primary-dark">
      {t('pro')}
    </span>
  ) : null;

  return (
    <StepsWrapper
      title={t('createUserName')}
      description={t('userNameDescription')}
    >
      <Input
        addMailSignBeforeInput={true}
        getIsSuccess={(val, error) => {
          if (val === undefined) {
            return false;
          }
          return val.length > 0 && !error;
        }}
        inputName={t('username') as string}
        maxLength={16}
        specificValidators={[
          validators.getMaxLengthValidator(
            15,
            t('errors.usernameMaxLength', {
              maxCharCount: 15,
            }) as string
          ),
          validators.getOnlyCharNumberPeriodUnderscoreValidator(
            t('errors.onlyCharNumberPeriodUnderscore') as string
          ),
        ]}
        outerError={
          typeof usernameStep.error === 'string' ? t(usernameStep.error) : null
        }
        successMsg={t(successMsg)}
        placeholder={t('username') as string}
        value={usernameStep?.data?.value || ''}
        onChange={(val) => {
          const proMode = val.length <= 7;
          if (proMode) {
            postOnboardingStore.setProMode(true);
          } else {
            postOnboardingStore.setProMode(false);
          }
          postOnboardingStore.setStepData(StepNamesEnum.USERNAME, {
            value: val,
            lastStandardValue: proMode
              ? usernameStep.data.lastStandardValue || val
              : val,
          });
        }}
        onError={(error) => {
          const existingError =
            postOnboardingStore.steps[StepNamesEnum.USERNAME].error;

          if (existingError !== error) {
            postOnboardingStore.setStepData(
              StepNamesEnum.USERNAME,
              usernameStep.data,
              error
            );
          }
        }}
        rightSideContent={proToken}
      />
    </StepsWrapper>
  );
}

export const Username = observer(_Username);
