import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BadgeDot, OutlineButton } from '@foundationPathAlias/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { SubscriptionActions } from './SubscriptionActions';
import { SubscriptionInfo } from './common/SubscriptionInfo';
import {
  ISubscriptionStore,
  useGetSubscriptionCost,
  useGetSubscriptionExpirationDate,
} from './data';

type Props = {
  onSuccess: () => void;
};

export function _ActiveContent(props: Props) {
  const { onSuccess } = props;
  const { t } = useTranslation('common');

  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);

  const cost = useGetSubscriptionCost();
  const expirationDate = useGetSubscriptionExpirationDate();

  const subscriptionStore =
    accountSettingsStore.activeSettingStore as ISubscriptionStore;

  const handleProceed = async () => {
    const { error } = await subscriptionStore.deactivateProUserSubscription();
    if (!error) {
      onSuccess();
    }
    return Boolean(error);
  };

  function showDeactivateAlert() {
    const alertConfig = {
      show: true,
      title: t('accountSettings.deactivateProSubscription'),
      description: t('accountSettings.deactivateProSubscriptionDescr'),
      customActions: (
        <SubscriptionActions
          onProceedClick={handleProceed}
          proceedBtnText={t<string>('deactivate')}
        />
      ),
    };

    accountSettingsStore.setChangesAlertConfig(alertConfig);
  }

  return (
    <>
      <SubscriptionInfo
        cost={cost}
        description={`${t<string>(
          'accountSettings.willRenew'
        )} ${expirationDate}.`}
        badge={<BadgeDot type="normal" label={t<string>('active')} />}
      />
      <OutlineButton
        cn="mt-[16px] text-sm14SB w-auto h-auto py-[8px] themed-text"
        onClick={showDeactivateAlert}
      >
        {t('deactivate')}
      </OutlineButton>
    </>
  );
}

export const ActiveContent = observer(_ActiveContent);
