import {
  Popover,
  PopoverContent,
  PopoverFactory,
} from '@10x/foundation/src/components/popover';

import { FloatingOverlay } from '@floating-ui/react';

import { observer } from 'mobx-react-lite';

import { messageEditorStore } from '../../editor-area/MessageEditor.store';

import { UserModel } from '@mainApp/src/stores/User.model';
import { PopoverTypeEnum } from '../types';
import { MentionsPopoverUserContentWithLoader } from './mentions-content';

import { AttachmentsUploadMenu } from '@mainApp/src/modules/channel/attachments';

const contentRegistry = {
  [PopoverTypeEnum.MENTION_USER]: MentionsPopoverUserContentWithLoader,
  [PopoverTypeEnum.CHANNEL]: (_props: any) => null,
  [PopoverTypeEnum.MENTION_ADMIN]: (_props: any) => null,
  [PopoverTypeEnum.ATTACHMENTS]: AttachmentsUploadMenu,
};

export function _ChannelPopover() {
  const { popoverData } = messageEditorStore;
  const { show, data, loading, PopoverProps, type, triggerEl } = popoverData;

  const Content = contentRegistry[type];

  return (
    <Popover className="border-none focus:outline-none" {...PopoverProps}>
      {show && (
        // @ts-ignore
        <PopoverContent>
          <Content
            isLoading={loading}
            //  TODO: for now it supports only UserModel
            data={data as unknown as UserModel}
          />
        </PopoverContent>
      )}

      <PopoverFactory
        triggerEl={triggerEl}
        isOpen={show}
        onClose={messageEditorStore.hidePopover}
      />

      {show && <FloatingOverlay />}
    </Popover>
  );
}

export const ChannelPopover = observer(_ChannelPopover);
