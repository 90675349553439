import { classNames } from '@foundationPathAlias/utilities';
import { useMinLoading } from '../loaders';
import { ButtonBasePropsType } from './ButtonBase';
import { OutlineButton } from './OutlineButton';

import { LoaderContentWrapper, LoaderContentWrapperPropsType } from './common';

export type OutlineButtonWithLoaderPropsType = LoaderContentWrapperPropsType &
  ButtonBasePropsType;

export function OutlineButtonWithLoader(
  props: OutlineButtonWithLoaderPropsType
) {
  const {
    loading,
    children,
    onClick,
    minLoadingTimeMs = 500,
    disabled,
    cn,
    contentWrapperCn,
    contentCn,
    alignerCn,
    loaderWrapperCn,
    loaderCn,
    ...otherProps
  } = props;

  const finalLoading = useMinLoading(loading, minLoadingTimeMs);

  return (
    <OutlineButton
      cn={classNames('px-[24px] w-auto min-w-[130px]', cn)}
      onClick={onClick}
      disabled={disabled || finalLoading}
      {...otherProps}
    >
      <LoaderContentWrapper
        loading={loading}
        minLoadingTimeMs={minLoadingTimeMs}
        contentWrapperCn={contentWrapperCn}
        contentCn={contentCn}
        alignerCn={alignerCn}
        loaderWrapperCn={loaderWrapperCn}
        loaderCn={loaderCn}
      >
        {children}
      </LoaderContentWrapper>
    </OutlineButton>
  );
}
