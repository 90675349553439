import {
  Cell,
  ColumnDef,
  FilterFn,
  Header,
  type Table as ITable,
  Row,
  RowData,
  TableOptions,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  export interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  export interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type CnParams<TGroupedValue = unknown, TValue = unknown> = (
  obj: TGroupedValue,
  index: number,
  list: TValue,
  customProps?: {
    hovered?: boolean;
  }
) => string;

export type TableProps<TData extends RowData> = {
  columns: ColumnDef<TData, any>[];
  dataSource: TData[];

  cnContainer?: string;
  cnTHead?: string;
  cnTBody?: string;

  cnTHeaderCell?: string | CnParams<Header<TData, any>>;
  cnTHeaderRow?: string;

  cnTRow?: string;
  cnTCell?: string | CnParams<Cell<TData, any>>;

  useReactTableOptions?: Partial<
    Omit<
      TableOptions<TData>,
      'data' | 'columns' | 'getCoreRowModel' | 'getSortedRowModel'
    >
  >;

  hasMorePages?: boolean;
  loading?: boolean;
  loadMore?: () => void;

  customRender?: (props: { table: ITable<TData> }) => JSX.Element | undefined;
  customRenderRow?: (row: Row<TData>) => React.ReactNode;
};

export function Table<TData extends RowData>({
  columns,
  dataSource,
  cnContainer,
  cnTHead,
  cnTHeaderRow,
  cnTHeaderCell,
  cnTRow,
  cnTCell,
  hasMorePages,
  loading,
  loadMore,
  useReactTableOptions,
  customRender,
  customRenderRow,
}: TableProps<TData>) {
  const table = useReactTable<TData>({
    columns,
    data: dataSource,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...useReactTableOptions,
  } as TableOptions<TData>);

  const [hoveredRowId, setHoveredRowId] = useState('');

  const { ref, inView } = useInView();

  const onScrollEnd = () => {
    if (loading || !hasMorePages) return;

    loadMore?.();
  };

  useEffect(() => {
    if (inView) {
      onScrollEnd();
    }
  }, [inView]);

  if (customRender) {
    const content = customRender({ table });

    if (content) {
      return content;
    }
  }

  function extractCustomClassNames<TGroupedValue = unknown, TValue = unknown>(
    args: Parameters<CnParams<TGroupedValue, TValue>>,
    customCn?: CnParams<TGroupedValue, TValue> | string
  ) {
    if (!customCn) return '';

    if (typeof customCn === 'function') return customCn(...args);

    return customCn;
  }

  return (
    <table
      className={classNames(
        'scrollbar-thin relative overflow-auto',
        cnContainer
      )}
    >
      <thead
        className={classNames(
          'themed-layout top-0 border-b border-b-element-subtle dark:border-b-element-subtle-dark',
          cnTHead
        )}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className={classNames(cnTHeaderRow)} key={headerGroup.id}>
            {headerGroup.headers.map((header, idx, list) => {
              return (
                <th
                  key={header.id}
                  className={classNames(
                    'themed-text px-[16px] py-[6px] text-start text-sm14SB font-semibold',
                    extractCustomClassNames([header, idx, list], cnTHeaderCell)
                  )}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody className="">
        {table.getRowModel().rows.map((row) => {
          const hovered = hoveredRowId === row.id;

          if (customRenderRow) {
            return customRenderRow(row);
          }

          return (
            <tr
              className={classNames(
                'px-[12px] py-[10px] hover:dark:bg-hovered-selected-dark',
                {
                  'bg-hovered-selected dark:bg-hovered-selected-dark': hovered,
                },
                cnTRow
              )}
              onMouseEnter={() => setHoveredRowId(row.id)}
              onMouseLeave={() => setHoveredRowId('')}
              key={row.id}
            >
              {row.getVisibleCells().map((cell, idx, list) => {
                return (
                  <td
                    className={classNames(
                      'themed-text-secondary py-[10px] first:pl-[12px] last:pr-[12px]',
                      {
                        'pr-[16px]': idx < list.length - 1,
                      },
                      extractCustomClassNames(
                        [cell, idx, list, { hovered }],
                        cnTCell
                      )
                    )}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      hovered,
                    })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={columns.length} style={{ textAlign: 'center' }}>
            {loading && <SmallLoader />}
          </td>
        </tr>
        <tr ref={ref} style={{ height: 1 }} />
      </tfoot>
    </table>
  );
}

export * from '@tanstack/react-table';

export {
  compareItems,
  rankItem,
  type RankingInfo,
} from '@tanstack/match-sorter-utils';
