import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MenuItem } from './MenuItem';
import { useSidebarMenu } from './useSidebarMenu';

const _SidebarMenu = () => {
  const {
    authStore,
    dimensionsStore: { isMobile },
    accountSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.authStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.accountSettingsStore,
  ]);
  const { t } = useTranslation(['common']);

  const { MENU_LIST } = useSidebarMenu();

  return (
    <div
      className={classNames(
        'pb-[24px] pt-[24px] ',
        isMobile
          ? 'h-full w-full bg-background-primary dark:bg-background-primary-dark'
          : 'w-[216px] bg-background-tetriary dark:bg-background-secondary-dark'
      )}
    >
      <div className="flex flex-col gap-4">
        {MENU_LIST.map((menuProps) => {
          const { label, ...otherMenuProps } = menuProps;
          return (
            <MenuItem
              label={t<string>(label)}
              {...otherMenuProps}
              onSelect={(menuId) => {
                // TODO: refactor it
                switch (menuId) {
                  case 'logOut':
                    authStore.logout();
                    break;
                  default:
                    accountSettingsStore.setActiveSettingId(menuId);
                    break;
                }
              }}
              active={accountSettingsStore.activeSettingId === menuProps.id}
              key={menuProps.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SidebarMenu = observer(_SidebarMenu);
