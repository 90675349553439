import { enableStaticRendering } from 'mobx-react-lite';

import type { ChangesAlertProps as ChangesAlertPropsType } from '@foundationPathAlias/components';
import {
    IAnimatedStackStore
} from '@mainApp/src/components/common';
import type { IDimensionsStore } from '@mainApp/src/stores/';
import { AboutStore } from '../about/data';
import { AccountStore } from '../account/data';
import { SettingIds } from '../common/types';
import { DisplayStore } from '../display/data';
import { LanguageStore } from '../language/data/Language.store';
import { SubscriptionStore } from '../subscription/data';
enableStaticRendering(typeof window === 'undefined');

export enum DiscardActionsEnum {
    BACK = 'back',
    GO_TO_MENU = 'goToMenu',
}

export type GoToMenuActionData = {
    savedMenuItemId: SettingIds;
};

export type DiscardActions = {
    [DiscardActionsEnum.BACK]: null;
    [DiscardActionsEnum.GO_TO_MENU]: GoToMenuActionData;
};

export type SettingsStores = {
    account: AccountStore | null;
    display: DisplayStore | null;
    subscription: SubscriptionStore | null;
    about: AboutStore | null;
    language: LanguageStore | null;
};


export type BottomSheetConfig = {
    content: null | JSX.Element;
    show: boolean;
    onClose: null | (() => void);
};

export interface IAccountSettingsStore {
    changesAlertConfig: Partial<ChangesAlertPropsType>;
    discardActions: {
        [DiscardActionsEnum.BACK]: null | (() => void);
        [DiscardActionsEnum.GO_TO_MENU]: {
            savedMenuItemId: null | string;
        };
    };
    activeDiscardAction: DiscardActionsEnum | null;
    dimensionStore: IDimensionsStore;
    activeSettingId: keyof SettingsStores | null;
    stackStore: IAnimatedStackStore;
    settingStores: SettingsStores;

    show: boolean;
    showChangesAlert: boolean;
    showSuccessProSubscriptionAlert: boolean;
    processingSubscription: boolean;
    isFirstRenderCompleted: boolean;
    wasLayoutMobile: boolean;
    bottomSheetConfig: BottomSheetConfig;
    readonly activeSettingStore: SettingsStores[keyof SettingsStores];
    readonly isMobile: boolean;
    readonly isActiveSettingStoreDirty: boolean;
    readonly isInitialScreen: boolean;

    setChangesAlertConfig: (config: Partial<ChangesAlertPropsType>) => void;
    // --- potential BASEITEM
    setActiveSettingId: (id: keyof SettingsStores) => void;
    renderInitialScreen: () => void;
    // --- !potential BASEITEM

    setShowSuccessProSubscriptionAlert: (show: boolean) => void;
    setBottomSheetConfig: (data: Partial<BottomSheetConfig>) => void;
    completeSubscription: (proUsername: string, fialed?: boolean) => Promise<void>;
    showChangesAlertAndSaveAction: (
        activeDiscardAction: DiscardActionsEnum,
        val?: DiscardActions[keyof DiscardActions]
    ) => void;
    executeSavedDiscardAction: () => void;
    setShow: (val: boolean) => void;
    back: () => void;
    setInitialSetting: () => void;
}
