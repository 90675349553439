import { MoonSVG, SunSVG } from '@10x/foundation/src/components';
import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const Icon = ({ children }: any) => {
  return (
    <span
      className="flex h-[35px] w-[50px] items-center rounded-[25px] px-[16px] py-[8px]
    "
    >
      {children}
    </span>
  );
};

type ThemeSwitcherProps = {
  wrapperCn?: string;
};

export function _ThemeSwitcher({ wrapperCn }: ThemeSwitcherProps) {
  const [dark, setDark] = useState(false);
  const systemStore = useInjection(IOC_TOKENS.systemStore);

  useEffect(() => {
    setDark(systemStore.isDarkTheme);
  }, [systemStore.isDarkTheme]);

  return (
    <button
      className={classNames(
        'relative flex h-[43px] w-[108px] rounded-[20px] bg-background-secondary p-[4px] dark:bg-background-primary-dark',
        wrapperCn
      )}
      onClick={() => {
        // by default it's light
        systemStore.setDarkTheme(!dark);
      }}
      aria-label="theme switcher"
    >
      <span
        className={classNames(
          dark ? 'translate-x-[51px]' : 'translate-x-0',
          'absolute left-1 flex h-[35px] w-[50px] transform items-center rounded-[25px] bg-background-primary px-[16px] py-[8px] shadow-chip-shadow transition-transform duration-200 ease-in-out dark:bg-color-0 dark:shadow-chip-shadow-dark'
        )}
      />

      <div className="relative z-1 flex">
        <Icon>
          <SunSVG />
        </Icon>
        <Icon>
          <MoonSVG />
        </Icon>
      </div>
    </button>
  );
}

export const ThemeSwitcher = observer(_ThemeSwitcher);
