import { classNames } from '@foundationPathAlias/utilities';
import { ButtonBase, ButtonBasePropsType } from './ButtonBase';
export type TextButtonPrimaryPropsType = ButtonBasePropsType;

export function TextButtonPrimary(props: TextButtonPrimaryPropsType) {
  const { cn, ...otherProps } = props;
  return (
    <ButtonBase
      {...otherProps}
      cn={classNames(
        'w-auto bg-transparent active:!bg-transparent active:opacity-20 text-primary-100 hover:text-primary-hover px-[8px]',
        cn
      )}
    />
  );
}
