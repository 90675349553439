import { OrderDown, OrderUp, OrderUpDown } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { HeaderContext } from '@tanstack/react-table';

export type TableHeaderSortableProps<T extends object> = {
  label: string;
  header: HeaderContext<T, any>['header'];
  cn?: string;
  translations?: Partial<{
    sortAscending: string;
    sortDescending: string;
    clearSort: string;
  }>;
};

export function TableHeaderSortable<T extends object>({
  label,
  header,
  translations,
  cn,
}: TableHeaderSortableProps<T>) {
  const sortAscendingText = translations?.sortAscending ?? 'Sort ascending';
  const sortDescendingText = translations?.sortDescending ?? 'Sort descending';
  const clearSortText = translations?.clearSort ?? 'Clear sort';

  return (
    <div
      className={classNames(
        'text-nowrap',
        {
          'flex cursor-pointer select-none items-center':
            header.column.getCanSort(),
        },
        cn
      )}
      onClick={header.column.getToggleSortingHandler()}
      title={
        header.column.getCanSort()
          ? header.column.getNextSortingOrder() === 'asc'
            ? sortAscendingText
            : header.column.getNextSortingOrder() === 'desc'
            ? sortDescendingText
            : clearSortText
          : undefined
      }
    >
      {label}

      {header.column.getCanSort()
        ? {
            asc: <OrderUp />,
            desc: <OrderDown />,
          }[header.column.getIsSorted() as string] ?? <OrderUpDown />
        : null}
    </div>
  );
}
