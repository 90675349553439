import { Emoji } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react';

type Props = {
  emojiUnified: string;
  count: number;
  size?: number;
};

export const ReactionAndCount = (props: Props) => {
  const { emojiUnified, count, size } = props;
  return (
    <>
      <Emoji unified={emojiUnified} {...(size && { size: size })} />
      <div className="themed-text ml-1 text-sm14T">{count}</div>
    </>
  );
};
