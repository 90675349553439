export const ArrowUpSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({
  width = 20,
  height = 20,
  className,
  color = '#1A1A1A',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M8.86294 2.48518C9.14941 2.18618 9.5527 2 9.99949 2C9.9994 2 9.99957 2 9.99949 2C10.0293 2 10.0594 2.00083 10.0891 2.00251C10.1959 2.00851 10.2999 2.02515 10.3999 2.0514C10.6611 2.11975 10.9081 2.25627 11.1128 2.46095L17.5391 8.88718C18.1537 9.50177 18.1537 10.4982 17.5391 11.1128C16.9245 11.7274 15.928 11.7274 15.3134 11.1128L11.5733 7.37267V16.4262C11.5733 17.2954 10.8687 18 9.99949 18C9.13032 18 8.42572 17.2954 8.42572 16.4262V7.3737L4.68659 11.1128C4.072 11.7274 3.07554 11.7274 2.46095 11.1128C1.84635 10.4982 1.84635 9.50177 2.46095 8.88718L8.86294 2.48518Z"
      fill={color}
    />
  </svg>
);
