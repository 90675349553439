import {
  IReactionDisposer,
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
enableStaticRendering(typeof window === 'undefined');

import {
  Account_Username_Statuses,
  User,
  User_Change_Email_Request_Status,
} from '@10x/foundation/types';
import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import { AccountSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';
import {
  IRedirectService,
  IStorageService,
  RedirectionIdsEnum,
  StorageDataKeysEnum,
} from '@mainApp/src/services';
import type { IToastStore, IUserStore } from '@mainApp/src/stores';
import { SubscriptionTypeEnum } from '@mainApp/src/stores';
import { ApiBase } from '@mainApp/src/stores/ApiBase';
import { refineURL } from '@mainApp/src/utils';
import { accountSettingsComponents } from './accountSettingsComponents';

import { IAnimatedStackStore } from '@mainApp/src/components/common';
import {
  Actions,
  DeleteStepEnum,
  FieldData,
  IAccountStore,
  SettingsData,
  SettingsDataNamesEnum,
} from './Account.store.types';

const initialData: FieldData = {
  data: '',
  loading: false,
  error: null,
  successMessage: '',
};

export class AccountStore extends ApiBase implements IAccountStore {
  private showActionReactionDisposer: IReactionDisposer;
  reactionDisposer: IReactionDisposer;
  storageService: IStorageService;
  redirectService: IRedirectService;
  //   repository;
  userStore: IUserStore;

  data: SettingsData = {
    [SettingsDataNamesEnum.USERNAME]: {
      ...initialData,
      successMessage: 'success.availableUsername',
      // TODO setup it from BE
      activeSubscriptionType: SubscriptionTypeEnum.YEAR,
    },
    [SettingsDataNamesEnum.DISPLAY_NAME]: {
      ...initialData,
      successMessage: 'success.availableDisplayName',
    },
    [SettingsDataNamesEnum.FIRST_NAME]: {
      ...initialData,
    },
    [SettingsDataNamesEnum.LAST_NAME]: {
      ...initialData,
    },
    [SettingsDataNamesEnum.EMAIL]: {
      ...initialData,
      serverEmail: null,
      changeEmailRequests: [],
    },
    [SettingsDataNamesEnum.DELETE]: {
      ...initialData,
      data: {
        step: DeleteStepEnum.INITIAL,
        reasonComment: '',
      },
    },
  };

  actions: Actions;
  // saveActionsNew: SaveActionsNew;

  // should be true if the user made some chanes for any data field in this store
  isDirty = false;
  isLoading = false;
  proMode = false;
  stackStore: IAnimatedStackStore;
  showActions = false;
  showCodeForm = false;
  isDisableNextAction = false;

  activeSettingId: AccountSettingsEnum = AccountSettingsEnum.INITIAL;

  get isError() {
    return Boolean(
      Object.keys(this.data).find((key) =>
        Boolean(this.data[key as SettingsDataNamesEnum].error)
      )
    );
  }

  get saveActionText() {
    return this.proMode && !this.userStore.me?.isActivePro
      ? 'continueWithPro'
      : 'saveChanges';
  }

  get actionsData() {
    if (
      this.activeSettingId === AccountSettingsEnum.INITIAL ||
      this.activeSettingId === AccountSettingsEnum.DEACTIVATE_OR_DELETE
    ) {
      return null;
    }

    return this.actions[this.activeSettingId];
  }

  get initialSetting() {
    return AccountSettingsEnum.INITIAL;
  }
  get activeSetting() {
    return accountSettingsComponents[this.activeSettingId];
  }

  get isInitialScreen() {
    return this.activeSettingId === AccountSettingsEnum.INITIAL;
  }

  constructor(stackStore: IAnimatedStackStore) {
    super(iocContainer.get<IToastStore>(IOC_TOKENS.toastStore));
    this.userStore = iocContainer.get<IUserStore>(IOC_TOKENS.userStore);
    this.storageService = iocContainer.get<IStorageService>(
      IOC_TOKENS.storageService
    );
    this.redirectService = iocContainer.get<IRedirectService>(
      IOC_TOKENS.redirectService
    );

    makeObservable(this, {
      activeSetting: computed,
      isInitialScreen: computed,
      saveActionText: computed,
      isError: computed,
      data: observable,
      isDirty: observable,
      showCodeForm: observable,
      showActions: observable,
      proMode: observable,
      isDisableNextAction: observable,
      isLoading: observable,

      activeSettingId: observable,
      setSettingValue: action,
      setSettingData: action,
      setShowCodeForm: action,
      deactivateUserAccount: action,
      setProMode: action,
      updateUsername: action,
      updateDisplayName: action,
      checkUsernameAvailability: action,
      checkEmailAvailability: action,
      updateFirstAndLastName: action,
      setActiveSettingId: action,
      completeSubscription: action,

      setNextSettingId: action,
      setDeleteStep: action,
      setIsDisableNextAction: action,
      setIsLoading: action,
      setShowActions: action,
      back: action,
      reset: action,
    });

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        this.setShowActions(isDirty);
      }
    );

    this.stackStore = stackStore;

    this.reactionDisposer = autorun(this.setupData);

    this.actions = {
      [AccountSettingsEnum.USERNAME]: {
        cancelAction: this.resetData,
        getCancelActionText: () => 'cancel',
        proceedAction: this.updateUsername,
        getProceedActionText: () => this.saveActionText,
      },
      [AccountSettingsEnum.DISPLAY_NAME]: {
        cancelAction: this.resetData,
        getCancelActionText: () => 'cancel',
        proceedAction: this.updateDisplayName,
        getProceedActionText: () => 'saveChanges',
      },
      [AccountSettingsEnum.NAME]: {
        cancelAction: this.resetData,
        getCancelActionText: () => 'cancel',
        proceedAction: this.updateFirstAndLastName,
        getProceedActionText: () => 'saveChanges',
      },
      [AccountSettingsEnum.EMAIL]: {
        cancelAction: this.resetData,
        getCancelActionText: () => 'cancel',
        proceedAction: this.updateEmail,
        getProceedActionText: () => 'saveChanges',
      },
      [AccountSettingsEnum.DEACTIVATE]: {
        cancelAction: () => {
          this.setShowActions(false);
          this.stackStore.back();
        },
        getCancelActionText: () => 'cancel',
        proceedAction: this.sendDeactivateLink,
        getProceedActionText: () => 'accountSettings.deactivateAccount',
      },
      [AccountSettingsEnum.DELETE]: {
        cancelAction: () => {
          const deleteStep = this.data[SettingsDataNamesEnum.DELETE].data.step;
          if (deleteStep === DeleteStepEnum.INITIAL) {
            this.setDeleteStep(DeleteStepEnum.FINAL);
            this.setIsDisableNextAction(false);
          } else {
            this.setDeleteStep(DeleteStepEnum.INITIAL);
          }
          this.setShowCodeForm(false);
        },
        getCancelActionText: () => {
          const deleteStep = this.data[SettingsDataNamesEnum.DELETE].data.step;
          return deleteStep === DeleteStepEnum.INITIAL ? 'skip' : 'cancel';
        },
        proceedAction: () => {
          const deleteStep = this.data[SettingsDataNamesEnum.DELETE].data.step;
          if (deleteStep === DeleteStepEnum.INITIAL) {
            this.setDeleteStep(DeleteStepEnum.FINAL);
          } else {
            this.sendDeleteLink();
          }
        },
        getProceedActionText: () => 'continue',
      },
    };
  }
  setShowActions = (show: boolean) => {
    if (this.showActions === show) return;
    this.showActions = show;
  };
  setShowCodeForm = (show: boolean) => {
    if (this.showCodeForm === show) return;
    this.showCodeForm = show;
  };

  setupData = () => {
    if (!this.userStore.me) return;
    const {
      displayName,
      username,
      firstName,
      lastName,
      email,
      pro,
      proUsername,
    } = this.userStore.me.serverData;

    const status = pro?.status;

    const finalUsername =
      status === Account_Username_Statuses.Active ? proUsername : username;

    runInAction(() => {
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.USERNAME]: {
          ...this.data[SettingsDataNamesEnum.USERNAME],
          data: finalUsername || '',
        },
        [SettingsDataNamesEnum.DISPLAY_NAME]: {
          ...this.data[SettingsDataNamesEnum.DISPLAY_NAME],
          data: displayName || '',
        },
        [SettingsDataNamesEnum.FIRST_NAME]: {
          ...this.data[SettingsDataNamesEnum.FIRST_NAME],
          data: firstName || '',
        },
        [SettingsDataNamesEnum.LAST_NAME]: {
          ...this.data[SettingsDataNamesEnum.LAST_NAME],
          data: lastName || '',
        },
        [SettingsDataNamesEnum.EMAIL]: {
          ...this.data[SettingsDataNamesEnum.EMAIL],
          data: email || '',
          serverEmail: email || null,
        },
      };
    });
  };

  setProMode = (proMode: boolean) => {
    this.proMode = proMode;
  };
  setIsDisableNextAction = (disable: boolean) => {
    if (disable === this.isDisableNextAction) return;
    this.isDisableNextAction = disable;
  };

  resetData = () => {
    this.isDirty = false;
    this.setupData();
  };

  dispose = () => {
    this.showActionReactionDisposer();
    this.reactionDisposer();
  };

  setUsername = (value: string) => {
    const oldVal = this.data[SettingsDataNamesEnum.USERNAME].data;
    if (value !== oldVal) {
      this.setSettingValue(SettingsDataNamesEnum.USERNAME, value);
    }
  };

  setDisplayName = (value: string) => {
    const oldVal = this.data[SettingsDataNamesEnum.DISPLAY_NAME].data;
    if (value !== oldVal) {
      this.setSettingValue(SettingsDataNamesEnum.DISPLAY_NAME, value);
    }
  };
  setFirstName = (value: string) => {
    const oldVal = this.data[SettingsDataNamesEnum.FIRST_NAME].data;
    if (value !== oldVal) {
      this.setSettingValue(SettingsDataNamesEnum.FIRST_NAME, value);
    }
  };
  setLastName = (value: string) => {
    const oldVal = this.data[SettingsDataNamesEnum.LAST_NAME].data;
    if (value !== oldVal) {
      this.setSettingValue(SettingsDataNamesEnum.LAST_NAME, value);
    }
  };
  setEmail = (value: string) => {
    const oldVal = this.data[SettingsDataNamesEnum.EMAIL].data;
    if (value !== oldVal) {
      this.setSettingValue(SettingsDataNamesEnum.EMAIL, value);
    }
  };

  setSettingValue = (
    settingName: SettingsDataNamesEnum,
    value: string | any,
    error?: string | null
  ) => {
    const serverData = this.userStore.me?.serverData[settingName as keyof User];
    this.isDirty = serverData !== value;

    this.data = {
      ...this.data,
      [settingName]: {
        ...this.data[settingName],
        data: value,
        error: error !== undefined ? error : null,
      },
    };
  };
  setSettingData = (
    settingName: SettingsDataNamesEnum,
    data: SettingsData[keyof SettingsData] = {} as SettingsData[keyof SettingsData]
  ) => {
    this.data = {
      ...this.data,
      [settingName]: {
        ...this.data[settingName],
        ...data,
      },
    };
  };

  checkUsernameAvailability = async () => {
    const username = this.data[SettingsDataNamesEnum.USERNAME].data;
    this.data = {
      ...this.data,
      [SettingsDataNamesEnum.USERNAME]: {
        ...this.data[SettingsDataNamesEnum.USERNAME],
        loading: true,
      },
    };
    this.setIsLoading(true);
    if (!username) return;
    const { error, data: response } =
      await this.userStore.checkUsernameAvailability(username);

    const newData = {
      ...this.data,
      [SettingsDataNamesEnum.USERNAME]: {
        ...this.data[SettingsDataNamesEnum.USERNAME],
        loading: false,
      },
    };

    if (error || !response) {
      newData[SettingsDataNamesEnum.USERNAME].error = !response
        ? 'usernameIsTaken'
        : error.message;
    }

    runInAction(() => {
      this.data = newData;
    });

    this.setIsLoading(false);

    return error;
  };
  checkEmailAvailability = async () => {
    const email = this.data[SettingsDataNamesEnum.EMAIL].data;
    this.setIsLoading(true);
    this.data = {
      ...this.data,
      [SettingsDataNamesEnum.EMAIL]: {
        ...this.data[SettingsDataNamesEnum.EMAIL],
        loading: true,
      },
    };
    if (!email) return;
    const { error, data: response } = await this.userStore.validateUserEmail(
      email
    );

    const newData = {
      ...this.data,
      [SettingsDataNamesEnum.EMAIL]: {
        ...this.data[SettingsDataNamesEnum.EMAIL],
        loading: false,
      },
    };

    if (error || !response) {
      newData[SettingsDataNamesEnum.EMAIL].error = !response
        ? 'errors.emailInUse'
        : error.message;
    }

    runInAction(() => {
      this.data = newData;
    });
    this.setIsLoading(false);

    return error;
  };

  updateUsername = async () => {
    this.isLoading = true;
    // should execute pro username update only after the success subscription. Always return false
    if (this.proMode) {
      if (this.userStore.me?.isActivePro) {
        const proUserName = this.data[SettingsDataNamesEnum.USERNAME].data;
        const { error } = await this.userStore.saveProUsername(
          proUserName as string
        );
        if (error) {
          this.data = {
            ...this.data,
            [SettingsDataNamesEnum.USERNAME]: {
              ...this.data[SettingsDataNamesEnum.USERNAME],
              error: error.message,
            },
          };
        }
        this.isLoading = false;
        this.isDirty = false;
      } else {
        this.createProUserSubscription();
      }

      return;
    }

    const username = this.data[SettingsDataNamesEnum.USERNAME].data;

    const res = await this.userStore.updateUsername(username as string);
    if (res && res.error) {
      const { error } = res;

      this.handleError('errors.usernameUpdateError', error.message);
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.USERNAME]: {
          ...this.data[SettingsDataNamesEnum.USERNAME],
          error: error.message,
        },
      };
    } else {
      this.isDirty = false;
      this.stackStore.back();
    }

    this.isLoading = false;

    return Boolean(res?.error);
  };
  updateDisplayName = async () => {
    const displayName = this.data[SettingsDataNamesEnum.DISPLAY_NAME].data;
    this.userStore.setDataField('displayName', displayName);
    const res = await this.userStore.updateCurrentUser();
    if (res && res.error) {
      const { error } = res;

      this.handleError('errors.displayNameUpdateError', error.message);
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.DISPLAY_NAME]: {
          ...this.data[SettingsDataNamesEnum.DISPLAY_NAME],
          error: error.message,
        },
      };
    } else {
      this.isDirty = false;

      this.stackStore.back();
    }

    return Boolean(res?.error);
  };

  updateFirstAndLastName = async () => {
    const firstName = this.data[SettingsDataNamesEnum.FIRST_NAME].data;
    const lastName = this.data[SettingsDataNamesEnum.LAST_NAME].data;

    this.userStore.setDataField('firstName', firstName);
    this.userStore.setDataField('lastName', lastName);

    const res = await this.userStore.updateCurrentUser();
    if (res && res.error) {
      const { error } = res;

      this.handleError('errors.firstAndLastNameUpdateError', error.message);
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.FIRST_NAME]: {
          ...this.data[SettingsDataNamesEnum.FIRST_NAME],
          error: error.message,
        },
        [SettingsDataNamesEnum.LAST_NAME]: {
          ...this.data[SettingsDataNamesEnum.LAST_NAME],
          error: error.message,
        },
      };
    } else {
      this.isDirty = false;
      this.stackStore.back();
    }

    return Boolean(res?.error);
  };

  updateEmail = async () => {
    const email = this.data[SettingsDataNamesEnum.EMAIL].data;
    const res = await this.userStore.preUpdateUserEmail(email);
    if (res && res.error) {
      const { error } = res;

      this.handleError('errors.emailUpdateError', error.message);
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.EMAIL]: {
          ...this.data[SettingsDataNamesEnum.EMAIL],
          error: error.message,
        },
      };
    } else {
      this.isDirty = false;
      this.getUserChangeEmailRequests();
      this.stackStore.back();
    }
  };

  getUserChangeEmailRequests = async () => {
    const { data, error } = await this.userStore.getUserChangeEmailRequests();

    if (error) {
      this.handleError('errors.cantGetChageEmailRequests', error.message);
    } else {
      this.data = {
        ...this.data,
        [SettingsDataNamesEnum.EMAIL]: {
          ...this.data[SettingsDataNamesEnum.EMAIL],
          changeEmailRequests:
            data?.filter(
              (o) => o.status === User_Change_Email_Request_Status.Pending
            ) || [],
        },
      };
    }
  };

  createProUserSubscription = async () => {
    const activePriceSlug =
      this.data[SettingsDataNamesEnum.USERNAME].activeSubscriptionType;
    const { data, error } = await this.userStore.createProUserSubscription(
      activePriceSlug
    );

    if (error) {
      this.handleError('errors.createUsernameError', error.message);
    } else if (data) {
      this.storageService.setStorageItem(
        StorageDataKeysEnum.PRO_SUBSCRIPTION_PAYMENT,
        JSON.stringify({
          id: RedirectionIdsEnum.ACCOUNT_SETTINGS,
          url: document.location.href,
          data: {
            username: this.data[SettingsDataNamesEnum.USERNAME].data,
          },
        })
      );

      this.redirectService.redirect(data, {
        self: true,
      });
    }

    return Boolean(error);
  };

  setActiveSettingId = (setting: AccountSettingsEnum) => {
    if (this.activeSettingId === setting) return;

    this.activeSettingId = setting;
  };

  setNextSettingId = (setting: AccountSettingsEnum) => {
    const stackStore = this.stackStore;
    // shouldn't do anything if the animation is running
    if (stackStore.isAnimationRunning) {
      return;
    }

    this.activeSettingId = setting;
    const nextItem = accountSettingsComponents[setting];
    this.setIsDisableNextAction(false);
    stackStore.next(nextItem.id, nextItem.Component);
  };
  back = () => {
    this.setShowActions(false);
    const stackStore = this.stackStore;
    // shouldn't do anything if the animation is running
    if (stackStore.isAnimationRunning) {
      return;
    }
    const prevSettingId = stackStore.back();

    if (!prevSettingId) return;

    this.activeSettingId = prevSettingId as AccountSettingsEnum;
  };
  sendDeactivateLink = async () => {
    this.setIsLoading(true);

    const { error } = await this.userStore.sendDeactivateUserAccountEmail();
    if (error) {
      this.handleError('errors.deactivateLinkError', error.message);
    } else {
      this.setShowCodeForm(true);
    }
    this.setIsLoading(false);
  };

  deactivateUserAccount = async (otp: string) => {
    const res = await this.userStore.deactivateUserAccount(otp);
    const { error } = res;
    if (!error) {
      this.setShowCodeForm(false);
    }

    return error;
  };
  sendDeleteLink = async () => {
    this.setIsLoading(true);
    const reason = this.data[SettingsDataNamesEnum.DELETE].data.reasonComment;

    const { error } = await this.userStore.sendDeleteUserAccountEmail(reason);
    if (error) {
      this.handleError('errors.deleteLinkError', error.message);
    } else {
      this.setShowCodeForm(true);
    }
    this.setIsLoading(false);
  };

  deleteAccount = async (otp: string) => {
    const res = await this.userStore.deleteUserAccount(otp);
    const { error } = res;
    if (!error) {
      this.setShowCodeForm(false);
    }

    return error;
  };

  setDeleteStep(step: DeleteStepEnum) {
    this.data = {
      ...this.data,
      [SettingsDataNamesEnum.DELETE]: {
        ...this.data[SettingsDataNamesEnum.DELETE],
        data: {
          ...this.data[SettingsDataNamesEnum.DELETE].data,
          step,
        },
      },
    };
  }

  completeSubscription = async (proUserName: string, failed = false) => {
    this.proMode = true;
    if (failed) {
      this.handleError(
        'errors.subscriptionPaymentErrorTitle',
        'errors.subscriptionPaymentError'
      );
    } else {
      // should be only in the case of Pro mode
      const { error } = await this.userStore.saveProUsername(proUserName);
      if (error) {
        this.data = {
          ...this.data,
          [SettingsDataNamesEnum.USERNAME]: {
            ...this.data[SettingsDataNamesEnum.USERNAME],
            error: error.message,
          },
        };
      }
    }

    const refinedUrl = refineURL();
    // TODO: global service maybe with save/restore storage data
    if (refinedUrl) {
      // TODO: internal use of i18n Service
      global?.history?.replaceState({}, 'Community', `/${refinedUrl}`);
    }
  };

  setIsLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  reset = () => {
    this.setActiveSettingId(this.initialSetting);
    this.stackStore.reset();
    this.stackStore.setVisible(
      this.activeSetting.id,
      this.activeSetting.Component
    );
  };
}
