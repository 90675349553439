import { ChevronRightIcon } from '@heroicons/react/20/solid';

export type ActionWithIconProps = {
  title: string;
  onClick: () => void;
};
export function ActionWithIcon(props: ActionWithIconProps) {
  const { title, onClick } = props;
  return (
    <div
      className="hover-el inline-flex cursor-pointer items-center"
      onClick={onClick}
    >
      <span className="text-primary-100">{title}</span>
      <ChevronRightIcon className=" ml-[8px] mt-[4px] h-[20px] w-[20px] text-primary-100" />
    </div>
  );
}
