
const ACTIONS = {
    SET_CHANGES_ALERT_CONFIG: 'SET_CHANGES_ALERT_CONFIG',
    SET_DISCARD_ACTIONS: 'SET_DISCARD_ACTIONS',
    SET_ACTIVE_DISCARD_ACTION: 'SET_ACTIVE_DISCARD_ACTION',
    SHOW_CHANGES_ALERT_AND_SAVE_ACTION: 'SHOW_CHANGES_ALERT_AND_SAVE_ACTION',
    SET_SHOW: 'SET_SHOW',
    SET_BOTTOM_SHEET_CONFIG: 'SET_BOTTOM_SHEET_CONFIG',
    SET_SIDEBAR_MODAL: 'SET_SIDEBAR_MODAL',
} as const;



export {
    ACTIONS
};

