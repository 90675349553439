import { observer } from 'mobx-react-lite';

import {
  IOC_TOKENS,
  useInjection,
  useMultipleInjection,
} from '@mainApp/src/ioc';

import { AttachmentPreview, Slider } from '@10x/foundation/src/components';
import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';

type Props = {
  attachmentModel: AttachmentModel;
};

const AttachmentPreviewWrapper = observer((props: Props) => {
  const { attachmentModel } = props;
  const { attachmentsMediator, systemStore } = useMultipleInjection([
    IOC_TOKENS.attachmentsMediator,
    IOC_TOKENS.systemStore,
  ]);

  return (
    <AttachmentPreview
      id={attachmentModel.id}
      isDarkMode={systemStore.isDarkTheme}
      // name={attachmentModel.preview.name}
      name={attachmentModel.fileName as string}
      // previewSrc={attachmentModel.preview.content}
      previewSrc={attachmentModel.getPreviewOrRealImageUrl() as string}
      loading={attachmentModel.uploading}
      failed={attachmentModel.failed}
      onRemove={() => {
        attachmentsMediator.removeAttachment(attachmentModel);
      }}
      onReUpload={() => {
        attachmentsMediator.reUploadAllFailed(
          attachmentModel.parentMessageModel
        );
      }}
    />
  );
});

export function _MessageAttchments() {
  const messageStore = useInjection(IOC_TOKENS.messageStore);

  const attachments = messageStore.activeMessageModel?.attachments;

  if (!attachments) {
    return null;
  }

  const attachmentsArray = Object.values(attachments);

  if (!attachmentsArray.length) {
    return null;
  }

  return (
    <div className="attachments h-[66px]">
      <Slider>
        {(SwiperSlide) => {
          return attachmentsArray.map((o, index) => {
            return (
              <SwiperSlide key={`${o.name}-${index}`}>
                <AttachmentPreviewWrapper attachmentModel={o} />
              </SwiperSlide>
            );
          });
        }}
      </Slider>
    </div>
  );
}

export const MessageAttachments = observer(_MessageAttchments);
