import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import { StepNamesEnum } from '../PostAuthOnboarding.store.types';
import { StepsWrapper } from './StepsWrapper';

export const CropperFND = dynamic(
  () => {
    return import('@10x/foundation/src/components/cropper').then((res) => {
      return res.Cropper;
    });
  },
  { ssr: false }
);

export function AvatarPicker() {
  const { t } = useTranslation('common');
  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);

  return (
    <StepsWrapper
      title={t('addProfilePicture')}
      description={t('addProfilePictureDescription')}
    >
      <div className="flex justify-start">
        <label className="hover-el themed-text flex h-[42px] w-auto flex-1 cursor-pointer items-center justify-center rounded-[5px] border-[1px] border-element-subtle px-[24px] dark:border-element-subtle-dark md:flex-initial md:justify-start">
          <input
            className="hidden"
            type="file"
            onChange={(e) => {
              const file = e?.target?.files && e?.target?.files[0];
              if (file) {
                // const url = URL.createObjectURL(file);
                // TODO: temporary till cloudflare
                new Promise((resolve, reject) => {
                  const fileReader = new FileReader();
                  fileReader.readAsDataURL(file);
                  fileReader.onload = () => {
                    resolve(fileReader.result);
                  };
                  fileReader.onerror = (error) => {
                    reject(error);
                  };
                }).then((url) => {
                  postOnboardingStore.setStepData(StepNamesEnum.AVATAR, {
                    showCropper: true,
                    // @ts-ignore
                    preview: url,
                    // TODO: cropped preview add
                    // croppedPreview: url,
                    file: file,
                  });
                });
                // postOnboardingStore.setStepData(StepNamesEnum.AVATAR, {
                //   showCropper: true,
                //   preview: url,
                //   file: file,
                // });
              }
            }}
          />
          {t('uploadPhoto')}
        </label>
      </div>
    </StepsWrapper>
  );
}
