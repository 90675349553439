import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';

import {
  ScreenToggleItem,
  ScreenWrapper,
  Separator,
} from '@mainApp/src/modules/account-settings/common';
import { AccountSettingsEnum } from '../data/types';
export function _Account() {
  const { t } = useTranslation('common');
  const { accountSettingsStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  // should do it in this way because of the rare case when the user switch Dekstop layout to Mobile and moves back in stack
  const accountStore = accountSettingsStore.settingStores
    .account as IAccountStore;
  const { title } = accountStore.activeSetting;

  useEffect(() => {
    accountStore.getUserChangeEmailRequests();
  }, []);

  const emailData = accountStore.data[SettingsDataNamesEnum.EMAIL];
  const isMobile = dimensionsStore.isMobile;
  const isChangeEmailPending = Boolean(emailData.changeEmailRequests.length);

  return (
    // true if mobile mode
    <ScreenWrapper title={t(title)} showBackBtn={isMobile}>
      <h5 className="themed-text text-left text-body16SB">
        {t('accountSettings.accountInformation')}
      </h5>

      <ScreenToggleItem
        label={t('username')}
        value={accountStore.data[SettingsDataNamesEnum.USERNAME].data}
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.USERNAME);
        }}
      />

      <ScreenToggleItem
        label={t('displayName')}
        value={accountStore.data[SettingsDataNamesEnum.DISPLAY_NAME].data}
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.DISPLAY_NAME);
        }}
      />

      <ScreenToggleItem
        label={t('accountSettings.deactivateOrDeleteAccount')}
        onClick={() => {
          accountStore.setNextSettingId(
            AccountSettingsEnum.DEACTIVATE_OR_DELETE
          );
        }}
      />
      <Separator />
      <h5 className="themed-text mt-[24px] text-left text-body16SB">
        {t('accountSettings.personalInformation')}
      </h5>

      <ScreenToggleItem
        label={t('name')}
        value={
          accountStore.data[SettingsDataNamesEnum.FIRST_NAME].data +
          ' ' +
          accountStore.data[SettingsDataNamesEnum.LAST_NAME].data
        }
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.NAME);
        }}
      />

      <ScreenToggleItem
        label={t('email')}
        value={
          <span className="flex flex-col">
            {isChangeEmailPending
              ? emailData.changeEmailRequests[0].email
              : emailData.data}
            {isChangeEmailPending && (
              <span className="themed-text-secondary text-sm13D italic">
                {t('accountSettings.waitingForConfirmation')}
              </span>
            )}
          </span>
        }
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.EMAIL);
        }}
      />
    </ScreenWrapper>
  );
}

export const Account = withAnimatedStack(observer(_Account));
