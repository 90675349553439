import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { OutlineButton, PrimaryButton } from '../buttons';
import { ImagePicker, ImagePickerImageType } from '../image-picker';
import { TextInput } from '../inputs';
import { Modal } from '../modal';


export function CustomEmoji({ show, handleHide, maxFileSize }: { show: boolean, handleHide: () => void, maxFileSize: number }) {
  const [image, setImage] = useState<ImagePickerImageType | null>(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
   const intervalRef = useRef<string | number | NodeJS.Timer | undefined>();


  useEffect( () => {
    if (show) {
      clearInterval(intervalRef.current);
      setImage(null)
      setProgressPercentage(0);
    }
  }, [show])

  const getImage = (img: ImagePickerImageType | null) => {
    setProgressPercentage(0);
    clearInterval(intervalRef.current);
    setImage(img);
  }


  const startUpload = () => {
    intervalRef.current = setInterval(() => {
      setProgressPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          clearInterval(intervalRef.current);
          return 100;
        }
        return prevPercentage + 1;
      });
    }, 100);
  };

  return (
    <div className='flex'>
      <Modal
        open={show}
        overlayCn='bg-opacity-0 bg-background-overlay dark:bg-background-overlay-dark'
        contentWrapperCn='xs:p-0 md:p-4 '
      >
        <Dialog.Panel className="relative w-screen md:max-w-[525px] transform rounded-lg text-left shadow-chip-shadow transition-all bg-background-primary dark:bg-background-primary-dark md:pt-1 md:pb-6 ">
          <div className="overflow-auto md:overflow-visible h-screen md:h-auto xs:flex xs:flex-col xs:justify-between md:block">
            <div>
              <div className="flex items-center md:justify-between xs:border-b md:border-b-0 border-element-subtle dark:border-element-subtle-dark xs:px-4 md:px-6">
                <XMarkIcon
                  className="h-[64px] w-6 text-text-secondary dark:text-text-secondary-dark cursor-pointer md:order-last"
                  aria-hidden="true"
                  onClick={handleHide}
                />
                <span className="table w-full xs:text-center md:text-left xs:text-body16B md:text-subheadingSB text-text-primary dark:text-text-primary-dark md:order-first">Add custom emoji</span>
              </div>
              <div className='xs:px-4 md:px-6 xs:mt-[16px] md:mt-0'>
                <span className="text-sm14R text-text-secondary dark:text-text-secondary-dark">Your custom emoji will be available to everyone in your community, and will be found under the community tab of the emoji picker. You can manage your community’s emojis
                  <a href="https://example.com" className="text-sm14R font-medium text-color-12 dark:text-color-12-dark"> here.</a>
                </span>

                <div className="mt-[24px] text-body16SB text-text-primary dark:text-text-primary-dark">Upload an image</div>

                <div className="text-sm14R mt-[8px] text-text-secondary dark:text-text-secondary-dark ">Tip: Square images with transparent backgrounds work best.</div>

                <div className="flex w-full">
                  <ImagePicker progressPercentage={progressPercentage} maxFileSize={maxFileSize} getImage={getImage} />
                </div>

                <div className='mt-[24px] border-[.5px] border-element-subtle dark:border-element-subtle-dark' />
                <div className=" mt-[24px] text-body16SB text-text-primary dark:text-text-primary-dark">Give it a name</div>
                <div className="text-sm14R mt-[8px] text-text-secondary dark:text-text-secondary-dark">The emoji’s name is what you’ll type to add this emoji to your messages.</div>

                <TextInput
                  ref={null}
                  disabled={false}
                  placeholder=':avocado:'
                  rootCn='w-full py-2 mt-[16px] rounded-[5px] text-sm14R'
                  inputCn='text-sm14R text-text-primary dark:text-text-primary-dark'
                />
              </div>
            </div>

            <div>
              <div className='xs:mt-[15px] md:mt-0 xs:border-[.5px] md:border-0 border-element-subtle dark:border-element-subtle-dark' />
              <div className="xs:mt-[15px] md:mt-[30px] xs:mb-[16px] md:mb-0 xs:px-4 md:px-6 flex xs:justify-start items-center md:justify-end sm:mb-[50px] ">
                <div className='sm:overflow-visible md:overflow-hidden'>
                  <OutlineButton
                    disabled={false}
                    onClick={handleHide}
                    cn={'px-[24px] py-2 mr-4 w-[94px] border-[1px] border-element-subtle dark:border-element-subtle-dark'}
                  >
                    <span className='text-sm14SB text-text-primary dark:text-text-primary-dark'>Cancel</span>
                  </OutlineButton>
                </div>

                <PrimaryButton
                  disabled={!image || progressPercentage > 0 && progressPercentage < 100}
                  onClick={startUpload}
                  cn={'px-[24px] shadow-none md:w-auto'}
                >
                  <span className='text-sm14SB text-text-primary-dark'>Save changes</span>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Modal>
    </div >
  );
}
