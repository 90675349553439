import { AccountProUsernameSubscription, AccountProUsernameTypeItemPayload, User, UserChangeEmailRequestType, UserGeneralSettingsInput, UserGeneralSettingsResponse } from '@10x/foundation/types';
import {
  GetUsersQueyParamsType,
  IUserRepository
} from '@10x/main_app/src/repositories/User.repository.types';
import { BaseRepositoryResponse } from '../repositories';
import { CommonApiDataShapeType, IterableDataShape } from './ApiBase';
import { DataFieldNames, UserModel } from './User.model';
type GeneralSettings = Omit<UserGeneralSettingsResponse, 'ownerId' | 'id' | '__typename'>

export interface IUserStore {
  me: UserModel | null;
  repository: IUserRepository;
  isSubmitting: boolean;
  isUploading: boolean;
  proSubscription: AccountProUsernameSubscription | null;
  subscriptionPrices: AccountProUsernameTypeItemPayload[] | null;
  users: CommonApiDataShapeType<IterableDataShape<UserModel> | null>;
  generalSettings: CommonApiDataShapeType<GeneralSettings>;
  // if true -> the fetching will happend and the .me will be updated regardless if it's existing or not
  getCurrentUser: (updateMeData?: boolean) => Promise<UserModel | null>;
  getUser: (id: string) => Promise<UserModel | null>;
  getUsers: (params: GetUsersQueyParamsType) => Promise<void>;

  updateUsername: (username: string) => Promise<BaseRepositoryResponse<Partial<User> | void>>;
  checkUsernameAvailability: (username: string) => Promise<BaseRepositoryResponse<boolean | void>>;
  updateCurrentUser: () => Promise<BaseRepositoryResponse<Partial<User>> | void>;

  updateAvatarUser: (file: File) => Promise<void>;
  setProSubscription: (data: AccountProUsernameSubscription | null) => void
  setDataField: (fieldName: DataFieldNames, val: string) => void;

  fetchMoreUsers: (params?: GetUsersQueyParamsType) => void;
  createProUserSubscription: (priceId: string) => Promise<BaseRepositoryResponse<string>>;
  deactivateProUserSubscription: () => Promise<BaseRepositoryResponse<AccountProUsernameSubscription>>;
  reactivateProUserSubscription: () => Promise<BaseRepositoryResponse<AccountProUsernameSubscription>>;
  getUserProSubscription: () => Promise<BaseRepositoryResponse<AccountProUsernameSubscription>>
  saveProUsername: (priceId: string) => Promise<BaseRepositoryResponse<Partial<User>>>;
  sendDeactivateUserAccountEmail: () => Promise<BaseRepositoryResponse<boolean>>;
  sendDeleteUserAccountEmail: (reason: string) => Promise<BaseRepositoryResponse<boolean>>;
  deactivateUserAccount: (otpCode: string) => Promise<BaseRepositoryResponse<Partial<User>>>;
  preUpdateUserEmail(email: string): Promise<BaseRepositoryResponse<boolean>>;
  deleteUserAccount: (otpCode: string) => Promise<BaseRepositoryResponse<Partial<User>>>;
  updateUserGeneralSettings(data: UserGeneralSettingsInput): Promise<BaseRepositoryResponse<Error | null>>;
  getUserGeneralSettings(): Promise<BaseRepositoryResponse<Error | null>>;
  getUserChangeEmailRequests(): Promise<BaseRepositoryResponse<UserChangeEmailRequestType[]>>;
  validateUserEmail(email: string): Promise<BaseRepositoryResponse<boolean>>;
  updateUserEmail(otpCode: string): Promise<BaseRepositoryResponse<Partial<User>>>;
  cancelUserChangeEmailRequest(changeEmailRequestId: string): Promise<BaseRepositoryResponse<Partial<UserChangeEmailRequestType>>>;
  resendUserChangeEmailRequest(changeEmailRequestId: string): Promise<BaseRepositoryResponse<boolean>>;
  getAccountProUsernameSubscriptionTypes: () => Promise<AccountProUsernameTypeItemPayload[] | null>;

  getSubscriptionPriceByType: (priceType: SubscriptionTypeEnum) => AccountProUsernameTypeItemPayload | void;
}

export enum SubscriptionTypeEnum {
  YEAR = 'yearly',
  MONTH = 'monthly',
}
export enum SubscriptionIntervalEnum {
  YEAR = 'year',
  MONTH = 'month',
}
