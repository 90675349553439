import { User } from '@10x/foundation/types';
import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import { ICommunityStore, IUserStore } from '@mainApp/src/stores';
import { IRole, UserModel } from '@mainApp/src/stores/User.model';
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

export class UsersStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements IUsersStoreLocal
{
  private showActionReactionDisposer: IReactionDisposer;
  private updateSelectedRolesDisposer: IReactionDisposer;
  private updateMembersListOnSwitchTabDisposer: IReactionDisposer;
  private memberFilterDisposer: IReactionDisposer;
  private blockedMemberFilterDisposer: IReactionDisposer;

  userStore: IUserStore;
  communityStore: ICommunityStore;

  memberFilter = '';
  blockedMemberFilter = '';

  activeTabIndex = 0;

  userToBan: User | null = null;

  selectedRolesByUser: { [key: string]: IRole[] } = {};
  rolesToAdd: { [key: string]: string[] } = {}; // Pending roles to add by user
  rolesToRemove: { [key: string]: string[] } = {}; // Pending roles to remove by user

  constructor() {
    super(ScreenIds, screensConfig);

    this.userStore = iocContainer.get(IOC_TOKENS.userStore);
    this.communityStore = iocContainer.get(IOC_TOKENS.communityStore);

    makeObservable(this, {
      userToBan: observable,
      activeTabIndex: observable,
      selectedRolesByUser: observable,

      setUserToBan: action,
      confirmBanMember: action,
      cancelBanMember: action,
      setActiveTabIndex: action,
      unBanMember: action,

      addRoleToUser: action,
      removeRoleFromUser: action,
      saveRoleChanges: action,
      cancelRoleChanges: action,

      memberFilter: observable,
      blockedMemberFilter: observable,
      setMemberFilter: action,
      setBlockedMemberFilter: action,

      membersBlockedCount: computed,
      membersCount: computed,
      activeCommunityId: computed,
      activeTabMembersCount: computed,
      setActiveScreenId: action,
      setNextScreenId: action,
      reset: action,
    });

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );

    this.updateSelectedRolesDisposer = reaction(
      () => this.communityStore.members.data?.valuesArray.slice(),
      (communityMembers) => {
        this.updateSelectedRolesByUser(communityMembers || []);
      }
    );

    this.updateMembersListOnSwitchTabDisposer = reaction(
      () => this.activeTabIndex,
      (activeTabIndex) => {
        if (activeTabIndex === 0) {
          this.communityStore.getCommunityMembers(
            {
              communityId: this.activeCommunityId,
            },
            { noCache: true }
          );
        }

        if (activeTabIndex === 1) {
          this.communityStore.getCommunityBlockedMembers(
            {
              communityId: this.activeCommunityId,
            },
            { noCache: true }
          );
        }
      }
    );

    this.memberFilterDisposer = reaction(
      () => this.memberFilter,
      (memberFilter) => {
        this.communityStore.getCommunityMembers({
          communityId: this.activeCommunityId,
          search: memberFilter,
        });
      }
    );

    this.blockedMemberFilterDisposer = reaction(
      () => this.blockedMemberFilter,
      (blockedMemberFilter) => {
        this.communityStore.getCommunityBlockedMembers({
          communityId: this.activeCommunityId,
          search: blockedMemberFilter,
        });
      }
    );
  }

  get activeTabMembersCount() {
    const { members, blockedMembers } = this.communityStore;

    if (this.activeTabIndex === 1) {
      return blockedMembers?.meta?.totalCount;
    }

    return members?.meta?.totalCount;
  }

  updateSelectedRolesByUser(communityMembers: UserModel[]) {
    runInAction(() => {
      const selectedIds = communityMembers.reduce(
        (acc: { [key: string]: IRole[] }, member: UserModel) => {
          acc[member.id] = (member.roles || []).filter((userRole) =>
            this.communityStore.rolesList.some(
              (role) => role.id === userRole.id
            )
          );
          return acc;
        },
        {}
      );

      this.selectedRolesByUser = selectedIds;
    });
  }

  private getRoleById = (roleId: string) => {
    const role = this.communityStore.rolesList.find(
      (role) => role.id === roleId
    );

    return role;
  };

  addRoleToUser(userId: string, roleId: string, persistInAction?: boolean) {
    runInAction(() => {
      if (!this.selectedRolesByUser[userId]) {
        this.selectedRolesByUser[userId] = [];
      }

      // Add to the local state
      if (
        !this.selectedRolesByUser[userId].some((role) => role.id === roleId)
      ) {
        const roleObj = this.getRoleById(roleId);

        if (roleObj) {
          this.selectedRolesByUser[userId].push(roleObj);
        }

        if (persistInAction) {
          this.communityStore.addCommunityRolesToMember(
            this.activeCommunityId,
            [roleId],
            userId,
            () => this.rollbackRoleMovement(userId, [roleId], 'add')
          );
        } else {
          // Mark the roller as an addition of addition
          if (!this.rolesToAdd[userId]) {
            this.rolesToAdd[userId] = [];
          }
          this.rolesToAdd[userId].push(roleId);

          // Removes the roll of those to be removed if there is
          if (this.rolesToRemove[userId]) {
            this.rolesToRemove[userId] = this.rolesToRemove[userId].filter(
              (r) => r !== roleId
            );
          }
        }
      }
    });
  }

  // Removes a roller from the user locally (but still does not send to Backend)
  removeRoleFromUser(
    userId: string,
    roleId: string,
    persistInAction?: boolean
  ) {
    runInAction(() => {
      if (this.selectedRolesByUser[userId]) {
        this.selectedRolesByUser[userId] = this.selectedRolesByUser[
          userId
        ].filter((role) => role.id !== roleId);

        if (persistInAction) {
          this.communityStore.removeCommunityRolesToMember(
            this.activeCommunityId,
            [roleId],
            userId,
            () => this.rollbackRoleMovement(userId, [roleId], 'remove')
          );
        } else {
          // Marks the roll as a removal pendant
          if (!this.rolesToRemove[userId]) {
            this.rolesToRemove[userId] = [];
          }
          this.rolesToRemove[userId].push(roleId);

          // removes the roll of those to be added, if existing
          if (this.rolesToAdd[userId]) {
            this.rolesToAdd[userId] = this.rolesToAdd[userId].filter(
              (r) => r !== roleId
            );
          }
        }
      }
    });
  }

  private rollbackRoleMovement(
    userId: string,
    roleIds: string[],
    /**
     * If "originalAction" is "add", it means that the action of adding the roles to the user failed
     * and should be removed from the "selectedRolesByUser" list and vice versa in relation to "originalAction" "remove"
     */
    originalAction: 'add' | 'remove'
  ) {
    runInAction(() => {
      if (!this.selectedRolesByUser[userId]) {
        this.selectedRolesByUser[userId] = [];
      }

      if (originalAction === 'add') {
        this.selectedRolesByUser[userId] = this.selectedRolesByUser[
          userId
        ].filter((role) => !roleIds.includes(role.id));
      } else if (originalAction === 'remove') {
        const roleObjs = roleIds.map((roleId) => this.getRoleById(roleId)!);

        this.selectedRolesByUser[userId] = [
          ...this.selectedRolesByUser[userId],
          ...roleObjs,
        ];
      }
    });
  }

  // Function to save pending role changes
  async saveRoleChanges(userId: string) {
    try {
      const rolesToAdd = this.rolesToAdd[userId] || [];
      const rolesToRemove = this.rolesToRemove[userId] || [];

      if (rolesToAdd.length > 0) {
        await this.communityStore.addCommunityRolesToMember(
          this.activeCommunityId,
          rolesToAdd,
          userId,
          () => this.rollbackRoleMovement(userId, rolesToAdd, 'add')
        );
      }
      if (rolesToRemove.length > 0) {
        await this.communityStore.removeCommunityRolesToMember(
          this.activeCommunityId,
          rolesToRemove,
          userId,
          () => this.rollbackRoleMovement(userId, rolesToRemove, 'remove')
        );
      }

      // Clean the outstanding roles after success
      runInAction(() => {
        this.rolesToAdd[userId] = [];
        this.rolesToRemove[userId] = [];
      });
    } catch (error) {
      console.error('Error', error);
    }
  }

  // Function to cancel pending role changes
  cancelRoleChanges(userId: string) {
    runInAction(() => {
      // Reverses local changes
      this.rolesToAdd[userId] = [];
      this.rolesToRemove[userId] = [];

      // Recharges the original rollers from community member
      const member = this.communityStore.members.data?.registry[userId];

      if (member) {
        this.selectedRolesByUser[userId] = [...member.roles];
      }
    });
  }

  get membersBlockedCount() {
    return this.communityStore.blockedMembers?.meta?.totalCount || 0;
  }

  get membersCount() {
    return this.communityStore.members?.meta?.totalCount || 0;
  }

  get activeCommunityId() {
    const communityId =
      this.communityStore.activeCommunity?.data?.serverData.id;
    return communityId || '';
  }

  setActiveTabIndex = (tabIndex: number) => {
    this.activeTabIndex = tabIndex;
  };

  setMemberFilter = (value: string) => {
    this.memberFilter = value;
  };

  setBlockedMemberFilter = (value: string) => {
    this.blockedMemberFilter = value;
  };

  unBanMember = async (userId: string) => {
    if (!this.activeCommunityId) return;

    await this.communityStore.unblockUser(this.activeCommunityId, userId);
  };

  // Ban user - Begin
  confirmBanMember = async () => {
    this.setUserToBan(null);
  };
  cancelBanMember = async () => {
    if (this.userToBan && this.activeCommunityId) {
      await this.communityStore.unblockUser(
        this.activeCommunityId,
        this.userToBan.id
      );
    }

    this.setUserToBan(null);
  };
  setUserToBan = async (user: User | null) => {
    this.userToBan = user;

    const communityId = this.activeCommunityId;
    const userId = this.userToBan?.id;

    if (!userId || !communityId) {
      return;
    }

    await this.communityStore.blockUser(communityId, userId);
  };
  // Ban user - End

  dispose = () => {
    this.showActionReactionDisposer();
    this.updateSelectedRolesDisposer();
    this.updateMembersListOnSwitchTabDisposer();
    this.memberFilterDisposer();
    this.blockedMemberFilterDisposer();
  };
}

export interface IUsersStoreLocal
  extends ISidebarModalChildScreen<ScreenIdsValuesType> {
  readonly userToBan: User | null;
  readonly activeTabIndex: number;
  readonly selectedRolesByUser: { [key: string]: IRole[] };
  readonly memberFilter: string;
  readonly blockedMemberFilter: string;
  readonly activeTabMembersCount: number;
  readonly activeCommunityId: string;
  readonly membersBlockedCount: number;
  readonly membersCount: number;

  setUserToBan: (user: User | null) => Promise<void>;
  confirmBanMember: () => Promise<void>;
  cancelBanMember: () => void;

  unBanMember: (userId: string) => Promise<void>;

  addRoleToUser(
    userId: string,
    roleId: string,
    persistInAction?: boolean
  ): void;
  removeRoleFromUser(
    userId: string,
    roleId: string,
    persistInAction?: boolean
  ): void;
  saveRoleChanges(userId: string): Promise<void>;
  cancelRoleChanges(userId: string): void;

  setMemberFilter(value: string): void;
  setBlockedMemberFilter(value: string): void;

  setActiveTabIndex: (tabIndex: number) => void;
}
