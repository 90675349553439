import { Modal as FNDModal } from '@10x/foundation/src/components/modal/Modal';
import { classNames } from '@foundationPathAlias/utilities';

import { useSidebarModalContext } from './data/SidebarModalContext';

import { ISidebarModal } from './data';

type Props = {
  /** it provided ReactNode must be a fragment with SidebarModalPositioner with children SidebarModalAnimated Stack and ChangesAlert and also it should contain the BottomSheet that the same level as SidebarModal positioner */
  children:
    | ((sidebarModalCtx: ISidebarModal) => React.ReactNode)
    | React.ReactNode;
  /** a modal root classname */
  cn?: string;
  /** modal content wrapper div classname */
  contentWrapperCn?: string;
  /** modal content div classname */
  contentCn?: string;
  /** div wrapper inside the modal for the animatedStack classname */
  animatedStackWrapperCn?: string;
  isMobile: boolean;
  onClose?: () => void;
};

export const SidebarModal = (props: Props) => {
  const {
    children,
    isMobile,
    onClose,
    cn,
    contentWrapperCn,
    contentCn,
    animatedStackWrapperCn,
  } = props;

  const sidebarModalCtx = useSidebarModalContext();

  const rootCnInner = 'w-full xs:justify-normal md:justify-center';
  const contentWrapperCnInner =
    'xs:p-0 sx:p-0 xs:justify-normal xs:w-full md:w-screen z-8';
  const contentCnInner =
    'flex overflow-hidden h-full xs:w-full md:w-auto md:max-w-[820px] md:w-full md:mx-auto';

  return (
    <FNDModal
      rootInnerWrapperCn={classNames(rootCnInner, cn)}
      contentWrapperCn={classNames(contentWrapperCnInner, contentWrapperCn)}
      contentCn={classNames(contentCnInner, contentCn)}
      open={Boolean(sidebarModalCtx?.state?.show)}
      onClose={onClose}
    >
      <div
        className={classNames(
          'relative flex h-[100%] w-full flex-col ',
          isMobile ? 'h-full' : 'justify-center overflow-hidden',
          animatedStackWrapperCn
        )}
      >
        {typeof children === 'function' ? children(sidebarModalCtx) : children}
      </div>
    </FNDModal>
  );
};
