import { ChannelPayload } from '@10x/foundation/types';
import { DeleteModalContent } from '@mainApp/src/components/common/interactive-modal-content';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { useDeleteChannel } from '@mainApp/src/hooks';

type Props = {
  onClose?: () => void;
  onCancel?: () => void;
  onProceed?: (isError: boolean) => void;
  channelServerData: ChannelPayload;
};

export function _DeleteModaChannelContent(props: Props) {
  const {
    onClose,
    onCancel,
    onProceed: onProceedOuter,
    channelServerData,
  } = props;
  const interactiveStore = useInjection(IOC_TOKENS.interactiveStore);

  const { isLoading, deleteChannel } = useDeleteChannel(channelServerData);

  const { t } = useTranslation(['common', 'channel']);
  const delChannelTitle = t('channel:deleteChannel');

  const onDeleteCancel: () => void = () => {
    interactiveStore.setInteractiveElementOpen(false);
    onCancel?.();
  };

  const onProceed = async () => {
    const isError = await deleteChannel();
    if (!isError) {
      interactiveStore.setInteractiveElementOpen(false);
    }
    onProceedOuter?.(isError);
  };

  return (
    <DeleteModalContent
      title={delChannelTitle}
      onClose={() => {
        interactiveStore.setInteractiveElementOpen(false);
        onClose?.();
      }}
      actionsPanelData={{
        cancelAction: onDeleteCancel,
        proceedAction: onProceed,
        getCancelActionText: () => t('common:cancel'),
        getProceedActionText: () => t('common:delete'),
      }}
      ActionsPanelProps={{
        isLoading: isLoading,
      }}
    >
      <p className="themed-text-secondary text-left text-body16M">
        {t('channel:channelSettings.deleteChannelWarning')}
      </p>
    </DeleteModalContent>
  );
}

export const DeleteModaChannelContent = observer(_DeleteModaChannelContent);
