import { createContext, useEffect, useLayoutEffect, useRef } from 'react';

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export type VirtualizerContextType = {
  virtualizer: any;
  holdScroll: boolean;
  setVirtualizer: (virtualizer: any) => void;
  setHoldScroll: (holdScroll: boolean) => void;
};

export const ChannelCtx = createContext<VirtualizerContextType>({
  virtualizer: null,
  holdScroll: true,
  setVirtualizer: (_virtualizer) => null,
  setHoldScroll: (_holdScroll) => null,
});

export const ChannelProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const stateRef = useRef({
    virtualizer: null,
    holdScroll: true,
  });
  const { children } = props;
  return (
    <ChannelCtx.Provider
      value={{
        ...stateRef.current,
        setVirtualizer: (virtualizer) => {
          stateRef.current.virtualizer = virtualizer;
        },

        setHoldScroll: (holdScroll) => {
          stateRef.current.holdScroll = holdScroll;
        },
      }}
    >
      {children}
    </ChannelCtx.Provider>
  );
};
