import {
  CHANNEL_GROUP_MARK_ALL_CHANNELS_AS_READ,
  CREATE_CHANNEL,
  CREATE_CHANNEL_GROUP,
  GET_CHANNELS,
  GET_CHANNEL_BY_SLUG,
  GET_CHANNEL_GROUPS,
  REMOVE_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_CHANNELS_ORDER_IN_GROUP,
  UPDATE_CHANNEL_GROUPS_ORDER,
} from '../graphql/queries/index';

import { BaseRepositoryResponse, Client } from './types';

import {
  GetChannelsQueyParamsType,
  IChannelRepository,
} from './Channel.repository.types';

import {
  ChannelCreateInput,
  ChannelGroupCreateInput,
  ChannelPayload,
  ChannelUpdateInput,
} from '@10x/foundation/types';
import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import { GraphQLSubscription } from './GraphQLSubscription';

export * from './Channel.repository.types';

@injectable()
export class ChannelRepository implements IChannelRepository {
  gqlClient;

  // TODO: injection
  graphQLSubscription;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;

    // TODO: injection
    this.graphQLSubscription = new GraphQLSubscription();
  }

  // TODO: I'm not sure if force request without cache is the best solution
  // but I didn't find any other option
  async getChannelGroups(communityId: string, noCache?: boolean) {
    const response = await this.gqlClient
      .query(
        GET_CHANNEL_GROUPS,
        {
          communityId,
        },
        noCache
          ? {
              requestPolicy: 'network-only',
              fetchOptions: { cache: 'no-cache' },
            }
          : undefined
      )
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data?.channelGroups?.edges,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async createChannelGroup(
    communityId: string,
    payload: ChannelGroupCreateInput
  ) {
    const response = await this.gqlClient
      .mutation(CREATE_CHANNEL_GROUP, {
        communityId,
        data: payload,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.createChannelGroup,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  async removeChannelGroup(communityId: string, id: string) {
    const response = await this.gqlClient
      .mutation(CREATE_CHANNEL_GROUP, {
        communityId,
        id,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.removeChannelGroup,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getChannels({ noCache, ...params }: GetChannelsQueyParamsType) {
    const response = await this.gqlClient
      .query(
        GET_CHANNELS,
        params,
        noCache
          ? {
              requestPolicy: 'network-only',
              fetchOptions: { cache: 'no-cache' },
            }
          : undefined
      )
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.channels,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  }

  async createChannel(
    communityId: string,
    channelGroupId: string,
    dataContent: ChannelCreateInput
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    const response = await this.gqlClient
      .mutation(CREATE_CHANNEL, {
        communityId,
        channelGroupId,
        data: dataContent,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.createChannel,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  }
  async updateChannel(
    communityId: string,
    id: string,
    payload: ChannelUpdateInput
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    const response = await this.gqlClient
      .mutation(UPDATE_CHANNEL, {
        communityId,
        id,
        data: payload,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.updateChannel,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  }
  async removeChannel(
    communityId: string,
    channelGroupId: string,
    id: string
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    const response = await this.gqlClient
      .mutation(REMOVE_CHANNEL, {
        communityId,
        channelGroupId,
        id: id,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.removeChannel,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  }

  async channelGroupMarkAllChannelsAsRead(
    communityId: string,
    groupId: string
  ): Promise<BaseRepositoryResponse<boolean>> {
    const response = await this.gqlClient
      .mutation(CHANNEL_GROUP_MARK_ALL_CHANNELS_AS_READ, {
        communityId,
        id: groupId,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.channelGroupMarkAllChannelsAsRead,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getChannelBySlug(communityId: string, slug: string) {
    const response = await this.gqlClient
      .query(GET_CHANNEL_BY_SLUG, {
        communityId,
        slug,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data?.channelBySlug,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async updateChannelsOrderInGroup(
    communityId: string,
    channelGroupId: string,
    channelIds: string[]
  ): Promise<BaseRepositoryResponse<boolean>> {
    const response = await this.gqlClient
      .mutation(UPDATE_CHANNELS_ORDER_IN_GROUP, {
        communityId,
        channelGroupId,
        ids: channelIds,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.updateChannelsOrder,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  async updateChannelGroupsOrder(
    communityId: string,
    ids: string[]
  ): Promise<BaseRepositoryResponse<boolean>> {
    const response = await this.gqlClient
      .mutation(UPDATE_CHANNEL_GROUPS_ORDER, {
        communityId,
        ids,
      })
      .toPromise();

    const { data, error } = response;
    const res = {
      data: data?.updateChannelGroupsOrder,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
}
