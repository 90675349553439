export const CommunitySVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 28, height = 18, color = 'currentColor', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05078 15.75C5.32286 15.75 2.30078 12.7279 2.30078 9C2.30078 5.27208 5.32286 2.25 9.05078 2.25C10.1929 2.25 11.2688 2.53367 12.2119 3.03441C10.805 4.62251 9.95082 6.7115 9.95082 9C9.95082 11.2885 10.805 13.3775 12.2119 14.9656C11.2688 15.4663 10.1929 15.75 9.05078 15.75ZM14.0008 16.5177C12.5807 17.4546 10.8794 18 9.05078 18C4.08022 18 0.0507812 13.9706 0.0507812 9C0.0507812 4.02944 4.08022 0 9.05078 0C10.8794 0 12.5807 0.54537 14.0008 1.48233C15.4209 0.54537 17.1222 0 18.9508 0C23.9214 0 27.9508 4.02944 27.9508 9C27.9508 13.9706 23.9214 18 18.9508 18C17.1222 18 15.4209 17.4546 14.0008 16.5177ZM15.7897 3.03441C16.7328 2.53367 17.8087 2.25 18.9508 2.25C22.6787 2.25 25.7008 5.27208 25.7008 9C25.7008 12.7279 22.6787 15.75 18.9508 15.75C17.8087 15.75 16.7328 15.4663 15.7897 14.9656C17.1966 13.3775 18.0508 11.2885 18.0508 9C18.0508 6.7115 17.1966 4.62251 15.7897 3.03441ZM14.0008 4.41086C15.1178 5.61518 15.8008 7.22784 15.8008 9C15.8008 10.7722 15.1178 12.3848 14.0008 13.5891C12.8838 12.3848 12.2008 10.7722 12.2008 9C12.2008 7.22784 12.8838 5.61518 14.0008 4.41086Z"
      fill={color}
    />
  </svg>
);
