import { makeAutoObservable } from 'mobx';
// TODO: Implement an onboarding model

import {
  Channel_Group_Types,
  User,
} from '@10x/foundation/types/graphql-schema';

const channelGroupMock = {};

import { ChannelModel } from './Channel.model';

export class ChannelGroupModel {
  id = '';
  // client
  type__client: 'grouped' | 'ungrouped' = 'ungrouped';

  name = '';
  order = 0;
  communityId = '';
  defaulted = false;
  private = false;
  authorId = '';
  createdAt = 0;
  updatedAt = 0;
  channelGroupType: Channel_Group_Types;

  author: Partial<User> = {};

  // should be type of Channel
  channels: any[] = [];

  static generateMockedChannelGroupModel() {
    return new ChannelGroupModel({ ...channelGroupMock });
  }

  constructor(data: any) {
    makeAutoObservable(this);

    this.id = data.id;
    this.name = data.name;
    this.defaulted = data.defaulted;
    this.communityId = data.communityId;
    this.private = data.private;
    this.authorId = data.authorId;
    this.author = data.author;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.channelGroupType = data.channelGroupType;

    if (data.channelGroupType === Channel_Group_Types.Group) {
      this.type__client = 'grouped';
    }
  }

  setChannels = (graphqlData: any, ChannelModelClass: typeof ChannelModel) => {
    const channelEdges = graphqlData.edges;
    if (channelEdges.length) {
      this.channels = channelEdges.map(
        (channelEdge: any) => new ChannelModelClass(channelEdge.node)
      );
    }

    return this;
  };

  // for the temporary storing in localStorage
  get asJson() {
    return {
      id: this.id,
      name: this.name,
      type__client: this.type__client,
      order: this.order,
      communityId: this.communityId,
      defaulted: this.defaulted,
      private: this.private,
      authorId: this.authorId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      channelGroupType: this.channelGroupType,

      channels: this.channels.map((channel) => channel.asJson),
    };
  }

  // for BE integration - create community
  get backendPayload() {
    return {};
  }
}
