import { classNames } from '@foundationPathAlias/utilities';
import { ReactNode } from 'react';

export type ChipProps = {
  label?: string;
  iconSrc?: string;
  iconAlt?: string;
  children?: ReactNode;
  cn?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const RoleChip = ({
  label,
  children,
  iconSrc,
  iconAlt,
  cn,
  onClick,
}: ChipProps) => {
  return (
    <div
      className={classNames(
        'inline-flex min-h-[32px] w-auto min-w-[32px] flex-shrink-0 cursor-default items-center justify-center rounded-full px-[8px] py-[2px]',
        'bg-background-secondary dark:bg-background-quaternary-dark',
        'themed-text',
        iconSrc && 'gap-[2px] pl-[4px] pr-[10px]',
        cn
      )}
      onClick={onClick}
    >
      {children || (
        <>
          {iconSrc && (
            <img
              src={iconSrc}
              alt={iconAlt ?? `Role chip ${label}`}
              width={28}
              height={28}
            />
          )}

          <span className="text-sm14R text-current">{label}</span>
        </>
      )}
    </div>
  );
};
