import { ProSection1 } from './ProSection1';

import dynamic from 'next/dynamic';

const ProSection2 = dynamic(
  () => import('./section-2/ProSection2').then((module) => module.ProSection2),
  { ssr: false }
);
const ProSection3 = dynamic(
  () => import('./section-3/ProSection3').then((module) => module.ProSection3),
  { ssr: false }
);
const ReadyToStartBlock = dynamic(
  () =>
    import('./ReadyToStartBlock').then((module) => module.ReadyToStartBlock),
  { ssr: false }
);

export function Pro() {
  return (
    <div className="bg-background-primary dark:bg-background-website-dark">
      <ProSection1 />
      <ProSection2 />
      <ProSection3 />
      <ReadyToStartBlock />
    </div>
  );
}
