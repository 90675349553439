import { action, computed, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { CommunityStepperModel, initialColor } from './CommunityStepper.model';
import { ColorType } from './CreateCommunityStepper.types';

import { IStepBase } from './StepBase';

import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { StepBase } from './StepBase';

enableStaticRendering(typeof window === 'undefined');

export class Step5Store extends StepBase implements IStep5Store {
  id = 5;

  // should be used as a fallback at the initial part
  // when normal colors hadn't been setup (eg step 1 at the beiginnig)
  initialPrimaryColor: ColorType = { ...initialColor };

  get lmPrimaryColor() {
    return this.communityStepperModel.lmPrimaryColor;
  }
  get dmPrimaryColor() {
    return this.communityStepperModel.dmPrimaryColor;
  }

  get canMoveNext() {
    return (
      Boolean(this.communityStepperModel.lmPrimaryColor) &&
      Boolean(this.communityStepperModel.dmPrimaryColor)
    );
  }

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    super(repository, communityStepperModel);

    makeObservable(this, {
      setColor: action,
      setLmPrimaryBlack: action,
      setDmPrimaryWhite: action,
      reset: action,
      canMoveNext: computed,
      lmPrimaryColor: computed,
      dmPrimaryColor: computed,
    });
  }

  setColor = (type: 'dm' | 'lm', val: ColorType) => {
    this.resetMoveBackError();
    this.communityStepperModel.setColor(type, val);
  };

  setLmPrimaryBlack = () => {
    this.resetMoveBackError();
    this.communityStepperModel.setDefaultContrastColors('lm');
  };

  setDmPrimaryWhite = () => {
    this.resetMoveBackError();
    this.communityStepperModel.setDefaultContrastColors('dm');
  };

  initDefaultColors = () => {
    if (!this.lmPrimaryColor) {
      this.communityStepperModel.setColor('lm', initialColor);
    }

    if (!this.dmPrimaryColor) {
      this.communityStepperModel.setColor('dm', initialColor);
    }
  };

  reset = () => {
    this.communityStepperModel.resetColors();
  };
}

export interface IStep5Store extends IStepBase {
  /**
   * null at the beginning and it's useful to not show the
   * ready token at the 1 step because lmPrimaryColor has the
   * default values but they should be set only when the
   * moving to the step 1
   */
  readonly lmPrimaryColor: ColorType | null;
  readonly dmPrimaryColor: ColorType | null;
  /**
   * a default initial color that doesn't trigger a
   *
   * */
  readonly initialPrimaryColor: ColorType;
  setColor: (type: 'dm' | 'lm', value: ColorType) => void;
  // useful when the user wants to automatically set the dark color for lm
  setLmPrimaryBlack(): void;
  // useful when the user wants to automatically set the white color for lm
  setDmPrimaryWhite(): void;
  // should be called when entering the 5 step to set the default values
  initDefaultColors(): void;
}
