export type RGBObject = {
  r: number,
  g: number,
  b: number
}


export type RGBAObject = RGBObject & {
  a: number;
}

export type HSLObject = {
  h: number,
  s: number,
  l: number
}

const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st: string, chunkSize: number) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr: string) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a: number, alpha: number) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const hexToRGBA = (hex: string, alpha: number) => {
  if (!isValidHex(hex)) {
    return null;
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr!.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};

export const hexToRGBValuesObject = (hex: string) => {
  if (!isValidHex(hex)) {
    return null;
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b] = hexArr!.map(convertHexUnitTo256);
  return {
    r,
    g,
    b,
  };
};


export const rgbaToValuesObject = (rgba: string) => {
  const regexp = /rgba\((.*)\)/;
  const res = rgba.match(regexp);
  if (!res) {
    throw new Error('incorrect css rgba string');
  }
  const [r, g, b, a] = res[1].split(',');
  return {
    r: Number(r),
    g: Number(g),
    b: Number(b),
    a: Number(a),
  };
};

export function getLuminance(rgb: RGBObject) {
  const { r, g, b } = rgb;
  const [lumR, lumG, lumB] = [r, g, b].map((component) => {
    let proportion = component / 255;
    let res;
    if (proportion <= 0.03928) {
      res = proportion / 12.92;
    } else {
      res = Math.pow((proportion + 0.055) / 1.055, 2.4);
    }
    return res;
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
}

export function getIsLowContrastColor(
  rgb: RGBObject,
  type: 'dm' | 'lm'
) {
  const white = 1;
  const black = 0;

  let res;

  const luminance = getLuminance(rgb);

  if (type === 'dm') {
    res = luminance - black < 0.05;
  } else {
    res = white - luminance < 0.3;
  }

  return res;
}

export function getRgbaString(
  rgba: RGBAObject,
  alphaFactor: number = 1
) {
  if (!rgba) {
    throw new Error('rgba object is not defined')
  };
  const { r, g, b, a = 1 } = rgba;
  return `rgba(${r}, ${g}, ${b}, ${a * alphaFactor})`;
}

export function RGBToHex(rgb: RGBObject
): string {
  const { r, g, b } = rgb;

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
}

/**
 * return an rgb object hovewer it isn't so precisiou as the hsl string one.
 * Use it only if you really want to avoid hsl usage
 * @param rgb { RBGObject } an object that contains rbg values
 * @param amount { Integer } luminance amount. Can be positive or negative.
 * it will be added to the resulted rgb value
 * @returns 
 */
export function adjustRGBLuminance(rgb: RGBObject, amount: number) {
  let { r, g, b } = rgb;

  let isSubstraction = amount < 0;

  r = Math.max(0, (r + amount) + (isSubstraction ? -20 : 20));
  g = Math.max(0, (g + amount) - (isSubstraction ? 21 : -21));
  b = Math.max(0, (b + amount) + (isSubstraction ? 5 : -5));

  // Ensure RGB values stay within the valid range
  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  return {
    r,
    g,
    b
  }
}


export function rgbToHsl(rgb: RGBObject): HSLObject {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  const l = (max + min) / 2;

  let s;
  if (max === min) {
    s = 0;
  } else {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
  }

  // Calculating hue
  let h = 0;
  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = (60 * ((g - b) / (max - min))) % 360;
  } else if (max === g) {
    h = 60 * ((b - r) / (max - min)) + 120;
  } else if (max === b) {
    h = 60 * ((r - g) / (max - min)) + 240;
  }
  return {
    h: Math.round(h),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

// the most precisious variant
export function adjustLuminanceRgbToHslStr(rgb: RGBObject, adjustLuminance: number) {
  const { h, s, l } = rgbToHsl(rgb);
  return `hsl(${Math.round(h)}, ${s}%, ${l + adjustLuminance}%)`;
}
// the most precisious variant
export function adjustLuminanceRgb(rgb: RGBObject, adjustLuminance: number) {
  const { h, s, l } = rgbToHsl(rgb);

  const payload = {
    h, s, l: l + adjustLuminance
  }
  return hslToRgb(payload);
}



export function hslToRgb(hsl: HSLObject) {
  const { h, s, l } = hsl;

  const hue = h / 360;
  const saturation = s / 100;
  const lightness = l / 100;

  let r, g, b;

  if (saturation === 0) {
    r = g = b = lightness;
  } else {


    const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
    const p = 2 * lightness - q;

    r = hueToRgb(p, q, hue + 1 / 3);
    g = hueToRgb(p, q, hue);
    b = hueToRgb(p, q, hue - 1 / 3);
  }
  const rgbHex = `#${toHex(r, true)}${toHex(g, true)}${toHex(b, true)}`;

  return rgbHex.toUpperCase();
}

const hueToRgb = (p: number, q: number, t: number) => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
};

// Convert RGB to HEX
const toHex = (x: number, hslVersion = false) => {
  const hex = hslVersion ? Math.round(x * 255).toString(16) : Math.max(0, Math.min(255, x)).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};
