import { Modal as FNDModal } from '@10x/foundation/src/components/modal/Modal';
import { ChangesAlert } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { componentSwitch } from '@mainApp/src/components/common/components-switch';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { StepScreen } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AddMembers } from './AddMembers';
import { CreateChannelForm } from './CreateChannelForm';
import { HeaderModal } from './HeaderModal';
import { MembersView } from './MembersView';

export const _CreateChannelModal = () => {
  const {
    createChannelStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.createChannelStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['channel']);

  const rootCn = 'w-full xs:justify-normal md:justify-center';
  const contentWrapperCn =
    'xs:p-0 sx:p-0 xs:justify-normal xs:w-full md:w-screen z-8';
  const contentCn =
    'flex overflow-hidden h-full xs:w-full md:w-auto md:max-w-[620px] md:w-full md:mx-auto';

  const content = componentSwitch(createChannelStore.stepScreen, {
    create: <CreateChannelForm />,
    addMembers: <AddMembers />,
    membersView: <MembersView />,
    default: <CreateChannelForm />,
  });

  let headerTitle: Record<StepScreen, string> = {
    addMembers: t('channel:modal.addMembers'),
    create: t<string>('channel:modal.createChannel'),
    membersView: t('channel:modal.roleMembers', {
      roleName: createChannelStore.selectedRole?.name,
    }),
  };

  if (createChannelStore.mode === 'group') {
    headerTitle = {
      addMembers: t('channel:modal.addMembers'),
      create: t<string>('channel:modal.createChannelGroup'),
      membersView: t('channel:modal.roleMembers', {
        roleName: createChannelStore.selectedRole?.name,
      }),
    };
  }

  const handleBack = () => {
    switch (createChannelStore.stepScreen) {
      case 'addMembers':
        createChannelStore.setStepScreen('create');
        break;
      case 'membersView':
        createChannelStore.setStepScreen('addMembers');
        break;
      default:
        break;
    }
  };

  return (
    <FNDModal
      rootInnerWrapperCn={rootCn}
      contentWrapperCn={contentWrapperCn}
      contentCn={contentCn}
      open={createChannelStore.active}
      onClose={createChannelStore.requestCloseModal}
    >
      <div
        className={classNames(
          'relative flex w-full flex-col bg-background-primary dark:bg-background-primary-dark',
          isMobile
            ? 'h-full'
            : 'overflow-hidden md:mt-10 md:h-min md:rounded-lg'
        )}
      >
        <HeaderModal
          onBack={handleBack}
          title={headerTitle[createChannelStore.stepScreen]}
          onClose={createChannelStore.requestCloseModal}
          showBackButton={createChannelStore.stepScreen !== 'create'}
        />

        {content}

        <ChangesAlert
          contentWrapperCn="border-[1px]"
          title={t('channel:modal.discardChangesTitle')}
          description={t('channel:modal.discardChangesDescription')}
          descriptionCn="text-left"
          show={createChannelStore.discardAlertIsActive}
          onFirstBtnClick={() => {
            createChannelStore.setDiscardAlertIsActive(false);
          }}
          onSecondBtnClick={createChannelStore.hide}
          firstBtnText={t<string>('channel:modal.keepEditing')}
          secondBtnText={t<string>('channel:modal.closeAndDiscard')}
        />
      </div>
    </FNDModal>
  );
};

export const CreateChannelModal = observer(_CreateChannelModal);
