import { classNames } from '@foundationPathAlias/utilities';
import { XMarkIcon } from '@heroicons/react/24/outline';

type CloseIconBtnProps = {
  onClick?: () => void;
  className?: string;
};

export const CloseIconBtn = ({ onClick, className }: CloseIconBtnProps) => (
  <XMarkIcon
    role="button"
    onClick={onClick}
    className={classNames(
      'animation-classic min-h-[16px] min-w-[16px] text-color-6 hover:text-hovered-selected active:text-pressed disabled:cursor-not-allowed dark:hover:text-hovered-selected-dark dark:active:text-pressed-dark',
      className
    )}
  />
);
