import { Row } from '@foundationPathAlias/components/table';
import { DotsButton } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { observer } from 'mobx-react-lite';
import { GridItemValue, type GridItemValueProps } from './GridItemValue';
import {
  AddRolesTooltipContent,
  AddRolesTooltipWrapper,
  AvailableActionsEnum,
  MemberActionMenuContent,
} from './tooltip-contents';
import { UsersTableDisplayNameCell } from './UsersTableDisplayNameCell';
import { UsersTableRoleCell } from './UsersTableRoleCell';

type UsersTableCustomMobileRowProps = {
  row: Row<Partial<TableUser>>;
  gridItems: GridItemValueProps[];
  hideRolesSection?: boolean;
  availableActions: AvailableActionsEnum[];
};

const _UsersTableCustomMobileRow = ({
  row,
  gridItems = [],
  hideRolesSection,
  availableActions,
}: UsersTableCustomMobileRowProps) => {
  const { capStore } = useMultipleInjection([IOC_TOKENS.capStore]);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const closeBottomSheet = () => {
    capStore.setBottomSheetConfig({ show: false });
  };

  const handleClickOptions = () => {
    capStore.setBottomSheetConfig({
      content: (
        <MemberActionMenuContent
          row={row}
          onBan={() => {
            usersStoreLocal.setUserToBan(row.original as any);
            closeBottomSheet();
          }}
          onRoles={onRoles}
          onUnBan={() => {
            usersStoreLocal.unBanMember(row.original.id as string);
            closeBottomSheet();
          }}
          availableActions={availableActions}
        />
      ),
      show: true,
    });
  };

  const rolesTooltipContent = (
    <AddRolesTooltipWrapper
      onBack={handleClickOptions}
      onCancel={closeBottomSheet}
      onSave={closeBottomSheet}
    >
      <AddRolesTooltipContent row={row} />
    </AddRolesTooltipWrapper>
  );

  const onRoles = () => {
    capStore.setBottomSheetConfig({
      content: rolesTooltipContent,
      show: true,
    });
  };

  return (
    <tr className="border-b border-b-element-subtle">
      <div className="my-[16px] flex flex-col gap-[16px]">
        <div className="flex justify-between">
          <UsersTableDisplayNameCell userData={row.original} />

          <DotsButton onClick={handleClickOptions} type="vertical" />
        </div>

        {!hideRolesSection && (
          <UsersTableRoleCell row={row} hoveredRow={false} />
        )}

        <div className="grid grid-cols-2 gap-[16px]">
          {gridItems.map((item) => (
            <GridItemValue key={item.label} {...item} />
          ))}
        </div>
      </div>
    </tr>
  );
};

export const UsersTableCustomMobileRow = observer(_UsersTableCustomMobileRow);
