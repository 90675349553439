import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { CommunityStepperModel } from './CommunityStepper.model';

import { action, makeObservable, observable } from 'mobx';

export class StepBase implements IStepBase {
  id = 0;

  communityStepperModel: CommunityStepperModel;
  repository: IOnboardingRepositoryInterface;

  active = false;

  get canMoveNext() {
    return false;
  }

  // show an error when click on the previous step but the active isn't filled
  isShowMoveBackError = false;

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    this.repository = repository;
    this.communityStepperModel = communityStepperModel;

    makeObservable(this, {
      active: observable,
      isShowMoveBackError: observable,
      setActive: action,
      setShowMoveBackError: action,
      resetMoveBackError: action,
    });
  }

  setOnboardingModel(communityStepperModel: CommunityStepperModel) {
    this.communityStepperModel = communityStepperModel;
  }

  setActive = (active: boolean) => {
    this.active = active;
  };

  setShowMoveBackError = (show: boolean) => {
    this.isShowMoveBackError = show;
  };

  resetMoveBackError = () => {
    if (this.isShowMoveBackError) {
      this.setShowMoveBackError(false);
    }
  };

  reset = () => { };
}

export interface IStepBase {
  repository: IOnboardingRepositoryInterface;
  id: number;

  // is current step active
  active: boolean;

  communityStepperModel: CommunityStepperModel;

  // if true: user tries to go back when the current step isn't finished
  isShowMoveBackError: boolean;

  // if true - it's possible to move for the next step
  readonly canMoveNext: boolean;

  setOnboardingModel(communityStepperModel: CommunityStepperModel): void;
  setActive(active: boolean): void;
  setShowMoveBackError(show: boolean): void;

  // set's the initial value for the step's fields
  reset(): void;

  resetMoveBackError(): void;
}
