import { Avatar } from '@foundationPathAlias/components';
import { MessageModel } from '@mainApp/src/stores/Message.model';

export type Props = {
  messageModel: MessageModel;
  name?: string | null;
  handle?: string | null;
  imageUrls?: string[] | null;
};

export const UserCard = (props: Props) => {
  const { name, handle, imageUrls, messageModel } = props;
  return (
    <div className="mt-2 flex h-[52px] items-center ">
      <Avatar
        cn="rounded-[40px] object-cover md:rounded-[5px]"
        width={40}
        height={40}
        src={imageUrls ? messageModel.getCloudflareUrl(imageUrls) : undefined}
      />
      <div className="ml-3 flex flex-col">
        <span className="text-sm14T text-text-primary dark:text-white">
          {name}
        </span>
        <span className="text-sm13D text-text-secondary dark:text-gray-400 ">
          {handle}
        </span>
      </div>
    </div>
  );
};
