import { ScreenIds } from '../constants';
import { Roles } from '../Roles';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'Roles',
    Component: Roles,
  },
};
