import { gql } from '@urql/core';

export const OAUTH_EXCHANGE_CODE_MUTATION = gql`
  mutation OAuthExchangeCode($code: String!) {
    oAuthExchangeCode(code: $code) {
      accessToken
    }
  }
`;


export const SIGN_IN_VIA_EMAIL = gql`
  mutation SignInViaEmail($email: String!) {
    signInViaEmail(email: $email)
  }
`;

export const SIGN_UP_VIA_EMAIL = gql`
  mutation SignUpViaEmail($email: String!) {
    signUpViaEmail(email: $email)
  }
`;

export const VERIFY_SIGN_IN = gql`
  mutation VerifySignIn($otp: String!) {
    verifySignIn(otp: $otp) {
      token
		  userId
    }
  }
`;

export const VERIFY_SIGN_UP = gql`
  mutation VerifySignUp($otp: String!) {
    verifySignUp(otp: $otp) {
      token
		  userId
    }
  }
`;
