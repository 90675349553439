import { ProgressBarCircular } from '@foundationPathAlias/components/progress/progress-bar-circular';
import { ButtonBase } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { colorSchema } from '@rootConfig/color-schema';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FeedbackActionProps = {
  duration: number;
  label: string;
  cn?: string;
  onConfirmAction: () => void;
  onCancelAction: () => void;
};

export const FeedbackAction = ({
  duration,
  label,
  cn,
  onConfirmAction,
  onCancelAction,
}: FeedbackActionProps) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const increment = 100 / duration;
    let currentPercentage = 0;

    const interval = setInterval(() => {
      currentPercentage += increment;
      if (currentPercentage >= 100) {
        clearInterval(interval);
        onConfirmAction();
        setPercentage(100);
      } else {
        setPercentage(currentPercentage);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [duration]);

  return (
    <div
      className={classNames(
        'themed-text-inverted flex items-center justify-between gap-[8px] rounded-[10px] bg-[#1A1A1A] px-[12px] py-[7px] md:min-w-[340px]',
        cn
      )}
    >
      <div className="flex items-center gap-[8px]">
        <ProgressBarCircular
          cn="w-[20px] h-[20px]"
          progressPercentage={percentage}
          pathStroke={colorSchema['background-primary']}
          trailStroke={colorSchema['background-overlay-text-dark']}
        />

        <span className="text-sm14R">{label}</span>
      </div>

      <ButtonBase
        cn="p-0 w-auto text-sm14T"
        onClick={() => {
          onCancelAction();
        }}
      >
        {t('cap:users.clickToUndo')}
      </ButtonBase>
    </div>
  );
};
