import { validators } from '@foundationPathAlias/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepNamesEnum } from '../PostAuthOnboarding.store.types';
import { Input } from '../common';
import { StepsWrapper } from './StepsWrapper';

export function _DisplayName() {
  const { t } = useTranslation('common');
  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);

  const displayNameStep = postOnboardingStore.steps[StepNamesEnum.DISPLAY_NAME];
  const successMsgKey = displayNameStep.successMessage;

  const displayNameWord = t('displayName');
  const displayNameWorkLc = displayNameWord.toLowerCase();
  return (
    <StepsWrapper
      title={t('chooseDisplayName')}
      description={t('displayNameDescription')}
    >
      <Input
        getIsSuccess={(val, error) => {
          if (val === undefined) {
            return false;
          }
          return val.length > 0 && !error;
        }}
        maxLength={16}
        inputName={displayNameWord}
        placeholder={displayNameWord}
        specificValidators={[
          validators.getBeginSpaceValidator(
            t('errors.beginSpace', {
              fieldName: displayNameWorkLc,
            }) as string
          ),
          validators.getDoubleConsequentSpacesValidator(
            t('errors.doubleConsequentSpaces', {
              fieldName: displayNameWorkLc,
            }) as string
          ),
        ]}
        successMsg={t(successMsgKey)}
        value={displayNameStep.data.value}
        onChange={(val) => {
          postOnboardingStore.setStepData(StepNamesEnum.DISPLAY_NAME, {
            value: val,
          });
        }}
        onError={(error) => {
          postOnboardingStore.setStepData(
            StepNamesEnum.DISPLAY_NAME,
            displayNameStep.data,
            error
          );
        }}
      />
    </StepsWrapper>
  );
}

export const DisplayName = observer(_DisplayName);
