// default schema for the product but landind could use it as well
const PRIMARY_COLOR = '#5F5CFF';
const PRIMARY_COLOR_DARK = '#7B7AFF';
const colorSchema = {
  // common
  'common-color-0': '#30CFC0',
  'common-color-1': '#D964FF',
  'common-color-2': '#FF9E00',
  'common-color-3': '#5F5CFF',
  'common-color-4': '#56CCF1',
  'common-color-5': '#000000',
  'common-color-0-dark': '#35CFC1',
  'common-color-1-dark': '#DB6BFF',
  'common-color-2-dark': '#FFA005',
  'common-color-3-dark': '#6461FF',
  'common-color-4-dark': '#5ACDF1',

  'primary-100': PRIMARY_COLOR,
  'primary-80': PRIMARY_COLOR + '80',
  'primary-60': PRIMARY_COLOR + '60',
  'primary-40': PRIMARY_COLOR + '40',
  'primary-10': PRIMARY_COLOR + '10',
  'primary-hover': '#7875FF',
  'primary-pressed': '#4F4CF6',
  'primary-100-dark': PRIMARY_COLOR_DARK,
  'primary-60-dark': PRIMARY_COLOR_DARK + '60',
  'primary-80-dark': PRIMARY_COLOR_DARK + '80',
  'primary-40-dark': PRIMARY_COLOR_DARK + '40',
  'primary-10-dark': PRIMARY_COLOR_DARK + '10',
  'primary-hover-dark': '#8280F5',
  'primary-pressed-dark': '#5C5AE7',

  // text
  'text-primary': '#1A1A1A',
  // - #1A1A1A, 70%
  'text-secondary': 'rgba(26, 26, 26, 0.7)',
  // - #1A1A1A, 40%
  'text-disabled': 'rgba(26, 26, 26, 0.4)',
  // card UI members text color
  'text-secondary-1': '#536170',
  'text-placeholder': '#737373',
  // - #FFFFFF, 90%
  'text-primary-dark': 'rgba(255, 255, 255, 0.9)',
  // - #FFFFFF, 65%
  'text-secondary-dark': 'rgba(255, 255, 255, 0.65)',
  // - #1A1A1A, 40%
  'text-disabled-dark': 'rgba(255, 255, 255, 0.4)',
  'text-secondary-1-dark': '#AFC0D1',
  'text-placeholder-dark': '#919191',
  'text-tertiary': 'rgba(202, 213, 224, 1)',
  'text-tertiary-dark': 'rgba(50, 59, 67, 1)',

  // element
  'element-subtle': '#E6E6E6',
  'element-normal': '#B4B4B4',
  'element-success': '#178752',
  // TODO: the old one, wait for Agos answer and decide what to do
  // 'element-error': '#CD434B',
  'element-error': '#DB343E',
  'element-warning': '#D97C12',
  'element-warning-10': 'rgba(237, 159, 9, 0.1)',
  'element-info': '#1872EA',
  'element-disabled': '#CDCDCD',
  'element-subtle-dark': '#484848',
  'element-normal-dark': '#8C8C8C',
  'element-success-dark': '#41D9A2',
  'element-error-dark': '#FF636C',
  'element-warning-dark': '#FA9247',
  'element-warning-10-dark': 'rgba(250, 146, 71, 0.1)',
  'element-warning-dark-20': 'rgba(250, 188, 71, 0.2)',
  'element-info-dark': '#4B98FF',
  'element-disabled-dark': '#595959',
  'element-low-contrast': 'rgba(26, 26, 26, 0.9)',
  'element-low-contrast-dark': 'rgba(72, 72, 72, 0.9)',

  //background
  'background-primary': '#FFFFFF',
  'background-secondary': '#F7F7F7',
  'background-secondary-1': '#F4F7FB',
  'background-tetriary': '#EDEDED',
  'background-quaternary': '#EBEBEB',
  'background-hover': '#55555505',
  'background-pressed': '#55555510',
  // - #1A1A1A, 55%
  'background-overlay': 'rgba(26, 26, 26, 0.55)',
  // - #1A1A1A, 25%
  'background-overlay-subtle': 'rgba(26, 26, 26, 0.25)',
  'background-overlay-text': '#1D1D1D90',
  'background-primary-dark': '#0C0C0C',
  'background-secondary-dark': '#171717',
  'background-secondary-1-dark': '#081928',
  'background-tetriary-dark': '#242424',
  'background-quaternary-dark': '#303030',
  'background-hover-dark': '#FFFFFF07',
  'background-pressed-dark': '#FFFFFF14',
  // - #333333, 65%
  'background-overlay-dark': 'rgba(51, 51, 51, 0.65)',
  // - #333333, 35%
  'background-overlay-subtle-dark': 'rgba(51, 51, 51, 0.35)',
  'background-overlay-text-dark': '#4E4E4E90',
  'background-website-dark': 'rgba(8, 25, 40, 1)',
  'background-ultra-dim': 'rgba(95, 92, 255, 0.03)',
  'background-ultra-dim-dark': 'rgba(123, 122, 255, 0.08)',

  // states
  // - #1A1A1A, 5%
  'hovered-selected': 'rgba(26, 26, 26, 0.05)',
  // - #1A1A1A, 10%
  pressed: 'rgba(26, 26, 26, 0.10)',
  // - #FFFFFF, 6%
  'hovered-selected-dark': 'rgba(255, 255, 255, 0.06)',
  // - #FFFFFF, 10%
  'pressed-dark': 'rgba(255, 255, 255, 0.1)',
  'background-coloured-overlay': 'rgba(255, 158, 0, 0.05);',

  // gradient
  'text-gradient-start': '#FF9E00',
  'text-gradient-end': '#D964FF',
  'text-gradient-start-dark': '#FFA005',
  'text-gradient-end-dark': '#DB6BFF',
  'background-gradient-start': '#30C8CF',
  'background-gradient-middle': '#D964FF',
  'background-gradient-end': '#FF9F01',
  'background-gradient-start-dark': '#30C8CF',
  'background-gradient-middle-dark': '#D964FF',
  'background-gradient-end-dark': '#FF9F01',

  // --- colors that aren't in color schema -----
  // used in Theme switcher - landing
  'color-0': '#182634',
  'color-1': '#092540',
  // tag dark mode
  'color-2': '#6461FF',
  // cropper slide range thumb borders
  'color-4': '#929292',
  // dropzone file over bg
  'color-5': 'rgba(95, 92, 255, 0.08)',
  'color-5-dark': 'rgba(100, 97, 255, 0.12)',
  'color-6': '#C4C4C4',
  'color-7': 'rgba(9, 37, 64, 0.08)',
  'color-7-dark': 'rgba(255, 255, 255, 0.1)',
  // color picker shadow
  'color-8': 'rgba(0,0,0,0.2)',
  'color-8-dark': 'rgba(29, 129, 129, 0.16)',

  // progress bar
  'color-9': '#185BB5',
  // progress loader attachments preview
  'color-10': '#4D4D4D',
  'color-10-dark': '#BBBBBB',
  // error chip
  'color-11': 'rgba(219, 52, 62, 0.08)',
  'color-11-dark': 'rgba(255, 99, 108, 0.08)',
  // hyperlink
  'color-12': 'rgba(95, 92, 255, 1)',
  'color-12-dark': 'rgba(107, 104, 243, 1)',
  // progress trail stroke
  'color-13': 'rgba(95, 92, 255, .3)',

  surface: 'rgba(255, 255, 255, 0.92)',
  'surface-dark': 'rgba(12, 12, 12, 0.82)',
  'surface-always-dark': 'rgba(0, 0, 0, 0.6)',
  'tab-explore': 'rgba(48, 207, 192, 1)',
  'tab-create': 'rgba(217, 100, 255, 1)',
  'tab-customize': 'rgba(255, 158, 0, 1)',
  'tab-explore-dark': 'rgba(53, 207, 193, 1)',
  'tab-create-dark': 'rgba(219, 107, 255, 1)',
  pro: 'rgba(244, 247, 251, 1)',
  'pro-dark': 'rgba(24, 38, 52, 1)',
  'color-14': 'rgba(0, 0, 0, 0.03)',
};

module.exports = {
  colorSchema,
};
