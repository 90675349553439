import React from 'react';

export function componentSwitch<Options extends object>(
  choice: keyof Options,
  components: Record<keyof Options | 'default', React.ReactNode>
): React.ReactNode {
  const haveDefault = components.default !== undefined;
  const haveChoice = components[choice] !== undefined;

  if (haveChoice) {
    return components[choice];
  } else if (haveDefault) {
    return components.default;
  } else {
    return null;
  }
}
