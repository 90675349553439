import { gql } from '@urql/core';
import {
  CommonUserFragment,
  GenerateUserPreSignedPostUrlFragment,
  UserSubscriptionFragment
} from './fragments/userFragments';

export const GET_CURRENT_USER_DATA = gql`
  query Me {
    me {
      ...CommonUserFragment
    }
    ${CommonUserFragment}
  }
`;

export const UPDATE_CURRENT_USER_DATA = gql`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $displayName: String
    $bio: String
    $locale: String
    $website: String
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
      bio: $bio
      locale: $locale
      website: $website
    ) {
      ...CommonUserFragment
    }
    ${CommonUserFragment}
  }
`;



export const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
     ...CommonUserFragment
    }
    ${CommonUserFragment}
  }
`;

export const GET_USERS = gql`
  query Users(
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
    $communityId: String
    $showPlatformBlockedUsers: Boolean
  ) {
    users(
      before: $before
      first: $first
      after: $after
      sort: $sort
      search: $search
      communityId: $communityId
      showPlatformBlockedUsers: $showPlatformBlockedUsers
    ) {
      edges {
        cursor
        node {
          id
          email
          username
          firstName
          imageUrls
          lastName
          displayName
          numericId
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        count
        __typename
      }
      totalCount
    }
  }
`;


export const GENERATE_USER_PRE_SIGNED_POST_URL = gql`
  mutation GenerateUserPreSignedPostUrl {
    generateUserPreSignedPostUrl {
      ...GenerateUserPreSignedPostUrlFragment
    }
    ${GenerateUserPreSignedPostUrlFragment}
  }
`;


export const CREATE_PRO_USER_SUBSCRIPTION = gql`
  mutation CreateProUserSubscription ($priceId: String!) {
    createProUserSubscription (priceId: $priceId)
  }
`;


export const DEACTIVATE_PRO_USER_SUBSCRIPTION = gql`
  mutation DeactivateProUserSubscription {
    deactivateProUserSubscription {
      ...UserSubscriptionFragment
    }
  }
  ${UserSubscriptionFragment}
`;

export const REACTIVATE_PRO_USER_SUBSCRIPTION = gql`
  mutation ReactivateProUserSubscription {
    reactivateProUserSubscription {
      ...UserSubscriptionFragment
    }
  }
  ${UserSubscriptionFragment}
`;

export const GET_USER_PRO_SUBSCRIPTION = gql`
  query GetUserProSubscription  {
    userProSubscription {
      ...UserSubscriptionFragment
    }
  }
  ${UserSubscriptionFragment}
`;

export const SAVE_PRO_USERNAME = gql`
  mutation SaveProUsername($username: String!) {
    saveProUsername(username: $username) {
      id
      username
      status
      activatedAt
      isPro
    }
  }
`;


export const UPDATE_USER_GENERAL_SETTINGS = gql`
  mutation UpdateUserGeneralSettings($data: UserGeneralSettingsInput!) {
    updateUserGeneralSettings (data: $data) {
    id
    displayPreferences
		ownerId
  }
  }
`;

export const GET_USER_GENERAL_SETTINGS = gql`
  query GetUserGeneralSettings  {
    userGeneralSettings  {
      id
      displayPreferences
      ownerId
    }
}
`;

// Probably should be in the User repository?
export const UPDATE_USERNAME = gql`
  mutation UpdateUsername($username: String!) {
    updateUsername(username: $username) {
      id
      username
      status
      activatedAt
    }
  }
`;

export const CREATE_PRO_USERNAME = gql`
  mutation CreateProUsername ($username: String!) {
    createProUsername (username: $username) {
      id
      isPro
      username
      status
      activatedAt
    }
  }
`;

export const GET_ACCOUNT_PRO_USERNAME_SUBSCRIPTION_TYPES = gql`
query AccountProUsernameSubscriptionTypes {
	accountProUsernameSubscriptionTypes {
		id
		label
		description
		slug
		value
		order
		createdAt
		ext {
			discount
			price
		}
	}
}
`;




export const UPDATE_PRO_USERNAME = gql`
  mutation UpdateProUsername($username: String!) {
    updateProUsername(username: $username) {
      id
      username
      displayName
      proUsername
    }
  }
`;


export const PRE_UPDATE_USER_EMAIL = gql`
  mutation PreUpdateUserEmail($data: PreUpdateUserEmailInput!)  {
  preUpdateUserEmail (data: $data) {
    id
		created {
			user
			when
		}
		updated {
			user
			when
		}
		deleted {
			user
			when
		}
		email
		status
  }
}
`;

export const UPDATE_USER_EMAIL = gql`
mutation UpdateUserEmail($data: UserOTPInput!)  {
  updateUserEmail (data: $data) {
    id
    email
		username
		firstName
		lastName
		displayName
  }
}
`;


export const CHECK_USERNAME_AVAILABILITY = gql`
  query CheckUsernameAvailability ($username: String!) {
    checkUsernameAvailability (username: $username)
  }
`;

export const VALIDATE_PRO_USERNAME = gql`
    mutation ValidateProUsername ($username: String!) {
      validateProUsername (username: $username)
    }
`;



export const SEND_DEACTIVATE_USER_ACCOUNT_EMAIL = gql`
  mutation SendDeactivateUserAccountEmail  {
    sendDeactivateUserAccountEmail
  }
`;
export const DEACTIVATE_USER_ACCOUNT = gql`
mutation DeactivateUserAccount($data: UserOTPInput!)  {
  deactivateUserAccount(data: $data) {
		id
		email
		username
		status
		deactivatedAt
	}
}
`;
export const SEND_DELETE_USER_ACCOUNT_EMAIL = gql`
mutation SendDeleteUserAccountEmail($data: UserDeleteAccountInput!)  {
  sendDeleteUserAccountEmail(data: $data)
}
`;
export const DELETE_USER_ACCOUNT = gql`
mutation DeleteUserAccount($data: UserOTPInput!)  {
  deleteUserAccount(data: $data) {
		id 
		email
		username
		status
		deletedAt
	}
}
`;


export const GET_USER_CHANGE_EMAIL_REQUESTS = gql`
query GetUserChangeEmailRequests  {
  userChangeEmailRequests {
		id
		created {
			user
			when
		}
		updated {
			user
			when
		}
		deleted {
			user
			when
		}
		email
		status
	}
}
`;

export const CANCEL_USER_CHANGE_EMAIL_REQUEST = gql`
  mutation CancelUserChangeEmailRequest($id: String!)  {
    cancelUserChangeEmailRequest (id: $id) {
		id
		created {
			user
			when
		}
		updated {
			user
			when
		}
		deleted {
			user
			when
		}
		email
		status
	}
  }
`;

export const RESEND_USER_CHANGE_EMAIL_REQUEST = gql`
  mutation ResendUserChangeEmailRequest($id: String!)  {
    resendUserChangeEmailRequest (id: $id) {
      id
		created {
			user
			when
		}
		updated {
			user
			when
		}
		deleted {
			user
			when
		}
		email
		status
    }
  }
`;

export const VALIDATE_USER_EMAIL = gql`
  mutation ValidateUserEmail($email: String!)  {
    validateUserEmail (email: $email)
  }
`;

export const BLOCK_USER = gql`
  mutation BlockUser($communityId: String!, $userId: String!)  {
    blockUser (communityId: $communityId, userId: $userId) {
      id
      userId
      authorId
      user {
        email
        displayName
      }
    }
  }
`;

export const UNBLOCK_USER = gql`
  mutation UnblockUser($communityId: String!, $userId: String!)  {
    unblockUser (communityId: $communityId, userId: $userId) {
      id
      userId
      authorId
      user {
        email
        displayName
      }
    }
  }
`;