import { ni18nConfig } from '@mainApp/ni18n.config';
import { getPrimaryLayout } from '@mainApp/src/components/layouts/primary/PrimaryLayout';
import { useHomeRedirectHandler } from '@mainApp/src/hooks';
import { ssrHub } from '@mainApp/src/services';
import { loadTranslations } from 'ni18n';
import { Home as HomeModule } from '../src/modules';

export default function Home() {
  useHomeRedirectHandler();
  return <HomeModule />;
}

Home.getLayout = (page: React.ReactNode) =>
  getPrimaryLayout(page, {
    headerCn: 'dark:bg-background-website-dark bg-background-primary',
    footerCn: 'dark:bg-background-website-dark bg-background-primary',
  });

export async function getServerSideProps(props: any) {
  // TODO: maybe use more correct lang selector from the req header?
  const locale = props.req.cookies.user_locale || props.locale;
  ssrHub.setDataProp('lang', locale);
  const res = {
    props: {
      ...(await loadTranslations(ni18nConfig, locale, [
        'common',
        'home',
        'create-community',
        'auth',
      ])),
    },
  };

  return res;
}
