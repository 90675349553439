import {
  defaultLocale,
  namespaces,
  supportedLocales,
} from './src/config/locales.config';
export const ni18nConfig = {
  supportedLngs: supportedLocales,
  fallbackLng: defaultLocale,
  ns: namespaces,
  react: {
    useSuspense: false,
  },
};
