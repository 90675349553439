export type StepContentTitlePropsType = {
  title: string;
  description: string;
};

export const StepContentTitle = ({
  title,
  description,
}: StepContentTitlePropsType) => (
  <div className="themed-text mb-[28px] text-left">
    <h5 className="pb-[4px] text-subheadingSB">{title}</h5>
    <p className="themed-text text-body16R">{description}</p>
  </div>
);
