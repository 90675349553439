import { classNames } from '@foundationPathAlias/utilities';
import { animated, useSpring } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { CheckboxCommon, Props } from '../CheckboxCommon';

const ANIMATION_CONFIG = {
  mass: 1,
  tension: 140,
  friction: 18,
  clamp: true,
};

export type CheckboxCollapseProps = {
  /** title string */
  header: string | JSX.Element;
  checked: boolean;
  /** root style classes */
  cn?: string;
  headerCn?: string;
  /** style classes for the div that animates (collapse) */
  collapseWrapperCn?: string;
  /** style classes for the content wrapper div */
  collapseContentCn?: string;
  CheckboxCommonProps?: Partial<Props>;
  collapsedContent: JSX.Element;
  onChange: (checked: boolean) => void;
};

export function CheckboxCollapseAction(props: CheckboxCollapseProps) {
  const {
    header,
    checked,
    cn,
    headerCn,
    collapseWrapperCn,
    collapseContentCn,
    CheckboxCommonProps = {},
    collapsedContent,
    onChange,
  } = props;
  const [styles, api] = useSpring(() => ({ h: 0 }));
  const [collapsedElHeight, setCollapsedElHeight] = useState(0);

  const collapsedElRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentAnimationVal = styles.h.animation.to;
    if (!checked && currentAnimationVal !== 0) {
      api.start({
        h: 0,
        config: ANIMATION_CONFIG,
      });
    } else if (checked && currentAnimationVal === 0) {
      api.start({
        h: collapsedElHeight,
        config: ANIMATION_CONFIG,
      });
    }
  }, [checked]);

  return (
    <div className={classNames('flex w-full flex-col justify-between', cn)}>
      <div className="mb-[8px] flex items-center space-x-[24px]">
        <span
          className={classNames(
            'themed-text flex-1 text-left text-body16R',
            headerCn
          )}
        >
          {header}
        </span>
        <CheckboxCommon
          {...CheckboxCommonProps}
          checked={checked}
          onChange={(checked) => {
            api.start({
              h: checked ? collapsedElHeight : 0,
              config: ANIMATION_CONFIG,
            });

            onChange(checked);
          }}
        />
      </div>

      <animated.div
        ref={(el) => {
          if (el && !collapsedElRef.current) {
            collapsedElRef.current = el;
          }
        }}
        className={classNames('w-full overflow-hidden', collapseWrapperCn)}
        style={{
          height: styles.h.to((val) => `${val}px`),
        }}
      >
        <div
          className={classNames('themed-text-secondary ', collapseContentCn)}
          ref={(el) => {
            if (el) {
              setCollapsedElHeight(el.clientHeight);
            }
          }}
        >
          {collapsedContent}
        </div>
      </animated.div>
    </div>
  );
}
