import { Bars3Icon } from '@heroicons/react/20/solid';
import {
  SquareIconButtonBaseMedium,
  SquareIconButtonBaseMediumPropsType,
} from './SquareIconButtonBaseMedium';

export const MenuButton = ({
  ...props
}: SquareIconButtonBaseMediumPropsType) => {
  return <SquareIconButtonBaseMedium Icon={Bars3Icon} {...props} />;
};
