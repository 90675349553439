import * as React from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';
import { ClassNames, clsx } from '../../DomUtils/classNames';
import { EmojiStyle } from '../../types/exposedTypes';

export function EmojiImg({
  emojiName,
  style,
  lazyLoad = false,
  imgUrl,
  onError
}: {
  emojiName: string;
  emojiStyle: EmojiStyle;
  style: React.CSSProperties;
  lazyLoad?: boolean;
  imgUrl: string;
  onError: () => void;
}) {
  if (emojiName === 'add') {
    return <div
      className={`flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-[5px] 
    bg-element-subtle dark:bg-element-subtle-dark`}
    ><PlusIcon className=" w-[24px] h-[24px] text-text-primary dark:text-text-primary-dark" /></div>
  }
  return (
    <img
      src={imgUrl}
      alt={emojiName}
      className={clsx(ClassNames.external, 'epr-emoji-img')}
      loading={lazyLoad ? 'lazy' : 'eager'}
      onError={onError}
      style={style}
    />
  );
}
