import { injectable } from 'inversify';
import debounce from 'lodash/debounce';
import inRange from 'lodash/inRange';
import { isBrowser } from '../utils';

import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import {
  IDimensionsStore,
  LAYOUT_TYPES_ENUM,
  SCREEN_SIZES_ENUM,
  WindowSize,
} from './types';

enableStaticRendering(!isBrowser);

@injectable()
export class DimensionsStore implements IDimensionsStore {
  windowSize: WindowSize = {
    width: 0,
    height: 0,
  };
  screenWindow: any;

  constructor() {
    makeObservable(this, {
      windowSize: observable,
      layoutType: computed,
      isReady: computed,
      isMobile: computed,
      init: action,
    });

    this.init();
  }

  get isReady() {
    return this.windowSize.width > 0 && this.windowSize.height > 0;
  }

  get isMobile() {
    if (!global.window) return false;

    return this.windowSize.width < SCREEN_SIZES_ENUM.md;
  }
  get isBigHeight() {
    return this.windowSize.height > SCREEN_SIZES_ENUM.bigHeight;
  }

  get layoutType() {
    const { width } = this.windowSize;
    const sizes: {
      [index: string]: boolean;
    } = {
      [LAYOUT_TYPES_ENUM.XS]: inRange(width, 0, SCREEN_SIZES_ENUM.sm),
      [LAYOUT_TYPES_ENUM.SM]: inRange(
        width,
        SCREEN_SIZES_ENUM.sm,
        SCREEN_SIZES_ENUM.md
      ),
      [LAYOUT_TYPES_ENUM.MD]: inRange(
        width,
        SCREEN_SIZES_ENUM.md,
        SCREEN_SIZES_ENUM.lg
      ),
      [LAYOUT_TYPES_ENUM.LG]: inRange(width, SCREEN_SIZES_ENUM.lg, +Infinity),
    };

    const res = Object.keys(sizes).find((k) => sizes[k]);
    return res as LAYOUT_TYPES_ENUM;
  }

  init() {
    if (isBrowser) {
      this.screenWindow = window;
      this.proceedSize();
      this.screenWindow.addEventListener('resize', this.proceedSize);
    }
  }

  proceedSize = debounce(() => {
    const width = this.screenWindow.innerWidth;
    const height = this.screenWindow.innerHeight;
    runInAction(() => {
      this.windowSize = {
        width,
        height,
      };
    });
  }, 100);
}
