import {
  Channel_Member_Type,
  CommunityRolesMemberPayload,
  type CommunityRolePayload,
} from '@10x/foundation/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  GetCommunityRoleMembersParamsType,
  type ICommunityRepository,
} from '../repositories';
import {
  ApiBase,
  CommonApiDataShapeType,
  IterableDataShape,
  UniqueKeysRegistry,
} from './ApiBase';
import { type ICommunityRoleModel } from './CreateChannel.types';
import { type IToastStore } from './Toast.store';

const MEMBERS_PAGE_LENGTH = 10;

export class CommunityRoleModel extends ApiBase implements ICommunityRoleModel {
  communityRepository: ICommunityRepository;

  roleMembers: CommonApiDataShapeType<IterableDataShape<CommunityRolesMemberPayload> | null> =
    CommunityRoleModel.generateCommonApiDataShape();

  roleId = '';
  name = '';
  totalMemberCount = 0;
  communityId = '';

  constructor({
    toastStore,
    communityRolePayload,
    communityRepository,
  }: {
    toastStore: IToastStore;
    communityRolePayload: Partial<CommunityRolePayload>;
    communityRepository: ICommunityRepository;
  }) {
    super(toastStore);
    this.communityRepository = communityRepository;

    this.roleId = communityRolePayload.id ?? '';
    this.name = communityRolePayload.name ?? '';
    this.totalMemberCount = communityRolePayload.totalMemberCount ?? 0;
    this.communityId = communityRolePayload.communityId ?? '';

    makeObservable(this, {
      communityId: observable,
      roleId: observable,
      name: observable,
      totalMemberCount: observable,
      roleMembers: observable,
      hasMoreMembersToLoad: computed,
      parsedRoleMembers: computed,
      getRoleMembers: action,
      loadMoreMembers: action,
    });
  }

  get parsedRoleMembers() {
    return (this.roleMembers.data?.valuesArray ?? []).map((roleMember) => {
      return {
        id: roleMember.id,
        description: `@${roleMember.user?.username}`,
        name: roleMember.user?.displayName ?? '',
        memberType: Channel_Member_Type.User,
        isOnline: roleMember.user?.online,
      };
    });
  }

  get hasMoreMembersToLoad() {
    return Boolean(
      this.roleMembers.meta.pageInfo.count < this.roleMembers.meta.totalCount
    );
  }

  getRoleMembers = async (
    params: Omit<GetCommunityRoleMembersParamsType, 'communityId' | 'roleId'>
  ) => {
    runInAction(() => {
      this.roleMembers = {
        ...this.roleMembers,
        loading: true,
      };
    });

    const response = await this.communityRepository.getCommunityRoleMembers({
      communityId: this.communityId,
      roleId: this.roleId,
      ...params,
      first: params.first || MEMBERS_PAGE_LENGTH,
    });
    const { error, data, originalResponse } = response;
    if (error) {
      this.handleError('Get Role Members', error.message);
      return;
    }

    const registry = data
      ? data.reduce(
          (acc: UniqueKeysRegistry<CommunityRolesMemberPayload>, edge) => {
            const node = edge?.node;
            if (node && !acc[node.id]) {
              acc[node.id] = node;
            }

            return acc;
          },
          {}
        )
      : {};

    runInAction(() => {
      this.roleMembers = {
        data: {
          registry: registry,
          valuesArray: Object.values(registry),
        },
        error: error,
        loading: false,
        meta: {
          variables: originalResponse.operation.variables,
          pageInfo: originalResponse?.data?.communityRoleMembers.pageInfo,
          nextPageLoading: false,
          totalCount: originalResponse?.data?.communityRoleMembers.totalCount,
        },
      };
    });
  };

  loadMoreMembers = async () => {
    if (!this.hasMoreMembersToLoad) {
      return;
    }

    const first = this.roleMembers.meta?.variables?.first;

    this.getRoleMembers({
      first: first ? first + MEMBERS_PAGE_LENGTH : MEMBERS_PAGE_LENGTH,
    });
  };
}
