import { ApiBase } from '@mainApp/src/stores/ApiBase';
import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
enableStaticRendering(typeof window === 'undefined');

import { subscriptionSettingsComponents } from './subscriptionComponents';

import { AccountProUsernameSubscription } from '@10x/foundation/types';
import { IAnimatedStackStore } from '@mainApp/src/components/common';
import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import { SubscriptionSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';
import { BaseRepositoryResponse } from '@mainApp/src/repositories';
import { IToastStore, IUserStore } from '@mainApp/src/stores';

// TODO: temporary. IF success should be moved to the AccountSetting store or even upper

export class SubscriptionStore extends ApiBase {
    userStore: IUserStore;
    stackStore: IAnimatedStackStore;

    isLoading = false;
    error: null | string = null;

    activeSettingId: SubscriptionSettingsEnum = SubscriptionSettingsEnum.INITIAL;

    get initialSetting() {
        return SubscriptionSettingsEnum.INITIAL;
    }

    get activeSetting() {
        return subscriptionSettingsComponents[this.activeSettingId];
    }

    get isInitialScreen() {
        return this.activeSettingId === SubscriptionSettingsEnum.INITIAL;
    }

    constructor(stackStore: IAnimatedStackStore) {
        super(iocContainer.get<IToastStore>(IOC_TOKENS.toastStore));
        this.userStore = iocContainer.get<IUserStore>(IOC_TOKENS.userStore);

        makeObservable(this, {
            isLoading: observable,
            error: observable,
            activeSetting: computed,
            isInitialScreen: computed,
            setActiveSettingId: action,
            setNextSettingId: action,
            setIsLoading: action,
            setError: action,
            back: action,
            reset: action,
        });

        this.stackStore = stackStore;
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    }
    setError = (error: null | string) => {
        this.error = error;
    }

    deactivateProUserSubscription = async () => {
        this.setIsLoading(true);
        const res = await this.userStore.deactivateProUserSubscription();
        const error = res.error;
        if (error) {
            this.handleError('Error', error.message)
            this.setError(error.message);
        }
        this.setIsLoading(false);
        return res;
    }
    reactivateProUserSubscription = async () => {
        this.setIsLoading(true);
        const res = await this.userStore.reactivateProUserSubscription();
        const error = res.error;
        if (error) {
            this.handleError('Error', error.message)
            this.setError(error.message);
        }
        this.setIsLoading(false);
        return res;
    }

    setActiveSettingId = (id: SubscriptionSettingsEnum) => {
        if (this.activeSettingId === id) return;
        this.activeSettingId = id;
    };

    setNextSettingId = (id: SubscriptionSettingsEnum) => {
        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }
        this.activeSettingId = id;

        const nextItem = subscriptionSettingsComponents[id];
        stackStore.next(nextItem.id, nextItem.Component);
    };
    back = () => {
        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }

        const prevSettingId = stackStore.back();

        if (!prevSettingId) return;

        this.activeSettingId = prevSettingId as SubscriptionSettingsEnum;
    };

    reset = () => {
        this.setActiveSettingId(this.initialSetting);
        this.stackStore.reset();
        this.stackStore.setVisible(
            this.activeSetting.id,
            this.activeSetting.Component
        );
    };
}
type SettingComponentsConfig = typeof subscriptionSettingsComponents;

export interface ISubscriptionStore {
    userStore: IUserStore;
    stackStore: IAnimatedStackStore;
    isLoading: boolean;
    error: null | string;
    activeSettingId: SubscriptionSettingsEnum
    readonly isInitialScreen: boolean;
    readonly initialSetting: SubscriptionSettingsEnum;
    readonly activeSetting: SettingComponentsConfig[keyof SettingComponentsConfig];
    setIsLoading: (isLoading: boolean) => void;
    setError: (error: null | string) => void;
    deactivateProUserSubscription: () => Promise<BaseRepositoryResponse<AccountProUsernameSubscription>>;
    reactivateProUserSubscription: () => Promise<BaseRepositoryResponse<AccountProUsernameSubscription>>;
    setActiveSettingId: (setting: SubscriptionSettingsEnum) => void;
    setNextSettingId: (setting: SubscriptionSettingsEnum) => void;
    back: () => void;
    reset: () => void;
}
