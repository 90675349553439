import {
  SortingState,
  Table,
  TableHeaderSortable,
  createColumnHelper,
  getFilteredRowModel,
} from '@foundationPathAlias/components/table';
import { TextInput } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  formatDate,
  fuzzyFilter,
} from '@mainApp/src/modules/customer-admin-panel/screens/users/data/helpers';
import { TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UsersTableCustomMobileRow,
  UsersTableDisplayNameCell,
  UsersTableFilterEmptyState,
  UsersTableNoMembers,
  UsersTableOptionsCell,
  UsersTableRoleCell,
} from '.';

import { Role_Levels, Role_List_Types } from '@10x/foundation/types';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { type IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { observer } from 'mobx-react-lite';
import { AvailableActionsEnum } from './tooltip-contents';

const _UsersTable = () => {
  const { t } = useTranslation();
  const {
    capStore,
    dimensionsStore: { isMobile },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.communityStore,
  ]);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const membersList = communityStore.members.data?.valuesArray || [];
  const isLoading = communityStore.members.loading;
  const hasNextPage = Boolean(
    communityStore.members?.meta?.pageInfo?.hasNextPage
  );

  const { memberFilter, setMemberFilter, activeCommunityId } =
    usersStoreLocal || {};

  useEffect(() => {
    if (activeCommunityId) {
      communityStore.getCommunityRoles(activeCommunityId, {
        listType: Role_List_Types.Community,
        roleLevels: [Role_Levels.Admin, Role_Levels.Owner, Role_Levels.Custom],
      });
    }
  }, [activeCommunityId]);

  const columnHelper = createColumnHelper<Partial<TableUser>>();

  const data = membersList || [];

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = [
    columnHelper.accessor('displayName', {
      id: 'displayName',
      enableGlobalFilter: true,
      filterFn: 'fuzzy',
      header: ({ header }) => {
        return (
          <TableHeaderSortable
            label={t('cap:users.table.columns.name')}
            header={header}
          />
        );
      },
      cell: ({ row }) => {
        return <UsersTableDisplayNameCell userData={row.original} />;
      },
    }),
    columnHelper.accessor('roles', {
      enableGlobalFilter: true,
      id: 'roles',
      filterFn: 'fuzzy',
      cell: (info) => (
        <UsersTableRoleCell
          row={info.row}
          // TODO: fix any type
          hoveredRow={Boolean((info as any).hovered)}
        />
      ),
      header: () => <span>{t('cap:users.table.columns.roles')}</span>,
    }),
    columnHelper.accessor('memberSince', {
      enableGlobalFilter: false,
      header: ({ header }) => (
        <TableHeaderSortable
          label={t('cap:users.table.columns.memberSince')}
          header={header}
        />
      ),
      cell: ({ row }) => <i>{formatDate(row.original.memberSince)}</i>,
    }),
    columnHelper.accessor('lastActive', {
      enableGlobalFilter: false,
      header: ({ header }) => (
        <TableHeaderSortable
          label={t('cap:users.table.columns.lastActive')}
          header={header}
        />
      ),
      cell: ({ row }) => <i>{formatDate(row.original.lastActive)}</i>,
      enableSorting: true,
    }),
    // TODO: this column will be added in another version
    // columnHelper.accessor('noOfReports', {
    //   enableGlobalFilter: false,
    //   maxSize: 20,
    //   header: ({ header }) => (
    //     <TableHeaderSortable
    //       label={t('cap:users.table.columns.reports')}
    //       header={header}
    //     />
    //   ),
    // }),
    columnHelper.accessor('id', {
      enableGlobalFilter: false,
      // TODO: adjust any type
      cell: (info) => (
        <UsersTableOptionsCell
          row={info.row}
          availableActions={[
            AvailableActionsEnum.AddRoles,
            AvailableActionsEnum.Ban,
          ]}
          hovered={Boolean((info as any).hovered)}
          onBan={() => usersStoreLocal.setUserToBan(info.row.original as any)}
        />
      ),
      header: () => null,
    }),
  ];

  const handleLoadMore = () => {
    if (!hasNextPage) return;

    communityStore.fetchMoreMembers();
  };

  return (
    <>
      <div className="flex items-center gap-[8px] pb-[16px] md:pb-[24px] md:pt-[16px]">
        <TextInput
          leftSideContent={
            <MagnifyingGlassIcon
              className="text-element-normal dark:text-element-normal-dark"
              width={20}
              height={20}
            />
          }
          InputProps={{
            defaultValue: memberFilter,
            onKeyDown: (evt) => {
              if (evt.key === 'Enter') {
                setMemberFilter(evt.currentTarget.value);
              }
            },
          }}
          placeholder={t<string>('common:search')}
          rootCn="flex-1"
        />
        {/* The invites button will be add on next version */}
        {/* <PrimaryButton
                  cn={classNames('w-auto mr-[4px]', {
                    hidden: isMobile,
                  })}
                  onClick={() => null}
                >
                  {t('cap:users.invitePeople')}
                </PrimaryButton> */}
      </div>

      <div className="flex-1 overflow-y-auto scrollbar-thin">
        <Table
          dataSource={data}
          columns={columns}
          useReactTableOptions={{
            onSortingChange: setSorting,
            filterFns: {
              fuzzy: fuzzyFilter,
            },
            state: { sorting },
            globalFilterFn: 'fuzzy',
            getFilteredRowModel: getFilteredRowModel(),
          }}
          cnContainer="w-full"
          customRenderRow={
            !isMobile
              ? undefined
              : (row) => {
                  return (
                    <UsersTableCustomMobileRow
                      row={row}
                      gridItems={[
                        {
                          label: t('cap:users.table.columns.memberSince'),
                          value: formatDate(row.original.memberSince),
                        },
                        {
                          label: t('cap:users.table.columns.lastActive'),
                          value: formatDate(row.original.lastActive),
                        },
                      ]}
                      availableActions={[
                        AvailableActionsEnum.AddRoles,
                        AvailableActionsEnum.Ban,
                      ]}
                    />
                  );
                }
          }
          cnTHeaderCell={(_cell, idx) =>
            classNames({
              'themed-layout sticky left-0 z-2 bg-background-primary dark:bg-background-primary-dark':
                idx === 0,
            })
          }
          cnTCell={(_cell, colIdx, _list, customProps = {}) => {
            const { hovered } = customProps;

            return classNames({
              'sticky left-0 z-1 shadow-blurred-border whitespace-nowrap bg-background-primary dark:bg-background-primary-dark before:absolute before:w-full before:h-full before:left-0 before:top-0 before:-z-1 before:shadow-right':
                colIdx === 0 && !isMobile,
              'before:bg-hovered-selected before:dark:bg-hovered-selected-dark':
                hovered && colIdx === 0 && !isMobile,
            });
          }}
          cnTHead="hidden md:table-header-group dark:bg-background-tetriary-dark"
          hasMorePages={hasNextPage}
          loadMore={handleLoadMore}
          loading={isLoading}
          customRender={({ table }) => {
            if (isLoading) return;

            if (!data.length) {
              return <UsersTableNoMembers />;
            }

            if (table.getRowCount() < 1) {
              return <UsersTableFilterEmptyState />;
            }

            return;
          }}
        />
      </div>
    </>
  );
};

export const UsersTable = observer(_UsersTable);
