import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';
import parseISO from 'date-fns/parseISO';

import { toFixedNumber } from './common';

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

export const PassedPeriods = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  WEEK_IN_CURRENT_YEAR: 'weekInCurrentYear',
  WEEK_IN_PAST_YEARS: 'weekInPastYears',
};
export type PassedPeriodsKeys = keyof typeof PassedPeriods;
export type PassedTimeResult = {
  passedPeriod: typeof PassedPeriods[PassedPeriodsKeys];
  value: string | number;
};

function generateResult(
  passedPeriod: typeof PassedPeriods[PassedPeriodsKeys],
  value: number | string,
) {
  return {
    passedPeriod,
    value,
  };
}

/**
 * compares the passed and the current date and returns the appropriatedly
 * formatted passed time in the TikTok format.
 * 
 * TikTok uses this format for old posts: 2020-6-30
  Posts that are in prior calendar year
  And this format for more recent posts:
  1s ago
  5m
  12h
  6d. If 24 hours turns to "1d"
  4-4. This means April 4. Appears if within current year. 
  This format is used if posted 7 days or more in past, 
  but within same calendar year.

  ATTENTION! This utility returns the schema of the passed time
  with the value and passed period type so you NEED to 
  implement the final string representation regarding your
  needs. There is no reason to put this logic inside this
  utility because you might want to use some translates etc.
  so it's better to return only the raw data that you should
  handle whatever you want
 * 
 */
export function getPassedTime(
  dateValue: number | string, // iso format or timestamp
  currentDate = new Date(),
) {
  const date = typeof dateValue === 'number' ? new Date(dateValue) : parseISO(dateValue);
  const isDateInThisYear = isThisYear(new Date(dateValue));

  const msDelta = differenceInMilliseconds(currentDate, date);

  const seconds = toFixedNumber(msDelta / second);
  const minutes = toFixedNumber(msDelta / minute);
  const hours = toFixedNumber(msDelta / hour);
  const days = toFixedNumber(msDelta / day);
  if (seconds < 60) {
    return generateResult(PassedPeriods.SECONDS, seconds);
  }
  if (minutes < 60) {
    return generateResult(PassedPeriods.MINUTES, minutes);
  }
  if (hours < 24) {
    return generateResult(PassedPeriods.HOURS, hours);
  }
  if (days <= 6) {
    return generateResult(PassedPeriods.DAYS, days);
  }
  if (isDateInThisYear && days >= 7) {
    const value = format(date, 'M-d');
    return generateResult(PassedPeriods.WEEK_IN_CURRENT_YEAR, value);
  }

  const value = format(date, 'yyyy-M-dd');
  return generateResult(PassedPeriods.WEEK_IN_PAST_YEARS, value);
}

type PassedPeriodsValues = typeof PassedPeriods[keyof typeof PassedPeriods];
export type CreationTimeMessages = {
  [key in PassedPeriodsValues]: (value: string | number) => string;
}


export function formatCreationTime({ passedPeriod, value }: PassedTimeResult, messages?: CreationTimeMessages): string {
  let res;

  switch (passedPeriod) {
    case PassedPeriods.SECONDS:
      res = messages?.[PassedPeriods.SECONDS](value) || `${value}s ago`;
      break;
    case PassedPeriods.MINUTES:
      res = messages?.[PassedPeriods.MINUTES](value) || `${value}m`;
      break;
    case PassedPeriods.HOURS:
      res = messages?.[PassedPeriods.HOURS](value) || `${value}h`;
      break;
    case PassedPeriods.DAYS:
      res = messages?.[PassedPeriods.DAYS](value) || `${value}d`;
      break;
    default:
      res = value as string;
  }
  return res;
}
