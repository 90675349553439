import { OutlineButton, PrimaryButton } from '@foundationPathAlias/components';
import { useTranslation } from 'react-i18next';

type ActionButtonsProps = {
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  submitLabel: string;
};

export const ActionButtons = ({
  onClose,
  onSubmit,
  submitLabel,
  isSubmitting,
}: ActionButtonsProps) => {
  const { t } = useTranslation(['common']);

  return (
    <div className="flex justify-end gap-4 border-t border-t-element-subtle p-6 dark:border-t-element-subtle-dark">
      <OutlineButton disabled={isSubmitting} cn="w-auto" onClick={onClose}>
        {t('cancel')}
      </OutlineButton>

      <PrimaryButton disabled={isSubmitting} cn="w-auto" onClick={onSubmit}>
        {submitLabel}
      </PrimaryButton>
    </div>
  );
};
