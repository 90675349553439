// import EmojiPicker from 'emoji-picker-react';

import EmojiPicker, {
  Theme,
} from '@foundationPathAlias/forked-thirdparty/emoji-picker-react';
import {
  Categories,
  CategoryConfig,
  baseCategoriesConfig,
} from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/config/categoryConfig';
import { EmojiClickData } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/types/exposedTypes';
import { CustomEmoji as CustomEmojiType } from 'emoji-picker-react/dist/config/customEmojiConfig';
import { useMemo, useState } from 'react';
import { CustomEmoji } from '../custom-emoji/CustomEmoji';

type Props = {
  isDark: boolean;
  customEmojis?: { category: CategoryConfig; icons: CustomEmojiType[] };
  getEmoji: (emoji: EmojiClickData) => void;
  dimensions?: { width: number; height: number };
  /** in KBs */
  maxFileSize?: number;
  isMobile?: boolean;
};

export function Reactions(props: Props) {
  const {
    isDark,
    customEmojis,
    getEmoji,
    dimensions,
    maxFileSize = 128,
    isMobile,
  } = props;
  const [showCustomEmoji, setShowCustomEmoji] = useState(false);
  const hideCustomEmoji = useMemo(
    () =>
      !customEmojis ||
      !customEmojis.category ||
      customEmojis.icons.length === 0,
    [customEmojis]
  );

  const getCategories = () => {
    let categories = baseCategoriesConfig().filter(
      (item) => item.category !== Categories.CUSTOM
    );
    if (hideCustomEmoji) return categories;
    if (customEmojis) categories.push(customEmojis.category);
    return categories;
  };

  const hadleEmojiClick = (emoji: EmojiClickData) => {
    setShowCustomEmoji(emoji.emoji === 'add');
    getEmoji?.(emoji);
  };

  return (
    <div>
      <EmojiPicker
        theme={isDark ? Theme.DARK : Theme.LIGHT}
        onEmojiClick={hadleEmojiClick}
        categories={getCategories()}
        customEmojis={customEmojis?.icons}
        {...(dimensions
          ? { height: dimensions.height, width: dimensions.width }
          : {})}
        previewConfig={{
          showPreview: !isMobile,
        }}
        isMobile={isMobile}
      />
      <CustomEmoji
        show={showCustomEmoji}
        handleHide={() => setShowCustomEmoji(false)}
        maxFileSize={maxFileSize}
      />
    </div>
  );
}
