import { action, makeObservable, observable } from 'mobx';

import type { FileContent } from 'use-file-picker';
import { DataModel } from '../Data.model';

import { ChannelMessageAttachmentPayload } from '@10x/foundation/types';
import { optimisticMocksService } from '@mainApp/src/stores/optimistic-mocks';

import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { injectable } from 'inversify';
import { MessageModel } from '../Message.model';

export type OptimisticAttachmentModelData = {
  preview: FileContent;
  uploading: boolean;
  optimistic: boolean;
};

export type AttachmentModelServerData = ChannelMessageAttachmentPayload & {
  optimisticData?: OptimisticAttachmentModelData;
};

export type AttachmentModelType = typeof AttachmentModel;

export type AttachmentModelJsonType = {
  file: File | undefined;
  name: string | undefined;
  preview: FileContent | undefined;
  uploading: boolean;
  optimistic: boolean;
  serverAttachmentId: string | null;
};

@injectable()
export class AttachmentModel extends DataModel {
  static typename = 'ChannelMessageAttachmentPayload';

  // TODO: DI
  static optimisticMocksService = optimisticMocksService;
  //   cacheRef;

  file: File | undefined;

  name: string | undefined;

  preview: FileContent | undefined;

  uploading = false;
  // true if the attachment failed uploading
  failed = false;
  // will contain batch url data when the attachment uploading will be failed
  batchUrlData = null;

  optimistic = false;

  /**
   * The real backend server ID that should be used
   * later for the message creation.
   * This value comes from the BE after the success
   * attachment uploading
   */
  serverAttachmentId: string | null = null;

  // Test. should be true if added optimistically to cache
  inCache = false;

  serverData: ChannelMessageAttachmentPayload | null = null;

  // TODO: test -> set the parent message model to always get a correct ref without any lookup
  parentMessageModel: MessageModel;

  get fileName() {
    return this.name || this.preview?.name || this.serverData?.filename;
  }

  // TODO: change to params object
  constructor(
    preview: FileContent | undefined,
    file: File | undefined,
    parentMessageModel: MessageModel,
    serverData?: AttachmentModelServerData
  ) {
    super();
    this.preview = preview;
    this.file = file;
    this.name = preview?.name;
    this.parentMessageModel = parentMessageModel;

    // means it's an optimistic case or preview in the input and it means uploading
    if (!serverData) {
      this.uploading = true;
    } else {
      const { optimisticData, ...pureServerData } = serverData;
      this.serverData = pureServerData;

      if (optimisticData) {
        this.optimistic = true;
        this.preview = optimisticData.preview;
        this.uploading = optimisticData.uploading;
      } else {
        // update from the optimistic ID to the real ID as in this case the data comes from the BE
        this.id = this.serverAttachmentId = this.serverData.id;
      }
    }

    makeObservable(this, {
      uploading: observable,
      failed: observable,
      setFailed: action,
      setUploading: action,
    });
  }

  setUploading = (uploading: boolean) => {
    this.uploading = uploading;
  };
  setFailed = (failed: boolean) => {
    this.failed = failed;
  };

  generateOptimisticResponse = (forClient = false) => {
    const base =
      AttachmentModel.optimisticMocksService.generateChannelMessageAttachmentPayload();

    // just use the real id. Needed for attachments update by file upload service
    const previewUrl = this.getPreviewOrRealImageUrl();
    // for urql cache
    const result: AttachmentModelServerData = {
      ...base,
      ...this.serverData,
      filename: this.fileName as string,
      url: '',
      urls: [previewUrl],
    } as any;

    // it's for the local client. Not urls (optimistic message model)
    if (forClient) {
      result.id = this.id;
      result.optimisticData = {
        preview:
          this.preview ||
          ({
            name: this.fileName,
            content: previewUrl,
          } as FileContent),
        // optimistic - means the real mutation is running = loading
        uploading: this.uploading,
        optimistic: true,
      };
    }

    return result;
  };


  // when the attachment uploaded need to update the uploading prop
  completeUploading = () => {
    this.uploading = false;
  };

  toJson = () => {
    return {
      file: this.file,
      name: this.name,
      preview: this.preview,
      uploading: this.uploading,
      optimistic: this.optimistic,
      serverAttachmentId: this.serverAttachmentId,
    };
  };

  updateFromJson = (data: AttachmentModelJsonType) => {
    Object.assign(this, data);
  };

  getImageUrl = (prefferedSize?: CLOUDFLARE_VARIANTS_ENUM) => {
    return getCloudflareSizeRecognition(
      this.serverData?.urls as string[],
      prefferedSize
    );
  };

  getPreviewOrRealImageUrl = () => {
    return this.preview?.content || this.getImageUrl();
  };
}
