import { OutlineButton, PrimaryButton } from '@foundationPathAlias/components';
import { ProgressLoader } from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useTranslation } from 'react-i18next';

import { StepNamesEnum } from '../PostAuthOnboarding.store.types';
import { Separator } from './Separator';

type Props = {
  isStepValid: boolean;
  isStepError: boolean;
};

export function Actions(props: Props) {
  const { t } = useTranslation('common');
  const { isStepValid, isStepError } = props;

  const postOnboardingStore = useInjection(IOC_TOKENS.postAuthOnboardingStore);

  const isSubscriptionStep =
    postOnboardingStore.activeStepName === StepNamesEnum.SUBSCRIPTION;
  const isNamesStep =
    postOnboardingStore.activeStepName === StepNamesEnum.USERNAME ||
    postOnboardingStore.activeStepName === StepNamesEnum.DISPLAY_NAME;

  let firstBtnText = t('skip');
  let firstBtnAction = postOnboardingStore.skip;
  let secondBtnText: string | JSX.Element = t('next') as string;

  const secondBtnAction = postOnboardingStore.next;

  switch (postOnboardingStore.activeStepName) {
    // avatar step
    case StepNamesEnum.AVATAR:
      secondBtnText = postOnboardingStore.proMode
        ? (t('next') as string)
        : (t('finish') as string);

      if (postOnboardingStore.activeStep.loading) {
        secondBtnText = (
          <p>
            <ProgressLoader cn="mr-[8px]" mainStrokeFill="#FFF" />{' '}
            {t('yourProfile')}
          </p>
        );
      }

      break;
    // subscription step
    case StepNamesEnum.SUBSCRIPTION:
      firstBtnText = t('switchToStandardUsername');
      firstBtnAction = postOnboardingStore.switchToStandardUsername;
      secondBtnText = t('continueWithPro') as string;
      //  secondBtnAction = postOnboardingStore.subscriptionPay;
      break;
    default:
      break;
  }

  const secondBtnContent = isSubscriptionStep
    ? t('continueWithPro')
    : secondBtnText;

  return (
    <div className="flex-end ==flex-1 flex items-end">
      <div className="flex flex-1 flex-col">
        <Separator />

        <div className="flex flex-1 items-end">
          <div
            className={classNames(
              'mt-[24px] flex flex-1 justify-end px-[24px]',
              isSubscriptionStep &&
                'flex-col space-y-[16px] md:flex-row md:space-x-[16px] md:space-y-0'
            )}
          >
            <OutlineButton
              cn={classNames(
                'min-h-[42px] w-auto rounded-[5px]',
                isSubscriptionStep ? 'mr-0' : 'mr-[16px]',
                isNamesStep && 'hidden'
              )}
              onClick={() => {
                firstBtnAction();
              }}
              disabled={isStepError}
            >
              {isSubscriptionStep
                ? t('switchToStandardUsername')
                : firstBtnText}
            </OutlineButton>
            <PrimaryButton
              cn={classNames(
                'min-h-[42px] w-auto rounded-[5px] flex-1 md:flex-initial',
                postOnboardingStore.activeStep.loading && 'opacity-50'
              )}
              onClick={() => {
                secondBtnAction(false);
              }}
              disabled={!isStepValid}
            >
              {secondBtnContent}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
