import * as React from 'react';

import { clsx } from '../../DomUtils/classNames';

/** TODO. Used "any" type explicitly as I've tried giving Button type in different ways,
 * but tslint is always giving error somehow
 */
// interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
//   className?: string;
// }
// interface Props
//   extends React.DetailedHTMLProps<
//     React.ButtonHTMLAttributes<HTMLButtonElement>,
//     HTMLButtonElement
//   > {
//   className?: string;
// }

export const Button: React.FC<any> = (props) => {
  return (
    <button
      type="button"
      {...props}
      className={clsx('epr-btn', props.className)}
    >
      {props.children}
    </button>
  );
};
