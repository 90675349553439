import {
  BottomSheetConfig,
  ChangesAlertConfig,
  ChildScreenClasses,
  ChildScreens,
  DiscardActionsEnum,
  ISidebarModal,
  ISidebarModalController,
  ScreensConfig,
} from '../data/types';

type Params<T extends ChildScreenClasses, C extends string> = {
  childScreenClasses: ChildScreenClasses;
  screensConfig: ScreensConfig<C>;
  screenIds: Record<string, C>;
  initialChildScreenId: keyof T | string;
};

// if it will be used with mobx - make sure to make the props/actions observable/actions etc.
export class SidebarModalController<
  T extends ChildScreenClasses,
  C extends string
> implements ISidebarModalController<T, C>
{
  private isSidebarModalWasSetFirstTime = false;

  sidebarModal: ISidebarModal | null = null;
  isFirstRenderCompleted = false;
  wasLayoutMobile = false;
  isMobile = false;
  screensConfig: ScreensConfig<C>;
  initialChildScreenId: keyof T;
  readonly screenIds: Record<string, C>;

  // instances of the child classes
  childScreens: ChildScreens<T>;
  // an object that contains child classes
  childScreenClasses: ChildScreenClasses;
  activeScreenId: keyof T | null = null;

  onSidebarModalSet?: (newSidebarModal: ISidebarModal) => void;
  onFirstSidebarModalSet?: (sidebarModal: ISidebarModal) => void;

  get activeChildScreen() {
    return this.childScreens[this.activeScreenId as keyof T];
  }

  get isActiveChildScreenDirty() {
    return this.activeChildScreen?.isDirty;
  }

  get isInitialScreen() {
    // if there is no any active child screen it means that only this controller is active and there is only sidebar menu screen should be visible on mobile layout
    return this.activeChildScreen
      ? this.activeChildScreen?.isInitialScreen
      : true;
  }

  constructor(params: Params<T, C>) {
    const {
      childScreenClasses,
      screensConfig,
      screenIds,
      initialChildScreenId,
    } = params;
    this.childScreenClasses = childScreenClasses;
    this.screensConfig = screensConfig;
    this.screenIds = screenIds;
    this.initialChildScreenId = initialChildScreenId;

    this.childScreens = Object.keys(childScreenClasses).reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {} as Record<string, any>) as ChildScreens<T>;
  }

  renderInitialScreen = () => {
    const initialMobileSettingScreen =
      this.screensConfig[this.screenIds.INITIAL];

    this.sidebarModal?.animatedStack.reset();
    this.sidebarModal?.animatedStack.setVisible(
      initialMobileSettingScreen.id,
      initialMobileSettingScreen.Component
    );
  };
  setInitialScreen = (skipFirstRenderCheck = false) => {
    if (this.isFirstRenderCompleted && !skipFirstRenderCheck) {
      // if there is non-initial settings screen active no need to
      // re-render it when the window size is changing as the layout is not significantly different

      // also shouldn't render settings menu if there is a change from desktop layout to mobile as some menu initial screen is active on desktop and should it keep persistent on mobile as well
      if (
        !this.isInitialScreen ||
        (!this.wasLayoutMobile &&
          this.activeScreenId !== (this.screenIds.INITIAL as keyof C))
      ) {
        return;
      }
    }
    let activeScreenId;

    if (this.isMobile) {
      // on mobile the first rendered is sidebar screen
      activeScreenId = this.screenIds.INITIAL;
      this.wasLayoutMobile = true;
    } else {
      this.wasLayoutMobile = false;
      // on desktop the first initial should be the initial child screen ID active and visible but if there is a mobile mode and the user switches back to desktop and if there is opened non default initial child screen etc. should preserve it and render on the desktop as well
      activeScreenId =
        this.activeScreenId &&
        this.activeScreenId !== (this.screenIds.INITIAL as keyof C)
          ? this.activeScreenId
          : this.initialChildScreenId;
    }

    this.setActiveScreenId(activeScreenId as any);
  };

  setActiveScreenId = <TScreenId>(
    id: TScreenId | string,
    ignoreDirty = false
  ) => {
    // the active screen has some unsaved changes and need just to show the changes alert. If there is ignore dirty was passed because the user wants to go out anyway - let's do it
    if (this.isActiveChildScreenDirty && !ignoreDirty) {
      this.showChangesAlertAndSaveAction(
        DiscardActionsEnum.GO_TO_MENU,
        id as string
      );
      return;
    }

    // cleanup on destroy if exists
    // @ts-ignore
    this.activeChildScreen?.dispose?.();

    this.activeScreenId = id as keyof T;

    this.isFirstRenderCompleted = true;
    const ChildScreenClass =
      this.childScreenClasses[id as keyof ChildScreenClasses];
    if (this.activeScreenId === (this.screenIds.INITIAL as keyof C)) {
      {
        this.renderInitialScreen();
        return;
      }
    }

    if (!ChildScreenClass) {
      // shouldn't happen. Just for devs
      throw new Error(
        `There is no such child screen class for this ID: ${String(id)}`
      );
    }

    const nextChildScreen = new ChildScreenClass() as InstanceType<T[keyof T]>;
    if (!this.sidebarModal) {
      throw new Error(
        'sidebarModal is not set. Plese, set it from the useSidebarModal hook'
      );
    }
    nextChildScreen.setSidebarModal(this.sidebarModal);

    this.childScreens[id as keyof T] = nextChildScreen;

    /** if mobile the stack should be controlled from this child screen
     * because settings menu is becoming stack entry point and all other screens
     * slides in over it. On desktop settings menu renders at the side and
     * the entry point will be every menu item initial screen
     * */
    if (this.isMobile) {
      const stackStore = this.sidebarModal?.animatedStack;
      // shouldn't do anything if the animation is running
      if (stackStore?.isAnimationRunning) {
        return;
      }
      const nextItem = nextChildScreen.activeScreen;
      stackStore?.next(nextItem.id, nextItem.Component);
    } else {
      nextChildScreen.reset();
    }
  };
  setIsShow = (isShow: boolean) => {
    if (!this.sidebarModal) {
      throw new Error(
        'sidebarModal is not set. Plese, set it from the useSidebarModal hook in your main component'
      );
    }

    if (!isShow) {
      this.resetToDefaultScreen();
    }

    this.sidebarModal?.setShow(isShow);
  };
  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  };
  back = async () => {
    const animatedStack = this.sidebarModal?.animatedStack;
    // shouldn't do anything if the animation is running
    if (animatedStack?.isAnimationRunning) {
      return;
    }

    // I don't want to get any extra validation as it could only happen when the user switches from desktop to mobile and press the button back on the intial screen to go to the Sidebar menu (on desktop it hadn't be added to history so just do it manually). Once it will be moved to the settings menu the back button won't be visible at all so this action shouldn't happen anymore

    const menuScreen = this.screensConfig[this.screenIds.INITIAL];

    animatedStack?.setPrevItem(menuScreen.id, menuScreen.Component);
    await animatedStack?.back();

    this.activeScreenId = this.screenIds.INITIAL as keyof T;
  };
  setSidebarModal = (sidebarModal: ISidebarModal) => {
    this.sidebarModal = sidebarModal;
    // everytime it's update we should update the child screen instance with the new value as well to be able to get the new snapshot
    this.activeChildScreen?.setSidebarModal(sidebarModal);

    this.onSidebarModalSet?.(sidebarModal);

    if (!this.isSidebarModalWasSetFirstTime) {
      this.isSidebarModalWasSetFirstTime = true;
      // set default global changes alert handlers
      this.setDefaultChangesAlertHanlers();
      this.onFirstSidebarModalSet?.(sidebarModal);
    }
  };

  showChangesAlertAndSaveAction = (
    discardActionId: DiscardActionsEnum,
    savedChildScreenId: string | null = null
  ) => {
    this.sidebarModal?.showChangesAlertAndSaveAction({
      discardActionId,
      savedChildScreenId,
    });
  };

  setChangesAlertConfig = (config: ChangesAlertConfig) => {
    this.sidebarModal?.setChangesAlertConfig(config);
  };

  setDefaultChangesAlertHanlers = () => {
    this.setChangesAlertConfig({
      onFirstBtnClick: this.proceedFirstBtnInteraction,
      onSecondBtnClick: this.proceedSecondBtnInteraction,
    });
  };

  proceedFirstBtnInteraction = () => {
    this.setChangesAlertConfig({
      show: false,
    });
  };

  proceedSecondBtnInteraction = () => {
    const sidebarState = this.sidebarModal?.state;

    this.setChangesAlertConfig({
      show: false,
    });

    switch (sidebarState?.activeDiscardAction) {
      case DiscardActionsEnum.BACK:
        (this.activeChildScreen || this).back();
        break;
      case DiscardActionsEnum.GO_TO_MENU:
        this.setActiveScreenId(
          sidebarState.discardActions[DiscardActionsEnum.GO_TO_MENU]
            .savedChildScreenId as any,
          true
        );
        break;
      case DiscardActionsEnum.CLOSE:
        this.setIsShow(false);
        break;
      default:
        break;
    }

    this.sidebarModal?.setActiveDiscardAction(null);
  };

  setBottomSheetConfig = (config: Partial<BottomSheetConfig>) => {
    this.sidebarModal?.setBottomSheetConfig(config);
  };

  resetToDefaultScreen = () => {
    // to avoid the content flickering effect at the moment of closing the modal
    setTimeout(() => {
      this.activeScreenId = null;
      this.setInitialScreen(true);
    }, 500);
  };
}
