import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

type ActionDropdownMenuItemProps = {
  onClick?: () => void;
  label: string;
  className?: string;
  icon?: React.ReactNode;
  iconSide?: 'left' | 'right';
};

export const ActionDropdownMenuItem = ({
  onClick,
  label,
  className,
  icon,
  iconSide = 'left',
}: ActionDropdownMenuItemProps) => {
  return (
    <li
      role="button"
      onClick={onClick}
      className={classNames(
        'themed-text themed-layout flex h-[44px] cursor-pointer items-center gap-4 px-[16px] text-body16R hover:bg-hovered-selected',
        'dark:hover:bg-hovered-selected-dark',
        className
      )}
    >
      {iconSide === 'left' && icon}

      {label}

      {iconSide === 'right' && icon}
    </li>
  );
};
