import { classNames } from '@foundationPathAlias/utilities';

export const SendSVG: React.FC<{
  width?: number;
  height?: number;
  className?: string;
}> = ({
  width = 20,
  height = 20,
  // to escape autogeneration fill prop from the story. Remove it later
  // @ts-ignore
  className,
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    className={classNames('themed-svg-fill', className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.93141 2.5512C1.69098 2.45244 1.41393 2.49889 1.22072 2.67035C1.02751 2.84181 0.953053 3.1073 1.02972 3.35138L3.1182 10L1.02972 16.6486C0.953053 16.8927 1.02751 17.1582 1.22072 17.3296C1.41393 17.5011 1.69098 17.5476 1.93141 17.4488L18.5881 10.6067C18.8376 10.5042 19 10.265 19 10C19 9.73495 18.8376 9.49579 18.5881 9.39329L1.93141 2.5512ZM4.31698 9.34212L2.73578 4.30841L16.5916 10L2.73578 15.6916L4.31697 10.6579H7.58211C7.95305 10.6579 8.25375 10.3634 8.25375 10C8.25375 9.63667 7.95305 9.34212 7.58211 9.34212H4.31698Z"
    />
  </svg>
);
