import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ScreenIds } from '../constants';

import {
  Community,
  UpdateCommunityProfileDataInput,
} from '@10x/foundation/types';
import { getRgbaString, RGBToHex } from '@foundationPathAlias/utilities';
import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@foundationPathAlias/utilities/getCloudflareSizeRecognition';
import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import { ICAPStore } from '@mainApp/src/modules/customer-admin-panel/data/types';
import { ICommunityRepository } from '@mainApp/src/repositories';
import {
  ISystemStore,
  LoadingStatusEnum,
  TextFieldType,
} from '@mainApp/src/stores';
import { CommunityColorsModel } from '@mainApp/src/stores/CommunityColors.model';
import { CommunityImagesModel } from '@mainApp/src/stores/CommunityImages.model';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

const emptyTextField = {
  value: '',
  error: null,
};

export class CustomizeStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements ICustomizeStoreLocal
{
  private showActionReactionDisposer: IReactionDisposer;
  communityId: string | null = null;
  name: TextFieldType = { ...emptyTextField };
  description: TextFieldType = { ...emptyTextField };
  topics: { [key in 'topic1' | 'topic2']: TextFieldType } = {
    topic1: { ...emptyTextField },
    topic2: { ...emptyTextField },
  };

  communityColors: CommunityColorsModel;
  communityImages: CommunityImagesModel;
  communityRepository: ICommunityRepository;

  initialLoadedData: Partial<Community> | null = null;

  capStore: ICAPStore;

  constructor() {
    super(ScreenIds, screensConfig);

    this.communityRepository = iocContainer.get(IOC_TOKENS.communityRepository);
    this.capStore = iocContainer.get(IOC_TOKENS.capStore);

    const systemStore = iocContainer.get<ISystemStore>(IOC_TOKENS.systemStore);

    this.communityColors = new CommunityColorsModel(systemStore);
    this.communityImages = new CommunityImagesModel(this.communityRepository);

    makeObservable(this, {
      name: observable,
      description: observable,
      topics: observable,
      communityColors: observable,
      communityImages: observable,
      showActionPanel: observable,
      isLoading: observable,

      updateName: action,
      updateDescription: action,
      updateTopic: action,
      loadInitialDataByCommunitySlug: action,

      activeScreen: computed,
      isInitialScreen: computed,

      activeScreenId: observable,
      isDirty: observable,

      resetData: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      back: action,
      reset: action,
    });

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );
  }

  get topicsList() {
    const { topic1, topic2 } = this.topics;

    const _topicsList = [
      { id: 1, name: topic1.value },
      { id: 2, name: topic2.value },
    ];

    return _topicsList;
  }

  get actionsPanelData() {
    return {
      cancelAction: () => {
        this.closeModal();
        this.isDirty = false
      },
      getCancelActionText: () => 'cancel',
      proceedAction: () => {
        this.submitForm();
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  async loadInitialDataByCommunitySlug(communitySlug: string) {
    runInAction(() => {
      this.isLoading = true;
    });

    const { data } = await this.communityRepository.getCommunityBySlugName(
      communitySlug
    );

    if (data) {
      runInAction(() => {
        this.initialLoadedData = data;
        this.name = { value: data.name || '', error: null };
        this.description = { value: data.description || '', error: null };
      });

      const [topic1, topic2] = data.topics || [];

      runInAction(() => {
        this.communityId = data.id || '';

        if (topic1) {
          this.topics = {
            ...this.topics,
            topic1: {
              error: null,
              value: topic1.name,
            },
          };
        }

        if (topic2) {
          this.topics = {
            ...this.topics,
            topic2: {
              error: null,
              value: topic2.name,
            },
          };
        }
      });

      runInAction(() => {
        if (data.color) {
          const [rgbaDm, rgbaLm] = [
            getRgbaString(data.color.dark)!,
            getRgbaString(data.color.light)!,
          ];

          const [hexDm, hexLm] = [
            RGBToHex(data.color.dark),
            RGBToHex(data.color.light),
          ];

          this.communityColors.setColor('dm', {
            pureRgba: data.color.dark,
            hex: hexDm,
            rgba: rgbaDm,
          });

          this.communityColors.setColor('lm', {
            pureRgba: data.color.light,
            hex: hexLm,
            rgba: rgbaLm,
          });
        }
      });

      runInAction(() => {
        if (data.logoImageUrls) {
          const logoUrl = getCloudflareSizeRecognition(
            data.logoImageUrls as string[],
            CLOUDFLARE_VARIANTS_ENUM.MEDIUM
          );

          if (logoUrl) {
            this.communityImages.setImage('logo', {
              fileUrl: logoUrl,
              status: LoadingStatusEnum.completed,
              croppedFileUrl: logoUrl,
            });
          }
        }
      });

      runInAction(() => {
        if (data.thumbnailImageUrls) {
          const thumbnailUrl = getCloudflareSizeRecognition(
            data.thumbnailImageUrls as string[],
            CLOUDFLARE_VARIANTS_ENUM.MEDIUM
          );

          if (thumbnailUrl) {
            this.communityImages.setImage('thumbnail', {
              fileUrl: thumbnailUrl,
              croppedFileUrl: thumbnailUrl,
              status: LoadingStatusEnum.completed,
            });
          }
        }
      });

      runInAction(() => {
        this.isDirty = false;
        this.isLoading = false;
      });
    }
  }

  updateName = (val: TextFieldType) => {
    this.name = val;
    runInAction(() => {
      this.isDirty = true;
    });
  };
  updateDescription = (val: TextFieldType) => {
    this.description = val;
    runInAction(() => {
      this.isDirty = true;
    });
  };
  updateTopic = (topicNumber: 1 | 2, val: TextFieldType) => {
    const topicName: 'topic1' | 'topic2' = `topic${topicNumber}`;
    this.topics = {
      ...this.topics,
      [topicName]: val,
    };
    runInAction(() => {
      this.isDirty = true;
    });
  };

  private get backendGeneralPayload() {
    const resultValue: Pick<
      ICustomizeBackendPayload,
      'name' | 'description' | 'color' | 'topics'
    > = {
      name: this.name.value,
      description: this.description.value,
      topics: Object.values(this.topics)
        .filter((o) => Boolean(o.value))
        .map((o) => o.value),
    };

    if (
      this.communityColors.lmPrimaryColor &&
      this.communityColors.dmPrimaryColor
    ) {
      resultValue.color = {
        light: this.communityColors.lmPrimaryColor?.pureRgba,
        dark: this.communityColors.dmPrimaryColor?.pureRgba,
      };
    }

    return resultValue;
  }

  private get backendImagesPayload() {
    let resultValue: Pick<
      ICustomizeBackendPayload,
      'thumbnail' | 'logo'
    > | null = null;

    if (this.communityImages.thumbnail.id) {
      resultValue = {};
      resultValue['thumbnail'] = this.communityImages.thumbnail.id;
    }

    if (this.communityImages.logo.id) {
      if (!resultValue) {
        resultValue = {};
      }
      resultValue['logo'] = this.communityImages.logo.id;
    }

    return resultValue;
  }

  get backendPayload() {
    return {
      ...this.backendGeneralPayload,
      ...this.backendImagesPayload,
    };
  }

  private closeModal() {
    if (this.isDirty) {
      this.setChangesAlertConfig({
        show: true,
        onSecondBtnClick: () => {
          this.resetData();
          this.capStore.setIsShow(false);
          this.setChangesAlertConfig({ show: false });
        },
      });
      return;
    }

    this.setChangesAlertConfig({
      onSecondBtnClick: this.capStore.proceedSecondBtnInteraction,
    });

    this.capStore.setIsShow(false);
  }

  submitForm = async () => {
    if (!this.communityId) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });
    const dataResult = await this.communityRepository.updateCommunityProfile(
      this.communityId,
      this.backendPayload
    );

    runInAction(() => {
      this.isLoading = false;
    });

    if (dataResult.data && !dataResult.error) {
      runInAction(() => {
        this.isDirty = false;
      });
      this.closeModal();
    }
  };

  resetTextFields = (field: 'name' | 'description') => {
    this[field] = { ...emptyTextField };
  };

  resetTopics = () => {
    this.topics = {
      topic1: { ...emptyTextField },
      topic2: { ...emptyTextField },
    };
  };

  resetData = () => {
    this.communityImages.reset('logo');
    this.communityImages.reset('thumbnail');
    this.resetTextFields('name');
    this.resetTextFields('description');
    this.resetTopics();
    this.communityId = null;
    this.initialLoadedData = null;
  };

  dispose = () => {
    this.showActionReactionDisposer();
  };
}

interface ITopic {
  id: number;
  name: string;
}

type ICustomizeBackendPayload = UpdateCommunityProfileDataInput & {
  thumbnail?: string;
  logo?: string;
};

export interface ICustomizeStoreLocal
  extends ISidebarModalChildScreen<ScreenIdsValuesType> {
  readonly name: TextFieldType;
  readonly description: TextFieldType;
  readonly topics: {
    topic1: TextFieldType;
    topic2: TextFieldType;
  };
  readonly topicsList: ITopic[];

  communityColors: CommunityColorsModel;
  communityImages: CommunityImagesModel;

  updateName: (val: TextFieldType) => void;
  updateDescription: (val: TextFieldType) => void;
  updateTopic: (topicNumber: 1 | 2, val: TextFieldType) => void;
  submitForm: () => Promise<void>;
  loadInitialDataByCommunitySlug: (communitySlug: string) => Promise<void>;
}
