import { computed, makeAutoObservable } from 'mobx';

import { RGB } from '@10x/foundation/src/components/color-picker/ColorPicker';
import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { Community, Role_Levels } from '@10x/foundation/types/graphql-schema';

import { ISystemStore } from './types';

const communityMock = {
  id: 'community_mock_id',
  name: 'Community Name',
  description: 'Community Description',
  slugName: 'community_mock_slug_name',
  members: {
    totalCount: 0,
  },
  onlineMembers: {
    totalCount: 0,
  },
  topics: [
    {
      id: 'community_topic_1_mock_id',
      name: 'Fly',
      __typename: 'Topic',
    },
    {
      id: 'community_topic_2_mock_id',
      name: 'Pizza',
      __typename: 'Topic',
    },
  ],
  thumbnailImageUrls: [
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/2560x1440?sig=ec5a16a61bc9b079b38464bb07f51f92e36bd6c138f6d4be04cb352148704c71',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/1280x720?sig=de8898e2c82d1a4c2ecf3aa94fc2899cdb60c55dab62f2c2920ee36c187e5ad7',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/1280x720blurred?sig=779b1219bedede9883965e27d707b08d472788fdceff3b7f988ec6f3ef35c6a1',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/public?sig=32476c236ae2fd1804302982bbfac74b818c490a9d451957bea3fd0557c37598',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/1080x1080?sig=4e6e13a00e0b4c75216dbadcaff5f62e36ab7bc5043e516d5e27cf6f56019aa1',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/820x520?sig=29f35daa54fd1cc39e1e4099afd8291062a8218b89f9fa013138b6e0e72b30d3',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/edcdbccd-ff8c-4746-9be5-bb61fb696201/20x20?sig=5b136afcc0c13419a7f6f238e5ebd701f31d4cef1cb93f818e4998512db32820',
  ],
  logoImageUrls: [
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/1280x720?sig=197dfbc6ad1ce8d5d659015784a98e1ff2eec40903f0b5694442b74aad73c503',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/public?sig=696576434cce2e94f433e6e9ed3dac4f4f45744ebd5b44a21811f309262cee93',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/820x520?sig=3e1572c36f61f857debb6b5e55359740136db6a081e7d73db918cd8ab6a337d9',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/2560x1440?sig=01930a949c754a466b56809bc052e9830e7c8021a13de2bdda14b197d6129648',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/1080x1080?sig=d49cba1ed44dda25c87a4809036d256f7037a9e74fa9354d4cd754fcaf608347',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/1280x720blurred?sig=5d86a029593d9d7c4cd06785f3fd1531be2d7225dbf1cb078541728ec326d178',
    'https://imagedelivery.net/YxxwLb8iGmDgEvHwkT_Ldw/dbdb4522-c5ca-41a2-efe2-e841b5268c01/20x20?sig=a239e5ca824f68041b5d5fc05092be01989a08b95ef589c1c34d9254371cd736',
  ],
  color: {
    light: {
      r: 101,
      g: 99,
      b: 255,
      a: 1,
    },
    dark: {
      r: 101,
      g: 99,
      b: 255,
      a: 1,
    },
    __typename: 'CommunityColorPayload',
  },
  __typename: 'Community',
};

export class CommunityModel {
  //   repository: IOnboardingRepositoryInterface;

  systemStore: ISystemStore;

  // id = '';
  // TODO: types

  serverData: Community;

  // name = '';
  // description = '';
  // slugName = '';
  // membersCount = 0;
  // onlineMembersCount = 0;
  // topics: Topic[] = [];
  // thumbnailImageUrls: string[] = [];
  // logoImageUrls: string[] = [];
  // userAssignedRoles: Role_Levels[] = [];
  // color: CommunityColorPayload = {
  //   light: {
  //     r: 101,
  //     g: 99,
  //     b: 255,
  //     a: 1,
  //   },
  //   dark: {
  //     r: 101,
  //     g: 99,
  //     b: 255,
  //     a: 1,
  //   },
  // };

  get isUserVisitorOrNonMember() {
    return this.isUserVisitor || this.isUserNonMember;
  }

  get isUserVisitor() {
    return this.serverData.userAssignedRoles.includes(Role_Levels.Visitor);
  }
  get isUserNonMember() {
    return this.serverData.userAssignedRoles.includes(Role_Levels.NonMember);
  }
  get isUserMember() {
    return this.serverData.userAssignedRoles.includes(Role_Levels.Member);
  }
  get isUserAdmin() {
    return this.serverData.userAssignedRoles.includes(Role_Levels.Admin);
  }
  get isUserOwner() {
    return this.serverData.userAssignedRoles.includes(Role_Levels.Owner);
  }
  get isUserAdminOrOwner() {
    return this.isUserAdmin || this.isUserOwner;
  }

  static generateMockedCommunityModel(systemStore: ISystemStore) {
    return new CommunityModel({ ...communityMock }, systemStore);
  }

  constructor(data: any, systemStore: ISystemStore) {
    makeAutoObservable(this, {
      isUserVisitor: computed,
      isUserNonMember: computed,
      isUserVisitorOrNonMember: computed,
    });
    this.systemStore = systemStore;
    // experiment. Should contain all the BE data
    this.serverData = data;

    // this.id = data.id;
    // this.description = data.description;
    // this.name = data.name;
    // this.slugName = data.slugName;
    // this.topics = data.topics;
    // this.membersCount = data.members.totalCount;
    // this.onlineMembersCount = data.onlineMembers.totalCount;
    // this.thumbnailImageUrls = data.thumbnailImageUrls;
    // this.logoImageUrls = data.logoImageUrls;
    // this.color = data.color;
    // this.userAssignedRoles = data.userAssignedRoles;
  }

  getThumbnailUrl(prefferedSize?: CLOUDFLARE_VARIANTS_ENUM) {
    return getCloudflareSizeRecognition(
      this.serverData.thumbnailImageUrls as string[],
      prefferedSize
    );
  }

  getLogoUrl(
    prefferedSize: CLOUDFLARE_VARIANTS_ENUM = CLOUDFLARE_VARIANTS_ENUM.SQUARE
  ) {
    return getCloudflareSizeRecognition(
      this.serverData.logoImageUrls,
      prefferedSize
    );
  }
  get themedColor(): RGB {
    // TODO: Root store in model isn't good
    return this.systemStore?.isDarkTheme
      ? this.serverData.color.dark
      : this.serverData.color.light;
  }

  // for the temporary storing in localStorage
  get asJson() {
    return {};
  }

  // for BE integration - create community
  get backendPayload() {
    return {};
  }
}
