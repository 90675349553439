export const PLAN_DETAILS = {
  free: {
    id: 'free',
    title: 'Totally free',
    description: 'No credit card required',
    priceText: '$0',
    price: 0,
    offer: '',
    features: [
      'Up to 30 days message history',
      'Up to 500 messages per month',
      'Up to 50 image attachments per month',
      'Admin, member & visitor roles',
      'Up to 20 members',
    ],
    styles: {
      outerCard:
        'relative flex-grow w-[310px] md:w-[427px] flex-col bg-background-primary dark:bg-background-website-dark flex justify-center rounded-[22px] h-[619px]', // Fixed height
      innerCard:
        'mx-[2px] mb-[2px] mt-[50px] bg-background-secondary dark:bg-pro-dark rounded-[20px] p-[24px] flex flex-col h-full', // Fill height
      button:
        'h-[40px] rounded-[20px] border-[.5px] w-full border-element-subtle text-sm14SB themed-text',
    },
  },
  highlighted: {
    id: 'paid',
    title: 'Ridiculously cheap',
    description: 'Start for free. Then usage-based',
    priceText: '$0',
    price: 0.5,
    offer: 'Best deal',
    features: [
      'Up to 90 days message history',
      'Up to 1.5k messages per month. Then, $25/mo/ additional 10k.',
      'Up to 150 image attachments per month. Then, $10/ mo/additional 1k.',
      'Admin, member & visitor roles',
      'Unlimited members',
      'Up to 5 channels',
    ],
    styles: {
      outerCard:
        'relative flex-grow w-[310px] md:w-[427px] flex-col bg-gradient-to-r from-tab-customize to-tab-create flex justify-center rounded-[22px] h-[619px]', // Fixed height
      innerCard:
        'mx-[2px] mb-[2px] mt-[50px] bg-background-primary dark:bg-background-website-dark rounded-[20px] p-[24px] flex flex-col h-full', // Fill height
      button:
        'h-[40px] rounded-[20px] w-full bg-gradient-to-r from-tab-customize to-tab-create text-sm14SB text-background-primary',
    },
  },
};

export const PRICING_DATA = [
  {
    sectionTitle: 'Content types',
    items: [
      {
        title: 'Messages',
        price: 25,
        features: [
          'Includes 10k messages per month',
          'Unlimited history',
          'Includes 1k attachments',
          '14-day free trial',
        ],
      },
      {
        title: 'Posts',
        price: 25,
        features: [
          'Includes 500 posts per month',
          'Usage-based pricing',
          '14-day free trial',
        ],
      },
      {
        title: 'Courses',
        price: 50,
        features: [
          'Includes courses channel',
          'Includes 5 courses',
          'Additional courses $10/mo/course',
          '14-day free trial',
        ],
      },
    ],
  },
  {
    sectionTitle: 'Website embeds',
    items: [
      {
        title: 'Website blocks',
        price: 25,
        features: [
          '50k views included per block',
          '35/mo AI feed generation',
          '14-day free trial',
        ],
      },
      {
        title: 'Customer support chat',
        price: 25,
        features: [
          '__[contacts or active messages]/mo included. Usage based pricing after',
          '14 day free trial',
        ],
      },
    ],
  },
  {
    sectionTitle: 'Add-ons',
    items: [
      {
        title: 'Admin',
        price: 10,
        features: ['Includes 1 admin role'],
        allowQuantity: true,
      },
      {
        title: 'Custom roles',
        price: 10,
        features: ['Includes 2 custom roles'],
        allowQuantity: true,
      },
      {
        title: 'Moderators',
        price: 10,
        features: ['Includes 10 moderator roles'],
        allowQuantity: true,
      },
      {
        title: 'Channels',
        price: 10,
        features: ['Includes 20 channels'],
        allowQuantity: true,
      },
      {
        title: 'Private Community',
        price: 10,
        features: ['Only invited members'],
      },
      {
        title: 'AI content analysis',
        price: 10,
        features: [
          'Includes analysis for up to ___ content items. Usage based pricing after.',
        ],
      },
    ],
  },
];

export const FREE_ADD_ONS = [
  '2 admins',
  '2 custom roles',
  '10 moderators',
  '20 channels',
];

export const PRICING_THRESHOLD_FOR_FREE_ADD_ONS = {
  min: 20,
  max: 50,
};

export const PRICING_FREE_ADD_ONS = [
  {
    title: 'Admin (x2)',
    features: ['Includes 2 admin roles'],
    price: 20,
  },
  {
    title: 'Custom roles',
    features: ['Includes 2 custom roles'],
    price: 10,
  },
  {
    title: 'Moderators',
    features: ['Includes 10 moderators'],
    price: 10,
  },
  {
    title: 'Channels',
    features: ['Includes 20 channels'],
    price: 10,
  },
];
