export const HashTagMessageSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 16, height = 16, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4094 0.904709C6.83469 0.951963 7.14114 1.33503 7.09388 1.76031L6.67296 5.5486H9.76256L10.2025 1.58919C10.2497 1.16391 10.6328 0.857456 11.0581 0.904709C11.4834 0.951963 11.7898 1.33503 11.7426 1.76031L11.3217 5.5486H14.0717C14.4996 5.5486 14.8465 5.89548 14.8465 6.32338C14.8465 6.75128 14.4996 7.09816 14.0717 7.09816H11.1495L10.9773 8.64773H9.59517C9.53471 8.64773 9.47485 8.65351 9.41632 8.66477L9.59038 7.09816H6.50079L6.15644 10.1973H8.64821V11.7469H5.98427L5.54432 15.7064C5.49707 16.1317 5.114 16.4381 4.68872 16.3909C4.26343 16.3436 3.95698 15.9606 4.00423 15.5353L4.42517 11.7469H1.67517C1.24727 11.7469 0.900391 11.4 0.900391 10.9721C0.900391 10.5442 1.24727 10.1973 1.67517 10.1973H4.59734L4.94169 7.09816H1.67517C1.24727 7.09816 0.900391 6.75128 0.900391 6.32338C0.900391 5.89548 1.24727 5.5486 1.67517 5.5486H5.11386L5.5538 1.58919C5.60105 1.16391 5.98412 0.857456 6.4094 0.904709ZM16.8356 15.4265C17.0051 15.2569 17.1004 15.027 17.1004 14.7873V10.9603C17.1004 10.7206 17.0051 10.4907 16.8356 10.3212C16.6661 10.1517 16.4362 10.0564 16.1965 10.0564H10.9608C10.7211 10.0564 10.4912 10.1517 10.3216 10.3212C10.1521 10.4907 10.0569 10.7206 10.0569 10.9603V17.0999L11.8647 15.6912H16.1965C16.4362 15.6912 16.6661 15.596 16.8356 15.4265Z"
      // fill="#1A1A1A"
      fillOpacity="0.7"
    />
  </svg>
);
