import { Toast } from '@10x/foundation/src/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
export function _ToastManager() {
  const toastStore = useInjection(IOC_TOKENS.toastStore);
  const { active, data, hide } = toastStore;
  if (!data) return null;
  return (
    <Toast active={active} type={data.type} title={data.title} onClose={hide}>
      {data.content}
    </Toast>
  );
}

export const ToastManager = observer(_ToastManager);
