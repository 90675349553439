import { classNames } from '@foundationPathAlias/utilities';
import { ButtonBasePropsType } from './ButtonBase';
import { OutlineButton } from './OutlineButton';

type Props = {
  iconWrapperCn?: string;
} & ButtonBasePropsType;

export function OutlineIconButton(props: Props) {
  const { cn, children, iconWrapperCn, ...otherProps } = props;
  return (
    <OutlineButton cn={classNames('outline-icon-button', cn)} {...otherProps}>
      <span
        className={classNames(
          '"flex dark:fill-text-primary-dark" h-[16px] w-[16px] flex-1 scale-125  fill-color-1',
          iconWrapperCn
        )}
      >
        {children}
      </span>
    </OutlineButton>
  );
}
