import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import type { IInteractiveStore } from '@mainApp/src/stores';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { action, makeObservable, observable } from 'mobx';
// .local - means it isn't in the IOC system. It's just a small store for local only purpose
export class DeleteMessageModalStoreLocal {
  messageModel: MessageModel | null = null;
  isShow = false;

  interactiveStore: IInteractiveStore;

  constructor() {
    this.interactiveStore = iocContainer.get(IOC_TOKENS.interactiveStore);
    makeObservable(this, {
      isShow: observable,
      show: action,
      hide: action,
      messageModel: observable,
    });
  }

  show = (messageModel: MessageModel) => {
    this.messageModel = messageModel;
    this.isShow = true;
  };

  hide = () => {
    this.isShow = false;
    this.messageModel = null;
  };
}

export const deleteMessageModalStoreLocal = new DeleteMessageModalStoreLocal();
