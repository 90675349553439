import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

import { displaySettingsComponents } from './displayComponents';

import { IAnimatedStackStore } from '@mainApp/src/components/common';
import { DisplaySettingsEnum } from '@mainApp/src/modules/account-settings/data/types';

enableStaticRendering(typeof window === 'undefined');

export class DisplayStore implements IDisplayStore {
    stackStore: IAnimatedStackStore;
    activeSettingId: DisplaySettingsEnum = DisplaySettingsEnum.INITIAL;

    get initialSetting() {
        return DisplaySettingsEnum.INITIAL;
    }

    get activeSetting() {
        return displaySettingsComponents[this.activeSettingId];
    }

    get isInitialScreen() {
        return this.activeSettingId === DisplaySettingsEnum.INITIAL;
    }

    constructor(stackStore: IAnimatedStackStore) {
        makeObservable(this, {
            activeSetting: computed,
            isInitialScreen: computed,

            activeSettingId: observable,
            setActiveSettingId: action,
            setNextSettingId: action,
            back: action,
            reset: action,
        });

        this.stackStore = stackStore;
    }

    setActiveSettingId = (id: DisplaySettingsEnum) => {
        if (this.activeSettingId === id) return;

        this.activeSettingId = id;
    };

    setNextSettingId = (setting: DisplaySettingsEnum) => {

        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }

        this.activeSettingId = setting;

        const nextItem = displaySettingsComponents[setting];
        stackStore.next(nextItem.id, nextItem.Component);
    };
    back = () => {
        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }

        const prevSettingId = stackStore.back();

        if (!prevSettingId) return;

        this.activeSettingId = prevSettingId as DisplaySettingsEnum;
    };

    reset = () => {
        this.setActiveSettingId(this.initialSetting);
        this.stackStore.reset();
        this.stackStore.setVisible(
            this.activeSetting.id,
            this.activeSetting.Component
        );
    };
}
type SettingComponentsConfig = typeof displaySettingsComponents;

export interface IDisplayStore {
    stackStore: IAnimatedStackStore;
    activeSettingId: DisplaySettingsEnum
    readonly isInitialScreen: boolean;
    readonly initialSetting: DisplaySettingsEnum;
    readonly activeSetting: SettingComponentsConfig[keyof SettingComponentsConfig];
    setActiveSettingId: (setting: DisplaySettingsEnum) => void;
    setNextSettingId: (setting: DisplaySettingsEnum) => void;
    back: () => void;
    reset: () => void;
}
