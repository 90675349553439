import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  OutlineButton,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';

import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { ISubscriptionStore } from './data';

export type Props = {
  onProceedClick: () => Promise<boolean>;
  proceedBtnText: string;
};

export function _SubscriptionActions(props: Props) {
  const { t } = useTranslation('common');
  const { onProceedClick, proceedBtnText } = props;
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const subscriptionStore =
    accountSettingsStore.activeSettingStore as ISubscriptionStore;
  const loading = useMinLoading(subscriptionStore.isLoading, 1000);
  return (
    <>
      <OutlineButton
        onClick={() => {
          accountSettingsStore.setChangesAlertConfig({
            show: false,
          });
        }}
      >
        {t('cancel')}
      </OutlineButton>
      <PrimaryButtonWithLoader
        loading={loading}
        dynamicColorsMode={true}
        onClick={() => {
          onProceedClick().then((isError) => {
            if (isError) return;
            accountSettingsStore.setChangesAlertConfig({
              show: false,
            });
          });
        }}
      >
        {loading ? t('processing') : proceedBtnText}
      </PrimaryButtonWithLoader>
    </>
  );
}

export const SubscriptionActions = observer(_SubscriptionActions);
