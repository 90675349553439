import { FormFieldInput } from '@mainApp/src/components/common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { UsersList } from '@mainApp/src/modules/channel/create-channel-modal/UsersList';

import { Role_List_Types } from '@10x/foundation/types';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { ItemHeader } from '@mainApp/src/modules/channel/channel-settings/components';
import { observer } from 'mobx-react-lite';
import { PermissionsStore } from '../data/Permissions.store.local';

export function _Visibility() {
  const { channelSettingsStore, communityStore } = useMultipleInjection([
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.communityStore,
  ]);

  const permissionsStoreLocal =
    channelSettingsStore.activeChildScreen as PermissionsStore;

  const {
    search,
    filteredRoles,
    filteredMembers,
    addedMembersAndRolesList,
    removeRoleOrMember,
    addRoleOrMember,
    onViewRoleMembers,
    loadCommunityRoles,
    setSearch,
  } = permissionsStoreLocal;

  // const [search, setSearch] = useState('');
  const { t } = useTranslation(['common', 'channel']);

  useEffect(() => {
    const communityId =
      permissionsStoreLocal.channelModel?.serverData.communityId;
    if (!communityId) {
      throw new Error('Community id is not defined');
    }
    communityStore.getCommunityMembers({
      communityId,
    });
    loadCommunityRoles(communityId, Role_List_Types.Community);
  }, []);

  return (
    <div>
      <ItemHeader cn="mb-[12px]">
        {t('channel:channelSettings.channelVisibility')}
      </ItemHeader>
      <FormFieldInput
        inputName="search"
        cnWrapper="mt-3 mb-6"
        onChange={setSearch}
        value={search.data as string}
        placeholder={t<string>('channel:modal.findMembersAndRoles')}
        leftSideContent={null}
        rightSideContent={search.loading ? <SmallLoader /> : null}
      />

      <div className="flex h-[calc(100vh-300px)] flex-[1] flex-col overflow-y-auto scrollbar-thin md:h-[350px]">
        <UsersList
          list={addedMembersAndRolesList}
          onAction={removeRoleOrMember}
          onClickRow={(row) => {
            onViewRoleMembers(row.id);
          }}
          actionType="remove"
        />
        {addedMembersAndRolesList.length > 0 && (
          <hr className="mb-4 text-element-subtle dark:text-element-subtle-dark" />
        )}

        {!filteredRoles.length && !filteredMembers.length && (
          <b className="self-center text-body16SB text-text-primary dark:text-text-primary-dark">
            {t('channel:modal.noRolesOrMembersFound')}
          </b>
        )}

        <UsersList
          title={t<string>('channel:modal.roles')}
          titleCn="block text-left"
          list={filteredRoles}
          onAction={addRoleOrMember}
          onClickRow={(row) => {
            () => {
              onViewRoleMembers(row.id);
            };
          }}
          actionType="add"
        />

        <UsersList
          title={t<string>('channel:modal.members')}
          titleCn="block text-left"
          list={filteredMembers}
          onAction={addRoleOrMember}
          actionType="add"
          onScrollEnd={communityStore.fetchMoreMembers}
          infinityScroll
        />
      </div>
    </div>
  );
}

export const Visibility = observer(_Visibility);
