import { ChangesAlertProps } from '@foundationPathAlias/main';
import { ACTIONS, BottomSheetConfig, DiscardActionsEnum, ISidebarModal, SetDiscardActionsPayload, ShowChangesAlertAndSaveActionPayload, sidebarModalkReducer, initialState as sidebarModalReducerInitialState } from '@foundationPathAlias/widgets/sidebar-modal/data';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { useAnimatedStack } from '@foundationPathAlias/components/animated-stack/useAnimatedStack';

import { emitEvent, sidebarModalEventBus, SidebarModalEventsInternal } from '@foundationPathAlias/widgets/sidebar-modal/events/sidebarModalEventBus';

export function useSidebarModal(): ISidebarModal {
    const animatedStack = useAnimatedStack();
    const [state, dispatch] = useReducer(sidebarModalkReducer, {
        ...sidebarModalReducerInitialState,

    });

    const setDiscardActions = useCallback((payload: SetDiscardActionsPayload) => {
        dispatch({
            type: ACTIONS.SET_DISCARD_ACTIONS,
            payload
        });
        emitEvent<SetDiscardActionsPayload>(SidebarModalEventsInternal.SET_DISCARD_ACTIONS, payload,);
    }, []);

    const setActiveDiscardAction = useCallback((payload: DiscardActionsEnum | null) => {
        dispatch({
            type: ACTIONS.SET_ACTIVE_DISCARD_ACTION,
            payload
        });
        emitEvent<DiscardActionsEnum | null>(SidebarModalEventsInternal.SET_ACTIVE_DISCARD_ACTION, payload);
    }, []);


    const setChangesAlertConfig = useCallback((config: Partial<ChangesAlertProps>) => {
        dispatch({
            type: ACTIONS.SET_CHANGES_ALERT_CONFIG,
            payload: config,
        });
        emitEvent<Partial<ChangesAlertProps>>(SidebarModalEventsInternal.SET_CHANGES_ALERT_CONFIG, config);
    }, []);

    const showChangesAlertAndSaveAction = useCallback((payload: ShowChangesAlertAndSaveActionPayload) => {
        dispatch({
            type: ACTIONS.SHOW_CHANGES_ALERT_AND_SAVE_ACTION,
            payload,
        });
        emitEvent<ShowChangesAlertAndSaveActionPayload>(SidebarModalEventsInternal.SHOW_CHANGES_ALERT_AND_SAVE_ACTION, payload);
    }, []);

    const setShow = useCallback((show: boolean) => {
        dispatch({ type: ACTIONS.SET_SHOW, payload: show });
        emitEvent<boolean>(SidebarModalEventsInternal.SET_SHOW, show);
    }, []);

    const setBottomSheetConfig = useCallback((config: Partial<BottomSheetConfig>) => {
        dispatch({
            type: ACTIONS.SET_BOTTOM_SHEET_CONFIG,
            payload: config,
        });
        emitEvent<Partial<BottomSheetConfig>>(SidebarModalEventsInternal.SET_BOTTOM_SHEET_CONFIG, config);
    }, []);

    const sidebarModal = useMemo(() => ({
        state,
        animatedStack,
        sidebarModalEventBus,
        setDiscardActions,
        setActiveDiscardAction,
        setChangesAlertConfig,
        showChangesAlertAndSaveAction,
        setShow,
        setBottomSheetConfig,
    }), [
        state,
        animatedStack,
        setDiscardActions,
        setActiveDiscardAction,
        setChangesAlertConfig,
        showChangesAlertAndSaveAction,
        setShow,
        setBottomSheetConfig]);

    useEffect(() => {
        emitEvent<ISidebarModal>(SidebarModalEventsInternal.SET_SIDEBAR_MODAL, sidebarModal);
    }, [sidebarModal])

    return sidebarModal
}
