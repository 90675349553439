import {
  CHANNEL_MESSAGE_MARK_ALL_AS_READ,
  CHANNEL_MESSAGE_MARK_ALL_AS_UNREAD,
  CREATE_CHANNEL_MESSAGE,
  DELETE_CHANNEL_MESSAGE,
  GET_CHANNEL_MESSAGES,
  PRE_CREATE_CHANNEL_MESSAGE,
  SAVE_CHANNEL_MESSAGE_REACTION,
  UPDATE_CHANNEL_MESSAGE,
} from '@10x/main_app/src/graphql/queries/index';

import {
  ChannelMessageUpdateInput,
  ContentReactionInput,
} from '@10x/foundation/types/graphql-schema';

import { Client } from './types';

import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import { GraphQLSubscription } from './GraphQLSubscription';
import {
  CreateMessagePayload,
  IMessageRepository,
  MessagesOptions,
} from './Message.repository.types';

export * from './Message.repository.types';
@injectable()
export class MessageRepository implements IMessageRepository {
  gqlClient;

  channelMesssagesQueryName = 'GET_CHANNEL_MESSAGES';
  // TODO: injection
  graphQLSubscription;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;

    // TODO: injection
    this.graphQLSubscription = new GraphQLSubscription();
  }
  // unsubscribeFromExistingMessagesQuery: () => void;
  // subscribeToGetChannelMessages(params: { communityId: string; channelId: string; options: MessagesOptions; }, beforeSubscriptionCb: () => void, subscribeCb: (response: BaseRepositoryResponse<ChannelMessagePayloadEdge[]>) => void): void {
  //   throw new Error('Method not implemented.');
  // }

  async createChannelMessage(
    communityId: string,
    channelId: string,
    payload: CreateMessagePayload
  ) {
    const { real, optimistic } = payload;

    const urqlVariables = {
      communityId,
      channelId,
      data: real,
    };

    if (optimistic) {
      // @ts-ignore
      urqlVariables.extra = {
        optimisticPayload: optimistic,
      };
    }

    const response = await this.gqlClient
      .mutation(CREATE_CHANNEL_MESSAGE, urqlVariables)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.createChannelMessage,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async updateChannelMessage(
    communityId: string,
    channelId: string,
    id: string,
    payload: ChannelMessageUpdateInput
  ) {
    const urqlVariables = {
      communityId,
      channelId,
      id,
      data: payload,
    };

    const response = await this.gqlClient
      .mutation(UPDATE_CHANNEL_MESSAGE, urqlVariables)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.updateChannelMessage,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async jumpTo(communityId: string, channelId: string, cursor: string) {
    const urqlVariables = {
      communityId,
      channelId,
      jumpTo: cursor,
    };

    const response = await this.gqlClient
      .query(GET_CHANNEL_MESSAGES, urqlVariables)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.channelMessages.edges,
      error: error,
      pageInfo: data.pageInfo,
      originalResponse: response,
    };

    return res;
  }

  async channelMessageMarkAllAsRead(
    communityId: string,
    channelId: string,
    timestamp?: number
  ) {
    const urqlVariables = {
      communityId,
      channelId,
      option: {
        timestamp,
      },
    };

    const response = await this.gqlClient
      .mutation(CHANNEL_MESSAGE_MARK_ALL_AS_READ, urqlVariables)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.channelMessageMarkAllAsRead,
      error: error,
      pageInfo: data.pageInfo,
      originalResponse: response,
    };

    return res;
  }
  async channelMessageMarkAllAsUnRead(
    communityId: string,
    channelId: string,
    timestamp: number
  ) {
    const urqlVariables = {
      communityId,
      channelId,
      data: {
        timestamp,
      },
    };

    const response = await this.gqlClient
      .mutation(CHANNEL_MESSAGE_MARK_ALL_AS_UNREAD, urqlVariables)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.channelMessagemarkAsUnread,
      error: error,
      pageInfo: data.pageInfo,
      originalResponse: response,
    };

    return res;
  }

  async preCreateChannelMessage(communityId: string, channelId: string) {
    const response = await this.gqlClient
      .mutation(PRE_CREATE_CHANNEL_MESSAGE, {
        communityId,
        channelId,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.preCreateChannelMessage,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async deleteChannelMessage(
    communityId: string,
    channelId: string,
    id: string
  ) {
    const response = await this.gqlClient
      .mutation(DELETE_CHANNEL_MESSAGE, {
        communityId,
        channelId,
        id,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.deleteChannelMessage,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getChannelMessages(
    communityId: string,
    channelId: string,
    options: MessagesOptions
  ) {
    const response = await this.gqlClient
      .query(GET_CHANNEL_MESSAGES, {
        communityId,
        channelId,
        ...options,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data?.channelMessages?.edges,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  }

  async saveChannelMessageReaction(
    communityId: string,
    channelId: string,
    id: string,
    dataInput: ContentReactionInput
  ) {
    const urqlVariables = {
      communityId,
      channelId,
      id,
      data: dataInput,
    };

    const response = await this.gqlClient
      .mutation(SAVE_CHANNEL_MESSAGE_REACTION, urqlVariables)
      .toPromise();

    const { data } = response;
    const res = data;
    return res;
  }
}
