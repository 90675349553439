import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

type TooltipMenuItemProps = {
  onClick?: () => void;
  label: string;
  className?: string;
  icon?: React.ReactNode;
};

export const TooltipMenuItem = ({
  onClick,
  label,
  className,
  icon,
}: TooltipMenuItemProps) => {
  return (
    <li
      role="button"
      onClick={onClick}
      className={classNames(
        'flex h-[44px] cursor-pointer items-center gap-4 bg-background-primary px-[16px] text-body16R text-text-primary hover:bg-hovered-selected dark:bg-background-tetriary-dark',
        'dark:text-text-primary-dark dark:hover:bg-hovered-selected-dark',
        className
      )}
    >
      {icon}

      {label}
    </li>
  );
};
