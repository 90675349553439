import { UserModel } from '@mainApp/src/stores/User.model';

import {
  MentionUserPopoverLoader,
  withLoading,
} from '@mainApp/src/components/loaders';

import { withMentionsPopoverContentHOC } from './withMentionsPopoverContentHOC';

export type Props = {
  data: UserModel;
};
export function MentionsPopoverUserContent(props: Props) {
  const { data: userModel } = props;
  if (!userModel || !userModel.serverData) return null;

  const { firstName, lastName, username, numericId, bio, website } =
    userModel.serverData;
  return (
    <>
      <figure className="mb-[32px] flex flex-col items-center">
        <img
          className="mb-[16px] h-[120px] w-[120px] rounded-full object-cover"
          src={userModel.getAvatar()}
          alt="User avatar"
        />

        <figcaption className="flex flex-col items-center text-body16SB">
          <b className="themed-text text-subheadingSB">
            {firstName} {lastName}
          </b>
          <p className="body16SB themed-text-secondary">
            @{username} #{numericId}
          </p>
        </figcaption>
      </figure>
      <div className="mb-[24px]">
        <h6 className="themed-text mb-[8px] text-body16SB">Bio</h6>
        <p className="themed-text-secondary text-body16R">
          {bio ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna elit, posuere non ex vel, accumsan.'}
        </p>
      </div>

      <h6>Website URL</h6>
      <a
        className="hover-el text-body16SB text-primary-100 outline-none"
        href={website || 'http://website.dribbble.com'}
      >
        {website || 'http://website.dribbble.com'}
      </a>
    </>
  );
}

export const MentionsPopoverUserContentWithLoader =
  withMentionsPopoverContentHOC(
    withLoading(MentionsPopoverUserContent, MentionUserPopoverLoader, {
      // to avoid parent component padding shift because svg has full w/h
      className: 'absolute left-0 top-0',
    })
  );
