import {
  Modal as FNDModal,
  ModalPropsType,
} from '@10x/foundation/src/components/modal/Modal';
import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { CreateCommunityStepper } from './CreateCommunityStepper';

export function _CreateCommunityStepperModal(
  props: Partial<ModalPropsType> = {}
) {
  const { createCommunityStepperStore, dimensionsStore } = useMultipleInjection(
    [IOC_TOKENS.createCommunityStepperStore, IOC_TOKENS.dimensionsStore]
  );

  const { isModalOpened, setModal } = createCommunityStepperStore;

  const closeModal = () => {
    setModal(false);
  };

  let rootCn = 'w-full ';
  let contentWrapperCn = 'w-full md:w-auto';
  let contentCn = 'flex overflow-hidden h-full w-full md:w-auto ';

  if (dimensionsStore.isMobile) {
    rootCn = rootCn + 'p-0 min-h-screen ';
    contentWrapperCn = contentWrapperCn + 'max-w-full p-0 min-h-full';
  } else {
    rootCn = rootCn + 'my-[20px] px-[10px] ';
    contentCn = contentCn + 'rounded-[10px]';
  }

  return (
    <FNDModal
      rootInnerWrapperCn={rootCn}
      contentWrapperCn={contentWrapperCn}
      contentCn={contentCn}
      open={isModalOpened}
      onClose={closeModal}
      {...props}
    >
      <CreateCommunityStepper />
    </FNDModal>
  );
}

export const CreateCommunityStepperModal = observer(
  _CreateCommunityStepperModal
);
