import {
  SquareIconButtonBaseTooltip,
  SquareIconButtonBaseTooltipPropsType,
} from '../SquareIconButtonBaseTooltip';

import { PencilIcon } from '@heroicons/react/24/outline';

export type EditBtnTooltipPropsType = Omit<
  SquareIconButtonBaseTooltipPropsType,
  'children'
>;

export function EditBtnTooltip(props: EditBtnTooltipPropsType) {
  return (
    <SquareIconButtonBaseTooltip {...props}>
      <PencilIcon width={20} height={20} className="shrink-0" />
    </SquareIconButtonBaseTooltip>
  );
}
