import { useContext } from 'react';

import { InversifyContext } from './IOCProvider';

import IOC_TOKENS, { IOC_TOKEN_TYPE } from './ioc-tokens';

// /**
//  * Injects a single dependency
//  * @param { string } identifier - dependency key (ioc token)
//  * @returns dependency
//  */
// export function useInjection<T extends keyof TokensTypeObject>(identifier: T) {
//   const { container } = useContext(InversifyContext);
//   if (!container) {
//     throw new Error();
//   }
//   const res: TokensTypeObject[T] = container.get(identifier) as any;
//   return res;
// }

// const data = useInjection(IOC_TOKENS.attachmentsMediator)

// Define the useInjection function with a conditional return type
export function useInjection<K extends keyof typeof IOC_TOKENS>(
  identifier: K
): IOC_TOKEN_TYPE[K] {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error();
  }
  const res = container.get(identifier);
  return res;
}

/**
 * Injects multiple dependencies
 * @param { string[] } tokensArray - array of keys for injected dependenkies. Resulted object will contin only them
 * @returns { [K in T[number]]: TokensTypeObject[K] } - composed object of injected dependecies containing dependencies which keys were presented in the tokesArray
 */
export function useMultipleInjection<T extends (keyof typeof IOC_TOKENS)[]>(
  tokensArray: T
): {
    [K in T[number]]: IOC_TOKEN_TYPE[K];
  } {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error();
  }

  const res = {} as {
    [K in T[number]]: IOC_TOKEN_TYPE[K];
  };

  tokensArray.forEach((key) => {
    const item = container.get<typeof key>(key);
    // @ts-ignore
    res[key] = item;
  });

  return res;
}

// type TokensTypeObject = {
//   [K in keyof IOC_TOKEN_TYPE]: IOC_TOKEN_TYPE[K];
// };

// /**
//  * Injects a single dependency
//  * @param { string } identifier - dependency key (ioc token)
//  * @returns dependency
//  */
// export function useInjection<T extends keyof TokensTypeObject>(identifier: T) {
//   const { container } = useContext(InversifyContext);
//   if (!container) {
//     throw new Error();
//   }
//   return container.get<TokensTypeObject[T]>(identifier);
// }

// /**
//  * Injects multiple dependencies
//  * @param { string[] } tokensArray - array of keys for injected dependenkies. Resulted object will contin only them
//  * @returns { [K in T[number]]: TokensTypeObject[K] } - composed object of injected dependecies containing dependencies which keys were presented in the tokesArray
//  */
// export function useMultipleInjection<T extends (keyof TokensTypeObject)[]>(
//   tokensArray: T
// ): {
//   [K in T[number]]: TokensTypeObject[K];
// } {
//   const { container } = useContext(InversifyContext);

//   if (!container) {
//     throw new Error();
//   }

//   const res = {} as {
//     [K in T[number]]: TokensTypeObject[K];
//   };

//   tokensArray.forEach((key) => {
//     const item = container.get<typeof key>(key);
//     // @ts-ignore
//     res[key] = item;
//   });

//   return res;
// }

// /**
//  * Injects a single dependency
//  * @param { string } identifier - dependency key (ioc token)
//  * @returns dependency
//  */
// export function useInjection<T extends keyof TokensTypeObject>(identifier: T) {
//   const { container } = useContext(InversifyContext);
//   if (!container) {
//     throw new Error();
//   }
//   return container.get<TokensTypeObject[T]>(identifier);
// }

// const { attachmentsMediator, http } = useMultipleInjection([
//   'attachmentsMediator',
//   'http',
// ]);

// export function useMultipleInjection(identifiersArray: keyof IOC_TOKEN_TYPE[]) {
//   const { container } = useContext(InversifyContext);

//   if (!container) {
//     throw new Error();
//   }

//   const res = identifiersArray.reduce((acc, key) => {
//     const item = container.get(key);
//     acc[key] = item;
//     return acc;
//   }, {});

//   return res as IOC_TOKEN_TYPE;
// }

// enum TEST_ENUM {
//   http = 'http',
//   mediator = 'mediator',
//   service = 'service',
// }

// const ObjectA = {
//   [TEST_ENUM.http]: {
//     get: () => {
//       console.log('get');
//     },
//   },
//   [TEST_ENUM.mediator]: {
//     say: () => {
//       console.log('say');
//     },
//   },
//   [TEST_ENUM.service]: false,
// };

// // Define a mapped type to capture the original types of ObjectA
// type ObjectAType = {
//   [K in keyof typeof ObjectA]: (typeof ObjectA)[K];
// };

// function pest<T extends (keyof ObjectAType)[]>(
//   param: T
// ): {
//   [K in T[number]]: ObjectAType[K];
// } {
//   const res = {} as {
//     [K in T[number]]: ObjectAType[K];
//   };

//   param.forEach((key) => {
//     res[key] = ObjectA[key];
//   });

//   return res;
// }

// const { http } = pest([TEST_ENUM.http, TEST_ENUM.mediator]);

// http.get();
