import { Categories, SuggestionMode } from '../types/exposedTypes';

import {
  ClockIcon,
  FlagIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline';

import {
  AirplaneSVG,
  EmojiSVG,
  LeafSVG,
  LikeOutlineSVG,
  PizzaSVG,
  SportsSVG,
} from '@foundationPathAlias/components/svg';

export { Categories };

const categoriesOrdered: Categories[] = [
  Categories.SUGGESTED,
  Categories.CUSTOM,
  Categories.SMILEYS_PEOPLE,
  Categories.ANIMALS_NATURE,
  Categories.FOOD_DRINK,
  Categories.TRAVEL_PLACES,
  Categories.ACTIVITIES,
  Categories.OBJECTS,
  Categories.SYMBOLS,
  Categories.FLAGS,
];

export const SuggestedRecent: CategoryConfig = {
  name: 'Recently Used',
  type: 'custom heroicon',
  category: Categories.SUGGESTED,
  IconComponent: ClockIcon,
};

export type CustomCategoryConfig = {
  category: Categories.CUSTOM;
  type: 'custom custom-svg';
  name: string;
  IconComponent: React.FunctionComponent;
};

const configByCategory: Record<Categories, CategoryConfig> = {
  [Categories.SUGGESTED]: {
    category: Categories.SUGGESTED,
    type: 'suggested heroicon',
    name: 'Frequently Used',
    IconComponent: ClockIcon,
  },
  [Categories.CUSTOM]: {
    category: Categories.CUSTOM,
    type: 'custom custom-svg',
    name: 'Custom Emojis',
    IconComponent: ClockIcon,
  },
  [Categories.SMILEYS_PEOPLE]: {
    category: Categories.SMILEYS_PEOPLE,
    type: 'smile custom-svg',
    name: 'Smileys & People',
    IconComponent: EmojiSVG,
  },
  [Categories.ANIMALS_NATURE]: {
    category: Categories.ANIMALS_NATURE,
    type: 'nature custom-svg',
    name: 'Animals & Nature',
    IconComponent: LeafSVG,
  },
  [Categories.FOOD_DRINK]: {
    category: Categories.FOOD_DRINK,
    type: 'food custom-svg',
    name: 'Food & Drink',
    IconComponent: PizzaSVG,
  },
  [Categories.ACTIVITIES]: {
    category: Categories.ACTIVITIES,
    type: 'activities custom-svg',
    name: 'Activities',
    IconComponent: SportsSVG,
  },
  [Categories.TRAVEL_PLACES]: {
    category: Categories.TRAVEL_PLACES,
    type: 'travel custom-svg',
    name: 'Travel & Places',
    IconComponent: AirplaneSVG,
  },

  [Categories.OBJECTS]: {
    category: Categories.OBJECTS,
    type: 'objects heroicon',
    name: 'Objects',
    IconComponent: LightBulbIcon,
  },
  [Categories.SYMBOLS]: {
    category: Categories.SYMBOLS,
    type: 'symbols custom-svg',
    name: 'Symbols',
    IconComponent: LikeOutlineSVG,
  },
  [Categories.FLAGS]: {
    category: Categories.FLAGS,
    type: 'flags heroicon',
    name: 'Flags',
    IconComponent: FlagIcon,
  },
};

export function baseCategoriesConfig(
  modifiers?: Record<Categories, CategoryConfig>
): CategoriesConfig {
  return categoriesOrdered.map((category) => {
    return {
      ...configByCategory[category],
      ...(modifiers && modifiers[category] && modifiers[category]),
    };
  });
}

export function categoryFromCategoryConfig(category: CategoryConfig) {
  return category.category;
}

export function categoryNameFromCategoryConfig(category: CategoryConfig) {
  return category.name;
}

export type CategoriesConfig = CategoryConfig[];

export type CategoryConfig = {
  category: Categories;
  name: string;
  type: string;
  IconComponent?: React.FunctionComponent<any>;
};

export type UserCategoryConfig = Array<Categories | CategoryConfig>;

export function mergeCategoriesConfig(
  userCategoriesConfig: UserCategoryConfig = [],
  modifiers: CategoryConfigModifiers = {}
): CategoriesConfig {
  const extra = {} as Record<Categories, CategoryConfig>;

  if (modifiers.suggestionMode === SuggestionMode.RECENT) {
    extra[Categories.SUGGESTED] = SuggestedRecent;
  }

  const base = baseCategoriesConfig(extra);
  if (!userCategoriesConfig?.length) {
    return base;
  }

  return userCategoriesConfig.map((category) => {
    if (typeof category === 'string') {
      return getBaseConfigByCategory(category, extra[category]);
    }

    return {
      ...getBaseConfigByCategory(category.category, extra[category.category]),
      ...category,
    };
  });
}

function getBaseConfigByCategory(
  category: Categories,
  modifier: CategoryConfig = {} as CategoryConfig
) {
  return Object.assign(configByCategory[category], modifier);
}

type CategoryConfigModifiers = {
  suggestionMode?: SuggestionMode;
};
