import { AccountProUsernameTypeItemPayload } from '@10x/foundation/types';

import { useTranslation } from 'react-i18next';
import { SubscriptionOption } from './SubscriptionOption';

import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';

type Props = {
  subscriptionPrices: AccountProUsernameTypeItemPayload[] | null;
  activeSubscriptionType: SubscriptionTypeEnum;
  onChange: (activeSubscriptionType: SubscriptionTypeEnum) => void;
};

export function SubscriptionList(props: Props) {
  const { subscriptionPrices, activeSubscriptionType, onChange } = props;
  const { t } = useTranslation('create-community');
  return (
    <>
      <p className="themed-text mb-[16px]">{t('chooseSubscription')}</p>

      <div className="flex-1">
        <div className="grid grid-rows-[repeat(2,56px)] gap-y-[4px]">
          {subscriptionPrices &&
            subscriptionPrices.map((o) => {
              return (
                <SubscriptionOption
                  key={o.slug}
                  label={t(o.slug)}
                  checked={activeSubscriptionType === o.slug}
                  price={o.ext.discount as number}
                  yearly={o.slug === SubscriptionTypeEnum.YEAR}
                  onChange={() => {
                    onChange(o.slug as SubscriptionTypeEnum);
                  }}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}
