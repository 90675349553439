import { useIsMobile } from '../../config/useConfig';
import Relative from '../Layout/Relative';
import { CategoryNavigation } from '../navigation/CategoryNavigation';
import './Header.css';
import { SearchContainer } from './Search';

export function Header() {
  const isMobile = useIsMobile();
  return (
    <Relative className="epr-header">
      {!isMobile && <CategoryNavigation />}
      <SearchContainer />
    </Relative>
  );
}
