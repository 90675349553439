export const LikeOutlineSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 20, height = 20, color = '#1A1A1A', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.30415 17.7388C9.49669 17.9072 9.74374 18 10 18C10.2561 18 10.5033 17.9072 10.6957 17.739C11.4245 17.1023 12.1267 16.5043 12.7463 15.9769L12.7466 15.9767C14.5596 14.4329 16.1253 13.0997 17.2149 11.7862C18.4328 10.3179 19 8.92567 19 7.40473C19 5.92701 18.493 4.56372 17.5722 3.56581C16.6404 2.5561 15.3617 2 13.9717 2C12.9327 2 11.9811 2.3282 11.1435 2.97541C10.7207 3.3021 10.3377 3.70192 10 4.16828C9.66245 3.70192 9.2793 3.3021 8.8566 2.97541C8.01903 2.3282 7.06747 2 6.02844 2C4.63826 2 3.35973 2.5561 2.42795 3.56581C1.50716 4.56372 1 5.92701 1 7.40473C1 8.92567 1.56731 10.3179 2.78528 11.7863C3.87485 13.0998 5.44081 14.4332 7.25424 15.9772L7.2574 15.9799C7.87593 16.5065 8.577 17.1035 9.30415 17.7388ZM3.59581 4.66894C4.28355 3.92376 5.22893 3.51342 6.25802 3.51342C7.01176 3.51342 7.7039 3.75285 8.31504 4.22499C8.85981 4.64593 9.2392 5.17805 9.4615 5.55039C9.57589 5.74185 9.77723 5.85614 10.0002 5.85614C10.2231 5.85614 10.4245 5.74185 10.5389 5.55039C10.7613 5.17805 11.1407 4.64593 11.6853 4.22499C12.2965 3.75285 12.9886 3.51342 13.7425 3.51342C14.7714 3.51342 15.7169 3.92376 16.4046 4.66894C17.1025 5.42537 17.487 6.471 17.487 7.61334C17.487 8.81864 17.0386 9.89659 16.0334 11.1085C15.0627 12.2788 13.6193 13.5079 11.948 14.9311L11.9435 14.935C11.3573 15.4339 10.6931 15.9995 9.99876 16.6036C9.30855 16.0006 8.64539 15.4359 8.06039 14.9378L8.05729 14.9352L8.05629 14.9344C6.38335 13.5099 4.9385 12.2797 3.96705 11.1085C2.96176 9.89659 2.5134 8.81864 2.5134 7.61334C2.5134 6.471 2.89784 5.42537 3.59581 4.66894Z"
      fill={color}
    />
  </svg>
);
