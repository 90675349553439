import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { reaction } from 'mobx';
import { useEffect } from 'react';

import _memoize from 'lodash/memoize';

import {
  adjustLuminanceRgb,
  getRgbaString,
  RGBToHex,
} from '@foundationPathAlias/utilities';

type ColorsType = {
  color100: string;
  color80: string;
  color60: string;
  color40: string;
  color10: string;
  hovered: string;
  pressed: string;
};

/** it's a top level hook that must be used once just to setup a reaction for the community color BE fetch change and update of the Primary colors for all the dependent components on the Community Page */
export function useCommunityColors() {
  const { communityStore } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.systemStore,
  ]);

  useEffect(() => {
    const generateColorsFromRgbaMemoized = _memoize(
      (r: number, g: number, b: number, dm = false) => {
        const rgbaObject = { r, g, b, a: 1 };

        const color100 = RGBToHex(rgbaObject);
        const color80 = getRgbaString(rgbaObject, 0.8);
        const color60 = getRgbaString(rgbaObject, 0.6);
        const color40 = getRgbaString(rgbaObject, 0.4);
        const color10 = getRgbaString(rgbaObject, 0.1);
        // hsl support is nice in major browsers so might use it without converting to rgb as it will cause some minor values shift which would be nice to avoid
        const hovered = adjustLuminanceRgb(rgbaObject, 5);
        const pressed = adjustLuminanceRgb(rgbaObject, -5);

        const payload = {
          color100,
          color80,
          color60,
          color40,
          color10,
          hovered: hovered,
          pressed: pressed,
        };

        updatePrimaryColorCss(payload, dm);
      }
    );

    const disposer = reaction(
      () => {
        return communityStore.activeCommunity.data;
      },
      (communityModel) => {
        const communityColors = communityModel?.serverData?.color;
        if (!communityColors) return;

        const lm = communityColors.light;
        const dm = communityColors.dark;

        generateColorsFromRgbaMemoized(lm.r, lm.g, lm.b);
        generateColorsFromRgbaMemoized(dm.r, dm.g, dm.b, true);
      }
    );

    return disposer;
  }, []);
}

function updatePrimaryColorCss(colors: ColorsType, dm = false) {
  if (!colors) {
    throw new Error('Color must be defined');
  }

  const rootEl = global.document.documentElement;
  if (!rootEl) return;

  const { color100, color80, color60, color40, color10, hovered, pressed } =
    colors;

  // these variables are defined in the css top level: foundation common.css
  rootEl.style.setProperty(getName('--primary-color-100', dm), color100);
  rootEl.style.setProperty(getName('--primary-color-80', dm), color80);
  rootEl.style.setProperty(getName('--primary-color-60', dm), color60);
  rootEl.style.setProperty(getName('--primary-color-40', dm), color40);
  rootEl.style.setProperty(getName('--primary-color-10', dm), color10);
  rootEl.style.setProperty(getName('--primary-color-hovered', dm), hovered);
  rootEl.style.setProperty(getName('--primary-color-pressed', dm), pressed);
}

const getName = (name: string, dm = false) => name + (dm ? '-dark' : '');
