import { classNames } from '@foundationPathAlias/utilities';

export type PrimaryButtonPropsType = {
  /** if true - should use the dynamic primary colors from the active community BE response */
  dynamicColorsMode?: boolean;
  cn?: string;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function PrimaryButton(props: PrimaryButtonPropsType) {
  const {
    cn,
    dynamicColorsMode = false,
    children,
    disabled,
    onClick,
    ...otherProps
  } = props;

  return (
    <button
      className={classNames(
        'button-base',
        dynamicColorsMode ? 'primary-button-dynamic' : 'primary-button',
        disabled && 'opacity-40',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
}
