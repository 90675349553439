import { classNames } from '@10x/foundation/src/utilities';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  GlobeAltIcon,
} from '@heroicons/react/20/solid';

import { StorageDataKeysEnum } from '@mainApp/src/services';
import cookie from 'js-cookie';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { localesData } from '@mainApp/src/config';
const languages = Object.values(localesData);

const cookieUserLocale = cookie.get(StorageDataKeysEnum.USER_LOCALE);

import { ssrHub } from '@mainApp/src/services';

export function LangSelect() {
  const { t, i18n } = useTranslation('common');
  const locale = cookieUserLocale || ssrHub.getDataProp('lang');
  const activeLang = languages.find((lang) => lang.id === locale);

  const [selected, setSelected] = useState(activeLang || languages[0]);

  const handleLocaleChange = (lang: any) => {
    setSelected(lang);
    // window.localStorage.setItem('user_locale', lang.id);
    cookie.set(StorageDataKeysEnum.USER_LOCALE, lang.id, {
      secure: true,
      sameSite: 'none',
    });
    i18n.changeLanguage(lang.id);
    // router.push(router.route, router.asPath, {
    //   locale: lang.id,
    // });
  };

  return (
    <Listbox value={selected} onChange={handleLocaleChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              aria-label={selected.label}
              className="relative w-full  py-2 pl-3 pr-10 text-left  focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 "
            >
              <div className="relative flex items-center pl-[8px]">
                <GlobeAltIcon className="themed-svg absolute -left-[14px] top-[50%] mr-[8px] h-[16px] w-[16px] shrink-0 -translate-y-[50%] transform" />
                <span className="themed-text block truncate text-body16R">
                  {t(selected.label)}
                </span>
                <ChevronDownIcon className="themed-svg ml-[10px] h-[20px] w-[20px]" />
              </div>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[250px] overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-secondary-dark sm:text-sm">
                {languages.map((lang) => (
                  <Listbox.Option
                    key={lang.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-hovered-selected dark:bg-hovered-selected-dark '
                          : '',
                        'relative cursor-default select-none py-2 pl-[20px] pr-9'
                      )
                    }
                    value={lang}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate text-body16R',
                            selected
                              ? 'text-color-12 dark:text-color-12-dark'
                              : 'text-text-primary dark:text-text-primary-dark'
                          )}
                        >
                          {t(lang.label)}
                        </span>

                        {selected && !active ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
