export const ReactionsSVG: React.FC<
  {
    color?: string;
  } & React.SVGProps<SVGSVGElement>
> = ({ color = '#1A1A1A', ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_154_11438)">
      <path
        d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67492 2.24624C8.31936 2.11001 6.95432 2.38717 5.75922 3.04127C4.56414 3.69538 3.59495 4.6958 2.97907 5.91104C2.3632 7.12627 2.12947 8.49942 2.30862 9.84998C2.48776 11.2005 3.07138 12.4653 3.98277 13.4779C4.89416 14.4906 6.09064 15.2037 7.41493 15.5237C8.73922 15.8436 10.1293 15.7553 11.4025 15.2704C12.6757 14.7855 13.7723 13.9267 14.5482 12.8068C15.3238 11.6875 15.7427 10.3598 15.7499 8.99809V8.24999C15.7499 7.83578 16.0857 7.49999 16.4999 7.49999C16.9141 7.49999 17.2499 7.83578 17.2499 8.24999V8.99999V9.00375C17.2416 10.6689 16.7295 12.2925 15.7812 13.6612C14.8328 15.0298 13.4925 16.0795 11.9364 16.6722C10.3803 17.2648 8.68126 17.3727 7.06269 16.9817C5.44412 16.5907 3.98175 15.7191 2.86783 14.4814C1.75391 13.2437 1.04059 11.6979 0.821639 10.0472C0.602688 8.39654 0.888356 6.71824 1.64109 5.23295C2.39382 3.74767 3.57839 2.52492 5.03905 1.72547C6.49972 0.926009 8.16811 0.587259 9.8249 0.753752C10.237 0.795168 10.5376 1.16285 10.4962 1.57498C10.4547 1.98712 10.0871 2.28765 9.67492 2.24624ZM5.99994 6.75003C5.99994 6.33582 6.33573 6.00003 6.74994 6.00003H6.75781C7.17203 6.00003 7.50781 6.33582 7.50781 6.75003C7.50781 7.16424 7.17203 7.50003 6.75781 7.50003H6.74994C6.33573 7.50003 5.99994 7.16424 5.99994 6.75003ZM11.2499 6.00003C10.8357 6.00003 10.4999 6.33582 10.4999 6.75003C10.4999 7.16424 10.8357 7.50003 11.2499 7.50003H11.2578C11.672 7.50003 12.0078 7.16424 12.0078 6.75003C12.0078 6.33582 11.672 6.00003 11.2578 6.00003H11.2499ZM11.2499 3.75C11.2499 3.33579 11.5857 3 11.9999 3H13.4999V1.49994C13.4999 1.08573 13.8357 0.74994 14.2499 0.74994C14.6642 0.74994 14.9999 1.08573 14.9999 1.49994V3H16.4999C16.9141 3 17.2499 3.33579 17.2499 3.75C17.2499 4.16421 16.9141 4.5 16.4999 4.5H14.9999V5.99994C14.9999 6.41415 14.6642 6.74994 14.2499 6.74994C13.8357 6.74994 13.4999 6.41415 13.4999 5.99994V4.5H11.9999C11.5857 4.5 11.2499 4.16421 11.2499 3.75Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_154_11438">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
