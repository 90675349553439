import { PrimaryButton } from '@foundationPathAlias/components';
import { useTranslation } from 'react-i18next';
import { Preview } from './Preview';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function CompletedUI() {
  const { t } = useTranslation(['common', 'create-community']);
  const postAuthOnboardingStore = useInjection(
    IOC_TOKENS.postAuthOnboardingStore
  );

  return (
    <div className="flex flex-col px-[16px] pt-[24px] md:px-[24px]">
      <h5 className="themed-text relative mb-[8px] items-center text-center text-miniHeadD font-bold">
        {t('yourProfileReady', {
          ns: 'create-community',
        })}
      </h5>
      <p className="themed-text-secondary mb-[12px] text-center text-body16R">
        {t('yourProfileReadyDescription', {
          ns: 'create-community',
        })}
      </p>
      <Preview rootCn="md:order-0 md:px-0 self-center" />
      <div className="mt-[12px]">
        <PrimaryButton
          onClick={() => {
            postAuthOnboardingStore.setShow(false);
          }}
        >
          {t('getStarted')}
        </PrimaryButton>
      </div>
    </div>
  );
}
