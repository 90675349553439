'use client';
import { Avatar, TextButtonPrimary } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { MemberData } from '@mainApp/src/stores';
import { useTranslation } from 'react-i18next';

export type UserRowProps = {
  data: MemberData;
  onAction?: () => void;
  onClick?: () => void;
  actionLabel: string;
};

export const UserRow = ({
  onAction,
  data,
  actionLabel,
  onClick,
}: UserRowProps) => {
  const { avatarSrc, description, name, isOnline } = data;
  const { t } = useTranslation(['common']);

  return (
    <div
      className={classNames(
        'flex items-center gap-3 py-2 ',
        onClick &&
          'cursor-pointer transition-colors hover:bg-background-hover hover:dark:bg-background-hover-dark'
      )}
    >
      <Avatar
        width={36}
        height={36}
        src={avatarSrc}
        alt={t<string>('common:avatarImage', { name })}
      />

      <div onClick={onClick} className="flex flex-[1] flex-col items-start">
        <b className="text-sm14T text-text-primary dark:text-text-primary-dark">
          {name}

          {isOnline && (
            <span className="ml-2 inline-block h-2 w-2 rounded-full bg-element-success" />
          )}
        </b>

        <small className="text-text-secondary dark:text-text-secondary">
          {description}
        </small>
      </div>

      {onAction && (
        <TextButtonPrimary cn="w-auto" onClick={onAction}>
          {actionLabel}
        </TextButtonPrimary>
      )}
    </div>
  );
};
