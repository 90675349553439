import { useTranslation } from 'react-i18next';

export const UsersTableFilterEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[114px]">
      <b className="themed-text-primary mb-[4px] text-body18SB">
        {t('cap:users.noMembersFound')}
      </b>
    </div>
  );
};
