import { classNames } from '@10x/foundation/src/utilities';
import { CheckIcon } from '@heroicons/react/24/outline';

type SuccessIconsPropsType = {
  /** could be an error mode for the preview upload */
  error?: boolean;
  rootCn?: string;
  iconCn?: string;
  /** will be applied in the case of error mode */
  errorCn?: string;
};

export const SuccessIcon = (props: SuccessIconsPropsType) => {
  const { error, rootCn = '', iconCn = '', errorCn = '' } = props;

  return (
    <div
      className={classNames(
        'flex min-h-[16px] min-w-[16px] items-center justify-center rounded-full bg-element-success dark:bg-element-success-dark',
        rootCn,
        error && 'bg-element-error dark:bg-element-error-dark ' + errorCn
      )}
    >
      <CheckIcon
        className={'min-h-[12px] min-w-[12px] text-white ' + iconCn}
        aria-hidden="true"
      />
    </div>
  );
};
