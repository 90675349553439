import { observer } from 'mobx-react-lite';
import { AuthContent } from './AuthContent';
import { EmailForm } from './EmailForm';

import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

type Props = {
  onCloseBtnClick: () => void;
  dedicatedPageMode?: boolean;
};

export function _Auth(props: Props) {
  const { onCloseBtnClick, dedicatedPageMode } = props;

  const authStore = useInjection(IOC_TOKENS.authStore);
  const { authForm, setAuthForm, resetAuthForm, setLoginMode, loginMode } =
    authStore;

  const content = authForm.show ? (
    <EmailForm
      emailFormData={authForm}
      loginMode={loginMode}
      setEmailForm={setAuthForm}
      resetEmailForm={resetAuthForm}
      dedicatedPageMode={Boolean(dedicatedPageMode)}
      onCloseBtnClick={() => {
        onCloseBtnClick();

        // TODO: callback after modal close
        setTimeout(() => {
          setAuthForm({
            ...authForm,
            show: false,
          });
        }, 1000);
      }}
      onBackClick={() => {
        setAuthForm({
          ...authForm,
          show: false,
        });
      }}
    />
  ) : (
    <AuthContent
      onCloseBtnClick={onCloseBtnClick}
      isAuthLoginMode={loginMode}
      setIsAuthLoginMode={setLoginMode}
      dedicatedPageMode={Boolean(dedicatedPageMode)}
      onEmailClick={() => {
        setAuthForm({
          ...authForm,
          show: true,
        });
      }}
    />
  );

  return (
    <div
      className={classNames(dedicatedPageMode && 'flex max-w-[376px] flex-col')}
    >
      {content}
    </div>
  );
}

export const Auth = observer(_Auth);
