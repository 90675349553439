import { ContentReactionPayload, UserEdge } from '@10x/foundation/types';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { useState } from 'react';
import { ReactionAndCountBar } from './ReactionAndCountBar';
import { UserCard } from './UserCard';

type Props = {
  handleClose: () => void;
  messageModel: MessageModel;
};

export function UserReactions(props: Props) {
  const { handleClose, messageModel } = props;
  const {
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore]);
  const [selectedReaction, setSelectedReaction] =
    useState<ContentReactionPayload>(messageModel.serverData.reactions[0]);
  const [selectedReactionIndex, setSelectedReactionIndex] = useState(0);

  const handleReaction = (
    newReaction: ContentReactionPayload,
    newIndex: number
  ) => {
    setSelectedReaction(newReaction);
    setSelectedReactionIndex(newIndex);
  };

  const _UserReactionBar = (
    <ReactionAndCountBar
      handleReaction={handleReaction}
      selectedReaction={selectedReaction}
      selectedReactionIndex={selectedReactionIndex}
      reactions={messageModel.serverData.reactions}
    />
  );

  return (
    <div className="themed-layout flex h-full flex-col">
      <div className="themed-layout sticky top-0 z-10">
        <div className="flex flex-row items-center justify-between px-6">
          <span className="themed-text flex h-9 flex-grow items-center justify-center text-center text-body16SB md:h-14 md:justify-start md:text-left md:text-subheadingXB">
            Reactions
          </span>
          {!isMobile && (
            <XMarkIcon
              className="themed-text-secondary h-16 w-6 cursor-pointer"
              aria-hidden="true"
              onClick={handleClose}
            />
          )}
        </div>
        <div className="border-b border-element-subtle dark:border-element-subtle-dark" />
        {!isMobile && <div>{_UserReactionBar}</div>}
      </div>

      <div className="mb-[10px] flex-grow overflow-y-auto px-6">
        {selectedReaction?.users?.edges?.map(
          (item: UserEdge, index: number) => (
            <UserCard
              key={index}
              messageModel={messageModel}
              name={item.node.displayName}
              handle={item.node.username}
              imageUrls={item.node.imageUrls}
            />
          )
        )}
      </div>

      {isMobile && (
        <div className="themed-layout sticky bottom-0">{_UserReactionBar}</div>
      )}
    </div>
  );
}
