import { IOC_TOKENS } from '@mainApp/src/ioc';
import { inject, injectable } from 'inversify';
import { refineURL } from '../utils';
import type { IStorageService } from './Storage.service.singletone';
import { RedirectDTO, RedirectOptions, RedirectionURLActionsEnum, RedirectionURLQueriesEnum, StorageDataKeysEnum } from './types';
/**
 * Data object to save before redirection and will be used in the Redirection page
 * key for saving in storage should be by convenience: REDIRECTION_DATA
 * {
 *  id: <ID_of_the_redirection> (like: subscrption-pament/auth/join-community)
 *  data: { <any_data_required_for_action> }
 *  // the url of the page where was originally called the redirection from
 *  url: <trigger_page_url>
 *
 * }
 *
 * Data object for the next redirection from the Redirect page to the further page
 * key for saving in storage should be by convenicence: REDIRECTION_CALLBACK_DATA
 *  {
 *  id: <ID_of_the_redirection> (like: subscrption-pament/auth/join-community)
 *  data: { <any_data_required_for_action> }
 *  // the url of the page where was originally called the redirection from
 *  url: <trigger_page_url>
 *
 * }
 * 
 * for redurection URL the convention is:
 * URL?<RedirectionURLQueriesEnum>=<RedirectionURLActionsEnum>
 * https://host?SCHEDULED_ACTIONS=JOIN
 *
 */

const defaultRedirectionOptions: RedirectOptions = {
    withTimeout: true,
    self: true,
    timeoutTime: 250
}


@injectable()
export class RedirectService implements IRedirectService {
    DATA_KEY = StorageDataKeysEnum.REDIRECTION_DATA;
    storageService: IStorageService;



    constructor(@inject(IOC_TOKENS.storageService) storageService: IStorageService) {
        this.storageService = storageService;
    }

    setToStorageDTO(data: RedirectDTO) {
        if (!data) throw new Error(`DTO had not ben passed. Received: ${data}`);
        this.storageService.setStorageItem(this.DATA_KEY, JSON.stringify(data));
    }


    generateDTO() {
        return {
            id: null,
            data: {},
            url: null

        } as any
    }

    getDROFromStorage() {
        const DTOstring = this.storageService.getStorageItem(this.DATA_KEY);
        return DTOstring ? JSON.parse(DTOstring) : undefined;
    }

    redirect(url: string, options?: Partial<RedirectOptions>) {

        let resultOptions = defaultRedirectionOptions;
        if (options) {
            resultOptions = { ...defaultRedirectionOptions, ...options }
        }

        const { withTimeout, timeoutTime = 250, self } = resultOptions;

        const openVariant = self ? '_self' : '_blank';
        if (withTimeout) {
            setTimeout(() => {
                global.open(url, openVariant);
            }, timeoutTime)
        } else {
            global.open(url, openVariant);
        }
    }

    /** does the same as utilities/common/refineUrl
     * 
     * get current window location href without query
     */
    refineURL = (historyTitle: string) => {
        const url = refineURL();

        global?.history?.replaceState({}, historyTitle, `${url || '/'}`);
    }

    generateQueryUrl = (url: string, queryId: RedirectionURLQueriesEnum, actionId: RedirectionURLActionsEnum) => {
        return `${url}?${queryId}=${actionId}`;
    }

    isActionExisting = (query: any, action: RedirectionURLActionsEnum) => query === action;
}

export interface IRedirectService {
    DATA_KEY: string;
    storageService: IStorageService;
    generateDTO(): RedirectDTO;
    getDROFromStorage(): RedirectDTO | undefined;
    setToStorageDTO(data: RedirectDTO): void;
    redirect(url: string, options?: Partial<RedirectOptions>): void;
    /**
     * 
     * @param historyTitle {string} - refined history title that will be used
     */
    refineURL(historyTitle: string): void;
    /**
     * for convenicence to not create it in the app. Here could be later added * a feature to generate URL with the multiple actions based on the 
     * convention which should be created in that case
     */
    generateQueryUrl(url: string, queryId: RedirectionURLQueriesEnum, actionId: RedirectionURLActionsEnum): string;
    // later if needed it could handle multiple actions. For now it's just single source of truth
    isActionExisting(query: any, actionId: RedirectionURLActionsEnum): boolean;
}