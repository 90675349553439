export const LeafSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  // to escape autogeneration fill prop from the story. Remove it later
  // @ts-ignore
}> = ({ width = 20, height = 20, color = '#1A1A1A', fill, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M9.16666 16.6667C7.70338 16.6711 6.29187 16.1254 5.21209 15.1379C4.13231 14.1503 3.46313 12.793 3.33727 11.3351C3.21141 9.87724 3.63807 8.42532 4.53262 7.2673C5.42717 6.10929 6.72427 5.32977 8.16666 5.08335C12.9167 4.16669 14.1667 3.73335 15.8333 1.66669C16.6667 3.33335 17.5 5.15002 17.5 8.33335C17.5 12.9167 13.5167 16.6667 9.16666 16.6667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66666 17.5C1.66666 15 3.20832 13.0333 5.89999 12.5C7.91666 12.1 9.99999 10.8333 10.8333 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
