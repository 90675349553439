'use client';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  RedirectionIdsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';
import { ToastTypesEnum } from '@mainApp/src/stores';
import { removeQueryParamsFromRouter } from '@mainApp/src/utils';
import { autorun } from 'mobx';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
/**
 * Renders PostAuthOnboarding.
 * Basically it's a reactive hook
 * that reacts on the post auth onboarding calling and renders the
 * Auth via Interactive store in Modal or Bottomsheet
 */
export function usePostAuthOnboardingUI() {
  const { postAuthOnboardingStore, authStore, toastStore } =
    useMultipleInjection([
      IOC_TOKENS.authStore,
      IOC_TOKENS.postAuthOnboardingStore,
      IOC_TOKENS.toastStore,
    ]);

  const router = useRouter();
  const { query } = router;
  const paymentRedirect = query?.[RedirectionURLQueriesEnum.PAYMENT_REDIRECT];
  const { t } = useTranslation('common');
  const isSubscriptionRedirectRef = useRef(false);

  useEffect(() => {
    const disposer = autorun(() => {
      const logged = authStore.logged;

      // check if should show the post auth onboarding. If there is a redirection after successful payment the showing of the completed status will be done by the query processing below
      if (logged && !paymentRedirect && router.isReady) {
        postAuthOnboardingStore.getUserOnboardingData();
      }
    });
    return () => {
      disposer();
    };
  }, [router.isReady]);

  useEffect(() => {
    if (!query || isSubscriptionRedirectRef.current) return;
    // checking if the payment happened and was successful
    if (paymentRedirect !== RedirectionIdsEnum.POST_AUTH_ONBOARDING) return;

    if (query[RedirectionURLQueriesEnum.ERROR]) {
      toastStore.show({
        type: ToastTypesEnum.ERROR,
        title: t('errors.error'),
        content: t('errors.subscriptionPaymentError'),
      });

      delete router.query[RedirectionURLQueriesEnum.ERROR];
      removeQueryParamsFromRouter(router, [RedirectionURLQueriesEnum.ERROR]);
      postAuthOnboardingStore.setShow(true);
    } else if (query?.[RedirectionURLQueriesEnum.RESULT] === 'success') {
      postAuthOnboardingStore.completeSubscription();
    }
    delete router.query[RedirectionURLQueriesEnum.PAYMENT_REDIRECT];
    delete router.query[RedirectionURLQueriesEnum.RESULT];

    removeQueryParamsFromRouter(router, [
      RedirectionURLQueriesEnum.PAYMENT_REDIRECT,
      RedirectionURLQueriesEnum.RESULT,
    ]);
    // in this case showing the toast will be processed by this method. Not mobx `when` that reacts on the user.me data
    isSubscriptionRedirectRef.current = true;
  }, [query, router]);
}
