'use client';

import {
  BackButton,
  EditBtnTooltip,
  MenuButton,
  OutlineButton,
  RulesSVG,
} from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds as CapScreenIds } from '@mainApp/src/modules/customer-admin-panel/screens/rules/constants';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { ScreenIds } from '../constants';
import { useScreenRules } from '../ScreenRules.context';

const ChannelHeaderLoginBtn = dynamic(
  () => import('@mainApp/src/modules/channel/common/ChannelHeaderLoginBtn'),
  {
    ssr: false,
  }
);

export const _RuleHeader = () => {
  const { t } = useTranslation(['channel']);

  const {
    systemStore,
    capStore,
    dimensionsStore: { isMobile },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.capStore,
    IOC_TOKENS.communityStore,
  ]);

  const { handleBack, currentItemId, currentPlatformRule, currentRuleDetails } =
    useScreenRules();

  const isUserAdmin = communityStore.activeCommunity.data?.isUserAdmin;

  const isOnInitialScreen = currentItemId === ScreenIds.INITIAL;

  const showMenuButton = isMobile && isOnInitialScreen;

  const handleEditRule = () => {
    capStore.setIsShow(true);
    capStore.setActiveScreenId(CapScreenIds.INITIAL);
  };

  let rightContent = null;

  if (isUserAdmin && currentItemId === ScreenIds.RULE_DETAILS) {
    rightContent = isMobile ? (
      <EditBtnTooltip
        onClick={handleEditRule}
        tooltipContent={t<string>('rules.editRule')}
        className="themed-text-secondary"
      />
    ) : (
      <OutlineButton
        cn="border-element-subtle dark:border-element-subtle-dark px-[20px] py-[8px] h-[40px] text-sm14SB"
        onClick={handleEditRule}
      >
        {t('rules.editRule')}
      </OutlineButton>
    );
  }

  let title = null;

  if (!isOnInitialScreen) {
    title =
      currentItemId === ScreenIds.SUB_RULES
        ? currentPlatformRule?.title
        : currentRuleDetails?.title;
  }

  return (
    <div
      className={classNames(
        'grid h-[52px] w-full grid-cols-3 items-center justify-between gap-[8px] border-b border-b-element-subtle px-[8px] dark:border-b-element-subtle-dark md:h-[65px] md:px-[20px]',
        {
          flex: !isOnInitialScreen,
        }
      )}
    >
      <div className="flex items-center gap-[8px]">
        {showMenuButton ? (
          <MenuButton
            onClick={() => {
              systemStore.setIsSidebarOpened(true);
            }}
            className="themed-text"
          />
        ) : (
          <>
            {!isOnInitialScreen && (
              <BackButton
                className="themed-text-secondary"
                onClick={handleBack}
              />
            )}

            {title && (
              <span className="themed-text text-body16SB md:text-subheadingSB">
                {title}
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex items-center justify-center gap-[8px]">
        {isOnInitialScreen && (
          <>
            <RulesSVG className="themed-text-secondary" />

            <span className="themed-text text-subheading">
              {t('rules.title')}
            </span>
          </>
        )}
      </div>

      <div className="flex items-center justify-end self-center justify-self-end">
        {rightContent}

        <div className="mt-[-20px]">
          <ChannelHeaderLoginBtn />
        </div>
      </div>
    </div>
  );
};

export const RuleHeader = observer(_RuleHeader);
