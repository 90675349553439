// ===@ts-nocheck
/* ====eslint-disable */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useCallback } from 'react';
// import * as ReactDOM from 'react-dom';
import { RolesEnum } from '@10x/foundation/types';
import { $createMentionUserNode } from '../nodes/MentionUserNode';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

function checkForAtSignMentions(text: string, minMatchLength: number) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    // const matchingString = match[3];
    const matchingString = match[2];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string) {
  const match = checkForAtSignMentions(text, 1);
  // it caused mentions trigger in the case of `Msg` word etc. capitalized
  // check how it will be working without it
  // return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
  return match;
}

type Props = {
  onQueryChange: (query: string) => void;
  onMentionParse: (selectOptionAndCleanUp: (optionData: any) => void) => void;
  onMentionCheck: (isFoundMention: boolean) => void;
  type: RolesEnum;
};

export function MentionsUserPlugin(props: Props) {
  const { onQueryChange, onMentionParse, onMentionCheck, type } = props;

  const [editor] = useLexicalComposerContext();

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const onSelectOption = useCallback(
    (selectedOption: any, nodeToReplace: any, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionUserNode(
          {
            id: selectedOption.id,
            type,
          },
          TRIGGERS[0] + selectedOption.label
        );
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor, type]
  );

  const checkForMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);

      const slashMatch = checkForSlashTriggerMatch(text, editor);
      const isMatch = !slashMatch && mentionMatch ? mentionMatch : null;
      onMentionCheck(Boolean(isMatch));
      return isMatch;
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    // @ts-ignore
    <LexicalTypeaheadMenuPlugin
      // options={undefined}
      onQueryChange={(val) => {
        // cut off the @ char
        onQueryChange(val?.substring(1) || '');
        // onQueryChange(val);
      }}
      onSelectOption={onSelectOption}
      triggerFn={checkForMatch}
      // options={options}
      menuRenderFn={(_anchorElementRef, { selectOptionAndCleanUp }) =>
        // { _selectedIndex, selectOptionAndCleanUp, _setHighlightedIndex }
        {
          // renders outside
          onMentionParse(selectOptionAndCleanUp);
          return null;
        }
      }
    />
  );
}
