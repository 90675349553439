import { v4 as uuidv4 } from 'uuid';

import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';

import { makeObservable, observable } from 'mobx';

export class DataModel {
  id: string = uuidv4();

  constructor() {
    makeObservable(this, {
      id: observable,
    });
  }

  getCloudflareUrl(
    imageUrls: string[],
    prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
  ) {
    return getCloudflareSizeRecognition(imageUrls, prefferedSize);
  }
}

export interface IDataModel {
  id: string;
  getCloudflareUrl: (
    imageUrls: string[],
    prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
  ) => string | undefined;
}
