import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@mainApp/src/components/common';
import { LangSelect } from '@mainApp/src/components/footer/LangSelect';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { ILanguageStore } from './data/Language.store';

export function _Language() {
  const { accountSettingsStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  // should do it in this way because of the rare case when the user switch Dekstop layout to Mobile and moves back in stack
  const activeStore = accountSettingsStore.settingStores
    .language as ILanguageStore;
  const { title } = activeStore.activeSetting;
  const { t } = useTranslation('common');
  return (
    <ScreenWrapper
      rootCn="flex-1"
      contentWrapperCn="flex-1"
      title={t<string>(title)}
      showBackBtn={dimensionsStore.isMobile}
    >
      <h6 className={'themed-text mb-[4px] text-body16SB'}>{t('language')}</h6>
      <p className={'themed-text-secondary mb-[12px] text-body16R'}>
        {t('accountSettings.changeTheLanguage')}
      </p>

      <LangSelect />
    </ScreenWrapper>
  );
}

export const Language = withAnimatedStack(observer(_Language));
