import { gql } from '@urql/core';

import { ChannelFragment } from './fragments';

export const GET_CHANNEL_BY_SLUG = gql`
  query ChannelBySlug($communityId: String!, $slug: String!) {
    channelBySlug(communityId: $communityId, slug: $slug) {
      ...ChannelFragment
    }
  }
  ${ChannelFragment}
`;

export const GET_CHANNEL_GROUPS = gql`
  query ChannelGroups(
    $communityId: String!
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
  ) {
    channelGroups(
      communityId: $communityId
      before: $before
      first: $first
      after: $after
      search: $search
      sort: $sort
    ) {
      edges {
        node {
          id
          name
          communityId
          private
          defaulted
          authorId
          createdAt
          channelGroupType
          author {
            id
            username
            status
            firstName
            lastName
          }
          channels {
            edges {
              node {
                ...ChannelFragment
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${ChannelFragment}
`;

export const GET_UNGROUPED_CHANNELS = gql`
  query UngroupedChannels(
    $communityId: String!
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String
  ) {
    ungroupedChannels(
      communityId: $communityId
      before: $before
      first: $first
      after: $after
      search: $search
      sort: $sort
    ) {
      edges {
        node {
          ...ChannelFragment
        }
      }
    }
  }
  ${ChannelFragment}
`;

export const GET_CHANNELS = gql`
  query Channels(
    $communityId: String!
    $channelGroupId: String
    $before: String
    $first: Int
    $after: String
    $sort: String
    $search: String = ""
  ) {
    channels(
      communityId: $communityId
      channelGroupId: $channelGroupId
      before: $before
      first: $first
      after: $after
      search: $search
      sort: $sort
    ) {
      edges {
        node {
          ...ChannelFragment
        }
      }
    }
  }
  ${ChannelFragment}
`;

export const CREATE_CHANNEL = gql`
  mutation CreateChannel(
    $communityId: String!
    $channelGroupId: String!
    $data: ChannelCreateInput!
  ) {
    createChannel(
      communityId: $communityId
      channelGroupId: $channelGroupId
      data: $data
    ) {
      ...ChannelFragment
    }
  }
  ${ChannelFragment}
`;

export const REMOVE_CHANNEL = gql`
  mutation RemoveChannel(
    $communityId: String!
    $channelGroupId: String!
    $id: String!
  ) {
    removeChannel(
      communityId: $communityId
      channelGroupId: $channelGroupId
      id: $id
    ) {
      ...ChannelFragment
    }
  }
  ${ChannelFragment}
`;
export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel(
    $communityId: String!
    $id: String!
    $data: ChannelUpdateInput!
  ) {
    updateChannel(communityId: $communityId, id: $id, data: $data) {
      ...ChannelFragment
    }
  }
  ${ChannelFragment}
`;

export const CREATE_CHANNEL_GROUP = gql`
  mutation CreateChannelGroup(
    $communityId: String!
    $data: ChannelGroupCreateInput!
  ) {
    createChannelGroup(communityId: $communityId, data: $data) {
      id
      name
      order
      authorId
      communityId
    }
  }
`;

export const REMOVE_CHANNEL_GROUP = gql`
  mutation RemoveChannelGroup($communityId: String!, $id: String!) {
    removeChannelGroup(communityId: $communityId, id: $id) {
      id
    }
  }
`;

export const CHANNEL_GROUP_MARK_ALL_CHANNELS_AS_READ = gql`
  mutation ChannelGroupMarkAllChannelsAsRead(
    $communityId: String!
    $id: String!
  ) {
    channelGroupMarkAllChannelsAsRead(communityId: $communityId, id: $id)
  }
`;

export const UPDATE_CHANNELS_ORDER_IN_GROUP = gql`
  mutation UpdateChannelsOrder(
    $communityId: String!
    $channelGroupId: String!
    $ids: [String!]!
  ) {
    updateChannelsOrder(
      communityId: $communityId
      channelGroupId: $channelGroupId
      ids: $ids
    )
  }
`;

export const UPDATE_CHANNEL_GROUPS_ORDER = gql`
  mutation UpdategChannelGroupsOrder($communityId: String!, $ids: [String!]!) {
    updateChannelGroupsOrder(communityId: $communityId, ids: $ids)
  }
`;
