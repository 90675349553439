import { gql } from '@urql/core';

export const GET_PLATFORM_RULES = gql`
  query GetPlatformRules($parentId: String) {
    platformRules(parentId: $parentId) {
      id
      title
      rawJSON
      type
      order
      active
      childrens {
        id
        title
        rawJSON
      }
    }
  }
`;
