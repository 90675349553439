import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { Avatar } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { useTranslation } from 'react-i18next';
import { StepNamesEnum } from '../PostAuthOnboarding.store.types';

type Props = {
  rootCn?: string;
};

function _Preview(props: Props) {
  const { t } = useTranslation('common');
  const { rootCn } = props;
  const { systemStore, postAuthOnboardingStore } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.postAuthOnboardingStore,
  ]);

  const { value: username } =
    postAuthOnboardingStore.steps[StepNamesEnum.USERNAME].data;
  const { value: displayName } =
    postAuthOnboardingStore.steps[StepNamesEnum.DISPLAY_NAME].data;
  const finalDisplayName = displayName || t('displayName');
  const { croppedPreview } =
    postAuthOnboardingStore.steps[StepNamesEnum.AVATAR].data;
  return (
    <figure
      className={classNames(
        'order-0 flex-grow-1 mb-[14px] flex  min-w-[190px] max-w-[228px] flex-col items-center self-center px-[24px] md:order-1 md:mb-0 md:pl-0',
        rootCn
      )}
    >
      <Avatar
        width={120}
        height={120}
        darkMode={systemStore.isDarkTheme}
        src={croppedPreview as string}
      />
      <figcaption className="mt-[8px] text-center text-body16SB text-text-secondary dark:text-text-secondary-dark md:mt-[16px]">
        <p className="min-h-[28px] text-center text-body18SB text-text-primary dark:text-text-primary-dark ">
          {finalDisplayName}
        </p>
        {`@${username}`}
      </figcaption>
    </figure>
  );
}

export const Preview = observer(_Preview);
