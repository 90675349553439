// ====@ts-nocheck
/* =====eslint-disable */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useCallback } from 'react';
import { $createMentionChannelNode } from './MentionChannelNode';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentChannelsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentChannelsRegex.PUNCTUATION;

const TRIGGERS = ['#'].join('');

// Chars we expect to see in a channel (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignChannelsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    /** match only when after there is no space or another # */
    `(?=\\S)` +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignChannelsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    /** match only when after there is no space or another # */
    `(?=\\S)` +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

function checkForAtSignChannels(text: string, minMatchLength: number) {
  let match = AtSignChannelsRegex.exec(text);

  if (match === null) {
    match = AtSignChannelsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string) {
  const match = checkForAtSignChannels(text, 1);
  return match;
}

type Props = {
  onQueryChange: (query: string) => void;
  onMentionParse: (selectOptionAndCleanUp: (optionData: any) => void) => void;
  onMentionCheck: (isFoundMention: boolean) => void;
};

export function MentionsChannelPlugin(props: Props) {
  const { onQueryChange, onMentionParse, onMentionCheck } = props;
  const [editor] = useLexicalComposerContext();

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const onSelectOption = useCallback(
    // TODO: type
    (selectedOption: any, nodeToReplace: any, closeMenu: () => void) => {
      editor.update(() => {
        const channelNode = $createMentionChannelNode(
          {
            id: selectedOption.id,
          },
          TRIGGERS[0] + selectedOption.label
        );
        if (nodeToReplace) {
          nodeToReplace.replace(channelNode);
        }
        channelNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMatch = useCallback(
    (text: string) => {
      const match = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      const isMatch = !slashMatch && match ? match : null;
      onMentionCheck(Boolean(isMatch));
      return isMatch;
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin
      // TODO: check how it could be solved
      // @ts-ignore
      options={undefined}
      onQueryChange={(val) => {
        // cut off the @ char
        onQueryChange(val?.substring(1) || '');
      }}
      onSelectOption={onSelectOption}
      triggerFn={checkForMatch}
      menuRenderFn={(
        _anchorElementRef,
        {
          selectedIndex: _selectedIndex,
          selectOptionAndCleanUp,
          setHighlightedIndex: _setHighlightedIndex,
        }
      ) => {
        // renders outside
        onMentionParse(selectOptionAndCleanUp);
        return null;
      }}
    />
  );
}
