import * as React from 'react';
import { useScroll } from 'react-use';
/**
 * Detects when the scroll happens at the end of list or in the
 * zone with treshold
 * @param {number} threshold - 0-1 range. Percentage of the available scroll height to detect scroll end that will be substracted from the end
 * @returns [
    React.MutableRefObject<HTMLElement | HTMLDivElement | null>,
    boolean,
    {
        x: number;
        y: number;
    }
    ]

 * @param {React.RefObject<HTMLElement>} [outerRef] - if passed it will be used instead the inner ref. In the returned array there will be a link to the outer ref
 */
export function useDetectScrollEnd(threshold: number = 0, outerRef?: React.RefObject<HTMLElement>): {
  ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>,
  scrollEnd: boolean,
  scrollData: {
    x: number;
    y: number;
  }
} {
  const ref = outerRef || React.useRef<HTMLElement | HTMLDivElement | null>(null);
  const [scrollEnd, setScrollEnd] = React.useState(false);
  const scrollData = useScroll(ref);
  const { y } = scrollData;

  React.useEffect(() => {
    const el = ref.current;
    if (!el || y === 0) return;

    const clientHeight = el.clientHeight;
    const scrollHeight = el.scrollHeight;

    const heightToScroll = scrollHeight - clientHeight;

    const heightToSubstract = threshold > 0 ? threshold * heightToScroll : 0;
    const isReachedEnd = Math.round(y) >= heightToScroll - heightToSubstract;

    if (scrollEnd !== isReachedEnd) {
      setScrollEnd(isReachedEnd);
    }


  }, [y]);

  return {
    ref,
    scrollEnd,
    scrollData
  };
}
