import { classNames } from '@foundationPathAlias/utilities';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ButtonBasePropsType } from './ButtonBase';

export type ActionRoundedButtonPropsType = {
  active: boolean;
  onClick?: () => void;
} & Partial<ButtonBasePropsType>;

export function ActionRoundedButton(props: ActionRoundedButtonPropsType) {
  const { cn, children, disabled, active, onClick, ...otherProps } = props;
  return (
    <button
      className={classNames(
        'animation-classic action-rounded-button items-center rounded-[20px] bg-background-secondary py-[8px] pl-[16px] pr-[12px] text-text-primary hover:!brightness-[1.02] active:!brightness-[0.9] dark:bg-background-secondary-dark dark:text-text-primary-dark',
        active && 'active',
        disabled && 'opacity-40',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      <div className="flex w-full items-center justify-between">
        {children}
        <ChevronDownIcon className="action-rounded-button-icon ml-[12px] h-[20px] w-[20px] transition-all duration-300 ease-out" />
      </div>
    </button>
  );
}
