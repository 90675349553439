import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { validators } from '@foundationPathAlias/components';
import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';

import { withAnimatedStack } from '@mainApp/src/components/common';
import { Input } from '@mainApp/src/components/post-auth-onboarding/common/Input';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';

import { UsernameSubscription } from './UsernameSubscription';

type Props = {
  titleCn?: string;
  descriptionCn?: string;
};

export function _Username(props: Props) {
  const { titleCn, descriptionCn } = props;

  const checkingRef = useRef(false);
  const { t } = useTranslation('common');
  const { accountSettingsStore, userStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.userStore,
  ]);

  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  const { title } = accountStore.activeSetting;

  const usernameData = accountStore.data[SettingsDataNamesEnum.USERNAME];

  const successMsg = usernameData.successMessage;
  const me = userStore.me;

  useDebounce(
    async () => {
      if (!accountStore.isDirty || usernameData.error) return;
      const error = await accountStore.checkUsernameAvailability();
      if (!error) {
        checkingRef.current = false;
      }
    },
    300,
    [usernameData.data, usernameData.error]
  );

  const availabilityCheckLoading = useMinLoading(
    Boolean(usernameData.loading),
    500
  );

  const isInitialData = !accountStore.isDirty;

  const proToken =
    accountStore.proMode || me?.isActivePro ? (
      <span className="block h-[20px] w-[37px] rounded-[10px] bg-primary-100-dark text-center text-sm14T text-text-primary-dark">
        {/* I guess it should be the same on any language */}
        {/* {t('pro')} */}
        Pro
      </span>
    ) : null;

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {t('username')}
      </h6>
      <p
        className={classNames(
          'themed-text-secondary mb-[16px] text-body16R',
          descriptionCn
        )}
      >
        {t('accountSettings.usernameLengthDescr')}
      </p>
      <Input
        maxLength={16}
        addMailSignBeforeInput={true}
        getIsSuccess={(val, error) => {
          if (checkingRef.current || availabilityCheckLoading) return false;

          if (val === undefined || isInitialData) {
            return false;
          }
          return val.length > 0 && !error;
        }}
        inputName={t('username') as string}
        successMsg={t<string>(successMsg as string)}
        specificValidators={[
          validators.getMaxLengthValidator(
            15,
            t('errors.usernameMaxLength', {
              maxCharCount: 15,
            }) as string
          ),
          validators.getOnlyCharNumberPeriodUnderscoreValidator(
            t('errors.onlyCharNumberPeriodUnderscore') as string
          ),
        ]}
        showLoader={availabilityCheckLoading}
        rightSideContent={proToken}
        outerError={usernameData.error}
        placeholder={t('username') as string}
        value={usernameData.data as string}
        onChange={(val) => {
          checkingRef.current = true;
          accountStore.setUsername(val);
          const proMode = val.length <= 7;
          if (proMode) {
            accountStore.setProMode(true);
          } else {
            accountStore.setProMode(false);
          }
        }}
        onError={(error) => {
          const existingError =
            accountStore.data[SettingsDataNamesEnum.USERNAME].error;
          if (existingError !== error) {
            accountStore.setSettingValue(
              SettingsDataNamesEnum.USERNAME,
              usernameData.data,
              error
            );
          }
        }}
      />
      {accountStore.proMode && !userStore.me?.isActivePro && (
        <UsernameSubscription />
      )}
    </ScreenWrapper>
  );
}

export const Username = withAnimatedStack(observer(_Username));
