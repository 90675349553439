import { observer } from 'mobx-react-lite';

import { messageEditorStore } from './MessageEditor.store';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { SuggestionsHeader } from '../editor-area/suggestions/SuggestionsHeader';
import { ChannelSuggestionItem } from './suggestions/ChannelSuggestionItem';

function _ChannelSuggestions() {
  const channelStore = useInjection(IOC_TOKENS.channelStore);

  const channels = channelStore.flattenGroupsChannels;
  const isExisting = Boolean(channels?.length);
  return (
    <div className="relative h-full">
      <SuggestionsHeader title="Channels" />

      {isExisting && channels ? (
        <ul>
          {channels.map((channelModel: ChannelModel) => {
            const { name, id } = channelModel.serverData;
            return (
              <ChannelSuggestionItem
                key={id}
                channelName={name}
                onClick={() => {
                  messageEditorStore.hideDropup();
                  messageEditorStore.selectOptionAndCleanUp({
                    id: channelModel.serverData.id,
                    // should be display name probably?
                    label: name,
                  });
                }}
              />
            );
          })}
        </ul>
      ) : (
        <span>No result</span>
      )}
    </div>
  );
}

export const ChannelSuggestions = observer(_ChannelSuggestions);
