import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@mainApp/src/components/common';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';

export function _Policy() {
  const { t } = useTranslation('common');
  return (
    <ScreenWrapper title={t('policy.label')} showBackBtn={true}>
      <h3>Policy Content</h3>
    </ScreenWrapper>
  );
}

export const Policy = withAnimatedStack(_Policy);
