import { ActionsPanel } from '@foundationPathAlias/widgets/sidebar-modal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

export function _ActionsPanelWithMobx() {
  const {
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);

  const activeChildScren = capStore.activeChildScreen;
  const actionsPanelData = activeChildScren?.actionsPanelData;
  const { t } = useTranslation('common');

  return (
    <ActionsPanel
      isMobile={isMobile}
      getTranslatedString={(key: string) => t(key)}
      actionsPanelData={actionsPanelData}
      isLoading={activeChildScren?.isLoading}
      showActionPanel={activeChildScren?.showActionPanel}
      isError={activeChildScren?.isError}
      isDisableProceedAction={activeChildScren?.isDisableProceedAction}
    />
  );
}

export const ActionsPanelWithMobx = observer(_ActionsPanelWithMobx);
