import { classNames } from '@foundationPathAlias/utilities';

export type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  labelCn?: string;
  inputCn?: string;
};

export function CheckboxCommon(props: Props) {
  const { checked, onChange, labelCn, inputCn } = props;

  return (
    <label
      className={classNames(
        'inline-block h-[18px] w-[18px] cursor-pointer rounded-full border-[1px] transition-all duration-100 ease-in  ',
        checked && 'border-[5px] border-blue-500 dark:bg-white',
        labelCn
      )}
    >
      <input
        id=""
        type="checkbox"
        className={classNames('hidden', inputCn)}
        checked={checked}
        onChange={(e) => {
          const checked = e.target.checked;
          onChange(checked);
        }}
      />
    </label>
  );
}
