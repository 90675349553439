import { ChannelPreview } from '../shared/channel-preview/ChannelPreview';
import { CommunityPreviewMobile } from './CommunityPreviewMobile';
export function CommunityAndChannelPreviewMobile() {
  return (
    <div className="[&>.community-preview-mobile]:mb-[64px]">
      <CommunityPreviewMobile />
      <ChannelPreview />
    </div>
  );
}
