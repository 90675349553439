export const isBrowser = typeof window !== 'undefined';
import { ContentReactionPayload } from '@10x/foundation/types';
import { NextRouter } from 'next/router';
import { UserModel } from '../stores/User.model';
export const isNavigator = typeof navigator !== 'undefined';

/** get current window location href without query */
export function refineURL() {
  // get full URL
  // eslint-disable-next-line
  const currURL = window.location.href; // get current address

  const queryIndex = currURL.indexOf('?');
  if (queryIndex === -1) return;

  const res = currURL.substring(0, queryIndex);

  return res;
}

export function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export function isForibiddenError(error: any) {
  return ~~error.message.indexOf('Forbidden resource');
}

/**
 * If removeList is empty, the function removes all params from url.
 */
export const removeQueryParamsFromRouter = (
  router: NextRouter,
  removeList: string[] = []
) => {
  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: true }
  );
};

// press duration in ms to enable long press
export const LONG_PRESS_DURATION = 1000;

// Utility function to merge multiple CSS style objects
export const mergeStyles = (
  ...styles: Record<string, any>[]
): Record<string, any> => {
  return Object.assign({}, ...styles);
};

export const formatReactedNames = (
  reaction: ContentReactionPayload,
  me: UserModel | null,
  translatedAnd: string,
  translatedOthers: string
) => {
  const { totalCount, edges } = reaction.users;
  const iReacted = reaction.reacted;
  const myName = me?.displayName;

  // Function to get names of reactors, excluding the current user's name
  const getNames = (count: number): string[] => {
    return edges
      .map((edge) => edge.node?.displayName)
      .filter(
        (name): name is string =>
          name !== null && name !== undefined && name !== myName
      )
      .slice(0, count);
  };

  // Function to format names into a readable string
  const formatNames = (names: string[]): string => {
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} ${translatedAnd} ${names[1]}`;
    return `${names[0]}, ${names[1]}`;
  };

  // Handle edge cases
  if (totalCount === 1 && iReacted) return { text: null, others: false };

  // Determine how many names to display
  const namesToShow = iReacted ? 2 : totalCount > 2 ? 2 : totalCount;

  // Get names of reactors
  let names = getNames(namesToShow);

  // If the current user reacted, add 'You' to the beginning of the list
  if (iReacted) {
    names = ['You', ...names];
  }

  // Calculate if there are additional reactors not shown in the list
  const remainingCount = totalCount - (iReacted ? names.length : namesToShow);
  const showOthers = remainingCount > 0;

  // Return the formatted result
  return {
    text: names.length > 0 ? formatNames(names) : null,
    others: showOthers
      ? `${translatedAnd} ${remainingCount} ${translatedOthers}`
      : false,
  };
};
