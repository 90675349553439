import { v4 as uuidv4 } from 'uuid';

const ChannelMessageAttachmentPayload = {
  id: 'optimistic_attachment_payload_id',
  filename: 'optimistic_attachment_payload_filename',
  url: null,
  urls: [],
  __typename: 'ChannelMessageAttachmentPayload',
};

// TODO: type
export const generateChannelMessageAttachmentPayload = (payload = {}) => {
  const id = uuidv4();
  return {
    ...ChannelMessageAttachmentPayload,
    id: `optimistic_channel_message_attachment_payload-${id}`,
    ...payload,
  };
};
