import { IEventBus } from '@foundationPathAlias/utilities';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import { Axios } from 'axios';
import { inject, injectable } from 'inversify';

import { FileUploadEventsEnum } from './events';

import { PreSignedPost } from '@10x/foundation/types';

import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';
import { AttachmentsRegistry } from './types';

@injectable()
export class FileUploadService {
  // @ts-ignore
  private _eventBus: IEventBus<FileUploadEventsEnum>;
  private http: Axios;

  queue: AttachmentModel[] = [];

  // cloudflare urls from BE to upload attachments
  private batchUrls: PreSignedPost[] = [];

  // Abort Controller
  // @ts-ignore
  private controller: AbortController | null;

  private currentUploadingAttachment: AttachmentModel | null = null;

  /**
   * used to store the deleting file name in the queue
   * to stop the uploading if it try to proceed the file
   * while it's still deleting
   *  */
  private deletingAttachmentInQueueId: string | null = null;

  get isQueueEmpty() {
    return this.queue.length === 0;
  }

  get eventBus() {
    if (!this._eventBus) {
      throw new Error('No event bus');
    }

    return this._eventBus;
  }

  set eventBus(val: IEventBus<FileUploadEventsEnum>) {
    this._eventBus = val;
  }

  get isBatchUrlExist() {
    return Boolean(this.batchUrls.length);
  }

  constructor(@inject(IOC_TOKENS.http) http: Axios) {
    this.http = http;
  }

  setBatchUrls = (batchUrls: PreSignedPost[]) => {
    // should save previous if the user adds new attachments during the uploading
    this.batchUrls = [...this.batchUrls, ...batchUrls];
    this.check();
    return this;
  };

  // check if the batch url and the queue are ready
  private check() {
    /**
     * if some file is uploading don't do anything.
     * it means that the new attachments had been added
     * while the uploading is going on
     *  */
    if (this.currentUploadingAttachment) return;
    if (this.batchUrls.length && this.queue.length) {
      // TODO: test to avoid unsync possibility

      this.proceedQueue();
    }
  }

  uploadAttachments = (attachmentsRegistry: AttachmentsRegistry) => {
    this.queue = [...this.queue, ...Object.values(attachmentsRegistry)] as any;
    this.check();
  };

  private proceedQueue() {
    const currentUploadingAttachment = this.queue.shift();
    const currentUploadingUrlData = this.batchUrls.shift();

    // I found a rare case when the createBatchUrl mutation returns nulled url's between the normal ones so will just add a handler that shows the failed attachment UI
    if (!currentUploadingUrlData) {
      this.handleUploadError(
        {
          message: 'Batch URL for this item is null!',
          name: 'AttachmentBatchUrlError',
        },
        currentUploadingAttachment
      );
      this.handleUploadEnd();
      return;
    }

    if (!currentUploadingAttachment || !currentUploadingUrlData) {
      // basically it shouldn't happen because the length of batch/queue should be the same
      throw new Error('Queue unsync error');
    }

    this.currentUploadingAttachment = currentUploadingAttachment;
    const serverAttachmentId = currentUploadingUrlData.id;

    this.eventBus.emit(
      FileUploadEventsEnum.UPLOADING_START,
      currentUploadingAttachment
    );

    this.uploadFile(
      currentUploadingUrlData.url,
      currentUploadingAttachment.file as File
    )
      .then(() => {
        this.updateCompletedAttachment(serverAttachmentId);

        this.eventBus.emit(FileUploadEventsEnum.UPLOADING_SUCCESS, {
          uploadedAttachmentModel: currentUploadingAttachment,
          serverAttachmentId,
        });

        if (this.isQueueEmpty) {
          this.emitQeueEnd(this.currentUploadingAttachment as AttachmentModel);
        }
      })
      .catch((e) => {
        this.handleUploadError(e, this.currentUploadingAttachment);
      })
      .finally(this.handleUploadEnd);
  }

  removeAttachment = (attachmentModel: AttachmentModel) => {
    // shouldn't do anything only if there is no query and no currently processing attachment model
    if (this.isQueueEmpty && !this.currentUploadingAttachment) {
      return;
    }

    const id = attachmentModel.id;

    if (this.currentUploadingAttachment?.id === id) {
      // means that just stop request and this attachment isn't it the queue or batch url (it was unshifter in the .process method)
      this.controller?.abort();
    }

    const indexInQueue = this.queue.indexOf(attachmentModel);

    // if there are no more files to upload so just hide the progressbar
    if (this.isQueueEmpty) {
      this.emitQeueEnd(attachmentModel);
      // there is no more files to upload
    } else if (indexInQueue !== -1) {
      // attachment in the queue but it isn't uploading

      this.deletingAttachmentInQueueId = id;
      // should save the index of the deleted file to remove
      // an appropriate URL from the batch urls
      let indexOfThedeletingFile: number;
      this.queue = this.queue.filter((attachment: AttachmentModel, index) => {
        indexOfThedeletingFile = index;
        return attachment.id !== id;
      });
      this.batchUrls = this.batchUrls.filter(
        (_url, index) => index !== indexOfThedeletingFile
      );
    }
  };

  private emitQeueEnd = (attachmentModel: AttachmentModel) => {
    this.eventBus.emit(FileUploadEventsEnum.QUEUE_END, attachmentModel);
  };

  /**
   *
   * @param serverAttachmentId comes from the BE for every batch url for attachment
   */
  private updateCompletedAttachment = (serverAttachmentId?: string) => {
    // const successResponse = response?.data?.result;
    if (this.currentUploadingAttachment === null) {
      throw new Error('Current uploading Attachment is null');
    }
    if (!serverAttachmentId) {
      throw new Error('serverAttachmentId is empty');
    }
  };

  private handleUploadEnd = () => {
    this.currentUploadingAttachment = null;

    if (!this.isQueueEmpty) {
      this.check();
    }
  };

  private uploadFile = (url: string, file: File) => {
    this.controller = new AbortController();
    const { signal } = this.controller;
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (!progressEvent) return;
        const { loaded, total } = progressEvent;
        if (!total) {
          throw new Error(
            'File upload progress event total percentage is undefined'
          );
        }

        const loadedPercent = Math.round((loaded / total) * 100);

        this.eventBus.emit(
          FileUploadEventsEnum.UPLOADING_PROGRESS,
          loadedPercent
        );
      },
    });
  };

  private handleUploadError = (
    e: Error,
    attachmentModel: AttachmentModel | void | null
  ) => {
    // shouldn't call the error handler on the fetch abort event
    if (e.message !== 'canceled' && attachmentModel) {
      this.eventBus.emit(FileUploadEventsEnum.UPLOADING_ERROR, {
        error: e,
        failedAttachmentModel: attachmentModel,
      });
      // ---- NEW FUNCTIONALITY: stop qeue, clear everything and retry it again 3 times

      this.reset();
    }
  };

  private reset = () => {
    this.queue = [];
    this.batchUrls = [];
    this.controller = null;
  };
}

export interface IFileUploadService {
  upload: () => void;
}
