
import { Display } from "../Display"

import { DisplaySettingsEnum } from "@mainApp/src/modules/account-settings/data/types"

export const displaySettingsComponents = {
    [DisplaySettingsEnum.INITIAL]: {
        id: DisplaySettingsEnum.INITIAL,
        title: 'display',
        Component: Display
    },

}