import { eventBus } from './EventBus.singletone';
import { GlobalEvents } from './events';

import { IOC_TOKENS, iocContainer } from '../ioc';
import { IMessageStore } from '../stores';

// they're permanent so they shouldn't be disposed
eventBus.on(GlobalEvents.MESSAGE_SUBSCRIPTION_CREATED, (value) => {
  const messageStore = iocContainer.get<IMessageStore>(IOC_TOKENS.messageStore);
  messageStore.onSubscriptionCreated(value);
});
eventBus.on(GlobalEvents.MESSAGE_SUBSCRIPTION_DELETED, (value) => {
  const messageStore = iocContainer.get<IMessageStore>(IOC_TOKENS.messageStore);
  messageStore.onSubscriptionDeleted(value);
});
eventBus.on(GlobalEvents.MESSAGE_SUBSCRIPTION_UPDATED, (value) => {
  const messageStore = iocContainer.get<IMessageStore>(IOC_TOKENS.messageStore);
  messageStore.onSubscriptionUpdated(value);
});
