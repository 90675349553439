import {
  ISidebarModalChildScreen,
  SidebarModalChildScreen,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';
import {
  IBaseRule,
  ICommunityRule,
  IPlatformRule,
  IRulesStore,
  TextFieldType,
} from '@mainApp/src/stores';
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

export class RulesStore
  extends SidebarModalChildScreen<ScreenIdsValuesType>
  implements IRulesStoreLocal
{
  private showActionReactionDisposer: IReactionDisposer;

  rulesStore: IRulesStore;

  selectedRuleDetails: IBaseRule | null = null;
  parentRuleDetails: IPlatformRule | null = null;
  selectedRuleIdToEdit: string | null = null;
  submittingRule = false;
  ruleToDelete: null | string = '';
  childRule: IPlatformRule | null = null;

  ruleName: TextFieldType = {
    value: '',
    error: null,
  };
  ruleDescription: TextFieldType = {
    value: '',
    error: null,
  };

  resetConditionals = () => {
    this.resetData();
  };

  constructor() {
    super(ScreenIds, screensConfig);

    this.rulesStore = iocContainer.get(IOC_TOKENS.rulesStore);

    makeObservable(this, {
      activeScreen: computed,
      isInitialScreen: computed,
      actionsPanelData: computed,

      activeScreenId: observable,
      isDirty: observable,
      showActionPanel: observable,
      selectedRuleDetails: observable,
      parentRuleDetails: observable,
      selectedRuleIdToEdit: observable,
      ruleName: observable,
      ruleDescription: observable,
      submittingRule: observable,
      ruleToDelete: observable,
      isLoading: observable,
      childRule: observable,

      setRuleName: action,
      setRuleDescription: action,
      goToRuleDetails: action,
      goToEditRule: action,
      submitRule: action,
      deleteRule: action,
      confirmDelete: action,
      undoDelete: action,
      goToChildRule: action,

      resetConditionals: action,
      resetData: action,
      setActiveScreenId: action,
      setNextScreenId: action,
      reset: action,
    });

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );
  }

  // Deletion section - Begin
  deleteRule = (ruleId: string) => {
    this.ruleToDelete = ruleId;
  };

  confirmDelete = async () => {
    if (!this.ruleToDelete) return;

    try {
      await this.rulesStore.deleteRule(this.ruleToDelete);

      runInAction(() => {
        this.ruleToDelete = null;
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  undoDelete = () => {
    this.ruleToDelete = null;
  };
  // Deletion section - End

  submitRule = async () => {
    runInAction(() => {
      this.submittingRule = true;
      this.isLoading = true;
    });

    if (this.selectedRuleIdToEdit) {
      try {
        await this.rulesStore.editRule({
          rawJSON: this.ruleDescription.value,
          title: this.ruleName.value,
          id: this.selectedRuleIdToEdit,
        });

        runInAction(() => {
          this.resetData();
          this.submittingRule = false;
          this.isLoading = false;
          this.back();
        });
      } catch {
        runInAction(() => {
          this.isLoading = false;
          this.submittingRule = false;
        });
      }
    } else {
      try {
        await this.rulesStore.createRule({
          rawJSON: this.ruleDescription.value,
          title: this.ruleName.value,
        });

        runInAction(() => {
          this.resetData();
          this.submittingRule = false;
          this.isLoading = false;
          this.back();
        });
      } catch (error) {
        runInAction(() => {
          this.isLoading = false;
          this.submittingRule = false;
        });
      }
    }
  };

  setRuleName = (val: TextFieldType) => {
    this.ruleName = val;
    runInAction(() => {
      this.isDirty = true;
      this.showActionPanel = true;
    });
  };
  setRuleDescription = (val: TextFieldType) => {
    this.ruleDescription = val;
    runInAction(() => {
      this.isDirty = true;
      this.showActionPanel = true;
    });
  };

  goToRuleDetails = <T extends IBaseRule>(content: T) => {
    this.selectedRuleDetails = content;

    this.setNextScreenId(ScreenIds.RULE_DETAILS);
  };

  goToChildRule = (childRule: IPlatformRule) => {
    this.childRule = childRule;
    this.setNextScreenId(ScreenIds.CHILD_RULE);
  };

  get actionsPanelData() {
    return {
      cancelAction: () => {
        this.back();
        runInAction(() => {
          this.showActionPanel = false;
        });
      },
      getCancelActionText: () => 'cancel',
      proceedAction: () => {
        this.submitRule();
      },
      getProceedActionText: () => 'saveChanges',
    };
  }

  goToCreateRule = () => {
    this.resetData();
    this.setNextScreenId(ScreenIds.RULES_EDITOR);
    runInAction(() => {
      this.isDirty = false;
      this.showActionPanel = true;
    });
  };

  goToEditRule = (content: ICommunityRule) => {
    this.selectedRuleIdToEdit = content.id;
    this.setRuleName({
      value: content.title || '',
      error: null,
    });
    this.setRuleDescription({
      value: content.rawJSON,
      error: null,
    });
    this.setNextScreenId(ScreenIds.RULES_EDITOR);

    runInAction(() => {
      this.isDirty = false;
      this.showActionPanel = true;
    });
  };

  resetData(): void {
    this.selectedRuleIdToEdit = null;
    this.ruleName = { error: null, value: '' };
    this.ruleDescription = { error: null, value: '' };
    runInAction(() => {
      this.isDirty = false;
      this.showActionPanel = false;
    });
  }

  dispose = () => {
    this.showActionReactionDisposer();
  };
}

export interface IRulesStoreLocal
  extends ISidebarModalChildScreen<ScreenIdsValuesType> {
  readonly ruleName: TextFieldType;
  readonly ruleDescription: TextFieldType;
  readonly selectedRuleDetails: ICommunityRule | IPlatformRule | null;
  readonly selectedRuleIdToEdit: string | null;
  readonly submittingRule: boolean;
  readonly ruleToDelete: null | string;
  readonly childRule: IPlatformRule | null;

  setRuleName: (val: TextFieldType) => void;
  setRuleDescription: (val: TextFieldType) => void;

  goToRuleDetails<T extends IBaseRule>(content: T): void;
  goToChildRule: (childRule: IPlatformRule) => void;
  goToEditRule: (content: ICommunityRule) => void;
  goToCreateRule: () => void;

  submitRule: () => Promise<void>;

  deleteRule: (ruleId: string) => void;
  confirmDelete: () => void;
  undoDelete: () => void;
}
