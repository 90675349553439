import { classNames } from '@foundationPathAlias/utilities';
export type CircleButtonBasePropsType = {
  cn?: string;
  onClick: (e?: any) => void;
  disabled?: boolean;
  children: JSX.Element;
};

export function CircleButtonBase(props: CircleButtonBasePropsType) {
  const { onClick, cn = '', disabled, children } = props;
  return (
    <button
      className={classNames(
        'flex h-[22px] w-[22px] items-center justify-center rounded-full ',
        disabled ? 'opacity-40' : 'hover-el',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
