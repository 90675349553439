import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
export type Props = {
  children: React.ReactNode;
};
export function ErrorChip(props: Props) {
  const { children } = props;
  return (
    <p className="m-auto flex h-[38px] max-w-[336px] items-center justify-center rounded-[8px] bg-color-11 px-[8px] py-[8px] text-sm14R text-element-error dark:bg-color-11-dark dark:text-element-error-dark">
      <ExclamationCircleIcon className="mr-[4px] h-[18px] w-[18px]" />{' '}
      {children}
    </p>
  );
}
