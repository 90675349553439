import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';

import { SubscriptionList as SubscriptionListUI } from '@mainApp/src/components/common';

export function _SubscriptionList() {
  const { accountSettingsStore, userStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.userStore,
  ]);

  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  const usernameData = accountStore.data[SettingsDataNamesEnum.USERNAME];

  const activeSubscriptionType = usernameData.activeSubscriptionType;

  const subscriptionPrices = userStore.subscriptionPrices;

  return (
    <SubscriptionListUI
      activeSubscriptionType={activeSubscriptionType}
      subscriptionPrices={subscriptionPrices}
      onChange={(slug: SubscriptionTypeEnum) => {
        accountStore.setSettingData(SettingsDataNamesEnum.USERNAME, {
          activeSubscriptionType: slug as SubscriptionTypeEnum,
        });
      }}
    />
  );
}

export const SubscriptionList = observer(_SubscriptionList);
