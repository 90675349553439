
const ACTIONS = {
    RESET: 'RESET',
    SET_PREV_ITEM: 'SET_PREV_ITEM',
    SET_VISIBLE: 'SET_VISIBLE',
    NEXT: 'NEXT',
    BACK: 'BACK',
    ON_ENTER_ANIMATION_END: 'ON_ENTER_ANIMATION_END',
    ON_LEAVING_ANIMATION_END: 'ON_LEAVING_ANIMATION_END',
} as const;


export {
    ACTIONS
};
