import { ScreenIds } from '../constants';
import { Users } from '../Users';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'Users',
    Component: Users,
  },
};
