import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  CodeForm,
  CodeFormWrapper,
} from '@mainApp/src/components/common/code-form';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useState } from 'react';

type Props = {
  onCloseBtnClick: () => void;
  email: string;
  loginMode: boolean;
  dedicatedPageMode?: boolean;
};

function _Code(props: Props) {
  const { onCloseBtnClick, email, loginMode, dedicatedPageMode } = props;

  const authStore = useInjection(IOC_TOKENS.authStore);

  const [otpVerifyError, setOtpVerifyError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('auth');

  const title = t('checkYourInbox');
  const subHeadingKey = loginMode ? 'codeSentSignIn' : 'codeSentSignUp';
  const subHeading = t(subHeadingKey, { email: email });

  return (
    <div className={dedicatedPageMode ? 'max-w-[376px]' : ''}>
      <CodeFormWrapper
        title={title}
        subHeading={subHeading}
        onCloseBtnClick={onCloseBtnClick}
        dedicatedPageMode={dedicatedPageMode}
      >
        <CodeForm
          otpError={otpVerifyError}
          loading={loading}
          onChange={() => {
            // reset validation error on change if exists
            if (otpVerifyError) {
              setOtpVerifyError(false);
            }
          }}
          onSubmit={async (otp: string) => {
            const { verifySignIn, verifySignUp } = authStore;
            setLoading(true);

            const action = loginMode ? verifySignIn : verifySignUp;
            const error = await action(otp);

            setLoading(false);
            if (error) {
              setOtpVerifyError(error);
            } else {
              onCloseBtnClick();
            }
          }}
        />
      </CodeFormWrapper>
    </div>
  );
}

export const Code = observer(_Code);
