// TODO: create later some generic component based on it
import { CheckboxCommon, Props } from '@foundationPathAlias/components';

import { classNames } from '@foundationPathAlias/utilities';

export type CheckboxCollapseProps = {
  /** title string */
  title: string | JSX.Element;
  description: string | JSX.Element;
  checked: boolean;
  /** root style classes */
  cn?: string;
  headerCn?: string;
  CheckboxCommonProps?: Partial<Props>;
  onChange: (checked: boolean) => void;
};

export function CheckboxOption(props: CheckboxCollapseProps) {
  const {
    title,
    description,
    checked,
    cn,
    headerCn,
    CheckboxCommonProps = {},
    onChange,
  } = props;

  return (
    <div
      className={classNames(
        'my-[12px] flex w-full items-center space-x-[24px]',
        cn
      )}
    >
      <p className={classNames('flex flex-1 flex-col text-left ', headerCn)}>
        <span className="themed-text mb-[4px] text-sm14T">{title}</span>
        <span className="themed-text-secondary text-sm14R">{description}</span>
      </p>

      <CheckboxCommon
        {...CheckboxCommonProps}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}
