
import { About } from "../About"
import { Policy, Terms } from "../options"

import { AboutSettingsEnum } from "@mainApp/src/modules/account-settings/data/types"

export const aboutSettingsComponents = {
    [AboutSettingsEnum.INITIAL]: {
        id: AboutSettingsEnum.INITIAL,
        title: 'about.label',
        Component: About
    },
    [AboutSettingsEnum.POLICY]: {
        id: AboutSettingsEnum.POLICY,
        title: 'policy.label',
        Component: Policy
    },
    [AboutSettingsEnum.TERMS]: {
        id: AboutSettingsEnum.TERMS,
        title: 'terms.label',
        Component: Terms
    },

}