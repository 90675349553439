import { animated, useSpring } from '@react-spring/web';
import { forwardRef, useCallback, useLayoutEffect, useRef } from 'react';

import { classNames, useResizeObserver } from '@10x/foundation/src/utilities';

export type Props = {
  show: boolean;
  children: React.ReactNode;
  rootCn?: string;
  maxHeight?: number;
  onScrollBarAppearance?: (isScrollBarAppeared: boolean) => void;
};

function _AnimatedDropup(
  props: Props,
  ref: React.MutableRefObject<HTMLElement | null>
) {
  const {
    show,
    rootCn,
    children,
    maxHeight = 220,
    onScrollBarAppearance,
  } = props;
  const contentElRef = useRef<HTMLDivElement | null>(null);
  const showRef = useRef<boolean>(show);
  showRef.current = show;

  const [springs, api] = useSpring(() => ({
    from: { height: 0 },
    config: {
      clamp: true,
      precision: 1,
    },
  }));

  useLayoutEffect(() => {
    const el = contentElRef.current;
    if (!el) return;

    const contentHeight = el?.offsetHeight || 0;
    api.start({
      to: {
        height: show ? contentHeight : 0,
      },
    });
  }, [show, contentElRef.current]);

  const onResizeCb = useCallback((entries: ResizeObserverEntry[]) => {
    const entry = entries[0];
    if (entry.contentRect && showRef.current) {
      const actualHeight = entry.contentRect.height;
      const oldHeight = springs.height.get();
      if (oldHeight !== actualHeight) {
        api.start({
          to: {
            height: actualHeight,
          },
          onResolve: (_result) => {
            const contentEL = contentElRef.current;
            if (!contentEL || !onScrollBarAppearance) return;
            const scrollHeight = contentEL.scrollHeight;
            const clientHeight = contentEL.clientHeight;

            onScrollBarAppearance(scrollHeight > clientHeight);
          },
        });
      }
    }
  }, []);

  useResizeObserver(contentElRef.current as HTMLDivElement, onResizeCb);

  return (
    <div className={classNames('relative w-full', rootCn)}>
      <animated.div
        className="absolute bottom-[6px] w-full shadow-drop-shadow-menu"
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          overflow: 'hidden',
          ...springs,
        }}
      >
        <div
          ref={(el) => {
            contentElRef.current = el;
            ref.current = el;
          }}
          className="absolute bottom-0 w-full overflow-auto bg-element-subtle"
          style={{
            maxHeight,
          }}
        >
          {children}
        </div>
      </animated.div>
    </div>
  );
}

// @ts-ignore
export const AnimatedDropup = forwardRef(_AnimatedDropup);
