import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  messages: any;
  messageStore: any;
};
export const HistoryLoadingPanel = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { messages, messageStore } = props;
    const { t } = useTranslation('common');
    return (
      <div
        className="absolute left-0 top-0 flex h-[50px] w-full items-center justify-center text-center"
        ref={ref}
      >
        <button
          disabled={
            !messages.meta?.pageInfo?.hasPreviousPage || messages?.loading
          }
        >
          {messages?.loading ||
          messageStore.messages.meta.pageInfo?.hasPreviousPage
            ? `${t('loadingMore')}...`
            : t('nothingMoreToLoad')}
        </button>
      </div>
    );
  }
);
