import { ProgressBar } from '@10x/foundation/src/components';
import { classNames } from '@10x/foundation/src/utilities';
import dynamic from 'next/dynamic';

import { useTranslation } from 'react-i18next';

import { CloseIconBtn } from '../icons/CloseIconBtn';
import { SuccessIcon } from '../icons/SuccessIcon';

export const CropperFND = dynamic(
  () => {
    return import('@10x/foundation/src/components/cropper').then((res) => {
      return res.Cropper;
    });
  },
  { ssr: false }
);

export const LoadedImagePreview = ({
  error,
  onCloseClick,
  uploading,
  uploadingPercentage,
  fileName,
  fileSize,
}: any) => {
  const isError = !uploading && error;
  const { t } = useTranslation(['common']);
  return (
    <div
      className={classNames(
        'min-h-[104px] max-w-[348px] rounded-[5px] border-[1px] border-element-normal p-[16px] dark:border-element-normal-dark',
        isError && 'border-element-error dark:border-element-error-dark'
      )}
    >
      <div className="flex justify-between">
        <h5 className="themed-text inline justify-between truncate text-body16R">
          {fileName}
        </h5>
        <CloseIconBtn className="h-4 w-4 self-center" onClick={onCloseClick} />
      </div>

      <span className="dark:text-placeholder-dark block text-left text-exstraSm12R text-text-placeholder">
        {fileSize}
      </span>

      {uploading ? (
        <ProgressBar progressPercent={uploadingPercentage} />
      ) : (
        <div className="mt-[2px] flex items-center space-x-[6px]">
          <SuccessIcon error={error} />
          <span
            className={
              error
                ? 'text-element-error dark:text-element-error-dark'
                : 'text-element-success dark:text-element-success-dark'
            }
          >
            {error ? error : t('upload.uploaded')}
          </span>
        </div>
      )}
    </div>
  );
};
