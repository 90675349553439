import {
  CirclePlusButton,
  OutlineButton,
  RoleChip,
  Tooltip,
} from '@foundationPathAlias/main';

import { Row } from '@foundationPathAlias/components/table';

import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { IRole } from '@mainApp/src/stores/User.model';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddRolesTooltipContent,
  AddRolesTooltipWrapper,
} from './tooltip-contents';
import { UsersTableDisplayNameCell } from './UsersTableDisplayNameCell';

type UsersTableRoleCellProps = {
  row: Row<Partial<TableUser>>;
  hoveredRow: boolean;
};

const ROLES_LIMIT = 1;

const _UsersTableRoleCell = ({ row, hoveredRow }: UsersTableRoleCellProps) => {
  const { t } = useTranslation();
  const {
    systemStore: { isDarkTheme },
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.capStore,
  ]);

  const userId = row.original.id!;

  const userStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const rolesArr = userStoreLocal.selectedRolesByUser[userId] || [];

  const [addRolesShow, setAddRolesShow] = useState(false);
  const getRole = (role: IRole) => <RoleChip label={role.name} />;

  const restOfRoles = rolesArr.slice(ROLES_LIMIT).map(getRole);

  const handleOpenAddRolesBottomSheet = () => {
    capStore.setBottomSheetConfig({
      content: addRolesMobileContent,
      show: true,
    });
  };

  const rolesBottomSheetContent = (
    <div>
      <header className="px-[24px] pb-[16px]">
        <UsersTableDisplayNameCell userData={row.original} />
      </header>

      <div className="min-h-[240px] border-b border-t border-b-element-subtle border-t-element-subtle dark:border-b-element-subtle-dark dark:border-t-element-subtle-dark">
        {rolesArr.length ? (
          <div className="flex flex-wrap items-start justify-start gap-[16px] px-[16px] py-[20px]">
            {rolesArr.map((role) => (
              <RoleChip key={role.id} label={role.name} />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center pt-[48px]">
            <span className="text-center text-body16SB">No roles</span>
          </div>
        )}
      </div>

      <div className="p-[16px]">
        <OutlineButton
          cn="border-element-subtle dark:border-b-element-subtle-dark"
          onClick={handleOpenAddRolesBottomSheet}
        >
          {t('cap:users.addNewRole')}
        </OutlineButton>
      </div>
    </div>
  );

  const handleCancelChanges = () => {
    userStoreLocal.cancelRoleChanges(userId);
  };

  const handleSaveChanges = () => {
    userStoreLocal.saveRoleChanges(userId);
  };

  const handleOpenRolesBottomSheet = () => {
    capStore.setBottomSheetConfig({
      content: rolesBottomSheetContent,
      show: true,
      onClose: handleCancelChanges,
    });
  };

  const handleCloseBottomSheet = () => {
    capStore.setBottomSheetConfig({
      show: false,
      content: null,
    });
  };

  const addRolesMobileContent = (
    <AddRolesTooltipWrapper
      onBack={() => {
        handleOpenRolesBottomSheet();
        handleCancelChanges();
      }}
      onCancel={() => {
        handleCloseBottomSheet();
        handleCancelChanges();
      }}
      onSave={() => {
        handleSaveChanges();
      }}
    >
      <AddRolesTooltipContent row={row} />
    </AddRolesTooltipWrapper>
  );

  const addRolesButton = (
    <CirclePlusButton
      cn={classNames('w-[32px] h-[32px]', {
        'md:invisible': !hoveredRow,
      })}
      onClick={() => {
        if (isMobile) {
          handleOpenAddRolesBottomSheet();
          return;
        }

        setAddRolesShow(true);
      }}
    />
  );

  const extraRolesTriggerEl = (
    <RoleChip
      cn="cursor-pointer"
      label={`+${rolesArr.slice(ROLES_LIMIT, rolesArr.length).length}`}
    />
  );

  return (
    <div className="flex w-max items-center gap-[8px] md:pl-[16px]">
      {rolesArr.slice(0, ROLES_LIMIT).map(getRole)}

      {rolesArr.length > ROLES_LIMIT && (
        <>
          {isMobile ? (
            <div onClick={handleOpenRolesBottomSheet}>
              {extraRolesTriggerEl}
            </div>
          ) : (
            <Tooltip
              contentStyle={{
                width: 'auto',
                padding: 0,
                boxShadow: '0px 1px 40px 0px rgba(26, 26, 26, 0.16)',
                border: 0,
                overflow: 'hidden',
              }}
              offsetY={8}
              position={['bottom left']}
              arrow={false}
              darkMode={!isDarkTheme}
              triggerEl={<div>{extraRolesTriggerEl}</div>}
            >
              <div className="flex flex-col items-start gap-[8px] px-[16px] py-[12px]">
                {restOfRoles}
              </div>
            </Tooltip>
          )}
        </>
      )}

      {isMobile ? (
        <div>{addRolesButton}</div>
      ) : (
        <Tooltip
          darkMode={addRolesShow ? !isDarkTheme : isDarkTheme}
          arrow={!addRolesShow}
          position={addRolesShow ? ['bottom left'] : 'top center'}
          onClose={() => {
            setAddRolesShow(false);
          }}
          mouseEnterDelay={600}
          offsetY={8}
          contentStyle={
            addRolesShow
              ? {
                  padding: 0,
                  width: '100%',
                  maxWidth: 232,
                }
              : undefined
          }
          closeOnDocumentClick={true}
          repositionOnResize
          on={[addRolesShow ? 'click' : 'hover']}
          triggerEl={<div>{addRolesButton}</div>}
        >
          {addRolesShow ? (
            <AddRolesTooltipContent row={row} />
          ) : (
            <span className="themed-text-inverted">
              {t('cap:users.addRole')}
            </span>
          )}
        </Tooltip>
      )}
    </div>
  );
};

export const UsersTableRoleCell = observer(_UsersTableRoleCell);
