import { gql } from '@urql/core';
import { PageInfoFragment } from './commonFragments';
import { CommonUserFragment } from './userFragments';
export const MessageBaseFragment = gql`
  fragment MessageBaseFragment on ChannelMessagePayload {
    id
    text
    rawJson
    contentType
    parentId
    channelId
    threadId
    read
    authorId
    communityId
    createdAt
  }
`;

export const MessageFragment = gql`
  fragment MessageFragment on ChannelMessagePayload {
    ...MessageBaseFragment
    attachments {
      id
      filename
      url
      urls

      # loading
    }

    parent {
      cursor
      node {
        ...MessageBaseFragment
        author {
          ...CommonUserFragment
        }
      }
    }
    reactions {
      id
      unified
      activeSkinTone
      count
      reacted
      users {
        edges {
          cursor
          node {
            ...CommonUserFragment
          }
        }
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
    author {
      ...CommonUserFragment
    }
  }
  ${CommonUserFragment}
  ${MessageBaseFragment}
  ${PageInfoFragment}
`;

export const ChannelMessageConnectionPayloadFragment = gql`
  fragment ChannelMessageConnectionPayloadFragment on ChannelMessageConnectionPayload {
    totalCount
    lastReadAt
    newMessagesCount
    latestCursor
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      count
    }
    edges {
      cursor
      node {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;
export const ChannelMessageEdgePayloadFragment = gql`
  fragment ChannelMessageEdgePayloadFragment on ChannelMessageEdgePayload {
    cursor
    node {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;
