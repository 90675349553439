import * as React from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';

import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  MentionAdminNode,
  MentionChannelNode,
  MentionUserNode,
} from '@mainApp/src/components/editor';

import { SerializedEditorState, SerializedLexicalNode } from 'lexical';

import { MessageEditorNodeEventPlugin } from './MessageEditorNodeEventPlugin';
import { editorParserTheme } from './editor-parser-theme.js';

const editorConfig = {
  theme: editorParserTheme,
  editable: false,
  onError(error: Error) {
    throw error;
  },
  namespace: 'MessageEditorParser',
  nodes: [MentionUserNode, MentionChannelNode, MentionAdminNode],
};

type Props = {
  editorState: SerializedEditorState<SerializedLexicalNode>;
};

export default function MessageEditorParser(props: Props) {
  const { editorState } = props;
  return (
    <div className="flex">
      <LexicalComposer initialConfig={editorConfig as any}>
        <ParsedEditorContainer editorState={editorState} />
      </LexicalComposer>
    </div>
  );
}

function ParsedEditorContainer(props: Props) {
  const { editorState } = props;
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    // TODO: check if the current state of editer is the same like prev
    const parsedEditorState = editor.parseEditorState(editorState);
    editor.setEditorState(parsedEditorState);
  }, [editorState]);
  return (
    <div>
      <div className="editor-readonly">
        <PlainTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={null}
          contentEditable={<ContentEditable />}
        />
        <MessageEditorNodeEventPlugin />
      </div>
    </div>
  );
}
