import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';

type ActionsProps = {
  onNextClick?: (stepNumber: number) => void;
};

export const _Actions = (props: ActionsProps) => {
  const { onNextClick } = props;
  const { t } = useTranslation(['common']);
  const { createCommunityStepperStore, interactiveStore } =
    useMultipleInjection([
      IOC_TOKENS.createCommunityStepperStore,
      IOC_TOKENS.interactiveStore,
    ]);

  const currentStepStore = createCommunityStepperStore?.activeStepStore;
  const canMoveNext = currentStepStore?.canMoveNext;

  return (
    <div className="flex pt-[20px]">
      <button
        className="hover-el-opacity themed-text radius-[2px] border border-[element-normal] px-[24px] py-[9px] text-body16R"
        onClick={() => {
          // close modal/btmsht and reset the onboarding
          interactiveStore.setInteractiveElementOpen(false);
          createCommunityStepperStore.reset();
        }}
      >
        {t('cancel')}
      </button>
      <button
        disabled={!canMoveNext}
        className={classNames(
          'hover-el-opacity ml-[16px] flex-1 bg-primary-100 px-[24px] py-[9px] text-body16R text-text-primary-dark md:min-w-[160px]',
          !canMoveNext && 'disabled'
        )}
        onClick={() => {
          const nextStepNumber = currentStepStore.id + 1;
          createCommunityStepperStore.setActiveStepStoreById(nextStepNumber);
          onNextClick?.(nextStepNumber);
        }}
      >
        {t('next')}
      </button>
    </div>
  );
};

export const Actions = observer(_Actions);
