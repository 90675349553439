import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { LoadingStatusEnum } from '../types';
import { CommunityStepperModel } from './CommunityStepper.model';

import { StepBase } from './StepBase';

import { CommunityImageType } from './CreateCommunityStepper.types';
import { IStepBase } from './StepBase';

enableStaticRendering(typeof window === 'undefined');

export class Step1Store extends StepBase implements IStep1Store {
  id = 1;

  cropperZoomVal = 0;

  get thumbnail() {
    return this.communityStepperModel.thumbnail;
  }

  get canMoveNext() {
    return this.communityStepperModel.thumbnail.status === LoadingStatusEnum.success;
  }

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    super(repository, communityStepperModel);
    makeObservable(this, {
      cropperZoomVal: observable,
      setCropperZoomVal: action,
      setThumbnail: action,
      reset: action,
      canMoveNext: computed,
      thumbnail: computed,
    });
  }

  setCropperZoomVal = (cropperZoomVal: any) => {
    this.cropperZoomVal = cropperZoomVal;
  };

  setThumbnail = (file: File, originalFileUrl?: string) => {
    if (!file) return;
    // reset the back error if exists
    this.resetMoveBackError();

    const url = URL.createObjectURL(file);

    this.communityStepperModel.setImage('thumbnail', {
      ...this.thumbnail,
      file: file,
      fileName: file.name ? file.name : this.thumbnail.fileName,
      fileSize: (file.size * 0.000001).toFixed(2) + ' MB',
      fileUrl: originalFileUrl ? originalFileUrl : this.thumbnail.fileUrl,
      croppedFileUrl: url,
      status: LoadingStatusEnum.initial,
    });
  };

  triggerUploading = async () => {
    this.communityStepperModel.thumbnail = {
      ...this.thumbnail,
      uploadingPercent: 0,
      status: LoadingStatusEnum.uploading,
    };
    try {
      await this.repository.uploadThumbnail(
        this.thumbnail.file as File,
        (uploadPercent: number, uploadedImgUrlId: string) => {
          if (uploadPercent !== 100) {
            this.communityStepperModel.setImage('thumbnail', {
              ...this.thumbnail,
              uploadingPercent: uploadPercent,
            });
          } else {
            this.communityStepperModel.setImage('thumbnail', {
              ...this.thumbnail,
              id: uploadedImgUrlId,
              status: LoadingStatusEnum.success,
            });
          }
        }
      );
    } catch (e: any) {
      this.communityStepperModel.setImage('thumbnail', {
        ...this.thumbnail,
        error: e.message,
        status: LoadingStatusEnum.error,
      });
    }
  };

  reset = () => {
    this.communityStepperModel.resetImage('thumbnail');
    this.setCropperZoomVal(0);
  };
}

export interface IStep1Store extends IStepBase {
  readonly thumbnail: CommunityImageType;
  // Useful when the user switch tabs from mobile to preview and moves back
  // so this val can be used to restore the previous zoom position
  // TODO: probably rework tabs to just hide the content, not destroy it?
  readonly cropperZoomVal: number;
  // set's a thumbnail based on the passed file
  setThumbnail: (file: File, originalFileUrl?: string) => void;
  setCropperZoomVal: (zoomVal: number) => void;
  // uploads an image to the BE
  triggerUploading: () => void;
}
