import { gql } from '@urql/core';

export const GENERATE_COMMUNITY_THUMBNAIL_IMAGE_PRESIGNED_POST_URL = gql`
  mutation GenerateCommunityThumbnailImagePreSignedPostUrl($id: String) {
    generateCommunityThumbnailImagePreSignedPostUrl(id: $id) {
      id
      url
      fields {
        key
        value
      }
    }
  }
`;
export const GENERATE_COMMUNITY_LOGO_IMAGE_PRESIGNED_POST_URL = gql`
  mutation GenerateCommunityLogoImagePreSignedPostUrl($id: String) {
    generateCommunityLogoImagePreSignedPostUrl(id: $id) {
      id
      url
      fields {
        key
        value
      }
    }
  }
`;




export const GET_COMMUNITY_THUMBNAIL_IMAGE = gql`
  query CommunityThumbnailImage($id: String!) {
    communityThumbnailImage(id: $id) {
      filename
      imageUrls
    }
  }
`;

export const GET_COMMUNITY_LOGO_IMAGE = gql`
  query CommunityLogoImage($id: String!) {
    communityLogoImage(id: $id) {
      filename
      imageUrls
    }
  }
`;

export const GET_USER_POST_ONBOARDING_DATA = gql`
    query UserOnboarding {
        userOnboarding {
          id
          status
          steps {
            completedAt
            status
            step
            skipped
            userId
          }
        }
  }
`;

export const COMPLETE_USER_ONBOARDING_STEP = gql`
  mutation CompleteUserOnboardingStep($data: UserOnboardingDataInput! ) {
	completeUserOnboardingStep(data: $data) {
		id
		status
		steps {
			completedAt,
			step,
			skipped,
			status,
			userId,
		}
	}
}

`;

export const COMPLETE_USER_ONBOARDING_ALL_STEPS = gql`
    mutation CompleteUserOnboardingAllSteps {
      completeUserOnboardingAllSteps {
        id
        status
        steps {
          completedAt,
          step,
          skipped,
          status,
          userId,
        }
      }
    }
`;

/**
 * for dev purposes only
 * https://www.notion.so/10xteam/User-Onboarding-490853560bf14682b9ec1a3e5e15a92f
 */
export const CREATE_USER_ONBOARDING = gql`
    mutation CreateUserOnboarding {
      createUserOnboarding {
        id
        status
        steps {
          completedAt,
          step,
          skipped,
          status,
          userId,
        }
      }
    }

`;



