import {
  CircleArrowUpButton,
  TextButtonPrimary,
  ToastTypesEnum,
} from '@10x/foundation/src/components';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import {
  EnterPressPlugin,
  MentionAdminPlugin,
  MentionsChannelPlugin,
  MentionsUserPlugin,
} from '@mainApp/src/components/editor';
import { $getRoot, $getSelection, CLEAR_EDITOR_COMMAND } from 'lexical';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  IOC_TOKENS,
  useInjection,
  useMultipleInjection,
} from '@mainApp/src/ioc';

import { RolesEnum } from '@10x/foundation/types';
import { classNames } from '@foundationPathAlias/utilities';
import { MessagesEventsEnum } from '@mainApp/src/events';
import { useCallback, useEffect } from 'react';
import { DropupTypeEnum, MentionQueryData } from '../common/types';
import { MessageReactionButton } from '../message/MessageReactionButton';
import { messageEditorStore } from './MessageEditor.store';
import { MessageEditorPlaceholder } from './MessageEditorPlaceholder';

type SubmitHandler = (editorState: string, stateInJson: object) => void;

function submitHandler(editorState: any, editor: any, onSubmit: SubmitHandler) {
  if (editor) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const stateInJson = editorState.toJSON();

      const editorStateTextString = $getRoot().getTextContent();

      onSubmit?.(editorStateTextString, stateInJson);
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, editor);
    });
  }
}

export type Props = {
  onMentionQueryChange: (data: MentionQueryData) => void;
  onSubmit: (text: string, stateInJson: object) => void;
};

export function _MessageEditorComposerContent({
  onSubmit,
  onMentionQueryChange,
}: Props) {
  const { communityStore, messageStore, toastStore, eventBus } =
    useMultipleInjection([
      IOC_TOKENS.communityStore,
      IOC_TOKENS.messageStore,
      IOC_TOKENS.toastStore,
      IOC_TOKENS.eventBus,
    ]);
  const attachmentMediator = useInjection(IOC_TOKENS.attachmentsMediator);
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation('common');
  const handleEditModeOff = useCallback(() => {
    if (!editor) return;
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }, [editor]);

  useEffect(() => {
    eventBus.on(MessagesEventsEnum.EDIT_MODE_OFF, handleEditModeOff);
    return () => {
      eventBus.removeListener(
        MessagesEventsEnum.EDIT_MODE_OFF,
        handleEditModeOff
      );
    };
  }, []);

  const { active: editMode, optimistic: editModeOptimisticRun } =
    messageStore.editData;

  const lexicalState = messageStore.activeMessageModel?.serverData.rawJson;

  // when the user optimistically updates msg, should set empty message model to clear the editor area. In this case the editor shouldn't parse predefined state
  if (editMode && !editModeOptimisticRun) {
    if (!lexicalState) {
      toastStore.show({
        type: ToastTypesEnum.ERROR,
        title: 'No lexical state',
        content: 'The message does not contain the rawJson property',
      });
    } else {
      const editModelState = editor.parseEditorState(lexicalState);

      editor.setEditorState(editModelState);
    }
  }

  const handleSubmit = () => {
    if (messageStore.activeMessageModel?.failed) {
      throw new Error('cant send');
    }

    const editorState = editor.getEditorState();
    if (editor && editorState) {
      submitHandler(editorState, editor, onSubmit);
    }
  };

  return (
    <div className=" flex min-h-full flex-1 items-center">
      <div className="editor-container relative flex-1 text-body16R dark:bg-transparent">
        <PlainTextPlugin
          ErrorBoundary={(error) => {
            return <span>Error: {error.children}</span>;
          }}
          contentEditable={
            <ContentEditable className="editor-input text-body16R" />
          }
          placeholder={<MessageEditorPlaceholder />}
        />

        <MentionsUserPlugin
          type={communityStore.roles.data?.Member?.name as RolesEnum}
          // TODO: maybe use only this one? I definitely need it
          // and onMentionParse works only on success
          onMentionCheck={(isFoundMention) => {
            const { show: isShown, type } = messageEditorStore.dropupData;

            if (isFoundMention && !isShown) {
              messageEditorStore.setShowDropupData({
                show: true,
                type: DropupTypeEnum.SUGGESTION_USERS,
              });
            } else if (
              type === DropupTypeEnum.SUGGESTION_USERS &&
              isShown &&
              !isFoundMention
            ) {
              messageEditorStore.hideDropup();
            }
          }}
          onMentionParse={(selectOptionAndCleanUp) => {
            messageEditorStore.setSelectOptionAndCleanUp(
              selectOptionAndCleanUp
            );
          }}
          onQueryChange={(query) => {
            const { show: isShown, type } = messageEditorStore.dropupData;
            if (isShown && type === DropupTypeEnum.SUGGESTION_USERS) {
              onMentionQueryChange({
                type: DropupTypeEnum.SUGGESTION_USERS,
                query,
              });

              // TODO: Experiment
              messageEditorStore.setShowDropupData({
                ...messageEditorStore.dropupData,
                searchCriteria: query,
              });
            }
          }}
        />
        <MentionsChannelPlugin
          onMentionCheck={(isFoundMention) => {
            const { show: isShown, type } = messageEditorStore.dropupData;

            if (isFoundMention && !isShown) {
              messageEditorStore.setShowDropupData({
                show: true,
                type: DropupTypeEnum.SUGGESTION_CHANNELS,
              });
            } else if (
              type === DropupTypeEnum.SUGGESTION_CHANNELS &&
              isShown &&
              !isFoundMention
            ) {
              messageEditorStore.hideDropup();
            }
          }}
          onMentionParse={(selectOptionAndCleanUp) => {
            messageEditorStore.setSelectOptionAndCleanUp(
              selectOptionAndCleanUp
            );
          }}
          onQueryChange={(query) => {
            const { show: isShown, type } = messageEditorStore.dropupData;
            if (isShown && type === DropupTypeEnum.SUGGESTION_CHANNELS) {
              onMentionQueryChange({
                type: DropupTypeEnum.SUGGESTION_CHANNELS,
                query,
              });

              // TODO: Experiment
              messageEditorStore.setShowDropupData({
                ...messageEditorStore.dropupData,
                searchCriteria: query,
              });
            }
          }}
        />
        <OnChangePlugin
          onChange={(_editorState, editor) => {
            const text = editor.getRootElement()?.textContent;

            if (!text) return;

            // close suggestions on editor clear
            if (!text.length && messageEditorStore.dropupData.show) {
              messageEditorStore.setShowDropupData({
                show: false,
                type: DropupTypeEnum.EMPTY,
              });
            }
          }}
        />

        <EnterPressPlugin
          onEnter={() => {
            const editorState = editor.getEditorState();
            if (editor && editorState) {
              submitHandler(editorState, editor, onSubmit);
            }
          }}
        />
        <ClearEditorPlugin />
        <MentionAdminPlugin
          type={communityStore.roles.data?.Admin?.name as RolesEnum}
        />
        <HistoryPlugin />
      </div>
      <div className="mt-[4px] flex items-center">
        <MessageReactionButton
          closeReactionsOnOneReaction
          useSmileIcon
          offset={{ x: -260, y: 10 }}
          position={['top center']}
          getEmoji={(emoji) => {
            editor.update(() => {
              const selection = $getSelection();
              if (selection) {
                selection.insertText(emoji.emoji);
              }
            });
          }}
        />

        {editMode ? (
          <TextButtonPrimary cn="ml-[20px] px-0" onClick={handleSubmit}>
            {t('save')}
          </TextButtonPrimary>
        ) : (
          <CircleArrowUpButton
            cn={classNames('max-w-[20px] max-h-[20px]  ml-[20px]')}
            disabled={
              messageStore.activeMessageModel?.failed ||
              attachmentMediator.isOptimisticQueueRunning
            }
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

export const MessageEditorComposerContent = observer(
  _MessageEditorComposerContent
);
