import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

const _Roles = () => {
  const {
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.capStore, IOC_TOKENS.dimensionsStore]);

  return (
    <ScreenWrapper
      title="Roles"
      sidebarModalController={capStore}
      showBackBtn={isMobile}
    >
      <div className="h-full w-full flex-1 ">
        <h1>Roles</h1>
      </div>
    </ScreenWrapper>
  );
};

export const Roles = withAnimatedStack(_Roles);
