'use client';
import { HashTagLockSVG } from '@foundationPathAlias/components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { FormFieldInput } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FooterModal } from './FooterModal';
import { UsersList } from './UsersList';

const _AddMembers = () => {
  const { t } = useTranslation(['common', 'channel']);

  const {
    dimensionsStore: { isMobile },
    createChannelStore: {
      channelName,
      isGroupMode,
      isSubmitting,
      addedMembersAndRolesList,
      search,
      filteredMembers,
      filteredRoles,
      setSearch,
      onViewRoleMembers,
      removeRoleOrMember,
      addRoleOrMember,
      submitCreateChannel,
      submitCreateChannelGroup,
      setStepScreen,
    },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.createChannelStore,
    IOC_TOKENS.communityStore,
  ]);

  let submitAction = submitCreateChannel;
  let successText = t<string>('channel:modal.createChannel');

  if (isGroupMode) {
    submitAction = submitCreateChannelGroup;
    successText = t<string>('channel:modal.createChannelGroup');
  }

  const handleSubmit = async () => {
    await submitAction();
  };

  const disabledSubmitButton = isSubmitting || !addedMembersAndRolesList.length;

  return (
    <>
      <div className="relative flex flex-col md:flex-[1]">
        <div className="px-4 py-6 text-start md:p-6">
          <div className="flex items-baseline">
            <p className="mb-0 text-body16R text-text-secondary dark:text-text-secondary-dark">
              {t('channel:modal.addPeopleTo')}
            </p>

            <HashTagLockSVG
              className="ml-2 mr-1 text-text-secondary dark:text-text-secondary-dark"
              height={14}
              width={14}
            />

            <b className="text-body16SB text-text-secondary dark:text-text-secondary-dark">
              {channelName.value}
            </b>
          </div>
          <FormFieldInput
            inputName="search"
            cnWrapper="mt-3 mb-6"
            onChange={setSearch}
            value={search}
            placeholder={t<string>('channel:modal.findMembersAndRoles')}
            leftSideContent={<MagnifyingGlassIcon width={20} height={20} />}
          />

          <div className="flex h-[calc(100vh-300px)] flex-[1] flex-col overflow-y-auto scrollbar-thin md:h-[350px]">
            <UsersList
              list={addedMembersAndRolesList}
              onAction={removeRoleOrMember}
              onClickRow={(row) => onViewRoleMembers(row.id)}
              actionType="remove"
            />
            {addedMembersAndRolesList.length > 0 && (
              <hr className="mb-4 text-element-subtle dark:text-element-subtle-dark" />
            )}

            {!filteredRoles.length && !filteredMembers.length && (
              <b className="self-center text-body16SB text-text-primary dark:text-text-primary-dark">
                {t('channel:modal.noRolesOrMembersFound')}
              </b>
            )}

            <UsersList
              title={t<string>('channel:modal.roles')}
              list={filteredRoles}
              onAction={addRoleOrMember}
              onClickRow={(row) => onViewRoleMembers(row.id)}
              actionType="add"
            />

            <UsersList
              title={t<string>('channel:modal.members')}
              list={filteredMembers}
              onAction={addRoleOrMember}
              actionType="add"
              onScrollEnd={communityStore.fetchMoreMembers}
              infinityScroll
            />
          </div>
        </div>
      </div>
      <FooterModal
        onCancel={() => setStepScreen('create')}
        cancelText={t<string>('common:back')}
        onSuccess={handleSubmit}
        disabledOkButton={disabledSubmitButton}
        successText={successText}
        hideCancelButton={isMobile}
        className="absolute bottom-0 mt-auto w-full md:relative md:mt-0"
      />
    </>
  );
};

export const AddMembers = observer(_AddMembers);
