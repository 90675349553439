import {
  BackButton,
  OutlineButton,
  PrimaryButton,
} from '@foundationPathAlias/main';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type AddRolesTooltipWrapperProps = {
  onBack: () => void;
  onCancel: () => void;
  onSave: () => void;
  children: ReactNode;
};

export const AddRolesTooltipWrapper = ({
  onBack,
  children,
  onCancel,
  onSave,
}: AddRolesTooltipWrapperProps) => {
  const { t } = useTranslation(['common', 'cap']);

  return (
    <div>
      <header className="relative flex h-[56px] items-center justify-center border-b border-b-element-subtle dark:border-b-element-subtle-dark">
        <BackButton className="absolute left-[16px]" onClick={onBack} />

        <span className="themed-text text-body16SB">
          {t('cap:users.table.columns.roles')}
        </span>
      </header>

      <div className="min-h-[288px] py-[16px]">{children}</div>

      <div className="flex gap-[16px] border-t border-t-element-subtle p-[16px] dark:border-t-element-subtle-dark">
        <OutlineButton cn="w-auto" onClick={onCancel}>
          {t('common:cancel')}
        </OutlineButton>

        <PrimaryButton onClick={onSave}>{t('common:save')}</PrimaryButton>
      </div>
    </div>
  );
};
