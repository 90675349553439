import { ContentReactionPayload } from '@10x/foundation/types';
import { classNames } from '@foundationPathAlias/utilities';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { animated, useSpring } from '@react-spring/web';
import React, { useRef, useState } from 'react';
import { ReactionAndCount } from './ReactionAndCount';

type Props = {
  reactions: ContentReactionPayload[];
  selectedReaction: ContentReactionPayload;
  selectedReactionIndex: number;
  handleReaction: (item: ContentReactionPayload, index: number) => void;
};

export function _ReactionAndCountBar(props: Props) {
  const { handleReaction, selectedReaction, selectedReactionIndex, reactions } =
    props;

  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showArrow, setShowArrow] = useState({
    left: false,
    right: reactions.length > 5,
  });
  const { x } = useSpring({
    from: { x: 0 },
    to: { x: selectedReactionIndex * 65 - scrollPosition },
    config: { tension: 130, friction: 20 },
  });

  // Modify the handleScroll function
  const handleScroll = (right: boolean) => {
    if (scrollRef.current) {
      const node = scrollRef.current;
      const delta = right ? 65 : -65;
      const newScrollPosition = node.scrollLeft + delta;
      node.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
      setScrollPosition(newScrollPosition);
      updateArrowVisibility(node);
    }
  };

  const handleManualScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const node = event.currentTarget;
    setScrollPosition(node.scrollLeft);
    updateArrowVisibility(node);
  };

  const updateArrowVisibility = (node: HTMLElement) => {
    setShowArrow({
      left: node.scrollLeft > 0,
      right: node.scrollLeft + node.clientWidth < node.scrollWidth,
    });
  };

  return (
    <div className="relative">
      <div
        id="peopleReactions"
        ref={scrollRef}
        onScroll={handleManualScroll}
        className="scrollbar-hide mx-[16px] mb-[16px] mt-[10px] flex h-[44px] flex-row items-center overflow-hidden whitespace-nowrap rounded-[10px] bg-background-secondary px-[8px] dark:bg-background-secondary-dark md:mx-[24px] md:mb-[8px] md:mt-[16px] "
      >
        {reactions.map((item, index) => (
          <>
            <animated.div
              id={'animatedDiv'}
              style={{ left: x.to((x) => `${x}px`) }}
              className={classNames(
                'absolute z-1 ml-[32px] flex h-[36px] w-[63px] items-center justify-center rounded-lg ',
                selectedReaction.unified === item.unified
                  ? 'bg-background-primary dark:bg-background-tetriary-dark'
                  : 'bg-transparent'
              )}
            />
            <div
              id="reactionItem"
              key={index}
              onClick={() => handleReaction(item, index)}
              className="relative z-2 mr-[2px] flex h-9 w-[63px] flex-none cursor-pointer items-center justify-center"
            >
              <ReactionAndCount
                emojiUnified={item.unified}
                count={item.count}
              />
            </div>
          </>
        ))}
      </div>
      {showArrow.left && (
        <div
          onClick={() => handleScroll(false)}
          className="absolute left-[16px] top-1/2 z-10 flex h-[22px] w-[22px] -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-[20px] bg-background-primary shadow-light-shadow dark:bg-background-tetriary-dark"
        >
          <ChevronLeftIcon className="h-[16px] w-[16px] text-text-primary dark:text-text-primary-dark " />
        </div>
      )}
      {showArrow.right && (
        <div
          onClick={() => handleScroll(true)}
          className="absolute right-[16px] top-1/2 z-10 flex h-[22px] w-[22px] -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-[20px] bg-background-primary shadow-light-shadow dark:bg-background-tetriary-dark"
        >
          <ChevronRightIcon className="h-[16px] w-[16px] text-text-primary dark:text-text-primary-dark " />
        </div>
      )}
    </div>
  );
}

export const ReactionAndCountBar = _ReactionAndCountBar;
