import { TextButtonPrimary } from '@foundationPathAlias/components/buttons';
import { ProgressBarCircular } from '@foundationPathAlias/components/progress/progress-bar-circular';
import { convertBytesToKb } from '@foundationPathAlias/utilities';
import { colorSchema } from '@rootConfig/color-schema';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export type ImagePickerPropTypes = {
  progressPercentage: number;
  /** file size is in KB */
  maxFileSize: number;
  getImage?: (image: ImagePickerImageType | null) => void;
};
export type ImagePickerImageType = {
  image: string | null;
  name: string;
  size: number;
};

export const ImagePicker = (props: ImagePickerPropTypes) => {
  const { progressPercentage, maxFileSize, getImage } = props;
  const [image, setImage] = useState<ImagePickerImageType | null>();

  const handleCancelChange = (e: React.MouseEvent) => {
    e.stopPropagation();
    setImage(null);
    getImage?.(null);
    if (progressPercentage === 100) {
      open();
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = {
          image: reader.result as string,
          name: file.name,
          size: convertBytesToKb(file.size), // Converting size to KB
        };
        setImage(img);
        getImage?.(img);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      getImage?.(null);
    }
  }, []);

  const { getRootProps, open, fileRejections } = useDropzone({
    onDrop,
    maxSize: maxFileSize * 1024,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
  });

  // Check if any files were rejected due to the file size limit
  fileRejections.forEach(({ file, errors }: any) => {
    errors.forEach((e: any) => {
      if (e.code === 'file-too-large') {
        console.log(
          `${file.name} was too large. Maximum file size is ${maxFileSize} bytes..`
        );
      }
    });
  });
  const showProgressBar = progressPercentage > 0 && progressPercentage < 100;
  return (
    <div
      {...getRootProps()}
      className="mt-[16px] flex h-[74px] w-full cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-element-subtle py-[16px] dark:border-element-subtle-dark"
    >
      {image ? (
        <div className="flex flex w-full justify-between self-start overflow-hidden px-4">
          <div className="flex min-w-0 overflow-hidden">
            {showProgressBar ? (
              <ProgressBarCircular
                cn="w-[38px] h-[38px]"
                progressPercentage={progressPercentage}
                pathStroke={colorSchema['color-12']}
                trailStroke={colorSchema['color-13']}
              />
            ) : (
              <img
                src={image?.image || ''}
                alt="Uploaded"
                className="h-[44px] w-[44px] rounded-lg object-cover"
              />
            )}
            <div
              className="mx-[12px] truncate"
              style={{ alignItems: 'center' }}
            >
              <div className="truncate text-sm14T text-text-primary dark:text-text-primary-dark">
                {image.name}
              </div>
              <div className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
                {image.size} KB
              </div>
            </div>
          </div>
          <div className="flex flex-shrink flex-grow-0">
            <TextButtonPrimary
              cn="text-sm14T text-primary-100 hover:text-primary-hover"
              onClick={handleCancelChange}
            >
              {progressPercentage === 100 ? 'Change' : 'Cancel'}
            </TextButtonPrimary>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center ">
          <p className="mb-2 text-sm14R text-text-secondary dark:text-text-secondary-dark">
            <span className="text-sm14R text-color-12 dark:text-color-12-dark">
              Click to upload
            </span>{' '}
            or drag and drop
          </p>
          <p className="text-exstraSm12R text-text-secondary dark:text-text-secondary-dark">
            Max file size {maxFileSize}KB
          </p>
        </div>
      )}
    </div>
  );
};
