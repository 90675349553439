// TODO type;
// @ts-nocheck
import { TextNode } from 'lexical';

// import { IOC_TOKENS, iocContainer } from '@mainApp/src/ioc';

function convertElement(domNode) {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMentionUserNode(textContent);
    return {
      node,
    };
  }

  return null;
}

const TYPE = 'mentionUser';
// const nodeStyle = 'background-color: rgba(24, 119, 232, 0.2)';
// const nodeStyleIfMentioningMe = 'background: #ED9F091A';
export class MentionUserNode extends TextNode {
  __name;
  __data;
  __displayName;

  static getType() {
    return TYPE;
  }

  static clone(node) {
    return new MentionUserNode(
      node.__data,
      node.__name,
      node.__text,
      node.__key
    );
  }
  static importJSON(serializedNode) {
    const node = $createMentionUserNode(
      serializedNode.data,
      serializedNode.name
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  get id() {
    let res = this.__data.id;
    if (!res || res === 'undefined') {
      res = this.__data._id;
    }

    return this.__data.id || this.__data._id;
  }

  constructor(data, name, text?, key?) {
    super(text ?? name, key);
    this.__data = data;
    this.__name = name;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      name: this.__name,
      data: this.__data,
      type: TYPE,
      version: 1,
    };
  }

  createDOM(config) {
    // const userStore = iocContainer.get<IUserStore>(IOC_TOKENS.userStore);

    // let cssText = nodeStyle;
    let cn = 'mention-user mention';

    // if (this.id === userStore.me?.serverData.id) {
    if (this.id === 'test_id') {
      // cssText = nodeStyleIfMentioningMe;
      cn = `${cn} mention-user-me`;
    }

    const dom = super.createDOM(config);
    // dom.style.cssText = cssText;
    dom.className = cn;
    dom.dataset.id = this.id;
    return dom;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention-user', 'true');
    element.setAttribute('data-mention-user-id', this.id);
    element.textContent = this.__text;
    return { element };
  }

  isSegmented() {
    return false;
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-mention-user')) {
          return null;
        }
        return {
          conversion: convertElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }

  isToken() {
    return true;
  }
}

export function $createMentionUserNode(data, name) {
  const node = new MentionUserNode(data, name);
  node.setMode('segmented').toggleDirectionless();
  return node;
}

export function $isMentionUserNode(node) {
  return node instanceof MentionUserNode;
}
