import { classNames } from '@foundationPathAlias/utilities';
import { Switch } from '@headlessui/react';

type ToggleProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
};

export const Toggle = ({
  checked,
  onChange,
  className,
  disabled,
}: ToggleProps) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        'relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75',
        disabled && 'opacity-40',
        checked
          ? 'bg-primary-100'
          : 'bg-element-subtle dark:bg-element-subtle-dark',
        !checked &&
          disabled &&
          'bg-element-disabled dark:bg-element-disabled-dark',
        className
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          'pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-background-primary shadow-lg ring-0',
          'transition duration-200 ease-in-out',
          checked ? 'translate-x-5' : 'translate-x-0'
        )}
      />
    </Switch>
  );
};
