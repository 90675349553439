import { IEventBus } from '@foundationPathAlias/utilities';
import { injectable } from 'inversify';
import { RetryEventsEnum } from './events';

@injectable()
export class RetryService {
  maxRetryAttmeptsCount = 3;
  counter = 0;

  // @ts-ignore
  private _eventBus: IEventBus<RetryEventsEnum>;

  get eventBus() {
    if (!this._eventBus) {
      throw new Error('No event bus');
    }

    return this._eventBus;
  }

  set eventBus(val: IEventBus<RetryEventsEnum>) {
    this._eventBus = val;
  }

  setMaxRetryAttemptsCount = (count: number) => {
    if (typeof count !== 'number') {
      throw new Error('Count must be a number');
    }

    this.maxRetryAttmeptsCount = count;
  };

  start = <T>(data?: T) => {
    if (this.counter >= this.maxRetryAttmeptsCount) {
      this.eventBus.emit(RetryEventsEnum.RETRY_MAX_ATTEMPT_REACHED, {
        attemptCount: this.counter,
        maxRetryAttmeptsCount: this.maxRetryAttmeptsCount,
        data,
      });

      this.counter = 0;

      return;
    }

    this.eventBus.emit(RetryEventsEnum.RETRY_START, {
      attemptCount: this.counter,
      maxRetryAttmeptsCount: this.maxRetryAttmeptsCount,
      data,
    });

    this.counter++;
  };

  reset = () => {
    this.counter = 0;
  };
}

export type RetryEventData<T = void> = {
  attemptCount: number;
  maxRetryAttmeptsCount: number;
  data: T;
};
