'use client';
import { PrimaryButton } from '@foundationPathAlias/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationProps = {
  onDeleteConfirm: () => void;
  onUndo: () => void;
  /**
   * Time in seconds
   */
  initialCount?: number;
};

export const DeleteConfirmation = ({
  onDeleteConfirm,
  onUndo,
  initialCount = 5,
}: DeleteConfirmationProps) => {
  const [count, setCount] = React.useState(initialCount);
  const { t } = useTranslation(['cap']);

  React.useEffect(() => {
    if (count <= 0) {
      onDeleteConfirm();
      return;
    }

    const timerId = setTimeout(() => setCount(count - 1), 1000);

    return () => clearTimeout(timerId);
  }, [count, onDeleteConfirm]);

  const strokeDashoffset = React.useMemo(() => {
    const totalLength = 282.6;
    return ((initialCount - count) / initialCount) * totalLength;
  }, [count, initialCount]);

  return (
    <>
      {count > 0 && (
        <PrimaryButton
          cn="primary-button bg-background-primary-dark dark:bg-element-subtle-dark w-auto font-normal px-20 h-8 flex justify-center items-center "
          onClick={onUndo}
          aria-label={`Button tap to undo with timeout initializing with ${initialCount} seconds`}
        >
          <svg className="mr-2" width="18" height="18" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="45"
              stroke="white"
              strokeWidth="14"
              opacity={0.3}
              fillOpacity={0.9}
              fill="none"
            />
            <circle
              cx="50"
              cy="50"
              r="45"
              stroke="white"
              strokeWidth="14"
              fill="none"
              strokeDasharray="282.6"
              strokeDashoffset={282.6 - strokeDashoffset}
            />
          </svg>
          {t('cap:rules.ruleDeleted')} <b>{t('cap:rules.tapToUndo')}</b>
        </PrimaryButton>
      )}
    </>
  );
};
