'use client';
import { observer } from 'mobx-react-lite';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { RichViewer } from '@foundationPathAlias/main';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { IRulesStoreLocal } from './data/Rules.store.local';

const _ChildRule = () => {
  const { capStore } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.platformStore,
  ]);

  const rulesStoreLocal =
    capStore.activeChildScreen as unknown as IRulesStoreLocal;

  const { childRule } = rulesStoreLocal || {};

  return (
    <ScreenWrapper
      title={childRule?.title ?? ''}
      sidebarModalController={capStore}
      showBackBtn
    >
      {!!childRule?.rawJSON && (
        <div>
          <RichViewer predefinedEditorStateJsonString={childRule.rawJSON} />
        </div>
      )}
    </ScreenWrapper>
  );
};

export const ChildRule = withAnimatedStack(observer(_ChildRule));
