import { PrimaryButton, TextInput } from '@foundationPathAlias/components';
import { useTranslation } from 'react-i18next';
import { HeaderModal } from './HeaderModal';

type EditUserFormFieldMobileProps = {
  title: string;
  label: string;
  onBack: () => void;
  onSubmit: () => void;
  onChange: (value: string) => void;
  defaultValue: string;
  textAreaMode?: boolean;
  isSubmitting?: boolean;
};

export const EditUserFormFieldMobile = ({
  defaultValue,
  label,
  onBack,
  onChange,
  onSubmit,
  title,
  textAreaMode,
  isSubmitting,
}: EditUserFormFieldMobileProps) => {
  const { t } = useTranslation(['channel'], { keyPrefix: 'editProfileModal' });

  return (
    <div className="flex h-screen w-full flex-col">
      <HeaderModal label={title} onCancel={onBack} buttonType="arrow" />

      <div className="flex flex-col gap-6 px-4 py-6">
        <label className="text-text-primary dark:text-text-primary-dark">
          {label}
        </label>

        <TextInput
          onChange={(value) => onChange(value)}
          InputProps={
            textAreaMode
              ? undefined
              : {
                  defaultValue,
                }
          }
          TextAreaProps={textAreaMode ? { defaultValue, rows: 4 } : undefined}
          textAreaMode={textAreaMode}
        />
      </div>

      <div className="mt-auto border-t border-t-element-subtle px-4 py-6 dark:border-t-element-subtle-dark">
        <PrimaryButton disabled={isSubmitting} onClick={onSubmit}>
          {t('saveChanges')}
        </PrimaryButton>
      </div>
    </div>
  );
};
