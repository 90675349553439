export const SunSVG: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 18, height = 17, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="themed-svg"
    {...otherProps}
  >
    <path d="M8.99987 4.06105C6.54828 4.06105 4.56091 6.04842 4.56091 8.50001C4.56091 10.9516 6.54828 12.939 8.99987 12.939C11.4515 12.939 13.4388 10.9516 13.4388 8.50001C13.436 6.04961 11.4503 4.06384 8.99987 4.06105ZM8.99987 12.2581C6.92434 12.2581 5.24176 10.5755 5.24176 8.50001C5.24176 6.42448 6.92434 4.7419 8.99987 4.7419C11.0754 4.7419 12.758 6.42448 12.758 8.50001C12.7556 10.5746 11.0745 12.2557 8.99987 12.2581Z" />
    <path d="M9.34042 2.5766V0.840426C9.34042 0.652394 9.18803 0.5 9 0.5C8.81196 0.5 8.65957 0.652394 8.65957 0.840426V2.5766C8.65957 2.76463 8.81196 2.91702 9 2.91702C9.18803 2.91702 9.34042 2.76463 9.34042 2.5766Z" />
    <path d="M9 14.0829C8.81196 14.0829 8.65957 14.2353 8.65957 14.4233V16.1595C8.65957 16.3476 8.81196 16.4999 9 16.4999C9.18803 16.4999 9.34042 16.3476 9.34042 16.1595V14.4233C9.34042 14.2353 9.18803 14.0829 9 14.0829Z" />
    <path d="M16.6596 8.1596H14.9234C14.7354 8.1596 14.583 8.31199 14.583 8.50002C14.583 8.68806 14.7354 8.84045 14.9234 8.84045H16.6596C16.8476 8.84045 17 8.68806 17 8.50002C17 8.31199 16.8476 8.1596 16.6596 8.1596Z" />
    <path d="M3.41699 8.50002C3.41699 8.31199 3.2646 8.1596 3.07657 8.1596H1.3404C1.15236 8.1596 0.999971 8.31199 0.999971 8.50002C0.999971 8.68806 1.15236 8.84045 1.3404 8.84045H3.07657C3.2646 8.84045 3.41699 8.68806 3.41699 8.50002Z" />
    <path d="M13.4342 4.54704L14.6418 3.33946C14.7738 3.20635 14.7733 2.99159 14.6407 2.85914C14.5082 2.72656 14.2935 2.72603 14.1604 2.85808L12.9528 4.06566C12.8662 4.15143 12.8321 4.2771 12.8634 4.39505C12.895 4.51287 12.987 4.60489 13.1048 4.6364C13.2227 4.66779 13.3484 4.63361 13.4342 4.54704Z" />
    <path d="M4.56575 12.4529L3.35817 13.6605C3.27147 13.7464 3.23743 13.8721 3.26881 13.9899C3.30019 14.1077 3.39235 14.1999 3.51017 14.2313C3.62799 14.2626 3.75365 14.2286 3.83956 14.1419L5.04713 12.9343C5.1337 12.8485 5.16788 12.7229 5.1365 12.6049C5.10498 12.4871 5.01296 12.3951 4.89514 12.3636C4.77719 12.3322 4.65152 12.3664 4.56575 12.4529Z" />
    <path d="M13.4342 12.4529C13.3484 12.3664 13.2227 12.3322 13.1048 12.3636C12.987 12.3951 12.895 12.4871 12.8634 12.6049C12.8321 12.7229 12.8662 12.8485 12.9528 12.9343L14.1604 14.1419C14.2935 14.2739 14.5082 14.2734 14.6407 14.1408C14.7733 14.0084 14.7738 13.7936 14.6418 13.6605L13.4342 12.4529Z" />
    <path d="M4.56563 4.54704C4.6514 4.63361 4.77707 4.66779 4.89502 4.6364C5.01284 4.60489 5.10486 4.51287 5.13638 4.39505C5.16776 4.2771 5.13358 4.15143 5.04701 4.06566L3.83943 2.85808C3.70632 2.72603 3.49156 2.72656 3.35911 2.85914C3.22653 2.99159 3.226 3.20635 3.35805 3.33946L4.56563 4.54704Z" />
    <path d="M8.99987 4.06105C6.54828 4.06105 4.56091 6.04842 4.56091 8.50001C4.56091 10.9516 6.54828 12.939 8.99987 12.939C11.4515 12.939 13.4388 10.9516 13.4388 8.50001C13.436 6.04961 11.4503 4.06384 8.99987 4.06105ZM8.99987 12.2581C6.92434 12.2581 5.24176 10.5755 5.24176 8.50001C5.24176 6.42448 6.92434 4.7419 8.99987 4.7419C11.0754 4.7419 12.758 6.42448 12.758 8.50001C12.7556 10.5746 11.0745 12.2557 8.99987 12.2581Z" />
    <path d="M9.34042 2.5766V0.840426C9.34042 0.652394 9.18803 0.5 9 0.5C8.81196 0.5 8.65957 0.652394 8.65957 0.840426V2.5766C8.65957 2.76463 8.81196 2.91702 9 2.91702C9.18803 2.91702 9.34042 2.76463 9.34042 2.5766Z" />
    <path d="M9 14.0829C8.81196 14.0829 8.65957 14.2353 8.65957 14.4233V16.1595C8.65957 16.3476 8.81196 16.4999 9 16.4999C9.18803 16.4999 9.34042 16.3476 9.34042 16.1595V14.4233C9.34042 14.2353 9.18803 14.0829 9 14.0829Z" />
    <path d="M16.6596 8.1596H14.9234C14.7354 8.1596 14.583 8.31199 14.583 8.50002C14.583 8.68806 14.7354 8.84045 14.9234 8.84045H16.6596C16.8476 8.84045 17 8.68806 17 8.50002C17 8.31199 16.8476 8.1596 16.6596 8.1596Z" />
    <path d="M3.41699 8.50002C3.41699 8.31199 3.2646 8.1596 3.07657 8.1596H1.3404C1.15236 8.1596 0.999971 8.31199 0.999971 8.50002C0.999971 8.68806 1.15236 8.84045 1.3404 8.84045H3.07657C3.2646 8.84045 3.41699 8.68806 3.41699 8.50002Z" />
    <path d="M13.4342 4.54704L14.6418 3.33946C14.7738 3.20635 14.7733 2.99159 14.6407 2.85914C14.5082 2.72656 14.2935 2.72603 14.1604 2.85808L12.9528 4.06566C12.8662 4.15143 12.8321 4.2771 12.8634 4.39505C12.895 4.51287 12.987 4.60489 13.1048 4.6364C13.2227 4.66779 13.3484 4.63361 13.4342 4.54704Z" />
    <path d="M4.56575 12.4529L3.35817 13.6605C3.27147 13.7464 3.23743 13.8721 3.26881 13.9899C3.30019 14.1077 3.39235 14.1999 3.51017 14.2313C3.62799 14.2626 3.75365 14.2286 3.83956 14.1419L5.04713 12.9343C5.1337 12.8485 5.16788 12.7229 5.1365 12.6049C5.10498 12.4871 5.01296 12.3951 4.89514 12.3636C4.77719 12.3322 4.65152 12.3664 4.56575 12.4529Z" />
    <path d="M13.4342 12.4529C13.3484 12.3664 13.2227 12.3322 13.1048 12.3636C12.987 12.3951 12.895 12.4871 12.8634 12.6049C12.8321 12.7229 12.8662 12.8485 12.9528 12.9343L14.1604 14.1419C14.2935 14.2739 14.5082 14.2734 14.6407 14.1408C14.7733 14.0084 14.7738 13.7936 14.6418 13.6605L13.4342 12.4529Z" />
    <path d="M4.56563 4.54704C4.6514 4.63361 4.77707 4.66779 4.89502 4.6364C5.01284 4.60489 5.10486 4.51287 5.13638 4.39505C5.16776 4.2771 5.13358 4.15143 5.04701 4.06566L3.83943 2.85808C3.70632 2.72603 3.49156 2.72656 3.35911 2.85914C3.22653 2.99159 3.226 3.20635 3.35805 3.33946L4.56563 4.54704Z" />
  </svg>
);
