import { HashTagMessageSVG } from '@foundationPathAlias/components/svg';
import {
  ArrowUturnLeftIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';

import { observer } from 'mobx-react-lite';

// import { MessagesEventsEnum } from '@mainApp/src/events';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';

import { useTranslation } from 'react-i18next';
import { deleteMessageModalStoreLocal } from '../delete-message-modal';
import { MessageMenuButton } from './MessageMenuButton';
import { MessageMenuMore } from './MessageMenuMore';
import { MessageReactionButton } from './MessageReactionButton';
import { ReactionsBar } from './ReactionsBar';

type Props = {
  messageModel: MessageModel;
  onClose: () => void;
  handleReactionClick?: () => void;
};

function _MessageMenu(props: Props) {
  const { onClose, messageModel, handleReactionClick } = props;

  const {
    messageStore,
    userStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.messageStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation('common');

  const isMyMessage =
    userStore.me?.serverData.id === messageModel.serverData.authorId;

  return (
    <div>
      {isMobile && (
        <ReactionsBar
          handleReactions={handleReactionClick}
          messageModel={messageModel}
        />
      )}
      <ul
        className={
          isMobile
            ? 'flex flex-col p-[24px]'
            : 'absolute right-[21px] top-[-24px] z-1 flex space-x-[4px] rounded-[5px] border-[1px] border-element-subtle bg-background-primary p-[4px] dark:border-element-subtle-dark dark:bg-background-tetriary-dark'
        }
      >
        {!isMobile && (
          <MessageReactionButton
            offset={{ x: -82, y: 8 }}
            messageModel={messageModel}
            closeMessageMenu={onClose}
          />
        )}
        <MessageMenuButton
          label={t('reply')}
          Icon={ArrowUturnLeftIcon}
          onClick={() => {
            messageStore.setReplyModel(messageModel);
            onClose();
          }}
        />
        {isMyMessage && (
          <MessageMenuButton
            label={t('edit')}
            Icon={PencilIcon}
            onClick={() => {
              messageStore.editMessage(messageModel);
              onClose();
            }}
          />
        )}

        <MessageMenuButton
          label={t('createLink')}
          Icon={HashTagMessageSVG}
          onClick={() => {
            onClose();
          }}
        />

        {isMyMessage && (
          <MessageMenuButton
            label={t('delete')}
            Icon={TrashIcon}
            onClick={() => {
              deleteMessageModalStoreLocal.show(messageModel);
              onClose();
            }}
          />
        )}

        {!isMobile && (
          <MessageMenuMore messageModel={messageModel} onClose={onClose} />
        )}
      </ul>
    </div>
  );
}

export const MessageMenu = observer(_MessageMenu);
