'use client';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { CommunitySettingsPreview } from './CommunitySettingsPreview';

import 'react-tabs/style/react-tabs.css';

type CustomizeFormWrapperProps = {
  children: React.ReactNode;
};

export const _CustomizeFormWrapper = ({
  children,
}: CustomizeFormWrapperProps) => {
  const {
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore]);

  const { t } = useTranslation(['common', 'cap']);

  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <>
      {isMobile ? (
        <Tabs
          selectedIndex={tabIndex}
          selectedTabClassName="themed-layout"
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="themed-text-secondary flex h-[56px] w-full shrink-0">
            <Tab className="flex w-full cursor-pointer items-center justify-center bg-element-subtle px-[24px] py-[12px]">
              {t('cap:customize.tabs.settings')}
            </Tab>

            <Tab className="flex w-full cursor-pointer items-center justify-center bg-element-subtle px-[24px] py-[12px]">
              {t('cap:customize.tabs.preview')}
            </Tab>
          </TabList>

          <div className="flex flex-col overflow-auto">
            <TabPanel className="">{children}</TabPanel>

            <TabPanel>
              <div className="pt-12">
                <CommunitySettingsPreview />
              </div>
            </TabPanel>
          </div>
        </Tabs>
      ) : (
        <div className="flex w-full">
          {children}

          <CommunitySettingsPreview />
        </div>
      )}
    </>
  );
};

export const CustomizeFormWrapper = observer(_CustomizeFormWrapper);
