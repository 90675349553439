import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { OutlineButton } from '@foundationPathAlias/main';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { UsersList } from '@mainApp/src/modules/channel/create-channel-modal/UsersList';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionsStore } from '../data/Permissions.store.local';
export function _MembersView() {
  const {
    dimensionsStore: { isMobile },
    channelSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.channelSettingsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const activeStore =
    channelSettingsStore.activeChildScreen as PermissionsStore;

  useEffect(() => {
    if (!activeStore) return;
    activeStore.setChannelModel(channelSettingsStore.channelModel);
  }, [channelSettingsStore.channelModel]);

  const selectedRole = activeStore?.selectedRole;

  if (!selectedRole) return null;

  return (
    <ScreenWrapper
      title={t('channel:modal.roleMembers', {
        roleName: activeStore.selectedRole?.name,
      })}
      sidebarModalController={channelSettingsStore}
      showBackBtn={true}
    >
      <div className="flex h-full w-full flex-1 flex-col justify-between">
        <UsersList
          maxHeight={isMobile ? 180 : 420}
          list={selectedRole?.parsedRoleMembers ?? []}
          onScrollEnd={() => {
            selectedRole?.loadMoreMembers();
          }}
          infinityScroll
          loading={selectedRole?.roleMembers.loading}
          actionType="none"
        />
        <div className="absolute -bottom-[24px] left-0 hidden h-[88px]  w-full items-center justify-end border-t-[1px] border-t-element-subtle px-[24px] dark:border-t-element-subtle-dark md:flex">
          <OutlineButton
            cn="w-auto"
            onClick={() => {
              activeStore.back();
            }}
          >
            {t('common:back')}
          </OutlineButton>
        </div>
      </div>
    </ScreenWrapper>
  );
}

export const MembersView = withAnimatedStack(observer(_MembersView));
