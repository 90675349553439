import { XMarkIcon } from '@heroicons/react/20/solid';

import { ButtonBase } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
type Props = {
  onCloseBtnClick: () => void;
  dedicatedPageMode?: boolean;
  cn?: string;
  btnCn?: string;
  iconCn?: string;
};

export function ModalClosePanel(props: Props) {
  const { dedicatedPageMode, onCloseBtnClick, cn, btnCn, iconCn } = props;
  return (
    <div
      className={classNames(
        'relative mb-[24px] hidden h-[10px] items-center justify-center border-b-[1px] border-element-normal-dark dark:border-element-normal md:flex md:border-none',
        !dedicatedPageMode ? 'px-[24px]' : '',
        cn
      )}
    >
      {!dedicatedPageMode ? (
        <ButtonBase
          className={classNames(
            'hover-el-opacity absolute -top-[8px] left-[16px] h-[22px] w-[22px] outline-none md:left-auto md:right-[20px]',
            btnCn
          )}
          onClick={onCloseBtnClick}
        >
          <XMarkIcon
            className={classNames('themed-svg h-[24px] w-[24px]', iconCn)}
          />
        </ButtonBase>
      ) : null}
    </div>
  );
}
