import {
  SmallLoader,
  useMinLoading,
} from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';

export type LoaderContentWrapperPropsType = {
  loading: boolean;
  minLoadingTimeMs?: number;
  /** the button classnames */
  cn?: string;
  /** the element that wraps a content inside the button */
  contentWrapperCn?: string;
  /** next element after the wrapper */
  contentCn?: string;
  /** aligns the content and loder at the center */
  alignerCn?: string;
  loaderWrapperCn?: string;
  loaderCn?: string;
  children: React.ReactNode;
};

export function LoaderContentWrapper(props: LoaderContentWrapperPropsType) {
  const {
    loading,
    children,
    minLoadingTimeMs = 500,
    contentWrapperCn,
    contentCn,
    alignerCn,
    loaderWrapperCn,
    loaderCn,
  } = props;

  const finalLoading = useMinLoading(loading, minLoadingTimeMs);

  return (
    <>
      <div className={classNames('relative flex ', contentWrapperCn)}>
        <div
          className={classNames(
            'flex flex-1 items-center justify-center',
            contentCn
          )}
        >
          <span
            className={classNames(
              'relative  flex  items-center px-[4px]',
              alignerCn
            )}
          >
            {finalLoading && (
              <span
                className={classNames(
                  'absolute -left-[16px] flex',
                  loaderWrapperCn
                )}
              >
                <SmallLoader
                  cn={classNames('w-[14px] h-[14px] text-gray-200', loaderCn)}
                />
              </span>
            )}
            {children}
          </span>
        </div>
      </div>
    </>
  );
}
