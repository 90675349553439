import { ButtonBase } from '@foundationPathAlias/components/buttons/ButtonBase';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
export type Props = {
  label: string;
  value?: string | JSX.Element;
  onClick: () => void;
};
export function ScreenToggleItem(props: Props) {
  const { label, value, onClick } = props;
  return (
    <div className="-mx-[16px] ">
      <ButtonBase
        cn="flex w-full px-[16px] py-[8px] items-center justify-between mt-[12px] mb-[12px] hover:bg-hovered-selected dark:hover:bg-hovered-selected-dark active:bg-pressed dark:active:bg-pressed-dark"
        onClick={onClick}
      >
        <p className="flex flex-col justify-center space-y-[8px]">
          <span className="themed-text text-left text-body16R">{label}</span>
          {value && (
            <span className="themed-text-secondary text-left text-body16R">
              {value}
            </span>
          )}
        </p>
        <p>
          <ChevronRightIcon className="themed-text-secondary ml-[16px] h-[28px] w-[28px]" />
        </p>
      </ButtonBase>
    </div>
  );
}
