import React from 'react';

export const OrderUpDown: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 20, height = 20, ...otherProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M10 4.19995L13 8.99995H7L10 4.19995Z" fill="currentColor" />
      <path d="M10 15.8L13 11H7L10 15.8Z" fill="currentColor" />
    </svg>
  );
};
