// just try to use own solution instead eventemitter3 etc. dep which is the same (almost)
import { createPubSub } from '@foundationPathAlias/utilities';
import { GlobalEventsType } from './events';
const eventBus = createPubSub<GlobalEventsType>();

// Simple debug logger. Later should be used more serious solution
if (process.env.NODE_ENV === 'development') {
    eventBus.setEventLogger((event, data) => {
        console.info('%c > eventBus Event fired: ', 'background-color: #7b7aff6e; color: black, border-radius: 4px; padding: 2px', event, data ? ` with data: ${data}` : '');
    })
}

export { eventBus };

