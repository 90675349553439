import { FilterFn, rankItem } from '@foundationPathAlias/components/table';

export const formatDate = (date?: Date | null) => {
  if (!date) return '';

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return formatter.format(date);
};

// Example: https://tanstack.com/table/v8/docs/framework/react/examples/filters-fuzzy
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};
