import { PrimaryButton } from '@foundationPathAlias/components/buttons';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { paths } from '@mainApp/src/config/paths';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function _NavbarActions() {
  const router = useRouter();
  const { t } = useTranslation(['common', 'home']);

  const {
    authStore,
    interactiveStore,
    createCommunityStepperStore,
    systemStore,
    communityStore,
    dimensionsStore,
  } = useMultipleInjection([
    IOC_TOKENS.authStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const createdCommunitySlug = createCommunityStepperStore.createdCommunitySlug;

  // const [actionTextKey, setActionTextKey] = useState('navbar.createCommunity');

  const [authTextKey, setAuthTextKey] = useState('navbar.logIn');
  const [authTextKey2, setAuthTextKey2] = useState('navbar.signUp');
  const isLogged = authStore.logged;

  useEffect(() => {
    const finalKey = isLogged ? 'access.logout' : 'navbar.logIn';
    setAuthTextKey(finalKey);
    setAuthTextKey2(isLogged ? 'navbar.open10X' : 'navbar.signUp');
  }, [isLogged]);

  const isCommunityCreated = createCommunityStepperStore.isCommunityCreated;

  // useEffect(() => {
  //   const actionTextKey = isCommunityCreated
  //     ? 'navbar.open10X'
  //     : 'navbar.createCommunity';

  //   setActionTextKey(actionTextKey);
  // }, [isCommunityCreated]);

  return (
    <>
      {!isLogged && (
        <button
          aria-label={authTextKey}
          className="nav shrink-0"
          onClick={() => {
            if (isLogged) {
              authStore.logout();
              interactiveStore.resetContent();
              interactiveStore.setInteractiveElementOpen(false);
            } else {
              authStore.setLoginMode(true);
              interactiveStore.setActiveContentType(
                InteractiveContentTypesEnum.AUTH,
                true
              );
            }
          }}
        >
          {t(authTextKey)}
        </button>
      )}
      <div>
        <PrimaryButton
          aria-label={authTextKey2}
          cn="rounded-[24px]"
          onClick={() => {
            if (isLogged) {
              // when saved model exists, should just redirect
              if (isCommunityCreated) {
                router.push(`${paths.home}/${createdCommunitySlug}`);
              } else {
                if (dimensionsStore.isMobile && systemStore.isMobileNavOpened) {
                  systemStore.setMobileNavOpen(false);
                }
                createCommunityStepperStore.setModal(true);
              }
            } else {
              authStore.setLoginMode(false);
              interactiveStore.setActiveContentType(
                InteractiveContentTypesEnum.AUTH,
                true
              );
            }
          }}
        >
          {communityStore.creatingCommunity ? (
            <>
              <SmallLoader />
              {t('creating')}...
            </>
          ) : (
            t(authTextKey2)
          )}
        </PrimaryButton>
      </div>
    </>
  );
}

export const NavbarActions = observer(_NavbarActions);
