import axios from 'axios';
import {
  ADD_COMMUNITY_ROLES_TO_MEMBER,
  CREATE_COMMUNITY,
  CREATE_COMMUNITY_RULE,
  DELETE_COMMUNITY_RULE,
  GENERATE_COMMUNITY_LOGO_IMAGE_PRESIGNED_POST_URL,
  GENERATE_COMMUNITY_THUMBNAIL_IMAGE_PRESIGNED_POST_URL,
  GET_COMMUNITY,
  GET_COMMUNITY_BLOCKED_MEMBERS,
  GET_COMMUNITY_BY_SLUG_NAME,
  GET_COMMUNITY_LOGO_IMAGE,
  GET_COMMUNITY_MEMBERS,
  GET_COMMUNITY_MEMBERS_COUNT,
  GET_COMMUNITY_ONLINE_MEMBERS,
  GET_COMMUNITY_ONLINE_MEMBERS_COUNT,
  GET_COMMUNITY_ROLES,
  GET_COMMUNITY_ROLE_MEMBERS,
  GET_COMMUNITY_RULES,
  GET_COMMUNITY_THUMBNAIL_IMAGE,
  GET_FEATURED_COMMUNITIES,
  GET_USER_COMMUNITIES,
  JOIN_COMMUNITY,
  LEAVE_COMMUNITY,
  REMOVE_COMMUNITY_ROLES_TO_MEMBER,
  UPDATE_COMMUNITY_PROFILE,
  UPDATE_COMMUNITY_RULE,
  UPDATE_COMMUNITY_RULES_SORT,
} from '../graphql/queries';
import {
  CommunitiesSubscriber,
  CommunitySubscriber,
  FEATURED_COMMUNITIES_SETTING_SORT_TYPE,
  FetchOptions,
  GetCommunityMembersParamsType,
  GetCommunityRoleMembersParamsType,
  ICommunityRepository,
  UploadImageParams,
} from './Community.repository.types';
import { BaseRepositoryResponse, Client } from './types';

import {
  Community,
  CommunityRoleListOption,
  ContentRuleInput,
  ContentRuleListOption,
  ContentRulePayload,
  CreateCommunityInput,
  UpdateCommunityProfileDataInput,
} from '@10x/foundation/types/graphql-schema';

import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import { GraphQLSubscription } from './GraphQLSubscription';

export * from './Community.repository.types';

@injectable()
export class CommunityRepository implements ICommunityRepository {
  gqlClient;

  // TODO: injection
  graphQLSubscription;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
    // TODO: injection
    this.graphQLSubscription = new GraphQLSubscription();
  }

  // TODO: migrate to new Rule.repository
  createCommunityRule = async (
    communityId: string,
    dataInput: ContentRuleInput
  ): Promise<BaseRepositoryResponse<ContentRulePayload>> => {
    const response = await this.gqlClient
      .mutation(CREATE_COMMUNITY_RULE, {
        communityId,
        data: dataInput,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.createCommunityRule,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  // TODO: migrate to new Rule.repository
  editCommunityRule = async (
    communityId: string,
    ruleId: string,
    dataInput: ContentRuleInput
  ): Promise<BaseRepositoryResponse<ContentRulePayload>> => {
    const response = await this.gqlClient
      .mutation(UPDATE_COMMUNITY_RULE, {
        communityId,
        id: ruleId,
        data: dataInput,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.createCommunityRule,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  // TODO: migrate to new Rule.repository
  deleteCommunityRule = async (
    communityId: string,
    ruleId: string
  ): Promise<BaseRepositoryResponse<ContentRulePayload>> => {
    const response = await this.gqlClient
      .mutation(DELETE_COMMUNITY_RULE, {
        communityId,
        id: ruleId,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.createCommunityRule,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  // TODO: migrate to new Rule.repository
  updateCommunityRulesOrder = async (
    communityId: string,
    rulesId: string[]
  ): Promise<BaseRepositoryResponse<ContentRulePayload[]>> => {
    const response = await this.gqlClient
      .mutation(UPDATE_COMMUNITY_RULES_SORT, {
        communityId,
        rulesId,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.updateCommunityRulesSort,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  // TODO: migrate to new Rule.repository
  async getCommunityRules(
    communityId: string,
    option?: ContentRuleListOption
  ): Promise<BaseRepositoryResponse<ContentRulePayload[]>> {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_RULES, { communityId, option })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.communityRules,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  // TODO. urql auto types
  createCommunity = async (
    payload: CreateCommunityInput
  ): Promise<BaseRepositoryResponse<Partial<Community>>> => {
    const response = await this.gqlClient
      .mutation(CREATE_COMMUNITY, { data: payload })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.createCommunity,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  updateCommunityProfile = async (
    communityId: string,
    payload: UpdateCommunityProfileDataInput
  ): Promise<BaseRepositoryResponse<Partial<Community>>> => {
    const response = await this.gqlClient
      .mutation(UPDATE_COMMUNITY_PROFILE, { communityId, data: payload })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.updateCommunityProfile,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  addCommunityRolesToMember = async (
    communityId: string,
    roleIds: string[],
    userId: string
  ) => {
    const response = await this.gqlClient
      .mutation(ADD_COMMUNITY_ROLES_TO_MEMBER, { communityId, roleIds, userId })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.addCommunityRoleMember,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  removeCommunityRolesToMember = async (
    communityId: string,
    roleIds: string[],
    userId: string
  ) => {
    const response = await this.gqlClient
      .mutation(REMOVE_COMMUNITY_ROLES_TO_MEMBER, {
        communityId,
        roleIds,
        userId,
      })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.removeCommunityRoleMember,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  getFeaturedCommunities = async (
    limit = 2,
    sortType: FEATURED_COMMUNITIES_SETTING_SORT_TYPE
  ) => {
    const response = await this.gqlClient
      .query(GET_FEATURED_COMMUNITIES, { limit, sortType })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.featuredCommunities,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  getCommunityRoles = async (
    communityId: string,
    option: CommunityRoleListOption
  ) => {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_ROLES, { communityId, option })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.communityRoles,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  getCommunityRoleMembers = async (
    params: GetCommunityRoleMembersParamsType
  ) => {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_ROLE_MEMBERS, params)
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.communityRoleMembers?.edges,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };

    return res;
  };

  getCommunityMembers = async (
    params: GetCommunityMembersParamsType,
    options?: FetchOptions
  ) => {
    const response = await this.gqlClient
      .query(
        GET_COMMUNITY_MEMBERS,
        params,
        !options?.noCache
          ? undefined
          : {
              requestPolicy: 'network-only',
              fetchOptions: { cache: 'no-cache' },
            }
      )
      .toPromise();
    const { data, error } = response;

    const res = {
      data: data?.communityMembers?.edges,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };
    return res;
  };

  getCommunityBlockedMembers = async (
    params: GetCommunityMembersParamsType,
    options?: FetchOptions
  ) => {
    const response = await this.gqlClient
      .query(
        GET_COMMUNITY_BLOCKED_MEMBERS,
        params,
        !options?.noCache
          ? undefined
          : {
              requestPolicy: 'network-only',
              fetchOptions: { cache: 'no-cache' },
            }
      )
      .toPromise();
    const { data, error } = response;

    const res = {
      data: data?.communityBlockedMembers?.edges,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };
    return res;
  };

  subscribeToGetUserCommunities(
    ownerId: string,
    beforeSubscriptionCb: () => void,
    subscribeCb: CommunitiesSubscriber
  ) {
    const QUERY_NAME = 'GET_USER_COMMUNITIES';

    const variables = {
      ownerId: ownerId,
    };
    const subscriber = this.generateUserCommunitiesSubscriber(subscribeCb);

    const getNewQueryValidator = (prevQueryVariables: any) => {
      return prevQueryVariables.ownerId !== ownerId;
    };

    const payload = {
      QUERY_NAME,
      gqlQuery: GET_USER_COMMUNITIES,
      variables,
      getNewQueryValidator,
      beforeSubscriptionCb,
      subscriber,
    };

    this.graphQLSubscription.createSubscription(this.gqlClient, payload);
  }

  private generateUserCommunitiesSubscriber = (
    subscribeCb: CommunitiesSubscriber
  ) => {
    // TODO: generic type for response
    return (response: any) => {
      const { data, error } = response;

      const res = {
        data: data?.userCommunities.edges,
        error: error,
        pageInfo: data?.userCommunities.pageInfo,
        originalResponse: response,
      };
      subscribeCb(res);
    };
  };

  async getCommunity(
    communityId: string
  ): Promise<BaseRepositoryResponse<Partial<Community>>> {
    const response = await this.gqlClient
      .query(GET_COMMUNITY, {
        communityId: communityId,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data.community,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getCommunityBySlugName(
    slugName: string
  ): Promise<BaseRepositoryResponse<Partial<Community>>> {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_BY_SLUG_NAME, {
        slugName: slugName,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res: any = {
      data: data?.communityBySlugName,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  subscribeToGetCommunityBySlugName(
    slugName: string,
    beforeSubscriptionCb: () => void,
    subscribeCb: CommunitySubscriber
  ) {
    const QUERY_NAME = 'GET_COMMUNITY_BY_SLUG_NAME';
    const variables = {
      slugName: slugName,
    };

    const subscriber = this.generateCommunityBySlugNameSubscriber(subscribeCb);
    const getNewQueryValidator = (prevQueryVariables: any) => {
      return prevQueryVariables.slugName !== slugName;
    };

    const payload = {
      QUERY_NAME,
      gqlQuery: GET_COMMUNITY_BY_SLUG_NAME,
      variables,
      beforeSubscriptionCb,
      getNewQueryValidator,
      subscriber,
    };

    this.graphQLSubscription.createSubscription(this.gqlClient, payload);
  }

  private generateCommunityBySlugNameSubscriber = (
    subscribeCb: CommunitySubscriber
  ) => {
    // TODO: generic type for response
    return (response: any) => {
      const { data, error } = response;

      const res = {
        data: data?.communityBySlugName,
        error: error,
        pageInfo: null,
        originalResponse: response,
      };
      subscribeCb(res);
    };
  };

  async joinCommunity(
    communityId: string,
    slugName: string
  ): Promise<BaseRepositoryResponse<Partial<Community>>> {
    const response = await this.gqlClient
      .mutation(JOIN_COMMUNITY, {
        communityId: communityId,
        extra: {
          slugName,
        },
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    // TODO: check type
    const res: any = {
      data: data?.joinCommunity,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async leaveCommunity(
    communityId: string,
    slugName: string
  ): Promise<BaseRepositoryResponse<Partial<Community>>> {
    const response = await this.gqlClient
      .mutation(LEAVE_COMMUNITY, {
        communityId: communityId,
        extra: {
          slugName,
        },
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    // check type
    const res: any = {
      data: data?.leaveCommunity,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getCommunityMembersCount(communityId: string): Promise<number> {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_MEMBERS_COUNT, {
        communityId: communityId,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res: any = {
      data: data.communityMemberCounts,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getCommunityOnlineMembersCount(communityId: string): Promise<number> {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_ONLINE_MEMBERS_COUNT, {
        communityId: communityId,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res: any = {
      data: data.communityOnlineMemberCounts,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getCommunityOnlineMembers(communityId: string) {
    const response = await this.gqlClient
      .query(GET_COMMUNITY_ONLINE_MEMBERS, {
        communityId: communityId,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data.communityOnlineMembers.edges,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async uploadImage({ callback, file, id, type }: UploadImageParams) {
    const response = await this.gqlClient
      .mutation(
        type === 'thumbnail'
          ? GENERATE_COMMUNITY_THUMBNAIL_IMAGE_PRESIGNED_POST_URL
          : GENERATE_COMMUNITY_LOGO_IMAGE_PRESIGNED_POST_URL,
        {
          id: id,
        }
      )
      .toPromise();

    const { data, error } = response;

    const responseDataKey =
      type === 'thumbnail'
        ? 'generateCommunityThumbnailImagePreSignedPostUrl'
        : 'generateCommunityLogoImagePreSignedPostUrl';

    const res = {
      data: data?.[responseDataKey],
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    const { url, id: urlId } = data?.[responseDataKey];
    const formData = new FormData();
    formData.append('file', file);

    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent: any) {
        if (!progressEvent) return;

        const { loaded, total = 1 } = progressEvent;

        const loadedPercent = Math.round((loaded / total) * 100);
        callback(loadedPercent, urlId);
      },
    });

    return (await res).data;
  }

  async getCommunityImage(type: 'thumbnail' | 'logo', id: string) {
    const query =
      type === 'thumbnail'
        ? GET_COMMUNITY_THUMBNAIL_IMAGE
        : GET_COMMUNITY_LOGO_IMAGE;
    const response = await this.gqlClient
      .query(query, {
        id: id,
      })
      .toPromise();

    const { data } = response;

    return data?.[
      type === 'thumbnail' ? 'communityThumbnailImage' : 'communityLogoImage'
    ]?.imageUrls;
  }
}
