import { classNames } from '@foundationPathAlias/utilities';
import { ModalClosePanel } from '@mainApp/src/components/common/MobalClosePanel';

export type ModalHeaderPropsType = {
  children: React.ReactNode;
  onClose: () => void;
  cn?: string;
  titleCn?: string;
};

export function ModalHeader(props: ModalHeaderPropsType) {
  const { children, onClose, cn, titleCn } = props;
  return (
    <div
      className={classNames(
        'flex h-[62px] items-center justify-between px-[24px]',
        cn
      )}
    >
      <h5
        className={classNames(
          'w-full text-center text-miniHeadD font-bold md:text-left',
          titleCn
        )}
      >
        {children}
      </h5>
      <ModalClosePanel
        cn="mb-0 px-0 flex border-none absolute w-[22px] h-[22px] left-auto md:right-[24px] right-auto"
        btnCn="md:right-0 top-0 left-0"
        iconCn="top-0"
        onCloseBtnClick={onClose}
      />
    </div>
  );
}
