import { useEffect, useRef, useState } from 'react';

import { useMinLoading } from './useMinLoading';

export function useMinLoadingMinStart(
  isOuterLoading: boolean,
  minStartTime = 150
) {
  // elapsed ms that allows to change inner loading state
  // start. If the outer loading state changes from true to false
  // faster than 300ms, so shouldn't do anything to prevent content jumping
  //   const minStartTime = 1000;
  const minStartTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  // to make the updated value available for the timeout callback
  const outerLoadingRef = useRef<null | boolean>(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    outerLoadingRef.current = isOuterLoading;

    if (!minStartTimeoutRef.current) {
      minStartTimeoutRef.current = setTimeout(() => {
        minStartTimeoutRef.current = null;

        // if the outer loading is still true we should change the inner loading state to true and being the next step processing
        if (outerLoadingRef.current) {
          setLoading(true);
        }
      }, minStartTime);
    }
  }, [isOuterLoading]);

  // inner loading is started but the outer loading is stopped so need to
  // update it in the useMinLoading hook
  const computedLoading =
    isOuterLoading === false && loading === true ? isOuterLoading : loading;

  const minLoading = useMinLoading(computedLoading);

  useEffect(() => {
    if (!minLoading && minLoading !== loading) {
      setLoading(minLoading);
    }
  }, [minLoading]);

  return loading;
}
