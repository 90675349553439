import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import {
  SquareIconButtonBaseMedium,
  SquareIconButtonBaseMediumPropsType,
} from './SquareIconButtonBaseMedium';

export const BackButton = ({
  cnIcon,
  ...props
}: SquareIconButtonBaseMediumPropsType) => {
  return <SquareIconButtonBaseMedium Icon={ArrowSmallLeftIcon} {...props} />;
};
