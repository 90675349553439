import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@foundationPathAlias/components';
import { ModalClosePanel } from '@mainApp/src/components/common/MobalClosePanel';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

type Props = {
  title: string;
  description: string;
};

export function _DeactivateOrDeleteRedirectContent(props: Props) {
  const { title, description } = props;
  const { t } = useTranslation('common');
  const interactiveStore = useInjection(IOC_TOKENS.interactiveStore);
  return (
    <div className="themed-layout max-w-[424px] py-[24px] ">
      <ModalClosePanel
        onCloseBtnClick={() => {
          interactiveStore.setInteractiveElementOpen(false);
        }}
      />
      <div className="px-[24px]">
        <h5 className="themed-text mb-[8px] text-miniHead">{title}</h5>
        <p className="themed-text-secondary mb-[32px] text-body16M leading-[24px]">
          {description}
        </p>
        <PrimaryButton
          onClick={() => {
            interactiveStore.setInteractiveElementOpen(false);
          }}
        >
          {t('done')}
        </PrimaryButton>
      </div>
    </div>
  );
}

export const DeactivateOrDeleteRedirectContent = observer(
  _DeactivateOrDeleteRedirectContent
);
