

import { createPubSub } from "@foundationPathAlias/utilities";

import { ACTIONS } from "./actionConstants";


export const AnimatedStackEvents = {
    [ACTIONS.RESET]: Symbol('RESET'),
    [ACTIONS.SET_PREV_ITEM]: Symbol('PREV_ITEM_SET'),
    [ACTIONS.SET_VISIBLE]: Symbol('VISIBLE_ITEM_SET'),
    [ACTIONS.NEXT]: Symbol('NEXT_ITEM_SET'),
    [ACTIONS.BACK]: Symbol('BACK_ACTION'),
    [ACTIONS.ON_ENTER_ANIMATION_END]: Symbol('ENTER_ANIMATION_END'),
    [ACTIONS.ON_LEAVING_ANIMATION_END]: Symbol('LEAVING_ANIMATION_END'),
};


export type AnimatedStackEventsType = typeof AnimatedStackEvents[keyof typeof AnimatedStackEvents];

export const animatedStackEventBus = createPubSub<AnimatedStackEventsType>();

export const emitEvent = <T>(event: AnimatedStackEventsType, payload?: T) => {
    animatedStackEventBus.emit(event, payload);
};
