import { withAnimatedStack } from '@foundationPathAlias/main';
import { extractPlainText } from '@mainApp/src/utils/lexical';
import { RuleItem } from '../components/RuleItem';
import { ScreenIds } from '../constants';
import { useScreenRules } from '../ScreenRules.context';

const _SubRulesScreen = () => {
  const { currentPlatformRule, handleGoToScreen, setCurrentRuleDetails } =
    useScreenRules();

  return (
    <div className="overflow-auto">
      {currentPlatformRule?.childrens &&
        currentPlatformRule.childrens.length > 0 && (
          <div className="px-[4px] py-[12px] md:px-[12px]">
            {currentPlatformRule?.childrens?.map((item) => (
              <RuleItem
                key={item?.id}
                title={item.title}
                description={extractPlainText(item.rawJSON) || ''}
                onClick={() => {
                  setCurrentRuleDetails({
                    id: item.id,
                    title: item.title,
                    rawJson: item.rawJSON,
                  });
                  handleGoToScreen(ScreenIds.RULE_DETAILS);
                }}
              />
            ))}
          </div>
        )}
    </div>
  );
};

export const SubRulesScreen = withAnimatedStack(_SubRulesScreen);
