export const DropZoneSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  isDarkTheme: boolean;
}> = ({ width = 144, height = 133, isDarkTheme, ...otherProps }) => {
  let MAIN_COLOR = '#EDEDED';
  let BORDER_COLOR = '#B4B4B4';
  let SECONDARY_COLOR = '#F2F2F2';
  let BG_COLOR = 'white';

  if (isDarkTheme) {
    MAIN_COLOR = 'rgba(255,255,255, 0.9)';
    BORDER_COLOR = '#8C8C8C';
    SECONDARY_COLOR = '#8C8C8C';
    BG_COLOR = '#242424';
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 144 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clip-path="url(#clip0_16342_89102)">
        <path
          d="M81.9415 0.314302L76.7354 1.6606L12.595 18.2473L7.38885 19.5936C4.86186 20.2502 2.69776 21.885 1.37132 24.1396C0.0448794 26.3942 -0.335567 29.0843 0.313443 31.6199L22.8883 119.405C23.543 121.939 25.1734 124.109 27.4216 125.439C29.6699 126.769 32.3526 127.151 34.8811 126.5L34.8945 126.496L109.42 107.224L109.434 107.22C111.961 106.564 114.125 104.929 115.451 102.674C116.778 100.42 117.158 97.7298 116.509 95.1942L93.9342 7.40951C93.2796 4.87546 91.6492 2.7053 89.4009 1.37515C87.1526 0.0450014 84.47 -0.336516 81.9415 0.314302Z"
          fill={BORDER_COLOR}
        />
        <path
          d="M82.6497 3.06575L76.4834 4.66035L14.2631 20.7505L8.09691 22.3451C6.29738 22.8126 4.75626 23.9769 3.81166 25.5824C2.86707 27.1879 2.59614 29.1036 3.05832 30.9092L25.6332 118.694C26.0994 120.499 27.2604 122.044 28.8615 122.991C30.4626 123.938 32.3729 124.21 34.1735 123.747L34.1869 123.743L108.713 104.471L108.726 104.467C110.526 104 112.067 102.835 113.011 101.23C113.956 99.6244 114.227 97.7087 113.765 95.9031L91.1899 8.11842C90.7236 6.31388 89.5627 4.76847 87.9616 3.82124C86.3606 2.87401 84.4502 2.60231 82.6497 3.06575Z"
          fill={BG_COLOR}
        />
        <path
          d="M77.992 31.4928L40.2912 41.2422C39.8702 41.3511 39.4232 41.2878 39.0487 41.0662C38.6741 40.8446 38.4027 40.4829 38.2941 40.0607C38.1855 39.6384 38.2487 39.1902 38.4696 38.8146C38.6906 38.4391 39.0513 38.1669 39.4724 38.058L77.1731 28.3086C77.5941 28.2001 78.0407 28.2637 78.4149 28.4853C78.7892 28.7069 79.0603 29.0685 79.1689 29.4905C79.2774 29.9125 79.2144 30.3604 78.9937 30.7359C78.773 31.1113 78.4128 31.3836 77.992 31.4928Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M85.924 35.1728L41.6741 46.6159C41.253 46.7248 40.806 46.6615 40.4315 46.4399C40.0569 46.2183 39.7855 45.8566 39.6769 45.4344C39.5684 45.0121 39.6315 44.5639 39.8525 44.1883C40.0734 43.8127 40.4341 43.5406 40.8552 43.4317L85.1051 31.9886C85.5262 31.8797 85.9732 31.943 86.3477 32.1646C86.7223 32.3862 86.9937 32.7479 87.1023 33.1702C87.2109 33.5924 87.1477 34.0406 86.9268 34.4162C86.7058 34.7918 86.3451 35.064 85.924 35.1728Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M84.1854 55.5742L46.4846 65.3236C46.2761 65.3775 46.0591 65.3898 45.8459 65.3596C45.6327 65.3294 45.4275 65.2573 45.242 65.1476C45.0566 65.0379 44.8945 64.8926 44.765 64.7201C44.6356 64.5476 44.5413 64.3512 44.4875 64.1421C44.4337 63.933 44.4215 63.7154 44.4517 63.5016C44.4818 63.2878 44.5536 63.082 44.663 62.896C44.7724 62.7101 44.9173 62.5475 45.0893 62.4177C45.2614 62.2879 45.4572 62.1933 45.6657 62.1394L83.3665 52.39C83.575 52.3361 83.7921 52.3239 84.0053 52.3541C84.2185 52.3842 84.4237 52.4563 84.6091 52.566C84.7946 52.6757 84.9566 52.821 85.0861 52.9935C85.2156 53.166 85.3099 53.3624 85.3636 53.5715C85.4174 53.7806 85.4296 53.9982 85.3995 54.212C85.3694 54.4258 85.2975 54.6316 85.1881 54.8176C85.0787 55.0035 84.9339 55.1661 84.7618 55.2959C84.5898 55.4257 84.3939 55.5203 84.1854 55.5742Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M92.1174 59.2539L47.8674 70.6969C47.6589 70.7509 47.4419 70.7631 47.2287 70.7329C47.0155 70.7027 46.8103 70.6307 46.6248 70.5209C46.4394 70.4112 46.2773 70.266 46.1478 70.0934C46.0184 69.9209 45.9241 69.7245 45.8703 69.5154C45.8165 69.3063 45.8044 69.0887 45.8345 68.8749C45.8646 68.6611 45.9364 68.4553 46.0458 68.2694C46.1552 68.0834 46.3001 67.9209 46.4721 67.791C46.6442 67.6612 46.84 67.5666 47.0485 67.5127L91.2985 56.0697C91.7196 55.9608 92.1666 56.0241 92.5411 56.2457C92.9156 56.4673 93.187 56.829 93.2956 57.2512C93.4042 57.6735 93.3411 58.1217 93.1201 58.4973C92.8991 58.8728 92.5384 59.145 92.1174 59.2539Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M90.3776 79.6543L52.6769 89.4037C52.2559 89.5123 51.8092 89.4488 51.4349 89.2271C51.0606 89.0055 50.7894 88.644 50.6809 88.2219C50.5723 87.7998 50.6353 87.3518 50.8561 86.9763C51.0768 86.6008 51.4372 86.3286 51.858 86.2195L89.5588 76.4701C89.9799 76.3612 90.4268 76.4245 90.8014 76.6461C91.1759 76.8676 91.4473 77.2293 91.5559 77.6516C91.6645 78.0739 91.6014 78.5221 91.3804 78.8976C91.1594 79.2732 90.7987 79.5454 90.3776 79.6543Z"
          fill={SECONDARY_COLOR}
        />
        <path
          d="M98.3102 83.334L54.0602 94.777C53.8517 94.8312 53.6345 94.8435 53.4212 94.8135C53.2078 94.7834 53.0025 94.7115 52.8169 94.6018C52.6313 94.4921 52.469 94.3468 52.3395 94.1742C52.2099 94.0016 52.1155 93.805 52.0617 93.5958C52.0079 93.3866 51.9958 93.1689 52.0259 92.955C52.0561 92.741 52.128 92.5352 52.2376 92.3492C52.3471 92.1631 52.4922 92.0006 52.6644 91.8708C52.8367 91.741 53.0327 91.6466 53.2414 91.5928L97.4913 80.1497C97.9124 80.0409 98.3594 80.1042 98.7339 80.3258C99.1084 80.5473 99.3798 80.909 99.4884 81.3313C99.597 81.7535 99.5339 82.2018 99.3129 82.5773C99.092 82.9529 98.7313 83.2251 98.3102 83.334Z"
          fill={SECONDARY_COLOR}
        />
        <path
          d="M33.8557 51.2393L20.5137 54.6895C20.3118 54.7415 20.0976 54.7111 19.9181 54.6048C19.7386 54.4986 19.6084 54.3253 19.5561 54.123L16.4935 42.2136C16.4416 42.0112 16.472 41.7964 16.5779 41.6163C16.6839 41.4363 16.8567 41.3058 17.0584 41.2533L30.4004 37.8031C30.6023 37.7511 30.8165 37.7816 30.996 37.8878C31.1755 37.994 31.3057 38.1673 31.358 38.3697L34.4206 50.279C34.4725 50.4815 34.4421 50.6963 34.3362 50.8763C34.2303 51.0564 34.0574 51.1869 33.8557 51.2393Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M40.049 75.3203L26.7071 78.7706C26.5052 78.8226 26.291 78.7921 26.1114 78.6859C25.9319 78.5797 25.8017 78.4064 25.7495 78.204L22.6868 66.2947C22.635 66.0922 22.6654 65.8774 22.7713 65.6974C22.8772 65.5174 23.05 65.3868 23.2518 65.3344L36.5937 61.8842C36.7956 61.8322 37.0098 61.8627 37.1894 61.9689C37.3689 62.0751 37.4991 62.2484 37.5514 62.4507L40.614 74.3601C40.6658 74.5625 40.6354 74.7773 40.5295 74.9574C40.4236 75.1374 40.2508 75.2679 40.049 75.3203Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M46.2404 99.4004L32.8985 102.851C32.6966 102.903 32.4824 102.872 32.3028 102.766C32.1233 102.66 31.9931 102.486 31.9409 102.284L28.8782 90.3748C28.8264 90.1723 28.8568 89.9575 28.9627 89.7775C29.0686 89.5974 29.2414 89.4669 29.4432 89.4145L42.7852 85.9642C42.9871 85.9123 43.2013 85.9427 43.3808 86.0489C43.5603 86.1552 43.6905 86.3285 43.7428 86.5308L46.8054 98.4402C46.8572 98.6426 46.8269 98.8574 46.7209 99.0375C46.615 99.2175 46.4422 99.348 46.2404 99.4004Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M133.937 22.5664H56.9456C54.3352 22.5694 51.8325 23.6105 49.9867 25.4615C48.1408 27.3126 47.1025 29.8222 47.0996 32.4399V123.097C47.1025 125.714 48.1408 128.224 49.9867 130.075C51.8325 131.926 54.3352 132.967 56.9456 132.97H133.937C136.548 132.967 139.05 131.926 140.896 130.075C142.742 128.224 143.78 125.714 143.783 123.097V32.4399C143.78 29.8222 142.742 27.3126 140.896 25.4615C139.05 23.6105 136.548 22.5694 133.937 22.5664Z"
          fill={BORDER_COLOR}
        />
        <path
          d="M133.939 25.4082H56.9471C55.0882 25.4103 53.306 26.1517 51.9915 27.4699C50.677 28.788 49.9376 30.5752 49.9355 32.4393V123.096C49.9376 124.96 50.677 126.747 51.9915 128.065C53.306 129.384 55.0882 130.125 56.9471 130.127H133.939C135.798 130.125 137.58 129.384 138.895 128.065C140.209 126.747 140.948 124.96 140.95 123.096V32.4393C140.948 30.5752 140.209 28.7881 138.895 27.4699C137.58 26.1518 135.798 25.4103 133.939 25.4082Z"
          fill={BG_COLOR}
        />
        <path
          d="M122.351 75.224H83.4168C83.2014 75.2242 82.988 75.1818 82.7888 75.0993C82.5897 75.0168 82.4087 74.8957 82.2563 74.743C82.1038 74.5903 81.9829 74.409 81.9004 74.2094C81.8179 74.0098 81.7754 73.7958 81.7754 73.5798C81.7754 73.3637 81.8179 73.1497 81.9004 72.9501C81.9829 72.7505 82.1038 72.5692 82.2563 72.4165C82.4087 72.2638 82.5897 72.1427 82.7888 72.0602C82.988 71.9777 83.2014 71.9353 83.4168 71.9355H122.351C122.786 71.936 123.202 72.1095 123.509 72.4178C123.816 72.7261 123.989 73.144 123.989 73.5798C123.989 74.0155 123.816 74.4334 123.509 74.7417C123.202 75.05 122.786 75.2235 122.351 75.224Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M129.114 80.7737H83.4168C83.2014 80.774 82.988 80.7316 82.7888 80.6491C82.5897 80.5666 82.4087 80.4455 82.2563 80.2928C82.1038 80.1401 81.9829 79.9588 81.9004 79.7592C81.8179 79.5596 81.7754 79.3456 81.7754 79.1295C81.7754 78.9135 81.8179 78.6995 81.9004 78.4999C81.9829 78.3003 82.1038 78.119 82.2563 77.9663C82.4087 77.8136 82.5897 77.6925 82.7888 77.61C82.988 77.5275 83.2014 77.4851 83.4168 77.4854H129.114C129.549 77.4854 129.966 77.6586 130.274 77.9669C130.581 78.2753 130.754 78.6935 130.754 79.1295C130.754 79.5656 130.581 79.9838 130.274 80.2922C129.966 80.6005 129.549 80.7737 129.114 80.7737Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M122.351 100.093H83.4168C83.2014 100.093 82.988 100.051 82.7888 99.9685C82.5897 99.8859 82.4087 99.7649 82.2563 99.6122C82.1038 99.4595 81.9829 99.2781 81.9004 99.0785C81.8179 98.8789 81.7754 98.665 81.7754 98.4489C81.7754 98.2328 81.8179 98.0189 81.9004 97.8193C81.9829 97.6197 82.1038 97.4383 82.2563 97.2856C82.4087 97.1329 82.5897 97.0118 82.7888 96.9293C82.988 96.8468 83.2014 96.8044 83.4168 96.8047H122.351C122.786 96.8047 123.203 96.9779 123.51 97.2863C123.818 97.5946 123.991 98.0128 123.991 98.4489C123.991 98.885 123.818 99.3032 123.51 99.6115C123.203 99.9199 122.786 100.093 122.351 100.093Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M129.115 105.642H83.4168C83.2014 105.642 82.988 105.6 82.7888 105.517C82.5897 105.435 82.4087 105.314 82.2563 105.161C82.1038 105.008 81.9829 104.827 81.9004 104.627C81.8179 104.428 81.7754 104.214 81.7754 103.998C81.7754 103.782 81.8179 103.568 81.9004 103.368C81.9829 103.168 82.1038 102.987 82.2563 102.834C82.4087 102.682 82.5897 102.561 82.7888 102.478C82.988 102.396 83.2014 102.353 83.4168 102.354H129.115C129.33 102.353 129.543 102.396 129.743 102.478C129.942 102.561 130.123 102.682 130.275 102.834C130.428 102.987 130.548 103.168 130.631 103.368C130.713 103.568 130.756 103.782 130.756 103.998C130.756 104.214 130.713 104.428 130.631 104.627C130.548 104.827 130.428 105.008 130.275 105.161C130.123 105.314 129.942 105.435 129.743 105.517C129.543 105.6 129.33 105.642 129.115 105.642Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M74.6955 83.2928H60.9171C60.7086 83.2925 60.5088 83.2094 60.3614 83.0616C60.214 82.9138 60.1311 82.7134 60.1309 82.5044V70.2054C60.1311 69.9964 60.214 69.796 60.3614 69.6482C60.5088 69.5004 60.7086 69.4172 60.9171 69.417H74.6955C74.9039 69.4172 75.1038 69.5004 75.2512 69.6482C75.3986 69.796 75.4815 69.9964 75.4817 70.2054V82.5044C75.4815 82.7134 75.3986 82.9138 75.2512 83.0616C75.1038 83.2094 74.9039 83.2925 74.6955 83.2928Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M74.6955 108.161H60.9171C60.7086 108.161 60.5088 108.078 60.3614 107.93C60.214 107.782 60.1311 107.582 60.1309 107.373V95.0736C60.1311 94.8645 60.214 94.6641 60.3614 94.5163C60.5088 94.3685 60.7086 94.2854 60.9171 94.2852H74.6955C74.9039 94.2854 75.1038 94.3685 75.2512 94.5163C75.3986 94.6641 75.4815 94.8645 75.4817 95.0736V107.373C75.4815 107.582 75.3986 107.782 75.2512 107.93C75.1038 108.078 74.9039 108.161 74.6955 108.161Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M122.393 47.5404H93.6884C93.2536 47.5404 92.8366 47.3671 92.5291 47.0588C92.2216 46.7504 92.0488 46.3322 92.0488 45.8962C92.0488 45.4601 92.2216 45.0419 92.5291 44.7335C92.8366 44.4252 93.2536 44.252 93.6884 44.252H122.393C122.828 44.252 123.245 44.4252 123.552 44.7335C123.86 45.0419 124.033 45.4601 124.033 45.8962C124.033 46.3322 123.86 46.7504 123.552 47.0588C123.245 47.3671 122.828 47.5404 122.393 47.5404Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M129.157 53.0902H93.6884C93.4731 53.0902 93.2599 53.0476 93.061 52.965C92.8621 52.8824 92.6813 52.7613 92.5291 52.6086C92.3768 52.4559 92.256 52.2746 92.1736 52.0752C92.0912 51.8757 92.0488 51.6619 92.0488 51.446C92.0488 51.23 92.0912 51.0162 92.1736 50.8167C92.256 50.6173 92.3768 50.436 92.5291 50.2833C92.6813 50.1307 92.8621 50.0095 93.061 49.9269C93.2599 49.8443 93.4731 49.8018 93.6884 49.8018H129.157C129.591 49.8018 130.008 49.975 130.316 50.2833C130.623 50.5917 130.796 51.0099 130.796 51.446C130.796 51.882 130.623 52.3002 130.316 52.6086C130.008 52.9169 129.591 53.0902 129.157 53.0902Z"
          fill={MAIN_COLOR}
        />
        <path
          d="M87.3372 59.8148H60.8741C60.6656 59.8146 60.4658 59.7314 60.3184 59.5836C60.171 59.4358 60.0881 59.2354 60.0879 59.0264V38.3157C60.0881 38.1067 60.171 37.9063 60.3184 37.7585C60.4658 37.6107 60.6656 37.5276 60.8741 37.5273H87.3372C87.5457 37.5276 87.7455 37.6107 87.8929 37.7585C88.0403 37.9063 88.1232 38.1067 88.1234 38.3157V59.0264C88.1232 59.2354 88.0403 59.4358 87.8929 59.5836C87.7455 59.7314 87.5457 59.8146 87.3372 59.8148Z"
          fill="#5F5CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_16342_89102">
          <rect width="144" height="133" fill={BG_COLOR} />
        </clipPath>
      </defs>
    </svg>
  );
};
