import { classNames } from '@foundationPathAlias/utilities';
import { animated, useSpring } from '@react-spring/web';
import { useEffect, useRef } from 'react';

export type ProgressBarFilePropsType = {
  /**
   * A progress percentage: 0 - 100 range.
   * Will be renderred at the right side os the progress bar
   *  */
  progressPercentage: number;

  /** if true the progress bar will be rendered with the fade In animation */
  show: boolean;

  content: JSX.Element | string;

  /**
   * executes at the end of the FadeOut animation after the
   * completed stage
   */
  onFadeOutEnd?: () => void;
  cn?: string;
  progressBarCn?: string;
  textCn?: string;
  progressBarWrapperCn?: string;
};

export function ProgressBarFile(props: ProgressBarFilePropsType) {
  const {
    show,
    progressPercentage,
    onFadeOutEnd,
    cn,
    content,
    progressBarCn,
    textCn,
    progressBarWrapperCn,
  } = props;
  const [springOpacity, springOpacityApi] = useSpring(() => ({ opacity: 0 }));
  const [springWidth, springWidthApi] = useSpring(() => ({
    width: 0,
  }));

  const prevProgressPercentageRef = useRef(0);

  useEffect(() => {
    const prevPercentage = prevProgressPercentageRef.current;

    const shouldSkipAnimation = prevPercentage > progressPercentage;

    springWidthApi.start({
      to: {
        width: progressPercentage,
      },
      config: {
        duration: shouldSkipAnimation ? 0 : 300,
      },
    });

    prevProgressPercentageRef.current = progressPercentage;
  }, [progressPercentage]);

  useEffect(() => {
    if (show) {
      springOpacityApi.start({
        to: {
          opacity: 1,
        },
        config: {
          duration: 150,
        },
      });
    } else {
      setTimeout(() => {
        springOpacityApi.start({
          to: {
            opacity: 0,
          },
          config: {
            duration: 150,
          },
          onResolve: () => {
            onFadeOutEnd?.();
          },
        });
      }, 350);
    }
  }, [show]);

  return (
    <animated.div className={cn} style={springOpacity}>
      <div
        className={classNames(
          'relative z-2 m-auto h-[40px] w-full overflow-hidden rounded-[25px] bg-element-info px-[16px] md:w-[450px]',
          progressBarWrapperCn
        )}
      >
        <animated.div
          className={classNames(
            'absolute left-0 h-full bg-color-9',
            progressBarCn
          )}
          style={{ width: springWidth.width.to((value) => `${value}%`) }}
        />
        <p
          className={classNames(
            ' relative z-1 flex h-full items-center justify-center text-body16SB text-text-primary-dark dark:text-text-primary',
            textCn
          )}
        >
          {content}
        </p>
      </div>
    </animated.div>
  );
}
