import { RoundedDropdownLayout } from '@foundationPathAlias/components/layouts';
import { Row } from '@foundationPathAlias/components/table';
import { ActionDropdownMenuItem, Tooltip } from '@foundationPathAlias/main';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { observer } from 'mobx-react-lite';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { AddRolesTooltipContent } from '.';

const Divider = () => (
  <hr className="border-t-element-subtle dark:border-t-element-subtle-dark" />
);

export enum AvailableActionsEnum {
  AddRoles = 'AddRoles',
  Ban = 'Ban',
  UnBan = 'UnBan',
}

type MemberActionMenuContentProps = {
  row: Row<Partial<TableUser>>;
  isSearchRolesOpen?: boolean;
  setIsSearchRolesOpen?: (value: SetStateAction<boolean>) => void;
  onRoles?: () => void;
  onBan?: () => void;
  onUnBan?: () => void;
  availableActions?: AvailableActionsEnum[];
};

const _MemberActionMenuContent = ({
  isSearchRolesOpen,
  setIsSearchRolesOpen,
  onRoles,
  onBan,
  onUnBan,
  row,
  availableActions,
}: MemberActionMenuContentProps) => {
  const { t } = useTranslation();

  const {
    dimensionsStore: { isMobile },
    systemStore: { isDarkTheme },
    userStore,
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.communityStore,
  ]);

  const isOwnUser = userStore.me?.id === row.original.id;
  const hasOwnerRole = communityStore.hasOwnerRole(row.original?.roles ?? []);

  const rolesOption = (
    <ActionDropdownMenuItem
      label={t('cap:users.table.actions.roles')}
      onClick={onRoles}
      icon={<ChevronRightIcon className="ml-auto" width={20} height={20} />}
      iconSide="right"
    />
  );

  const banOption =
    isOwnUser || hasOwnerRole ? null : (
      <ActionDropdownMenuItem
        label={t('cap:users.table.actions.banThisMember')}
        onClick={onBan}
        className="themed-text-error"
      />
    );

  const unBanOption = (
    <ActionDropdownMenuItem
      label={t('cap:users.table.actions.unBan')}
      onClick={onUnBan}
      className="themed-text-error"
    />
  );

  const hasAddRolesAction = availableActions?.includes(
    AvailableActionsEnum.AddRoles
  );
  const hasBanAction = availableActions?.includes(AvailableActionsEnum.Ban);
  const hasUnBanAction = availableActions?.includes(AvailableActionsEnum.UnBan);

  if (isMobile) {
    return (
      <ul className="pb-[24px]">
        {hasAddRolesAction && rolesOption}

        {hasBanAction && banOption && (
          <>
            {hasAddRolesAction && (
              <div className="my-[8px] px-[16px]">
                <Divider />
              </div>
            )}

            {banOption}
          </>
        )}

        {hasUnBanAction && unBanOption}
      </ul>
    );
  }

  return (
    <RoundedDropdownLayout cn="py-[8px]">
      <ul>
        {hasAddRolesAction && (
          <Tooltip
            on="hover"
            offsetX={4}
            contentStyle={{
              padding: 0,
              maxWidth: 232,
              width: '100%',
            }}
            mouseEnterDelay={300}
            open={isSearchRolesOpen}
            onOpen={() => setIsSearchRolesOpen?.(true)}
            onClose={() => setIsSearchRolesOpen?.(false)}
            arrow={false}
            closeOnDocumentClick={false}
            position={'left top'}
            darkMode={!isDarkTheme}
            triggerEl={<div>{rolesOption}</div>}
          >
            <AddRolesTooltipContent row={row} />
          </Tooltip>
        )}

        {hasBanAction && banOption && (
          <>
            <Divider />

            {banOption}
          </>
        )}

        {hasUnBanAction && unBanOption}
      </ul>
    </RoundedDropdownLayout>
  );
};

export const MemberActionMenuContent = observer(_MemberActionMenuContent);
