import { withAnimatedStack } from '@mainApp/src/components/common';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { ScreenToggleItem } from '@mainApp/src/modules/account-settings/common/ScreenToggleItem';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { Separator } from '@mainApp/src/modules/account-settings/common/Separator';
import { AccountSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';
import { useTranslation } from 'react-i18next';

import { IAccountStore } from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { observer } from 'mobx-react-lite';
export function _DeactivationInitial() {
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;

  const { t } = useTranslation('common');

  const activeMenuItemStore =
    accountSettingsStore.activeSettingStore as IAccountStore;
  const { title } = activeMenuItemStore.activeSetting;

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <ScreenToggleItem
        label={t('accountSettings.deactivateAccount')}
        value={t<string>('accountSettings.deactivateAccountDescr')}
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.DEACTIVATE);
        }}
      />

      <Separator />

      <ScreenToggleItem
        label={t('accountSettings.deleteAccountPermanently')}
        value={t<string>('accountSettings.deleteAccountPermanentlyDescr')}
        onClick={() => {
          accountStore.setNextSettingId(AccountSettingsEnum.DELETE);
        }}
      />
    </ScreenWrapper>
  );
}

export const DeactivationInitial = withAnimatedStack(
  observer(_DeactivationInitial)
);
