// TODO type;
// @ts-nocheck
import { TextNode } from 'lexical';

function convertElement(domNode) {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMentionChannelNode(textContent);
    return {
      node,
    };
  }

  return null;
}
const TYPE = 'mentionChannel';
const nodeStyle = 'background-color: rgba(223, 21, 200, 0.2)';
export class MentionChannelNode extends TextNode {
  __name;
  __data;

  static getType() {
    return TYPE;
  }

  static clone(node) {
    return new MentionChannelNode(
      node.__data,
      node.__name,
      node.__text,
      node.__key
    );
  }
  static importJSON(serializedNode) {
    const node = $createMentionChannelNode(
      serializedNode.data,
      serializedNode.name
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  get id() {
    let res = this.__data.id;
    if (!res || res === 'undefined') {
      res = this.__data._id;
    }

    return this.__data.id || this.__data._id;
  }

  constructor(data, name, text, key) {
    super(text ?? name, key);
    this.__data = data;
    this.__name = name;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      name: this.__name,
      data: this.__data,
      type: TYPE,
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = nodeStyle;
    dom.className = 'mention-channel mention';
    dom.dataset.id = this.id;
    return dom;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention-channel', 'true');
    element.setAttribute('data-mention-channel-id', this.id);
    element.textContent = this.__text;
    return { element };
  }

  isSegmented() {
    return false;
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-mention-channel')) {
          return null;
        }
        return {
          conversion: convertElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }

  isToken() {
    return true;
  }
}

export function $createMentionChannelNode(data, name) {
  const node = new MentionChannelNode(data, name);
  node.setMode('segmented').toggleDirectionless();
  return node;
}

export function $isChannelNode(node) {
  return node instanceof MentionChannelNode;
}
