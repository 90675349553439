import { ImageAttachment, Slider } from '@10x/foundation/src/components';
import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';

import { CLOUDFLARE_VARIANTS_ENUM } from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

export type MessageAttachmentViewPropsType = {
  data: AttachmentModel[];
  optimistic: boolean;
};

export function MessageAttachmentsView(props: MessageAttachmentViewPropsType) {
  const { data, optimistic } = props;

  const [attachmentsDataArray, setAttachmentsDataArray] = useState(data);

  useEffect(() => {
    if (data.length !== attachmentsDataArray.length) {
      setAttachmentsDataArray(data);
    } else {
      // should check if the name had been changed
      for (let i = 0; i < data.length; i++) {
        const prev = attachmentsDataArray[i];
        const next = data[i];

        const prevName = prev.name || prev.serverData?.filename;
        const nextName = next.name || next.serverData?.filename;

        if (prevName !== nextName) {
          setAttachmentsDataArray(data);
        }
      }
    }
  }, [data]);

  if (!data.length) return null;

  return (
    <div className="attachment-views h-[316px] w-full">
      <Slider>
        {(SwiperSlide) => {
          return data?.map((attachmentModel) => {
            const name =
              attachmentModel.name || attachmentModel.serverData?.filename;

            const src = attachmentModel.getImageUrl(
              CLOUDFLARE_VARIANTS_ENUM.PUBLIC
            ) as string;

            const placeholder =
              attachmentModel.preview?.content ||
              (attachmentModel.getImageUrl(
                CLOUDFLARE_VARIANTS_ENUM.MEDIUM_BLURRED
              ) as string);

            return (
              <SwiperSlide key={name}>
                <SlideContent
                  placeholderSrc={placeholder}
                  src={src}
                  optimistic={optimistic}
                  attachmentModel={attachmentModel}
                />
              </SwiperSlide>
            );
          });
        }}
      </Slider>
    </div>
  );
}

type SlideContentPropsType = {
  placeholderSrc: string;
  optimistic: boolean;
  src: string;
  attachmentModel: AttachmentModel;
};

// to support the mobx reactivity for the attachmentModel
const SlideContent = observer((props: SlideContentPropsType) => {
  const { placeholderSrc, optimistic, src, attachmentModel } = props;

  return (
    <ImageAttachment
      rootCn="rounded-[8px] overflow-hidden"
      placeholderSrc={placeholderSrc}
      src={optimistic ? '' : src}
      alt={attachmentModel.fileName as string}
      loading={attachmentModel.uploading}
      failed={attachmentModel.failed}
    />
  );
});
