import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { SmallLoader } from '@foundationPathAlias/components/loaders';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';

import { useValidators, ValidatorType } from './validators';

import { classNames } from '@foundationPathAlias/utilities';

export type TextInputRefType = {
  getValue: () => string;
  setValue: (val: string) => void;
  clearValue: () => void;
};

export type TextInputPropsType = {
  textAreaMode?: boolean;
  onChange?: (
    val: string,
    event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /** triggers when any of the validators produces an error */
  onError?: (error: null | string) => void;
  validators?: ValidatorType<string>[];
  /** an error that could be passed from outside. It will replace the validators error and will be passed to the getCustomErrorUI if exists */
  outerError?: React.ReactNode;
  /** the content that will be rendered at the right side of the input */
  rightSideContent?: JSX.Element | null;
  /** the content that will be rendered at the right side of the input */
  leftSideContent?: JSX.Element | null;
  /** should return if the input success or not. Used for custom logic */
  getIsSuccess?: (value: string, error: boolean) => boolean;
  disabled?: boolean;
  inputName?: string;
  TextAreaProps?: React.HTMLProps<HTMLTextAreaElement>;
  InputProps?: React.HTMLProps<HTMLInputElement>;
  placeholder?: string;
  /** if true the loader will be shown instead success/error icon */
  showLoader?: boolean;
  /** render input without icon if true */
  noIcon?: boolean;
  /** use it if you want to render a custom error ui below the input */
  getCustomErrorUI?: (error: React.ReactNode) => JSX.Element;
  getCustomSuccessUI?: (success: Boolean | undefined) => JSX.Element;
  rootCn?: string;
  /** Input and icons wrapper */
  labelCn?: string;
  inputCn?: string;
  iconWrapperCn?: string;
  /** wrapper around the success/error message */
  infoWrapperCn?: string;
  /** content box in the wrapper around the success/error message */
  infoWrapperContentCn?: string;
  errorTextCn?: string;
  maxLength?: number;
};

export function _TextInput(props: TextInputPropsType, ref: any) {
  const {
    onChange,
    onError,
    outerError,
    validators = [],
    leftSideContent,
    textAreaMode,
    rightSideContent,
    getIsSuccess,
    disabled,
    showLoader,
    inputName,
    TextAreaProps,
    InputProps,
    placeholder,
    noIcon,
    getCustomErrorUI,
    getCustomSuccessUI,
    rootCn,
    labelCn,
    inputCn,
    iconWrapperCn,
    errorTextCn,
    infoWrapperCn,
    infoWrapperContentCn,
    maxLength,
  } = props;

  const { error, runValidators, resetValidationError } =
    useValidators(validators);

  useEffect(() => {
    onError?.(error);
  }, [error]);

  const inputRef: any = useRef<TextInputRefType | null>(null);

  const getValue = useCallback(() => inputRef.current?.value, []);

  const setValue = useCallback((value: string) => {
    if (inputRef.current.value === value) {
      return;
    }
    inputRef.current.value = value;
    // trigger validators on the manual input value set
    runValidators(value);
  }, []);

  const clearValue = useCallback(() => {
    inputRef.current.value = '';
    resetValidationError();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getValue,
      setValue,
      clearValue,
    }),
    []
  );

  let icon;

  const success = getIsSuccess?.(
    inputRef?.current?.value,
    Boolean(outerError || error)
  );

  if (showLoader) {
    icon = <SmallLoader width={20} height={20} />;
  } else {
    if (outerError || error) {
      icon = (
        <ExclamationCircleIcon className="text-element-error dark:text-element-error-dark" />
      );
    } else if (success) {
      icon = (
        <CheckCircleIcon className="text-element-success dark:text-element-success-dark" />
      );
    }
  }

  /**
   * custom UI should be rendered always and the user should decide
   * what he should render at the moment when the error message
   * is presented and what when it is not
   */
  const errorUI = getCustomErrorUI
    ? getCustomErrorUI(outerError || error)
    : Boolean(outerError || error) && (
        <span className={classNames('text-sm14R', errorTextCn)}>
          {outerError || error}
        </span>
      );

  let successUI = null;

  if (!errorUI && getCustomSuccessUI && Boolean(getValue()) && success) {
    successUI = getCustomSuccessUI(success);
  }

  let borderColorDefault =
    !error &&
    !outerError &&
    !success &&
    'border-element-normal dark:bg-background-tetriary-dark';

  const finalHtmlElement = textAreaMode ? (
    <textarea
      ref={inputRef}
      name={inputName}
      disabled={disabled}
      className={classNames(
        'w-[0] min-w-[100%] flex-1 bg-background-primary outline-none [-ms-overflow-style:"none"] [scrollbar-width:"none"] dark:bg-background-tetriary-dark [&::-webkit-scrollbar]:hidden',
        inputCn
      )}
      maxLength={maxLength}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = e.target.value;
        runValidators(val);
        onChange?.(val, e);
      }}
      placeholder={placeholder}
      {...TextAreaProps}
    />
  ) : (
    <input
      type="text"
      ref={inputRef}
      name={inputName}
      disabled={disabled}
      maxLength={maxLength}
      className={classNames(
        'w-[0] min-w-[100%] flex-1 bg-background-primary outline-none dark:bg-background-tetriary-dark',
        inputCn
      )}
      onChange={(e) => {
        const val = e.target.value;
        console.log('2222 VALUE', val);
        runValidators(val);
        onChange?.(val, e);
      }}
      placeholder={placeholder}
      {...InputProps}
    />
  );

  return (
    <div className={classNames('flex w-full flex-1 flex-col', rootCn)}>
      <label
        className={classNames(
          'flex items-start space-x-[10px] rounded-[5px] border-[1px]  bg-background-primary p-[8px] text-body16R text-text-primary placeholder-text-placeholder focus:border-primary-100 dark:bg-background-tetriary-dark dark:text-text-primary-dark dark:placeholder-text-placeholder-dark',
          (outerError || error) && 'border-element-error',
          success && 'border-element-success dark:border-element-success-dark',
          borderColorDefault,
          disabled
            ? 'border-element-normal placeholder-text-placeholder opacity-70 dark:placeholder-text-placeholder-dark'
            : 'focus-within:border-primary-100',
          labelCn
        )}
      >
        {leftSideContent && leftSideContent}
        <div className="flex-1">{finalHtmlElement}</div>

        {!noIcon && (
          <div
            className={classNames(
              'flex min-h-[24px] items-center justify-end',
              !textAreaMode && 'h-full'
            )}
          >
            <span
              className={classNames(
                'flex h-[20px] w-[20px] items-start',
                iconWrapperCn,
                icon ? 'visible' : 'invisible'
              )}
            >
              {icon}
            </span>

            {rightSideContent && (
              <div className="ml-[10px]">{rightSideContent}</div>
            )}
          </div>
        )}
      </label>
      <div className={classNames('relative', infoWrapperCn)}>
        <div className={infoWrapperContentCn}>
          {successUI}
          {errorUI}
        </div>
      </div>
    </div>
  );
}

export const TextInput = memo(forwardRef(_TextInput));
