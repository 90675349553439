import { EmojiClickData, EmojiStyle, SkinTones } from '../types/exposedTypes';

const CDN_URL_APPLE =
  'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/';
const CDN_URL_FACEBOOK =
  'https://cdn.jsdelivr.net/npm/emoji-datasource-facebook/img/facebook/64/';
const CDN_URL_TWITTER =
  'https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/';
const CDN_URL_GOOGLE =
  'https://cdn.jsdelivr.net/npm/emoji-datasource-google/img/google/64/';

export function cdnUrl(emojiStyle: EmojiStyle): string {
  switch (emojiStyle) {
    case EmojiStyle.TWITTER:
      return CDN_URL_TWITTER;
    case EmojiStyle.GOOGLE:
      return CDN_URL_GOOGLE;
    case EmojiStyle.FACEBOOK:
      return CDN_URL_FACEBOOK;
    case EmojiStyle.APPLE:
    default:
      return CDN_URL_APPLE;
  }
}

export const popuplarEmojis: EmojiClickData[] = [
  {
    activeSkinTone: SkinTones.NEUTRAL,
    emoji: '😀',
    imageUrl:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f600.png',
    isCustom: false,
    names: ['grinning', 'grinning face'],
    unified: '1f600',
    unifiedWithoutSkinTone: '1f600',
    getImageUrl: () =>
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f600.png',
  },
  {
    activeSkinTone: SkinTones.NEUTRAL,
    emoji: '😂',
    imageUrl:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f602.png',
    isCustom: false,
    names: ['joy', 'face with tears of joy'],
    unified: '1f602',
    unifiedWithoutSkinTone: '1f602',
    getImageUrl: () =>
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f602.png',
  },
  {
    activeSkinTone: SkinTones.NEUTRAL,
    emoji: '👻',
    imageUrl:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47b.png',
    isCustom: false,
    names: ['ghost'],
    unified: '1f47b',
    unifiedWithoutSkinTone: '1f47b',
    getImageUrl: () =>
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f47b.png',
  },
  {
    activeSkinTone: SkinTones.NEUTRAL,
    emoji: '👍',
    imageUrl:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png',
    isCustom: false,
    names: ['+1', 'thumbsup', 'thumbs up sign'],
    unified: '1f44d',
    unifiedWithoutSkinTone: '1f44d',
    getImageUrl: () =>
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png',
  },
  {
    activeSkinTone: SkinTones.NEUTRAL,
    emoji: '🥰',
    imageUrl:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f970.png',
    isCustom: false,
    names: [
      'smiling face with 3 hearts',
      'smiling face with smiling eyes and three hearts',
    ],
    unified: '1f970',
    unifiedWithoutSkinTone: '1f970',
    getImageUrl: () =>
      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f970.png',
  },
];
