import { getChannelIconByType } from '@mainApp/src/modules/channel/utilities';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

function _MessageEditorPlaceholder() {
  const channelStore = useInjection(IOC_TOKENS.channelStore);

  const { t } = useTranslation('common');
  const activeChannelData = channelStore.activeChannel?.data?.serverData;
  const channelType = activeChannelData?.channelType;
  if (!channelType) {
    return null;
  }

  const Icon = getChannelIconByType(channelType);

  return (
    <div className="editor-placeholder pointer-events-none absolute text-body16R text-text-secondary dark:text-text-secondary-dark">
      <span>{t('message')}</span>
      <span className="relative pl-[14px]">
        {Boolean(Icon) && (
          <Icon
            className="absolute left-[2px]  top-[6px] h-[14px] w-[14px] fill-text-secondary opacity-70
                      dark:fill-text-secondary-dark"
          />
        )}

        <span>{activeChannelData?.name}</span>
      </span>
    </div>
  );
}

export const MessageEditorPlaceholder = observer(_MessageEditorPlaceholder);
