import { PlatformContentRulePayload } from '@10x/foundation/types';
import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { IOC_TOKENS } from '../ioc';
import { PlatformRepository, type IPlatformRepository } from '../repositories';
import { isForibiddenError } from '../utils';
import { ApiBase, CommonApiDataShapeType } from './ApiBase';
import { type IToastStore } from './Toast.store';

@injectable()
export class PlatformStore extends ApiBase implements IPlatformStore {
  repository: PlatformRepository;

  platformRules: CommonApiDataShapeType<PlatformContentRulePayload[] | null> =
    PlatformStore.generateCommonApiDataShape(true, []);

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.platformRepository) repository: IPlatformRepository
  ) {
    super(toastStore);
    this.repository = repository;
    makeObservable(this, {
      platformRules: observable,
      loadPlatformRules: action,
    });
  }

  loadPlatformRules = async (parentId?: string) => {
    runInAction(() => {
      this.platformRules = {
        ...this.platformRules,
        loading: true,
      };
    });
    const response = await this.repository.getPlatformRules(parentId);

    const { error, errors, data } = response as any;

    // on forbidden error must be shown the auth UI. It's an allowed scenario
    if (!isForibiddenError(error) && (error || errors)) {
      this.handleError('Get Platform Rules', error?.message || '');
      return;
    }

    runInAction(() => {
      this.platformRules = {
        ...this.platformRules,
        data: data,
        loading: false,
        error: error,
      };
    });
  };
}

export interface IPlatformStore {
  platformRules: CommonApiDataShapeType<PlatformContentRulePayload[] | null>;
  loadPlatformRules: (parentId?: string) => Promise<void>;
}
