import { classNames } from '@foundationPathAlias/utilities';
export type ButtonBasePropsType = {
  cn?: string;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonBase(props: ButtonBasePropsType) {
  const { children, onClick, cn = '', disabled, ...otherProps } = props;
  return (
    <button
      className={classNames(
        'button-base-interactive',
        disabled && 'opacity-40',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
}
