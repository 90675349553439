import { gql } from '@urql/core';
import { PageInfoFragment } from './commonFragments';
import { RoleFragment } from './rolesFragments';
export const ChannelFragment = gql`
  fragment ChannelFragment on ChannelPayload {
    id
    slug
    name
    order
    communityId
    defaulted
    private
    parentId
    authorId
    channelType
    notificationCounts {
      unread
      mentioned
      __typename
    }
    author {
      id
      username
      status
      firstName
      lastName
      __typename
    }

    roles {
      edges {
        cursor
        node {
          ...RoleFragment
        }
      }
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      __typename
    }

    option {
      sort
      heatOption {
        duration
        initialTemperature
        __typename
      }
      __typename
    }
    __typename
  }
  ${PageInfoFragment}
  ${RoleFragment}
`;
