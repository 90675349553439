import { ContentReactionInput } from '@10x/foundation/types';
import {
  Reactions,
  ReactionsSVG,
  SquareIconButtonBase,
  TooltipHoverContent,
  TooltipHoverContentStyles,
} from '@foundationPathAlias/components';
import { EmojiClickData } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react/types/exposedTypes';
import { classNames } from '@foundationPathAlias/utilities';
import { FaceSmileIcon } from '@heroicons/react/24/outline';
import { Popup } from '@mainApp/src/components/forked-thirdparty/reactjs-popup/src';
import { PopupPosition } from '@mainApp/src/components/forked-thirdparty/reactjs-popup/src/types';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { mergeStyles } from '@mainApp/src/utils';
import { colorSchema } from '@rootConfig/color-schema';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  customTrigger?: JSX.Element;
  offset?: { x: number; y: number };
  position?: PopupPosition[];
  getEmoji?: (emoji: EmojiClickData) => void;
  messageModel?: MessageModel;
  useSmileIcon?: boolean;
  closeReactionsOnOneReaction?: boolean;
  hideHoverStyles?: boolean;
  closeMessageMenu?: () => void;
};

export function MessageReactionButton(props: Props) {
  const { t } = useTranslation(['common']);
  const {
    systemStore: { isDarkTheme, setReactionsOpen },
    messageStore,
    dimensionsStore: { isMobile, windowSize },
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.messageStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
  ]);
  const componentRef = useRef<HTMLDivElement>(null);
  const reactionsRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);
  const [triggerType, setTriggerType] = useState<string>('onMouseEnter');

  const {
    customTrigger,
    offset,
    position,
    getEmoji,
    messageModel,
    useSmileIcon,
    closeReactionsOnOneReaction,
    hideHoverStyles,
    closeMessageMenu,
  } = props;
  const { x: offsetX, y: offsetY } = offset || {};
  let themeColor = colorSchema['background-primary-dark'];
  let svgColor = colorSchema['text-primary'];
  let svgSecondaryColor = colorSchema['text-secondary'];
  if (isDarkTheme) {
    themeColor = colorSchema['background-primary'];
    svgColor = colorSchema['text-primary-dark'];
    svgSecondaryColor = colorSchema['text-secondary-dark'];
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /** to know whether the click is done outside of reactions popup? */
  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node) &&
      reactionsRef.current &&
      !reactionsRef.current.contains(event.target as Node)
    ) {
      handleOutsideClick();
    }
  };

  const handleOutsideClick = () => {
    setActive(false);
    setReactionsOpen(false);
    closeMessageMenu?.();
  };

  const handleEmojiSelection = (emoji: EmojiClickData) => {
    interactiveStore.setBottomSheet(false);
    if (closeReactionsOnOneReaction) {
      setActive(false);
      setReactionsOpen(false);
      closeMessageMenu?.();
    }
    getEmoji?.(emoji);
    if (messageModel) {
      const reaction: ContentReactionInput = {
        unified: emoji.unified,
        activeSkinTone: emoji.activeSkinTone,
        status: true,
      };
      messageStore.saveChannelMessageReaction(messageModel, reaction);
    }
  };

  const handleClick = () => {
    if (isMobile) {
      interactiveStore.setContent(
        <Reactions
          isDark={isDarkTheme}
          isMobile={isMobile}
          getEmoji={handleEmojiSelection}
          dimensions={{
            height: windowSize.height * 0.75,
            width: windowSize.width,
          }}
        />
      );
      //h-[80vh] is used to define 80% of the viewport height
      interactiveStore.setBottomSheetCn('h-[80vh] bottom-[0px] pb-[0px]');
      interactiveStore.setBottomSheet(true);
    }
  };

  const trigger = customTrigger || (
    <div>
      <SquareIconButtonBase
        onClick={handleClick}
        className={classNames(
          'animation-classic group flex items-center justify-center ',
          hideHoverStyles && 'btn-standard-non-hover'
        )}
      >
        {useSmileIcon ? (
          <FaceSmileIcon
            className={classNames(
              'themed-text-secondary h-[24px]  w-[24px] hover:text-color-12 dark:hover:text-color-12-dark',
              active && 'text-color-12 dark:text-color-12-dark'
            )}
          />
        ) : (
          <ReactionsSVG color={active ? svgColor : svgSecondaryColor} />
        )}
      </SquareIconButtonBase>
    </div>
  );

  if (isMobile) {
    return trigger;
  }

  return (
    <div ref={componentRef}>
      <Popup
        trigger={trigger}
        open={active}
        position={position || ['top center', 'center center', 'bottom center']}
        on={['hover', 'click']}
        offsetX={triggerType === 'onMouseEnter' ? undefined : offsetX}
        offsetY={offsetY}
        mouseEnterDelay={100}
        arrow={triggerType === 'onMouseEnter'}
        skipContentStyle={triggerType !== 'onMouseEnter'}
        // repositionOnResize
        // centerOnViewportOverflow={true}
        shouldBeControlledfromParent
        contentStyle={mergeStyles(TooltipHoverContentStyles.tooltip, {
          backgroundColor: themeColor,
          borderColor: themeColor,
        })}
        arrowStyle={{ color: themeColor }}
        onOpen={(a: any) => {
          a?._reactName && setTriggerType(a._reactName);
          setActive(true);
          if (a?._reactName === 'onClick') {
            setReactionsOpen(true);
          }
        }}
        onClose={(a: any) => {
          if (triggerType === 'onMouseEnter') {
            setActive(false);
            if (a?._reactName === 'onClick') {
              setTimeout(() => {
                setTriggerType(a?._reactName);
                setActive(true);
                setReactionsOpen(true);
              }, 0);
            }
          }
        }}
      >
        {triggerType === 'onMouseEnter' ? (
          <TooltipHoverContent
            label={t(closeReactionsOnOneReaction ? 'addEmoji' : 'react')}
          />
        ) : (
          <div ref={reactionsRef}>
            <Reactions getEmoji={handleEmojiSelection} isDark={isDarkTheme} />
          </div>
        )}
      </Popup>
    </div>
  );
}
