import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { useTranslation } from 'react-i18next';

import { StepContentTitle } from '@mainApp/src/components/common/create-community-stepper/shared/StepContentTitle';
import { YourCommunityCard } from '@mainApp/src/components/common/create-community-stepper/shared/community-card/YourCommunityCard';
import { paths } from '@mainApp/src/config/paths';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

export function _CompletedUI() {
  const {
    createCommunityStepperStore,
    systemStore,
    communityStore,
    authStore,
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.authStore,
  ]);
  const {
    steps: { step5: step5Store },
  } = createCommunityStepperStore;

  const { isDarkTheme } = systemStore;

  const { t } = useTranslation(['common', 'create-community']);

  const router = useRouter();

  const action = authStore.logged ? (
    <button
      className={
        'themed-text-inverted body18SB mt-[16px] flex w-full items-center justify-center rounded-[2px] bg-primary-100 px-[16px] py-[9px]'
      }
      disabled={communityStore.creatingCommunity}
      style={{
        backgroundColor: isDarkTheme
          ? step5Store.dmPrimaryColor?.rgba ||
            step5Store.initialPrimaryColor.rgba
          : step5Store.lmPrimaryColor?.rgba ||
            step5Store.initialPrimaryColor.rgba,
      }}
      onClick={() => {
        const isOnboardingModelCreated =
          createCommunityStepperStore.isCommunityCreated;

        /**
         * It's a second time when the user opens this model
         * after the success community creation
         *  */
        if (isOnboardingModelCreated) {
          router.push(
            `${paths.root}/${createCommunityStepperStore.createdCommunitySlug}`
          );
          createCommunityStepperStore.setModal(false);
        } else {
          /**
           * the community hand't been created. User tries to do it after
           * login. Execute process
           *  */
          createCommunityStepperStore.proceedCommunityCreation().finally(() => {
            createCommunityStepperStore.setModal(false);
          });
        }
      }}
    >
      {communityStore.creatingCommunity && <SmallLoader />}
      {communityStore.creatingCommunity
        ? 'Creating....'
        : t('create-community:seeMyCommunity')}
    </button>
  ) : (
    <>
      <button
        className={
          'themed-text-inverted body18SB mt-[16px] flex w-full items-center justify-center rounded-[2px] bg-primary-100 px-[16px] py-[9px]'
        }
        style={{
          backgroundColor: isDarkTheme
            ? step5Store.dmPrimaryColor?.rgba ||
              step5Store.initialPrimaryColor.rgba
            : step5Store.lmPrimaryColor?.rgba ||
              step5Store.initialPrimaryColor.rgba,
        }}
        onClick={() => {
          createCommunityStepperStore.setModal(false);
          router.push(paths.signup);
        }}
      >
        {t('create-community:signUpToSave')}
      </button>
      <p className="themed-text mt-[16px] flex justify-center text-body16R">
        {t('create-community:haveAnAccount')}{' '}
        <button
          className="text-action-primary ml-[8px]"
          onClick={() => {
            createCommunityStepperStore.setModal(false);
            router.push(paths.login);
          }}
        >
          {t('navbar.logIn')}
        </button>
      </p>
    </>
  );

  return (
    <div>
      <StepContentTitle
        title={t('create-community:ready')}
        description={t('create-community:readyDescription')}
      />
      <div className="mb-[32px] mt-[20px] flex justify-center">
        <YourCommunityCard />
      </div>
      {action}
    </div>
  );
}

export const CompletedUI = observer(_CompletedUI);
