import { observer } from 'mobx-react-lite';

import {
  BottomSheet,
  SidebarModal,
  SidebarModalAnimatedStack,
  SidebarModalPositioner,
  useSidebarModalContext,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useEffect, useMemo } from 'react';

import { ChangesAlert } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { useTranslation } from 'react-i18next';
import { ActionsPanelWithMobx } from './components/ActionsPanelWithMobx';
import { SidebarMenuWithMobx } from './components/SidebarMenuWithMobx';
import { ScreenIds } from './constants';
import { ScreenIds as ScreenRuleIds } from './screens/rules/constants';

const _CAPModal = () => {
  const { t } = useTranslation(['common']);
  const {
    capStore,
    dimensionsStore: { isMobile, isReady },
  } = useMultipleInjection([IOC_TOKENS.capStore, IOC_TOKENS.dimensionsStore]);

  const sidebarModalCtx = useSidebarModalContext();

  const {
    title: genericChangesAlertTitleKey,
    description: genericChangesAlertDescr,
    firstBtnText: genericChangesAlertFirstBtnText,
    secondBtnText: genericChangesAlertSecondBtnText,
    ...otherGenericChangesAlertConfig
  } = sidebarModalCtx.state.changesAlertConfig;

  useEffect(() => {
    capStore.setSidebarModal(sidebarModalCtx);
  }, [sidebarModalCtx, capStore]);

  useEffect(() => {
    if (isReady) {
      capStore.setInitialScreen();

      sidebarModalCtx.setChangesAlertConfig({
        title: 'discardChanges',
        description: 'discardChangesDescr',
        firstBtnText: 'keepEditing',
        secondBtnText: 'closeAndDiscard',
      });
    }
  }, [isReady, isMobile]);

  const removeWrapperPaddingBottom = useMemo(() => {
    if ([ScreenIds.USERS].includes(capStore.activeScreenId as string)) {
      return true;
    }

    if (
      [ScreenRuleIds.INITIAL].includes(
        capStore.activeChildScreen?.activeScreen.id as string
      )
    ) {
      return true;
    }

    if (
      capStore.activeScreenId === ScreenIds.CUSTOMIZE &&
      !capStore.isActiveChildScreenDirty
    ) {
      return true;
    }

    return false;
  }, [
    capStore.activeScreenId,
    capStore.isActiveChildScreenDirty,
    capStore.activeChildScreen?.activeScreen.id,
  ]);

  return (
    <SidebarModal
      isMobile={isMobile}
      contentCn="max-w-[820px] md:max-w-[1255px] mx-[auto]"
      onClose={() => {
        capStore.setIsShow(false);
      }}
    >
      <SidebarModalPositioner cn="max-w-[1255px] min-h-full md:min-h-[auto] md:max-h-[908px] md:my-[24px]">
        <SidebarModalAnimatedStack
          isMobile={isMobile}
          desktopSidebarMenu={<SidebarMenuWithMobx />}
          actionsPanel={<ActionsPanelWithMobx />}
          stackWrapperCn="flex-col"
          wrapperCn={classNames({
            'pb-0': removeWrapperPaddingBottom,
          })}
        />

        <ChangesAlert
          contentWrapperCn="border-[1px]"
          titleCn="text-sm14SB"
          descriptionCn="text-sm14R"
          firstBtnCn="text-sm14SB"
          secondBtnCn="text-sm14SB"
          title={t(genericChangesAlertTitleKey as string)}
          description={t(genericChangesAlertDescr as string)}
          firstBtnText={t<string>(genericChangesAlertFirstBtnText as string)}
          secondBtnText={t<string>(genericChangesAlertSecondBtnText as string)}
          show={otherGenericChangesAlertConfig.show as boolean}
          {...otherGenericChangesAlertConfig}
        />
      </SidebarModalPositioner>

      <BottomSheet
        isMobile={isMobile}
        bottomSheetConfig={sidebarModalCtx.state?.bottomSheetConfig}
        onClose={() => {
          capStore.setBottomSheetConfig({
            show: false,
          });
        }}
      />
    </SidebarModal>
  );
};

export const CAPModal = observer(_CAPModal);
