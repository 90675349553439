'use client';
import { RichViewer } from '@foundationPathAlias/components';
import { observer } from 'mobx-react-lite';
import { PlatformRuleItem } from './components';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { IRulesStoreLocal } from './data/Rules.store.local';

const _RuleDetails = () => {
  const { capStore } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.platformStore,
  ]);

  const rulesStoreLocal =
    capStore.activeChildScreen as unknown as IRulesStoreLocal;

  const { selectedRuleDetails, goToChildRule } = rulesStoreLocal;

  const isPlatformRule = selectedRuleDetails?.baseType === 'platform';

  return (
    <ScreenWrapper
      title={selectedRuleDetails?.title ?? ''}
      sidebarModalController={capStore}
      showBackBtn
    >
      <div>
        {isPlatformRule && Boolean(selectedRuleDetails.childrens?.length) ? (
          <>
            {selectedRuleDetails.childrens?.map((subRule) => {
              return (
                <PlatformRuleItem
                  key={subRule.id}
                  rule={subRule}
                  onClick={() => {
                    goToChildRule(subRule);
                  }}
                />
              );
            })}
          </>
        ) : (
          <RichViewer
            predefinedEditorStateJsonString={selectedRuleDetails?.rawJSON ?? ''}
          />
        )}
      </div>
    </ScreenWrapper>
  );
};

export const RuleDetails = withAnimatedStack(observer(_RuleDetails));
