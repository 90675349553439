import {
  ChannelAddMemberInput,
  Channel_Member_Action,
  Channel_Member_Type,
  Channel_Sort_Types,
  Channel_Types,
  Role_List_Types,
} from '@10x/foundation/types';
import type {
  IChannelRepository,
  ICommunityRepository,
} from '@mainApp/src/repositories';
import { inject, injectable } from 'inversify';
import { debounce } from 'lodash';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { IOC_TOKENS } from '../ioc';
import { ApiBase, CommonApiDataShapeType } from './ApiBase';
import { type IChannelStore } from './Channel.store.types';
import { type ICommunityStore } from './Community.store.types';
import { CommunityRoleModel } from './CommunityRole.model';
import {
  ICreateChannelStore,
  MemberData,
  StepScreen,
} from './CreateChannel.types';
import { type IToastStore } from './Toast.store';
import type { ISystemStore, TextFieldType } from './types';

const DEBOUCE_TIMEOUT = 500;
// TODO: make all the functionality which is used in the Channel settings/Group settings as shared! Currently they are using the same things and it's copy paste
@injectable()
export class CreateChannelStore extends ApiBase implements ICreateChannelStore {
  active = false;
  isSubmitting = false;
  currentChannelGroupId = '';
  stepScreen: StepScreen = 'create';

  mode: 'channel' | 'group' = 'channel';

  selectedRole: CommunityRoleModel | null = null;

  addedMembers: MemberData[] = [];
  addedRoles: MemberData[] = [];

  roles: CommonApiDataShapeType<CommunityRoleModel[] | null> =
    CreateChannelStore.generateCommonApiDataShape();

  search = '';
  channelName: TextFieldType;
  channelGroupName: TextFieldType;
  isPrivate = false;

  communityRepository: ICommunityRepository;
  communityStore: ICommunityStore;
  channelRepository: IChannelRepository;
  channelStore: IChannelStore;
  discardAlertIsActive = false;
  systemStore: ISystemStore;

  get isGroupMode() {
    return this.mode === 'group';
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.channelRepository) channelRepository: IChannelRepository,
    @inject(IOC_TOKENS.channelStore) channelStore: IChannelStore,
    @inject(IOC_TOKENS.systemStore) systemStore: ISystemStore,
    @inject(IOC_TOKENS.communityRepository)
    communityRepository: ICommunityRepository
  ) {
    super(toastStore);
    this.communityRepository = communityRepository;
    this.communityStore = communityStore;
    this.channelRepository = channelRepository;
    this.channelStore = channelStore;
    this.systemStore = systemStore;

    this.channelName = {
      value: '',
      error: null,
    };
    this.channelGroupName = {
      value: '',
      error: null,
    };

    makeObservable(this, {
      active: observable,
      channelName: observable,
      channelGroupName: observable,
      isPrivate: observable,
      isSubmitting: observable,
      stepScreen: observable,
      selectedRole: observable,
      search: observable,
      addedMembers: observable,
      mode: observable,
      addedRoles: observable,
      currentChannelGroupId: observable,
      discardAlertIsActive: observable,
      roles: observable,
      addedMembersAndRolesList: computed,
      rolesList: computed,
      isGroupMode: computed,
      membersList: computed,
      filteredMembers: computed,
      filteredRoles: computed,
      setSearch: action,
      setMode: action,
      onBackFromMembersView: action,
      setChannelName: action,
      setChannelGroupName: action,
      addRoleOrMember: action,
      removeRoleOrMember: action,
      onViewRoleMembers: action,
      setIsPrivate: action,
      setStepScreen: action,
      requestCloseModal: action,
      loadMoreMembers: action,
      setDiscardAlertIsActive: action,
      isChannelsNameAvailable: action,
      isChannelGroupNameAvailable: action,
      show: action,
      setCurrentChannelGroupId: action,
      hide: action,
      submitCreateChannel: action,
      submitCreateChannelGroup: action,
    });

    this.debouncedLoadMoreMembers = debounce(
      this.debouncedLoadMoreMembers.bind(this),
      DEBOUCE_TIMEOUT
    ) as () => Promise<void>;
  }

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData.id;
    if (!id) {
      throw this.handleError('Error', 'There is no active community id');
    }
    return id;
  }

  get addedMembersAndRolesList(): MemberData[] {
    return [...this.addedMembers, ...this.addedRoles];
  }

  get rolesList(): MemberData[] {
    const roles = !this.roles?.data
      ? []
      : this.roles.data.map((role) => {
          const { roleId, totalMemberCount, name } = role;

          const description = this.systemStore.i18n?.t(
            'channel:modal.roleMembers',
            {
              roleName: totalMemberCount,
            }
          ) as string;

          return {
            id: roleId,
            description,
            name: name!,
            memberType: Channel_Member_Type.Role,
            totalMemberCount: totalMemberCount,
          };
        });

    return roles;
  }

  get membersList(): MemberData[] {
    const members = (this.communityStore.members.data?.valuesArray ?? []).map(
      (member) => {
        const { id, online, displayName, avatarUrl, standardOrProUsername } =
          member;
        const description = `@${standardOrProUsername}`;

        return {
          id,
          description,
          avatarSrc: avatarUrl,
          name: displayName,
          isOnline: online,
          memberType: Channel_Member_Type.User,
        };
      }
    );

    return members;
  }

  get filteredRoles(): MemberData[] {
    const roles = this.rolesList.filter((item) => {
      return (
        !this.addedRoles.some((role) => role.id === item.id) &&
        item.name.toLocaleLowerCase().includes(this.search)
      );
    });

    return roles;
  }

  get filteredMembers(): MemberData[] {
    return this.membersList.filter(
      (item) => !this.addedMembers.some((member) => member.id === item.id)
    );
  }

  setMode = (mode: 'channel' | 'group') => {
    this.mode = mode;
  };

  loadCommunityRoles = async (
    communityId: string,
    listType: Role_List_Types = Role_List_Types.All
  ) => {
    this.roles = {
      ...this.roles,
      loading: true,
    };

    const { data = [], error } =
      await this.communityRepository.getCommunityRoles(communityId, {
        listType,
      });

    if (error) {
      this.handleError('Community Roles', error.message);
      return;
    }

    this.roles = {
      ...this.roles,
      data: !data
        ? null
        : data.map(
            (role) =>
              new CommunityRoleModel({
                toastStore: this.toastStore,
                communityRepository: this.communityRepository,
                communityRolePayload: {
                  ...role,
                  communityId: this.activeCommunityId,
                },
              })
          ),
      loading: false,
      error: error,
    };
  };

  setDiscardAlertIsActive = (bool: boolean) => {
    this.discardAlertIsActive = bool;
  };

  debouncedLoadMoreMembers = async () => {
    this.communityStore.getCommunityMembers({
      communityId: this.activeCommunityId,
      search: this.search,
    });
  };

  isChannelsNameAvailable = async () => {
    if (!this.channelName.value) {
      this.setChannelName({
        ...this.channelName,
        error: null,
      });

      return true;
    }

    const { data } = await this.channelRepository.getChannels({
      communityId: this.activeCommunityId,
      channelGroupId: this.currentChannelGroupId,
      search: this.channelName.value,
      noCache: true,
    });

    if (data?.edges && this.channelName.value) {
      const hasTheSameName = data.edges.some(
        (edge) =>
          edge.node.name.toLocaleLowerCase() ===
          this.channelName.value.toLocaleLowerCase()
      );

      return !hasTheSameName;
    }

    return true;
  };

  isChannelGroupNameAvailable = async () => {
    if (!this.channelGroupName.value) {
      this.setChannelGroupName({
        ...this.channelGroupName,
        error: null,
      });

      return true;
    }

    await this.channelStore.getChannelGroups(this.activeCommunityId, true);
    const data = this.channelStore.channelGroups.data;
    if (data && this.channelGroupName.value) {
      const hasTheSameName = data.some(
        (channelGroupModel) =>
          channelGroupModel.name.toLocaleLowerCase() ===
          this.channelGroupName.value.toLocaleLowerCase()
      );

      return !hasTheSameName;
    }

    return true;
  };

  requestCloseModal = () => {
    if (this.channelName.value || this.addedMembersAndRolesList.length) {
      this.setDiscardAlertIsActive(true);
      return;
    }

    this.hide();
  };

  loadMoreMembers = async () => {
    this.debouncedLoadMoreMembers();
  };

  setSearch = (val: string) => {
    this.search = val;

    this.loadMoreMembers();
  };

  addRoleOrMember = (memberOrRoleId: string, type: Channel_Member_Type) => {
    if (type === Channel_Member_Type.User) {
      const member = this.membersList.find(
        (member) => member.id === memberOrRoleId
      );

      if (member) {
        this.addedMembers.push(member);
      }
    } else {
      const role = this.rolesList.find((role) => role.id === memberOrRoleId);

      if (role) {
        this.addedRoles.push(role);
      }
    }
  };

  removeRoleOrMember = (memberOrRoleId: string, type: Channel_Member_Type) => {
    if (type === Channel_Member_Type.User) {
      this.addedMembers = this.addedMembers.filter(
        (member) => member.id !== memberOrRoleId
      );
    } else {
      this.addedRoles = this.addedRoles.filter(
        (role) => role.id !== memberOrRoleId
      );
    }
  };

  onViewRoleMembers = async (roleId: string) => {
    const foundRole = (this.roles.data ?? []).find(
      (role) => role.roleId === roleId
    );

    if (!foundRole) return;

    this.selectedRole = foundRole;

    await this.selectedRole.getRoleMembers({});

    this.setStepScreen('membersView');
  };

  onBackFromMembersView = () => {
    this.stepScreen = 'addMembers';
    this.selectedRole = null;
  };

  setStepScreen = (stepScreen: StepScreen) => {
    this.stepScreen = stepScreen;

    if (stepScreen === 'addMembers') {
      Promise.all([
        this.communityStore.getCommunityMembers({
          communityId: this.activeCommunityId,
        }),
        this.loadCommunityRoles(
          this.activeCommunityId,
          Role_List_Types.Community
        ),
      ]);
    }
  };

  submitCreateChannel = async () => {
    runInAction(() => {
      this.isSubmitting = true;
    });

    const members: ChannelAddMemberInput[] | undefined = this.isPrivate
      ? this.addedMembersAndRolesList.map((roleAndMember) => {
          return {
            action: Channel_Member_Action.Add,
            id: roleAndMember.id,
            type: roleAndMember.memberType,
          };
        })
      : undefined;

    const { error } = await this.channelStore.createChannel(
      this.activeCommunityId,
      this.currentChannelGroupId,
      {
        name: this.channelName.value,
        channelType: Channel_Types.Feeds,
        private: this.isPrivate,
        option: {
          sort: Channel_Sort_Types.New,
        },
        members: members,
      }
    );

    runInAction(() => {
      this.isSubmitting = false;
    });
    if (error) {
      this.handleError('Error', error?.message);
      return;
    }

    this.hide();
  };

  submitCreateChannelGroup = async () => {
    runInAction(() => {
      this.isSubmitting = true;
    });

    // const members: ChannelAddMemberInput[] | undefined = this.isPrivate
    //   ? this.addedMembersAndRolesList.map((roleAndMember) => {
    //     return {
    //       action: Channel_Member_Action.Add,
    //       id: roleAndMember.id,
    //       type: roleAndMember.memberType,
    //     };
    //   })
    //   : undefined;

    const { error } = await this.channelStore.createChannelGroup(
      this.activeCommunityId,
      {
        name: this.channelGroupName.value,
        private: this.isPrivate,
        // members: members,
      }
    );

    runInAction(() => {
      this.isSubmitting = false;
    });
    if (error) {
      this.handleError('Error', error?.message);
      return;
    }

    this.hide();
  };

  setChannelName = (val: TextFieldType) => {
    this.channelName = val;
  };
  setChannelGroupName = (val: TextFieldType) => {
    this.channelGroupName = val;
  };

  setIsPrivate = (val: boolean) => {
    this.isPrivate = val;
  };

  show = (mode: 'channel' | 'group' = 'channel') => {
    this.mode = mode;
    this.active = true;
  };
  setCurrentChannelGroupId = (channelGroupId: string) => {
    this.currentChannelGroupId = channelGroupId;
  };

  hide = () => {
    this.active = false;
    this.reset();
  };

  reset = () => {
    this.channelName = { value: '', error: null };
    this.channelGroupName = { value: '', error: null };
    this.isPrivate = false;
    this.stepScreen = 'create';
    this.selectedRole = null;
    this.search = '';
    this.discardAlertIsActive = false;
    this.addedMembers = [];
    this.addedRoles = [];
  };
}
