import { ScreenIds } from '../constants';
import { Customize } from '../Customize';

export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'Customize',
    Component: Customize,
  },
};
