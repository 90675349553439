import { FileUploadStatusEnum } from '@10x/foundation/types';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { AttachmentModel } from './Attachment.model';
import { AttachmentsProgressBarData } from './types';

const initialState = {
  currentUploadingAttachment: null,
  showProgressBar: false,
  uploadingPercent: 0,
  retryingCounter: 0,
  status: FileUploadStatusEnum.INITIAL,
};

@injectable()
export class AttachmentsProgressBarViewStore
  implements IAttachmentsProgressBarViewStore
{
  state: AttachmentsProgressBarData = { ...initialState };

  retryMaxAttemptCount = 3;

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
    });
  }

  setState = (data: Partial<AttachmentsProgressBarData>) => {
    this.state = {
      ...this.state,
      ...data,
    };
  };

  setStart = (attacmentModel: AttachmentModel) => {
    this.setState({
      currentUploadingAttachment: attacmentModel,
      showProgressBar: true,
      status: FileUploadStatusEnum.UPLOADING,
    });
  };

  updateProgress = (uploadingPercent: number) => {
    this.setState({
      uploadingPercent,
    });
  };

  setProcessing = () => {
    this.setState({
      ...initialState,
      showProgressBar: true,
      status: FileUploadStatusEnum.PROCESSING,
    });
  };
  setCompleted = () => {
    this.setState({
      ...initialState,
      status: FileUploadStatusEnum.COMPLETED,
    });
  };

  setRetry = (retryAttempt: number, failedAttachmentModel: AttachmentModel) => {
    this.setState({
      ...initialState,
      showProgressBar: true,
      currentUploadingAttachment: failedAttachmentModel,
      retryingCounter: retryAttempt,
      status: FileUploadStatusEnum.RETRYING,
    });
  };

  setFailed = () => {
    this.setState({
      showProgressBar: false,
      status: FileUploadStatusEnum.FAILED,
    });
  };

  updateAttachment = (attachmentModel: AttachmentModel) => {
    this.setState({
      ...this.state,
      currentUploadingAttachment: attachmentModel,
    });
  };

  checkIsAttachmentUploading = (attachmentId: string) => {
    if (!attachmentId) {
      throw new Error(`attachmentId does not exists: ${attachmentId}`);
    }

    return this.state.currentUploadingAttachment?.id === attachmentId;
  };
}

export interface IAttachmentsProgressBarViewStore {
  state: AttachmentsProgressBarData;
  retryMaxAttemptCount: number;
  setState: (data: AttachmentsProgressBarData) => void;
  setStart: (attachmentModel: AttachmentModel) => void;
  setCompleted: () => void;
  setProcessing: () => void;
  setFailed: () => void;
  setRetry: (
    retryAttempt: number,
    failedAttachmentModel: AttachmentModel
  ) => void;
  updateProgress: (uploadingPercent: number) => void;
}
