import { OutlineButton, PrimaryButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { useTranslation } from 'react-i18next';

type FooterModalProps = {
  cancelText?: string;
  onCancel: () => void;
  onSuccess?: () => void;
  successText?: string;
  hideCancelButton?: boolean;
  className?: string;
  disabledOkButton?: boolean;
};

export const FooterModal = ({
  successText,
  onCancel,
  onSuccess,
  hideCancelButton,
  className,
  disabledOkButton,
  cancelText,
}: FooterModalProps) => {
  const { t } = useTranslation(['common']);

  return (
    <div
      className={classNames(
        'flex justify-end gap-4 border-t border-t-element-subtle bg-background-primary p-6 dark:border-t-element-subtle-dark dark:bg-background-primary-dark',
        className
      )}
    >
      {!hideCancelButton && (
        <OutlineButton cn="w-auto" onClick={onCancel}>
          {cancelText || t('common:cancel')}
        </OutlineButton>
      )}

      {onSuccess && successText && (
        <PrimaryButton
          cn="md:w-auto"
          disabled={disabledOkButton}
          onClick={onSuccess}
        >
          {successText}
        </PrimaryButton>
      )}
    </div>
  );
};
