import {
  // AppleLogoSVG,
  EmailSVG,
  // FbLogoSVG,
  GoogleLogoSVG,
} from '@10x/foundation/src/components/svg';
import { useTranslation } from 'react-i18next';

import { AuthStore } from '@mainApp/src/stores';
import { SocialAuthTypes } from '@mainApp/src/stores/types';

import { classNames } from '@foundationPathAlias/utilities';
import { AuthBtn, AuthContentTitle, AuthContentTopSection } from './common';

const generateAuthAction = (type: SocialAuthTypes) => () => {
  AuthStore.authorizeBySocial(type);
};

const actionsConfig = [
  {
    id: 0,
    action: generateAuthAction('google'),
    icon: <GoogleLogoSVG />,
    label: 'continueWithGoogle',
  },
  // TODO: uncomment when apple and facebook auth will be implemented
  // {
  //   id: 1,
  //   action: generateAuthAction('facebook'),
  //   icon: <FbLogoSVG />,
  //   label: 'continueWithFb',
  // },
  // {
  //   id: 2,
  //   action: generateAuthAction('apple'),
  //   icon: <AppleLogoSVG />,
  //   label: 'continueWithApple',
  // },
  {
    id: 3,
    action: () => {},
    icon: <EmailSVG />,
    label: 'continueWithEmail',
  },
];

type Props = {
  onCloseBtnClick: () => void;
  isAuthLoginMode: boolean;
  setIsAuthLoginMode: (val: boolean) => void;
  onEmailClick: () => void;
  dedicatedPageMode: boolean;
};

export function AuthContent({
  onCloseBtnClick,
  isAuthLoginMode,
  setIsAuthLoginMode,
  onEmailClick,
  // when renders on auth page no need to render a close btn
  dedicatedPageMode,
}: Props) {
  const { t } = useTranslation(['auth', 'common']);
  let title = t('signUp');
  let actionQuestion = t('haveAnAccount');
  let action = t('logIn');
  let policyTitle = t('bySigningUp');

  if (isAuthLoginMode) {
    title = t('logIn');
    actionQuestion = t('dontHaveAcc');
    action = t('signUp');
    policyTitle = t('byLoggingIn');
  }

  const agreeStr = policyTitle + ' ' + t('youAgreeToOur');
  return (
    <>
      <AuthContentTopSection
        onCloseBtnClick={onCloseBtnClick}
        dedicatedPageMode={dedicatedPageMode}
      />
      <AuthContentTitle title={title} />
      <div
        className={classNames(
          'mt-[32px] flex-1 pb-[10px]',
          !dedicatedPageMode ? 'px-[24px]' : ''
        )}
      >
        {actionsConfig.map((actionConfig) => {
          return (
            <AuthBtn
              key={`${actionConfig.id}_${actionConfig.label}`}
              onClick={
                actionConfig.id === 3 ? onEmailClick : actionConfig.action
              }
              icon={actionConfig.icon}
              label={t(actionConfig.label)}
            />
          );
        })}
      </div>

      <div
        className={classNames(
          'flex-col text-center',
          !dedicatedPageMode ? 'px-[24px]' : ''
        )}
      >
        <h5 className="themed-text mb-[22px] text-body16R">
          {actionQuestion}{' '}
          <span
            className="text-action text-body16SB  text-primary-100"
            onClick={() => {
              setIsAuthLoginMode(!isAuthLoginMode);
            }}
          >
            {action}
          </span>
        </h5>
        <p className="dark:text-placeholder-dark text-sm14R text-text-placeholder">
          {agreeStr}{' '}
          <span className="text-action text-sm14SB">
            {t('common:legal.terms')}
          </span>
          ,{' '}
          <span className="text-action text-sm14SB">
            {t('common:legal.privacyPolicy')}
          </span>{' '}
          {t('common:and').toLowerCase()}{' '}
          <span className="text-action text-sm14SB">
            {t('common:legal.cookiesPolicy')}
          </span>
        </p>
      </div>
    </>
  );
}
