import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useRef } from 'react';

import { useKeyPress } from 'react-use';

import { classNames } from '../../../src/utilities';

export enum ToastTypesEnum {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

const TOAST_CONFIG = {
  [ToastTypesEnum.ERROR]: {
    Icon: XCircleIcon,
    bgColor: 'bg-red-50',
    iconColor: 'text-red-400',
    titleColor: 'text-red-800',
    contentColor: 'text-red-700',
  },
  [ToastTypesEnum.SUCCESS]: {
    Icon: CheckCircleIcon,
    bgColor: 'bg-green-50',
    iconColor: 'text-green-400',
    titleColor: 'text-green-800',
    contentColor: 'text-green-700',
  },
  [ToastTypesEnum.WARNING]: {
    Icon: ExclamationTriangleIcon,
    bgColor: 'bg-yellow-50',
    iconColor: 'text-yellow-400',
    titleColor: 'text-yellow-800',
    contentColor: 'text-yellow-700',
  },
  [ToastTypesEnum.INFO]: {
    Icon: InformationCircleIcon,
    bgColor: 'bg-blue-50',
    iconColor: 'text-blue-400',
    titleColor: 'text-blue-800',
    contentColor: 'text-blue-700',
  },
};

export type ToastPropsType = {
  active: boolean;
  children: React.ReactNode;
  title: string | React.ReactNode;
  type: ToastTypesEnum;
  iconClassNames?: string;
  titleClassNames?: string;
  contentClassNames?: string;
  rootClassNames?: string;
  onClose: () => void;
};

export function Toast(props: ToastPropsType) {
  const {
    type,
    active,
    children,
    onClose,
    title,
    rootClassNames,
    iconClassNames,
    titleClassNames,
    contentClassNames,
  } = props;

  const timeoutIdRef = useRef<any>(null);

  const toastConfig = TOAST_CONFIG[type];

  const [isEscPressed] = useKeyPress('Escape');

  if (isEscPressed && active) {
    onClose();
  }

  useEffect(() => {
    if (active) {
      // if you change the timeout value -> do not forget to change in the progressbar as well. Tailwind doesn't support a  dynamic variable somehow
      timeoutIdRef.current = setTimeout(onClose, 5000);
    } else if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, [active]);

  return (
    <div
      className={classNames(
        'absolute left-[50%] top-[12px] z-50 min-w-[260px] -translate-x-[50%] rounded-md pt-[12px]',
        !active && 'hidden',
        toastConfig.bgColor,
        rootClassNames
      )}
    >
      <div className="flex px-4">
        <div className="flex-shrink-0">
          <toastConfig.Icon
            className={classNames(
              'h-5 w-5',
              toastConfig.iconColor,
              iconClassNames
            )}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3
            className={classNames(
              'text-sm font-medium',
              toastConfig.titleColor,
              titleClassNames
            )}
          >
            {title}
          </h3>
          <div
            className={classNames(
              'mt-2 text-sm',
              toastConfig.contentColor,
              contentClassNames
            )}
          >
            {children}
          </div>
        </div>
      </div>
      <div className="mt-[12px] h-1.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
        <div className="h-1.5 animate-[timed-progress-out_5s_linear_forwards] rounded-full bg-blue-600 dark:bg-blue-500"></div>
      </div>
    </div>
  );
}
