import { SidebarModalChildScreen } from '@foundationPathAlias/widgets/sidebar-modal';
import { IReactionDisposer, makeObservable, reaction, runInAction } from 'mobx';
import { ScreenIds } from '../constants';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

export class RolesStore extends SidebarModalChildScreen<ScreenIdsValuesType> {
  private showActionReactionDisposer: IReactionDisposer;

  constructor() {
    super(ScreenIds, screensConfig);
    makeObservable(this, {});

    this.showActionReactionDisposer = reaction(
      () => {
        return this.isDirty;
      },
      (isDirty) => {
        runInAction(() => {
          this.showActionPanel = isDirty;
        });
      }
    );
  }

  dispose = () => {
    this.showActionReactionDisposer();
  };
}
