import { TextInput } from '@foundationPathAlias/components';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EditUserContent, EditUserFormFieldMobile } from '.';
import { NavFieldItem } from './NavFieldItem';

const _EditUserFormFields = () => {
  const {
    dimensionsStore: { isMobile },
    interactiveStore,
    userStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.userStore,
  ]);

  const { t } = useTranslation(['channel'], { keyPrefix: 'editProfileModal' });

  const handleBackOnMobile = () => {
    interactiveStore.setContent(<EditUserContent key="EditUserFormFields" />);
  };

  if (!userStore.me) {
    return null;
  }

  const handleSubmit = async () => {
    await userStore.updateCurrentUser();
    handleBackOnMobile();
  };

  const { displayName, bio, website } = userStore.me;

  if (isMobile) {
    return (
      <div className="flex flex-col gap-4">
        <NavFieldItem
          label={t('displayName')}
          value={displayName}
          onClick={() =>
            interactiveStore.setContent(
              <EditUserFormFieldMobile
                isSubmitting={userStore.isSubmitting}
                defaultValue={displayName}
                label={t('addNameOrNickname')}
                onBack={handleBackOnMobile}
                onChange={(val) => {
                  userStore.setDataField('displayName', val);
                }}
                onSubmit={handleSubmit}
                title={t('displayName')}
              />
            )
          }
        />
        <hr />
        <NavFieldItem
          label={t('bio')}
          value={t('describeYourself')}
          onClick={() =>
            interactiveStore.setContent(
              <EditUserFormFieldMobile
                isSubmitting={userStore.isSubmitting}
                defaultValue={bio}
                label={t('tellUsAboutYourself')}
                onBack={handleBackOnMobile}
                onChange={(val) => {
                  userStore.setDataField('bio', val);
                }}
                onSubmit={handleSubmit}
                title={t('bio')}
                textAreaMode
              />
            )
          }
        />
        <hr />

        <NavFieldItem
          label={t('website')}
          value={t('addYourWebsite')}
          onClick={() =>
            interactiveStore.setContent(
              <EditUserFormFieldMobile
                defaultValue={website}
                isSubmitting={userStore.isSubmitting}
                label={t('addYourWebsite')}
                onBack={handleBackOnMobile}
                onChange={(val) => {
                  userStore.setDataField('website', val);
                }}
                onSubmit={handleSubmit}
                title={t('website')}
              />
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      <TextInput
        onChange={(val) => {
          userStore.setDataField('displayName', val);
        }}
        placeholder={t('addNameOrNickname') as string}
        InputProps={{
          defaultValue: displayName,
        }}
      />

      <TextInput
        onChange={(val) => {
          userStore.setDataField('bio', val);
        }}
        placeholder={t('tellUsAboutYourself') as string}
        TextAreaProps={{
          defaultValue: bio,
        }}
        maxLength={80}
        textAreaMode
      />

      <TextInput
        onChange={(val) => {
          userStore.setDataField('website', val);
        }}
        placeholder={t('addYourWebsite') as string}
        InputProps={{
          defaultValue: website,
        }}
      />
    </div>
  );
};

export const EditUserFormFields = observer(_EditUserFormFields);
