import { Permissions } from '../Permissions';
import { MembersView } from '../components';
import { ScreenIds } from '../constants';
export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'permissions',
    Component: Permissions,
  },
  [ScreenIds.MEMBERS_VIEW]: {
    id: ScreenIds.MEMBERS_VIEW,
    title: 'channel:modal.roleMembers',
    Component: MembersView,
  },
};
