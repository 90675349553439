
import { Account } from "../Account"

import { AccountSettingsEnum } from "../../data/types"

import { Deactivate, DeactivationInitial } from "../options"
import { Delete } from "../options/deactivation/Delete"
import { DisplayName } from "../options/displayname/DisplayName"
import { Email } from "../options/email"
import { Name } from "../options/name"
import { Username } from "../options/username/Username"
export const accountSettingsComponents = {
    [AccountSettingsEnum.INITIAL]: {
        id: AccountSettingsEnum.INITIAL,
        title: 'account',
        Component: Account
    },
    [AccountSettingsEnum.USERNAME]: {
        id: AccountSettingsEnum.USERNAME,
        title: 'accountSettings.changeUsername',
        Component: Username
    },
    [AccountSettingsEnum.DISPLAY_NAME]: {
        id: AccountSettingsEnum.DISPLAY_NAME,
        title: 'accountSettings.changeDisplayName',
        Component: DisplayName
    },
    [AccountSettingsEnum.DEACTIVATE_OR_DELETE]: {
        id: AccountSettingsEnum.DEACTIVATE_OR_DELETE,
        title: 'accountSettings.deactivateOrDeleteAccount',
        Component: DeactivationInitial
    },
    [AccountSettingsEnum.DEACTIVATE]: {
        id: AccountSettingsEnum.DEACTIVATE,
        title: 'accountSettings.deactivateAccount',
        Component: Deactivate
    },
    [AccountSettingsEnum.DELETE]: {
        id: AccountSettingsEnum.DELETE,
        title: 'accountSettings.deleteAccountPermanently',
        Component: Delete
    },
    [AccountSettingsEnum.NAME]: {
        id: AccountSettingsEnum.NAME,
        title: 'accountSettings.changeName',
        Component: Name
    },
    [AccountSettingsEnum.EMAIL]: {
        id: AccountSettingsEnum.EMAIL,
        title: 'accountSettings.changeEmail',
        Component: Email
    },
}