import { classNames } from '@foundationPathAlias/utilities';
import { animated, to, useSpring } from '@react-spring/web';
import { useEffect, useRef } from 'react';

/**
 * Reflects this css animation: 
 * easing: cubic-bezier(0.38, 0.1, 0.36, 0.9);
 * 
 * @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
 * 
 */

type Props = {
  isShow: boolean;
  children: React.ReactNode;
  className?: string;
};

export function AnimatedLayoutAnvil(props: Props) {
  const { isShow, children, className, ...otherProps } = props;

  const wasShownBeforeRef = useRef(false);

  const [springs, api] = useSpring(() => ({
    from: { scale: 1, translateY: 0, opacity: 0 },
    config: { mass: 1, tension: 280, friction: 60, bounce: 0, duration: 300 },
  }));

  useEffect(() => {
    if (isShow) {
      animationShow();
      wasShownBeforeRef.current = true;
      // shouldn't trigger it if it wasn't show before because it will trigger leaving animation and we don't need it if there were no entering animation
    } else if (wasShownBeforeRef.current) {
      animationHide();
      wasShownBeforeRef.current = false;
    }
  }, [isShow]);

  const animationShow = () => {
    api.start({
      from: {
        scale: 1,
        translateY: 0,
        opacity: 0,
      },
      to: async (next) => {
        await next({
          scale: 0.96,
          translateY: 10,
          opacity: 0,
          config: { duration: 3 },
        });
        await next({
          scale: 1,
          translateY: 0,
          opacity: 1,
          config: { duration: 297 },
        });
      },
    });
  };

  const animationHide = () => {
    api.start({
      from: {
        scale: 1,
        translateY: 0,
        opacity: 1,
      },
      to: async (next) => {
        await next({
          scale: 0.96,
          translateY: 10,
          opacity: 0,
          config: { duration: 297 },
        });
        await next({
          scale: 1,
          translateY: 0,
          opacity: 0,
        });
      },
    });
  };

  return (
    <animated.div
      className={classNames('w-fit', className)}
      style={{
        transform: to(
          [springs.scale, springs.translateY],
          (scale, translateY) => `scale(${scale}) translateY(${translateY}px)`
        ),
        opacity: springs.opacity,
      }}
      {...otherProps}
    >
      {children}
    </animated.div>
  );
}
