import { PlatformContentRulePayload } from '@10x/foundation/types';
import { inject, injectable } from 'inversify';
import { Client } from 'urql';
import { GET_PLATFORM_RULES } from '../graphql/queries';
import { IOC_TOKENS } from '../ioc';
import { GraphQLSubscription } from './GraphQLSubscription';
import { BaseRepositoryResponse } from './types';

@injectable()
export class PlatformRepository implements IPlatformRepository {
  gqlClient;

  graphQLSubscription;

  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
    this.graphQLSubscription = new GraphQLSubscription();
  }
  getPlatformRules = async (
    parentId?: string | undefined
  ): Promise<BaseRepositoryResponse<PlatformContentRulePayload[]>> => {
    const response = await this.gqlClient
      .query(GET_PLATFORM_RULES, { parentId })
      .toPromise();

    const { data, error } = response;

    const res = {
      // TODO: remove this parse when receives the correct structure (Lexical) from BE
      data: data?.platformRules?.map((platformRule: any) => ({
        ...platformRule,
      })),
      error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };
}

export interface IPlatformRepository {
  gqlClient: Client;
  graphQLSubscription: GraphQLSubscription;

  getPlatformRules: (
    parentId?: string
  ) => Promise<BaseRepositoryResponse<PlatformContentRulePayload[]>>;
}
