import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { setFloatingElemPositionForSuggestions } from '@foundationPathAlias/components/rich-editor/utils/setFloatingElemPositionForSuggestions';

function FloatingSuggestions({
  editor,
  anchorElem,
  show,
  children,
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  show: boolean;
  children: JSX.Element;
}): JSX.Element {
  const componentElRef = useRef<HTMLDivElement | null>(null);

  const updateComponent = useCallback(() => {
    const selection = $getSelection();
    const componentEl = componentElRef.current;
    const nativeSelection = window.getSelection();
    if (componentEl === null) {
      return;
    }

    const rootEditorEl = editor.getRootElement();

    if (
      nativeSelection !== null &&
      rootEditorEl !== null &&
      editor.isEditable()
    ) {
      const domRect: DOMRect | undefined =
        nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      const anchorNode = nativeSelection?.anchorNode;
      if (nativeSelection && selection && anchorNode) {
        const endOffset = nativeSelection.anchorOffset;

        const newRange = editor?._window?.document.createRange();
        newRange?.setStart(anchorNode, endOffset);
        newRange?.setEnd(anchorNode, endOffset);

        if (newRange && domRect) {
          // a bit below the anchor text line
          domRect.y += 40;
          domRect.x = newRange?.getBoundingClientRect().x;
          setFloatingElemPositionForSuggestions(
            domRect,
            componentEl,
            anchorElem
          );
        }
      }
    }
  }, [anchorElem, editor, show]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateComponent();
      });
    };

    window.addEventListener('resize', update);

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [anchorElem.parentElement, editor, updateComponent]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateComponent();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateComponent();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateComponent, show]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateComponent();
    });
  }, [editor, updateComponent]);

  return (
    <div ref={componentElRef} className="suggestions-floating">
      {show ? (
        <div className="suggestions-floating-body">{children}</div>
      ) : null}
    </div>
  );
}

function useFloatingSuggestionsPlugin(payload: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  show: boolean;
  children: JSX.Element;
  onPopupClose: () => void;
}): JSX.Element | null {
  const { editor, anchorElem, show, children, onPopupClose } = payload;
  const [activeEditor, setActiveEditor] = useState(editor);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (show) {
            onPopupClose();
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor]);

  return createPortal(
    <FloatingSuggestions
      editor={activeEditor}
      anchorElem={anchorElem}
      show={show}
      children={children}
    />,
    anchorElem
  );
}

export function FloatingSuggestionsPlugin({
  anchorElem = document.body,
  show,
  children,
  onPopupClose,
}: {
  anchorElem?: HTMLElement;
  show: boolean;
  children: JSX.Element;
  onPopupClose: () => void;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const payload = {
    editor,
    anchorElem,
    show,
    children,
    onPopupClose,
  };
  return useFloatingSuggestionsPlugin(payload);
}
