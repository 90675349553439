import { XMarkIcon } from '@heroicons/react/20/solid';

import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  onCloseBtnClick: () => void;
  dedicatedPageMode: boolean;
};

export function AuthContentTopSection(props: Props) {
  const { dedicatedPageMode, onCloseBtnClick } = props;
  return (
    <div
      className={classNames(
        'relative mb-[22px] hidden items-center justify-center border-b-[1px] border-element-normal-dark dark:border-element-normal md:flex md:border-none',
        !dedicatedPageMode ? 'px-[24px]' : ''
      )}
    >
      {!dedicatedPageMode ? (
        <button
          className={classNames(
            'hover-el absolute -top-[8px] left-[16px] h-[22px] w-[22px] outline-none md:left-auto md:right-[20px]'
          )}
          onClick={onCloseBtnClick}
        >
          <XMarkIcon className="themed-svg h-[24px] w-[24px]" />
        </button>
      ) : null}
    </div>
  );
}
