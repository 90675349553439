export const SportsSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({
  width = 18,
  height = 18,
  color = '#1A1A1A',
  // to escape autogeneration fill prop from the story. Remove it later
  // @ts-ignore
  fill,
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M10.4333 6.68335C10.4905 6.62195 10.5595 6.5727 10.6362 6.53854C10.7129 6.50437 10.7956 6.48601 10.8796 6.48453C10.9635 6.48305 11.0468 6.49848 11.1247 6.52992C11.2025 6.56135 11.2732 6.60814 11.3325 6.66749C11.3919 6.72684 11.4387 6.79753 11.4701 6.87535C11.5015 6.95318 11.517 7.03654 11.5155 7.12045C11.514 7.20437 11.4956 7.28713 11.4615 7.3638C11.4273 7.44047 11.3781 7.50947 11.3167 7.56669L7.56666 11.3167C7.50944 11.3781 7.44044 11.4273 7.36377 11.4615C7.2871 11.4957 7.20434 11.514 7.12042 11.5155C7.0365 11.517 6.95315 11.5016 6.87532 11.4701C6.7975 11.4387 6.72681 11.3919 6.66746 11.3326C6.60811 11.2732 6.56132 11.2025 6.52989 11.1247C6.49845 11.0469 6.48302 10.9635 6.4845 10.8796C6.48598 10.7957 6.50434 10.7129 6.5385 10.6362C6.57266 10.5596 6.62192 10.4906 6.68332 10.4334L10.4333 6.68335ZM13.7917 0.666687C14.731 0.666687 15.6318 1.03983 16.296 1.70402C16.9602 2.36821 17.3333 3.26904 17.3333 4.20835V5.04169C17.3333 11.83 11.83 17.3334 5.04166 17.3334H4.20832C3.26901 17.3334 2.36818 16.9602 1.70399 16.296C1.0398 15.6318 0.666656 14.731 0.666656 13.7917V12.9584C0.666656 6.17002 6.16999 0.666687 12.9583 0.666687H13.7917ZM16.0833 4.20835C16.0833 3.60057 15.8419 3.01767 15.4121 2.5879C14.9823 2.15813 14.3994 1.91669 13.7917 1.91669H12.9583C12.6225 1.91669 12.29 1.93169 11.9617 1.96085L16.0392 6.03835C16.0683 5.71002 16.0833 5.37752 16.0833 5.04169V4.20835ZM15.7942 7.56169L10.4383 2.20585C8.42236 2.67999 6.58006 3.7107 5.1212 5.1806C3.66234 6.65051 2.64557 8.50054 2.18666 10.52L7.47999 15.8134C9.49951 15.3545 11.3496 14.3378 12.8195 12.8789C14.2894 11.42 15.3201 9.57769 15.7942 7.56169ZM1.95249 12.0542C1.92916 12.3525 1.91666 12.6542 1.91666 12.9584V13.7917C1.91666 14.3995 2.1581 14.9824 2.58787 15.4121C3.01764 15.8419 3.60053 16.0834 4.20832 16.0834H5.04166C5.34582 16.0834 5.64749 16.0709 5.94582 16.0467L1.95249 12.0542Z"
      fill={color}
    />
  </svg>
);
