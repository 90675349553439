import { MessageModel } from '../Message.model';

export class MessageNode {
  id: string;
  // TODO: maybe do it more generic?
  messageModel: MessageModel;

  prev: MessageNode | null = null;
  next: MessageNode | null = null;

  orderIndex: number | null = null;

  constructor(messageModel: MessageModel) {
    this.id = messageModel.id;
    this.messageModel = messageModel;
  }
}
