import { classNames } from '@foundationPathAlias/utilities';
import { ModalClosePanel } from '../MobalClosePanel';
export type CodeFormWrapperProps = {
  title: string;
  subHeading: string;
  onCloseBtnClick: () => void;
  /** CodeForm component */
  children: JSX.Element;
  /** needed for post auth Onboarding because it supports dedicated page */
  dedicatedPageMode?: boolean;
  cn?: string;
  headerCn?: string;
  titleCn?: string;
  subHeadingCn?: string;
};
export function CodeFormWrapper(props: CodeFormWrapperProps) {
  const {
    cn,
    onCloseBtnClick,
    dedicatedPageMode,
    title,
    subHeading,
    headerCn,
    subHeadingCn,
    titleCn,
    children,
  } = props;
  return (
    <div
      className={classNames(
        'themed-layout max-w-[424px] rounded-[10px] py-[24px]',
        cn
      )}
    >
      <ModalClosePanel
        onCloseBtnClick={onCloseBtnClick}
        dedicatedPageMode={Boolean(dedicatedPageMode)}
      />
      <div className={classNames('flex flex-1 flex-col px-[16px] ', headerCn)}>
        <h4
          className={classNames(
            'themed-text mb-[8px] text-center text-miniHead',
            titleCn
          )}
        >
          {title}
        </h4>
        <p
          className={classNames(
            'themed-text-secondary mb-[32px] text-center text-body16R',
            subHeadingCn
          )}
        >
          {subHeading}
        </p>

        {children}
      </div>
    </div>
  );
}
