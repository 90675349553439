import {
  MenuListItem,
  SidebarMenu,
  SidebarMenuItem,
} from '@foundationPathAlias/components/sidebar-menu';
import { Sidebar } from './Sidebar';

export type { MenuListItem };

export type SidebarMenuPropsType<T> = {
  isMobile: boolean;
  sidebaMenuCn?: string;
  sidebarCn?: string;
  menuItemLabelCn?: string;
  menuItemCn?: string;
  onMenuSelect: (id: T) => void;
  activeMenuItemId: T;
  menuItemsConfig: MenuListItem<T>[];
  // use it if you want a custm sidebar menu item
  getSidebarMenuItem?: (item: MenuListItem<T>) => JSX.Element;
};

export function SidebarWithMenu<T>(props: SidebarMenuPropsType<T>) {
  const {
    isMobile,
    onMenuSelect,
    menuItemsConfig,
    activeMenuItemId,
    menuItemLabelCn,
    menuItemCn,
    sidebaMenuCn,
    sidebarCn,
    getSidebarMenuItem,
  } = props;

  return (
    <Sidebar isMobile={isMobile} cn={sidebarCn}>
      <SidebarMenu cn={sidebaMenuCn}>
        {menuItemsConfig.map((item) =>
          getSidebarMenuItem ? (
            getSidebarMenuItem(item)
          ) : (
            <SidebarMenuItem
              cn={menuItemCn}
              labelCn={menuItemLabelCn}
              icon={item.icon}
              id={item.id}
              isMobile={false}
              onSelect={() => {
                onMenuSelect(item.id);
              }}
              label={item.label}
              active={activeMenuItemId === item.id}
              key={item.id as string}
            />
          )
        )}
      </SidebarMenu>
    </Sidebar>
  );
}
