import {
    BottomSheetConfig,
    DiscardActionsEnum, SetActiveDiscardAction, SetBottomSheetConfigAction, SetChangesAlertConfigAction,
    SetDiscardActions,
    SetDiscardActionsPayload,
    SetShowAction,
    ShowChangesAlertAndSaveAction,
    ShowChangesAlertAndSaveActionPayload,
    SidebarModalActions, SidebarModalState,
} from './types';

import { ChangesAlertProps } from '@foundationPathAlias/main';
import { ACTIONS } from './actionConstants';

export const initialState: SidebarModalState = {
    changesAlertConfig: {
        show: false,
        title: '',
        description: '',
        descriptionCn: '',
        firstBtnText: '',
        secondBtnText: '',
        onFirstBtnClick: undefined,
        onSecondBtnClick: undefined,
    },
    discardActions: {
        [DiscardActionsEnum.BACK]: null,
        [DiscardActionsEnum.CLOSE]: null,
        [DiscardActionsEnum.GO_TO_MENU]: {
            savedChildScreenId: null,
        },

    },
    activeDiscardAction: null,

    show: false,
    bottomSheetConfig: {
        content: null,
        show: false,
        onClose: null,
    }
};

const handlers = {
    [ACTIONS.SET_CHANGES_ALERT_CONFIG]: (state: SidebarModalState, action: SetChangesAlertConfigAction) => setChangesAlertConfig(state, action.payload),
    [ACTIONS.SET_DISCARD_ACTIONS]: (state: SidebarModalState, action: SetDiscardActions) => setDiscardActions(state, action.payload),
    [ACTIONS.SET_ACTIVE_DISCARD_ACTION]: (state: SidebarModalState, action: SetActiveDiscardAction) => setActiveDiscardAction(state, action.payload),
    [ACTIONS.SHOW_CHANGES_ALERT_AND_SAVE_ACTION]: (state: SidebarModalState, action: ShowChangesAlertAndSaveAction) =>
        showChangesAlertAndSaveAction(state, action.payload),
    [ACTIONS.SET_SHOW]: (state: SidebarModalState, action: SetShowAction) => setShow(state, action.payload),
    [ACTIONS.SET_BOTTOM_SHEET_CONFIG]: (state: SidebarModalState, action: SetBottomSheetConfigAction) =>
        setBottomSheetConfig(state, action.payload),
};

export const sidebarModalkReducer = (state: SidebarModalState, action: SidebarModalActions) => {
    const handler = handlers[action.type];
    if (handler) {
        // a top-notch hack to avoid the overload issue
        return handler(state, action as any);
    }
    return state;
};


function setChangesAlertConfig(state: SidebarModalState, payload: Partial<ChangesAlertProps>) {
    return { ...state, changesAlertConfig: { ...state.changesAlertConfig, ...payload } };
}
function setDiscardActions(state: SidebarModalState, payload: SetDiscardActionsPayload) {
    return { ...state, discardActions: { ...state.discardActions, ...payload } };
}
function setActiveDiscardAction(state: SidebarModalState, payload: DiscardActionsEnum | null) {
    return { ...state, activeDiscardAction: payload };
}

function showChangesAlertAndSaveAction(state: SidebarModalState, payload: ShowChangesAlertAndSaveActionPayload) {

    return {
        ...state,
        discardActions: {
            ...state.discardActions,
            [payload.discardActionId]: {
                savedChildScreenId: payload.savedChildScreenId || null,
            },
        },
        activeDiscardAction: payload.discardActionId,
        changesAlertConfig: {
            ...state.changesAlertConfig,
            show: true,
        },
    };
}

function setShow(state: SidebarModalState, payload: boolean) {
    // shouldn't do anything if the value hadn't changed
    if (state.show === payload) return state;
    return { ...state, show: payload };
}

function setBottomSheetConfig(
    state: SidebarModalState,
    payload: Partial<BottomSheetConfig>
) {
    return { ...state, bottomSheetConfig: { ...state.bottomSheetConfig, ...payload } };
}
