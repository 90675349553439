import { PrimaryButton } from '@foundationPathAlias/components/buttons';
import { classNames } from '@foundationPathAlias/utilities';
import { YourCommunityCard } from '@mainApp/src/components/common/create-community-stepper/shared/community-card/YourCommunityCard';
import CornerGradient from '@mainApp/src/components/gradient/CornerGradient';
import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DotsBlock } from './DotsBlock';

export function _HomeSection1() {
  const { t } = useTranslation(['common', 'home']);
  const router = useRouter();
  const {
    createCommunityStepperStore: { setModal, completed, createdCommunitySlug },
    systemStore,
    dimensionsStore: { isMobile },
    authStore: { logged },
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.authStore,
  ]);
  const [textKey, setTextKey] = useState({
    title1: 'buildCommunity',
    title2: 'onYourSite',
    title3: 'yourVisitors',
    btnName: 'navbar.createMyCommunity',
  });

  useEffect(() => {
    if (logged && completed) {
      setTextKey({
        title1: 'yourCommunity',
        title2: 'ready',
        title3: 'excitingPart',
        btnName: 'home:viewMyCommunity',
      });
    }
  }, [completed]);

  const { title1, title2, title3, btnName } = textKey;
  return (
    <section className="grid grid-cols-1 px-[24px] pt-[48px] sm:grid-cols-2 sm:px-[115px]">
      <div className="relative z-1 pb-[60px]">
        <h1 className="leading-0 mb-[32px] text-h2 sm:text-h1">
          <span className="grad-t-build inline leading-[55px] sm:leading-[86px] ">
            {t(title1, { ns: 'home' })}
          </span>
          <p className="themed-text">{t(title2, { ns: 'home' })}</p>
        </h1>

        {isMobile && completed && logged ? null : (
          <h2 className="themed-text-secondary text-subheading">
            {t(title3, { ns: 'home' })}
          </h2>
        )}
        <div className="absolute right-[-25px] mt-[20px] sm:hidden">
          <CornerGradient
            color={
              systemStore.isDarkTheme
                ? 'rgba(123, 122, 255, .6)'
                : 'rgba(95, 92, 255, 0.3)'
            }
            size={110}
          />
        </div>
        {isMobile && logged && completed && (
          <RightCol
            isMobile
            isMyCommunity
            isDarkTheme={systemStore.isDarkTheme}
          />
        )}
        <div className="mb-[32px] mt-[40px] flex flex-col space-y-[24px] sm:flex-row sm:space-x-[24px] sm:space-y-0">
          <PrimaryButton
            aria-label={btnName}
            cn="z-1 w-auto sm:min-w-[220px] px-[60px] rounded-[24px] text-white text-body16SB"
            onClick={() => {
              if (logged && completed) {
                router.push(`${paths.home}/${createdCommunitySlug}`);
              } else {
                if (isMobile && systemStore.isMobileNavOpened) {
                  systemStore.setMobileNavOpen(false);
                }
                setModal(true);
              }
              // setModal(true);

              // if (dimensionsStore.isMobile && systemStore.isMobileNavOpened) {
              //   systemStore.setMobileNavOpen(false);
              // }
              // setModal(true);
            }}
          >
            {t(btnName)}
          </PrimaryButton>
        </div>
        {!(logged && completed) && (
          <p className="z-9 text-center text-sm14R text-text-secondary dark:text-text-secondary-dark sm:text-left">
            {t('oneClickSignUp', { ns: 'home' })}
            <span className="z-9 mx-[8px] mb-[2px] inline-block h-[4px] w-[4px] rounded-full bg-text-secondary dark:bg-text-secondary-dark"></span>
            {t('noCreditCard', { ns: 'home' })}
          </p>
        )}
        {isMobile && (
          <div className="absolute -left-[20px] bottom-0 flex">
            <DotsBlock
              rowsCount={3}
              colsCount={5}
              dotColorTwCn="bg-common-color-2 dark:bg-common-color-2-dark"
            />
          </div>
        )}
      </div>
      {!isMobile && (
        <RightCol
          isMyCommunity={logged && completed}
          isDarkTheme={systemStore.isDarkTheme}
        />
      )}
      {isMobile && <MobileLeftDotsElement />}
    </section>
  );
}

function RightCol({
  isDarkTheme,
  isMyCommunity,
  isMobile,
}: {
  isDarkTheme: boolean;
  isMyCommunity: boolean;
  isMobile?: boolean;
}) {
  return (
    <div className="flex justify-center md:justify-end">
      <div
        className={classNames(
          'home-section-1-community-image-wrapper relative',
          'mt-[8px] bigheight:mt-0',
          'flex items-center justify-center'
        )}
      >
        {isMyCommunity ? (
          <div>
            <YourCommunityCard
              loadImageOnPriority
              cn="z-3 h-[380px] w-[calc(100vw-60px)] bigheight:h-[480px] bigheight:w-[372px]"
            />
            {!isMobile && (
              <div className="absolute -right-[59px] bottom-[50px]">
                <DotsBlock
                  rowsCount={3}
                  colsCount={10}
                  dotColorTwCn="bg-common-color-2 dark:bg-common-color-2-dark"
                />
              </div>
            )}
          </div>
        ) : (
          <div className="relative z-1 flex justify-end">
            <Image
              src={'/images/to_delete/cat.webp'}
              width={387}
              height={494}
              alt="Community image section 1"
              className="rounded-[20px]"
              priority
            />
            <figure className="absolute -right-[32px] top-[30px] flex">
              <figcaption className="themed-layout themed-text-secondary h-[56px] w-[137px] rounded-[10px] px-[12px] py-[8px] text-exstraSm12R">
                <p className="themed-text text-exstraSm12B">Maya Collins</p>
                Oh he’s so cute!
              </figcaption>

              {/* <img
                className="mt-[18px] h-[89px] w-[89px]"
                src="/images/to_delete/maya.png"
                alt="Gabriel avatar"
              /> */}
              <Image
                src={'/images/to_delete/maya.webp'}
                width={89}
                height={89}
                alt="Gabriel avatar"
                className="mt-[18px]"
              />
            </figure>
            <div className="themed-layout absolute -left-[52px] bottom-[24px] h-[126px] w-[300px] rounded-[16px] px-[20px] py-[13px]">
              <h5 className="themed-text mb-[8px] text-body18XB">Tiny cats</h5>
              <p className="mb-[8px] flex space-x-[8px] text-body16M text-primary-100">
                <span className="block rounded-[4px] bg-primary-10 px-[8px] py-[2px]">
                  Animals
                </span>
                <span className="block rounded-[4px] bg-primary-10 px-[8px] py-[2px]">
                  Adoption
                </span>
              </p>
              <span className="themed-text-secondary text-body16R">
                1.3k members
              </span>
            </div>

            <div className="absolute -right-[59px] bottom-[50px]">
              <DotsBlock
                rowsCount={3}
                colsCount={10}
                dotColorTwCn="bg-common-color-2 dark:bg-common-color-2-dark"
              />
            </div>
          </div>
        )}

        <div className="home-section-1-left-dots-block absolute -left-[90px] bottom-[100px] ">
          <figure className="absolute -top-[14px] left-[25px]">
            {!isMobile && (
              <DotsBlock
                rowsCount={3}
                colsCount={6}
                dotColorTwCn="bg-common-color-4 dark:bg-common-color-4-dark"
              />
            )}
          </figure>

          {!isMyCommunity && (
            <div className="absolute ml-[370px] mt-[80px]">
              <CornerGradient
                color={
                  isDarkTheme
                    ? 'rgba(123, 122, 255, .4)'
                    : 'rgba(95, 92, 255, 0.3)'
                }
              />
            </div>
          )}

          {!isMobile && (
            <span className="block h-[92px] w-[155px] rounded-[10px] bg-background-secondary dark:bg-background-website-dark" />
          )}
        </div>
      </div>
    </div>
  );
}

function MobileLeftDotsElement() {
  return (
    <div className="absolute right-0 top-[90px]  h-[74px] w-[79px]">
      <div className="relative h-[74px] w-[79px] overflow-hidden">
        <span className="absolute left-0 block h-[74px] w-[176px] overflow-hidden rounded-[10px] bg-background-secondary dark:bg-background-secondary-dark"></span>
      </div>
      <div className="relative bottom-[88px] z-1 flex pl-[22px]">
        <DotsBlock
          rowsCount={3}
          colsCount={5}
          dotColorTwCn="bg-common-color-4 dark:bg-common-color-4-dark"
        />
      </div>
    </div>
  );
}

export const HomeSection1 = observer(_HomeSection1);
