import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  MESSAGE_EDITOR_EVENTS_ENUM,
  messageEditorStore,
} from '@mainApp/src/modules/channel/editor-area/MessageEditor.store';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { $createMentionAdminNode } from '../nodes/MentionAdminNode';

import { RolesEnum } from '@10x/foundation/types';

type Props = {
  type: RolesEnum;
};

// TODO: test
import { $getRoot } from 'lexical';
export function _MentionAdminPlugin(props: Props) {
  const { type } = props;
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    if (!editor) return;

    messageEditorStore.on(
      MESSAGE_EDITOR_EVENTS_ENUM.SELECT_MENTION_ADMIN,
      (data: any) => {
        editor.update(() => {
          const node = $createMentionAdminNode({ type }, data.label);

          const selection = $getRoot().select();
          selection.deleteCharacter(true);

          selection.insertNodes([node]);
        });
      }
    );

    return () => {
      messageEditorStore.clear(MESSAGE_EDITOR_EVENTS_ENUM.SELECT_MENTION_ADMIN);
    };
  }, [editor, type]);

  return null;
}

export const MentionAdminPlugin = observer(_MentionAdminPlugin);
