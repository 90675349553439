

import { Action, ACTIONS, AnimatedStackReducerState, BackAction, BackActionPayload, ItemPayload, NextAction, SetPrevItemAction, SetVisibleAction, StackItem } from "./data";

export const initialItemState: StackItem = {
    Component: null,
    id: null,
};

export const initialState: AnimatedStackReducerState = {
    prevItem: { ...initialItemState },
    visibleItem: { ...initialItemState },
    nextItem: { ...initialItemState },
    startEntering: false,
    startLeaving: false,
    history: [],
};

const handlers = {
    [ACTIONS.RESET]: (state: AnimatedStackReducerState) => reset(state),
    [ACTIONS.SET_PREV_ITEM]: (state: AnimatedStackReducerState, action: SetPrevItemAction) => setPrevItem(state, action.payload),
    [ACTIONS.SET_VISIBLE]: (state: AnimatedStackReducerState, action: SetVisibleAction) => setVisible(state, action.payload),
    [ACTIONS.NEXT]: (state: AnimatedStackReducerState, action: NextAction) => next(state, action.payload),
    [ACTIONS.BACK]: (state: AnimatedStackReducerState, action: BackAction) => back(state, action.payload),
    [ACTIONS.ON_LEAVING_ANIMATION_END]: (state: AnimatedStackReducerState) => onLeavingAnimationEnd(state),
    [ACTIONS.ON_ENTER_ANIMATION_END]: (state: AnimatedStackReducerState) => onEnterAnimationEnd(state),
};

export const animatedStackReducer = (state: AnimatedStackReducerState, action: Action) => {

    const handler = handlers[action.type];
    if (handler) {
        // a top-notch hack to avoid overloading issue
        return handler(state, action as any);
    }
    return state;
};

function reset(state: AnimatedStackReducerState) {
    return {
        ...state,
        history: [],
        prevItem: { ...initialItemState },
        visibleItem: { ...initialItemState },
        nextItem: { ...initialItemState },
    };
}

function setPrevItem(state: AnimatedStackReducerState, payload: ItemPayload) {
    return { ...state, prevItem: { ...payload } };
}
function setVisible(state: AnimatedStackReducerState, payload: ItemPayload) {
    return { ...state, visibleItem: { ...payload }, startEntering: false, startLeaving: false };
}
function next(state: AnimatedStackReducerState, payload: ItemPayload) {
    // checking if there already had been added this screen. Just in case
    if (state.history.some(o => o.id === payload.id)) return state;
    // a next prev item will be active so should add the current prev item to history to be able to move back step by step
    const history = [...state.history, state.visibleItem];
    return { ...state, nextItem: { ...payload }, startEntering: true, history };
}


function back(state: AnimatedStackReducerState, payload: BackActionPayload) {
    // if there is no any history but only a prev item so just should move to it. This use case is possible when the user switches from desktop to mobile and press the button back on the initial screen of the setting etc. to go to the settings menu (on desktop it hadn't be added to history so just do it manually). Once it will be moved to the settings menu the back button won't be visible at all so this action shouldn't happen anymore
    const lastItem = state.history[state.history.length - 1] || state.prevItem;
    if (state.startLeaving || !lastItem || lastItem.id !== state.prevItem.id) {
        return state;
    }

    payload.callback?.(lastItem.id as string);

    return { ...state, startLeaving: true, history: state.history.slice(0, state.history.length - 1) };
}

function onLeavingAnimationEnd(state: AnimatedStackReducerState) {
    const visibleItem = { ...state.prevItem };
    const nextItem = { ...initialItemState };

    // put the prev item from history if exists or use the initial state
    const prevItem = state.history[state.history.length - 1] || initialItemState;

    return { ...state, visibleItem, nextItem, prevItem: { ...prevItem }, startLeaving: false };
}

function onEnterAnimationEnd(state: AnimatedStackReducerState) {
    // if there is no any history but only a prev item so just should move to it. In this use case is possible when the user switches from desktop to mobile and press the button back on the intial screen of a settings store to go to the settings menu (on desktop it hadn't be added to history so just do it manually). Once it will be moved to the settings menu the back button won't be visible at all so this action shouldn't happen anymore
    const prevItem = state.history.length ? { ...state.visibleItem } : { ...initialItemState };
    const visibleItem = { ...state.nextItem };
    const nextItem = { ...initialItemState }


    return { ...state, visibleItem, prevItem, nextItem, startEntering: false };
}
