import { action, computed, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { CommunityStepperModel } from './CommunityStepper.model';

import { IStepBase, StepBase } from './StepBase';

import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { TextFieldType } from '../types';
import { TopicsType } from './CreateCommunityStepper.types';

enableStaticRendering(typeof window === 'undefined');

export class Step4Store extends StepBase implements IStep4Store {
  id = 4;

  get topics() {
    return this.communityStepperModel.topics;
  }

  get canMoveNext() {
    const topicsValues = Object.values(this.topics);
    const isSomeTopicFilled = topicsValues.some((topic) =>
      Boolean(topic.value)
    );
    const isNoError = topicsValues.every((topic) => !topic.error);
    return isSomeTopicFilled && isNoError;
  }

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    super(repository, communityStepperModel);

    makeObservable(this, {
      setTopicValue: action,
      reset: action,
      topics: computed,
    });
  }

  setTopicValue = (topicNumber: 1 | 2, value: TextFieldType) => {
    this.resetMoveBackError();
    const topicName: 'topic1' | 'topic2' = `topic${topicNumber}`;
    const newTopics = {
      ...this.topics,
      [topicName]: value,
    };

    this.communityStepperModel.setTopics(newTopics);
  };

  reset = () => {
    this.communityStepperModel.resetTopics();
  };
}

export interface IStep4Store extends IStepBase {
  readonly topics: TopicsType;
  setTopicValue: (topicNumber: 1 | 2, value: TextFieldType) => void;
}
