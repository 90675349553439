export const HashTagLockSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 16, height = 16, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.78321 4.72559L8.72111 4.72559V6.16565L6.53265 6.16565L5.89588 9.8255L9.47371 9.8255L9.66603 8.72012H11.1283L10.936 9.8255H14.4813C14.879 9.8255 15.2014 10.1479 15.2014 10.5455C15.2014 10.9432 14.879 11.2656 14.4813 11.2656H10.6855L10.1043 14.6059C10.0361 14.9978 9.66332 15.2588 9.2717 15.1889C8.88007 15.1189 8.61788 14.7444 8.68607 14.3525L9.22316 11.2656H5.64533L5.06415 14.6059C4.99596 14.9978 4.6232 15.2588 4.23158 15.1889C3.83995 15.1189 3.57776 14.7444 3.64595 14.3525L4.18304 11.2656H1.52081C1.12315 11.2656 0.800781 10.9432 0.800781 10.5455C0.800781 10.1479 1.12315 9.8255 1.52081 9.8255H4.43359L5.07036 6.16565H1.52081C1.12315 6.16565 0.800781 5.84328 0.800781 5.44562C0.800781 5.04796 1.12315 4.72559 1.52081 4.72559L5.32091 4.72559L5.90055 1.39411C5.96875 1.00217 6.3415 0.741169 6.73313 0.811151C7.12475 0.881133 7.38695 1.2556 7.31875 1.64754L6.78321 4.72559ZM14.5714 3.82391C14.9188 3.82391 15.2014 4.08226 15.2014 4.39994V6.70403C15.2014 7.02171 14.9188 7.28005 14.5714 7.28005H10.7912C10.4437 7.28005 10.1612 7.02171 10.1612 6.70403V4.39994C10.1612 4.08226 10.4437 3.82391 10.7912 3.82391H11.1062V2.95988C11.1062 2.16583 11.8128 1.51982 12.6813 1.51982C13.5498 1.51982 14.2563 2.16583 14.2563 2.95988V3.82391H14.5714ZM12.6813 2.09584C12.1602 2.09584 11.7362 2.48351 11.7362 2.95988V3.82391H13.6263V2.95988C13.6263 2.48351 13.2023 2.09584 12.6813 2.09584Z"
      fill="currentColor"
    />
  </svg>
);
