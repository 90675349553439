import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@10x/foundation/src/components/modal/Modal';
import {
  CodeForm,
  CodeFormWrapper,
} from '@mainApp/src/components/common/code-form';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { DeactivateOrDeleteRedirectContent } from '@mainApp/src/modules/account-settings/account/common';

import { paths } from '@mainApp/src/config';
import { IAccountStore } from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import {
  RedirectionURLActionsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';

type Props = {
  email: string;
  username: string;
  setShowCodeForm: (show: boolean) => void;
  showCodeForm: boolean;
};

export function _DeleteFinalContent(props: Props) {
  const { setShowCodeForm, email, username, showCodeForm } = props;
  const { t } = useTranslation(['common', 'auth']);
  const [otpVerifyError, setOtpVerifyError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const {
    accountSettingsStore,
    dimensionsStore,
    redirectService,
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.redirectService,
    IOC_TOKENS.interactiveStore,
  ]);
  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;

  const isMobile = dimensionsStore.isMobile;

  useEffect(() => {
    const { show: showBtmSht } = accountSettingsStore.bottomSheetConfig;

    if (!isMobile) return;

    if (!accountStore.showCodeForm && showBtmSht) {
      accountSettingsStore.setBottomSheetConfig({
        show: false,
      });
      return;
    }

    if (accountStore.showCodeForm && !showBtmSht) {
      accountSettingsStore.setBottomSheetConfig({
        show: true,
      });
      return;
    }
  }, [accountStore.showCodeForm, isMobile]);

  const formContent = (
    <CodeFormWrapper
      title={t('checkYourInbox', {
        ns: 'auth',
      })}
      subHeading={t('accountSettings.codeSentDelete', { email: email })}
      onCloseBtnClick={() => {
        setShowCodeForm(false);
      }}
    >
      <CodeForm
        otpError={otpVerifyError}
        loading={loading}
        onChange={() => {
          // reset validation error on change if exists
          if (otpVerifyError) {
            setOtpVerifyError(false);
          }
        }}
        onSubmit={async (otp) => {
          setLoading(true);

          const error = await accountStore.deleteAccount(otp);

          setLoading(false);
          if (error) {
            setOtpVerifyError(Boolean(error));
          } else {
            interactiveStore.setContent(
              <DeactivateOrDeleteRedirectContent
                title={t<string>('accountSettings.accountScheduledForDeletion')}
                description={t<string>('accountSettings.accountScheduledDescr')}
              />
            );
            const url = redirectService.generateQueryUrl(
              paths.home,
              RedirectionURLQueriesEnum.SCHEDULED_ACTIONS,
              RedirectionURLActionsEnum.SUCCESS_ACCOUNT_DELETION
            );

            router.push(url);
          }
        }}
      />
    </CodeFormWrapper>
  );

  useEffect(() => {
    accountSettingsStore.setBottomSheetConfig({
      onClose: () => {
        accountStore.setShowCodeForm(false);
      },
      content: formContent,
    });

    return () => {
      accountSettingsStore.setBottomSheetConfig({
        content: null,
      });
    };
  }, []);

  return (
    <div className="themed-text-secondary flex flex-col space-y-[8px] text-body16R">
      <b className="themed-text text-body16SB">{`@${username}`}</b>
      <p>{t('accountSettings.accountWillBeDeactivated')}</p>
      <p>{t('accountSettings.permanentDeleteDescr')}</p>
      <p>{t('accountSettings.ifYouDeleteAccount')}</p>
      <ul className="ml-[18px] list-disc">
        <li>{t('accountSettings.deleteAccountWarn1')}</li>
        <li>{t('accountSettings.deleteAccountWarn2')}</li>
        <li>{t('accountSettings.deleteAccountWarn3')}</li>
        <li>{t('accountSettings.deleteAccountWarn4')}</li>
      </ul>
      <p>{t('accountSettings.doYouWantToContinue')}</p>
      {!isMobile && <Modal open={showCodeForm}>{formContent}</Modal>}
    </div>
  );
}

export const DeleteFinalContent = observer(_DeleteFinalContent);
