import {
    RedirectionURLActionsEnum,
    RedirectionURLQueriesEnum,
} from '@mainApp/src/services';
import { reaction } from 'mobx';
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IOC_TOKENS, useMultipleInjection } from "../ioc";

export function useHomeRedirectHandler() {
    const router = useRouter();
    const { t } = useTranslation('home');
    const { redirectService, interactiveStore, dimensionsStore } = useMultipleInjection([
        IOC_TOKENS.redirectService,
        IOC_TOKENS.interactiveStore,
        IOC_TOKENS.dimensionsStore
    ]);
    function proceedQuery() {
        const query = router.query;

        if (!query) return;

        const scheduledActions = query[RedirectionURLQueriesEnum.SCHEDULED_ACTIONS];

        if (
            redirectService.isActionExisting(scheduledActions, RedirectionURLActionsEnum.SUCCESS_ACCOUNT_DEACTIVATION)
            ||
            redirectService.isActionExisting(scheduledActions, RedirectionURLActionsEnum.SUCCESS_ACCOUNT_DELETION)
        ) {
            interactiveStore.setInteractiveElementOpen(true);
            // Must be Home
            redirectService.refineURL(t('label'));
        }
    }

    useEffect(() => {
        let disposer: any;

        if (!dimensionsStore.isReady && !disposer) {
            disposer = reaction(() => {
                return dimensionsStore.isReady;
            }, (isReady: boolean) => {
                if (!isReady) return;
                proceedQuery();
            })
        } else {
            proceedQuery();
        }


        return () => {
            disposer?.();
        }

    }, [router.query, dimensionsStore.isReady]);

}