export const EmailSVG: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 21, height = 18, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M0 5.6691V14.25C0 15.9069 1.34315 17.25 3 17.25H18C19.6569 17.25 21 15.9069 21 14.25V5.6691L12.0723 11.1631C11.1081 11.7564 9.89193 11.7564 8.92771 11.1631L0 5.6691Z"
      fill="#5F5CFF"
    />
    <path
      d="M21 3.90783V3.75C21 2.09315 19.6569 0.75 18 0.75H3C1.34315 0.75 0 2.09315 0 3.75V3.90783L9.71386 9.88558C10.196 10.1823 10.804 10.1823 11.2861 9.88558L21 3.90783Z"
      fill="#5F5CFF"
    />
  </svg>
);
