import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { User_General_Settings_Display_Preferences } from '@10x/foundation/types';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { CheckboxOption } from './CheckboxOption';
import { IDisplayStore } from './data';

const initialState = {
  auto: false,
  light: false,
  dark: false,
};

export function _Display() {
  const [state, setState] = useState({ ...initialState });

  const { accountSettingsStore, dimensionsStore, userStore } =
    useMultipleInjection([
      IOC_TOKENS.accountSettingsStore,
      IOC_TOKENS.dimensionsStore,
      IOC_TOKENS.userStore,
    ]);
  // should do it in this way because of the rare case when the user switch Dekstop layout to Mobile and moves back in stack
  const activeStore = accountSettingsStore.settingStores
    .display as IDisplayStore;
  const { title } = activeStore.activeSetting;
  const displayPreferences = userStore.generalSettings.data
    .displayPreferences as User_General_Settings_Display_Preferences;

  const generateOnChange =
    (optionName: keyof typeof initialState) => async (checked: boolean) => {
      setState({
        ...initialState,
        [optionName]: checked,
      });

      const userId = userStore?.me?.serverData?.id;
      // shouldn't happen. Just for devs
      if (!userId) throw new Error('No user id');

      userStore.updateUserGeneralSettings({
        ownerId: userStore?.me?.serverData?.id,
        displayPreferences:
          optionName as User_General_Settings_Display_Preferences,
      });
    };

  useEffect(() => {
    const stateSetting = state[displayPreferences];

    if (!stateSetting) {
      setState({
        ...initialState,
        [displayPreferences]: true,
      });
    }
  }, [displayPreferences]);

  const { t } = useTranslation('common');
  return (
    // back button true on Mobile
    <ScreenWrapper
      title={t<string>(title)}
      showBackBtn={dimensionsStore.isMobile}
    >
      <h6 className={'themed-text mb-[4px] text-body16SB'}>Display</h6>
      <p className={'themed-text-secondary mb-[12px] text-body16R'}>
        {t('accountSettings.changeTheAppearance')}
      </p>

      <div className="flex flex-col">
        <CheckboxOption
          checked={state.auto}
          title={t<string>('auto')}
          description={t<string>('accountSettings.autoThemeDescr')}
          onChange={generateOnChange('auto')}
        />
        <div className="8px" />
        <CheckboxOption
          checked={state.light}
          title={t<string>('accountSettings.lightMode')}
          description={t<string>('accountSettings.lightModeDescr')}
          onChange={generateOnChange('light')}
        />
        <div className="8px" />
        <CheckboxOption
          checked={state.dark}
          title={t<string>('accountSettings.darkMode')}
          description={t<string>('accountSettings.darkModeDescr')}
          onChange={generateOnChange('dark')}
        />
      </div>
    </ScreenWrapper>
  );
}

export const Display = withAnimatedStack(observer(_Display));
