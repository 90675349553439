import { useEffect } from 'react';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useRouter } from 'next/router';

import { paths } from '@mainApp/src/config';

export function useDefaultChannelRedirectByQuery() {
  const { query, replace } = useRouter();
  const { communityStore } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.channelStore,
  ]);
  const communitySlug = query.community_slug;

  useEffect(() => {
    if (communitySlug && typeof communitySlug === 'string') {
      communityStore.getCommunityBySlugName(communitySlug).then((community) => {
        if (!community) {
          console.error(
            `community with the slug ${communitySlug} is not found `
          );
          return null;
        }
        const defaultChannelSlug = community?.serverData.defaultChannel?.slug;

        communityStore.setActiveCommunity(community);

        if (defaultChannelSlug) {
          replace(paths.getChannelPath(communitySlug, defaultChannelSlug));
        } else {
          throw new Error(
            `Community with slug ${communitySlug} does not have a default channel`
          );
        }
      });
    }
  }, [communitySlug]);
}
