import React from 'react';

export const OrderUp: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 20, height = 20, ...otherProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M10 7.2L14 12.8H6L10 7.2Z"
        fill="currentColor"
        fill-opacity="0.7"
      />
    </svg>
  );
};
