type EventLogger<T> = (event: T, data: any) => void
export function createPubSub<EventEnum>() {
  const map = new Map<EventEnum, Array<(data: any) => void>>();
  let eventLogger: EventLogger<EventEnum> | null = null;
  return {
    emit<T = any>(event: EventEnum, data?: T) {
      map.get(event)?.forEach((handler) => handler(data));
      eventLogger?.(event, data);
    },
    on<T = any>(event: EventEnum, listener: (data?: T) => void) {
      map.set(event, [...(map.get(event) || []), listener]);
    },
    removeListener<T = any>(event: EventEnum, listener: (data?: T) => void) {
      map.set(event, map.get(event)?.filter((l) => l !== listener) || []);
    },
    setEventLogger: (logger: EventLogger<EventEnum>) => {
      eventLogger = logger;
    },
  };
}

export interface IEventBus<EventEnum> {
  emit: <T = any>(event: EventEnum, data?: T) => void;
  on: <T = any>(event: EventEnum, listener: (data?: T) => void) => void;
  removeListener: <T = any>(
    event: EventEnum,
    listener: (data?: T) => void
  ) => void;
  setEventLogger: (logger: EventLogger<EventEnum>) => void;
}
