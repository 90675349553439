export const UnionSVG: React.FC<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ width = 24, height = 25, ...otherProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="themed-svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 4.5C8.67157 4.5 8 5.17157 8 6C8 6.82843 8.67157 7.5 9.5 7.5C10.3284 7.5 11 6.82843 11 6C11 5.17157 10.3284 4.5 9.5 4.5ZM9.5 10.5C8.67157 10.5 8 11.1716 8 12C8 12.8284 8.67157 13.5 9.5 13.5C10.3284 13.5 11 12.8284 11 12C11 11.1716 10.3284 10.5 9.5 10.5ZM8 18C8 17.1716 8.67157 16.5 9.5 16.5C10.3284 16.5 11 17.1716 11 18C11 18.8284 10.3284 19.5 9.5 19.5C8.67157 19.5 8 18.8284 8 18ZM15.5 4.5C14.6716 4.5 14 5.17157 14 6C14 6.82843 14.6716 7.5 15.5 7.5C16.3284 7.5 17 6.82843 17 6C17 5.17157 16.3284 4.5 15.5 4.5ZM14 12C14 11.1716 14.6716 10.5 15.5 10.5C16.3284 10.5 17 11.1716 17 12C17 12.8284 16.3284 13.5 15.5 13.5C14.6716 13.5 14 12.8284 14 12ZM15.5 16.5C14.6716 16.5 14 17.1716 14 18C14 18.8284 14.6716 19.5 15.5 19.5C16.3284 19.5 17 18.8284 17 18C17 17.1716 16.3284 16.5 15.5 16.5Z"
        fill="#B4B4B4"
      />
    </svg>
  );
};
