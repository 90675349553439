type RuleTitleProps = {
  title: string;
  description: string;
};

export const RuleTitle = ({ title, description }: RuleTitleProps) => {
  return (
    <div className="mb-[8px] px-[12px]">
      <h2 className="themed-text mb-[4px] text-miniHead">{title}</h2>
      <p className="themed-text-secondary text-body16R">{description}</p>
    </div>
  );
};
