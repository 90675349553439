import { User } from '@10x/foundation/types';
import {
  UserProfileCard,
  UserProfileTooltipWrapper,
} from '@mainApp/src/components/common/user-profile-card';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { EditUserContent } from '../edit-user';

export type MessageUserPanelPropsType = {
  username: string;
  avatarUrl?: string;
  date: string;
  justJoined?: boolean;
  author?: Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'bio'
    | 'website'
    | 'imageUrls'
  > | null;
};
export function _MessageUserPanel(props: MessageUserPanelPropsType) {
  const { username, date, justJoined, avatarUrl, author } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const {
    systemStore,
    userStore,
    dimensionsStore,
    interactiveStore,
    accountSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.accountSettingsStore,
  ]);

  const ownUser = React.useMemo(() => {
    return userStore.me?.serverData?.id === author?.id;
  }, [userStore.me, author]);

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  const cardProfileContent = (
    <UserProfileCard
      onClose={handleCloseTooltip}
      onCtaClick={() => {
        interactiveStore.setContent(<EditUserContent key="MessageUserPanel" />);
        interactiveStore.setModal(true);
      }}
      onClickOption={(option) => alert(option)}
      darkMode={systemStore.isDarkTheme}
      user={
        author
          ? {
              ...author,
              avatarUrl,
            }
          : undefined
      }
      onSettings={() => {
        accountSettingsStore.setShow(true);
      }}
      ownProfile={ownUser}
    />
  );

  return (
    <div className="flex items-center">
      {dimensionsStore.isMobile ? (
        <span
          onClick={() => {
            interactiveStore.setContent(cardProfileContent);
            interactiveStore.setBottomSheet(true);
          }}
          role="button"
          className="cursor-pointer text-body16SB text-text-primary dark:text-text-primary-dark"
        >
          {username}
        </span>
      ) : (
        <UserProfileTooltipWrapper
          open={tooltipOpen}
          onClose={handleCloseTooltip}
          onOpen={() => setTooltipOpen(true)}
          triggerEl={
            <span
              role="button"
              className="cursor-pointer text-body16SB text-text-primary dark:text-text-primary-dark"
            >
              {username}
            </span>
          }
        >
          {cardProfileContent}
        </UserProfileTooltipWrapper>
      )}

      {justJoined && (
        <span
          className="ml-[4px] text-body16R text-text-placeholder dark:text-text-placeholder-dark
      "
        >
          just joined!
        </span>
      )}
      <span className="mx-[4px] flex items-center text-text-placeholder dark:text-text-placeholder-dark">
        •
      </span>
      <span className="text-sm14T text-text-placeholder dark:text-text-placeholder-dark">
        {date}
      </span>
    </div>
  );
}

export const MessageUserPanel = observer(_MessageUserPanel);
