import { classNames } from '@foundationPathAlias/utilities';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { validators } from '@foundationPathAlias/components';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { Input } from '@mainApp/src/components/post-auth-onboarding/common/Input';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';

type Props = {
  titleCn?: string;
  descriptionCn?: string;
};

export function _DisplayName(props: Props) {
  const { titleCn, descriptionCn } = props;

  const { t } = useTranslation('common');
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);

  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  const { title } = accountStore.activeSetting;
  const displayData = accountStore.data[SettingsDataNamesEnum.DISPLAY_NAME];
  const displayNameWord = t('displayName');
  const displayNameWorkLc = displayNameWord.toLowerCase();
  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {t('displayName')}
      </h6>
      <p
        className={classNames(
          'themed-text-secondary mb-[16px] text-body16R',
          descriptionCn
        )}
      >
        {t('accountSettings.displayNameDescr')}
      </p>
      <Input
        maxLength={16}
        inputName={displayNameWord}
        placeholder={displayNameWord}
        specificValidators={[
          validators.getBeginSpaceValidator(
            t('errors.beginSpace', {
              fieldName: displayNameWorkLc,
            }) as string
          ),
          validators.getDoubleConsequentSpacesValidator(
            t('errors.doubleConsequentSpaces', {
              fieldName: displayNameWorkLc,
            }) as string
          ),
        ]}
        outerError={null}
        successMsg={''}
        value={displayData.data}
        onChange={(val) => {
          accountStore.setDisplayName(val);
        }}
        onError={(error) => {
          const existingError =
            accountStore.data[SettingsDataNamesEnum.USERNAME].error;
          if (existingError !== error) {
            accountStore.setSettingValue(
              SettingsDataNamesEnum.DISPLAY_NAME,
              displayData.data,
              error
            );
          }
        }}
      />
    </ScreenWrapper>
  );
}

export const DisplayName = withAnimatedStack(observer(_DisplayName));
