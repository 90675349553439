import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FooterModal } from './FooterModal';
import { UsersList } from './UsersList';

const _MembersView = () => {
  const { t } = useTranslation(['channel']);

  const {
    dimensionsStore: { isMobile },
    createChannelStore: { setStepScreen, selectedRole },
  } = useMultipleInjection([
    IOC_TOKENS.createChannelStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const handleBack = () => {
    setStepScreen('addMembers');
  };

  return (
    <>
      <div className="flex flex-[1] flex-col">
        <div className="px-4 py-2 md:min-h-[430px]">
          <UsersList
            list={selectedRole?.parsedRoleMembers ?? []}
            onScrollEnd={() => {
              selectedRole?.loadMoreMembers();
            }}
            infinityScroll
            loading={selectedRole?.roleMembers.loading}
            actionType="none"
          />
        </div>

        {!isMobile && (
          <FooterModal
            onCancel={handleBack}
            cancelText={t<string>('common:back')}
            className="mt-auto md:mt-0"
          />
        )}
      </div>
    </>
  );
};

export const MembersView = observer(_MembersView);
