import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

const CornerGradient = ({
  size = 200,
  color,
}: {
  size?: number;
  color: string;
}) => {
  const {
    systemStore: { isDarkTheme },
  } = useMultipleInjection([IOC_TOKENS.systemStore]);
  let color2 = 'rgba(255, 255, 255, .8)';
  let color3 = 'rgba(255, 255, 255, 0)';
  if (isDarkTheme) {
    color2 = 'rgba(8, 25, 40, .8)';
    color3 = 'rgba(8, 25, 40, 0)';
  }
  return (
    <div className="relative" style={{ width: size, height: size }}>
      <div
        className="absolute inset-0 rounded-full"
        style={{
          background: `radial-gradient(circle, ${color} 0%, ${color2} 60%, ${color3} 70%)`,
        }}
      />
    </div>
  );
};

export default CornerGradient;
