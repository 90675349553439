import { ArrowDownIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { AddedItemsType } from '../Pricing';
import { PLAN_DETAILS } from '../section-2/data';

function _PlanCard({
  highlight = false,
  addedItems,
  setAddedItems,
}: {
  highlight?: boolean;
  setAddedItems: (items: AddedItemsType) => void;
  addedItems: AddedItemsType;
}) {
  const plan = highlight ? PLAN_DETAILS.highlighted : PLAN_DETAILS.free;

  return (
    <div className="flex-shrink-0">
      <div className={plan.styles.outerCard}>
        <div className="absolute left-0 right-0 top-[15px] text-center">
          <span className="text-body16SB text-background-primary">
            {plan.offer}
            {highlight && (
              <ArrowDownIcon className="background-primary mb-1 ml-1 inline-block h-4 w-4 align-middle" />
            )}
          </span>
        </div>
        <div className={plan.styles.innerCard}>
          <div className="flex flex-grow flex-col">
            <span className="themed-text mb-[8px] text-miniHead">
              {plan.title}
            </span>
            <p className="mb-[12px] text-sm14R">{plan.description}</p>
            <div className="themed-text mb-[32px] text-subHeadL">
              {plan.priceText}
            </div>
            <button
              onClick={() => {
                if (setAddedItems && addedItems) {
                  if (plan.title === PLAN_DETAILS.highlighted.title) {
                    setAddedItems({
                      basePlan: {
                        title: plan.title,
                        price: plan.price,
                        features: plan.features,
                        allowQuantity: false,
                      },
                      aLaCarte: [...(addedItems.aLaCarte || [])],
                    });
                  } else if (
                    addedItems.aLaCarte &&
                    addedItems.aLaCarte.length === 0
                  ) {
                    setAddedItems({ basePlan: null, aLaCarte: null });
                  }
                }
              }}
              className={plan.styles.button}
            >
              {addedItems.basePlan?.title === plan.title ||
              (!addedItems.basePlan && !highlight)
                ? 'Current plan'
                : 'Choose this plan'}
            </button>
            <div className="mt-[32px] flex-grow">
              <h3 className="themed-text mb-[16px] text-sm14SB">Includes</h3>
              <ul className="space-y-[16px]">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <CheckIcon height={16} width={16} fontSize={14} />
                    <span className="themed-text ml-2 text-sm14R">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const PlanCard = observer(_PlanCard);
