import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { useEffect, useRef } from 'react';
import { ChannelProvider } from './common/channelContext';
import { ChannelPopover } from './common/popover/ChannelPopover';

import { GET_CHANNEL_MESSAGES_SUBSCRIPTION } from '@mainApp/src/graphql/queries';

import { executeFnWhenParamChangedOnly } from '@10x/foundation/src/utilities';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';

import { WorkingArea } from './WorkingArea';

import { useSubscription } from 'urql';
import { CreateChannelModal } from './create-channel-modal';
import { useDeleteMessageModalUI } from './delete-message-modal';

import { Channel_Types } from '@10x/foundation/types';
import { SidebarModalProvider } from '@foundationPathAlias/widgets/sidebar-modal';
import { CAPModal } from '@mainApp/src/modules/customer-admin-panel/CAPModal';
import { ChannelSettings } from './channel-settings/ChannelSettings';
import { Rules } from './rules';
import { ScreenRulesProvider } from './rules/ScreenRules.context';

export function _Channel() {
  const { channelStore, messageStore, communityStore, attachmentsMediator } =
    useMultipleInjection([
      IOC_TOKENS.channelStore,
      IOC_TOKENS.messageStore,
      IOC_TOKENS.communityStore,
      IOC_TOKENS.attachmentsMediator,
    ]);
  const {
    query: { channel_slug },
  } = useRouter();

  const isRulesChannel =
    channelStore.activeChannel.data?.serverData?.channelType ===
    Channel_Types.Rules;

  useDeleteMessageModalUI();

  const channelId = channelStore.activeChannel?.data?.serverData?.id;
  const communityId = channelStore.activeChannel?.data?.serverData?.communityId;

  const fetchMessagesWhenArgsChange = useRef(
    executeFnWhenParamChangedOnly(
      (communityId: string, channelSlug: string) => {
        // console.log('>>> FETCH MESSAGE WHEN ARGS CHANGE <<< ');
        // clears in message store before the new channel load
        messageStore.dispose();
        fetchMessages(communityId, channelSlug);
      }
    )
  ).current;

  useEffect(() => {
    /** TODO: maybe rename .data to .model ? for sanity
     because if I add to model autocollect all props from res they will be in the serverData. OR should I remove the serverData and assign all props 
     from res automatically to the model this context
    */
    const communityId = communityStore.activeCommunity.data?.serverData.id;
    if (communityId && channel_slug) {
      fetchMessagesWhenArgsChange(communityId, channel_slug as string);
    }
  }, [communityStore.activeCommunity, channel_slug]);

  useEffect(() => {
    // subscribe for every class mount after the proper memory dispose
    attachmentsMediator.setupEventListeners();
    return () => {
      attachmentsMediator.dispose();
      // just clear everything on leaving the channel
      messageStore.dispose();
    };
  }, []);

  useSubscription({
    query: GET_CHANNEL_MESSAGES_SUBSCRIPTION,
    variables: {
      channelId,
      communityId,
    },
    pause: !communityId && !channelId,
  });

  async function fetchMessages(communityId: string, channelSlug: string) {
    const activeChannel: ChannelModel = (await channelStore.getChannelBySlug(
      communityId,
      channelSlug
    )) as any;

    const previousVisitedData =
      messageStore.visitedChannelsReigstryService.tryToGetChannelData(
        activeChannel.serverData.id
      );

    messageStore.getChannelMessages(
      communityId,
      // temporary to simulate the forward unread messages
      activeChannel.serverData.id,
      {
        // @ts-ignore
        jumpTo: previousVisitedData?.visibleMessageCursor
          ? previousVisitedData?.visibleMessageCursor
          : undefined,
        before: '',
        last: messageStore.messagesChunkAmount,
      }
    );
  }

  return (
    <ChannelProvider>
      {isRulesChannel ? (
        <ScreenRulesProvider>
          <Rules />
        </ScreenRulesProvider>
      ) : (
        <WorkingArea />
      )}

      <ChannelPopover />

      <SidebarModalProvider>
        <CAPModal />
      </SidebarModalProvider>

      <CreateChannelModal />
      <SidebarModalProvider>
        <ChannelSettings />
      </SidebarModalProvider>
    </ChannelProvider>
  );
}

export const Channel = observer(_Channel);
