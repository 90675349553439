import { useEffect, useRef, useState } from 'react';

import _merge from 'lodash/merge';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { EditorState } from 'lexical';

import { CAN_USE_DOM, classNames } from '@foundationPathAlias/utilities';

import { MentionsChannelPlugin } from './mentions/MentionsChannelPlugin';
import { MentionsUserPlugin } from './mentions/MentionsUserPlugin';

import { CodeHighlightPlugin } from './CodeHighlightPlugin';
import { FloatingLinkEditorPlugin } from './FloatingLinkEditorPlugin';
import { LinkPlugin } from './LinkPlugin';
import { RestoreStatePlugin } from './RestoreStatePlugin';
import { ToolbarPlugin } from './ToolbarPlugin';
import { FloatingSuggestionsPlugin } from './mentions/FloatingSuggestionsPlugin';
import { MentionChannelNode } from './mentions/MentionChannelNode';
import { MentionUserNode } from './mentions/MentionUserNode';
import { DropupTypeEnum, SuggestionOptionData } from './mentions/types';
import { theme } from './theme';

import { RolesEnum } from '@10x/foundation/types';

const initialConfig = {
  namespace: 'RichEditor',
  theme,
  nodes: [
    HeadingNode,
    AutoLinkNode,
    LinkNode,
    ListNode,
    ListItemNode,
    MentionChannelNode,
    MentionUserNode,
    CodeNode,
    CodeHighlightNode,
    QuoteNode,
  ],
};

export type PickMentionsCb = (result: SuggestionOptionData) => void;
type MentionSuggestionData = {
  query: string;
  selectOptionAndCleanUp: null | ((optionData: any) => void);
  type: DropupTypeEnum;
};

type Props = {
  onChange: (editorState: EditorState) => void;
  onError?: (error: Error) => void;
  predefinedStateJsonStr?: string;
  containerCn?: string;
  mentionsProvider: (
    type: DropupTypeEnum,
    query: string,
    pickMentionCb: PickMentionsCb
  ) => JSX.Element;
};

export function RichEditor(props: Props) {
  const {
    onChange,
    onError,
    predefinedStateJsonStr,
    mentionsProvider,
    containerCn,
  } = props;
  const [config, setConfig] = useState<InitialConfigType | null>(null);

  useEffect(() => {
    const mergedConfig = _merge(initialConfig, {
      onError,
    });

    setConfig(mergedConfig as InitialConfigType);
  }, [onError]);

  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);

  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  // currently it's the root editor el
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const [isShowMentionSuggestions, setIsShowMentionSuggestions] =
    useState(false);

  const mentionSuggestionDataRef = useRef<MentionSuggestionData>({
    query: '',
    selectOptionAndCleanUp: null,
    type: DropupTypeEnum.EMPTY,
  });

  const floatingEditorElRef = useRef<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      floatingEditorElRef.current = _floatingAnchorElem;
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  if (!config) return null;

  const isShowSuggestions =
    floatingAnchorElem &&
    !isSmallWidthViewport &&
    mentionSuggestionDataRef.current.query;

  return (
    <div
      className={classNames(
        'relative h-full w-full rounded-md border border-element-subtle dark:border-element-subtle-dark',
        containerCn
      )}
    >
      <LexicalComposer initialConfig={config}>
        <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
        <RichTextPlugin
          contentEditable={
            <div className="editor-scroller">
              <div className="editor" ref={onRef}>
                <ContentEditable className="ContentEditable__root" />
              </div>
            </div>
          }
          // should use if needed the real placeholder
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />

        <MentionsUserPlugin
          type={RolesEnum.Member}
          onMentionCheck={(isFoundMention) => {
            const mentionData = mentionSuggestionDataRef.current;
            if (isFoundMention) {
              if (mentionData.type !== DropupTypeEnum.SUGGESTION_USERS) {
                setIsShowMentionSuggestions(true);
                mentionSuggestionDataRef.current = {
                  ...mentionData,
                  type: DropupTypeEnum.SUGGESTION_USERS,
                };
              }
            } else {
              if (mentionData.type === DropupTypeEnum.SUGGESTION_USERS) {
                setIsShowMentionSuggestions(false);
                mentionSuggestionDataRef.current = {
                  ...mentionData,
                  query: '',
                  type: DropupTypeEnum.EMPTY,
                };
              }
            }
          }}
          onMentionParse={(selectOptionAndCleanUp) => {
            const cb = mentionSuggestionDataRef.current.selectOptionAndCleanUp;

            if (cb !== selectOptionAndCleanUp) {
              mentionSuggestionDataRef.current.selectOptionAndCleanUp =
                selectOptionAndCleanUp;
            }

            // messageEditorStore.setSelectOptionAndCleanUp(
            //   selectOptionAndCleanUp
            // );
          }}
          onQueryChange={(query) => {
            const mentData = mentionSuggestionDataRef.current;
            const { type } = mentData;

            // if the dropup won't be shown so it will be EMPTY. Set state finishes later than this method trigger so that's why I should use the condition based on the ref which is faster than the setState
            if (type === DropupTypeEnum.SUGGESTION_USERS) {
              // TODO: Experiment
              mentionSuggestionDataRef.current = {
                ...mentData,
                query,
              };
            }
          }}
        />

        <MentionsChannelPlugin
          // TODO: maybe use only this one? I definitely need it
          // and onMentionParse works only on success
          onMentionCheck={(isFoundMention) => {
            const mentionData = mentionSuggestionDataRef.current;
            if (isFoundMention) {
              if (mentionData.type !== DropupTypeEnum.SUGGESTION_CHANNELS) {
                setIsShowMentionSuggestions(true);
                mentionSuggestionDataRef.current = {
                  ...mentionData,
                  type: DropupTypeEnum.SUGGESTION_CHANNELS,
                };
              }
            } else {
              if (mentionData.type === DropupTypeEnum.SUGGESTION_CHANNELS) {
                setIsShowMentionSuggestions(false);
                mentionSuggestionDataRef.current = {
                  ...mentionData,
                  query: '',
                  type: DropupTypeEnum.EMPTY,
                };
              }
            }
          }}
          onMentionParse={(selectOptionAndCleanUp) => {
            const cb = mentionSuggestionDataRef.current.selectOptionAndCleanUp;

            if (cb !== selectOptionAndCleanUp) {
              mentionSuggestionDataRef.current.selectOptionAndCleanUp =
                selectOptionAndCleanUp;
            }
          }}
          onQueryChange={(query) => {
            const mentData = mentionSuggestionDataRef.current;
            const { type } = mentData;

            // if the dropup won't be shown so it will be EMPTY. Set state finishes later than this method trigger so that's why I should use the condition based on the ref which is faster than the setState
            if (type === DropupTypeEnum.SUGGESTION_CHANNELS) {
              // TODO: Experiment
              mentionSuggestionDataRef.current = {
                ...mentData,
                query,
              };
            }
          }}
        />

        <HistoryPlugin />
        <CodeHighlightPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <ListPlugin />
        <LinkPlugin />
        {/* lexical throws ts error if some component returns null. It must be a string */}
        {floatingAnchorElem && !isSmallWidthViewport ? (
          <FloatingLinkEditorPlugin
            anchorElem={floatingAnchorElem}
            isLinkEditMode={isLinkEditMode}
            setIsLinkEditMode={setIsLinkEditMode}
          />
        ) : (
          ''
        )}
        {floatingAnchorElem &&
        !isSmallWidthViewport &&
        mentionSuggestionDataRef.current.query ? (
          <FloatingSuggestionsPlugin
            anchorElem={floatingAnchorElem}
            show={isShowMentionSuggestions}
            // triggers on ESC close etc.
            onPopupClose={() => {
              setIsShowMentionSuggestions(false);
            }}
          >
            {mentionsProvider(
              mentionSuggestionDataRef.current.type,
              mentionSuggestionDataRef.current.query,
              (result) => {
                setIsShowMentionSuggestions(false);
                mentionSuggestionDataRef.current.selectOptionAndCleanUp?.(
                  result
                );
              }
            )}
          </FloatingSuggestionsPlugin>
        ) : (
          ''
        )}
        <RestoreStatePlugin
          predefinedEditorStateJsonString={predefinedStateJsonStr}
        />
        <OnChangePlugin onChange={onChange} />
      </LexicalComposer>
    </div>
  );
}
