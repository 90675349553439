import { PrimaryButton } from '@foundationPathAlias/main';
import { useTranslation } from 'react-i18next';

export const UsersTableNoMembers = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[109px]">
      <b className="themed-text-primary mb-[4px] text-body18SB">
        {t('cap:users.noOneSeemsToBeHere')}
      </b>
      <p className="themed-text-secondary text-body16R">
        {t('cap:users.startInvitingPeople')}
      </p>

      <PrimaryButton cn="rounded-full w-auto mt-[24px]" onClick={() => {}}>
        {t('cap:users.invitePeople')}
      </PrimaryButton>
    </div>
  );
};
