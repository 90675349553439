// Import Swiper React components

import { YourCommunityCard } from '../shared/community-card/YourCommunityCard';

export function CommunityPreviewDesktop() {
  return (
    <div className="flex max-w-[584px] items-start">
      <div className="grid min-w-0">
        <div className="flex ">
          <YourCommunityCard />
        </div>
      </div>
    </div>
  );
}
