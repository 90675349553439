// @ts-nocheck
/* eslint-disable */
import { useEffect, useRef, useState } from 'react';

type MutationObserverConfigType = {
  attributes: boolean;
  childList: boolean;
  subtree: boolean;
};

type MutationObserverOptionsType = {
  config: MutationObserverConfigType;
  debounceTime: number;
  throttleTime: number;
};

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
  debounceTime: 0,
  throttleTime: 0,
};

export function useResizeObserver(
  targetEl: HTMLElement,
  resizeCb,
  options: MutationObserverOptionsType = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState(null);

  const onResizeRef = useRef(undefined);
  onResizeRef.current = resizeCb;

  const cb = onResizeRef.current;

  useEffect(() => {
    if (!cb || typeof cb !== 'function') {
      console.warn(
        `You must provide a valid callback function, instead you've provided ${cb}`
      );
      return;
    }
    const obs = new ResizeObserver(cb);
    setObserver(obs);
  }, [cb, options, setObserver]);

  useEffect(() => {
    if (!observer) return;
    if (!targetEl) {
      console.warn(
        `You must provide a valid DOM element to observe, instead you've provided ${targetEl}`
      );
    }
    const { config } = options;
    try {
      observer.observe(targetEl, config);
    } catch (e) {
      console.error(e);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
}
