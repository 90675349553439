'use client';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import type { IPlatformRule } from '@mainApp/src/stores/Rules.store';
import { extractPlainText } from '@mainApp/src/utils/lexical';

type PlatformRuleItemProps = {
  onClick: (rule: IPlatformRule) => void;
  rule: IPlatformRule;
};

export const PlatformRuleItem = ({ rule, onClick }: PlatformRuleItemProps) => {
  const { rawJSON, title } = rule;

  return (
    <div
      role="button"
      className="flex w-full items-center justify-between gap-4 rounded-md py-4 transition-colors hover:bg-background-secondary dark:hover:bg-background-secondary-dark md:px-4 "
      onClick={() => onClick({ ...rule, baseType: 'platform' })}
    >
      <div className="flex flex-col items-start">
        <b className="text-body16SB text-text-primary dark:text-text-primary-dark">
          {title}
        </b>
        <span className="line-clamp-1 text-ellipsis text-start text-sm14R text-text-secondary dark:text-text-secondary-dark">
          {extractPlainText(rawJSON)}
        </span>
      </div>

      <ChevronRightIcon
        height={20}
        width={20}
        className="flex-shrink-0 text-text-primary dark:text-text-primary-dark"
      />
    </div>
  );
};
