import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { HeaderModal } from './HeaderModal';

import {
  Cropper,
  CropperOrientationEnum,
  PrimaryButton,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import _debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditUserContent } from '.';
import { ActionButtons } from './ActionButtons';

type UserCropImageProps = {
  file: File;
  onCropDone: () => void;
};

const _UserCropImage = ({ file, onCropDone }: UserCropImageProps) => {
  const {
    interactiveStore,
    dimensionsStore: { isMobile },
    userStore,
  } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.userStore,
  ]);
  const { t } = useTranslation(['channel']);
  const [croppedFile, setCroppedFile] = React.useState(file);

  const src = React.useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  const debouncedOnCrop = React.useRef(
    _debounce((croppedCanvas) => {
      croppedCanvas.toBlob((blob: any) => {
        setCroppedFile(
          new File([blob], file.name, {
            type: blob.type,
          })
        );
      });
    }, 300)
  ).current;

  const handleBack = () => {
    if (isMobile) {
      interactiveStore.setContent(
        <EditUserContent key="UserCropImage_Mobile" />
      );
    } else {
      interactiveStore.resetContent();
      interactiveStore.setModal(false);
    }
  };

  return (
    <div
      className={classNames(
        'bg-background-primary dark:bg-background-primary-dark',
        isMobile ? 'flex h-screen w-full flex-col' : 'w-[480px]'
      )}
    >
      <HeaderModal
        label={t('editProfileModal.cropImage')}
        buttonType="chevron"
        onCancel={handleBack}
      />

      <div className={classNames(!isMobile && 'p-6')}>
        {src && (
          <div className="flex flex-1 justify-center">
            <Cropper
              src={src}
              onCrop={debouncedOnCrop}
              width={'100%'}
              height={isMobile ? undefined : 434}
              minCropBoxHeight={311}
              minCropBoxWidth={311}
              cropBoxOrientation={CropperOrientationEnum.Circle}
              renderRangeSlider={(rangeSlider) => {
                return (
                  <div className="px-4">
                    <p className="mb-[16px] mt-[24px] text-center text-body16SB text-text-primary dark:text-text-primary-dark">
                      {t('editProfileModal.dragToFitImage')}
                    </p>
                    {rangeSlider}
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>

      {isMobile ? (
        <div className="mt-auto flex justify-center py-7">
          <PrimaryButton
            cn="w-auto rounded-full"
            disabled={userStore.isUploading}
            onClick={() => {
              userStore.updateAvatarUser(croppedFile).then(onCropDone);
            }}
          >
            {t('editProfileModal.upload')}
          </PrimaryButton>
        </div>
      ) : (
        <ActionButtons
          onClose={() => {
            interactiveStore.setContent(
              <EditUserContent key="UserCropImage_Desktop" />
            );
          }}
          isSubmitting={userStore.isUploading}
          onSubmit={() => {
            userStore.updateAvatarUser(croppedFile).then(onCropDone);
          }}
          submitLabel={t('editProfileModal.upload')}
        />
      )}
    </div>
  );
};

export const UserCropImage = observer(_UserCropImage);
