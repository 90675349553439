import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';

import { classNames } from '@foundationPathAlias/utilities';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { RestoreStatePlugin } from './RestoreStatePlugin';
import { MentionChannelNode } from './mentions/MentionChannelNode';
import { MentionUserNode } from './mentions/MentionUserNode';
import { theme } from './theme';

const initialConfig = {
  namespace: 'RichViewer',
  theme,
  nodes: [
    HeadingNode,
    AutoLinkNode,
    LinkNode,
    ListNode,
    ListItemNode,
    MentionChannelNode,
    MentionUserNode,
    CodeNode,
    CodeHighlightNode,
    QuoteNode,
  ],
  readOnly: true,
  editorState: null,
} as unknown as InitialConfigType;

type RichViewerProps = {
  predefinedEditorStateJsonString: string;
};

export const RichViewer = ({
  predefinedEditorStateJsonString,
}: RichViewerProps) => {
  const config = initialConfig;

  return (
    <div className="relative h-full w-full">
      <LexicalComposer initialConfig={config}>
        <ContentEditable
          className={classNames('readOnlyEditor', 'text-start')}
        />

        <RestoreStatePlugin
          predefinedEditorStateJsonString={predefinedEditorStateJsonString}
        />
      </LexicalComposer>
    </div>
  );
};
