import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { IOC_TOKENS } from '@mainApp/src/ioc';
import type { IDimensionsStore } from '@mainApp/src/stores';
import { inject, injectable } from 'inversify';
import { ScreenIdsValuesType, screensConfig } from './screensConfig';

import { SidebarModalController } from '@foundationPathAlias/widgets/sidebar-modal';
import { childScreensRegistry, ScreenIds } from '../constants';

import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChildScreenClasses, IChannelSettingsStore } from './types';

enableStaticRendering(typeof window === 'undefined');

@injectable()
export class ChannelSettingsStore
  extends SidebarModalController<ChildScreenClasses, ScreenIdsValuesType>
  implements IChannelSettingsStore
{
  isMobile = false;

  autorunDisposer: IReactionDisposer;
  channelModel: ChannelModel | null = null;

  constructor(
    @inject(IOC_TOKENS.dimensionsStore) dimensionStore: IDimensionsStore
  ) {
    super({
      childScreenClasses: childScreensRegistry,
      screensConfig,
      screenIds: ScreenIds,
      initialChildScreenId: ScreenIds.OVERVIEW,
    });

    makeObservable(this, {
      isMobile: observable,
      activeScreenId: observable,
      setActiveScreenId: action,
    });

    this.autorunDisposer = autorun(() => {
      this.isMobile = dimensionStore.isMobile;
    });
  }

  setChannelModel = (channelModel: ChannelModel) => {
    this.channelModel = channelModel;
    this.activeChildScreen.setChannelModel(channelModel);
  };

  dispose() {
    this.autorunDisposer();
  }
}
