import { useCallback, useMemo, useReducer } from 'react';
import { animatedStackReducer, initialState } from './animatedStackReducer';
import {
  ACTIONS,
  AnimatedStack,
  animatedStackEventBus,
  AnimatedStackEvents,
  emitEvent,
  StackItem,
} from './data';

export function useAnimatedStack(): AnimatedStack {
  const [state, dispatch] = useReducer(animatedStackReducer, initialState);

  const canMoveBack = useMemo(() => {
    return Boolean(state.prevItem.id) && !state.startLeaving;
  }, [state.prevItem.id, state.startLeaving]);

  const isAnimationRunning = useMemo(() => {
    return state.startEntering || state.startLeaving;
  }, [state.startEntering, state.startLeaving]);

  const reset = useCallback(() => {
    dispatch({ type: ACTIONS.RESET });
    emitEvent<void>(AnimatedStackEvents[ACTIONS.RESET]);
  }, []);

  const setPrevItem = useCallback(
    (id: string, Component: React.ComponentType<any>) => {
      dispatch({ type: ACTIONS.SET_PREV_ITEM, payload: { id, Component } });
      emitEvent<StackItem>(AnimatedStackEvents[ACTIONS.SET_PREV_ITEM], {
        id,
        Component,
      });
    },
    []
  );

  const setVisible = useCallback(
    (id: string, Component: React.ComponentType<any>) => {
      dispatch({
        type: ACTIONS.SET_VISIBLE,
        payload: { id, Component },
      });
      emitEvent<StackItem>(AnimatedStackEvents[ACTIONS.SET_VISIBLE], {
        id,
        Component,
      });
    },
    []
  );

  const next = useCallback(
    (id: string, Component: React.ComponentType<any>) => {
      dispatch({ type: ACTIONS.NEXT, payload: { id, Component } });
      emitEvent<StackItem>(AnimatedStackEvents[ACTIONS.NEXT], {
        id,
        Component,
      });
    },
    []
  );

  const back = useCallback(() => {
    return new Promise<string | undefined>((resolve) => {
      dispatch({
        type: ACTIONS.BACK,
        payload: {
          callback: (prevItemId?: string) => {
            resolve(prevItemId);
            emitEvent<string>(AnimatedStackEvents[ACTIONS.BACK], prevItemId);
          },
        },
      });
    });
  }, []);

  const onLeavingAnimationEnd = useCallback(() => {
    dispatch({ type: ACTIONS.ON_LEAVING_ANIMATION_END });
    emitEvent<void>(AnimatedStackEvents[ACTIONS.ON_LEAVING_ANIMATION_END]);
  }, []);

  const onEnterAnimationEnd = useCallback(() => {
    dispatch({ type: ACTIONS.ON_ENTER_ANIMATION_END });
    emitEvent<void>(AnimatedStackEvents[ACTIONS.ON_ENTER_ANIMATION_END]);
  }, []);

  return useMemo(
    () => ({
      state,
      animatedStackEventBus,
      canMoveBack,
      isAnimationRunning,
      reset,
      setPrevItem,
      setVisible,
      next,
      back,
      onLeavingAnimationEnd,
      onEnterAnimationEnd,
    }),
    [
      state,
      reset,
      setPrevItem,
      setVisible,
      next,
      back,
      onLeavingAnimationEnd,
      onEnterAnimationEnd,
    ]
  );
}
