import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import { TooltipMenuItem } from '../tooltip-menu-item/TooltipMenuItem';

type TooltipMenuProps = PopupProps;

export const TooltipMenu = ({
  position = ['top center', 'bottom center'],
  ...props
}: TooltipMenuProps) => <Popup {...props} position={position} />;

TooltipMenu.Item = TooltipMenuItem;
