

export type VisitedChannelDataType = {
    // the cursor of the message at the middle of the message list. Need to save it for the loading the correct message when moving back to the previously visited channel
    visibleMessageCursor: string;
    visibleMessageId: string;
}

export type VisitedChannelRegistryType = {
    [channelId: string]: VisitedChannelDataType
}

export class VisitedChannelsDataRegistryService {
    registry: VisitedChannelRegistryType = {}

    setChannelData = (channelId: string, data: VisitedChannelDataType) => {
        if (!channelId) {
            throw new Error('No channel ID');
        }

        if (!data) {
            throw new Error('No data');
        }

        this.registry[channelId] = data
    }

    tryToGetChannelData = (channelId: string) => {
        if (!channelId) {
            throw new Error('No channel ID');
        }

        return this.registry[channelId];
    }

    clearChannelData = (channelId: string) => {
        if (!channelId) {
            throw new Error('No channel ID');
        }

        delete this.registry[channelId];
    }

}