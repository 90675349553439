import { ProgressBarFile } from '@10x/foundation/src/components/progress';
import { observer } from 'mobx-react-lite';

import { FileUploadStatusEnum } from '@10x/foundation/types';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function _MessageAttachmentsProgressBar() {
  const attachmentsProgressBarViewStore = useInjection(
    IOC_TOKENS.attachmentsProgressBarViewStore
  );

  const {
    currentUploadingAttachment,
    showProgressBar,
    uploadingPercent,
    retryingCounter,
    status,
  } = attachmentsProgressBarViewStore.state;

  let content: string | JSX.Element = 'Uploading';

  switch (status) {
    case FileUploadStatusEnum.UPLOADING:
      content = (
        <>
          <span className="max-w-[91%] flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
            {`Uploading ${currentUploadingAttachment?.fileName} ...`}
          </span>
          <span>{uploadingPercent}%</span>
        </>
      );
      break;

    case FileUploadStatusEnum.FAILED:
      content = 'Uploading failed';
      break;
    case FileUploadStatusEnum.COMPLETED:
      content = 'Completed';
      break;
    case FileUploadStatusEnum.RETRYING:
      content = (
        <>
          <span className="max-w-[91%] flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
            {`Failed ${currentUploadingAttachment?.fileName} `}
          </span>
          <span>
            {`Retry attempt: ${retryingCounter} of ${attachmentsProgressBarViewStore.retryMaxAttemptCount}`}
          </span>
        </>
      );
      break;
    case FileUploadStatusEnum.PROCESSING:
      content = 'Processing';
      break;

    default:
      content = 'Uploading';
      break;
  }

  return (
    <ProgressBarFile
      cn="absolute w-full top-[68px]"
      progressPercentage={uploadingPercent}
      show={showProgressBar}
      content={content}
    />
  );
}

export const MessageAttachmentsProgressBar = observer(
  _MessageAttachmentsProgressBar
);
