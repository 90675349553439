import 'react-tabs/style/react-tabs.css';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { classNames } from '@10x/foundation/src/utilities';
import { ThemeSwitcher } from '@mainApp/src/components/navbar/ThemeSwitcher';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { Actions } from './shared/Actions';
import { StepsColumn } from './steps-panel-column/StepsColumn';
import { CommunityAndChannelPreviewDesktop } from './steps-previews/CommunityAndChannelPreviewDesktop';
import { CommunityAndChannelPreviewMobile } from './steps-previews/CommunityAndChannelPreviewMobile';
import { CommunityPreviewDesktop } from './steps-previews/CommunityPreviewDesktop';
import { CommunityPreviewMobile } from './steps-previews/CommunityPreviewMobile';

import { CompletedUI } from './CompletedUI';

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className={classNames('hover-el h-[22px] w-[22px] scale-125 outline-none')}
    onClick={onClick}
  >
    <XMarkIcon className="fill-color-6" />
  </button>
);

const stepsContentRegistry = {
  step1: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreviewDesktop />,
  },
  step2: {
    mobile: <CommunityAndChannelPreviewMobile />,
    desktop: <CommunityAndChannelPreviewDesktop />,
  },
  step3: {
    mobile: <CommunityAndChannelPreviewMobile />,
    desktop: <CommunityAndChannelPreviewDesktop />,
  },
  step4: {
    mobile: <CommunityAndChannelPreviewMobile />,
    desktop: <CommunityPreviewDesktop />,
  },
  step5: {
    mobile: <CommunityAndChannelPreviewMobile />,
    desktop: <CommunityAndChannelPreviewDesktop />,
  },
};

export function _CreateCommunityStepper() {
  const { dimensionsStore, createCommunityStepperStore } = useMultipleInjection(
    [IOC_TOKENS.dimensionsStore, IOC_TOKENS.createCommunityStepperStore]
  );

  const { t } = useTranslation(['common', 'create-community']);

  const [tabIndex, setTabIndex] = useState(0);

  const activeStepContent =
    stepsContentRegistry[createCommunityStepperStore.activeStepStoreKey];

  const handleClose = () => {
    createCommunityStepperStore.setModal(false);
  };

  const MobileTabs = (
    <div className="themed-layout themed-text grid max-h-full min-h-full w-full  grid-cols-1 grid-rows-[1fr_78px] overflow-auto md:w-auto">
      <Tabs
        className="grid h-full w-full grid-rows-[144px_1fr] overflow-hidden md:w-auto"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <div className="flex flex-col">
          <div className="flex items-center px-[20px] py-[12px]">
            <div className="flex flex-1 items-center">
              <CloseButton onClick={handleClose} />
              <h5 className="themed-text max-[335px]:text-[14px] ml-[22px] text-body18SB ">
                {t('create-community:createCommunity')}
              </h5>
            </div>

            <ThemeSwitcher />
          </div>

          <TabList className="react-tabs-panel mb-[20px] flex h-[56px]">
            <Tab className="flex grow items-center justify-center">
              {t('settings')}
            </Tab>
            <Tab className="flex grow items-center justify-center">
              {t('preview')}
            </Tab>
          </TabList>
        </div>

        <div className="flex flex-col overflow-auto">
          <TabPanel className="px-[20px]">
            <StepsColumn />
          </TabPanel>
          <TabPanel className="!flex flex-1 flex-col justify-center px-[20px] pb-[20px]">
            <div>{activeStepContent.mobile}</div>
          </TabPanel>
        </div>
      </Tabs>

      <div className="px-[20px] pb-[12px]">
        <Actions
          onNextClick={() => {
            // switch to control panel if the preview col is active
            if (tabIndex === 1) {
              setTabIndex(0);
            }
          }}
        />
      </div>
    </div>
  );

  const Desktop = (
    <div className="themed-layout themed-text flex max-h-full">
      <div className="max-w-[440px] overflow-auto bg-background-secondary px-[24px] pb-[20px] dark:bg-background-secondary-dark">
        <StepsColumn />
      </div>
      <div className="flex min-w-[692px] flex-1 flex-col pt-[16px]">
        <div className="flex items-center justify-between border-b-[1px] border-b-element-normal pb-[10px] pl-[24px] pr-[14px] dark:border-b-element-normal-dark">
          <h4 className="text-subheadingSB">
            {t('create-community:createCommunity')}
          </h4>
          <div className="flex items-center space-x-[46px]">
            <ThemeSwitcher />
            <CloseButton onClick={handleClose} />
          </div>
        </div>
        {/* {Preview} */}
        <div className="flex flex-1 items-center justify-center py-[12px] pl-[24px] pr-[14px] bigheight:py-[24px]">
          {activeStepContent.desktop}
        </div>
        <div className="flex justify-end border-t-[1px] border-t-element-normal px-[24px] pb-[20px] dark:border-t-element-normal-dark">
          <Actions />
        </div>
      </div>
    </div>
  );

  const finalContent = dimensionsStore.isMobile ? MobileTabs : Desktop;
  if (createCommunityStepperStore.completed) {
    return (
      <div
        className="themed-layout m-auto mx-[8px] max-w-[400px] self-center overflow-auto rounded-[10px] p-[16px] md:p-[24px]"
        onPointerMove={(e) => {
          e.stopPropagation();
        }}
      >
        <CompletedUI />
      </div>
    );
  }

  return <DndProvider backend={HTML5Backend}>{finalContent}</DndProvider>;
}

export const CreateCommunityStepper = observer(_CreateCommunityStepper);
