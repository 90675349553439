// TODO: create later some generic component based on it
import { RoundedDropdownLayout } from '@foundationPathAlias/components/layouts';
import { ActionDropdownMenu } from '@foundationPathAlias/components/menu';
import { ActionRoundedButton, SendSVG } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  titleCn?: string;
  /** change email request ID */
  id: string;
  email: string;
  onResend: (id: string) => void;
  onCancelRequest: (id: string) => void;
};

export function _EmailRow(props: Props) {
  const { titleCn, id, email, onResend, onCancelRequest } = props;
  const [open, setOpen] = useState(false);

  const dimensionsStore = useInjection(IOC_TOKENS.dimensionsStore);
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const { t } = useTranslation('common');
  const options = [
    {
      id: 'option-0',
      icon: <SendSVG className="themed-text h-[20px] w-[20px] fill-white" />,
      label: t('accountSettings.resendEmail'),
      action: () => {
        onResend(id);
        setOpen(false);
      },
    },
    {
      id: 'option-1',
      icon: <XMarkIcon className="themed-text h-[22px] w-[22px]" />,
      label: t('accountSettings.cancelRequest'),
      action: () => {
        onCancelRequest(id);
        setOpen(false);
      },
    },
  ];

  const isMobile = dimensionsStore.isMobile;

  useEffect(() => {
    accountSettingsStore.setBottomSheetConfig({
      content: (
        <div className="py-[8px]">
          <ul className="space-y-[12px]">
            {options.map((option) => {
              return (
                <ActionDropdownMenu.Item
                  className="dark:bg-transparent"
                  key={option.id}
                  label={option.label}
                  onClick={() => {
                    option.action();
                    accountSettingsStore.setBottomSheetConfig({
                      show: false,
                    });
                  }}
                  icon={option.icon}
                />
              );
            })}
          </ul>
        </div>
      ),
    });

    return () => {
      accountSettingsStore.setBottomSheetConfig({
        content: null,
      });
    };
  }, []);

  return (
    <div>
      <h6 className={classNames('themed-text mb-[8px] text-body16SB', titleCn)}>
        {t('email')}
      </h6>
      <div className="grid grid-cols-[1fr_auto] grid-rows-1">
        <div>
          <p className="themed-text-secondary text-body16R">{email}</p>
          <span className="themed-text-secondary text-sm13D italic">
            {t('accountSettings.waitingForConfirmation')}
          </span>
        </div>
        <div className="flex flex-col justify-end">
          {isMobile && (
            <ActionRoundedButton
              active={open}
              onClick={() => {
                setOpen(true);
                accountSettingsStore.setBottomSheetConfig({
                  show: true,
                  onClose: () => {
                    setOpen(false);
                  },
                });
              }}
            >
              <span className="themed-text">{t('actions')}</span>
            </ActionRoundedButton>
          )}

          {!isMobile && (
            <ActionDropdownMenu
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              trigger={
                <div className="self-baseline">
                  <ActionRoundedButton active={open}>
                    <span className="themed-text">{t('actions')}</span>
                  </ActionRoundedButton>
                </div>
              }
            >
              <RoundedDropdownLayout cn="py-[8px]">
                <ul>
                  {options.map((option) => {
                    return (
                      <ActionDropdownMenu.Item
                        key={option.id}
                        label={option.label}
                        onClick={option.action}
                        icon={option.icon}
                      />
                    );
                  })}
                </ul>
              </RoundedDropdownLayout>
            </ActionDropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
}

export const EmailRow = observer(_EmailRow);
