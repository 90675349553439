import { classNames } from '@foundationPathAlias/utilities';
type Props = {
  cn?: string;
};
export function LoaderQuatro(props: Props) {
  const { cn } = props;
  return (
    <div className={classNames('loader-quatro center', cn)}>
      <span></span>
    </div>
  );
}
