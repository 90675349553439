export enum LAYOUT_TYPES_ENUM {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
export enum SCREEN_SIZES_ENUM {
  'xs' = 375,
  'sm' = 640,
  'md' = 1024,
  'lg' = 1440,
  // @media min-heigt = 720px (tailwind preset)
  'bigHeight' = 720,
}

export type WindowSize = {
  width: number;
  height: number;
};

export interface IDimensionsStore {
  windowSize: WindowSize;
  layoutType: LAYOUT_TYPES_ENUM;
  isMobile: boolean;
  // useful to determinate when the window size had been received
  isReady: boolean;
  isBigHeight: boolean;

  screenWindow?: Window;

  init: () => void;
  proceedSize: () => void;
}
