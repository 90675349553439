// for Sidebar language select
// export const supportedLocalesData = {
//   en: { nativeName: 'English', value: 'en' },
//   es: { nativeName: 'Espanol', value: 'es' },
// };
export enum LocaleEnum {
  EN = 'en',
  ES = 'es'
}

export const namespaces = ['common', 'cap', 'channel', 'home', 'create-community', 'auth', 'channel'];

export const localesData = {
  [LocaleEnum.EN]: { id: LocaleEnum.EN, label: 'lang.enLabel' },
  [LocaleEnum.ES]: { id: LocaleEnum.ES, label: 'lang.esLabel' },
}

export const defaultLocale = 'en';

// for ni18n.config.js
export const supportedLocales = Object.keys(localesData);

export type Locales = 'es' | 'en';

// for Storybook to render the lang select on the tool panel
export const storybookGlobalTypes = {
  locale: {
    name: 'Locale',
    description: 'Internationalization locale',
    toolbar: {
      icon: 'globe',
      items: [
        { value: LocaleEnum.EN, right: LocaleEnum.EN, title: 'English' },
        { value: LocaleEnum.ES, right: LocaleEnum.ES, title: 'Espanol' },
      ],
      showTitle: 'Locale',
    },
  },
};
