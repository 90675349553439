import { observer } from 'mobx-react-lite';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionWithIcon,
  CheckboxCollapseAction,
  TextInput,
} from '@foundationPathAlias/components';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import {
  DeleteStepEnum,
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { AccountSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';

import { DeleteFinalContent } from './DeleteFinalContent';

const initialState = {
  deactivation: false,
  reason: false,
};

export function _Delete() {
  const { t } = useTranslation('common');
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  useLayoutEffect(() => {
    if (accountStore.activeSettingId !== AccountSettingsEnum.DELETE) return;

    accountStore.setShowActions(true);
    accountStore.setIsDisableNextAction(true);
  }, [accountStore.activeSettingId]);

  const [selectedOptions, setSelectedOptions] = useState(initialState);

  const { title } = accountStore.activeSetting;

  const accountData = accountStore.data;
  const email = accountData[SettingsDataNamesEnum.EMAIL]?.serverEmail;
  const username = accountData[SettingsDataNamesEnum.USERNAME]?.data;
  const deleteStep = accountData[SettingsDataNamesEnum.DELETE]?.data?.step;

  useEffect(() => {
    setSelectedOptions({ ...initialState });
  }, [deleteStep]);

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <div className="flex flex-col items-start">
        {deleteStep === DeleteStepEnum.FINAL ? (
          <DeleteFinalContent
            setShowCodeForm={accountStore.setShowCodeForm}
            email={email || ''}
            username={username || ''}
            showCodeForm={accountStore.showCodeForm}
          />
        ) : (
          <>
            <b className="themed-text text-body16SB">
              {t('accountSettings.whyLeaving')}
            </b>
            <p className="themed-text-secondary mb-[24px] mt-[8px] text-left text-body16R">
              {t('accountSettings.leavingSorry')}
            </p>
            <CheckboxCollapseAction
              header={t<string>('accountSettings.leavingTemporarily')}
              checked={selectedOptions.deactivation}
              onChange={(checked) => {
                setSelectedOptions({
                  deactivation: checked,
                  reason: false,
                });
              }}
              collapsedContent={
                <div className="mr-[42px] flex flex-col">
                  <p className="themed-text-secondary mb-[6px] text-left text-sm14R">
                    {t('accountSettings.mightBack')}
                  </p>
                  <ActionWithIcon
                    title={t<string>('accountSettings.deactivateAccount')}
                    onClick={() => {
                      accountStore.setNextSettingId(
                        AccountSettingsEnum.DEACTIVATE
                      );
                    }}
                  />
                </div>
              }
            />
            <CheckboxCollapseAction
              cn="mt-[16px]"
              header={t<string>('accountSettings.anotherReason')}
              checked={selectedOptions.reason}
              onChange={(checked) => {
                setSelectedOptions({
                  deactivation: false,
                  reason: checked,
                });
              }}
              collapsedContent={
                <div className="flex flex-col">
                  <TextInput
                    noIcon
                    inputCn="h-[120px]"
                    onChange={(val) => {
                      accountStore.setSettingValue(
                        SettingsDataNamesEnum.DELETE,
                        {
                          step: deleteStep,
                          reasonComment: val,
                        }
                      );

                      accountStore.setIsDisableNextAction(!val.length);
                    }}
                    textAreaMode
                  />
                </div>
              }
            />{' '}
          </>
        )}
      </div>
    </ScreenWrapper>
  );
}

export const Delete = withAnimatedStack(observer(_Delete));
