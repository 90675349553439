import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// TODO: create later some generic component based on it
import { withAnimatedStack } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenToggleItem } from '@mainApp/src/modules/account-settings/common/ScreenToggleItem';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { AboutSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';
import { IAboutStore } from './data';

export function _About() {
  const { t } = useTranslation('common');
  const { accountSettingsStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  // should do it in this way because of the rare case when the user switch Dekstop layout to Mobile and moves back in stack
  const aboutStore = accountSettingsStore.settingStores.about as IAboutStore;
  const { title } = aboutStore.activeSetting;

  return (
    // showBackBtn should be true on Mobile
    <ScreenWrapper title={t(title)} showBackBtn={dimensionsStore.isMobile}>
      <ScreenToggleItem
        label={t('policy.detailedLabel')}
        onClick={() => {
          aboutStore.setNextSettingId(AboutSettingsEnum.POLICY);
        }}
      />
      <ScreenToggleItem
        label={t('terms.detailedLabel')}
        onClick={() => {
          aboutStore.setNextSettingId(AboutSettingsEnum.TERMS);
        }}
      />

      <p className="themed-text-secondary mt-[24px] text-body16SB">
        Version 1.0.0
      </p>
    </ScreenWrapper>
  );
}

export const About = withAnimatedStack(observer(_About));
