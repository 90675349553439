import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { LoaderQuatro } from './LoaderQuatro';

import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CodeFormInput } from './CodeFormInput';
import { ErrorChip } from './ErrorChip';

const charFieldNames = {
  char0: 'char0',
  char1: 'char1',
  char2: 'char2',
  char3: 'char3',
  char4: 'char4',
  char5: 'char5',
};

const validationSchema = object({
  [charFieldNames.char0]: string().required(),
  [charFieldNames.char1]: string().required(),
  [charFieldNames.char2]: string().required(),
  [charFieldNames.char3]: string().required(),
  [charFieldNames.char4]: string().required(),
  [charFieldNames.char5]: string().required(),
});

const initialState = {
  [charFieldNames.char0]: '',
  [charFieldNames.char1]: '',
  [charFieldNames.char2]: '',
  [charFieldNames.char3]: '',
  [charFieldNames.char4]: '',
  [charFieldNames.char5]: '',
};

export type Props = {
  onSubmit: (otpCode: string) => void;
  otpError: boolean;
  onChange: (val: string) => void;
  loading: boolean;
};

export function CodeForm(props: Props) {
  const { onSubmit, otpError, onChange, loading } = props;
  const [state, setState] = useState(initialState);

  const [error, setError] = useState(false);
  const { t } = useTranslation('auth');

  const inputsRef = useRef<{ [index: string]: null | HTMLInputElement }>({
    [charFieldNames.char0]: null,
    [charFieldNames.char1]: null,
    [charFieldNames.char2]: null,
    [charFieldNames.char3]: null,
    [charFieldNames.char4]: null,
    [charFieldNames.char5]: null,
  });

  const generateFieldHandler =
    (fieldNumber: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const fieldName = `char${fieldNumber}`;
      const val = e.target.value;
      const prevVal = state[fieldName];

      onChange(val);

      setState({
        ...state,
        [fieldName]: val.toUpperCase(),
      });

      if (val.length > prevVal.length) {
        const nextFieldNumber = fieldNumber + 1;
        const lastFieldNumber = 5;
        const nextFieldName = `char${
          nextFieldNumber <= lastFieldNumber ? nextFieldNumber : lastFieldNumber
        }`;
        inputsRef.current[nextFieldName]?.focus();
      }
    };
  const generateRefHandler =
    (fieldName: string) => (input: HTMLInputElement) => {
      inputsRef.current[fieldName] = input;
    };

  const isValid = validationSchema.isValidSync(state);

  const generateHandlePaste =
    (fieldName: string) => (e: ClipboardEvent<HTMLInputElement>) => {
      const clipboardData = e.clipboardData;
      const pastedText = clipboardData.getData('text');

      if (!pastedText) return;
      const charsArr = pastedText
        // in the email letter the code in format XXX-XXX. Should remove '-'
        .replace(/\s+/g, '')
        .substring(0, 6)
        .split('');
      const newState = Object.keys(state).reduce((acc, key, index) => {
        acc[key] = charsArr[index] || '';
        return acc;
      }, {} as typeof initialState);

      setState(newState);

      inputsRef.current[fieldName]?.blur();

      e.preventDefault();
    };

  const generateKeyboardHandler =
    (fieldNumber: number) => (e: KeyboardEvent<HTMLInputElement>) => {
      const code = e.code;

      let nextInput;
      if (code === 'ArrowLeft' || code === 'Backspace') {
        nextInput = inputsRef.current[`char${fieldNumber - 1}`];
      } else if (code === 'ArrowRight' || code === 'Delete') {
        nextInput = inputsRef.current[`char${fieldNumber + 1}`];
      }
      nextInput?.focus();
    };

  useEffect(() => {
    if (Boolean(otpError) !== error) {
      setError(Boolean(otpError));
    }
  }, [otpError]);

  useEffect(() => {
    if (isValid) {
      const otp = Object.values(state).join('');
      onSubmit(otp);
    }
  }, [isValid]);

  return (
    <div>
      <form
        autoComplete="off"
        className="mb-[16px] flex items-center justify-center space-x-[8px]"
      >
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char0)}
          id={charFieldNames.char0}
          name={charFieldNames.char0}
          onKeyUp={generateKeyboardHandler(0)}
          onChange={generateFieldHandler(0)}
          value={state[charFieldNames.char0]}
          onPaste={generateHandlePaste(charFieldNames.char0)}
        />
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char1)}
          id={charFieldNames.char1}
          name={charFieldNames.char1}
          onKeyUp={generateKeyboardHandler(1)}
          onChange={generateFieldHandler(1)}
          value={state[charFieldNames.char1]}
          onPaste={generateHandlePaste(charFieldNames.char1)}
        />
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char2)}
          id={charFieldNames.char2}
          name={charFieldNames.char2}
          onKeyUp={generateKeyboardHandler(2)}
          onChange={generateFieldHandler(2)}
          value={state[charFieldNames.char2]}
          onPaste={generateHandlePaste(charFieldNames.char2)}
        />

        <hr className="h-[4px] w-[24px] bg-element-subtle dark:bg-element-subtle-dark" />
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char3)}
          id={charFieldNames.char3}
          name={charFieldNames.char3}
          onKeyUp={generateKeyboardHandler(3)}
          onChange={generateFieldHandler(3)}
          value={state[charFieldNames.char3]}
          onPaste={generateHandlePaste(charFieldNames.char3)}
        />
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char4)}
          id={charFieldNames.char4}
          name={charFieldNames.char4}
          onKeyUp={generateKeyboardHandler(4)}
          onChange={generateFieldHandler(4)}
          value={state[charFieldNames.char4]}
          onPaste={generateHandlePaste(charFieldNames.char4)}
        />
        <CodeFormInput
          ref={generateRefHandler(charFieldNames.char5)}
          id={charFieldNames.char5}
          name={charFieldNames.char5}
          onKeyUp={generateKeyboardHandler(5)}
          onChange={generateFieldHandler(5)}
          value={state[charFieldNames.char5]}
          onPaste={generateHandlePaste(charFieldNames.char5)}
        />
      </form>

      {loading && (
        <div className="relative">
          <figure className="absolute left-[50%] -translate-x-[50%]">
            <LoaderQuatro />
          </figure>
        </div>
      )}

      {error ? (
        <ErrorChip>{t('codeError')}</ErrorChip>
      ) : (
        <p className="h-[38px]" />
      )}
    </div>
  );
}
