export const RulesSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 18, height = 17, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5432 2.16064C2.33244 2.16064 2.1239 2.24753 1.96527 2.41267C1.80566 2.57885 1.71094 2.81044 1.71094 3.05795V10.7023C1.97326 10.5993 2.25484 10.5445 2.5432 10.5445H10.3374V2.16064H2.5432ZM1.96527 12.2965C1.80566 12.4627 1.71094 12.6943 1.71094 12.9418V12.9418C1.71094 13.1893 1.80566 13.4209 1.96527 13.5871C2.1239 13.7522 2.33244 13.8391 2.5432 13.8391H10.3374V12.0445H2.5432C2.33244 12.0445 2.1239 12.1314 1.96527 12.2965ZM11.8374 1.41064V11.2945V14.5891C11.8374 15.0033 11.5016 15.3391 11.0874 15.3391H2.5432C1.91467 15.3391 1.31831 15.0789 0.883468 14.6262C0.449617 14.1745 0.210938 13.5681 0.210938 12.9418V12.9418V3.05795C0.210938 2.43168 0.449617 1.82528 0.883468 1.37359C1.31831 0.920872 1.91467 0.660645 2.5432 0.660645H11.0874C11.5016 0.660645 11.8374 0.996431 11.8374 1.41064ZM8.88569 5.55426C9.17263 5.25552 9.16307 4.78075 8.86434 4.49381C8.5656 4.20688 8.09083 4.21644 7.80389 4.51517L5.44398 6.9721L4.66633 6.16248C4.3794 5.86375 3.90462 5.85418 3.60589 6.14112C3.30716 6.42806 3.29759 6.90283 3.58453 7.20157L4.90308 8.57432C5.0445 8.72156 5.23983 8.80478 5.44398 8.80478C5.64813 8.80478 5.84346 8.72156 5.98488 8.57432L8.88569 5.55426Z"
      fill="currentColor"
    />
  </svg>
);
