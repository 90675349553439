export enum DropupTypeEnum {
    // EDIT = 'EDIT',
    SUGGESTION_USERS = 'SUGGESTION_USERS',
    SUGGESTION_CHANNELS = 'SUGGESTION_CHANNELS',
    EMPTY = 'EMPTY',
}

export enum PopoverTypeEnum {
    MENTION_USER = 'MENTION_USER',
    CHANNEL = 'CHANNEL',
    MENTION_ADMIN = 'MENTION_ADMIN',
    ATTACHMENTS = 'ATTACHMENTS',
}

export type DropupData = {
    show: boolean;
    type: DropupTypeEnum;
    searchCriteria?: string | void;
};

export type MentionQueryData = {
    type: DropupTypeEnum;
    query: string;
};

export enum PopoverPlacementEnum {
    RightEnd = 'right-end',
    RightCenter = 'right-center',
    RightStart = 'right-start',
    LeftEnd = 'left-end',
    LeftCenter = 'left-center',
    LeftStart = 'left-start',
    TopEnd = 'top-end',
    TopCenter = 'top-center',
    TopStart = 'top-start',
    BottomEnd = 'bottom-end',
    BottomCenter = 'bottom-center',
    BottomStart = 'bottom-start',
}

export type PopoverData = {
    show: boolean;
    type: PopoverTypeEnum;
    triggerEl: null | HTMLElement;
    loading: boolean;
    // TODO: generic type
    data: null | any;
    PopoverProps?: {
        placement: PopoverPlacementEnum;
        offsetConfig:
        | number
        | {
            alignmentAxis: number;
            mainAxis: number;
        };
    };
};

export type SuggestionOptionData = {
    id: string;
    label: string;
}