import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { validators } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { Input } from '@mainApp/src/components/post-auth-onboarding/common/Input';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';

type Props = {
  titleCn?: string;
};

export function _Name(props: Props) {
  const { titleCn } = props;
  const { t } = useTranslation('common');

  const { accountSettingsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
  ]);

  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  const { title } = accountStore.activeSetting;
  const firstnameData = accountStore.data[SettingsDataNamesEnum.FIRST_NAME];
  const lastNameData = accountStore.data[SettingsDataNamesEnum.LAST_NAME];

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {t('accountSettings.firstName')}
      </h6>
      <Input
        inputName={t<string>('accountSettings.firstName')}
        placeholder={t<string>('accountSettings.firstName')}
        infoWrapperCn="min-h-[24px]"
        specificValidators={[
          validators.getBeginSpaceValidator(
            t('errors.beginSpace', {
              fieldName: t('accountSettings.firstName')?.toLowerCase(),
            }) as string
          ),
        ]}
        outerError={null}
        successMsg={''}
        value={firstnameData.data}
        onChange={(val) => {
          accountStore.setFirstName(val);
        }}
        onError={(error) => {
          const existingError =
            accountStore.data[SettingsDataNamesEnum.FIRST_NAME].error;
          if (existingError !== error) {
            accountStore.setSettingValue(
              SettingsDataNamesEnum.FIRST_NAME,
              lastNameData.data,
              error
            );
          }
        }}
      />
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {t('accountSettings.lastName')}
      </h6>
      <Input
        inputName={t<string>('accountSettings.lastName')}
        placeholder={t<string>('accountSettings.lastName')}
        infoWrapperCn="min-h-[24px]"
        specificValidators={[
          validators.getBeginSpaceValidator(
            t('errors.beginSpace', {
              fieldName: t('accountSettings.lastName')?.toLowerCase(),
            }) as string
          ),
        ]}
        outerError={null}
        successMsg={''}
        value={lastNameData.data}
        onChange={(val) => {
          accountStore.setLastName(val);
        }}
        onError={(error) => {
          const existingError =
            accountStore.data[SettingsDataNamesEnum.LAST_NAME].error;
          if (existingError !== error) {
            accountStore.setSettingValue(
              SettingsDataNamesEnum.DISPLAY_NAME,
              lastNameData.data,
              error
            );
          }
        }}
      />
    </ScreenWrapper>
  );
}

export const Name = withAnimatedStack(observer(_Name));
