import { reaction } from 'mobx';
import { useEffect } from 'react';

import { CommonEventsEnum } from '@mainApp/src/events';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { DeleteMessageModalContent } from './DeleteMessageModalContent';
import { deleteMessageModalStoreLocal } from './data/deleteMessageModal.store.local';

/**
 * Renders delete message modal.
 * Basically it's a reactive hook
 * that reacts on the local store call and renders the
 * Modal content via Interactive store in Modal or Bottomsheet
 */
export function useDeleteMessageModalUI() {
  const { interactiveStore, eventBus } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.eventBus,
  ]);

  useEffect(() => {
    const delMsgReactionDisposer = reaction(
      () => deleteMessageModalStoreLocal.isShow,
      (isdelMsgModalShow) => {
        if (isdelMsgModalShow) {
          const element = <DeleteMessageModalContent />;
          interactiveStore.setContent(element);
          interactiveStore.setActiveContentType(
            InteractiveContentTypesEnum.OTHER,
            true
          );
          interactiveStore.setInteractiveElementOpen(true);
          return;
        }
      }
    );

    eventBus.on(CommonEventsEnum.INTERACTIVE_STORE_ELEMENT_CLOSE, () => {
      deleteMessageModalStoreLocal.hide();
    });

    return delMsgReactionDisposer;
  }, []);
}
