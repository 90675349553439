import { useEffect, useState } from 'react';

import { PrimaryButton } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

export function _ProSection1() {
  const {
    authStore: { logged, setLoginMode },
    interactiveStore,
  } = useMultipleInjection([IOC_TOKENS.authStore, IOC_TOKENS.interactiveStore]);
  const [actionTextKey, setActionTextKey] = useState('navbar.signUp');

  useEffect(() => {
    if (logged) {
      setActionTextKey('home:subscribeNow');
    }
  }, [logged]);

  const { t } = useTranslation(['common', 'home']);

  return (
    <div className="relative top-[-100px] mb-[0px] flex h-[550px] w-full flex-col items-start justify-center bg-pro dark:bg-pro-dark xs:px-[24px] md:mb-[20px] md:h-[600px] md:items-center">
      <Image
        src={'/images/pro-bg.png'}
        fill
        priority
        alt="Background Pro"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      />
      <div className="mt-[65px] flex flex-col items-start justify-center sm:items-center md:mt-[0px] lg:flex-row">
        <div className="grad-t-build text-h1Mobile sm:text-h1">Do more</div>
        <div className="themed-text text-h1Mobile sm:text-h1 lg:ml-[14px]">
          with Pro.
        </div>
      </div>
      <div className="themed-text mt-[20px] text-start text-subheading sm:text-center">
        Secure an awesome short username, join our inner circle,{' '}
        <span className="hidden sm:inline">
          <br />
        </span>
        and gain access to exclusive Pro perks over time.
      </div>
      <PrimaryButton
        cn="mt-[40px] w-[calc(100vw)-48px] md:w-auto rounded-[24px] z-1"
        onClick={() => {
          if (!logged) {
            setLoginMode(false);
            interactiveStore.setActiveContentType(
              InteractiveContentTypesEnum.AUTH,
              true
            );
          }
        }}
      >
        {t(actionTextKey)}
      </PrimaryButton>
    </div>
  );
}

export const ProSection1 = observer(_ProSection1);
