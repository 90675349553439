import {
  ChangeEvent,
  ClipboardEvent,
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
} from 'react';

export type Props = {
  id: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPaste: (e: ClipboardEvent<HTMLInputElement>) => void;
  value: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

function _CodeFormInput(props: Props, ref: ForwardedRef<HTMLInputElement>) {
  return (
    <input
      ref={ref}
      className="color-0 h-[64px] w-[44px] rounded-[8px] border-[2px] border-transparent bg-background-secondary text-center text-code text-text-secondary outline-primary-100 dark:bg-background-secondary-dark dark:text-text-secondary-dark dark:outline-primary-100-dark"
      type="text"
      maxLength={1}
      autoComplete="off"
      {...props}
    />
  );
}

export const CodeFormInput = forwardRef(_CodeFormInput);
