import { Row } from '@foundationPathAlias/components/table';
import { ActionDropdownMenu, DotsButton } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { type TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { colorSchema } from '@rootConfig/color-schema';
import { useState } from 'react';
import {
  AvailableActionsEnum,
  MemberActionMenuContent,
} from './tooltip-contents';

type UsersTableOptionsCellProps = {
  row: Row<Partial<TableUser>>;
  hovered?: boolean;
  onBan?: () => void;
  onUnBan?: () => void;
  availableActions: AvailableActionsEnum[];
};

export const UsersTableOptionsCell = ({
  hovered,
  onBan,
  row,
  onUnBan,
  availableActions,
}: UsersTableOptionsCellProps) => {
  const { isDarkTheme } = useInjection(IOC_TOKENS.systemStore);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearchRolesOpen, setIsSearchRolesOpen] = useState(false);

  const borderColor = isDarkTheme
    ? colorSchema['element-subtle-dark']
    : colorSchema['element-subtle'];

  const handleCloseDropdown = () => {
    if (isSearchRolesOpen) {
      return;
    }

    setIsDropdownOpen(false);
  };

  return (
    <>
      <ActionDropdownMenu
        position="left bottom"
        open={isDropdownOpen}
        on="hover"
        mouseEnterDelay={300}
        contentStyle={{
          borderColor,
        }}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={handleCloseDropdown}
        closeOnDocumentClick={!isSearchRolesOpen}
        trigger={
          <div>
            <DotsButton
              className={classNames({ invisible: !hovered })}
              type="vertical"
              onClick={() => null}
            />
          </div>
        }
      >
        <MemberActionMenuContent
          row={row}
          availableActions={availableActions}
          onUnBan={() => {
            onUnBan?.();
            handleCloseDropdown();
          }}
          isSearchRolesOpen={isSearchRolesOpen}
          setIsSearchRolesOpen={setIsSearchRolesOpen}
          onBan={() => {
            onBan?.();
            handleCloseDropdown();
          }}
          onRoles={handleCloseDropdown}
        />
      </ActionDropdownMenu>
    </>
  );
};
