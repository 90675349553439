import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SuccessIcon } from './icons/SuccessIcon';

type StepPreviewProps = {
  label: string;
  title: string;
  description: string;
  children?: React.ReactNode;
  stepStore: any;
};

export const _StepPanel = (props: StepPreviewProps) => {
  const { label, title, stepStore, description, children } = props;
  const { dimensionsStore, createCommunityStepperStore } = useMultipleInjection(
    [IOC_TOKENS.dimensionsStore, IOC_TOKENS.createCommunityStepperStore]
  );
  const { t } = useTranslation(['create-community']);
  const {
    id: currentActiveStepStoreId,
    isShowMoveBackError,
    setShowMoveBackError,
    canMoveNext: isCompletedActiveStep,
  } = createCommunityStepperStore.activeStepStore;
  const isActiveStep = currentActiveStepStoreId === stepStore?.id;
  const active = isActiveStep || stepStore?.canMoveNext;

  const completed = !isActiveStep && stepStore?.canMoveNext;
  const canMoveToPrevStep = completed && isCompletedActiveStep;

  const showMoveBackError = isActiveStep && isShowMoveBackError;

  return (
    <div
      onClick={() => {
        // shouldn't allow to move to prev step if the current one isn't completed
        if (canMoveToPrevStep) {
          createCommunityStepperStore.setActiveStepStoreById(stepStore.id);
        } else if (completed) {
          /**
           * show error only when the click on the some other step panel
           * (not active) and only if that step is completed.
           *  */
          setShowMoveBackError(true);
        }
      }}
      className={classNames(
        'relative  rounded-md bg-background-primary p-[16px] dark:bg-background-primary-dark',
        dimensionsStore.isMobile &&
          'bg-background-secondary dark:bg-background-secondary-dark',
        !active && 'opacity-70',
        canMoveToPrevStep && 'cursor-pointer'
      )}
    >
      {completed ? (
        <span className="absolute right-[16px] top-[16px]">
          <SuccessIcon
            rootCn="mx-auto flex h-[22px] w-[22px] items-center justify-center rounded-full bg-primary-100"
            iconCn="h-[16px] w-[16px] text-white"
          />
        </span>
      ) : null}
      <div className="flex flex-col items-start">
        <span
          className={classNames(
            'mb-[2px] block text-sm14R',
            active
              ? 'text-text-secondary dark:text-text-secondary-dark'
              : 'text-text-placeholder dark:text-text-placeholder-dark'
          )}
        >
          {label}
        </span>
        <h3
          className={classNames(
            'mb-[2px] text-body18SB',
            active
              ? 'text-text-primary dark:text-text-primary-dark'
              : 'text-text-placeholder dark:text-text-placeholder-dark'
          )}
        >
          {title}
        </h3>
        <p
          className={classNames(
            'text-left text-body16R',
            active
              ? 'text-text-secondary dark:text-text-secondary-dark'
              : 'text-text-placeholder dark:text-text-placeholder-dark'
          )}
        >
          {description}
        </p>
        {showMoveBackError && (
          <small className="mt-[4px] block text-exstraSm12R text-element-error dark:text-element-error-dark">
            {t('create-community:moveBackError')}
          </small>
        )}
      </div>

      {isActiveStep ? children : null}
    </div>
  );
};

export const StepPanel = observer(_StepPanel);
