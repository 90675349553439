import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import {
    SubscriptionTypeEnum
} from '@mainApp/src/stores';
import { useMemo } from 'react';


export function useGetSubscriptionCost() {
    const userStore = useInjection(
        IOC_TOKENS.userStore,
    );

    const cost = useMemo(() => {
        const priceType = userStore.proSubscription?.subscriptionTypeSlug as SubscriptionTypeEnum;

        if (!priceType) return '';


        const price: any = userStore.getSubscriptionPriceByType(priceType);
        let costLabel;

        switch (price?.slug) {
            case SubscriptionTypeEnum.YEAR:
                costLabel = `Year`;
                break;
            case SubscriptionTypeEnum.MONTH:
                costLabel = `Month`;
                break;
            default:
                costLabel = null;
                break;
        }
        return costLabel ? `$${price.ext.price}/${costLabel}` : '';
    }, [userStore.subscriptionPrices, userStore.proSubscription?.interval]);

    return cost;
}