import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

import { aboutSettingsComponents } from './aboutComponents';

import { IAnimatedStackStore } from '@mainApp/src/components/common';
import { AboutSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';


export class AboutStore implements IAboutStore {
    stackStore: IAnimatedStackStore;
    activeSettingId: AboutSettingsEnum = AboutSettingsEnum.INITIAL;

    get initialSetting() {
        return AboutSettingsEnum.INITIAL;
    }


    get activeSetting() {
        return aboutSettingsComponents[this.activeSettingId];
    }
    get isInitialScreen() {
        return this.activeSettingId === AboutSettingsEnum.INITIAL;
    }

    constructor(stackStore: IAnimatedStackStore) {
        makeObservable(this, {
            activeSetting: computed,
            isInitialScreen: computed,
            activeSettingId: observable,
            setActiveSettingId: action,
            setNextSettingId: action,
            back: action,
            reset: action,
        });

        this.stackStore = stackStore;
    }

    setActiveSettingId = (id: AboutSettingsEnum) => {
        if (this.activeSettingId === id) return;
        this.activeSettingId = id;
    };

    setNextSettingId = (setting: AboutSettingsEnum) => {
        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }

        this.activeSettingId = setting;

        const nextItem = aboutSettingsComponents[setting];
        stackStore.next(nextItem.id, nextItem.Component);
    };
    back = () => {
        const stackStore = this.stackStore;
        // shouldn't do anything if the animation is running
        if (stackStore.isAnimationRunning) {
            return;
        }
        const prevSettingId = stackStore.back();

        if (!prevSettingId) return;

        this.activeSettingId = prevSettingId as AboutSettingsEnum;
    };

    reset = () => {
        this.setActiveSettingId(this.initialSetting);
        this.stackStore.reset();
        this.stackStore.setVisible(
            this.activeSetting.id,
            this.activeSetting.Component
        );
    };
}

type SettingComponentsConfig = typeof aboutSettingsComponents;

export interface IAboutStore {
    stackStore: IAnimatedStackStore;
    activeSettingId: AboutSettingsEnum;
    readonly isInitialScreen: boolean;
    readonly initialSetting: AboutSettingsEnum;
    readonly activeSetting: SettingComponentsConfig[keyof SettingComponentsConfig];
    setActiveSettingId: (id: AboutSettingsEnum) => void;
    setNextSettingId: (id: AboutSettingsEnum) => void;
    back: () => void;
    reset: () => void;
}
