/**
 * Function to extract text from a deserialized node structure
 */
function extractTextFromNode(node: any) {
  let text = '';
  if (node.type === 'text') {
    text += node.text;
  } else if (node.children) {
    for (const child of node.children) {
      text += extractTextFromNode(child);
    }
  }
  return text;
}

/**
 * Function to deserialize the string and extract the text
 */
export function extractPlainText(serializedStateString: string) {
  try {
    const serializedState = JSON.parse(serializedStateString);
    const root = serializedState.root;
    return extractTextFromNode(root);
  } catch (error) {
    console.error(error);
    return null;
  }
}
