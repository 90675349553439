import { action, computed, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { CommunityStepperModel } from './CommunityStepper.model';
import { IStepBase } from './StepBase';

import type { IOnboardingRepositoryInterface } from '@mainApp/src/repositories/Onboarding.repository';

enableStaticRendering(typeof window === 'undefined');

import { TextFieldType } from '../types';
import { StepBase } from './StepBase';

export class Step3Store extends StepBase implements IStep3Store {
  id = 3;

  get communityName() {
    return this.communityStepperModel.communityName;
  }
  get description() {
    return this.communityStepperModel.description;
  }

  get canMoveNext() {
    const res =
      this.communityStepperModel.isValidCommunityName &&
      this.communityStepperModel.isValidCommunityDescription;

    return res;
  }

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    super(repository, communityStepperModel);

    makeObservable(this, {
      setCommunityName: action,
      reset: action,
      canMoveNext: computed,
      communityName: computed,
    });
  }

  setCommunityName = (val: TextFieldType) => {
    this.resetMoveBackError();
    this.communityStepperModel.setCommunityName(val);
  };

  setDescription = (val: TextFieldType) => {
    this.resetMoveBackError();
    this.communityStepperModel.setDescription(val);
  };

  reset = () => {
    this.communityStepperModel.resetCommunityName();
    this.communityStepperModel.resetDescription();
  };
}

export interface IStep3Store extends IStepBase {
  readonly communityName: TextFieldType;
  readonly description: TextFieldType;
  setCommunityName: (val: TextFieldType) => void;
  setDescription: (val: TextFieldType) => void;
}
