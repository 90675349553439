import {
  MenuListItem,
  SidebarWithMenu,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { observer } from 'mobx-react-lite';

import { RulesSVG } from '@foundationPathAlias/main';
import {
  SwatchIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

type MenuItemsIds = 'users' | 'roles' | 'rules' | 'customize';

const menuItemsConfig: MenuListItem<MenuItemsIds>[] = [
  {
    label: 'Users',
    id: 'users',
    icon: <UsersIcon width={20} />,
  },
  {
    label: 'Rules',
    id: 'rules',
    icon: <RulesSVG width={20} height={20} />,
  },
  {
    label: 'Customize',
    id: 'customize',
    icon: <SwatchIcon width={20} />,
  },
];

export function _SidebarMenuWithMobx() {
  const {
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);

  return (
    <SidebarWithMenu
      isMobile={isMobile}
      menuItemsConfig={menuItemsConfig}
      activeMenuItemId={capStore.activeScreenId}
      sidebarCn="md:w-[240px]"
      onMenuSelect={(itemId) => {
        capStore.setActiveScreenId(itemId);
      }}
    />
  );
}

export const SidebarMenuWithMobx = observer(_SidebarMenuWithMobx);
