import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Payment_Subscription_States } from '@10x/foundation/types';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';

import { ActiveContent } from './ActiveContent';
import { ExpiredContent } from './ExpiredContent';
import { InactiveContent } from './InactiveContent';
import { InitialContent } from './InitialContent';
import { ISubscriptionStore } from './data';

enum ContentStageEnum {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
}

export function _Subscription() {
  const { t } = useTranslation('common');

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<ContentStageEnum>(ContentStageEnum.LOADING);
  const { accountSettingsStore, dimensionsStore, userStore } =
    useMultipleInjection([
      IOC_TOKENS.accountSettingsStore,
      IOC_TOKENS.dimensionsStore,
      IOC_TOKENS.userStore,
    ]);

  let content;
  // should do it in this way because of the rare case when the user switch Dekstop layout to Mobile and moves back in stack
  const subscriptionStore = accountSettingsStore.settingStores
    .subscription as ISubscriptionStore;
  const { title } = subscriptionStore.activeSetting;
  useEffect(() => {
    userStore.getUserProSubscription().then((res) => {
      // there is no any pro subscription. Stop loading and show Initil UI
      if (!res.data) {
        setSubscriptionStatus(ContentStageEnum.INITIAL);
      }
    });
    userStore.getAccountProUsernameSubscriptionTypes();
  }, []);

  const subscriptionState = userStore.proSubscription?.state;
  const isSubscriptionCanceled = userStore.proSubscription?.cancelAtPeriodEnd;
  useEffect(() => {
    switch (subscriptionState) {
      case Payment_Subscription_States.Active:
        if (isSubscriptionCanceled) {
          setSubscriptionStatus(ContentStageEnum.INACTIVE);
        } else {
          setSubscriptionStatus(ContentStageEnum.ACTIVE);
        }
        break;
      case Payment_Subscription_States.Expired:
        setSubscriptionStatus(ContentStageEnum.EXPIRED);
        break;
      case Payment_Subscription_States.Inactive:
        setSubscriptionStatus(ContentStageEnum.INACTIVE);
        break;
      case Payment_Subscription_States.Pending:
        setSubscriptionStatus(ContentStageEnum.INITIAL);
        break;
      default:
        break;
    }
  }, [subscriptionState]);

  switch (subscriptionStatus) {
    case ContentStageEnum.INITIAL:
      content = <InitialContent />;
      break;
    case ContentStageEnum.INACTIVE:
      content = (
        <InactiveContent
          onSuccess={() => {
            setSubscriptionStatus(ContentStageEnum.ACTIVE);
          }}
        />
      );
      break;
    case ContentStageEnum.EXPIRED:
      content = (
        <ExpiredContent
          onSuccess={() => {
            setSubscriptionStatus(ContentStageEnum.ACTIVE);
          }}
        />
      );
      break;
    case ContentStageEnum.ACTIVE:
      content = (
        <ActiveContent
          onSuccess={() => {
            setSubscriptionStatus(ContentStageEnum.INACTIVE);
          }}
        />
      );
      break;
    default:
      content = (
        <div className="flex justify-center">
          <SmallLoader cn="w-[24px] h-[24px]" />
        </div>
      );
      break;
  }

  return (
    // showBackBtn should be true on mobile
    <ScreenWrapper
      title={t<string>(title)}
      showBackBtn={dimensionsStore.isMobile}
    >
      {content}
    </ScreenWrapper>
  );
}

export const Subscription = withAnimatedStack(observer(_Subscription));
