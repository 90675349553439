export type Props = {
  channelName: string;
  onClick: () => void;
};
export function ChannelSuggestionItem(props: Props) {
  const { channelName, onClick } = props;

  return (
    <li className="suggestion-option h-[36px] pt-[2px]">
      <button className="flex w-full justify-between" onClick={onClick}>
        <span className="text-sm14T text-text-primary dark:text-text-primary-dark">
          {channelName}
        </span>
      </button>
    </li>
  );
}
