import { classNames } from '@foundationPathAlias/utilities';
type Props = {
  isFirstUnread: boolean;
};
export function MessageUnreadSeparator(props: Props) {
  const { isFirstUnread } = props;
  return (
    <div className="absolute -top-[22px] flex w-full items-center justify-end">
      <hr
        className={classNames(
          'h-[1px] w-full border-transparent',
          isFirstUnread
            ? 'bg-element-error dark:bg-element-error-dark'
            : 'bg-transparent'
        )}
      />
      <span
        className={classNames(
          'relative z-1 rounded-[3px] bg-element-error px-[8px] py-[2px] text-text-primary-dark dark:bg-element-error-dark',
          isFirstUnread ? 'block' : 'hidden'
        )}
      >
        New
      </span>
    </div>
  );
}
