import { TableRowMember } from '@foundationPathAlias/components/table';
import {
  UserProfileCard,
  UserProfileTooltipWrapper,
} from '@mainApp/src/components/common/user-profile-card';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { UserModel } from '@mainApp/src/stores/User.model';
import { observer } from 'mobx-react-lite';

type UsersTableDisplayNameCellProps = {
  userData?: Partial<UserModel>;
};

const _UsersTableDisplayNameCell = ({
  userData,
}: UsersTableDisplayNameCellProps) => {
  const {
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);

  const handleClose = () => {
    capStore.setBottomSheetConfig({
      content: null,
      show: false,
    });
  };

  const cardContent = (
    <UserProfileCard
      onClose={() => {}}
      onCtaClick={handleClose}
      user={{
        id: userData?.id ?? '',
        username: userData?.username,
        bio: userData?.bio,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        website: userData?.website,
        avatarUrl: userData?.avatarUrl,
      }}
      onSettings={() => {}}
    />
  );

  const handleOpen = () => {
    capStore.setBottomSheetConfig({
      content: cardContent,
      show: true,
    });
  };

  const tableRowContent = (
    <TableRowMember
      displayName={userData?.displayName || ''}
      username={userData?.username || ''}
      src={userData?.avatarUrl}
      cn="min-w-[215px]"
      AvatarProps={{
        cn: 'shrink-0',
      }}
    />
  );

  if (isMobile) {
    return <div onClick={handleOpen}>{tableRowContent}</div>;
  }

  return (
    <UserProfileTooltipWrapper
      on={'hover'}
      position={['bottom left']}
      mouseEnterDelay={600}
      offsetY={8}
      contentStyle={{
        border: 0,
        boxShadow: '0px 1px 40px 0px rgba(26, 26, 26, 0.16)',
      }}
      triggerEl={<div>{tableRowContent}</div>}
    >
      {cardContent}
    </UserProfileTooltipWrapper>
  );
};

export const UsersTableDisplayNameCell = observer(_UsersTableDisplayNameCell);
