import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

import { DiscardActionsEnum, ISidebarModalController } from '../data';
import { ScreenHeader, ScreenHeaderPropsType } from './ScreenHeader';

type Props = {
  title: string;
  rootCn?: string;
  sidebarModalController: ISidebarModalController<any, any>;
  isMobile?: boolean;
  showBackBtn: boolean;
  contentWrapperCn?: string;
  // if you want to use i18n by yourself so you can pass this function
  getTranslatedString?: (key: string) => string;
  onBackBtnPress?: () => void;
  onCloseBtnPress?: () => void;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  ScreenHeaderProps?: Partial<ScreenHeaderPropsType>;
};

export function ScreenWrapper(props: Props) {
  const {
    title,
    children,
    sidebarModalController,
    onBackBtnPress,
    onCloseBtnPress,
    isMobile,
    showBackBtn,
    rootCn,
    ScreenHeaderProps = {},
  } = props;
  props;

  const activeChildScreen = sidebarModalController.activeChildScreen;

  return (
    <>
      <ScreenHeader
        isShowBackBtn={showBackBtn}
        onBackBtnPress={() => {
          if (activeChildScreen?.isDirty) {
            sidebarModalController.showChangesAlertAndSaveAction(
              DiscardActionsEnum.BACK
            );
          } else {
            const screen = sidebarModalController.isInitialScreen
              ? sidebarModalController
              : activeChildScreen;
            screen?.back();
          }

          onBackBtnPress?.();
        }}
        onCloseBtnPress={() => {
          sidebarModalController.setIsShow(false);
          onCloseBtnPress?.();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={title}
        {...ScreenHeaderProps}
      />

      <div
        className={classNames(
          'flex flex-1  overflow-auto pb-[24px] pt-[20px]',
          isMobile ? 'px-[16px]' : 'px-[24px]',
          rootCn
        )}
      >
        {children}
      </div>
    </>
  );
}
