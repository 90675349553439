import { classNames } from '@foundationPathAlias/utilities';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { Header } from '@mainApp/src/modules/account-settings/common/Header';
import React from 'react';
import { DiscardActionsEnum } from '../data/AccountSettings.store.types';
type Props = {
  title: string;
  rootCn?: string;
  showBackBtn: boolean;
  contentWrapperCn?: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
};

export function _ScreenWrapper(props: Props) {
  const { title, children, showBackBtn, rootCn, contentWrapperCn } = props;
  props;
  const { accountSettingsStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const activeStore = accountSettingsStore.activeSettingStore;

  return (
    <>
      <Header
        isShowBackBtn={showBackBtn}
        onBackBtnPress={() => {
          if (accountSettingsStore.isActiveSettingStoreDirty) {
            accountSettingsStore.showChangesAlertAndSaveAction(
              DiscardActionsEnum.BACK
            );
          } else {
            const store = accountSettingsStore.isInitialScreen
              ? accountSettingsStore
              : activeStore;
            store?.back();
          }
        }}
        onCloseBtnPress={() => {
          accountSettingsStore.setShow(false);
        }}
      >
        {title}
      </Header>

      <div
        className={classNames(
          'flex flex-col overflow-auto  pb-[24px] pt-[20px]',
          dimensionsStore.isMobile ? 'px-[16px]' : 'px-[24px]',
          rootCn
        )}
      >
        <section
          className={classNames(
            'themed-layout flex-1 text-left',
            contentWrapperCn
          )}
        >
          {children}
        </section>
      </div>
    </>
  );
}

export const ScreenWrapper = _ScreenWrapper;
