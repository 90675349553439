import { classNames } from '@foundationPathAlias/utilities';
import { CloseIconBtn } from '@mainApp/src/components/common/icons';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  isMobile: boolean;
};
export function DeleteMessageModalHeader(props: Props) {
  const { onClose, isMobile } = props;
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[66px] w-full items-center justify-between p-[24px]">
      <h5
        className={classNames(
          'themed-text text-center text-miniHeadD font-semibold',
          isMobile && 'flex-1'
        )}
      >
        {t('deleteMessage')}
      </h5>

      {!isMobile && (
        <div className="relative flex h-[24px] w-[24px] items-center justify-end">
          <CloseIconBtn className="h-full w-full" onClick={onClose} />
        </div>
      )}
    </div>
  );
}
