import { makeAutoObservable } from 'mobx';
// TODO: Implement an onboarding model

import {
  ChannelPayload,
  Channel_Types,
  User,
} from '@10x/foundation/types/graphql-schema';
import { ChannelGroupModel } from './ChannelGroup.model';

const channelMock = {};

export class ChannelModel {
  type__client: 'grouped' | 'ungrouped' = 'ungrouped';

  serverData: ChannelPayload;

  parentGroupModel: ChannelGroupModel | null = null;

  channelType: Channel_Types = Channel_Types.Messages;

  author: Partial<User> = {};

  static generateMockedChannelModel() {
    return new ChannelModel({ ...channelMock });
  }

  constructor(data: any) {
    makeAutoObservable(this);

    this.serverData = data;
  }

  // for the temporary storing in localStorage
  get asJson() {
    return {
      channelType: this.channelType,
      parentGroupModelId: this.parentGroupModel?.id,
      serverData: {
        ...this.serverData,
      },
    };
  }

  // for BE integration - create community
  get backendPayload() {
    return {};
  }

  setParentGroupModel(groupModel: ChannelGroupModel) {
    this.parentGroupModel = groupModel;
  }
}
