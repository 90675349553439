import { useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type Props = {
  predefinedEditorStateJsonString?: string;
};

export function RestoreStatePlugin(props: Props) {
  const { predefinedEditorStateJsonString } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (predefinedEditorStateJsonString) {
      const initialEditorState = editor.parseEditorState(
        predefinedEditorStateJsonString
      );
      editor.setEditorState(initialEditorState);
    }
  }, [predefinedEditorStateJsonString, editor]);

  return null;
}
