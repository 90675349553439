import { classNames } from '@foundationPathAlias/utilities';
export type SidebarModalPositionerPropsType = {
  cn?: string;
  children: React.ReactNode;
};

export const SidebarModalPositioner = (
  props: SidebarModalPositionerPropsType
) => (
  <div
    className={classNames(
      'themed-layout relative flex h-full max-h-fit w-full max-w-[820px] flex-1 overflow-hidden md:max-h-[650px] md:rounded-[10px]',
      props.cn
    )}
  >
    {props.children}
  </div>
);
