import { DropZoneSVG } from '@10x/foundation/src/components/svg';
import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { observer } from 'mobx-react-lite';

export type Props = {
  isShiftPressed: boolean;
};

export function _DropFileLayer(props: Props) {
  const { isShiftPressed } = props;
  const systemStore = useInjection(IOC_TOKENS.systemStore);
  return (
    <div className="absolute left-0 top-0 z-1 h-full w-full pt-[65px]">
      <section className="relative h-full w-full ">
        <div className="absolute left-0 top-0 h-full w-full bg-background-tetriary opacity-90 dark:bg-background-tetriary-dark"></div>
        <div className="relative top-[50%] z-1 flex -translate-y-[50%] transform flex-col items-center">
          <DropZoneSVG isDarkTheme={systemStore.isDarkTheme} />
          <h1 className="themed-text mt-[50px] text-[36px] text-subheadingSB">
            Upload to Figma Lovers
          </h1>
          <p className="themed-text-secondary mt-[24px] text-body18R">
            Hold{' '}
            <span
              className={classNames(
                'keyboard-btn',
                isShiftPressed ? '!shadow-none' : null
              )}
            >
              Shift
            </span>{' '}
            to share immediatelly to #Design
          </p>
        </div>
      </section>
    </div>
  );
}

export const DropFileLayer = observer(_DropFileLayer);
