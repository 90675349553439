import {
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';

import { classNames } from '@foundationPathAlias/utilities';
import { SquareIconButtonBase } from './SquareIconButtonBase';

export type DotsButtonPropsType = {
  onClick: () => void;
  type?: 'horizontal' | 'vertical';
  className?: string;
  cnWrapper?: string;
  iconWrapperCn?: string;
};

export function DotsButton(props: DotsButtonPropsType) {
  const {
    onClick,
    type = 'horizontal',
    className,
    iconWrapperCn,
    cnWrapper,
  } = props;
  const FinalIcon =
    type === 'horizontal' ? EllipsisHorizontalIcon : EllipsisVerticalIcon;
  return (
    <SquareIconButtonBase
      className={cnWrapper}
      iconWrapperCn={classNames('h-auto w-auto', className)}
      onClick={onClick}
    >
      <FinalIcon className={classNames('w-[22px]', className)} />
    </SquareIconButtonBase>
  );
}
