import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@mainApp/src/components/common';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
export function _Terms() {
  const { t } = useTranslation('common');
  return (
    <ScreenWrapper title={t('terms.label')} showBackBtn={true}>
      <h3>Terms Content</h3>
    </ScreenWrapper>
  );
}

export const Terms = withAnimatedStack(_Terms);
