import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};
export function _JumpToLatestButton(props: Props) {
  const { onClick } = props;
  const { t } = useTranslation('common');
  return (
    <button
      className="floating-btn jump-to-latest dark:material-paper-dark dark:element-subtle-dark absolute -top-[65px] right-0 flex h-[44px] min-w-[44px] cursor-pointer items-center justify-center overflow-hidden rounded-full border-[1px] border-element-subtle py-[10px] shadow-material-paper "
      onClick={onClick}
    >
      <ChevronDownIcon className="mt-[2px] h-[16px] w-[24px]" />
      <div className="floating-btn-text-wrapper themed-text max-w-[0px] overflow-hidden text-body16SB">
        <span className="floating-btn-text">{t('jumpToLatest')}</span>
      </div>
    </button>
  );
}

export const JumpToLatestButton = observer(_JumpToLatestButton);
