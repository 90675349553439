import { classNames } from '@10x/foundation/src/utilities';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

type Props = {
  link: {
    label: string;
    href: string;
  };
  locale?: string;
};

export function NavItem({ link, locale = 'en' }: Props) {
  const { t } = useTranslation('common');
  // const isActivePath = router.asPath === link.href;
  return (
    <Link
      key={link.label}
      href={link.href}
      locale={locale}
      className={classNames('', 'nav')}
    >
      {t(link.label)}
    </Link>
  );
}
