import { ButtonBase } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';

import { useTranslation } from 'react-i18next';

type TabsContentProps = {
  handleSelectOption: (optionIdx: number) => void;
};

type OptionItemProps = {
  onClick: () => void;
  label: string;
  count: number;
};

const OptionItem = ({ onClick, count, label }: OptionItemProps) => {
  return (
    <ButtonBase
      onClick={onClick}
      role="button"
      className="flex justify-between px-[8px] py-[12px]"
    >
      <span className="themed-text text-body16R">{label}</span>

      <span className="rounded-full bg-background-secondary px-[12px] py-[4px] text-sm14T dark:bg-background-secondary-dark">
        {count}
      </span>
    </ButtonBase>
  );
};

export const TabsContent = ({ handleSelectOption }: TabsContentProps) => {
  const { capStore } = useMultipleInjection([IOC_TOKENS.capStore]);
  const { t } = useTranslation(['cap']);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const { membersBlockedCount, membersCount } = usersStoreLocal;

  return (
    <div className="flex flex-col gap-[12px] px-[16px] pb-[24px] pt-[16px]">
      <OptionItem
        label={t('cap:users.tabs.members')}
        count={membersCount}
        onClick={() => handleSelectOption(0)}
      />
      <OptionItem
        label={t('cap:users.tabs.banned')}
        count={membersBlockedCount}
        onClick={() => handleSelectOption(1)}
      />
    </div>
  );
};
