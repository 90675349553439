import * as React from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from 'lexical';

// TODO: test
export function EnterPressPlugin({ onEnter }: any) {
  const [editor] = useLexicalComposerContext();
  // editor.update(() => {
  //   const html = $generateHtmlFromNodes(editor, null)
  //   setHtml(html)
  // })

  React.useEffect(() => {
    const unsubEnterCmd = editor.registerCommand(
      KEY_ENTER_COMMAND,
      (payload: any) => {
        const event = payload;
        event.preventDefault();
        if (event.shiftKey) {
          return false;
        } else {
          onEnter?.();
          return true;
        }

        // return editor.dispatchCommand(
        //   event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
        // );
      },
      COMMAND_PRIORITY_HIGH
    );

    return unsubEnterCmd;
  }, [editor]);

  return null;
}
