import {
  getIsLowContrastColor,
  hexToRGBValuesObject,
} from '@10x/foundation/src/utilities';

type ThemeVariant = 'dm' | 'lm';

export type GetColorContrastValidatorErrorKeys =
  | 'invalidHexFormatError'
  | 'tooLightColorError'
  | 'tooDarkColorError'
  | 'useAlternativeColorLm'
  | 'useAlternativeColorDm';

export type GetColorContrastValidatorErrorMessages = Record<
  GetColorContrastValidatorErrorKeys,
  string
>;

export type GetColorContrastValidatorParams = {
  type: ThemeVariant;
  onSetColorClick: () => void;
  errorMessages: GetColorContrastValidatorErrorMessages;
};

export const getColorContrastValidator =
  ({ errorMessages, onSetColorClick, type }: GetColorContrastValidatorParams) =>
  (hex: string) => {
    const rgba = hexToRGBValuesObject(hex);

    if (!rgba) {
      return (
        <span className="hover-el text-primary-100 dark:text-text-secondary">
          {errorMessages.invalidHexFormatError}
        </span>
      );
    }
    const isLowContrastColor = getIsLowContrastColor(rgba, type);
    let err = null;

    const errorMsg =
      type === 'lm'
        ? errorMessages.tooLightColorError
        : errorMessages.tooDarkColorError;

    if (isLowContrastColor) {
      err = (
        <p className="text-sm14R">
          {errorMsg}{' '}
          <button
            className="hover-el text-primary-100"
            onClick={onSetColorClick}
          >
            {type === 'lm'
              ? errorMessages.useAlternativeColorLm
              : errorMessages.useAlternativeColorDm}
          </button>
        </p>
      );
    }

    return err;
  };
