import { SubscriptionList } from '@mainApp/src/modules/account-settings/common/';
import { observer } from 'mobx-react-lite';

import { Separator } from '@mainApp/src/components/post-auth-onboarding/common';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useEffect } from 'react';

// TODO: loader for it
export function _UsernameSubscription() {
  const userStore = useInjection(IOC_TOKENS.userStore);
  useEffect(() => {
    userStore.getAccountProUsernameSubscriptionTypes();
  }, []);
  return (
    <>
      <Separator cn="mt-0 mb-[24px]" />
      <SubscriptionList />
    </>
  );
}

export const UsernameSubscription = observer(_UsernameSubscription);
