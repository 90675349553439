import { useDetectScrollEnd } from '@10x/foundation/src/utilities/hooks';
import { classNames } from '@foundationPathAlias/utilities';
import { useCallback, useEffect, useRef } from 'react';

import { useResizeObserver } from '@foundationPathAlias/utilities/hooks';

export type InfinityScrollerPropsType = {
  maxHeight: number;
  children: JSX.Element | JSX.Element[];
  onScrollEndChange: (isDetectedScrollEnd: boolean) => void;
  /** could be used to detect a content resize and trigger the
   * fetch more action if the content at the inital loading if
   * the scrollbar isn't appeared but there is more data could be loaded
   *
   * scrollbarAppeared - true if the scrollbar appeared.
   * Theoretically you should use this marker to do a fetch more action
   * only if there is not scrollbar
   */
  onContentResize?: (
    scrollbarAppeared: boolean,
    contentRect: DOMRectReadOnly
  ) => void;
  /** 0-1 range. Percentage of the available scroll height to detect scroll end that will be substracted from the end */
  threshold?: number;
  cn?: string;
};

export function InfinityScroller(props: InfinityScrollerPropsType) {
  const {
    maxHeight,
    cn,
    children,
    onScrollEndChange,
    onContentResize,
    threshold,
  } = props;

  const contentElRef = useRef<HTMLDivElement>(null);
  const {
    ref: scrollRef,
    scrollEnd
  } = useDetectScrollEnd(threshold);

  useEffect(() => {
    onScrollEndChange(scrollEnd);
  }, [scrollEnd]);

  const onResizeCb = useCallback((entries: ResizeObserverEntry[]) => {
    const entry = entries[0];
    const scrollEl = scrollRef.current;

    if (entry.contentRect && scrollEl) {
      const isScrollBarAppeared = Boolean(
        scrollEl.scrollHeight - scrollEl?.clientHeight
      );
      onContentResize?.(isScrollBarAppeared, entry.contentRect);
    }
  }, []);

  useResizeObserver(contentElRef.current as HTMLDivElement, onResizeCb);

  return (
    <div
      ref={scrollRef as any}
      className={classNames('overflow-auto', cn)}
      style={{
        maxHeight,
      }}
    >
      <div ref={contentElRef} className="h-full w-full">
        {children}
      </div>
    </div>
  );
}
