import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepContentTitle } from '../StepContentTitle';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { CommunityLogoPreview } from './CommunityLogoPreview';
import { CommunityPreviewHeader } from './CommunityPreviewHeader';

export function _ChannelPreview() {
  const { createCommunityStepperStore, systemStore } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.systemStore,
  ]);
  const {
    steps: { step2: step2Store, step3: step3Store, step5: step5Store },
  } = createCommunityStepperStore;

  const { t } = useTranslation(['common', 'create-community']);

  const { isDarkTheme } = systemStore;

  const { croppedFileUrl } = step2Store.logo;

  return (
    <>
      <StepContentTitle
        title={t('logo')}
        description={t('create-community:channelPreviewStepContentDescr')}
      />
      <div className="relative flex bg-background-tetriary dark:bg-background-tetriary-dark">
        <div className="h-full w-[76px] space-y-[14px] bg-background-tetriary px-[16px] pb-[16px] pt-[26px]  dark:bg-background-tetriary-dark ">
          <CommunityLogoPreview active logoSrc={croppedFileUrl as any} />
          <CommunityLogoPreview
            // TODO: maybe use some R2 bucket instead of local images
            logoSrc="/images/to_delete/sketch_logo.jpg"
            notificationsCount="9+"
          />
          <CommunityLogoPreview
            logoSrc="/images/to_delete/community_logo_1.jpg"
            notificationDot
          />
          <CommunityLogoPreview
            logoSrc="/images/to_delete/community_logo_2.jpg"
            notificationsCount="8"
          />
          <CommunityLogoPreview logoSrc="/images/to_delete/community_logo_3.jpg" />
        </div>
        <div className="themed-layout flex-1 pb-[16px]">
          <div className="border-b-[1px] border-element-subtle py-[20px] pl-[16px] pr-[20px] dark:border-element-subtle-dark">
            <CommunityPreviewHeader
              communityName={step3Store.communityName.value}
            />
          </div>

          <div className="flex flex-col border-b-[1px] border-element-subtle py-[20px] pl-[16px] pr-[20px] dark:border-element-subtle-dark">
            <span className="mb-3 text-body16R">
              {step3Store.description.value}
            </span>
            <div className="max-[335px]:text-[14px] flex items-center text-body16SB">
              {t('members', { count: 168 })}
              <span className="mx-[3px] mt-[3px] block h-[3px] w-[3px] rounded-full bg-text-primary dark:bg-text-primary-dark" />
              24 {t('online')}
            </div>
            {/* TODO: Button Component */}
            <button
              className={
                'themed-text-inverted body18SB mt-[16px] flex w-full items-center justify-center rounded-[2px] bg-primary-100 px-[16px] py-[9px]'
              }
              style={{
                backgroundColor: isDarkTheme
                  ? step5Store.dmPrimaryColor?.rgba ||
                    step5Store.initialPrimaryColor.rgba
                  : step5Store.lmPrimaryColor?.rgba ||
                    step5Store.initialPrimaryColor.rgba,
              }}
            >
              {t('joinCommunity')}
            </button>
          </div>
          <div className="pl-[16px] pr-[20px] pt-[20px] ">
            <h5 className="themed-text text-body18SB">{t('general')}</h5>
          </div>
        </div>
        <span className="bg-gradient-opacity-top-to-bottom absolute left-0 top-0 h-full w-full " />
      </div>
    </>
  );
}

export const ChannelPreview = observer(_ChannelPreview);
