import { ReactNode } from 'react';

export type GridItemValueProps = {
  label: string;
  value?: string | number | ReactNode;
};

export const GridItemValue = ({ value, label }: GridItemValueProps) => {
  return (
    <div className="flex flex-col items-start">
      <b className="themed-text text-sm14T">{label}</b>
      <span className="themed-text-secondary text-sm14R">{value}</span>
    </div>
  );
};
