import { CommunityCard } from '@foundationPathAlias/components/community-card';

import { useTranslation } from 'react-i18next';

import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

export function _YourCommunityCard({
  cn,
  loadImageOnPriority,
}: {
  cn?: string;
  loadImageOnPriority?: boolean;
}) {
  const {
    systemStore,
    createCommunityStepperStore: {
      steps: {
        step1: step1Store,
        step2: step2Store,
        step3: step3Store,
        step4: step4Store,
        step5: step5Store,
      },
      createdCommunitySlug,
    },
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.createCommunityStepperStore,
  ]);

  const router = useRouter();
  const { t } = useTranslation(['common', 'create-community']);

  const { isDarkTheme } = systemStore;

  const communityCardName: string =
    step3Store.communityName.value || t('yourCommunity');
  const description: string =
    step3Store.description.value || t('create-community:communityDescription');
  return (
    <CommunityCard
      active
      communityColor={
        isDarkTheme
          ? step5Store.dmPrimaryColor?.pureRgba ||
            step5Store.initialPrimaryColor.pureRgba
          : step5Store.lmPrimaryColor?.pureRgba ||
            step5Store.initialPrimaryColor.pureRgba
      }
      name={communityCardName}
      description={description}
      membersCount={0}
      imageSrc={step1Store.thumbnail.croppedFileUrl as string}
      logoSrc={step2Store.logo.croppedFileUrl as string}
      topics={[
        {
          id: 0,
          name: step4Store.topics.topic1.value,
        },
        {
          id: 1,
          name: step4Store.topics.topic2.value,
        },
      ]}
      isDarkMode={isDarkTheme}
      cn={cn}
      onClick={() => {
        if (!createdCommunitySlug) return;
        router.push(`${paths.root}/${createdCommunitySlug}`);
      }}
      loadImageOnPriority={loadImageOnPriority}
    />
  );
}

export const YourCommunityCard = observer(_YourCommunityCard);
