import { useMemo } from 'react';

import {
    PassedPeriods,
    PassedTimeResult,
    formatCreationTime,
} from '@foundationPathAlias/utilities';
import { useTranslation } from 'react-i18next';


export function useFormatCreatedAt(createdAt: PassedTimeResult) {

    const { t } = useTranslation('common');

    const res = useMemo(() => {
        const periodFormatters = {
            [PassedPeriods.SECONDS]: (value: number | string) =>
                t('time.passedSeconds', {
                    value: value as number,
                }) as string,
            [PassedPeriods.MINUTES]: (value: number | string) =>
                t('time.passedMinutes', {
                    value: value as number,
                }) as string,
            [PassedPeriods.HOURS]: (value: number | string) =>
                t('time.passedHours', {
                    value: value as number,
                }) as string,
            [PassedPeriods.DAYS]: (value: number | string) =>
                t('time.passedDays', {
                    value: value as number,
                }) as string,
        };

        return formatCreationTime(createdAt, periodFormatters);
    }, [createdAt]);

    return res;
}