import { classNames } from '@foundationPathAlias/utilities';

export type BadgeDotPropsType = {
  label: string;
  type?: 'normal' | 'warning' | 'negative';
  icon?: JSX.Element;
  cn?: string;
  defaultDotWrapperCn?: string;
  defaultIconCn?: string;
};

export function BadgeDot(props: BadgeDotPropsType) {
  const { label, type = 'normal', cn, icon, defaultDotWrapperCn } = props;

  let rootDynamicCn;
  let iconDynamicCn;

  switch (type) {
    case 'negative':
      rootDynamicCn =
        'bg-background-secondary dark:bg-background-quaternary-dark text-text-secondary dark:text-text-secondary-dark';
      iconDynamicCn = 'bg-text-secondary dark:bg-text-secondary-dark';
      break;
    case 'warning':
      rootDynamicCn =
        'bg-element-warning-10 dark:bg-element-warning-10-dark text-element-warning dark:text-element-warning-dark';
      iconDynamicCn = 'bg-element-warning dark:bg-element-warning-dark';
      break;
    default:
      rootDynamicCn =
        'bg-primary-10 dark:bg-primary-10-dark text-primary-100 dark:text-primary-100-dark';
      iconDynamicCn = 'bg-primary-100 dark:bg-primary-100-dark';
      break;
  }

  const finalIcon = icon || (
    <span
      className={classNames('mr-[4px] flex items-center ', defaultDotWrapperCn)}
    >
      <i
        className={classNames(
          'block h-[8px] w-[8px] rounded-full',
          iconDynamicCn
        )}
      />
    </span>
  );

  return (
    <div
      className={classNames(
        'text-sm13DM inline-flex w-auto rounded-[12px]  py-[2px] pl-[8px] pr-[10px]',
        rootDynamicCn,
        cn
      )}
    >
      {finalIcon}
      {label}
    </div>
  );
}
