import { type Row } from '@foundationPathAlias/components/table';
import { ButtonBase, TextInput } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { RolesEnum } from '@10x/foundation/types';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { type TableUser } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/types';
import { type IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { observer } from 'mobx-react-lite';

type AddRolesTooltipContentProps = {
  row: Row<Partial<TableUser>>;
};

const _AddRolesTooltipContent = ({ row }: AddRolesTooltipContentProps) => {
  const userId = row.original.id || '';
  const { t } = useTranslation();

  const {
    communityStore,
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.capStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const userStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const isOwnerUser = communityStore.activeCommunity.data?.isUserOwner;

  const checkedRoleIds = userStoreLocal.selectedRolesByUser[userId] || [];

  const allRoles = communityStore.rolesList;

  const [filteredRoles, setFilteredRoles] = useState(allRoles);

  const [query, setQuery] = useState('');

  useDebounce(
    () => {
      setFilteredRoles(
        allRoles.filter((role) =>
          role.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
      );
    },
    200,
    [query]
  );

  const handleAddRole = (roleId: string) => {
    userStoreLocal.addRoleToUser(userId, roleId, !isMobile);
  };

  const handleRemoveRole = (roleId: string) => {
    userStoreLocal.removeRoleFromUser(userId, roleId, !isMobile);
  };

  return (
    <div className="themed-text w-full">
      <div className="px-[16px] md:p-0">
        <TextInput
          labelCn="md:border-0 dark:bg-transparent items-center"
          inputCn="dark:bg-transparent"
          onChange={(val) => setQuery(val)}
          leftSideContent={
            <MagnifyingGlassIcon
              className="text-element-normal dark:text-element-normal-dark"
              width={20}
              height={20}
            />
          }
          placeholder={t<string>('cap:users.searchRoles')}
        />
      </div>

      <hr className="hidden border-t-element-subtle dark:border-t-element-subtle-dark md:block" />

      {!filteredRoles.length ? (
        <div className="my-[8px] py-[8px] text-center">
          <p className="themed-text text-sm14SB">
            {t<string>('cap:users.noRolesFound')}
          </p>
        </div>
      ) : (
        <ul className="my-[8px] max-h-[70vh] overflow-x-auto">
          {filteredRoles
            .filter(
              (role) =>
                isOwnerUser ||
                role.level.toLocaleLowerCase() !==
                  RolesEnum.Owner.toLocaleLowerCase()
            )
            .map((role) => {
              const checked = checkedRoleIds.some(
                (selectedRole) => selectedRole.id === role.id
              );

              return (
                <ButtonBase
                  cn="w-full p-0 hover:bg-hovered-selected dark:hover:bg-hovered-selected-dark"
                  onClick={() => {
                    if (checked) {
                      handleRemoveRole(role.id);
                    } else {
                      handleAddRole(role.id);
                    }
                  }}
                  key={role.id}
                >
                  <div className="flex flex-row-reverse items-center justify-between px-[16px] py-[8px] md:flex-row md:justify-start">
                    <div
                      className={classNames(
                        'flex size-[20px] items-center justify-center rounded-full border border-[#B4B4B4]',
                        {
                          'border-primary-100 bg-primary-100': checked,
                        }
                      )}
                    >
                      {checked && (
                        <CheckIcon
                          className="text-text-primary-dark"
                          width={13}
                          height={13}
                        />
                      )}
                    </div>

                    <div className="flex items-center">
                      {/* TODO: this img below will be uncommented when we have the possibility to add images to roles */}
                      {/* <img
                      className="mr-[4px] md:ml-[12px]"
                      width={28}
                      height={28}
                      src={MOCKED_SRC_ROLES.ADMIN}
                      alt="role's icon"
                    /> */}

                      <span className="themed-text ml-[4px] text-sm14R md:ml-[12px]">
                        {role.name}
                      </span>
                    </div>
                  </div>
                </ButtonBase>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export const AddRolesTooltipContent = observer(_AddRolesTooltipContent);
