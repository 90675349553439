import axios from 'axios';
import { inject, injectable } from 'inversify';
import {
  BLOCK_USER,
  CANCEL_USER_CHANGE_EMAIL_REQUEST,
  CHECK_USERNAME_AVAILABILITY,
  CREATE_PRO_USERNAME,
  CREATE_PRO_USER_SUBSCRIPTION,
  DEACTIVATE_PRO_USER_SUBSCRIPTION,
  DEACTIVATE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  GENERATE_USER_PRE_SIGNED_POST_URL,
  GET_ACCOUNT_PRO_USERNAME_SUBSCRIPTION_TYPES,
  GET_CURRENT_USER_DATA,
  GET_USER,
  GET_USERS,
  GET_USER_CHANGE_EMAIL_REQUESTS,
  GET_USER_GENERAL_SETTINGS,
  GET_USER_PRO_SUBSCRIPTION,
  PRE_UPDATE_USER_EMAIL,
  REACTIVATE_PRO_USER_SUBSCRIPTION,
  RESEND_USER_CHANGE_EMAIL_REQUEST,
  SAVE_PRO_USERNAME,
  SEND_DEACTIVATE_USER_ACCOUNT_EMAIL,
  SEND_DELETE_USER_ACCOUNT_EMAIL,
  UNBLOCK_USER,
  UPDATE_CURRENT_USER_DATA,
  UPDATE_PRO_USERNAME,
  UPDATE_USERNAME,
  UPDATE_USER_EMAIL,
  UPDATE_USER_GENERAL_SETTINGS,
  VALIDATE_PRO_USERNAME,
  VALIDATE_USER_EMAIL
} from '../graphql/queries';
import { Client } from './types';

import { UserGeneralSettingsInput } from '@10x/foundation/types';

import { IOC_TOKENS } from '../ioc';
import {
  GetUsersQueyParamsType,
  IUserRepository,
  UserUpdateData,
} from './User.repository.types';

export * from './User.repository.types';

@injectable()
export class UserRepository implements IUserRepository {
  gqlClient;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
  }

  async updateCurrentUser(userData: UserUpdateData) {
    const response = await this.gqlClient
      .mutation(UPDATE_CURRENT_USER_DATA, { ...userData })
      .toPromise();

    const { data, error } = response;

    return {
      data: data.updateUserProfile,
      error: error,
      originalResponse: response,
      pageInfo: null,
    };
  }

  async getCurrentUser() {
    const response = await this.gqlClient
      .query(GET_CURRENT_USER_DATA, {}, { requestPolicy: "network-only" })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data?.me,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };
    return res;
  }

  async uploadAvatarImage(file: File) {
    const response = await this.gqlClient
      .mutation(GENERATE_USER_PRE_SIGNED_POST_URL, {})
      .toPromise();

    const { data, error } = response;

    const { url } = data?.generateUserPreSignedPostUrl;
    const formData = new FormData();
    formData.append('file', file);

    const responseImage = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const res = {
      data: responseImage.data.result,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  async getUsers(params: GetUsersQueyParamsType) {
    const response = await this.gqlClient.query(GET_USERS, params).toPromise();
    const { data, error } = response;
    const res = {
      data: data?.users?.edges,
      error: error,
      pageInfo: data?.pageInfo,
      originalResponse: response,
    };
    return res;
  }

  async getUser(id: string) {
    const response = await this.gqlClient
      .query(GET_USER, {
        id,
      })
      .toPromise();
    const { data, error } = response;
    const res = {
      data: data?.user,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };
    return res;
  }

  async uploadAvatar(
    file: File,
    // callback?: (uploadPercent: number, uploadedImgUrlId: string) => void,
    // id?: string
  ) {
    const response = await this.gqlClient
      .mutation(GENERATE_USER_PRE_SIGNED_POST_URL, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data } = response;

    const { url } =
      data?.generateUserPreSignedPostUrl;
    const formData = new FormData();
    formData.append('file', file);

    return await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }


  createProUsername = async (username: string) => {
    const response = await this.gqlClient
      .mutation(CREATE_PRO_USERNAME, { username })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.createProUsername,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  // Probably should be in the User repository?
  updateUsername = async (username: string) => {
    const response = await this.gqlClient
      .mutation(UPDATE_USERNAME, { username })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.updateUsername,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  updateProUsername = async (username: string) => {
    const response = await this.gqlClient
      .mutation(UPDATE_PRO_USERNAME, { username })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.updateProUsername,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  checkUsernameAvailability = async (username: string) => {
    const response = await this.gqlClient
      .query(CHECK_USERNAME_AVAILABILITY, { username })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.checkUsernameAvailability,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  validateProUsername = async (username: string) => {
    const response = await this.gqlClient
      .mutation(VALIDATE_PRO_USERNAME, { username })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.validateProUsername,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  createProUserSubscription = async (priceId: string) => {
    const response = await this.gqlClient
      .mutation(CREATE_PRO_USER_SUBSCRIPTION, { priceId })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.createProUserSubscription,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  deactivateProUserSubscription = async () => {
    const response = await this.gqlClient
      .mutation(DEACTIVATE_PRO_USER_SUBSCRIPTION, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.deactivateUserSubscription,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  reactivateProUserSubscription = async () => {
    const response = await this.gqlClient
      .mutation(REACTIVATE_PRO_USER_SUBSCRIPTION, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.reactivateProUserSubscription,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  getUserProSubscription = async () => {
    const response = await this.gqlClient
      .query(GET_USER_PRO_SUBSCRIPTION, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.userProSubscription,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  preUpdateUserEmail = async (email: string) => {
    const response = await this.gqlClient
      .mutation(PRE_UPDATE_USER_EMAIL, { data: { email } })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.preUpdateUserEmail,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  saveProUsername = async (username: string) => {
    const response = await this.gqlClient
      .mutation(SAVE_PRO_USERNAME, { username })
      .toPromise();

    const res = {
      data: response.data?.saveProUsername,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  updateUserGeneralSettings = async (data: UserGeneralSettingsInput) => {
    const response = await this.gqlClient
      .mutation(UPDATE_USER_GENERAL_SETTINGS, {
        data
      })
      .toPromise();

    const res = {
      data: response.data?.updateUserGeneralSettings,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  sendDeactivateUserAccountEmail = async () => {
    const response = await this.gqlClient
      .mutation(SEND_DEACTIVATE_USER_ACCOUNT_EMAIL, {})
      .toPromise();

    const res = {
      data: response.data?.sendDeactivateUserAccountEmail,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  deactivateUserAccount = async (otpCode: string) => {
    const response = await this.gqlClient
      .mutation(DEACTIVATE_USER_ACCOUNT, {
        data: {
          otpValue: otpCode
        }
      })
      .toPromise();

    const res = {
      data: response.data?.deactivateUserAccount,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  sendDeleteUserAccountEmail = async (reason: string) => {
    const response = await this.gqlClient
      .mutation(SEND_DELETE_USER_ACCOUNT_EMAIL, {
        data: {
          reason
        }
      })
      .toPromise();

    const res = {
      data: response.data?.sendDeleteUserAccountEmail,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  deleteUserAccount = async (otpCode: string) => {
    const response = await this.gqlClient
      .mutation(DELETE_USER_ACCOUNT, {
        data: {
          otpValue: otpCode
        }
      })
      .toPromise();

    const res = {
      data: response.data?.deactivateUserAccount,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  getUserChangeEmailRequests = async () => {
    const response = await this.gqlClient
      .query(GET_USER_CHANGE_EMAIL_REQUESTS, {}, { requestPolicy: "network-only" })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.userChangeEmailRequests,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  validateUserEmail = async (email: string) => {
    const response = await this.gqlClient
      .mutation(VALIDATE_USER_EMAIL, {
        email
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.validateUserEmail,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  cancelUserChangeEmailRequest = async (changeEmailRequestId: string) => {
    const response = await this.gqlClient
      .mutation(CANCEL_USER_CHANGE_EMAIL_REQUEST, { id: changeEmailRequestId })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.cancelUserChangeEmailRequest,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  resendUserChangeEmailRequest = async (changeEmailRequestId: string) => {
    const response = await this.gqlClient
      .mutation(RESEND_USER_CHANGE_EMAIL_REQUEST, { id: changeEmailRequestId })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.resendUserChangeEmailRequest,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  updateUserEmail = async (optCode: string) => {
    const response = await this.gqlClient
      .mutation(UPDATE_USER_EMAIL, {
        data: {
          otpValue: optCode
        }
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.updateUserEmail,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  getUserGeneralSettings = async () => {
    const response = await this.gqlClient
      .query(GET_USER_GENERAL_SETTINGS, {})
      .toPromise();

    const res = {
      data: response.data?.userGeneralSettings,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  getAccountProUsernameSubscriptionTypes = async () => {
    const response = await this.gqlClient
      .query(GET_ACCOUNT_PRO_USERNAME_SUBSCRIPTION_TYPES, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?


    const res = {
      data: response.data?.accountProUsernameSubscriptionTypes,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  blockUser = async (communityId: string, userId: string) => {
    const response = await this.gqlClient
      .mutation(BLOCK_USER, {
        communityId,
        userId,
      })
      .toPromise();

    const res = {
      data: response.data?.blockUser,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }

  unblockUser = async (communityId: string, userId: string) => {
    const response = await this.gqlClient
      .mutation(UNBLOCK_USER, {
        communityId,
        userId,
      })
      .toPromise();

    const res = {
      data: response.data?.unblockUser,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
}
