import { action, computed, makeObservable, observable } from 'mobx';
import { ColorType } from '../components/common/community-colors/types';
import type { ISystemStore } from './types';

export const initialColor = {
  rgba: `rgba(101, 99, 255, 1)`,
  pureRgba: {
    r: 101,
    g: 99,
    b: 255,
    a: 1,
  },
  hex: '#6563FF',
};

export class CommunityColorsModel implements ICommunityColors {
  lmPrimaryColor: ColorType | null = null;
  dmPrimaryColor: ColorType | null = null;
  initialPrimaryColor = { ...initialColor };
  systemStore: ISystemStore;

  get primaryColor() {
    const { isDarkTheme } = this.systemStore;

    if (isDarkTheme) {
      return this.dmPrimaryColor || this.initialPrimaryColor;
    }

    return this.lmPrimaryColor || this.initialPrimaryColor;
  }

  constructor(systemStore: ISystemStore) {
    makeObservable(this, {
      dmPrimaryColor: observable,
      lmPrimaryColor: observable,
      primaryColor: computed,
      initialPrimaryColor: observable,
      setColor: action,
      initDefaultColors: action,
      setDmPrimaryWhite: action,
      setLmPrimaryBlack: action,
    });

    this.systemStore = systemStore;
  }

  private setDefaultContrastColors = (type: 'dm' | 'lm') => {
    const whiteForDm = {
      rgba: `rgba(255, 255, 255, 1)`,
      pureRgba: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      hex: '#FFFFFF',
    };
    const blackForLm = {
      rgba: `rgba(0, 0, 0, 1)`,
      pureRgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      hex: '#000000',
    };

    if (type === 'lm') {
      this.setColor('lm', blackForLm);
    } else {
      this.setColor('dm', whiteForDm);
    }
  };

  setColor = (type: 'dm' | 'lm', val: ColorType) => {
    const key: 'lmPrimaryColor' | 'dmPrimaryColor' = `${type}PrimaryColor`;

    this[key] = {
      ...val,
      hex: val.hex.toUpperCase(),
    };
  };
  setLmPrimaryBlack(): void {
    this.setDefaultContrastColors('lm');
  }
  setDmPrimaryWhite(): void {
    this.setDefaultContrastColors('dm');
  }
  initDefaultColors(): void {
    if (!this.dmPrimaryColor) {
      this.setColor('dm', initialColor);
    }

    if (!this.lmPrimaryColor) {
      this.setColor('lm', initialColor);
    }
  }
}

export interface ICommunityColors {
  readonly systemStore: ISystemStore;

  readonly lmPrimaryColor: ColorType | null;
  readonly dmPrimaryColor: ColorType | null;
  readonly primaryColor: ColorType;

  readonly initialPrimaryColor: ColorType;

  setColor: (type: 'dm' | 'lm', value: ColorType) => void;
  setLmPrimaryBlack(): void;
  setDmPrimaryWhite(): void;
  initDefaultColors(): void;
}
