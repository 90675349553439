/* eslint-disable @next/next/no-img-element */
import { classNames } from '@foundationPathAlias/utilities';
import { AvatarSVG } from '../svg';
export type AvatarPropsType = {
  cn?: string;
  /** if it isn't passed the default AvatarSVG will be rendered */
  src?: string | null;
  /** use it if you want to add the custom classes for image if src passed */
  imageCn?: string;
  alt?: string;
  darkMode?: boolean;
  width?: number;
  height?: number;
};

export function Avatar(props: AvatarPropsType) {
  const {
    cn = '',
    imageCn,
    src,
    alt = 'Avatar image',
    darkMode,
    width,
    height,
  } = props;

  let content = <AvatarSVG darkMode={darkMode} width={width} height={height} />;

  if (src) {
    content = (
      <img
        className={classNames(
          'h-full w-full rounded-full object-cover',
          imageCn
        )}
        alt={alt}
        src={src}
      />
    );
  }

  return (
    <div
      className={cn}
      style={{
        width,
        height,
      }}
    >
      {content}
    </div>
  );
}
