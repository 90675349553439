import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  title: string;
  description: string;
  children: JSX.Element | JSX.Element[];
  rootCn?: string;
  titleCn?: string;
  descriptionCn?: string;
};
export function StepsWrapper(props: Props) {
  const { title, description, children, rootCn, titleCn, descriptionCn } =
    props;
  return (
    <section className={classNames('text-left md:pr-[6px]', rootCn)}>
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {title}
      </h6>
      <p
        className={classNames(
          'themed-text-secondary mb-[16px] min-h-[72px] text-body16R',
          descriptionCn
        )}
      >
        {description}
      </p>
      {children}
    </section>
  );
}
