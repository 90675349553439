import { v4 as uuidv4 } from 'uuid';

const ChannelMessagePayloadMock = {
  id: 'optimistic_channel_message_payload_id',
  text: 'Optimistic text',
  rawJson:
    '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Optimistic text","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}',
  contentType: 'REGULAR',
  parentId: null,
  parent: null,
  channelId: 'optimistic_channel_id',
  threadId: null,
  read: true,
  authorId: 'optimistic_author_id',
  communityId: 'optimistic_community_id',
  createdAt: Date.now(),
  attachments: [],
  reactions: [],
  author: {},
  __typename: 'ChannelMessagePayload',
};

// TODO: type
export const generateChannelMessagePayload = (payload = {}) => {
  const id = uuidv4();
  return {
    ...ChannelMessagePayloadMock,
    id: `optimistic_channel_message_payload-${id}`,
    createdAt: Date.now(),
    ...payload,
  };
};
