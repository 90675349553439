

import { createPubSub } from "@foundationPathAlias/utilities";
import { ACTIONS } from "../data/actionConstants";

export const SidebarModalEventsInternal = {
    [ACTIONS.SET_CHANGES_ALERT_CONFIG]: Symbol('SET_CHANGES_ALERT_CONFIG'),
    [ACTIONS.SET_SIDEBAR_MODAL]: Symbol('SET_SIDEBAR_MODAL'),
    [ACTIONS.SET_DISCARD_ACTIONS]: Symbol('SET_DISCARD_ACTIONS'),
    [ACTIONS.SET_ACTIVE_DISCARD_ACTION]: Symbol('SET_ACTIVE_DISCARD_ACTION'),
    [ACTIONS.SHOW_CHANGES_ALERT_AND_SAVE_ACTION]: Symbol('SHOW_CHANGES_ALERT_AND_SAVE_ACTION'),
    [ACTIONS.SET_SHOW]: Symbol('SHOW_UPDATED'),
    [ACTIONS.SET_BOTTOM_SHEET_CONFIG]: Symbol('BOTTOM_SHEET_CONFIG_UPDATED'),
};




export type SidebarModalEventsType = typeof SidebarModalEventsInternal[keyof typeof SidebarModalEventsInternal];

export const sidebarModalEventBus = createPubSub<SidebarModalEventsType>();

export const emitEvent = <T>(event: SidebarModalEventsType, payload?: T) => {
    sidebarModalEventBus.emit(event, payload);
};
