import ContentLoader from 'react-content-loader';
import { loaderColors } from './loaderColors';

export const MentionUserPopoverLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={340}
    height={454}
    viewBox="0 0 340 454"
    backgroundColor={loaderColors.backgroundColor}
    foregroundColor={loaderColors.foregroundColor}
    {...props}
  >
    <circle cx="170" cy="84" r="60" />
    <rect x="111" y="158" rx="0" ry="0" width="122" height="30" />
    <rect x="77" y="191" rx="0" ry="0" width="196" height="20" />
    <rect x="24" y="245" rx="0" ry="0" width="58" height="22" />
    <rect x="24" y="280" rx="0" ry="0" width="292" height="76" />
    <rect x="24" y="381" rx="0" ry="0" width="156" height="24" />
    <rect x="24" y="409" rx="0" ry="0" width="221" height="20" />
  </ContentLoader>
);
