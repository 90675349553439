import { classNames } from '@foundationPathAlias/utilities';
type Props = {
  cn?: string;
};
export function Separator(props: Props) {
  const { cn } = props;
  return (
    <hr
      className={classNames(
        'my-[12px] bg-element-subtle dark:bg-element-subtle-dark',
        cn
      )}
    />
  );
}
