import { useEffect, useRef, useState } from 'react';

/**
 * this hook for the image loaded. It triggers only once
 * when the `loaded` had been changed from false to true.
 *
 * if the `loaded` prop was changed to `true` faster than
 * the minimal rendering time - it won't update the loaded state to true as well
 *
 * it will wait the minimal time and then will be changed to true. If the *
 * timeout had finished but the `loaded` prop hasn't been changed so when it
 * will be changed the timeout won't be executed
 *
 * used in ImageWithPlaceholder
 *
 * @param isOuterLoaded
 * @param minRenderTime
 * @returns
 */
export function useMinLoaded(isOuterLoaded: boolean, minRenderTime = 350) {
  const [loaded, setLoaded] = useState(false);
  const wasLoadedRef = useRef<null | Boolean>(false);
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  // to make the updated value available for the timeout callback
  const outerLoadedRef = useRef<null | boolean>(false);

  useEffect(() => {
    outerLoadedRef.current = isOuterLoaded;
    // if it had been loaded no need to do anything more
    if (wasLoadedRef.current) {
      return;
    }
    // it isn't loaded
    if (!outerLoadedRef.current) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        // it had been loaded earlier, now can set it
        if (outerLoadedRef.current) {
          setLoaded(true);
          wasLoadedRef.current = true;
        }
      }, minRenderTime);
    } else {
      // loaded. check if the time is passed normally
      if (!timeoutRef.current) {
        // min time has passed so can just set the loaded
        setLoaded(true);
        wasLoadedRef.current = true;
      }
    }
  }, [isOuterLoaded]);

  return loaded;
}
