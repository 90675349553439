import { enableStaticRendering } from 'mobx-react-lite';
enableStaticRendering(typeof window === 'undefined');

import {
  BaseFieldData,
  UserChangeEmailRequestType,
} from '@10x/foundation/types';
import { IAnimatedStackStore } from '@mainApp/src/components/common';
import { AccountSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';
import { IRedirectService, IStorageService } from '@mainApp/src/services';
import { IUserStore, SubscriptionTypeEnum } from '@mainApp/src/stores';
import { IReactionDisposer } from 'mobx';
import { accountSettingsComponents } from './accountSettingsComponents';

export enum SettingsDataNamesEnum {
  USERNAME = 'username',
  DISPLAY_NAME = 'displayName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  DELETE = 'delete',
}

export type Setting = BaseFieldData<string>;

export enum DeleteStepEnum {
  INITIAL = 'INITIAL',
  FINAL = 'FINAL',
}

export type SettingsData = {
  [SettingsDataNamesEnum.USERNAME]: Partial<Setting> & {
    activeSubscriptionType: SubscriptionTypeEnum;
  };
  [SettingsDataNamesEnum.DISPLAY_NAME]: Setting;
  [SettingsDataNamesEnum.FIRST_NAME]: Setting;
  [SettingsDataNamesEnum.LAST_NAME]: Setting;
  [SettingsDataNamesEnum.EMAIL]: Setting & {
    serverEmail: string | null;
    changeEmailRequests: UserChangeEmailRequestType[];
  };
  [SettingsDataNamesEnum.DELETE]: BaseFieldData<{
    step: DeleteStepEnum;
    reasonComment: string;
  }>;
};

export type Actions = {
  [K in keyof typeof AccountSettingsEnum as (typeof AccountSettingsEnum)[K] extends
    | AccountSettingsEnum.INITIAL
    | AccountSettingsEnum.DEACTIVATE_OR_DELETE
    ? never
    : (typeof AccountSettingsEnum)[K]]: {
    cancelAction: VoidFunction;
    getCancelActionText: () => string;
    proceedAction: VoidFunction;
    getProceedActionText: () => string;
  };
};

export type FieldData = {
  data: string;
  loading: boolean;
  error: string | null;
  successMessage: string;
};
type SettingComponentsConfig = typeof accountSettingsComponents;

export interface IAccountStore {
  reactionDisposer: IReactionDisposer;
  storageService: IStorageService;
  redirectService: IRedirectService;
  userStore: IUserStore;

  data: SettingsData;
  actions: Actions;

  isDirty: boolean;
  isLoading: boolean;
  proMode: boolean;
  stackStore: IAnimatedStackStore;
  showActions: boolean;
  showCodeForm: boolean;
  isDisableNextAction: boolean;
  activeSettingId: AccountSettingsEnum;

  readonly activeSetting: SettingComponentsConfig[keyof SettingComponentsConfig];
  readonly isError: boolean;
  readonly saveActionText: string;
  readonly actionsData: null | Actions[keyof Actions];
  readonly initialSetting: AccountSettingsEnum;
  readonly isInitialScreen: boolean;

  setShowActions: (show: boolean) => void;
  setShowCodeForm: (show: boolean) => void;
  setupData: () => void;
  setProMode: (proMode: boolean) => void;
  setIsDisableNextAction: (disable: boolean) => void;
  resetData: () => void;
  dispose: () => void;
  setUsername: (value: string) => void;
  setDisplayName: (value: string) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setSettingValue: (
    settingName: SettingsDataNamesEnum,
    value: string | any,
    error?: string | null
  ) => void;
  setSettingData: (
    settingName: SettingsDataNamesEnum,
    data: SettingsData[keyof SettingsData]
  ) => void;
  checkUsernameAvailability: () => Promise<string | null | void>;
  checkEmailAvailability: () => Promise<string | null | void>;
  updateUsername: () => Promise<void | boolean>;
  updateDisplayName: () => Promise<boolean>;
  updateFirstAndLastName: () => Promise<boolean>;
  updateEmail: () => Promise<void>;
  getUserChangeEmailRequests: () => Promise<void>;
  createProUserSubscription: () => Promise<boolean>;
  setActiveSettingId: (setting: AccountSettingsEnum) => void;
  setNextSettingId: (setting: AccountSettingsEnum) => void;
  back: () => void;
  sendDeactivateLink: () => Promise<void>;
  deactivateUserAccount: (otp: string) => Promise<string | null>;
  sendDeleteLink: () => Promise<void>;
  deleteAccount: (otp: string) => Promise<string | null>;
  setDeleteStep: (step: DeleteStepEnum) => void;
  completeSubscription(proUserName: string, failed: boolean): Promise<void>;
}
