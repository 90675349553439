'use client';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IRulesStoreLocal } from '../data/Rules.store.local';
import { CommunityRuleItem } from './CommunityRuleItem';

export const _CommunityRules = () => {
  const {
    capStore,
    rulesStore: { communityRules, moveRule, loadingRules },
  } = useMultipleInjection([IOC_TOKENS.rulesStore, IOC_TOKENS.capStore]);

  const rulesStoreLocal =
    capStore.activeChildScreen as unknown as IRulesStoreLocal;

  const { goToEditRule, goToRuleDetails, deleteRule } = rulesStoreLocal || {};

  return (
    <>
      {loadingRules ? (
        <SmallLoader />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <div className="flex flex-col gap-2 transition-all duration-500">
            {communityRules.map((rule, idx) => {
              return (
                <CommunityRuleItem
                  key={`community-rule-${rule.id}`}
                  index={idx}
                  data={rule}
                  onEdit={goToEditRule}
                  onClick={() => goToRuleDetails(rule)}
                  moveCard={(dragIndex, hoverIndex) => {
                    moveRule(dragIndex, hoverIndex);
                  }}
                  onDelete={deleteRule}
                />
              );
            })}
          </div>
        </DndProvider>
      )}
    </>
  );
};

export const CommunityRules = observer(_CommunityRules);
