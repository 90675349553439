import {
  CloseButton,
  OutlineIconButton,
} from '@foundationPathAlias/components';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { StepNamesEnum } from '../PostAuthOnboarding.store.types';
type Props = {
  children: React.ReactNode;
};
function _Header(props: Props) {
  const { children } = props;
  const { postAuthOnboardingStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.postAuthOnboardingStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const isShowBackBtn =
    postAuthOnboardingStore.activeStepName !== StepNamesEnum.USERNAME &&
    !postAuthOnboardingStore.completed;
  return (
    <h5 className="themed-text relative mx-[16px] mb-[10px] flex items-center text-center text-miniHeadD font-bold md:mx-[24px] md:mb-[20px] md:text-left">
      {isShowBackBtn && (
        <OutlineIconButton
          cn="p-[4px] absolute left-0 w-[32px] h-[26px]"
          iconWrapperCn="w-[20px] h-[20px] flex justify-center"
          onClick={() => {
            postAuthOnboardingStore.back();
          }}
        >
          <ArrowSmallLeftIcon className="w-[20px]" />
        </OutlineIconButton>
      )}
      <span className="themed-text flex-1 pl-[38px] pr-[38px] text-center md:pr-[0] md:text-left">
        {children}
      </span>
      {dimensionsStore.isMobile && (
        <CloseButton
          onClick={() => {
            postAuthOnboardingStore.setShow(false);
          }}
        />
      )}
    </h5>
  );
}

export const Header = observer(_Header);
