import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import { ActionDropdownMenuItem } from './ActionDropdownMenuItem';

type TooltipMenuProps = PopupProps;

export const ActionDropdownMenu = ({
  position = ['bottom right', 'bottom center'],
  arrow = false,
  contentStyle,
  ...props
}: TooltipMenuProps) => (
  <Popup
    contentStyle={{ padding: 0, borderRadius: '5px', ...contentStyle }}
    position={position}
    arrow={arrow}
    offsetY={5}
    {...props}
  />
);

ActionDropdownMenu.Item = ActionDropdownMenuItem;
