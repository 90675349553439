import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/24/outline';

import { forwardRef } from 'react';

import { classNames } from '@foundationPathAlias/utilities';
export type NavArrowButtonPropsType = {
  cn?: string;
  /** 40 by default */
  width?: number;
  /** 40 by default */
  height?: number;
  arrowCn?: string;
  onClick?: () => void;
  direction: 'left' | 'right';
};

export const CircleNavArrowButton = forwardRef(function CircleNavArrowBtn(
  props: NavArrowButtonPropsType,
  ref
) {
  const {
    onClick,
    cn = '',
    arrowCn,
    width = 40,
    height = 40,
    direction,
  } = props;
  const FinalArrow =
    direction === 'left' ? ArrowSmallLeftIcon : ArrowSmallRightIcon;
  return (
    <button
      ref={ref as any}
      className={classNames(
        'hover-el flex items-center justify-center rounded-full bg-background-primary  shadow-floating-sheet dark:bg-background-primary-dark',
        cn
      )}
      style={{
        width,
        height,
      }}
      onClick={onClick}
    >
      <FinalArrow
        className={classNames(
          'h-[16px] w-[16px] text-text-primary dark:text-text-primary-dark',
          arrowCn
        )}
      />
    </button>
  );
});
