import { ChevronRightIcon } from '@heroicons/react/24/outline';

type NavFieldItemProps = {
  label: string;
  value: string;
  onClick: () => void;
};

export const NavFieldItem = ({ label, onClick, value }: NavFieldItemProps) => {
  return (
    <div
      onClick={onClick}
      role="button"
      className="flex items-center justify-between"
    >
      <div className="flex flex-col gap-2">
        <span className="text-body16R text-text-primary dark:text-text-primary-dark">
          {label}
        </span>
        <span className="text-body16R text-text-secondary dark:text-text-secondary-dark">
          {value}
        </span>
      </div>

      <ChevronRightIcon
        className="text-text-secondary dark:text-text-secondary-dark"
        width={20}
        height={20}
      />
    </div>
  );
};
