import { MessageNode } from './MessageNode';

export class MessageRegistryService {
  registry = new Map();
  orderedNodeArray: MessageNode[] = [];

  head: MessageNode | null = null;
  tail: MessageNode | null = null;

  addNodeToStart = (node: MessageNode) => {
    const existingNode = this.registry.get(node.id);
    if (existingNode) {
      this.replaceNode(node.id, node);
      return;
    }

    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      const head = this.head;
      node.next = head;
      head.prev = node;

      this.head = node;
    }

    // must regenerate it to update all indexes after the new node
    this.registry.set(node.id, node);
    this.orderedNodeArray = this.generateOrderedNodeArray();
  };

  addNode = (node: MessageNode) => {
    const existingNode = this.registry.get(node.id);

    if (!existingNode) {
      if (!this.head) {
        this.head = node;
        this.tail = node;
      } else {
        // @ts-ignore
        this.tail.next = node;
        node.prev = this.tail;
        this.tail = node;
      }

      node.orderIndex = this.orderedNodeArray.length;
      this.orderedNodeArray.push(node);
      // just set/update the node in registry regardless if it exists or not
      this.registry.set(node.id, node);
    } else {
      // update the existing one in array
      this.replaceNode(node.id, node);
    }
  };

  addNodeBefore = (node: MessageNode, existingNodeId: string | void) => {
    const existingNode = this.registry.get(existingNodeId);
    if (!existingNode) {
      throw new Error(`There is no node with this ID: ${existingNodeId}`);
    }

    const prevNode = existingNode.prev;

    if (prevNode) {
      node.prev = prevNode;
    } else {
      // It's head (first item). Should update it
      this.head = node;
    }
    node.next = existingNode;
    existingNode.prev = node;

    this.registry.set(node.id, node);

    // should regenerate the whole array to update all order indexes
    this.orderedNodeArray = this.generateOrderedNodeArray();
  };

  addNodeAfter = (node: MessageNode, existingNodeId: string | void) => {
    const existingNode = this.registry.get(existingNodeId);
    if (!existingNode) {
      throw new Error(`There is no node with this ID: ${existingNodeId}`);
    }

    const nextNode = existingNode.next;

    if (nextNode) {
      node.next = nextNode;
    } else {
      // It's tail (last item). Should update it
      this.tail = node;
    }

    node.prev = existingNode;
    existingNode.next = node;

    this.registry.set(node.id, node);

    // should regenerate the whole array to update all order indexes
    this.orderedNodeArray = this.generateOrderedNodeArray();
  };

  removeNode = (id: string) => {
    const node = this.registry.get(id);
    if (node) {
      if (node.prev) {
        node.prev.next = node.next;
      } else {
        this.head = node.next;
      }
      if (node.next) {
        node.next.prev = node.prev;
      } else {
        this.tail = node.prev;
      }
      this.registry.delete(id);
      this.orderedNodeArray = this.generateOrderedNodeArray();
    }
  };

  generateOrderedNodeArray = () => {
    const res: MessageNode[] = [];
    let current = this.head;
    let counter = 0;

    while (current) {
      current.orderIndex = counter;
      counter++;
      res.push(current);
      current = current.next;
    }

    return res;
  };

  replaceNode = (id: string, newNode: MessageNode) => {
    if (id !== newNode.id) {
      throw new Error(
        `ID of the new node and the existing one does not match: existingNode ID: ${id}, new Node ID: ${newNode.id}`
      );
    }
    const existingNode = this.getNode(id);

    const existingNodeIndex = existingNode.orderIndex;
    newNode.orderIndex = existingNodeIndex;

    // updating links
    const prevNode = existingNode.prev;
    const nextNode = existingNode.next;

    if (prevNode) {
      newNode.prev = prevNode;
      prevNode.next = newNode;
    } else {
      // it's a first node. Updating head
      this.head = newNode;
    }

    if (nextNode) {
      newNode.next = nextNode;
      nextNode.prev = newNode;
    } else {
      // it's a last node. Updating tail
      this.tail = newNode;
    }

    this.orderedNodeArray.splice(existingNodeIndex, 1, newNode);
    this.registry.set(id, newNode);
  };

  getNode = (id: string) => {
    const node = this.registry.get(id);
    if (!node) {
      throw new Error(`There is no node with this ID: ${id}`);
    }
    return node;
  };
  tryToGetNode = (id: string) => {
    const node = this.registry.get(id);
    return node;
  };

  reset = () => {
    this.registry = new Map();
    this.orderedNodeArray = [];
  };
}
