import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { Modal } from '@10x/foundation/src/components/modal/Modal';
import { withAnimatedStack } from '@mainApp/src/components/common';
import {
  CodeForm,
  CodeFormWrapper,
} from '@mainApp/src/components/common/code-form';
import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { DeactivateOrDeleteRedirectContent } from '@mainApp/src/modules/account-settings/account/common';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import {
  RedirectionURLActionsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';

export function _Deactivate() {
  const { t } = useTranslation(['common', 'auth']);
  const [otpVerifyError, setOtpVerifyError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const {
    accountSettingsStore,
    dimensionsStore,
    redirectService,
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.redirectService,
    IOC_TOKENS.interactiveStore,
  ]);
  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;

  const isMobile = dimensionsStore.isMobile;

  useEffect(() => {
    const { show: showBtmSht } = accountSettingsStore.bottomSheetConfig;

    if (!isMobile) return;

    if (!accountStore.showCodeForm && showBtmSht) {
      accountSettingsStore.setBottomSheetConfig({
        show: false,
      });
      return;
    }

    if (accountStore.showCodeForm && !showBtmSht) {
      accountSettingsStore.setBottomSheetConfig({
        show: true,
      });
      return;
    }
  }, [accountStore.showCodeForm, isMobile]);

  const { title } = accountStore.activeSetting;

  const email = accountStore.data[SettingsDataNamesEnum.EMAIL]?.serverEmail;
  const username = accountStore.data[SettingsDataNamesEnum.USERNAME]?.data;

  const formContent = (
    <CodeFormWrapper
      title={t('checkYourInbox', {
        ns: 'auth',
      })}
      subHeading={t('accountSettings.codeSentDeactivate', { email: email })}
      onCloseBtnClick={() => {
        accountStore.setShowCodeForm(false);
      }}
    >
      <CodeForm
        otpError={otpVerifyError}
        loading={loading}
        onChange={() => {
          // reset validation error on change if exists
          if (otpVerifyError) {
            setOtpVerifyError(false);
          }
        }}
        onSubmit={async (otp) => {
          setLoading(true);

          const error = await accountStore.deactivateUserAccount(otp);

          setLoading(false);
          if (error) {
            setOtpVerifyError(Boolean(error));
          } else {
            interactiveStore.setContent(
              <DeactivateOrDeleteRedirectContent
                title={t('accountSettings.deactivationSuccess')}
                description={t('accountSettings.deactivationSuccessDescr')}
              />
            );
            const url = redirectService.generateQueryUrl(
              paths.home,
              RedirectionURLQueriesEnum.SCHEDULED_ACTIONS,
              RedirectionURLActionsEnum.SUCCESS_ACCOUNT_DEACTIVATION
            );

            router.push(url);
          }
        }}
      />
    </CodeFormWrapper>
  );

  useLayoutEffect(() => {
    accountStore.setShowActions(true);

    accountSettingsStore.setBottomSheetConfig({
      onClose: () => {
        accountStore.setShowCodeForm(false);
      },
      content: formContent,
    });

    return () => {
      accountSettingsStore.setBottomSheetConfig({
        content: null,
      });
    };
  }, []);

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <div className="flex flex-col items-start">
        <b className="themed-text text-body16SB">{`@${username}`}</b>
        <p className="themed-text-secondary my-[8px] text-body16R">
          {t('accountSettings.ifYouDeactivateYourAccount')}
        </p>
        <ul className="themed-text-secondary list-disc space-y-[12px] pl-[32px] text-left text-body16R">
          <li>{t('accountSettings.yourAccountIsOnHold')}</li>
          <li>{t('accountSettings.accountDeactivateWarning')}</li>
          <li>{t('accountSettings.accountDeactivationReactivateDescr')}</li>
        </ul>
      </div>

      {!isMobile && (
        <Modal open={accountStore.showCodeForm}>{formContent}</Modal>
      )}
    </ScreenWrapper>
  );
}

export const Deactivate = withAnimatedStack(observer(_Deactivate));
