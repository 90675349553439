import { useEffect, useRef } from 'react';
import { FreeMode, Navigation, Swiper as SwiperType } from 'swiper';

import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { classNames } from '@foundationPathAlias/utilities';

import { CircleNavArrowButton } from '../buttons';

export type SliderProps = {
  children: (swiperSLider: typeof SwiperSlide) => JSX.Element[];
  SwiperProps?: SwiperProps;
  sliderCn?: string;
  sliderRef?: (ref: SwiperType) => void;
  /** for the wrapper */
  rootCn?: string;
  lNavButtonCn?: string;
  rNavButtonCn?: string;
  navButtonSize?: { width: number; height: number };
};

export function Slider(props: SliderProps) {
  const {
    children,
    sliderCn,
    rootCn,
    sliderRef,
    lNavButtonCn,
    rNavButtonCn,
    navButtonSize = { width: undefined, height: undefined },
  } = props;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const swiperRef = useRef<SwiperType | null>(null);

  const slides = children(SwiperSlide);

  useEffect(() => {
    const swiper = swiperRef.current;
    const prevBtn = navigationPrevRef.current;
    const nextBtn = navigationNextRef.current;

    if (swiper && prevBtn && nextBtn) {
      // @ts-ignore
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      // @ts-ignore
      swiper.params.navigation.nextEl = navigationNextRef.current;

      // Re-init navigation
      swiper.navigation.destroy();
      swiper.navigation.init();
      swiper.navigation.update();

      // didn't find any other way to update the navigation after slides change
      setTimeout(() => {
        if (!swiper || !swiper.navigation) return;
        swiper.updateSlides();
        swiper.updateSlidesClasses();
      }, 1000);
    }
  }, [navigationPrevRef, navigationNextRef, swiperRef, slides.length]);

  return (
    <div className={classNames('relative h-full w-full', rootCn)}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation]}
        className={classNames('slider', sliderCn)}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          if (sliderRef) {
            sliderRef(swiper);
          }
        }}
      >
        {slides}
      </Swiper>
      <div>
        <CircleNavArrowButton
          ref={navigationPrevRef}
          cn={classNames(
            'swiper-nav-btn absolute -left-[12px] top-[50%] z-1 -translate-y-[50%]',
            lNavButtonCn
          )}
          direction="left"
          width={navButtonSize.width}
          height={navButtonSize.height}
        />
        <CircleNavArrowButton
          ref={navigationNextRef}
          cn={classNames(
            'swiper-nav-btn absolute -right-[12px] top-[50%] z-1 -translate-y-[50%] transform',
            rNavButtonCn
          )}
          direction="right"
          width={navButtonSize.width}
          height={navButtonSize.height}
        />
      </div>
    </div>
  );
}
