import { inject, injectable } from 'inversify';

import { IEventBus } from '@foundationPathAlias/utilities';
import { OptimisticQueueEventsEnum } from './events';
/**
 * TODO: We decided to remove for MVP optimistic queue as it's complex
 * and leave just a basic stuff but this service is a core
 * for the future work on it
 * this file should contain all the optimistic models that
 * is running in the graphql at the realtime
 *
 * it must be done in this way because it isn't possible to pass some
 * custom client-only fields for the urql mutation that could
 * mark the optimistic response only (for example: loading state in the
 * attachments) so I decided to make this service, save this data here
 * and then in components just fetch it
 *
 */

import { IOC_TOKENS } from '@mainApp/src/ioc';
import { DoubleLinkedRegistryQueue } from './DoubleLinkedRegistryQueue';

export type DataItem<T = any> = {
  data: T;
  id: string;
  next: string | null; // model ID or some pointer
  prev: string | null;
};

export type DataStep<T = any> = {
  prevCompletedItem: T;
  nextItem: T;
};

@injectable()
export class OptimisticQueueService<T> {
  registry: DoubleLinkedRegistryQueue<T>;

  activeItem: DataItem | null = null;
  lastItemPointer: string | null = null;

  /**
   * contains an items that had been failed and removed from the normal registry
   */
  failedRegistry: DoubleLinkedRegistryQueue<T>;

  // @ts-ignore
  private _eventBus: IEventBus<OptimisticQueueEventsEnum>;

  get eventBus() {
    if (!this._eventBus) {
      throw new Error('No event bus');
    }

    return this._eventBus;
  }

  set eventBus(val: IEventBus<OptimisticQueueEventsEnum>) {
    this._eventBus = val;
  }

  get running() {
    return !this.registry.isEmpty || !this.failedRegistry.isEmpty;
  }

  constructor(
    @inject(IOC_TOKENS.DoubleLinkedRegistryQueue)
    DblReqQ: typeof DoubleLinkedRegistryQueue
  ) {
    this.registry = new DblReqQ<T>();
    this.failedRegistry = new DblReqQ<T>();
  }

  private isRegistered = (id: string) => {
    const item = this.registry.getById(id) || this.failedRegistry.getById(id);
    return Boolean(item);
  };

  isInActiveRegistry = (id: string) => {
    const item = this.registry.getById(id);
    return Boolean(item);
  };

  addToActiveRegistryIfNotRegistered = (id: string, item: T) => {
    // if the model had already been added so shouldn't do it again
    if (!this.isRegistered(id)) {
      this.registry.add(id, item);
    }
  };

  proceedNext = () => {
    const nextData = this.registry.next();

    if (nextData === null) {
      this.eventBus.emit(
        OptimisticQueueEventsEnum.OPTIMISTIC_QUEUE_END,
        undefined
      );

      return null;
    }

    const { handledItem, nextItem } = nextData;

    if (nextItem) {
      this.eventBus.emit(OptimisticQueueEventsEnum.OPTIMISTIC_QUEUE_NEXT, {
        prevCompletedItem: handledItem,
        nextItem: nextItem,
      });
    } else {
      this.eventBus.emit(
        OptimisticQueueEventsEnum.OPTIMISTIC_QUEUE_END,
        handledItem
      );
    }
  };

  addFailedToActiveQueue = (id: string) => {
    const failedItem = this.failedRegistry.extractById(id);
    this.addToActiveRegistryIfNotRegistered(failedItem.id, failedItem.data);
  };

  // trying to handle the next one to failed and change links for the failed one
  // for now it's only for the scenario when 1 optimistic message could exsist, it could fail in the message list and the second one is the active message list that has attachments
  proceedNextAfterFailedOne = () => {
    // extracting the new one, unlink, leave the failed one in reg and moving further
    const data = this.registry.next();

    if (!data) {
      return;
    }

    const { handledItem, nextItem } = data;
    const handledItemId = handledItem.id;

    // add to failed registry
    this.failedRegistry.add(handledItemId, handledItem);

    if (nextItem) {
      this.eventBus.emit(
        OptimisticQueueEventsEnum.OPTIMISTIC_QUEUE_NEXT_AFTER_FAILED,
        nextItem
      );
    }
  };
}
