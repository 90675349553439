import * as React from 'react';

import { usePopoverContext } from './PopoverCtx';
export function PopoverFactory({ triggerEl, isOpen, onClose }) {
  const context = usePopoverContext();

  React.useEffect(() => {
    if (triggerEl) {
      context.refs.setReference(triggerEl);

      if (isOpen) {
        if (!context.open) {
          context.setOpen(true);
        }
      } else {
        onClose();
      }
    }
  }, [triggerEl, context, isOpen, onClose]);

  React.useEffect(() => {
    if (context) {
      context.context.events.on('dismiss', onClose);
    }

    return () => {
      context.context.events.off('dismiss', onClose);
    };
  }, [context]);

  return null;
}
