'use client';
import { PrimaryButton } from '@foundationPathAlias/components';
import { PlusIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import {
  CommunityRules,
  DeleteConfirmation,
  PlatformRuleItem,
} from './components';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { classNames } from '@foundationPathAlias/utilities';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IRulesStoreLocal } from './data/Rules.store.local';

const MAX_RULES = 10;

const _Rules = () => {
  const { t } = useTranslation(['cap']);

  const {
    capStore,
    dimensionsStore: { isMobile },
    platformStore: { platformRules, loadPlatformRules },
    rulesStore: { loadCommunityRules, communityRules },
  } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.platformStore,
    IOC_TOKENS.rulesStore,
  ]);

  const rulesStoreLocal =
    capStore.activeChildScreen as unknown as IRulesStoreLocal;

  const {
    goToRuleDetails,
    undoDelete,
    confirmDelete,
    ruleToDelete,
    goToCreateRule,
  } = rulesStoreLocal || {};

  useEffect(() => {
    loadCommunityRules();
    loadPlatformRules();
  }, []);

  return (
    <ScreenWrapper
      title={t('cap:menu.rules')}
      sidebarModalController={capStore}
      showBackBtn={isMobile}
      ScreenHeaderProps={{
        childrenWrapperCn: classNames({
          'text-start': isMobile,
        }),
      }}
    >
      <div className="w-full overflow-y-auto scrollbar-thin">
        <div className="absolute bottom-0 left-0 z-1 flex w-full justify-end pr-[24px] pt-[8px]">
          {Boolean(ruleToDelete) && (
            <DeleteConfirmation
              onUndo={undoDelete}
              onDeleteConfirm={confirmDelete}
            />
          )}
        </div>

        <div className="mb-6 flex w-full flex-col gap-6 md:flex-row md:items-start md:justify-between">
          <div className="flex flex-col items-start">
            <b className="text-body18SB text-text-primary dark:text-text-primary-dark">
              {t('cap:rules.communityRules')}
            </b>
            <span className="text-start text-body16R text-text-secondary dark:text-text-secondary-dark">
              {t('cap:rules.communityRulesDescription')}
            </span>
          </div>

          {communityRules.length < MAX_RULES && (
            <PrimaryButton
              cn="flex md:w-auto items-center w-full justify-center gap-2"
              onClick={goToCreateRule}
            >
              <PlusIcon width={16} height={16} />
              {t('cap:rules.createNewRule')}
            </PrimaryButton>
          )}
        </div>

        <CommunityRules />

        {platformRules.data && platformRules.data?.length > 0 && (
          <>
            <hr className="my-6" />

            <div className="mb-2 flex flex-col items-start gap-1">
              <b className="text-body18SB text-text-primary dark:text-text-primary-dark">
                {t('cap:rules.platformRules')}
              </b>

              <span className="text-start text-body16R text-text-secondary dark:text-text-secondary-dark">
                {t('cap:rules.platformRulesDescription')}
              </span>
            </div>
          </>
        )}

        <div>
          {platformRules.data?.map((platformRule) => {
            return (
              <PlatformRuleItem
                key={platformRule.id}
                rule={platformRule}
                onClick={goToRuleDetails}
              />
            );
          })}
        </div>
      </div>
    </ScreenWrapper>
  );
};

export const Rules = withAnimatedStack(observer(_Rules));
