
import { Language } from "../Language"

import { LanguageSettingsEnum } from "@mainApp/src/modules/account-settings/data/types"

export const languageSettingsComponents = {
    [LanguageSettingsEnum.INITIAL]: {
        id: LanguageSettingsEnum.INITIAL,
        title: 'language',
        Component: Language
    },

}