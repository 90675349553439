import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
// TODO: DI
import { messageEditorStore } from '../editor-area/MessageEditor.store';
import { AnimatedDropup } from './AnimatedDropup';

import { useClickAway, useKeyPress } from 'react-use';

import { ChannelSuggestions } from '../editor-area/ChannelSuggestions';
import { UserSuggestions } from '../editor-area/UserSuggestions';

import { DropupTypeEnum } from './types';

const contentRegistry: {
  [key in DropupTypeEnum]: (props: any) => React.ReactElement | null;
} = {
  [DropupTypeEnum.SUGGESTION_USERS]: UserSuggestions,
  [DropupTypeEnum.SUGGESTION_CHANNELS]: ChannelSuggestions,
  [DropupTypeEnum.EMPTY]: () => null,
};

export function _DropupManager() {
  const [isEscPressed] = useKeyPress('Escape');

  const ref = useRef<HTMLElement | null>(null);

  useClickAway(ref, () => {
    if (messageEditorStore.dropupData.show) {
      messageEditorStore.hideDropup();
    }
  });

  useEffect(() => {
    if (isEscPressed && messageEditorStore.dropupData.show) {
      messageEditorStore.hideDropup();
    }
  }, [isEscPressed]);

  const ContentComponent = contentRegistry[messageEditorStore.dropupData.type];
  const isEmptyContent =
    messageEditorStore.dropupData.type === DropupTypeEnum.EMPTY;

  return (
    <AnimatedDropup
      ref={ref}
      show={messageEditorStore.dropupData.show}
      maxHeight={300}
    >
      {!isEmptyContent && (
        <div className="relative z-1 max-h-[300px] overflow-hidden rounded-[5px] bg-white px-[8px] pb-[8px] pt-[12px] shadow-drop-shadow-menu">
          <ContentComponent />
        </div>
      )}
    </AnimatedDropup>
  );
}

export const DropupManager = observer(_DropupManager);
