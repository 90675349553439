import { classNames } from '@foundationPathAlias/utilities';
import { animated, useSpring } from '@react-spring/web';
import { useEffect } from 'react';
type Props = {
  slideType: 'in' | 'out';
  startEntering: boolean;
  startLeaving: boolean;
  stackWrapperCn?: string;
  isNext: boolean;
  onEnterEnd: () => void;
  onLeaveEnd: () => void;
  onInitialRender: () => void;
};

export function withAnimatedStack(Component: React.FC) {
  Component.displayName = `withAnimatedStack--${
    Component.displayName || Component.name
  }`;
  return (props: Props) => {
    const {
      slideType = 'in',
      startEntering,
      startLeaving,
      stackWrapperCn,
      onEnterEnd,
      onLeaveEnd,
      onInitialRender,
      isNext,
      ...otherProps
    } = props;

    const config = {
      mass: 1,
      tension: 470,
      friction: 46,
      clamp: true,
      velocity: 0,
    };

    const isSlideIn = slideType === 'in';
    const [springs, api] = useSpring(() => ({
      from: { x: isNext ? 1 : 0 },
      config: config,
    }));

    const onLeaveResolve = () => {
      onLeaveEnd();
    };

    useEffect(() => {
      onInitialRender?.();
    }, []);

    useEffect(() => {
      if (!startEntering) {
        return;
      }

      // happens double trigger at the dev mode
      let unmounted = false;

      api.start({
        from: {
          x: isSlideIn ? 1 : 0,
        },
        to: {
          x: isSlideIn ? 0 : 1,
        },
        config: config,
        onResolve: () => {
          if (unmounted) return;
          onEnterEnd();
        },
      });
      return () => {
        unmounted = true;
      };
    }, [startEntering]);

    useEffect(() => {
      if (!startLeaving) {
        return;
      }

      // happens double trigger at the dev mode
      let unmounted = false;

      api.start({
        from: {
          x: isSlideIn ? 0 : 1,
        },
        to: {
          x: isSlideIn ? 1 : 0,
        },
        config: config,
        onResolve: () => {
          if (unmounted) return;
          onLeaveResolve();
        },
      });
      return () => {
        unmounted = true;
      };
    }, [startLeaving]);

    return (
      <animated.div
        className={classNames(
          'themed-layout absolute left-0 top-0 flex h-full w-full transform flex-col',
          stackWrapperCn
        )}
        style={{
          transform: springs.x
            .to([0, 1], [0, 100])
            .to((value) => `translate(${value}%, 0)`),
        }}
      >
        <Component {...otherProps} />
      </animated.div>
    );
  };
}
