import { ArrowUpSVG } from '@foundationPathAlias/components/svg';
import { classNames } from '@foundationPathAlias/utilities';
import { colorSchema } from '@rootConfig/color-schema';
export type CircleArrowUpButtonPropsType = {
  cn?: string;
  onClick: () => void;
  disabled?: boolean;
};

export function CircleArrowUpButton(props: CircleArrowUpButtonPropsType) {
  const { onClick, cn = '', disabled } = props;
  return (
    <button
      className={classNames(
        'themed-btn flex h-[24px] w-[24px] items-center justify-center rounded-full ',
        disabled && 'opacity-40',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowUpSVG
        className="h-[10px] w-[10px]"
        color={colorSchema['background-primary']}
      />
    </button>
  );
}
