export const PizzaSVG: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  clipPathColor?: string;
}> = ({
  width = 20,
  height = 20,
  color = '#1A1A1A',
  clipPathColor = 'white',
  // to escape autogeneration fill prop from the story. Remove it later
  // @ts-ignore
  fill,
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g clip-path="url(#clip0_12839_29934)">
      <path
        d="M12.5 9.16669H12.51"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16666 12.5H9.17666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 13.3333H13.3433"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 13.3334L18.3333 18.3334L13.3333 1.66669C10.5434 2.43159 8.00062 3.90951 5.95505 5.95508C3.90948 8.00065 2.43156 10.5434 1.66666 13.3334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75833 14.2584C5.4346 12.0137 6.65607 9.97144 8.31374 8.31377C9.97141 6.6561 12.0137 5.43463 14.2583 4.75836"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12839_29934">
        <rect width="20" height="20" fill={clipPathColor} />
      </clipPath>
    </defs>
  </svg>
);
