import React from 'react';

type FormFieldLabelProps = {
  label: string;
  description: string;
  children: React.ReactNode;
};

export const FormFieldLabel = ({
  children,
  label,
  description,
}: FormFieldLabelProps) => {
  return (
    <div className="flex flex-col text-left">
      <label className="text-body18SB text-text-primary dark:text-text-primary-dark">
        {label}
      </label>

      <span className="text-body16R text-text-secondary dark:text-text-secondary-dark">
        {description}
      </span>

      {children}
    </div>
  );
};
