export const LogoSVG: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 64, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="themed-svg"
  >
    <path d="M11.2486 3.42016V24.9934H3.79168V8.90593H0V3.42016H11.2486Z" />
    <path d="M23.9814 25.4865C22.0224 25.4865 20.2845 25.0448 18.7678 24.1613C17.2722 23.2573 16.0926 21.9629 15.2289 20.2781C14.3863 18.5728 13.965 16.549 13.965 14.2068C13.965 11.8645 14.3863 9.85104 15.2289 8.16627C16.0926 6.46096 17.2722 5.16657 18.7678 4.28309C20.2845 3.37907 22.0224 2.92706 23.9814 2.92706C25.9404 2.92706 27.6677 3.37907 29.1633 4.28309C30.68 5.16657 31.8597 6.46096 32.7022 8.16627C33.5659 9.85104 33.9977 11.8645 33.9977 14.2068C33.9977 16.549 33.5659 18.5728 32.7022 20.2781C31.8597 21.9629 30.68 23.2573 29.1633 24.1613C27.6677 25.0448 25.9404 25.4865 23.9814 25.4865ZM23.9814 19.6617C25.6666 19.6617 26.5092 17.8434 26.5092 14.2068C26.5092 10.5701 25.6666 8.75183 23.9814 8.75183C22.2962 8.75183 21.4536 10.5701 21.4536 14.2068C21.4536 17.8434 22.2962 19.6617 23.9814 19.6617Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5888 0H33.5587L51.2804 24.2088C51.6612 24.729 52.2502 24.9862 52.8369 24.9802L52.8253 24.9933H64L63.9884 24.9802C63.4148 24.9744 62.8434 24.7171 62.4712 24.2083L58.4158 18.664L58.4127 18.6604L58.4095 18.664L58.4127 18.6597L46.6077 2.52093C45.4506 0.939026 43.5811 0 41.5888 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 3.00665L63.9884 3.01975C63.4148 3.02555 62.8434 3.28287 62.4712 3.79168L58.4158 9.33605L58.4127 9.33956L46.6077 25.479C45.4506 27.0609 43.5811 28 41.5888 28H33.5587L51.2804 3.79114C51.3897 3.64188 51.5161 3.51428 51.6546 3.40834C51.9916 3.15073 52.4005 3.02117 52.8098 3.01967C52.815 3.01965 52.8202 3.01965 52.8253 3.01967C52.8292 3.01969 52.833 3.01972 52.8369 3.01976L52.8253 3.00665H64ZM55.3452 4.13417H60.8007L57.4747 8.68121L57.4716 8.68544L45.6667 24.8242C44.7265 26.1095 43.2076 26.8725 41.5888 26.8725H35.8045L52.2212 4.44632C52.3692 4.24411 52.5923 4.14486 52.8249 4.14722L55.3452 4.13417Z"
    />
  </svg>
);
