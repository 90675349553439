// TODO: use symbols instead for strings to avoid global evt collisions?
export enum FileUploadEventsEnum {
  QUEUE_START = 'QUEUE_START',
  QUEUE_END = 'QUEUE_END',
  QUEUE_SUCCESS = 'QUEUE_SUCCESS',
  QUEUE_ERROR = 'QUEUE_ERROR',
  UPLOADING_START = 'UPLOADING_START',
  UPLOADING_PROGRESS = 'UPLOADING_PROGRESS',
  UPLOADING_SUCCESS = 'UPLOADING_SUCCESS',
  UPLOADING_ERROR = 'UPLOADING_ERROR',
}

export enum RetryEventsEnum {
  RETRY_START = 'RETRY_START',
  RETRY_MAX_ATTEMPT_REACHED = 'RETRY_MAX_ATTEMPT_REACHED',
}

export enum OptimisticQueueEventsEnum {
  OPTIMISTIC_QUEUE_ADD = 'OPTIMISTIC_QUEUE_ADD',
  OPTIMISTIC_QUEUE_EXISTING_ID_ERROR = 'OPTIMISTIC_QUEUE_EXISTING_ID_ERROR',
  OPTIMISTIC_QUEUE_REMOVE = 'OPTIMISTIC_QUEUE_REMOVE',
  OPTIMISTIC_QUEUE_NEXT = 'OPTIMISTIC_QUEUE_NEXT',
  OPTIMISTIC_QUEUE_NEXT_AFTER_FAILED = 'OPTIMISTIC_QUEUE_NEXT_AFTER_FAILED',
  OPTIMISTIC_QUEUE_END = 'OPTIMISTIC_QUEUE_END',
}

export enum MediatorOwnEventsEnum {
  EDIT_MESSAGE_OPTIMISTIC_UPLOAD_END = 'EDIT_MESSAGE_OPTIMISTIC_UPLOAD_END',
}

export const MediatorEvents = {
  ...FileUploadEventsEnum,
  ...RetryEventsEnum,
  ...OptimisticQueueEventsEnum,
  ...MediatorOwnEventsEnum,
};

export type MediatorEventsType =
  | FileUploadEventsEnum
  | RetryEventsEnum
  | OptimisticQueueEventsEnum
  | MediatorOwnEventsEnum;
