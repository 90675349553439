import { reaction } from 'mobx';
import { useEffect } from 'react';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { RedirectionIdsEnum, StorageDataKeysEnum } from '@mainApp/src/services';
import { Auth } from './Auth';
/**
 * Renders Auth Module.
 * Basically it's a reactive hook
 * that reacts on the auth calling and renders the
 * Auth via Interactive store in Modal or Bottomsheet
 */
export function useAuthUI() {
  const { interactiveStore, dimensionsStore, authStore } = useMultipleInjection(
    [
      IOC_TOKENS.interactiveStore,
      IOC_TOKENS.dimensionsStore,
      IOC_TOKENS.authStore,
    ]
  );

  const auth = (
    <Auth
      onCloseBtnClick={() => {
        interactiveStore.resetContent();
        interactiveStore.setInteractiveElementOpen(false);
      }}
    />
  );

  useEffect(() => {
    const disposer = reaction(
      () => {
        const activeContentTypeRegistry =
          interactiveStore.activeContentTypeRegistry;
        const isMobile = dimensionsStore.isMobile;

        return {
          activeContentTypeRegistry,
          isMobile,
        };
      }, // Observe everything that is used in the JSON.
      ({ activeContentTypeRegistry, isMobile }) => {
        if (activeContentTypeRegistry.auth) {
          const content = !isMobile ? wrapAuthForBottomSheet(auth) : auth;
          interactiveStore.setContent(content);
          interactiveStore.setInteractiveElementOpen(true);

          // to be able to redirect back to the page from the user received the auth popup appear. Useful when user have non-authorized channel view. In this case he will be redirected back automatically after login
          const existingAuthCallerData = localStorage.getItem(
            StorageDataKeysEnum.AUTH_CALLER_URL
          );

          // prevent from the use case when on the community page appears unauthorized error -> the user closes the modal and clicks on the join to community action. Auth required was overriding the JOIN_COMMUNITY action
          if (!existingAuthCallerData) {
            authStore.saveAuthCallerUrl(RedirectionIdsEnum.AUTH_REQUIRED);
          }
        }
      }
    );
    return disposer;
  }, []);
}

function wrapAuthForBottomSheet(authComp: JSX.Element) {
  return (
    <div className="flex flex-1 justify-center">
      <div className="themed-layout z-9 flex min-w-[325px] max-w-[424px] flex-1 flex-col  py-[24px]">
        {authComp}
      </div>
    </div>
  );
}
