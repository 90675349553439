import {
  ButtonBasePropsType,
  OutlineButton,
  PrimaryButton,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

export type ChangesAlertProps = {
  show: boolean;
  firstBtnText?: string;
  firstBtnCn?: string;
  secondBtnText?: string;
  secondBtnCn?: string;
  /** if presented the continueText, cancelText, onContinue and onCancel aren't necessary because the default buttons will be replaced by the custom one */
  customActions?: JSX.Element | JSX.Element[];
  cn?: string;
  overlayCn?: string;
  contentWrapperCn?: string;
  contentCn?: string;
  titleCn?: string;
  title: string;
  descriptionCn?: string;
  description: string | null;
  actionsCn?: string;
  FirstBtnProps?: Partial<ButtonBasePropsType>;
  SecondBtnProps?: Partial<ButtonBasePropsType>;
  onFirstBtnClick?: () => void;
  onSecondBtnClick?: () => void;
};

export const ChangesAlert = (props: ChangesAlertProps) => {
  const {
    show,
    firstBtnText,
    firstBtnCn,
    secondBtnText,
    secondBtnCn,
    customActions,
    cn,
    overlayCn,
    contentWrapperCn,
    contentCn,
    title,
    titleCn,
    description,
    descriptionCn,
    actionsCn,
    FirstBtnProps,
    SecondBtnProps,
    onFirstBtnClick,
    onSecondBtnClick,
  } = props;

  if (!show) {
    return null;
  }
  return (
    <div
      className={classNames(
        'absolute left-0 top-0 z-10 flex h-full w-full flex-col',
        cn
      )}
    >
      <div
        className={classNames(
          'flex-grow bg-background-overlay-subtle dark:bg-background-overlay-dark',
          overlayCn
        )}
      />

      <div
        className={classNames(
          'flex flex-shrink-0 flex-col items-baseline gap-6 bg-background-primary p-6 dark:bg-background-primary-dark md:flex-row md:items-center',
          contentWrapperCn
        )}
      >
        <div
          className={classNames(
            'flex flex-grow flex-col items-start',
            contentCn
          )}
        >
          <b
            className={classNames(
              'text-body16SB text-text-primary dark:text-text-primary-dark',
              titleCn
            )}
          >
            {title}
          </b>

          <span
            className={classNames(
              'text-left text-sm14R  text-text-secondary dark:text-text-secondary-dark',
              descriptionCn
            )}
          >
            {description}
          </span>
        </div>

        <div
          className={classNames(
            'space-b flex w-full flex-shrink-0 gap-4  md:w-auto',
            actionsCn
          )}
        >
          {customActions ? (
            customActions
          ) : (
            <>
              <OutlineButton
                {...FirstBtnProps}
                cn={firstBtnCn}
                onClick={() => {
                  onFirstBtnClick?.();
                }}
              >
                {firstBtnText}
              </OutlineButton>
              <PrimaryButton
                dynamicColorsMode={true}
                {...SecondBtnProps}
                cn={classNames('whitespace-nowrap', secondBtnCn)}
                onClick={() => {
                  onSecondBtnClick?.();
                }}
              >
                {secondBtnText}
              </PrimaryButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
