import {
  MenuListItem,
  SidebarWithMenu,
} from '@foundationPathAlias/widgets/sidebar-modal';
import { observer } from 'mobx-react-lite';

import { EyeIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type MenuItemsIds = 'overview' | 'permissions';

const menuItemsConfig: MenuListItem<MenuItemsIds>[] = [
  {
    label: 'overview',
    id: 'overview',
    icon: <EyeIcon width={20} />,
  },
  {
    label: 'permissions',
    id: 'permissions',
    icon: <ShieldCheckIcon width={20} />,
  },
];

export function _SidebarMenuWithMobx() {
  const {
    dimensionsStore: { isMobile },
    channelSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.channelSettingsStore,
  ]);

  const { t, ready } = useTranslation('common');

  const translatedMenuItemsConfigRef = useRef<MenuListItem<MenuItemsIds>[]>([]);

  const translatedMenuItemsConfig = useMemo(() => {
    if (!ready) {
      return menuItemsConfig;
    }
    if (translatedMenuItemsConfigRef.current.length) {
      return translatedMenuItemsConfigRef.current;
    }

    translatedMenuItemsConfigRef.current = menuItemsConfig.map((item) => ({
      ...item,
      label: t(item.label),
    }));

    return translatedMenuItemsConfigRef.current;
  }, [ready]);

  return (
    <SidebarWithMenu
      isMobile={isMobile}
      menuItemsConfig={translatedMenuItemsConfig}
      activeMenuItemId={channelSettingsStore.activeScreenId}
      onMenuSelect={(itemId) => {
        channelSettingsStore.setActiveScreenId(itemId);
      }}
    />
  );
}

export const SidebarMenuWithMobx = observer(_SidebarMenuWithMobx);
