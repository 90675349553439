import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  children: React.ReactNode;
  cn?: string;
};

export const SidebarMenu = (props: Props) => {
  const { children, cn } = props;

  return (
    <div className={classNames('flex flex-col gap-4', cn)}>{children}</div>
  );
};
