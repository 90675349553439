import { classNames } from '@foundationPathAlias/utilities';
import { observer } from 'mobx-react-lite';
// TODO: create later some generic component based on it
import { UserChangeEmailRequestType } from '@10x/foundation/types';
import { validators } from '@foundationPathAlias/components';
import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { withAnimatedStack } from '@mainApp/src/components/common';
import { Input } from '@mainApp/src/components/post-auth-onboarding/common/Input';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  IAccountStore,
  SettingsDataNamesEnum,
} from '@mainApp/src/modules/account-settings/account/data/Account.store.types';
import { ScreenWrapper } from '@mainApp/src/modules/account-settings/common/ScreenWrapper';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { EmailRow } from './EmailRow';

type Props = {
  titleCn?: string;
  descriptionCn?: string;
};

export function _Email(props: Props) {
  const { titleCn, descriptionCn } = props;
  const checkingRef = useRef(false);

  const { t } = useTranslation('common');
  const { accountSettingsStore, userStore } = useMultipleInjection([
    IOC_TOKENS.accountSettingsStore,
    IOC_TOKENS.userStore,
  ]);

  const accountStore = accountSettingsStore.activeSettingStore as IAccountStore;
  const { title } = accountStore.activeSetting;
  const emailData = accountStore.data[SettingsDataNamesEnum.EMAIL];

  const emailChangeRequests = emailData.changeEmailRequests;

  const isInitialData = !accountStore.isDirty;
  useDebounce(
    async () => {
      if (!accountStore.isDirty || emailData.error) return;
      const error = await accountStore.checkEmailAvailability();
      if (!error) {
        checkingRef.current = false;
      }
    },
    300,
    [emailData.data, emailData.error]
  );

  const availabilityCheckLoading = useMinLoading(emailData.loading, 500);

  const changePending = Boolean(emailChangeRequests.length);

  return (
    <ScreenWrapper title={t(title)} showBackBtn={true}>
      <h6 className={classNames('themed-text mb-[4px] text-body16SB', titleCn)}>
        {t('email')}
      </h6>
      <p
        className={classNames(
          'themed-text-secondary mb-[16px] text-body16R',
          descriptionCn
        )}
      >
        {t('accountSettings.youCanSignIn')}
      </p>
      <Input
        withoutDefaultValidators={true}
        disabled={changePending}
        getIsSuccess={(val, error) => {
          if (checkingRef.current || availabilityCheckLoading) return false;
          if (val === undefined || isInitialData) {
            return false;
          }
          return val.length > 0 && !error;
        }}
        showLoader={availabilityCheckLoading}
        specificValidators={[
          validators.getEmailValidator(
            t('errors.invalidEmailFormat') as string
          ),
        ]}
        outerError={emailData.error}
        successMsg={''}
        placeholder={t<string>('email')}
        value={
          (changePending ? emailData.serverEmail : emailData.data) as string
        }
        onChange={(val) => {
          checkingRef.current = true;
          accountStore.setEmail(val);
        }}
        onError={(error) => {
          const existingError =
            accountStore.data[SettingsDataNamesEnum.EMAIL].error;
          if (existingError !== error) {
            accountStore.setSettingValue(
              SettingsDataNamesEnum.EMAIL,
              emailData.data,
              error
            );
          }
        }}
      />

      {emailChangeRequests.map((request: UserChangeEmailRequestType) => (
        <EmailRow
          key={request.id}
          id={request.id}
          email={request.email}
          onResend={async (id) => {
            await userStore.resendUserChangeEmailRequest(id);
            accountStore.getUserChangeEmailRequests();
          }}
          onCancelRequest={async (id) => {
            await userStore.cancelUserChangeEmailRequest(id);
            accountStore.setEmail(emailData.serverEmail || '');
            accountStore.getUserChangeEmailRequests();
          }}
        />
      ))}
    </ScreenWrapper>
  );
}

export const Email = withAnimatedStack(observer(_Email));
