import { Overview } from "../Overview";

import { ScreenIds } from "../constants";

export type ScreenIdsValuesType = typeof ScreenIds[keyof typeof ScreenIds];


export const screensConfig = {
    [ScreenIds.INITIAL]: {
        id: ScreenIds.INITIAL,
        title: 'overview',
        Component: Overview
    }
}