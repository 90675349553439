import { FileUploadStatusEnum } from '@10x/foundation/types';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import { inject, injectable } from 'inversify';
import { FileContent } from 'use-file-picker';

import { action, makeObservable, observable } from 'mobx';

import { AttachmentsProgressBarViewStore } from './AttachmentsProgressBar.view.store';
import { FileUploadService } from './FileUpload.service';

import { IEventBus } from '@foundationPathAlias/utilities';
import { FileUploadEventsEnum, MediatorEvents, MediatorEventsType, OptimisticQueueEventsEnum, RetryEventsEnum } from './events';

import type { IAttachmentsRepository } from '@mainApp/src/repositories';
import type {
  IChannelStore,
  ICommunityStore,
  IMessageStore,
} from '@mainApp/src/stores';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';
import { DataStep, OptimisticQueueService } from './OptimisticQueue.service';
import { RetryEventData, RetryService } from './Retry.service';
import { AttachmentsRegistry } from './types';

// Maybe rename it to MessageMediator as it handles message optimistic qeue as well?
@injectable()
export class AttachmentsMediator implements IAttachmentsMediator {
  private progressStore: AttachmentsProgressBarViewStore;
  private uploadService: FileUploadService;
  private retryService: RetryService;
  private optimisticQueueService: OptimisticQueueService<MessageModel>;
  private messageStore: IMessageStore;
  private communityStore: ICommunityStore;
  private channelStore: IChannelStore;
  private repository: IAttachmentsRepository;

  // contains attachments that uploading right now. Required to avoid issue when
  // attachments registry has 5 items, batch url requested, user deleted
  // the attachment and it will contain 4 items, but the batsh response later will contain 5 items and need to handle it. I can't use the msgModel.attachments as it might contain the previously uploaded items from old qeue
  private processingAttachmentsRegistry: AttachmentsRegistry | null = null;

  get activeMessageModel() {
    return this.messageStore.activeMessageModel;
  }

  get activeCommunityId() {
    return this.communityStore.activeCommunity.data?.serverData.id as string;
  }
  get activeChannelId() {
    return this.channelStore.activeChannel.data?.serverData.id;
  }

  isOptimisticQueueRunning = false;

  setIsOptimisticQueueRunning = (val: boolean) => {
    this.isOptimisticQueueRunning = val;
  };

  eventBus: IEventBus<MediatorEventsType>;

  public constructor(
    @inject(IOC_TOKENS.messageStore) messageStore: IMessageStore,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.channelStore) channelStore: IChannelStore,
    @inject(IOC_TOKENS.attachmentsProgressBarViewStore)
    attachmentsProgressBarViewStore: AttachmentsProgressBarViewStore,
    @inject(IOC_TOKENS.attachmentsRepository)
    repository: IAttachmentsRepository,
    @inject(IOC_TOKENS.fileUploadService)
    fileUploadService: FileUploadService,
    @inject(IOC_TOKENS.retryService)
    retryService: RetryService,
    @inject(IOC_TOKENS.optimisticQueueService)
    optimisticQueueService: OptimisticQueueService<MessageModel>,
    @inject(IOC_TOKENS.eventBusFactory)
    eventBusFactory: any
  ) {
    this.messageStore = messageStore;
    this.channelStore = channelStore;
    this.communityStore = communityStore;

    this.progressStore = attachmentsProgressBarViewStore;

    this.uploadService = fileUploadService;
    this.retryService = retryService;
    this.optimisticQueueService = optimisticQueueService;

    this.eventBus = eventBusFactory();
    // A simple ts overload trick. Just to keep things simple.
    this.uploadService.eventBus = this.eventBus as IEventBus<FileUploadEventsEnum>;
    this.retryService.eventBus = this.eventBus as IEventBus<RetryEventsEnum>;
    this.optimisticQueueService.eventBus = this.eventBus as IEventBus<OptimisticQueueEventsEnum>;

    this.repository = repository;

    makeObservable(this, {
      isOptimisticQueueRunning: observable,
      setIsOptimisticQueueRunning: action,
    });
  }

  /**
   * should be called in the useEffect of the required component
   * as this class is a singletone but still need to clear memory on unmount.
   * After the unmount if the user will come back to the component - need to subscribe again
   */
  setupEventListeners = () => {
    this.eventBus.on(MediatorEvents.UPLOADING_START, this.onUploadingStart);
    this.eventBus.on(
      MediatorEvents.UPLOADING_PROGRESS,
      this.onUploadingProgress
    );
    this.eventBus.on(MediatorEvents.UPLOADING_SUCCESS, this.onUploadingSuccess);
    this.eventBus.on(MediatorEvents.UPLOADING_ERROR, this.onUploadingError);
    this.eventBus.on(MediatorEvents.RETRY_START, this.onRetryStart);
    this.eventBus.on(
      MediatorEvents.RETRY_MAX_ATTEMPT_REACHED,
      this.onRetryMaxReached
    );
    this.eventBus.on(MediatorEvents.QUEUE_END, this.onUploadQeueEnd);
    this.eventBus.on(
      MediatorEvents.OPTIMISTIC_QUEUE_END,
      this.onOptimisticQueueEnd
    );
    this.eventBus.on(
      MediatorEvents.OPTIMISTIC_QUEUE_NEXT,
      this.onOptimisticQueueNext
    );
    this.eventBus.on(
      MediatorEvents.OPTIMISTIC_QUEUE_NEXT_AFTER_FAILED,
      this.onOptimisticQueueNextAfterFailed
    );
  };

  private disposeEventListeners = () => {
    this.eventBus.removeListener(
      MediatorEvents.UPLOADING_PROGRESS,
      this.onUploadingProgress
    );
    this.eventBus.removeListener(
      MediatorEvents.UPLOADING_START,
      this.onUploadingStart
    );
    this.eventBus.removeListener(
      MediatorEvents.UPLOADING_SUCCESS,
      this.onUploadingProgress
    );
    this.eventBus.removeListener(
      MediatorEvents.UPLOADING_ERROR,
      this.onUploadingError
    );
    this.eventBus.removeListener(MediatorEvents.RETRY_START, this.onRetryStart);
    this.eventBus.removeListener(
      MediatorEvents.RETRY_MAX_ATTEMPT_REACHED,
      this.onRetryMaxReached
    );
    this.eventBus.removeListener(
      MediatorEvents.QUEUE_END,
      this.onUploadQeueEnd
    );
    this.eventBus.removeListener(
      MediatorEvents.OPTIMISTIC_QUEUE_END,
      this.onOptimisticQueueEnd
    );
    this.eventBus.removeListener(
      MediatorEvents.OPTIMISTIC_QUEUE_NEXT,
      // @ts-ignore
      this.onOptimisticQueueNext
    );
    this.eventBus.removeListener(
      MediatorEvents.OPTIMISTIC_QUEUE_NEXT_AFTER_FAILED,
      this.onOptimisticQueueNextAfterFailed
    );
  };

  private onOptimisticQueueEnd = (lastMessageModel?: MessageModel) => {
    // means it's an end of the active queue. There might be some failed messages but it isn't the business of this method
    if (!lastMessageModel) {
      this.progressStore.setCompleted();
      return;
    }

    // means it's an active message model and attachments were added to the
    // message text area as previews so shouldn't create a message
    if (!lastMessageModel.optimistic) {
      const attachmentsLn = lastMessageModel.attachmenthsCount;
      const attachmentsRegistry = lastMessageModel.attachments;

      if (!this.optimisticQueueService.running) {
        this.setIsOptimisticQueueRunning(false);
      }

      // there always will be at least 1 attachment model because it's at this step
      this.progressStore.setStart(
        lastMessageModel.getFirstAttachmentIfExists() as AttachmentModel
      );
      this.uploadAttachments(attachmentsRegistry, attachmentsLn);

      return;
    } else {
      // means that this message model was optimistically added to the message list so should create a message
      this.proceedCompletedMessageModel(lastMessageModel);
    }
  };

  private proceedCompletedMessageModel = (messageModel: MessageModel) => {
    const attachmentsServerIds = messageModel?.getAttachmentsServerIds();
    const { text, rawJson } = messageModel.serverData;
    const realPayload = {
      text: text,
      rawJson: rawJson,
      attachmentsId: attachmentsServerIds || ([] as any),
      parentId: '',
      threadId: '',
      // The cached model should have the real ID from the precreated mutation
      preCreatedId: messageModel.id,
    };

    // means it was the active message model. Attachments were added as previews into the message text area. Can't create a message automatically

    this.progressStore.setProcessing();

    // TODO: I think will be better to move this logic outside as Edit
    this.messageStore
      .createMessage(this.activeCommunityId, this.activeChannelId as string, {
        // TODO: probably remove this object and leave just real param
        real: realPayload as any,
        // optimistic: optimisticPayload,
      })
      .then(() => {
        this.setIsOptimisticQueueRunning(false);
        // TODO: DI
        // this.messageStore.isOptimisticMessageExisting = false;

        // here could be handled the optimistic queue next item
        if (this.optimisticQueueService.running) {
          this.optimisticQueueService.proceedNext();
        } else {
          this.progressStore.setCompleted();
        }
      });
  };

  private onOptimisticQueueNext = (dataStep?: DataStep<MessageModel>) => {
    if (!dataStep) {
      throw new Error('there is no dataStep');
    }

    const { prevCompletedItem: completedMsgModel } = dataStep;

    this.proceedCompletedMessageModel(completedMsgModel);
  };

  private onOptimisticQueueNextAfterFailed = () => {
    this.optimisticQueueService.proceedNext();
  };

  private onRetryStart = (data?: RetryEventData<AttachmentModel>) => {
    if (!data) {
      throw new Error('there is no data');
    }

    const { attemptCount, data: failedAttachmentModel } = data;
    const humanReadableCounter = attemptCount + 1;
    this.progressStore.setRetry(humanReadableCounter, failedAttachmentModel);

    const msgModel: MessageModel =
      failedAttachmentModel.parentMessageModel as MessageModel;
    const failedAttachmentsRegistry: AttachmentsRegistry = {};
    let failedAttachmentDetected = false;
    let failedAttachmentsCounter = 0;

    for (const [id, attachmentModel] of Object.entries(msgModel.attachments)) {
      // means all the attachments after the failed one should be added including the failed one
      if (id === failedAttachmentModel.id || failedAttachmentDetected) {
        failedAttachmentDetected = true;
        failedAttachmentsRegistry[id] = attachmentModel;
        failedAttachmentsCounter++;
      }
    }

    msgModel.setFailed(false);

    // start the uploading process with the failed registry
    this.uploadAttachments(failedAttachmentsRegistry, failedAttachmentsCounter);
  };

  private onRetryMaxReached = (data?: RetryEventData<AttachmentModel>) => {
    if (!data) {
      throw new Error('there is no data');
    }
    const { data: failedAttachmentModel } = data;
    const msgModel: MessageModel = failedAttachmentModel.parentMessageModel;
    msgModel.failAttachmentsAfterTheFailedOne(failedAttachmentModel.id);

    this.progressStore.setFailed();

    if (this.optimisticQueueService.running) {
      this.optimisticQueueService.proceedNextAfterFailedOne();
    }
  };

  private onUploadingStart = (attachment?: AttachmentModel) => {
    if (!attachment) {
      throw new Error('there is no attachment');
    }

    if (this.progressStore.state.status !== FileUploadStatusEnum.UPLOADING) {
      this.progressStore.setStart(attachment);
    } else {
      this.progressStore.updateAttachment(attachment);
    }
  };

  private onUploadingProgress = (loadedPercent?: number) => {
    if (loadedPercent === undefined) {
      throw new Error('there is no loadedPercent');
    }
    this.progressStore.updateProgress(loadedPercent);
  };
  private onUploadingSuccess = (data?: {
    uploadedAttachmentModel: AttachmentModel;
    serverAttachmentId: string;
  }) => {
    if (!data) {
      throw new Error('there is no data');
    }
    const { uploadedAttachmentModel, serverAttachmentId } = data;
    uploadedAttachmentModel.serverAttachmentId = serverAttachmentId;
    uploadedAttachmentModel.setUploading(false);
  };

  private onUploadingError = (data?: {
    error: string;
    failedAttachmentModel: AttachmentModel;
  }) => {
    if (!data) {
      throw new Error('there is no data');
    }
    const { error: _error, failedAttachmentModel } = data;

    const parentMessageModel = failedAttachmentModel.parentMessageModel;

    parentMessageModel.setFailed(true);

    // start auto retry service
    this.retryService.start<AttachmentModel>(failedAttachmentModel);
  };

  private onUploadQeueEnd = (attachmentModel?: AttachmentModel) => {
    if (!attachmentModel) {
      throw new Error('there is no attachmentModel');
    }

    const parentMsgModel = attachmentModel.parentMessageModel;
    if (!parentMsgModel.failed || !attachmentModel.failed) {
      const isRegistered = this.optimisticQueueService.isInActiveRegistry(
        parentMsgModel.id
      );

      /**
       * isRegistered = false will be in the case when the optimistic message had been acced to the list and it's failed. Means the optimistic queue will start to proceed next model (in our case the activeMessageModel) in the text area. When the attachments had been added to this model and finished uploading - the model was completed and removed from the optimistic registry. But when the user adds a new attachments to it -> means the queue is running (because failed optimistic message exists) but the active message model does not exists in the optimistic registry as it previously was removed due to completed attachments. So the newly added attachments will be uploaded but there is no reason to proceedNext optimistic queue if it had already been processed and removed by this service
       */
      if (this.optimisticQueueService.running) {
        if (isRegistered) {
          this.optimisticQueueService.proceedNext();
        }
      } else {
        if (
          this.isOptimisticQueueRunning &&
          this.messageStore.editData.active
        ) {
          /**
           * in the edit mode the message model will be already in the list
           * and when the added attachments are uploading and user sends the
           * message so there will be runned optimisticQueueService because
           * need to wait uploding end to update the message but no need to add
           * it to optimistic qeue
           */
          if (this.messageStore.editData.active) {
            this.setIsOptimisticQueueRunning(false);
            this.eventBus.emit(
              MediatorEvents.EDIT_MESSAGE_OPTIMISTIC_UPLOAD_END,
              parentMsgModel
            );
          }
        }

        this.progressStore.setCompleted();
      }
    }
    this.processingAttachmentsRegistry = null;
  };

  proceedAttachments = (filesContent: FileContent[], plainFiles: File[]) => {
    const plainFilesLength = plainFiles.length;

    if (!this.activeMessageModel) {
      throw new Error('No active message model');
    }

    const [attachmentsRegistry, firstAttachmentModel] =
      this.filesToAttachmentModels(filesContent, plainFiles);

    this.updateAttachmentsInMsgModel(attachmentsRegistry);

    if (this.activeMessageModel.failed) {
      return;
    }

    // if there are processing other attachments shouldn't show the start progress bar status
    if (!this.processingAttachmentsRegistry) {
      this.progressStore.setStart(firstAttachmentModel);
    }

    const isMsgAlreadyProcessed =
      this.activeMessageModel.hasSomeAttachmentWasUploaded;

    // should just add the message to the queue
    if (this.optimisticQueueService.running && !isMsgAlreadyProcessed) {
      // shouldn't add if it's an active message
      this.optimisticQueueService.addToActiveRegistryIfNotRegistered(
        this.activeMessageModel.id,
        this.activeMessageModel
      );
    } else {
      this.uploadAttachments(attachmentsRegistry, plainFilesLength);
    }
  };

  // reUploadAllFailed = (attachmentModel: AttachmentModel) => {
  reUploadAllFailed = (msgModel: MessageModel) => {
    // const msgModel = attachmentModel.parentMessageModel;

    const {
      failedAttachmentsRegistry,
      failedItemsCounter,
      firstFailedAttachment,
    } = msgModel.makeUploadingAllFailedAttachments();

    this.progressStore.setStart(firstFailedAttachment);

    // start the uploading process with the failed registry
    this.uploadAttachments(failedAttachmentsRegistry, failedItemsCounter);
  };

  private filesToAttachmentModels = (
    filesContent: FileContent[],
    plainFiles: File[]
  ): [AttachmentsRegistry, AttachmentModel] => {
    let firstAttachmentModel: AttachmentModel;
    const attachmentModelsData = plainFiles.reduce((acc, plainFile, index) => {
      const attachmentModel = new AttachmentModel(
        filesContent[index],
        plainFile,
        this.activeMessageModel as MessageModel
      );

      // set failed if the message model is failed already
      if (this.activeMessageModel?.failed) {
        attachmentModel.setFailed(true);
        attachmentModel.setUploading(false);
      }

      acc[attachmentModel.id] = attachmentModel;

      if (index === 0) {
        // to avoid further lookup for the first model
        firstAttachmentModel = attachmentModel;
      }

      return acc;
    }, {} as AttachmentsRegistry);

    // @ts-ignore
    return [attachmentModelsData, firstAttachmentModel];
  };

  uploadAttachments = async (
    attachmentsRegistry: AttachmentsRegistry,
    count: number
  ) => {
    // save the pointer for the case when user will delete a file
    this.processingAttachmentsRegistry = attachmentsRegistry;

    const batchUrls = await this.getBatchUrls(count);
    if (!batchUrls) {
      throw new Error('no batch urls');
    }
    const attachmentsLn = Object.values(attachmentsRegistry).length;
    const urlsLn = batchUrls.length;
    const delta = urlsLn - attachmentsLn;
    if (delta) {
      // just remove the redundant batch url stuff. It's necessary because
      // for ex we have 5 attachs, and request to the urls had been send with
      // the ln 5. Then user deleted one before the response come and
      // the current ln is 4, but the response will still contain 5 so need
      // to handlel it
      batchUrls.splice(0, delta);
    }

    this.uploadService.setBatchUrls(batchUrls);
    this.uploadService.uploadAttachments(attachmentsRegistry);
  };

  removeAttachment = (attachmentModel: AttachmentModel) => {
    const id = attachmentModel.id;
    const messageModel = attachmentModel.parentMessageModel;
    if (!messageModel) {
      throw new Error('There is no parent message model');
    }
    delete messageModel.attachments[id];
    // should delete the file in the the current attachments regsitry
    if (this.processingAttachmentsRegistry !== null) {
      delete this.processingAttachmentsRegistry?.[id];
    }

    this.uploadService.removeAttachment(attachmentModel);
  };

  private getBatchUrls = async (attachmentsCount: number) => {
    const { data, error } = await this.repository.getBatchUrls(
      this.activeCommunityId,
      attachmentsCount
    );

    if (error) {
      // TODO: api base. common error toast
      // this.handleError('Can not get batch urls', error.message);
      throw new Error('Can not get batch urls');
    }

    return data;
  };

  private updateAttachmentsInMsgModel = (attachments: AttachmentsRegistry) => {
    const firstAttachment = Object.values(attachments)[0];
    const messageModel = firstAttachment.parentMessageModel;
    if (!messageModel) {
      throw new Error(
        `Missed parent message model in the attachment with ID: ${firstAttachment.id}`
      );
    }

    const combinedAttachments = { ...messageModel.attachments, ...attachments };
    messageModel.updateAttachments(combinedAttachments);
  };

  // think if it could be combined with reUploadALlFailed
  reUploadMessage = (messageModel: MessageModel) => {
    // theoretically it should always be running because this method
    // could be triggered only when the optimistic message was failed
    if (this.optimisticQueueService.running) {
      this.optimisticQueueService.addFailedToActiveQueue(messageModel.id);
      // move step forward but without events as this model will be handled manually
      this.reUploadAllFailed(messageModel);
    }
  };

  enableOptimisticQueue = () => {
    // this.messageStore.isOptimisticMessageExisting = true;
    this.setIsOptimisticQueueRunning(true);
  };

  addMessageToOptimisticQueue = (messageModel: MessageModel) => {
    this.enableOptimisticQueue();

    this.optimisticQueueService.addToActiveRegistryIfNotRegistered(
      messageModel.id,
      messageModel
    );
  };

  dispose = () => {
    this.disposeEventListeners();
  };
}

export interface IAttachmentsMediator {
  eventBus: IEventBus<MediatorEventsType>;
  isOptimisticQueueRunning: boolean;
  proceedAttachments: (filesContent: FileContent[], plainFiles: File[]) => void;
  removeAttachment: (attachmentModel: AttachmentModel) => void;
  reUploadAllFailed: (messageModel: MessageModel) => void;
  uploadAttachments: (
    attachmentsRegistry: AttachmentsRegistry,
    count: number
  ) => Promise<void>;
  reUploadMessage: (messageModel: MessageModel) => void;

  addMessageToOptimisticQueue: (messageModel: MessageModel) => void;
  enableOptimisticQueue: () => void;

  setupEventListeners: () => void;
  dispose: () => void;
}
