import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  cn?: string;
  isMobile: boolean;
  children: React.ReactNode;
};

export function Sidebar(props: Props) {
  const { cn, isMobile, children } = props;
  return (
    <div
      className={classNames(
        'h-full py-[24px]  md:w-[216px]',
        isMobile ? 'themed-layout h-full w-full' : 'themed-layout-secondary',
        cn
      )}
    >
      {children}
    </div>
  );
}
