import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { StepNamesEnum } from '@mainApp/src/components/post-auth-onboarding/PostAuthOnboarding.store.types';

import { SubscriptionList as SubscriptionListUI } from '@mainApp/src/components/common';

import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';

export function _SubscriptionList() {
  const { postAuthOnboardingStore, userStore } = useMultipleInjection([
    IOC_TOKENS.postAuthOnboardingStore,
    IOC_TOKENS.userStore,
  ]);
  const subscriptionStep =
    postAuthOnboardingStore.steps[StepNamesEnum.SUBSCRIPTION];
  const activeSubscriptionType = subscriptionStep.data.activeSubscriptionType;

  const subscriptionPrices = userStore.subscriptionPrices;

  return (
    <SubscriptionListUI
      activeSubscriptionType={activeSubscriptionType}
      subscriptionPrices={subscriptionPrices}
      onChange={(slug: SubscriptionTypeEnum) => {
        postAuthOnboardingStore.setStepData(StepNamesEnum.SUBSCRIPTION, {
          activeSubscriptionType: slug as SubscriptionTypeEnum,
        });
      }}
    />
  );
}

export const SubscriptionList = observer(_SubscriptionList);
