import { AggregatedRulesScreen } from './screens/AggregatedRulesScreen';
import { RulesDetailsScreen } from './screens/RuleDetailsScreen';
import { SubRulesScreen } from './screens/SubRulesScreen';

export const ScreenIds = {
  INITIAL: 'aggregate-rules',
  SUB_RULES: 'sub-rules',
  RULE_DETAILS: 'rule-details',
} as const;

export const ScreensItems = [
  {
    id: ScreenIds.INITIAL,
    Component: AggregatedRulesScreen,
  },
  {
    id: ScreenIds.SUB_RULES,
    Component: SubRulesScreen,
  },
  {
    id: ScreenIds.RULE_DETAILS,
    Component: RulesDetailsScreen,
  },
];
