import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { BadgeDot } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannedUsersTable,
  FeedbackAction,
  UsersFilterActionButton,
} from './components';
import { UsersTable } from './components/UsersTable';

import { Role_Levels, Role_List_Types } from '@10x/foundation/types';
import { type IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { observer } from 'mobx-react-lite';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

const _Users = () => {
  const { t } = useTranslation();
  const {
    capStore,
    dimensionsStore: { isMobile },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.capStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.communityStore,
  ]);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const {
    userToBan,
    cancelBanMember,
    confirmBanMember,
    activeTabIndex,
    setActiveTabIndex,
    membersBlockedCount,
    membersCount,
    activeCommunityId,
  } = usersStoreLocal || {};

  useEffect(() => {
    if (activeCommunityId) {
      communityStore.getCommunityMembers({
        communityId: activeCommunityId,
      });

      communityStore.getCommunityBlockedMembers({
        communityId: activeCommunityId,
      });

      communityStore.getCommunityRoles(activeCommunityId, {
        listType: Role_List_Types.Community,
        roleLevels: [Role_Levels.Admin, Role_Levels.Owner, Role_Levels.Custom],
      });
    }
  }, [activeCommunityId]);

  const getTabTitle = ({
    title,
    count,
    active,
  }: {
    title: string;
    count: number;
    active: boolean;
  }) => {
    return (
      <>
        {title}
        <BadgeDot
          cn={classNames('ml-[8px] py-0', {
            'themed-text text-sm14T': active,
          })}
          label={String(count || 0)}
          type="negative"
          icon={<></>}
        />
      </>
    );
  };

  return (
    <ScreenWrapper
      title={t('cap:menu.users')}
      sidebarModalController={capStore}
      showBackBtn={isMobile}
      ScreenHeaderProps={{
        children: isMobile ? (
          <div className="flex items-center justify-between">
            <span className="themed-text text-body16SB">
              {t('cap:menu.users')}
            </span>

            <UsersFilterActionButton />
          </div>
        ) : (
          <>{t('cap:menu.users')}</>
        ),
        cn: classNames({
          'pb-[20px]': isMobile,
        }),
        hideCloseButton: isMobile,
        childrenWrapperCn: classNames({
          'pr-0': isMobile,
        }),
      }}
      rootCn={classNames('pt-[16px]', {
        'pb-0 px-[16px] relative': isMobile,
      })}
    >
      <div className="absolute bottom-[24px] right-0 z-2 flex w-full flex-col gap-[16px] px-[24px]">
        {/* The invites button will be add on next version */}
        {/* {isMobile && data.length > 0 && (
          <PrimaryButton
            cn="w-auto self-center rounded-full"
            onClick={() => {}}
          >
            {t('cap:users.invitePeople')}
          </PrimaryButton>
        )} */}

        {userToBan && (
          <FeedbackAction
            duration={10}
            label={t('cap:users.userWasBanned', {
              name: userToBan.displayName,
            })}
            cn="md:self-end"
            onConfirmAction={() => confirmBanMember()}
            onCancelAction={() => cancelBanMember()}
          />
        )}
      </div>

      <div className="h-full w-full flex-1 ">
        <div className="flex h-full flex-col">
          <Tabs
            className="flex flex-1 flex-col overflow-hidden"
            selectedIndex={activeTabIndex}
            selectedTabClassName="border-b-[3px] transition-all themed-text border-b-primary-100"
            onSelect={(index) => setActiveTabIndex(index)}
          >
            <TabList
              className={classNames(
                'themed-text-secondary hidden h-[48px] border-b border-b-element-subtle text-sm14SB dark:border-b-element-subtle-dark md:flex'
              )}
            >
              <Tab
                className={classNames(
                  'flex cursor-pointer items-center px-[24px] py-[12px]'
                )}
              >
                {getTabTitle({
                  title: t('cap:users.tabs.members'),
                  count: membersCount,
                  active: activeTabIndex === 0,
                })}
              </Tab>

              <Tab
                className={classNames(
                  'flex cursor-pointer items-center px-[24px] py-[12px]'
                )}
              >
                {getTabTitle({
                  title: t('cap:users.tabs.banned'),
                  count: membersBlockedCount,
                  active: activeTabIndex === 1,
                })}
              </Tab>
            </TabList>

            <TabPanel
              className={classNames({
                'flex flex-1 flex-col overflow-hidden': activeTabIndex === 0,
              })}
            >
              <UsersTable />
            </TabPanel>

            <TabPanel
              className={classNames({
                'flex flex-1 flex-col overflow-hidden': activeTabIndex === 1,
              })}
            >
              <BannedUsersTable />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export const Users = withAnimatedStack(observer(_Users));
