import { useEffect, useRef, useState } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

// import { useKeyPressEvent } from 'react-use';

import { DropFileLayer } from './DropFileLayer';

type Props = {
  children: React.ReactNode;
  onFileCatch: (files: FileList, isShiftPressed: boolean) => void;
};

export function MessageListDropZone(props: Props) {
  const { children, onFileCatch } = props;
  // const pickedFileSizeRef = useRef(undefined);
  // const isNonImageUploadRef = useRef(false);
  const shiftHoldRef = useRef(false);
  const dropZoneRef = useRef<HTMLDivElement | null>(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleDragStart = (event: DragEvent) => {
    if (event.shiftKey !== shiftHoldRef.current) {
      setIsShiftPressed(event.shiftKey);
      shiftHoldRef.current = event.shiftKey;
      window.focus();
    }
  };

  const attachDragListeners = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current.addEventListener('dragenter', handleDragStart);
    }
  };

  // Remove the drag event listeners when the component unmounts
  const removeDragListeners = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current.removeEventListener('dragenter', handleDragStart);
    }
  };

  useEffect(() => {
    attachDragListeners();
    return removeDragListeners;
  }, [dropZoneRef.current]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: FileList }) {
        const files = item.files;
        if (files && files.length) {
          onFileCatch(files, shiftHoldRef.current);
        }
      },
      canDrop(item: any) {
        // isNonImageUploadRef.current = false;
        const file = item.files[0];
        const fileSize = file?.size;
        // pickedFileSizeRef.current = fileSize;

        const isImage = file?.type.includes('image');
        let res = true;
        if (file && fileSize > 100000 * 50) {
          alert('You can upload only attachment with the size less than 10Mb');
          res = false;
        }

        if (file && !isImage) {
          res = false;
          alert('Sorry, currently we support only images');
          // isNonImageUploadRef.current = true;
        }

        return res;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const isActive = canDrop && isOver;

  return (
    <div
      ref={(ref) => {
        drop(ref);
        dropZoneRef.current = ref;
      }}
      className={'flex min-h-0 flex-1 flex-col'}
    >
      {children}
      {isActive && <DropFileLayer isShiftPressed={isShiftPressed} />}
    </div>
  );
}
