import { useEffect, useState } from 'react';

export const useImage = (src: string | void) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!src) return;

    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return {
    loaded,
  };
};
