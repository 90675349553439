import * as React from 'react';

import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';
import { AnimatedLayoutAnvil } from '../layouts';

import { usePopoverContext } from './PopoverCtx';

export const PopoverContent = React.forwardRef(function PopoverContent(
  props,
  propRef
) {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const { enableFocusManager, ...otherProps } = props;

  if (!floatingContext.open) return null;

  let content = (
    <div
      ref={ref}
      className="popover-content themed-layout z-1"
      style={{
        ...context.floatingStyles,
        ...props.style,
      }}
      aria-labelledby={context.labelId}
      aria-describedby={context.descriptionId}
      {...context.getFloatingProps(otherProps)}
    >
      <AnimatedLayoutAnvil isShow={floatingContext.open}>
        {props.children}
      </AnimatedLayoutAnvil>
    </div>
  );

  if (enableFocusManager) {
    let content = (
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        {content}
      </FloatingFocusManager>
    );
  }

  return <FloatingPortal>{content}</FloatingPortal>;
});
