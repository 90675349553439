import { OverviewStore } from './screens/overview/data/Overview.store.local';
import { PermissionsStore } from './screens/permissions/data/Permissions.store.local';
export const ScreenIds = {
    INITIAL: 'initial_menu',
    OVERVIEW: 'overview',
    PERMISSIONS: 'permissions',
}


export const childScreensRegistry = {
    [ScreenIds.OVERVIEW]: OverviewStore,
    [ScreenIds.PERMISSIONS]: PermissionsStore,
};