'use client';
import { DotsButton, UnionSVG } from '@foundationPathAlias/components';
import { TooltipMenu } from '@foundationPathAlias/components/menu/tooltip-menu/TooltipMenu';
import { classNames } from '@foundationPathAlias/utilities';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ICommunityRule } from '@mainApp/src/stores/Rules.store';
import { extractPlainText } from '@mainApp/src/utils/lexical';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

type CommunityRuleItemProps = {
  data: ICommunityRule;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDelete: (ruleId: string) => void;
  onEdit: (rule: ICommunityRule) => void;
  onClick: () => void;
};

const _CommunityRuleItem = ({
  index,
  moveCard,
  data,
  onDelete,
  onEdit,
  onClick,
}: CommunityRuleItemProps) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const menuContentRef = React.useRef<HTMLUListElement>(null);

  const cardRef = React.useRef<HTMLDivElement>(null);

  const { id, title, rawJSON } = data;

  const {
    dimensionsStore: { isMobile },
    capStore,
  } = useMultipleInjection([IOC_TOKENS.dimensionsStore, IOC_TOKENS.capStore]);

  const { t } = useTranslation(['cap']);

  const handleClose = React.useCallback(() => {
    setMenuIsOpen(false);
  }, [menuIsOpen]);

  const fnWrapper = (fn: () => void) => () => {
    fn();
    handleClose();
  };

  const contentMenu = (
    <ul
      ref={menuContentRef}
      className={classNames(
        ' dark:bg-background-tetriary-dark',
        isMobile
          ? 'flex w-full flex-col gap-3 px-4 py-4'
          : 'w-48 rounded-[5px] border-0 py-2 shadow-shadow-menu-dekstop'
      )}
    >
      <TooltipMenu.Item
        label={t('cap:rules.editRule')}
        onClick={fnWrapper(() => onEdit(data))}
        icon={<PencilIcon width={20} height={20} />}
      />

      <hr className="border-t-element-subtle dark:border-t-element-subtle-dark" />

      <TooltipMenu.Item
        label={t('cap:rules.delete')}
        onClick={fnWrapper(() => onDelete(id))}
        icon={<TrashIcon width={20} height={20} />}
        className="text-element-error"
      />
    </ul>
  );

  const handleOpenMenu = () => {
    if (isMobile) {
      capStore.setBottomSheetConfig({
        content: contentMenu,
        show: true,
      });

      return;
    }
    setMenuIsOpen(true);
  };

  const [, drop] = useDrop({
    accept: 'card',
    hover(item: { index: number }) {
      if (!cardRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(cardRef));

  useClickAway(menuContentRef, menuIsOpen ? handleClose : () => {});

  return (
    <>
      {isDragging && (
        <>
          <hr className="border-t-2 border-t-primary-100 dark:border-t-primary-100-dark" />
          <div className="h-20 w-full rounded-md border-2 border-dashed border-primary-100 bg-primary-10 dark:border-primary-100-dark dark:bg-primary-10-dark" />
        </>
      )}
      <div
        ref={cardRef}
        onClick={onClick}
        className={classNames(
          'flex cursor-pointer gap-4 rounded-md border border-element-subtle bg-background-secondary p-4',
          'dark:border-element-subtle-dark dark:bg-background-quaternary-dark',
          isDragging ? 'opacity-0' : 'opacity-100'
        )}
      >
        <UnionSVG className="flex-shrink-0 self-center" />

        <div className="flex flex-col items-start text-start">
          <b className="text-body16SB text-text-primary dark:text-text-primary-dark">
            {title}
          </b>

          <p className="line-clamp-1 text-ellipsis text-sm14R text-text-secondary dark:text-text-secondary-dark">
            {extractPlainText(rawJSON)}
          </p>
        </div>

        {isMobile ? (
          <DotsButton
            cnWrapper="ml-auto flex-shrink-0 "
            type="vertical"
            className="flex-shrink-0 dark:text-text-secondary-dark "
            onClick={handleOpenMenu}
          />
        ) : (
          <TooltipMenu
            open={menuIsOpen}
            onClose={handleClose}
            contentStyle={{
              width: 'auto',
              padding: '0',
              fontSize: '14px',
              borderRadius: 5,
            }}
            closeOnDocumentClick={false}
            arrow={false}
            trigger={
              <div className="ml-auto flex-shrink-0 ">
                <DotsButton
                  type="vertical"
                  className="dark:text-text-secondary-dark"
                  onClick={handleOpenMenu}
                />
              </div>
            }
            position={['bottom left']}
          >
            {contentMenu}
          </TooltipMenu>
        )}
      </div>
    </>
  );
};

export const CommunityRuleItem = observer(_CommunityRuleItem);
