'use client';
import { observer } from 'mobx-react-lite';

import { ScreenWrapper } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { type ICustomizeStoreLocal } from './data/Customize.store.local';

import { classNames } from '@foundationPathAlias/utilities';
import {
  CommunityColors,
  FormFieldInput,
  UploadImageContainer,
  withAnimatedStack,
} from '@mainApp/src/components/common';
import { ThemeSwitcher } from '@mainApp/src/components/navbar/ThemeSwitcher';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CustomizeFormWrapper } from './components/CustomizeFormWrapper';
import { FormFieldLabel } from './components/FormFieldLabel';

const _Customize = () => {
  const { t } = useTranslation(['common', 'cap']);
  const { query } = useRouter();
  const communitySlug = query.community_slug;

  const {
    capStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([IOC_TOKENS.capStore, IOC_TOKENS.dimensionsStore]);

  const customizeForm =
    capStore.activeChildScreen as unknown as ICustomizeStoreLocal;

  useEffect(() => {
    if (typeof communitySlug === 'string') {
      customizeForm?.loadInitialDataByCommunitySlug(communitySlug);
    }
  }, [capStore.activeScreenId, communitySlug]);

  const { communityImages, communityColors } = customizeForm || {};

  useEffect(() => {
    communityColors?.initDefaultColors();
  }, []);

  const communityColorsErrorMessages = {
    invalidHexFormatError: t('common:communityColors.invalidHexFormatError'),
    tooLightColorError: t('common:communityColors.tooLightColorError'),
    tooDarkColorError: t('common:communityColors.tooDarkColorError'),
    useAlternativeColorLm: t('common:communityColors.useAlternativeColorLm'),
    useAlternativeColorDm: t('common:communityColors.useAlternativeColorDm'),
  };

  return (
    <ScreenWrapper
      title={t('cap:menu.customize')}
      sidebarModalController={capStore}
      showBackBtn={isMobile}
      ScreenHeaderProps={{
        children: (
          <div className="flex flex-1 items-center justify-between">
            <span>{t('cap:menu.customize')}</span>

            <ThemeSwitcher wrapperCn="-my-[8px] mr-[16px] md:mr-[8px]" />
          </div>
        ),
        childrenWrapperCn: classNames({
          'pr-0': isMobile,
        }),
        cn: 'pb-[20px]',
      }}
      rootCn={classNames(isMobile ? 'p-0' : 'pb-0 px-0 pt-0')}
    >
      <DndProvider backend={HTML5Backend}>
        <CustomizeFormWrapper>
          <div className="flex flex-col gap-8 overflow-y-auto px-4 py-6 scrollbar-thin md:bg-background-primary md:pt-[20px] md:dark:bg-background-primary-dark lg:max-w-[370px]">
            <FormFieldLabel
              label={t('cap:customize.thumbnail.label')}
              description={t('cap:customize.thumbnail.description')}
            >
              <UploadImageContainer
                label={t('cap:customize.thumbnail.label')}
                cropperZoomVal={communityImages?.cropperThumbnailZoomVal}
                fileSrc={communityImages?.thumbnail.fileUrl}
                onFileCatch={(files: FileList, fileUrl: string) => {
                  communityImages?.setThumbnail(files[0], fileUrl);
                }}
                onFileCrop={(croppedFile: File) => {
                  communityImages?.setThumbnail(croppedFile);
                }}
                setCropperZoomVal={(val) => {
                  if (val) {
                    communityImages?.setCropperZoomVal('thumbnail', val);
                  }
                }}
                onCancel={() => {
                  communityImages?.reset('thumbnail');
                }}
                onSave={() => {
                  communityImages?.triggerUploading('thumbnail');
                  communityImages?.setCropperZoomVal('thumbnail', 0);
                }}
                uploadingStatus={communityImages?.thumbnail.status}
                uploadingPercentage={
                  communityImages?.thumbnail.uploadingPercent
                }
                error={communityImages?.thumbnail.error}
                fileName={communityImages?.thumbnail.fileName}
                fileSize={communityImages?.thumbnail.fileSize}
              />
            </FormFieldLabel>

            <FormFieldLabel
              label={t('cap:customize.logo.label')}
              description={t('cap:customize.logo.description')}
            >
              <UploadImageContainer
                label={t('cap:customize.logo.label')}
                cropperZoomVal={communityImages?.cropperLogoZoomVal}
                fileSrc={communityImages?.logo.fileUrl}
                onFileCatch={(files: FileList, fileUrl: string) => {
                  communityImages?.setLogo(files[0], fileUrl);
                }}
                onFileCrop={(croppedFile: File) => {
                  communityImages?.setLogo(croppedFile);
                }}
                setCropperZoomVal={(val) => {
                  if (val) {
                    communityImages?.setCropperZoomVal('logo', val);
                  }
                }}
                onCancel={() => {
                  communityImages?.reset('logo');
                }}
                onSave={() => {
                  communityImages?.triggerUploading('logo');
                  communityImages?.setCropperZoomVal('logo', 0);
                }}
                uploadingStatus={communityImages?.logo.status}
                uploadingPercentage={communityImages?.logo.uploadingPercent}
                error={communityImages?.logo.error}
                fileName={communityImages?.logo.fileName}
                fileSize={communityImages?.logo.fileSize}
              />
            </FormFieldLabel>

            <FormFieldLabel
              label={t('cap:customize.nameAndDescription.label')}
              description={t('cap:customize.nameAndDescription.description')}
            >
              <FormFieldInput
                onChange={(value) =>
                  customizeForm?.updateName({
                    value,
                    error: customizeForm?.name.error,
                  })
                }
                onError={(error) =>
                  customizeForm?.updateName({
                    value: customizeForm?.name.value,
                    error,
                  })
                }
                // TODO: find a way to don't pass the value prop explicitly
                // because the value is being updated after the component is mounted
                InputProps={{
                  value: customizeForm?.name.value,
                }}
                value={customizeForm?.name.value}
                inputName="name"
                maxCharsLimit={25}
              />

              <FormFieldInput
                onChange={(value) =>
                  customizeForm?.updateDescription({
                    value,
                    error: customizeForm?.description.error,
                  })
                }
                onError={(error) =>
                  customizeForm?.updateDescription({
                    value: customizeForm?.description.value,
                    error,
                  })
                }
                // TODO: find a way to don't pass the value prop explicitly
                TextAreaProps={{
                  value: customizeForm?.description.value,
                }}
                value={customizeForm?.description.value}
                inputName="description"
                maxCharsLimit={95}
                textAreaMode
              />
            </FormFieldLabel>

            <FormFieldLabel
              label={t('cap:customize.topics.label')}
              description={t('cap:customize.topics.description')}
            >
              <FormFieldInput
                placeholder={
                  t('cap:customize.topics.formFields.topic1.placeholder') ?? ''
                }
                onChange={(value) =>
                  customizeForm?.updateTopic(1, {
                    value,
                    error: customizeForm?.topics.topic1.error,
                  })
                }
                onError={(error) =>
                  customizeForm?.updateTopic(1, {
                    error,
                    value: customizeForm?.topics.topic1.value,
                  })
                }
                // TODO: find a way to don't pass the value prop explicitly
                InputProps={{
                  value: customizeForm?.topics.topic1.value,
                }}
                value={customizeForm?.topics.topic1.value}
                maxCharsLimit={12}
                inputName="topic1"
              />
              <FormFieldInput
                placeholder={
                  t('cap:customize.topics.formFields.topic2.placeholder') ?? ''
                }
                onChange={(value) =>
                  customizeForm?.updateTopic(2, {
                    value,
                    error: customizeForm?.topics.topic2.error,
                  })
                }
                onError={(error) =>
                  customizeForm?.updateTopic(2, {
                    error,
                    value: customizeForm?.topics.topic2.value,
                  })
                }
                // TODO: find a way to don't pass the value prop explicitly
                InputProps={{
                  value: customizeForm?.topics.topic2.value,
                }}
                value={customizeForm?.topics.topic2.value}
                maxCharsLimit={12}
                inputName="topic2"
              />
            </FormFieldLabel>

            <FormFieldLabel
              label={t('cap:customize.style.label')}
              description={t('cap:customize.style.description')}
            >
              <CommunityColors
                lmLabel={t('cap:customize.style.formFieldLabels.lm')}
                dmLabel={t('cap:customize.style.formFieldLabels.dm')}
                lmPrimaryColor={communityColors?.lmPrimaryColor}
                dmPrimaryColor={communityColors?.dmPrimaryColor}
                initialPrimaryColor={communityColors?.initialPrimaryColor}
                setColor={communityColors?.setColor}
                setDmPrimaryWhite={communityColors?.setDmPrimaryWhite}
                setLmPrimaryBlack={communityColors?.setLmPrimaryBlack}
                errorMessages={communityColorsErrorMessages}
              />
            </FormFieldLabel>
          </div>
        </CustomizeFormWrapper>
      </DndProvider>
    </ScreenWrapper>
  );
};

export const Customize = withAnimatedStack(observer(_Customize));
