import { Avatar, AvatarPropsType } from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';

export type TableRowMemberProps = {
  displayName: string;
  username: string;
  src?: string;
  cn?: string;
  AvatarProps?: AvatarPropsType;
  displayNameCn?: string;
  usernameCn?: string;
};

export const TableRowMember = ({
  displayName,
  username,
  src,
  cn,
  AvatarProps,
  displayNameCn,
  usernameCn,
}: TableRowMemberProps) => {
  return (
    <div className={classNames('flex items-center gap-[8px]', cn)}>
      <Avatar
        height={36}
        width={36}
        src={src}
        alt={`User Avatar: ${displayName}`}
        {...AvatarProps}
      />

      <div className="flex flex-col items-start">
        <b
          className={classNames(
            'themed-text whitespace-nowrap text-sm14T',
            displayNameCn
          )}
        >
          {displayName}
        </b>
        <span
          className={classNames(
            'themed-text whitespace-nowrap text-sm13D',
            usernameCn
          )}
        >
          @{username}
        </span>
      </div>
    </div>
  );
};
