// just simulates chatroom and message box
import { useCallback, useContext, useEffect, useRef } from 'react';
import type { FileContent } from 'use-file-picker';
import { ChannelMessagesList } from './ChannelMessagesList';

import { useResizeObserver } from '@10x/foundation/src/utilities';
import dynamic from 'next/dynamic';
import { ChannelCtx, VirtualizerContextType } from './common/channelContext';
import { EditorWrapper } from './editor-area';

import { MediatorEvents } from '@mainApp/src/stores/attachments/events';
import {
  MessageAttachmentsProgressBar,
  MessageListDropZone,
} from './attachments';

import { emptyEditorState } from '@mainApp/src/stores/optimistic-mocks';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

const ChannelHeaderNoSSR = dynamic(() => import('./common/ChannelHeader'), {
  ssr: false,
});

import { DeleteMessageEventPayload } from '@mainApp/src/stores/Message.store.types';

import { MessagesEventsEnum } from '@mainApp/src/events';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export function WorkingArea() {
  const editorWrapperRef = useRef<HTMLDivElement | null>(null);
  const messageDataRef = useRef({
    text: '',
    stringifiedEditorState: '',
  });

  const channelCtx = useContext<VirtualizerContextType>(ChannelCtx);
  const { channelStore, messageStore, eventBus, attachmentsMediator } =
    useMultipleInjection([
      IOC_TOKENS.channelStore,
      IOC_TOKENS.messageStore,
      IOC_TOKENS.eventBus,
      IOC_TOKENS.attachmentsMediator,
    ]);

  useEffect(() => {
    eventBus.on(MessagesEventsEnum.DELETE_MESSAGE, deleteMessage);
    attachmentsMediator.eventBus.on(
      MediatorEvents.EDIT_MESSAGE_OPTIMISTIC_UPLOAD_END,
      updateMessage
    );
    return () => {
      attachmentsMediator.eventBus.removeListener(
        MediatorEvents.EDIT_MESSAGE_OPTIMISTIC_UPLOAD_END,
        updateMessage
      );
      eventBus.removeListener(MessagesEventsEnum.DELETE_MESSAGE, deleteMessage);
    };
  }, []);

  const deleteMessage = useCallback((payload?: DeleteMessageEventPayload) => {
    if (!payload) {
      throw new Error('There is no payload');
    }
    messageStore.deleteMessage(
      payload.communityId,
      payload.channelId,
      payload.messageModel.serverData.id
    );
  }, []);

  const onResizeCallback = useCallback(() => {
    // if the user scrolled to top and started to type or clicked on the Edit button and the previews vere added to the editor area it will be resized but no need to scroll to bottom
    if (channelCtx.virtualizer?.isScrollBarAtBottom) {
      channelCtx.virtualizer?.scrollToBottom();
    }
  }, [channelCtx.virtualizer]);

  const onCreateMessageCallback = useCallback(
    (text: string, stringifiedEditorState: string) => {
      const communityId =
        channelStore.activeChannel?.data?.serverData?.communityId;

      const channelId = channelStore.activeChannel?.data?.serverData?.id;
      if (!communityId || !channelId) {
        throw new Error(
          'Not existing channelId or communityId. Please, check it'
        );
      }

      // TODO: async await
      messageStore
        .preCreateChannelMessage(communityId, channelId)
        .then((response) => {
          const precreatedMessageID = response.id;

          const activeMessageModel = messageStore.activeMessageModel;
          if (!activeMessageModel) {
            throw new Error('There is no active message model');
          }

          const attachmentsServerIds =
            activeMessageModel?.getAttachmentsServerIds();

          const realPayload = {
            text,
            rawJson: stringifiedEditorState,
            preCreatedId: precreatedMessageID,
            attachmentsId: attachmentsServerIds || ([] as any),
            parentId: messageStore.replyModel?.serverData.id || '',
            threadId: '',
          };

          if (
            // allow only single optimistic message while attachments loading
            activeMessageModel?.isAttachmentsUploading
          ) {
            const optimisticPayload =
              activeMessageModel?.generateOptimisticResponse(
                {
                  text,
                  stringifiedEditorState,
                  id: precreatedMessageID,
                  replyModel: messageStore.replyModel,
                },
                true
              );

            const optimisticMessageModel = messageStore.optimisticCreateMessage(
              optimisticPayload as any
            );

            attachmentsMediator.addMessageToOptimisticQueue(
              optimisticMessageModel as MessageModel
            );
          } else {
            const optimisticPayload =
              activeMessageModel.generateOptimisticResponse(
                {
                  text,
                  stringifiedEditorState,
                  id: precreatedMessageID,
                  replyModel: messageStore.replyModel,
                },
                true
              );

            // TODO: correct types
            // @ts-ignore
            messageStore.optimisticCreateMessage(optimisticPayload);
            messageStore.createMessage(communityId, channelId, {
              real: realPayload,
              // TODO: type
              optimistic: optimisticPayload as any,
            });
          }

          // should create an empty message model to be ready to handle a new message
          // TODO: maybe move it to the store.createMessage method?
          messageStore.setActiveNewEmptyMessageModel();
        });
    },
    []
  );

  const updateMessage = () => {
    const { text, stringifiedEditorState } = messageDataRef.current;
    const communityId =
      channelStore.activeChannel?.data?.serverData?.communityId;

    const channelId = channelStore.activeChannel?.data?.serverData?.id;
    if (!communityId || !channelId) {
      throw new Error(
        'Not existing channelId or communityId. Please, check it'
      );
    }

    const processingMessageModel = messageStore.editData.editingMessageModel;
    if (!processingMessageModel) {
      throw new Error('There is no processing message model');
    }

    const attachmentsServerIds =
      processingMessageModel.getAttachmentsServerIds();

    const payload = {
      text,
      rawJson: stringifiedEditorState,
      attachmentsId: attachmentsServerIds || ([] as any),
    };

    messageStore.updateMessage(
      communityId,
      channelId,
      processingMessageModel.id,
      payload
    );
  };

  const onEditMessageCallback = useCallback(
    (text: string, stringifiedEditorState: string) => {
      messageDataRef.current = {
        text,
        stringifiedEditorState,
      };

      const activeMessageModel = messageStore.activeMessageModel;
      if (
        // allow only single optimistic message while attachments loading
        activeMessageModel?.isAttachmentsUploading
      ) {
        messageStore.applyEditChangesOptimistically();
        attachmentsMediator.enableOptimisticQueue();
      } else {
        updateMessage();
      }
    },
    []
  );

  useResizeObserver(
    editorWrapperRef.current as HTMLDivElement,
    onResizeCallback
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="relative flex h-full min-h-0 flex-1 flex-col pb-[20px]">
        <ChannelHeaderNoSSR />
        <MessageAttachmentsProgressBar />
        <MessageListDropZone
          onFileCatch={(fileList, isShiftPressed) => {
            const filesArray = Array.from(fileList);
            const filePreviews = filesArray.map((file) => ({
              content: URL.createObjectURL(file),
              name: file.name,
              lastModified: file.lastModified,
            }));

            attachmentsMediator.proceedAttachments(
              filePreviews as FileContent[],
              filesArray
            );
            if (isShiftPressed) {
              onCreateMessageCallback('', JSON.stringify(emptyEditorState));
            }
          }}
        >
          <div className="flex min-h-0 flex-1">
            <ChannelMessagesList />
          </div>

          <div
            ref={editorWrapperRef}
            className="mx-[20px] mt-[4px] flex min-h-[48px] flex-col "
          >
            <EditorWrapper
              onCreateMessage={onCreateMessageCallback}
              onEditMessage={onEditMessageCallback}
            />
          </div>
        </MessageListDropZone>
      </div>
    </DndProvider>
  );
}
