import _debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import { useRef } from 'react';

import { LoadingStatusEnum } from '@mainApp/src/stores/types';
import { useTranslation } from 'react-i18next';
import { DropZone } from './DropZone';

import { LoadedImagePreview } from './LoadedImagePreview';

import { CropperPropsType } from '@10x/foundation/src/components/cropper';

export const CropperFND = dynamic(
  () => {
    return import('@10x/foundation/src/components/cropper').then((res) => {
      return res.Cropper;
    });
  },
  { ssr: false }
);

type ImageContainerProps = {
  label: string;
  fileSrc?: string | null;
  onFileCatch: (file: FileList, originalFileSrc: string) => void;
  onCancel: () => void;
  onFileCrop: (file: File) => void;
  onSave: () => void;
  uploadingStatus: LoadingStatusEnum;
  uploadingPercentage: number;
  cropperZoomVal: number;
  setCropperZoomVal: (zoomVal: number) => void;
  error: string | void;
  fileName: string | null;
  fileSize: number | null;
  cropperProps?: Partial<CropperPropsType>;
};

export const UploadImageContainer = (props: ImageContainerProps) => {
  const {
    fileSrc,
    // setSrc,
    label,
    onFileCatch,
    onSave,
    onFileCrop,
    onCancel,
    cropperZoomVal,
    setCropperZoomVal,
    uploadingStatus,
    uploadingPercentage,
    error,
    fileName,
    fileSize,
    cropperProps = {},
  } = props;
  const src = fileSrc as string;

  const { t } = useTranslation(['common']);

  const cropperResetRef = useRef<(() => void) | null>(null);
  // TODO: useStateRef hook in foundation
  const srcRef = useRef<any | null>(src);

  // TODO: check as debounce re-creates every time
  const onCropDeb = _debounce((croppedCanvas, currentZoom) => {
    // TODO: test. check if it was a r!eset action
    // so there won't be any image src when debounce
    // will happens
    setCropperZoomVal(currentZoom);

    if (!srcRef.current) return;
    croppedCanvas.toBlob((blob: any) => {
      onFileCrop(blob);
    });
  }, 300);

  const reset = () => {
    const resetAction = cropperResetRef.current;
    if (resetAction) {
      resetAction();
    }
    if (onCancel) {
      onCancel();
    }
    srcRef.current = null;
  };

  const nonDropZoneContent =
    uploadingStatus === LoadingStatusEnum.initial ? (
      <div>
        <CropperFND
          src={src}
          width="100%"
          initialZoomVal={cropperZoomVal}
          onCrop={onCropDeb}
          getCropperRef={(data) => {
            const { resetAction } = data;

            if (resetAction) {
              cropperResetRef.current = resetAction;
            }
          }}
          renderRangeSlider={(rangeSlider) => {
            return (
              <div className="mb-[24px]">
                <p className="mb-[16px] mt-[24px] text-center text-body16SB">
                  {label}
                </p>
                {rangeSlider}
              </div>
            );
          }}
          {...cropperProps}
        />

        <div className="flex space-x-[16px]">
          <button
            className="hover-el themed-text radius-[2px] flex-1 border border-[element-normal] px-[24px] py-[9px] text-body16R"
            onClick={reset}
          >
            {t('cancel')}
          </button>
          <button
            className="hover-el ml-[16px] flex-1 bg-primary-100 px-[24px] py-[9px] text-body16R text-text-primary-dark"
            onClick={onSave}
          >
            {t('save')}
          </button>
        </div>
      </div>
    ) : uploadingStatus === LoadingStatusEnum.completed ? (
      <div className="flex items-center gap-3 rounded-md border border-element-subtle bg-background-primary p-2 pr-4 dark:border-element-normal-dark dark:bg-background-tetriary-dark">
        <img
          className="h-10 w-10 rounded object-cover"
          width={40}
          height={40}
          src={src}
          alt={'logo image preview'}
        />

        <span className="text-body16R text-text-primary dark:text-text-primary-dark">
          {label}
        </span>

        <button
          onClick={reset}
          className="ml-auto text-sm14T text-primary-100-dark"
          type="button"
        >
          {t('change')}
        </button>
      </div>
    ) : (
      <LoadedImagePreview
        error={error}
        fileName={fileName}
        fileSize={fileSize}
        onCloseClick={reset}
        uploading={uploadingStatus === LoadingStatusEnum.uploading}
        uploadingPercentage={uploadingPercentage}
      />
    );

  return (
    <div className="mt-[24px] w-full md:max-w-[338px]">
      {src ? (
        nonDropZoneContent
      ) : (
        <DropZone
          onFileCatch={(files: FileList) => {
            const file = files[0];
            const fileUrl = URL.createObjectURL(file);
            if (file) {
              srcRef.current = fileUrl;
            }

            onFileCatch(files, fileUrl);
          }}
        />
      )}
    </div>
  );
};
