import { Channel_Member_Type } from '@10x/foundation/types';
import { InfinityScroller } from '@foundationPathAlias/components';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { classNames } from '@foundationPathAlias/utilities';
import { MemberData } from '@mainApp/src/stores';
import { useTranslation } from 'react-i18next';
import { UserRow } from './UserRow';

type ActionTypes = 'add' | 'remove' | 'none';

type UsersListProps = {
  title?: string;
  titleCn?: string;
  list: MemberData[];
  actionType: ActionTypes;
  onAction?: (id: string, type: Channel_Member_Type) => void;
  onClickRow?: (row: MemberData) => void;
  onScrollEnd?: () => void;
  infinityScroll?: boolean;
  loading?: boolean;
  maxHeight?: number;
};

export const UsersList = ({
  list,
  onAction,
  title,
  titleCn,
  actionType,
  onClickRow,
  infinityScroll,
  onScrollEnd,
  loading,
  maxHeight = 180,
}: UsersListProps) => {
  const { t } = useTranslation(['common']);

  const ACTION_LABELS: Record<ActionTypes, string> = {
    add: t('common:add'),
    remove: t('common:remove'),
    none: '',
  };

  const content = (
    <div className="mt-2 flex flex-col ">
      {list.map((item) => {
        return (
          <UserRow
            key={item.id}
            data={item}
            onAction={() => onAction?.(item.id, item.memberType)}
            actionLabel={ACTION_LABELS[actionType]}
            onClick={
              onClickRow &&
              item.memberType === Channel_Member_Type.Role &&
              Boolean(item.totalMemberCount)
                ? () => onClickRow(item)
                : undefined
            }
          />
        );
      })}
    </div>
  );

  if (!list.length) {
    return null;
  }

  return (
    <div className="pb-4">
      {Boolean(title) && (
        <b className={classNames('themed-text text-body16SB', titleCn)}>
          {title}
        </b>
      )}

      {infinityScroll ? (
        <InfinityScroller
          maxHeight={maxHeight}
          threshold={0.1}
          onScrollEndChange={(isEnd) => {
            if (isEnd) {
              onScrollEnd?.();
            }
          }}
          onContentResize={(isScrollBarAppeared) => {
            if (isScrollBarAppeared) return;

            onScrollEnd?.();
          }}
        >
          {content}
        </InfinityScroller>
      ) : (
        <>{content}</>
      )}

      {loading && <SmallLoader />}
    </div>
  );
};
