import { useEffect } from 'react';
import { useImage } from './useImage';

import { animated, useSpring } from '@react-spring/web';

import { useMinLoaded } from '@foundationPathAlias/components/loaders';
// import { useMinLoaded } from '@mainApp/src/components/loaders/hooks';

type ImageWithPlaceholderPropsType = {
  placeholderSrc: string;
  src?: string;
  alt: string;
  rootCn?: string;
};

export function ImageWithPlaceholder(props: ImageWithPlaceholderPropsType) {
  const { placeholderSrc, src, alt, rootCn } = props;

  const { loaded: isOriginalImageLoaded } = useImage(src);
  const finalLoaded = useMinLoaded(isOriginalImageLoaded, 450);

  const [springStyle, api] = useSpring(
    () => ({
      opacity: 1,
    }),
    []
  );

  const triggerAppearAnim = () => {
    api.start({
      immediate: true,
      to: {
        opacity: 0,
      },
      onResolve() {
        api.start({
          to: {
            opacity: 1,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (finalLoaded) {
      triggerAppearAnim();
    }
  }, [finalLoaded]);

  return (
    <div className={rootCn}>
      <animated.img
        className="h-full w-auto"
        style={springStyle}
        src={finalLoaded ? src : placeholderSrc}
        alt={alt}
      />
    </div>
  );
}
