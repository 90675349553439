import { injectable } from 'inversify';
export type DataItem<T = any> = {
  data: T;
  id: string;
  next: string | null; // model ID or some pointer
  prev: string | null;
};

/**
 * cointans an object with the dbl liked items in it
 */

@injectable()
export class DoubleLinkedRegistryQueue<T = any> {
  registry: {
    [optimistic_entity_unique_id: string]: DataItem<T>;
  } = {};
  itemsCount = 0;

  activeItem: DataItem | null = null;
  lastItem: DataItem | null = null;

  get isEmpty() {
    return this.itemsCount === 0;
  }

  getById = (id: string) => {
    return this.registry[id]?.data;
  };

  getActiveItem = () => {
    return this.activeItem?.data;
  };

  getLastItem = () => {
    return this.lastItem?.data;
  };

  // removes an item from the registry and returns it)
  extractById = (id: string) => {
    this.throwErrorIfNotExists(id);
    const res = this.registry[id];
    this.removeById(id);
    return res;
  };

  // removes all items from the registry and returns them
  extractAll = () => {
    if (this.isEmpty) {
      return null;
    }
    const result = this.registry;
    this.registry = {};
    this.itemsCount = 0;
    return result;
  };

  add = (id: string, item: T) => {
    this.throwErrorIfExists(id);
    const dataItem = this.generateDataItem(id, item);
    if (this.isEmpty) {
      this.registry[id] = dataItem;
      // after initial adding the  this.next method should handle it
      this.activeItem = dataItem;
    } else {
      if (!this.activeItem) {
        throw new Error('No active item but the queue is not empty!');
      }

      this.activeItem.next = id;
      dataItem.prev = this.activeItem.id;

      this.registry[id] = dataItem;
    }

    this.lastItem = dataItem;
    this.itemsCount++;
  };

  removeById = (id: string) => {
    this.throwErrorIfNotExists(id);

    const item = this.registry[id];

    const prevId = item.prev;
    const nextId = item.next;

    // delete in between of the queue
    if (prevId && nextId) {
      const prevItem = this.registry[prevId];
      const nextItem = this.registry[nextId];

      prevItem.next = nextId;
      nextItem.prev = prevId;
    } else if (prevId) {
      // deleting qeue's ending item
      const prevItem = this.registry[prevId];
      prevItem.next = null;
    } else if (nextId) {
      // deleting queue's starting item
      const nextItem = this.registry[nextId];
      nextItem.prev = null;
    }

    delete this.registry[id];
    this.itemsCount--;
  };

  next = () => {
    let res = null;

    let nextItem = null;
    const prevItem = this.activeItem;

    if (!prevItem) {
      return res;
    }

    const nextItemId = prevItem?.next;

    if (nextItemId) {
      nextItem = this.registry[nextItemId];
      // prev item had been processed and will be deleted so remove link
      nextItem.prev = null;
      this.activeItem = nextItem;

      res = {
        handledItem: prevItem.data,
        nextItem: nextItem.data,
      };
    } else {
      // the last item. Can clear the last and acrive items
      this.lastItem = null;
      this.activeItem = null;

      res = {
        handledItem: prevItem.data,
        nextItem: null,
      };
    }

    this.removeById(prevItem.id);

    return res;
  };

  private generateDataItem = (id: string, item: T): DataItem => {
    return {
      data: item,
      id: id,
      next: null,
      prev: null,
    };
  };

  private throwErrorIfExists = (id: string) => {
    const item = this.registry[id];
    if (item) {
      throw new Error(`The queue already contains an item with this id: ${id}`);
    }
  };

  private throwErrorIfNotExists = (id: string) => {
    const item = this.registry[id];
    if (!item) {
      throw new Error(`In the queue is not registered this id: ${id}`);
    }
  };
}
