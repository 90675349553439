import {
  OutlineButton,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';

import { classNames } from '@foundationPathAlias/utilities';
import { ActionsPanelData } from '../data';

import { Separator } from './Separator';

export type Props = {
  actionsPanelData: void | null | ActionsPanelData;
  isLoading?: boolean;
  showActionPanel?: boolean;
  isMobile?: boolean;
  isError?: boolean;
  isDisableProceedAction?: boolean;
  // if you want to use i18n by yourself so you can pass this function
  getTranslatedString?: (key: string) => string;
  onCancel?: () => void;
  onProceed?: () => void;
  rootCn?: string;
  /** next div after the root. Positioning the items */
  wrapperCn?: string;
  separatorCn?: string;
  /** div which wraps buttons */
  buttonsBoxCn?: string;
  /** div which makes spaces for buttons */
  buttonsPositionerCn?: string;
  cancelBtnCn?: string;
  proceedBtnCn?: string;
};

export function ActionsPanel(props: Props) {
  const {
    actionsPanelData,
    isMobile,
    isLoading,
    isError,
    isDisableProceedAction,
    showActionPanel,
    getTranslatedString,
    onCancel,
    onProceed,
    rootCn,
    wrapperCn,
    separatorCn,
    buttonsBoxCn,
    buttonsPositionerCn,
    cancelBtnCn,
    proceedBtnCn,
  } = props;

  const t = getTranslatedString || ((key: string) => key);

  if (!actionsPanelData || !showActionPanel) return null;

  const disableProceedBtn = isLoading || isError || isDisableProceedAction;

  return (
    <div className={classNames('flex-end flex items-end', rootCn)}>
      <div className={classNames('flex flex-1 flex-col', wrapperCn)}>
        <Separator cn={classNames('my-0', separatorCn)} />

        <div className={classNames('flex flex-1 items-end', buttonsBoxCn)}>
          <div
            className={classNames(
              'mt-[24px] flex flex-1 justify-end space-x-[16px] px-[24px]',
              buttonsPositionerCn
            )}
          >
            {!isMobile && (
              <OutlineButton
                cn={classNames(
                  'min-h-[42px] w-auto rounded-[5px]',
                  cancelBtnCn
                )}
                onClick={() => {
                  actionsPanelData.cancelAction();
                  onCancel?.();
                }}
              >
                {t(actionsPanelData.getCancelActionText())}
              </OutlineButton>
            )}
            <PrimaryButtonWithLoader
              dynamicColorsMode={true}
              loading={Boolean(isLoading)}
              cn={classNames(
                'min-h-[42px] w-auto rounded-[5px] flex-1 md:flex-initial',
                false && 'opacity-50',
                proceedBtnCn
              )}
              onClick={() => {
                actionsPanelData.proceedAction();
                onProceed?.();
              }}
              disabled={disableProceedBtn}
            >
              {t(actionsPanelData.getProceedActionText())}
            </PrimaryButtonWithLoader>
          </div>
        </div>
      </div>
    </div>
  );
}
