import { gql } from '@urql/core';

export const REMOVE_CHANNEL_MESSAGE_ATTACHMENT = gql`
  mutation ChannelMessageRemoveAttachment(
    $attachmentId: String!
    $communityId: String!
    $messageId: String!
  ) {
    channelMessageRemoveAttachment(
      attachmentId: $attachmentId
      communityId: $communityId
      messageId: $messageId
    ) {
      id
    }
  }
`;

export const CREATE_MESSAGE_ATTACHMENT_URL = gql`
  mutation ChannelMessageCreateAttachmentPresignedUrl(
    $communityId: String!
    $id: String!
  ) {
    channelMessageCreateAttachmentPresignedUrl(
      communityId: $communityId
      id: $id
    ) {
      url
      fields {
        key
        value
      }
    }
  }
`;
export const CREATE_BATCH_MESSAGE_ATTACHMENT_URL = gql`
  mutation ChannelMessageCreateBatchAttachmentPresignedUrl(
    $communityId: String!
    $size: Int!
  ) {
    channelMessageCreateBatchAttachmentPresignedUrl(
      communityId: $communityId
      size: $size
    ) {
      id
      url
      fields {
        key
        value
      }
    }
  }
`;
