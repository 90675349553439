export enum StorageDataKeysEnum {
    // Unified redirection data object
    REDIRECTION_DATA = 'REDIRECTION_DATA',
    // Auth Bearer token
    ACCESS_TOKEN = 'accessToken',
    // Project theme
    THEME = 'THEME',
    // user lang
    USER_LOCALE = 'USER_LOCALE',
    PRO_SUBSCRIPTION_PAYMENT = 'PRO_SUBSCRIPTION_PAYMENT',
    // the original url where the auth redirection was triggered
    AUTH_CALLER_URL = 'AUTH_CALLER_URL',
    POST_AUTH_ONBOARDING = 'POST_AUTH_ONBOARDING',
}

// Ids to determinate what app domain was trigger the redirection
export enum RedirectionIdsEnum {
    POST_AUTH_ONBOARDING = 'POST_AUTH_ONBOARDING',
    JOIN_COMMUNITY = 'JOIN_COMMUNITY',
    AUTH_REQUIRED = 'AUTH_REQUIRED',
    PRO_SUBSCRIPTION_PAYMENT = 'PRO_SUBSCRIPTION_PAYMENT',
    ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS'
}

/** contains possible queries if the redirection URL should contain some data.
 * Usually it's required affeter the redirect page handling and going to the destination page in the app
 * 
 * the scheduled actions ID's that should be executed once redirect handler will catch the query
 */
export enum RedirectionURLQueriesEnum {
    SCHEDULED_ACTIONS = 'scheduled_actions',
    PAYMENT_REDIRECT = 'payment_redirect',
    RESULT = 'result',
    PRO_USERNAME = 'proUsername',
    ERROR = 'error'
}

/**
 * the actions that should be done once redirect handler will catch the query
 */
export enum RedirectionURLActionsEnum {
    // join to the community. Happens after the auth from the community page
    JOIN = 'join',
    SUCCESS_ACCOUNT_DEACTIVATION = 'success-account-deactivation',
    SUCCESS_ACCOUNT_DELETION = 'success-acount-deletion',
}

export type RedirectDTO = {
    id: RedirectionIdsEnum;
    data: { [index: string]: any };
    url: string;
}

export type RedirectOptions = {
    // open the redirected url in the same window. By default it should be true
    self?: boolean;
    // the timeout will be used before open the new url. Useful for prevent browsers blocking for programmatically opening new pages. By default it should be true
    withTimeout?: boolean;
    // 250ms by default
    timeoutTime?: number;
}