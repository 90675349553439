import { ActionRoundedButton } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { IUsersStoreLocal } from '@mainApp/src/modules/customer-admin-panel/screens/users/data/Users.store.local';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TabsContent } from './tooltip-contents';

const _UsersFilterActionButton = () => {
  const { t } = useTranslation(['cap']);
  const { capStore } = useMultipleInjection([IOC_TOKENS.capStore]);

  const usersStoreLocal = capStore.activeChildScreen as IUsersStoreLocal;

  const { activeTabIndex, setActiveTabIndex, activeTabMembersCount } =
    usersStoreLocal || {};

  const tabs = [t('cap:users.tabs.members'), t('cap:users.tabs.banned')];

  const handleSelectOption = (optionIndex: number) => {
    setActiveTabIndex(optionIndex);
    capStore.setBottomSheetConfig({
      content: null,
      show: false,
    });
  };

  const onClick = () => {
    capStore.setBottomSheetConfig({
      content: <TabsContent handleSelectOption={handleSelectOption} />,
      show: true,
    });
  };

  return (
    <ActionRoundedButton cn="text-sm14SB" active={false} onClick={onClick}>
      {tabs[activeTabIndex]}
      <span className="themed-layout ml-[8px] rounded-full px-[6px] text-sm14T">
        {activeTabMembersCount}
      </span>
    </ActionRoundedButton>
  );
};

export const UsersFilterActionButton = observer(_UsersFilterActionButton);
