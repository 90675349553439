import { PopoverContext } from './PopoverCtx';
import { usePopover } from './usePopover';

export type Placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

// TODO: types
export function Popover({ children, modal = false, ...restOptions }: any) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions });
  return (
    <PopoverContext.Provider value={popover as any}>
      {children}
    </PopoverContext.Provider>
  );
}
