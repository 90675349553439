
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export type ProgressBarCircularPropTypes = {
  /**
   * A progress percentage: 0 - 100 range.
  */
  progressPercentage: number;
  pathStroke: string;
  trailStroke: string,
  cn: string;
};

export function ProgressBarCircular(props: ProgressBarCircularPropTypes) {
  const {
    progressPercentage,
    cn,
    pathStroke,
    trailStroke,
  } = props;

  return (
    <div className={cn}>
      <CircularProgressbar value={progressPercentage}
        strokeWidth={10}
        styles={{
          root: {
            height: '100%',
          },
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: pathStroke,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
            // Rotate the path
            // transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: trailStroke,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Rotate the trail
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
        }}
      />
    </div>
  );
}
