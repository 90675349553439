import { classNames } from '@foundationPathAlias/utilities';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

export type InfoLinePropsType = {
  cn?: string;
  iconCn?: string;
  iconWrapperCn?: string;
  customIcon?: JSX.Element;
  children: React.ReactNode;
};
export function InfoLine(props: InfoLinePropsType) {
  const { children, cn, iconCn, iconWrapperCn, customIcon } = props;
  return (
    <div
      className={classNames(
        'themed-text-secondary flex w-full items-center rounded-[8px] bg-background-secondary px-[12px] py-[8px] text-sm14T dark:bg-background-quaternary-dark',
        cn
      )}
    >
      <p className={classNames(' mr-[4px] flex items-center', iconWrapperCn)}>
        {customIcon ? (
          customIcon
        ) : (
          <InformationCircleIcon
            className={classNames('h-[16px] w-[16px]', iconCn)}
          />
        )}
      </p>
      {children}
    </div>
  );
}
