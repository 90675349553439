import { observer } from 'mobx-react-lite';
import { AddedItemsType } from '../Pricing';
import { PlanCard } from './PlanCard';

export function _PricingSection1({
  setAddedItems,
  addedItems,
}: {
  setAddedItems: (items: AddedItemsType) => void;
  addedItems: AddedItemsType;
}) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-[48px] flex flex-col items-center justify-center text-center md:mt-[130px]">
        {/* Flex for larger screens, stacked for mobile */}
        <div className="flex flex-col items-center justify-center md:flex-row">
          {/* "Simple &" on top in mobile view */}
          <span className="grad-t-build text-subHeadingB md:text-h1">
            Simple &&nbsp;
          </span>
          {/* "Flexible pricing" on the same line in mobile, inline for larger screens */}
          <div className="flex text-subHeadingB md:text-h1">
            <span className="grad-t-build">flexible&nbsp;</span>
            <span>pricing</span>
          </div>
        </div>
      </div>

      {/* Subtitle */}
      <span className="themed-text mt-[24px] text-center text-body18R md:mt-[32px] md:text-subheading">
        Select the best plan to create your tailored community
      </span>

      {/* Plan Cards Section with custom gap */}
      <div className="scrollbar-hide mt-[72px] flex w-full justify-center overflow-x-auto px-[20px] md:mt-[96px] md:flex-row">
        <div className="flex w-full flex-row gap-4 md:w-auto md:gap-8">
          {/* Highlighted card - Comes first on mobile, second on larger screens */}
          <div className="order-1 min-w-[306px] md:order-2 md:min-w-[427px]">
            <PlanCard
              highlight={true}
              addedItems={addedItems}
              setAddedItems={setAddedItems}
            />
          </div>
          {/* Non-highlighted card - Comes second on mobile, first on larger screens */}
          <div className="order-2 min-w-[306px] md:order-1 md:min-w-[427px]">
            <PlanCard addedItems={addedItems} setAddedItems={setAddedItems} />
          </div>
        </div>
      </div>
    </div>
  );
}

export const PricingSection1 = observer(_PricingSection1);
