import { ChannelPayload } from '@10x/foundation/types';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useCallback, useState } from 'react';

export function useDeleteChannel(channelServerData: ChannelPayload) {

    const [isLoading, setIsLoading] = useState(false);

    const channelStore = useInjection(IOC_TOKENS.channelStore);

    const activeChannelId = channelServerData?.id;
    if (!activeChannelId) {
        throw new Error('activeChannelId is not defined');
    }



    const deleteChannel = useCallback(async () => {
        setIsLoading(true);

        const communityId = channelServerData.communityId;
        const channelGroupId = channelServerData.parentId;
        const id = channelServerData.id;

        const { error } = await channelStore.removeChannel(communityId, channelGroupId as string, id);
        setIsLoading(false);

        return Boolean(error);
    }, [
        channelServerData,
        activeChannelId
    ]);

    return { deleteChannel, isLoading };
}