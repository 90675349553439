import { inject, injectable } from 'inversify';
import {
  CREATE_BATCH_MESSAGE_ATTACHMENT_URL,
  CREATE_MESSAGE_ATTACHMENT_URL,
} from '../graphql/queries';

import { IOC_TOKENS } from '@mainApp/src/ioc';
import { IAttachmentsRepository } from './Attachments.repository.types';
import { Client } from './types';

export * from './Attachments.repository.types';

@injectable()
export class AttachmentsRepository implements IAttachmentsRepository {
  gqlClient;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
  }

  async getBatchUrls(communityId: string, size: number) {
    const response = await this.gqlClient
      .mutation(CREATE_BATCH_MESSAGE_ATTACHMENT_URL, { communityId, size })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.channelMessageCreateBatchAttachmentPresignedUrl,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
  async createMessageAttachmentUrl(communityId: string, id: string) {
    const response = await this.gqlClient
      .mutation(CREATE_MESSAGE_ATTACHMENT_URL, { communityId, id })
      .toPromise();

    const { data, error } = response;

    const res = {
      data: data?.channelMessageCreateAttachmentPresignedUrl,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  }
}
