type AuthBtnProps = {
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
};

export function AuthBtn(props: AuthBtnProps) {
  const { onClick, icon, label } = props;

  return (
    <button
      className=" mb-[16px] flex w-full items-center rounded-[2px] border border-element-normal bg-background-primary  py-[10px]  pl-[24px] dark:border-element-subtle dark:bg-element-subtle-dark xs:pl-[62px]"
      onClick={onClick}
    >
      <span className="mr-[17px]">{icon}</span>

      <p className="text-body16SB text-text-primary dark:text-text-primary-dark">
        {label}
      </p>
    </button>
  );
}
