import { BackButton, CloseButton } from '@foundationPathAlias/components';

type Props = {
  children: any;
  isShowBackBtn: boolean;
  onBackBtnPress: () => void;
  onCloseBtnPress: () => void;
};

export function Header(props: Props) {
  const { children, isShowBackBtn, onBackBtnPress, onCloseBtnPress } = props;
  return (
    <h5 className="themed-text relative flex items-center border-b-[1px] border-b-element-subtle px-[16px] pb-[10px] text-center text-miniHeadD font-bold md:px-[24px] md:pb-[20px] md:text-left">
      {isShowBackBtn && <BackButton onClick={onBackBtnPress} />}
      <span className="themed-text flex-1 pl-[12px] pr-[38px] text-center md:pr-[0] md:text-left">
        {children}
      </span>
      <CloseButton onClick={onCloseBtnPress} />
    </h5>
  );
}
