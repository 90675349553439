export interface TooltipHoverContentProps {
  label: string;
}

export const TooltipHoverContentStyles = {
  tooltip: {
    border: 'none',
    boxShadow: 'none',
    width: 'auto',
    height: 24,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

const TooltipHoverContent = (props: TooltipHoverContentProps) => {
  const { label } = props;
  return (
    <div className="px-[8px] text-sm13D text-text-primary-dark dark:text-text-primary">
      {label}
    </div>
  );
};

export { TooltipHoverContent };
