export type Props = {
  avatarSrc?: string;
  username: string;
  displayName: string;
  numericId: string;
  onClick: () => void;
};
export function UserSuggestionItem(props: Props) {
  const {
    avatarSrc,
    displayName = '',
    username = '',
    numericId,
    onClick,
  } = props;

  return (
    <li className="suggestion-option h-[36px] pt-[2px]">
      <button className="flex w-full justify-between" onClick={onClick}>
        <figure className="flex">
          <img
            className="mr-[12px] h-[24px] w-[24px] overflow-hidden rounded-[5px] object-cover"
            src={avatarSrc}
            alt={`${username} avatar`}
          />
          <figcaption className="text-sm14T text-text-primary dark:text-text-primary-dark">
            {displayName}
          </figcaption>
        </figure>
        <p>
          <span className="mr-[4px] inline-block text-sm14T text-text-primary dark:text-text-primary-dark">
            {username}
          </span>
          <span className="text-sm13D text-text-secondary opacity-70 dark:text-text-secondary-dark">
            #{numericId}
          </span>
        </p>
      </button>
    </li>
  );
}
