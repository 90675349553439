import {
    action,
    makeObservable, observable
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

import { IAnimatedStackStore, StackItem } from './AnimatedStack.store.types';

const initialItemState: StackItem = {
    Component: null,
    id: null,
};

export class AnimatedStackStore implements IAnimatedStackStore {
    prevItem = { ...initialItemState };
    visibleItem = { ...initialItemState }
    nextItem = { ...initialItemState };
    startEntering = false;
    startLeaving = false;
    history: StackItem[] = [];

    get isAnimationRunning() {
        return this.startEntering || this.startLeaving;
    }

    constructor() {
        makeObservable(this, {
            prevItem: observable,
            nextItem: observable,
            startEntering: observable,
            startLeaving: observable,
            onLeavingAnimationEnd: action,
            onEnterAnimationEnd: action,
            setVisible: action,
            back: action,
            next: action,
            reset: action,
        });

    }

    reset = () => {
        this.history = [];
        this.prevItem = { ...initialItemState };
        this.visibleItem = { ...initialItemState }
        this.nextItem = { ...initialItemState };
    }

    setPrevItem = (id: string, Component: React.ComponentType<any>) => {
        this.prevItem = { ...initialItemState, id, Component }
    }


    setVisible = (id: string, Component: React.ComponentType<any>) => {
        this.visibleItem = {
            ...this.visibleItem,
            id,
            Component,
        }

        this.startEntering = false;
        this.startLeaving = false;
    }

    next = (id: string, Component: React.ComponentType<any>) => {
        // checking if there already had been added this screen. Just in case

        if (this.history.some(o => o.id === id)) return;

        this.nextItem = {
            ...this.nextItem,
            id,
            Component,
        }
        this.startEntering = true;

        // there will be a next prev item so should add the current prev item to history to be able to move back step by step
        this.history.push({ ...this.visibleItem });


    }
    back = () => {
        // shouldn't do anything if it's still moving back
        if (this.startLeaving) return;

        const prevItem = this.history.pop() || this.prevItem;
        const prevId = prevItem?.id;

        if (!prevId) return;

        this.startLeaving = true;
        return prevId;
    }


    onLeavingAnimationEnd = () => {
        this.visibleItem = { ...this.prevItem }
        this.nextItem = { ...initialItemState }

        // put the prev item from history if exists or use the initial state
        const prevItem = this.history[this.history.length - 1] || initialItemState;

        this.prevItem = { ...prevItem }
        this.startLeaving = false;
    }
    onEnterAnimationEnd = () => {
        this.prevItem = {
            ...this.visibleItem
        }

        this.visibleItem = {
            ...this.nextItem
        }

        this.nextItem = { ...initialItemState }
        this.startEntering = false;
    }


}
