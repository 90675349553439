import { classNames } from '@foundationPathAlias/utilities';
export type SquareIconButtonBasePropsType = {
  className?: string;
  iconWrapperCn?: string;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
};

export function SquareIconButtonBase(props: SquareIconButtonBasePropsType) {
  const { children, onClick, className = '', iconWrapperCn, disabled } = props;
  return (
    <button
      className={classNames(
        'square-icon-button-base',
        disabled ? 'opacity-40' : 'hover-el',
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <figure
        className={classNames(
          'flex h-[24px] w-[24px] items-center justify-center',
          iconWrapperCn
        )}
      >
        {children}
      </figure>
    </button>
  );
}
