import { gql } from '@urql/core';

import {
  ChannelMessageConnectionPayloadFragment,
  ChannelMessageEdgePayloadFragment,
  MessageFragment,
  PageInfoFragment,
} from './fragments';

export const GET_CHANNEL_MESSAGES_SUBSCRIPTION = gql`
  subscription GetChannelMessagesSubscription(
    $communityId: String!
    $channelId: String!
    $actionType: MESSAGE_ACTION_TYPES
  ) {
    channelMessages(
      communityId: $communityId
      channelId: $channelId
      actionType: $actionType
    ) {
      action
      communityId
      payload {
        ...ChannelMessageEdgePayloadFragment
      }
    }
  }
  ${ChannelMessageEdgePayloadFragment}
`;

export const GET_CHANNEL_MESSAGES = gql`
  query GetChannelMessages(
    $communityId: String!
    $channelId: String!
    $threadId: String
    $before: String
    $first: Int
    $last: Int
    $after: String
    $sort: String
    $search: String
    $jumpTo: String
  ) {
    channelMessages(
      communityId: $communityId
      channelId: $channelId
      threadId: $threadId
      before: $before
      first: $first
      last: $last
      after: $after
      search: $search
      sort: $sort
      jumpTo: $jumpTo
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      lastReadAt
      newMessagesCount
      latestCursor
      totalCount
      edges {
        cursor
        node {
          ...MessageFragment
        }
      }
    }
  }
  ${MessageFragment}
  ${PageInfoFragment}
`;

// currently it isn't using. BE has jumpTo for the parentReply. Leave it as an example of combining 2 gql queries into 1 request
export const GET_REPLY_DATA = gql`
  query jump($communityId: String!, $channelId: String!, $cursor: String) {
    beforeReply: channelMessages(
      communityId: $communityId
      channelId: $channelId
      # after: $cursor,
      before: $cursor
      last: 5 # first:10
    ) {
      ...ChannelMessageConnectionPayloadFragment
    }
    afterReply: channelMessages(
      communityId: $communityId
      channelId: $channelId
      after: $cursor
      # before:$cursor,
      last: 5 # first:10
    ) {
      ...ChannelMessageConnectionPayloadFragment
    }
  }
  ${ChannelMessageConnectionPayloadFragment}
`;

export const PRE_CREATE_CHANNEL_MESSAGE = gql`
  mutation PreCreateChannelMessage($communityId: String!, $channelId: String!) {
    preCreateChannelMessage(communityId: $communityId, channelId: $channelId) {
      id
    }
  }
`;

export const CREATE_CHANNEL_MESSAGE = gql`
  mutation CreateChannelMessage(
    $communityId: String!
    $channelId: String!
    $data: ChannelMessageCreateInput!
  ) {
    createChannelMessage(
      communityId: $communityId
      channelId: $channelId
      data: $data
    ) {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;

export const UPDATE_CHANNEL_MESSAGE = gql`
  mutation UpdateChannelMessage(
    $communityId: String!
    $channelId: String!
    $id: String!
    $data: ChannelMessageUpdateInput!
  ) {
    updateChannelMessage(
      communityId: $communityId
      channelId: $channelId
      id: $id
      data: $data
    ) {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;

export const DELETE_CHANNEL_MESSAGE = gql`
  mutation DeleteChannelMessage(
    $communityId: String!
    $channelId: String!
    $id: String!
  ) {
    deleteChannelMessage(
      communityId: $communityId
      channelId: $channelId
      id: $id
    ) {
      id
    }
  }
`;

export const SAVE_CHANNEL_MESSAGE_REACTION = gql`
  mutation SaveChannelMessageReaction(
    $communityId: String!
    $channelId: String!
    $id: String!
    $data: ContentReactionInput!
  ) {
    saveChannelMessageReaction(
      communityId: $communityId
      channelId: $channelId
      id: $id
      data: $data
    )
  }
`;

export const CHANNEL_MESSAGE_MARK_ALL_AS_READ = gql`
  mutation ChannelMessageMarkAllAsRead(
    $communityId: String!
    $channelId: String!
    $option: ReadContentMarkReadInput!
  ) {
    channelMessageMarkAllAsRead(
      communityId: $communityId
      channelId: $channelId
      option: $option
    )
  }
`;

export const CHANNEL_MESSAGE_MARK_ALL_AS_UNREAD = gql`
  mutation ChannelMessageMarkAsUnread(
    $communityId: String!
    $channelId: String!
    $data: ReadContentMarkUnreadInput!
  ) {
    channelMessageMarkAsUnread(
      communityId: $communityId
      channelId: $channelId
      data: $data
    )
  }
`;
