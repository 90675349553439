import { Avatar, IconButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { CameraIcon } from '@heroicons/react/24/outline';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserCropImage } from '.';
import { ActionButtons } from './ActionButtons';
import { EditUserFormFields } from './EditUserFormFields';
import { HeaderModal } from './HeaderModal';

const _EditUserContent = () => {
  const {
    interactiveStore,
    systemStore,
    dimensionsStore: { isMobile },
    userStore,
  } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.userStore,
  ]);

  const { t } = useTranslation(['channel'], { keyPrefix: 'editProfileModal' });

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const handleClose = () => {
    userStore.me?.reset();
    interactiveStore.resetContent();
    interactiveStore.setModal(false);

    if (isMobile) {
      interactiveStore.setBottomSheet(false);
    }
  };

  const handleSubmit = async () => {
    await userStore.updateCurrentUser();
    handleClose();
  };

  if (!userStore.me) {
    return null;
  }

  const { username } = userStore.me.serverData;

  return (
    <div
      className={classNames(
        'bg-background-primary dark:bg-background-primary-dark',
        isMobile ? 'h-screen w-full' : 'w-[480px]'
      )}
    >
      <input
        type="file"
        accept="image/*"
        ref={inputFileRef}
        hidden
        onChange={(e) => {
          const file = e?.target?.files && e?.target?.files[0];

          if (file) {
            interactiveStore.setContent(
              <UserCropImage
                file={file}
                onCropDone={() => {
                  interactiveStore.setContent(
                    <EditUserContent key="EditUserContent" />
                  );
                }}
              />
            );
          }
        }}
      />
      <HeaderModal label={t('editProfile')} onCancel={handleClose} />

      <div className="flex flex-col gap-8 px-4 py-6 lg:gap-6 lg:px-6">
        <div className="flex flex-col self-center">
          <div className="relative flex flex-col items-center justify-center ">
            <Avatar
              darkMode={systemStore.isDarkTheme}
              src={userStore.me?.avatarUrl}
              height={isMobile ? 120 : 160}
              width={isMobile ? 120 : 160}
              alt={'Avatar image'}
            />

            <IconButton
              cn="absolute p-[10px] self-center w-fit border-0 bg-background-overlay"
              onClick={() => {
                inputFileRef.current?.click?.();
              }}
            >
              <CameraIcon
                width={24}
                height={24}
                className="text-text-primary-dark"
              />
            </IconButton>
          </div>

          <b className="mb-1 mt-4 text-center text-subheadingSB text-text-primary dark:text-text-primary-dark">
            @{username}
          </b>

          <button className="text-center text-body16SB text-primary-100 dark:text-primary-100-dark">
            {t('changeUsername')}
          </button>
        </div>

        <EditUserFormFields />
      </div>

      {!isMobile && (
        <ActionButtons
          onClose={handleClose}
          onSubmit={handleSubmit}
          isSubmitting={userStore.isSubmitting}
          submitLabel={t('saveChanges')}
        />
      )}
    </div>
  );
};

export const EditUserContent = observer(_EditUserContent);
