export const MoonSVG: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 18, height = 17, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="themed-svg"
    {...otherProps}
  >
    <path
      d="M16.6218 9.60563C16.4582 9.56459 16.2946 9.60563 16.1515 9.70822C15.6199 10.1596 15.0065 10.529 14.3317 10.7752C13.6978 11.0214 13.0026 11.1446 12.2665 11.1446C10.6103 11.1446 9.09716 10.4674 8.01345 9.37992C6.92974 8.29241 6.25498 6.77401 6.25498 5.11197C6.25498 4.41433 6.37766 3.7372 6.58213 3.12163C6.80705 2.46503 7.13421 1.86998 7.56361 1.357C7.74763 1.13129 7.70674 0.802991 7.48182 0.618321C7.33869 0.515726 7.17511 0.474688 7.01153 0.515726C5.2735 0.987662 3.7604 2.03413 2.67668 3.42942C1.63387 4.80419 1 6.50726 1 8.37449C1 10.6111 1.89968 12.6424 3.3719 14.1198C4.84411 15.5972 6.8684 16.5 9.09716 16.5C10.9783 16.5 12.7163 15.8434 14.1068 14.7559C15.5176 13.6479 16.54 12.0679 16.9694 10.2622C17.0716 9.95445 16.9081 9.66718 16.6218 9.60563ZM13.4933 13.8941C12.3074 14.838 10.7943 15.4125 9.13806 15.4125C7.19555 15.4125 5.43708 14.6123 4.16934 13.3401C2.90161 12.0679 2.10416 10.3033 2.10416 8.35397C2.10416 6.73297 2.63579 5.25561 3.55592 4.06551C4.18979 3.24475 4.98724 2.56762 5.90737 2.09569C5.80513 2.32139 5.7029 2.5471 5.62111 2.79333C5.35529 3.53201 5.23261 4.31173 5.23261 5.13249C5.23261 7.08179 6.03006 8.86695 7.29779 10.1391C8.56553 11.4113 10.3445 12.2115 12.287 12.2115C13.1457 12.2115 13.9636 12.0679 14.7202 11.7806C14.986 11.678 15.2518 11.5754 15.4972 11.4523C15.0065 12.3962 14.3317 13.2375 13.4933 13.8941Z"
      strokeWidth="0.5"
    />
  </svg>
);
