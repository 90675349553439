import { classNames } from '../../../utilities';

export type ProgressBarPropsType = {
  progressPercent: number;
  rootCn?: string;
  progressBarCn?: string;
  thumbCn?: string;
  textCn?: string;
};

export function ProgressBar(props: ProgressBarPropsType) {
  const { progressPercent, rootCn, progressBarCn, thumbCn, textCn } = props;

  return (
    <div className={classNames('flex items-center space-x-[8px]', rootCn)}>
      <div
        className={classNames(
          'h-[9px] w-full rounded-[5px] bg-element-subtle dark:bg-element-subtle-dark',
          progressBarCn
        )}
      >
        <div
          className={classNames(
            'animation-classic h-2.5 rounded-[5px] bg-primary-100',
            thumbCn
          )}
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
      <span
        className={classNames(
          'min-w-[25px] text-exstraSm12R text-text-placeholder dark:text-text-placeholder-dark',
          textCn
        )}
      >
        {`${progressPercent}%`}
      </span>
    </div>
  );
}
