// @ts-ignore:next-line
import { classNames } from '@foundationPathAlias/utilities';
import { Dialog, Transition } from '@headlessui/react';

import { Fragment } from 'react';
import { useKeyPress } from 'react-use';
export type ModalPropsType = {
  /** a speed of the programatically closed bottomsheed. Default 9  */
  /** for the bottomSheet itself */
  rootCn?: string;
  /** dialog wrapper. rootCn is a transition div class names */
  rootInnerWrapperCn?: string;
  overlayCn?: string;
  contentWrapperCn?: string;
  contentCn?: string;
  overlayStyle?: object;
  /** for the bottomSheet itself */
  style?: object;
  /** a content of te bottomsheet */
  children: JSX.Element | JSX.Element[];

  open: boolean;
  // ESC and overlay click
  onClose?: () => void;
  onCloseEnd?: () => void;
};

export function Modal({
  children,
  rootCn = '',
  rootInnerWrapperCn = '',
  overlayCn = '',
  contentWrapperCn = '',
  contentCn = '',
  open,
  onCloseEnd = () => undefined,
  onClose,
}: // height,
ModalPropsType) {
  const [isEscPressed] = useKeyPress('Escape');

  if (isEscPressed && open) {
    onClose?.();
  }

  return (
    <Transition.Root show={open} as={Fragment as any}>
      <Dialog
        as="div"
        className={classNames('relative z-10 ', rootCn)}
        onClose={onCloseEnd}
      >
        <div
          className={classNames(
            'fixed inset-0 flex justify-center overflow-y-auto ',
            rootInnerWrapperCn
          )}
        >
          <Transition.Child
            as={Fragment as any}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            // Cole mentioned in dev-frontend channel, to hide leave animations for a modal component.
            // leave="ease-in duration-200"
            // leaveFrom="opacity-100"
            // leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ',
                overlayCn
              )}
              onClick={onClose}
            />
          </Transition.Child>
          <div
            className={classNames(
              'z-10 flex max-w-[100%] items-end justify-center p-4 text-center sm:items-center sm:p-0 ',
              contentWrapperCn
            )}
          >
            <Transition.Child
              className={contentCn}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              // Cole mentioned in dev-frontend channel, to hide leave animations for a modal component.
              // leave="ease-in duration-200"
              // leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              // leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
