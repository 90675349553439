import { useAuthUI } from '@mainApp/src/components/auth/useAuthUI';
import { Navbar } from '@mainApp/src/components/navbar/Navbar';
import { usePostAuthOnboardingUI } from '@mainApp/src/components/post-auth-onboarding/usePostAuthOnboardingUI';
import dynamic from 'next/dynamic';
import Head from 'next/head';

const Footer = dynamic(
  () =>
    import('@mainApp/src/components/footer/Footer').then(
      (module) => module.Footer
    ),
  { ssr: false }
);

export interface IPrimaryLayout {
  children: React.ReactNode;
  headerCn?: string;
  footerCn?: string;
}

const PrimaryLayout: React.FC<IPrimaryLayout> = ({
  children,
  headerCn,
  footerCn,
}) => {
  useAuthUI();
  usePostAuthOnboardingUI();

  return (
    <>
      <Head>
        <title>10X | Build community on your website</title>
        <meta
          name="description"
          content="Your website visitors share interests: bring them together in your own community. Let’s build a better version of social together."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Navbar cn={headerCn} />
      <main className="flex-1">{children}</main>
      <Footer cn={footerCn} />
    </>
  );
};

export default PrimaryLayout;

export const getPrimaryLayout = (
  page: any,
  {
    headerCn,
    footerCn,
  }: { headerCn?: string; footerCn?: string } | undefined = {}
) => {
  return (
    <PrimaryLayout headerCn={headerCn} footerCn={footerCn}>
      {page}
    </PrimaryLayout>
  );
};
