import { UserOnboardingPayload } from '@10x/foundation/types';
import { getCloudflareSizeRecognition } from '@foundationPathAlias/utilities/getCloudflareSizeRecognition';
import { IOC_TOKENS } from '@mainApp/src/ioc';
import type {
  IOnboardingRepositoryInterface,
  IUserRepository,
} from '@mainApp/src/repositories';
import type { IToastStore, IUserStore } from '@mainApp/src/stores';
import { ApiBase } from '@mainApp/src/stores/ApiBase';
import { refineURL } from '@mainApp/src/utils';
import { inject, injectable } from 'inversify';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';

import type { IRedirectService, IStorageService } from '@mainApp/src/services';
import { RedirectionIdsEnum, StorageDataKeysEnum } from '@mainApp/src/services';
import { UserModel } from '@mainApp/src/stores/User.model';
import { SubscriptionTypeEnum } from '@mainApp/src/stores/User.store.types';
import {
  IPostAuthOnboardingStore,
  SetStepDataPyaload,
  StepNamesEnum,
} from './PostAuthOnboarding.store.types';
const initialAvatarStepData: {
  showCropper: boolean;
  preview: null | string;
  file: File | null;
  croppedPreview: null | string;
} = {
  showCropper: false,
  preview: null,
  file: null,
  croppedPreview: null,
};

@injectable()
export class PostAuthOnboardingStore
  extends ApiBase
  implements IPostAuthOnboardingStore
{
  userRepository: IUserRepository;
  userStore: IUserStore;
  onboardingRepository: IOnboardingRepositoryInterface;
  storageService: IStorageService;
  redirectService: IRedirectService;

  userPostOnboardingData: UserOnboardingPayload | null = null;

  proMode = false;
  completed = false;

  // just a marker to prevent multiple getUserOnboardingData calls
  private isGotUserOnboardingDataAlready = false;

  get stepsDataJSON() {
    return {
      [StepNamesEnum.USERNAME]: this.steps[StepNamesEnum.USERNAME].data,
      [StepNamesEnum.DISPLAY_NAME]: this.steps[StepNamesEnum.DISPLAY_NAME].data,
      // just ignore the avatar step as there is no normal way to save picked File into the localStorage
      [StepNamesEnum.AVATAR]: {
        ...initialAvatarStepData,
        croppedPreview: this.steps[StepNamesEnum.AVATAR].data.croppedPreview,
        preview: this.steps[StepNamesEnum.AVATAR].data.preview,
      },
      [StepNamesEnum.SUBSCRIPTION]: this.steps[StepNamesEnum.SUBSCRIPTION].data,
    };
  }

  steps = {
    [StepNamesEnum.USERNAME]: {
      data: {
        value: '',
        lastStandardValue: '',
      },
      loading: false,
      backendStepNumber: 0,
      error: null,
      getIsvalid() {
        return !this.error && !this.loading && Boolean(this.data.value);
      },
      successMessage: 'success.availableUsername',
      next: async () => {
        return await this.updateUsername();
      },
      getPrevStepName: () => null,
      getNextStepName: () => StepNamesEnum.DISPLAY_NAME,
    },
    [StepNamesEnum.DISPLAY_NAME]: {
      data: {
        value: '',
      },
      loading: false,
      backendStepNumber: 1,
      error: null,
      getIsvalid() {
        return !this.error && !this.loading && Boolean(this.data.value);
      },
      successMessage: 'success.availableDisplayName',
      next: async () => {
        return await this.updateDisplayName();
      },
      getPrevStepName: () => StepNamesEnum.USERNAME,
      getNextStepName: () => StepNamesEnum.AVATAR,
    },
    [StepNamesEnum.AVATAR]: {
      data: initialAvatarStepData,
      loading: false,
      backendStepNumber: 2,
      error: null,
      getIsvalid() {
        return !this.error && !this.loading && Boolean(this.data.preview);
      },
      successMessage: '',
      next: async () => {
        const {
          data: { file, preview },
          backendStepNumber,
        } = this.steps[StepNamesEnum.AVATAR];
        const isAvatarStepWasCompleted = Boolean(
          this.userPostOnboardingData?.steps[backendStepNumber].status
        );
        const hasBeenRestoredFromStorage =
          file === null && preview && isAvatarStepWasCompleted;
        // if it was  restored from the storage no need to upload the avatar again so just return no error and continue
        if (hasBeenRestoredFromStorage) {
          this.saveDataInStorage();
          return false;
        }

        runInAction(() => {
          this.steps = {
            ...this.steps,
            [StepNamesEnum.AVATAR]: {
              ...this.steps[StepNamesEnum.AVATAR],
              loading: true,
            },
          };
        });
        const error = await this.userRepository
          .uploadAvatar(file as File)
          .then(async () => {
            const userModel = await this.userStore.getCurrentUser(true);
            const previewUrl = getCloudflareSizeRecognition(
              userModel?.serverData.imageUrls as string[]
            );
            const avatarStep = this.steps[StepNamesEnum.AVATAR];

            runInAction(() => {
              this.steps = {
                ...this.steps,
                [StepNamesEnum.AVATAR]: {
                  ...avatarStep,
                  data: {
                    ...this.steps[StepNamesEnum.AVATAR].data,
                    preview: previewUrl as string,
                    croppedPreview: previewUrl as string,
                  },
                  loading: false,
                },
              };
            });
            this.saveDataInStorage();
          });
        return Boolean(error);
      },
      getPrevStepName: () => StepNamesEnum.DISPLAY_NAME,
      getNextStepName: () => StepNamesEnum.SUBSCRIPTION,
    },
    [StepNamesEnum.SUBSCRIPTION]: {
      data: {
        activeSubscriptionType: SubscriptionTypeEnum.YEAR,
      },
      loading: false,
      // this step does not exists in the BE order scheme so it doesn't contain backendStepNumber
      error: null,
      getIsvalid() {
        return !this.error && !this.loading;
      },
      successMessage: '',
      backendStepNumber: null,
      next: async () => {
        return await this.createProUserSubscription();
      },
      getPrevStepName: () => StepNamesEnum.AVATAR,
      getNextStepName: () => null,
    },
  };

  activeStepName: StepNamesEnum = StepNamesEnum.USERNAME;

  show = false;

  get activeStep() {
    return this.steps[this.activeStepName];
  }

  // TODO: maybe create some base store/service with these actions?
  // currently it's Stripe
  static openPaymentUrl(paymentUrl: string) {
    global.open(paymentUrl, '_self');
  }

  constructor(
    @inject(IOC_TOKENS.onboardingRepository)
    onboardingRepository: IOnboardingRepositoryInterface,
    @inject(IOC_TOKENS.userRepository)
    userRepository: IUserRepository,
    @inject(IOC_TOKENS.userStore) userStore: IUserStore,
    @inject(IOC_TOKENS.storageService) storageService: IStorageService,
    @inject(IOC_TOKENS.redirectService) redirectService: IRedirectService,
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore
  ) {
    super(toastStore);
    makeObservable(this, {
      userPostOnboardingData: observable,
      steps: observable,
      stepsDataJSON: computed,
      show: observable,
      proMode: observable,
      completed: observable,
      activeStepName: observable,
      activeStep: computed,
      setupData: action,
      updateUsername: action,
      switchToStandardUsername: action,
      setActiveStepName: action,
      setStepData: action,
      setShow: action,
      tryToRestore: action,
      setCompleted: action,
      setProMode: action,
      getUserOnboardingData: action,
      completeUserOnboardingStep: action,
      completeUserOnboardingAllSteps: action,
      completeSubscription: action,
      next: action,
      skip: action,
      back: action,
    });
    this.userRepository = userRepository;
    this.onboardingRepository = onboardingRepository;

    this.userStore = userStore;
    this.storageService = storageService;
    this.redirectService = redirectService;

    when(() => {
      const meData = this.userStore.me;

      const savedPostOnboardingModelStr = this.storageService.getStorageItem(
        StorageDataKeysEnum.POST_AUTH_ONBOARDING
      );

      // if there exists already saved onboarding data no reason to handle user data.
      if (savedPostOnboardingModelStr) {
        this.tryToRestore(savedPostOnboardingModelStr);
        return true;
      }

      if (meData) {
        this.setupData(meData);
        return true;
      }

      return false;
    });
  }

  setupData = (meData: UserModel | null) => {
    if (!meData?.serverData) return;

    const { username, displayName } = meData.serverData;

    const stepUsername = this.steps[StepNamesEnum.USERNAME];
    const stepDisplayname = this.steps[StepNamesEnum.DISPLAY_NAME];
    this.steps = {
      ...this.steps,
      [StepNamesEnum.USERNAME]: {
        ...stepUsername,
        data: {
          ...stepUsername.data,
          value: username as string,
          lastStandardValue: username as string,
        },
      },
      [StepNamesEnum.DISPLAY_NAME]: {
        ...stepDisplayname,
        data: {
          ...stepDisplayname.data,
          value: displayName as string,
        },
      },
    };
  };

  setActiveStepName = (stepName: StepNamesEnum) => {
    this.activeStepName = stepName;
  };

  setStepData = (
    stepName: StepNamesEnum,
    data: SetStepDataPyaload,
    error?: string | null
  ) => {
    this.steps = {
      ...this.steps,
      [stepName]: {
        ...this.steps[stepName],
        data,
        error: error !== undefined ? error : this.steps[stepName].error,
      },
    };

    // the avatar should be saved only after the image upload and receiving cloudflare url
    if (stepName !== StepNamesEnum.AVATAR) {
      this.saveDataInStorage();
    }
  };

  saveDataInStorage = () => {
    const userName = this.steps[StepNamesEnum.USERNAME].data.value;
    const displayName = this.steps[StepNamesEnum.DISPLAY_NAME].data.value;
    // they shouldn't be empty
    if (!userName || !displayName) return;
    const data = this.stepsDataJSON;
    this.storageService.setStorageItem(
      StorageDataKeysEnum.POST_AUTH_ONBOARDING,
      JSON.stringify(data)
    );
  };

  tryToRestore = (savedModelStr: string | null) => {
    if (savedModelStr) {
      const restoredData = JSON.parse(savedModelStr);
      const updatedSteps = Object.keys(restoredData).reduce((acc, key) => {
        // set pro mode if the username value has the pro value
        if (
          key === StepNamesEnum.USERNAME &&
          restoredData[key].value.length < 7
        ) {
          this.proMode = true;
        }

        acc[key] = {
          ...this.steps[key as StepNamesEnum],
          data: restoredData[key],
        };

        return acc;
      }, {} as any);

      this.steps = updatedSteps;
    }
  };

  setProMode = (proMode: boolean) => {
    if (this.proMode !== proMode) {
      this.proMode = proMode;
    }
  };

  setShow = (val: boolean) => {
    this.show = val;
  };
  setCompleted = (val: boolean) => {
    this.completed = val;
  };

  switchToStandardUsername = () => {
    const lastStandardUsername =
      this.steps[StepNamesEnum.USERNAME].data.lastStandardValue;
    this.setStepData(StepNamesEnum.USERNAME, {
      value: lastStandardUsername,
      lastStandardValue: lastStandardUsername,
    });
    this.setActiveStepName(StepNamesEnum.USERNAME);
    this.setProMode(false);
  };
  back = () => {
    const prevStepName = this.activeStep.getPrevStepName();

    if (!prevStepName) {
      return;
    }

    this.setActiveStepName(prevStepName);
  };

  next = async (skip = false) => {
    if (!skip) {
      const error = await this.activeStep.next();
      if (error) return;
    }

    const beStepNumber = this.activeStep.backendStepNumber;
    if (this.activeStepName === StepNamesEnum.AVATAR) {
      if (skip) {
        this.setStepData(StepNamesEnum.AVATAR, initialAvatarStepData);
      }

      if (!this.proMode) {
        // --- should complete it if non pro just by hit Next btn
        const completeError = await this.completeUserOnboardingStep(2, skip);
        const completeAllError = await this.completeUserOnboardingAllSteps();
        if (completeError || completeAllError) {
          return;
        }
        // !--- should complete it if non pro just by hit Next btn

        this.setCompleted(true);
        return;
      }
    } else {
      // complete on BE only the expected steps
      if (beStepNumber === 0 || typeof beStepNumber === 'number') {
        const completeError = await this.completeUserOnboardingStep(
          beStepNumber,
          skip
        );

        if (completeError) {
          return;
        }
      }
    }

    const nextStepName = this.activeStep.getNextStepName();
    if (!nextStepName) {
      return;
    }

    this.setActiveStepName(nextStepName);
  };

  skip = () => {
    this.next(true);
  };

  updateUsername = async () => {
    // should execute pro username update only after the success subscription. Always return false
    if (this.proMode) return false;

    const action = this.userRepository.updateUsername;
    const { error } = await action(
      this.steps[StepNamesEnum.USERNAME].data.value
    );

    if (error) {
      this.steps = {
        ...this.steps,
        [StepNamesEnum.USERNAME]: {
          ...this.steps[StepNamesEnum.USERNAME],
          error: error.message,
        },
      };
    }
    return Boolean(error);
  };
  updateDisplayName = async () => {
    const displayName = this.steps[StepNamesEnum.DISPLAY_NAME].data.value;
    this.userStore.setDataField('displayName', displayName);
    const res = await this.userStore.updateCurrentUser();
    if (res && res.error) {
      const { error } = res;

      this.steps = {
        ...this.steps,
        [StepNamesEnum.DISPLAY_NAME]: {
          ...this.steps[StepNamesEnum.DISPLAY_NAME],
          error: error.message,
        },
      };
    }

    return Boolean(res?.error);
  };

  createProUserSubscription = async () => {
    const activePriceSlug =
      this.steps[StepNamesEnum.SUBSCRIPTION].data.activeSubscriptionType;
    const { data, error } = await this.userRepository.createProUserSubscription(
      activePriceSlug
    );

    if (data) {
      this.saveDataInStorage();
      this.storageService.setStorageItem(
        StorageDataKeysEnum.PRO_SUBSCRIPTION_PAYMENT,
        JSON.stringify({
          id: RedirectionIdsEnum.POST_AUTH_ONBOARDING,
          url: document.location.href,
          // no need to set data because it's already saved in the storage
        })
      );

      PostAuthOnboardingStore.openPaymentUrl(data);
    } else if (error) {
      this.handleError('error', error.message);
    }

    return Boolean(error);
  };

  getUserOnboardingData = async () => {
    if (this.isGotUserOnboardingDataAlready) return;

    this.isGotUserOnboardingDataAlready = true;
    const { data } = await this.onboardingRepository.getUserOnboardingData();

    this.userPostOnboardingData = data;

    // if it does not exists or isn't true it means that it's the first login time or the user hand't finished the onboarding flow before
    // if it's in-progress - the user hasn't finished the previous onboarding
    if (!data?.status || data?.status === 'in-progress') {
      this.setShow(true);
    }
  };

  completeUserOnboardingStep = async (stepNumber: number, skipped = false) => {
    const payload = {
      step: stepNumber,
      skipped,
    };
    const { error } =
      await this.onboardingRepository.completeUserOnboardingStep(payload);

    if (error) {
      // use toast store
      this.steps = {
        ...this.steps,
        [this.activeStepName]: {
          ...this.activeStep,
          error: error.message,
        },
      };
    }
    return error;
  };
  completeUserOnboardingAllSteps = async () => {
    const { error } =
      await this.onboardingRepository.completeUserOnboardingAllSteps();

    return error;
  };

  checkUsernameAvailability = async () => {
    const username = this.steps[StepNamesEnum.USERNAME].data.value;
    if (!username) return;
    const { error, data } = await this.userRepository.checkUsernameAvailability(
      username
    );

    if (error || !data) {
      this.steps = {
        ...this.steps,
        [StepNamesEnum.USERNAME]: {
          ...this.steps[StepNamesEnum.USERNAME],
          error: !data ? 'Username is taken' : error.message,
        },
      };
    }
  };

  completeSubscription = async (failed = false) => {
    if (!failed) {
      if (this.proMode) {
        const { error } = await this.userRepository.saveProUsername(
          this.steps[StepNamesEnum.USERNAME].data.value
        );

        if (error) {
          this.steps = {
            ...this.steps,
            [StepNamesEnum.USERNAME]: {
              ...this.steps[StepNamesEnum.USERNAME],
              error: error.message,
            },
          };

          this.setActiveStepName(StepNamesEnum.USERNAME);
        }
      }

      this.completeUserOnboardingAllSteps();
      this.setCompleted(true);
    } else {
      this.handleError(
        'errors.subscriptionPaymentErrorTitle',
        'errors.subscriptionPaymentError'
      );
    }

    global?.history?.replaceState({}, '10x Home Page', refineURL());
    this.show = true;
  };

  getAccountProUsernameSubscriptionTypes = async () => {
    const data = await this.userStore.getAccountProUsernameSubscriptionTypes();

    if (data) {
      const yearlyData = this.userStore.getSubscriptionPriceByType(
        SubscriptionTypeEnum.YEAR
      );
      this.setStepData(StepNamesEnum.SUBSCRIPTION, {
        activeSubscriptionType: yearlyData?.slug as SubscriptionTypeEnum,
      });
    }
  };

  validateProUsername = () => {
    const username = this.steps[StepNamesEnum.USERNAME].data.value;
    if (!username) return;
    this.userRepository.checkUsernameAvailability(username);
  };

  createProUsername = (username: string) => {
    this.userRepository.createProUsername(username);
  };

  updateProUsername = (username: string) => {
    this.userRepository.updateProUsername(username);
  };
}
