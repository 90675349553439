import { Popup } from '@mainApp/src/components/forked-thirdparty/reactjs-popup/src';

import { ColorPicker } from '@10x/foundation/src/components/color-picker/ColorPicker';
import { ColorType } from './types';

type CommunityColorPickerPropsType = {
  color: ColorType;
  onColorPick: (color: ColorType) => void;
  isMobileMode: boolean;
};

export function CommunityColorPicker(props: CommunityColorPickerPropsType) {
  const { color, onColorPick, isMobileMode } = props;
  return (
    <div className="relative z-10 flex items-center">
      <Popup
        onOpen={() => {
          window?.document.documentElement.classList.add('overflow-hidden');
        }}
        onClose={() => {
          // to prevent unnecessary scroll on mobile while selecting color
          window?.document.documentElement.classList.remove('overflow-hidden');
        }}
        keepTooltipInside
        centerOnViewportOverflow
        trigger={
          <button
            className="h-[32px] w-[32px] rounded-[3px]"
            style={{
              backgroundColor: color.rgba,
            }}
          />
        }
        position={isMobileMode ? 'top right' : 'right bottom'}
        offsetX={isMobileMode ? 21 : 17}
        arrow={false}
        contentStyle={{
          width: 276,
          boxShadow: 'none',
          border: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <ColorPicker
          color={color.rgba}
          onChange={(color: any) => {
            const rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
            onColorPick({
              rgba,
              hex: color.hex,
              pureRgba: color.rgb,
            });
          }}
        />
      </Popup>
    </div>
  );
}
