import {
  BadgeDot,
  OutlineButtonWithLoader,
} from '@foundationPathAlias/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SubscriptionInfo } from './common/SubscriptionInfo';

import {
  ISubscriptionStore,
  useGetSubscriptionCost,
  useGetSubscriptionExpirationDate,
} from './data';
type Props = {
  onSuccess: () => void;
};

export function _ExpiredContent(props: Props) {
  const { onSuccess } = props;
  const { t } = useTranslation('common');
  const cost = useGetSubscriptionCost();
  const expirationDate = useGetSubscriptionExpirationDate();
  const accountSettingsStore = useInjection(IOC_TOKENS.accountSettingsStore);
  const subscriptionStore =
    accountSettingsStore.activeSettingStore as ISubscriptionStore;

  const handleProceed = async () => {
    const { error } = await subscriptionStore.reactivateProUserSubscription();
    if (!error) {
      onSuccess();
    }
  };
  return (
    <>
      <SubscriptionInfo
        cost={cost}
        description={
          <span>
            {t('accountSettings.subscriptionExpiredOn')}{' '}
            <span className="text-sm13DM">{expirationDate}</span>.
          </span>
        }
        badge={<BadgeDot type="negative" label={t<string>('expired')} />}
      />
      <OutlineButtonWithLoader
        loading={subscriptionStore.isLoading}
        cn="mt-[16px] text-sm14SB w-auto h-auto py-[8px] themed-text"
        onClick={handleProceed}
      >
        {t('reactivate')}
      </OutlineButtonWithLoader>
    </>
  );
}

export const ExpiredContent = observer(_ExpiredContent);
