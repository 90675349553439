'use client';
import { Toggle } from '@foundationPathAlias/components';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { FormFieldInput } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { FooterModal } from './FooterModal';

export const _CreateChannelForm = () => {
  const { t } = useTranslation(['common', 'channel']);

  const [checking, setChecking] = useState(false);

  const {
    dimensionsStore: { isMobile },
    createChannelStore: {
      isChannelsNameAvailable,
      isChannelGroupNameAvailable,
      isGroupMode,
      channelName,
      channelGroupName,
      isPrivate,
      isSubmitting,
      requestCloseModal,
      setChannelName,
      setChannelGroupName,
      setIsPrivate,
      submitCreateChannel,
      submitCreateChannelGroup,
      setStepScreen,
    },
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.createChannelStore,
  ]);

  let submitAction = submitCreateChannel;
  let checkNameAction = isChannelsNameAvailable;
  let setNameAction = setChannelName;
  let nameField = channelName;
  let errorMsg = t('channel:modal.channelAlreadyExists');
  let visibilityLabel = t('channel:modal.channelVisibility');
  let privateExplanation = t('channel:modal.onlySelectedRolesAndMembers');
  let nextText = t<string>('channel:modal.createChannel');

  let label = t('channel:modal.channelName');
  let placeholder = t('channel:modal.writeNameForYourChannel');
  if (isGroupMode) {
    submitAction = submitCreateChannelGroup;
    checkNameAction = isChannelGroupNameAvailable;
    setNameAction = setChannelGroupName;
    nameField = channelGroupName;
    label = t('channel:modal.channeGroupName');
    errorMsg = t('channel:modal.groupAlreadyExists');
    placeholder = t('channel:modal.writeNameForYourChannelGroup');
    visibilityLabel = t('channel:modal.channelGroupVisibility');
    privateExplanation = t('channel:modal.onlySelectedRolesAndMembersGroup');
    nextText = t<string>('channel:modal.createChannelGroup');
  }

  useDebounce(
    async () => {
      const isAvailable = await checkNameAction();

      setNameAction({
        ...nameField,
        error: isAvailable ? null : errorMsg,
      });

      setChecking(false);
    },
    300,
    [nameField.value]
  );

  const handleSubmit = () => {
    if (isPrivate) {
      setStepScreen('addMembers');
    } else {
      submitAction();
    }
  };

  return (
    <>
      <div className="flex flex-[1] flex-col">
        <div className="px-4 py-6 text-start md:p-6">
          <FormFieldInput
            cnWrapper="mt-0"
            inputName="name"
            onChange={(val) => {
              setChecking(true);
              setNameAction({
                value: val,
                error: nameField.error,
              });
            }}
            errorTextCn="text-element-error dark:text-element-error-dark"
            onError={(error) => {
              setNameAction({
                value: nameField.value,
                error: error,
              });
            }}
            rightSideContent={checking ? <SmallLoader /> : undefined}
            outerError={nameField.error}
            value={nameField.value}
            label={label}
            placeholder={placeholder}
          />

          <hr className="my-6" />

          <label className="text-body16SB text-text-primary dark:text-text-primary-dark">
            {visibilityLabel}
          </label>

          <div className="my-4 flex items-center justify-between gap-12">
            <div>
              <p className="text-sm14SB text-text-primary dark:text-text-primary-dark">
                {t('channel:modal.makePrivate')}
              </p>

              <span className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
                {privateExplanation}
              </span>
            </div>

            <Toggle checked={isPrivate} onChange={setIsPrivate} />
          </div>
        </div>

        <FooterModal
          onCancel={requestCloseModal}
          onSuccess={handleSubmit}
          disabledOkButton={
            isSubmitting ||
            checking ||
            Boolean(nameField.error) ||
            !nameField.value
          }
          successText={isPrivate ? t<string>('common:next') : nextText}
          hideCancelButton={isMobile}
          className="mt-auto md:mt-0"
        />
      </div>
    </>
  );
};

export const CreateChannelForm = observer(_CreateChannelForm);
