import { createContext, ReactNode, useContext, useState } from 'react';

import { useAnimatedStack } from '@foundationPathAlias/components/animated-stack/useAnimatedStack';
import {
  AnimatedStack,
  AnimatedStackReducerState,
  StackItem,
} from '@foundationPathAlias/main';

import { PlatformContentRulePayload } from '@10x/foundation/types';
import { ScreenIds, ScreensItems } from './constants';

type RuleDetails = {
  id: string;
  title: string;
  rawJson: string;
};

type ScreenRulesContextType = {
  stackVisibleItem: StackItem;
  stackPrevItem: StackItem;
  stackNextItem: StackItem;
  currentItem: {
    id: string;
    Component: (props?: any) => JSX.Element;
  };
  currentItemId: string | null;
  stackState: AnimatedStackReducerState;
  animatedStack: AnimatedStack;
  handleGoToScreen: (screenId: string) => void;
  handleBack: () => void;
  setCurrentRuleDetails: (subRules: RuleDetails | null) => void;
  currentRuleDetails: RuleDetails | null;
  setCurrentPlatformRule: (
    platformRule: PlatformContentRulePayload | null
  ) => void;
  currentPlatformRule: PlatformContentRulePayload | null;
};

const ScreenRulesContext = createContext<ScreenRulesContextType>(
  {} as ScreenRulesContextType
);

export const ScreenRulesProvider = ({ children }: { children: ReactNode }) => {
  const animatedStack = useAnimatedStack();

  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const currentItemId = animatedStack.state.visibleItem.id;

  const currentItem = ScreensItems[currentItemIndex];

  const stackState = animatedStack.state;

  const stackVisibleItem = stackState.visibleItem;
  const stackPrevItem = stackState.prevItem;
  const stackNextItem = stackState.nextItem;

  const [currentPlatformRule, setCurrentPlatformRule] =
    useState<PlatformContentRulePayload | null>(null);

  const [currentRuleDetails, setCurrentRuleDetails] =
    useState<RuleDetails | null>(null);

  const handleGoToScreen = (screenId: string) => {
    const screenItemIdx = ScreensItems.findIndex(
      (screen) => screen.id === screenId
    );

    if (screenItemIdx === -1) return;

    const screenFound = ScreensItems[screenItemIdx];

    animatedStack.next(screenFound.id, screenFound.Component);
    setCurrentItemIndex(screenItemIdx);
  };

  const handleBack = () => {
    if (currentItemId === ScreenIds.INITIAL) return;

    animatedStack.back();
  };

  return (
    <ScreenRulesContext.Provider
      value={{
        stackVisibleItem,
        stackPrevItem,
        stackNextItem,
        currentItem,
        stackState,
        animatedStack,
        handleGoToScreen,
        handleBack,

        currentItemId,

        currentRuleDetails,
        setCurrentRuleDetails,

        currentPlatformRule,
        setCurrentPlatformRule,
      }}
    >
      {children}
    </ScreenRulesContext.Provider>
  );
};

export const useScreenRules = () => {
  const context = useContext(ScreenRulesContext);
  if (context === undefined) {
    throw new Error('useScreenRules must be used within a ScreenRulesProvider');
  }
  return context;
};
