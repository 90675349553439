import { classNames } from '@10x/foundation/src/utilities';

type ComminutyLogoPreviewProps = {
  logoSrc?: string;
  active?: boolean;
  notificationsCount?: string;
  notificationDot?: boolean;
};
export function CommunityLogoPreview(props: ComminutyLogoPreviewProps) {
  const { logoSrc, active, notificationsCount, notificationDot } = props;
  return (
    <div
      className={classNames(
        'h-[48px] w-[48px] rounded-[5px] border-text-primary p-[2px]',
        active && 'border-[3px]'
      )}
    >
      <div className="relative h-full w-full rounded-[5px] bg-element-normal dark:bg-element-normal-dark">
        {notificationsCount && (
          <span className="absolute -top-[10px] -right-[12px]  rounded-[20px] border-[3px] border-background-secondary bg-background-primary-dark px-[7px] dark:border-background-secondary-dark dark:bg-background-primary">
            <span className="flex text-sm14T leading-normal text-text-primary-dark dark:text-text-primary">
              {notificationsCount}
            </span>
          </span>
        )}
        {notificationDot && (
          <span className=" absolute -top-[8px] -right-[8px] h-[18px] w-[18px] rounded-full border-[3px] border-background-secondary bg-background-primary-dark dark:border-background-secondary-dark dark:bg-background-primary" />
        )}
        {logoSrc && (
          <img
            className="h-full rounded-[5px] object-cover"
            src={logoSrc}
            alt="Logo image"
          />
        )}
      </div>
    </div>
  );
}
