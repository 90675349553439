import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  children: React.ReactNode;
  cn?: string;
};

export function RoundedDropdownLayout(props: Props) {
  const { cn, children, ...otherProps } = props;

  return (
    <div
      className={classNames(
        'themed-layout h-full w-full rounded-[5px] border-[1px] border-element-subtle shadow-shadow-menu-dekstop  dark:border-element-subtle-dark',
        cn
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}
