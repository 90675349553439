import { action, computed, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

import { languageSettingsComponents } from './languageComponents';

import { IAnimatedStackStore } from '@mainApp/src/components/common';
import { LanguageSettingsEnum } from '@mainApp/src/modules/account-settings/data/types';

// TODO: temporary. IF success should be moved to the AccountSetting store or even upper

export class LanguageStore implements ILanguageStore {
  //   repository;

  stackStore;

  activeSettingId: LanguageSettingsEnum = LanguageSettingsEnum.INITIAL;

  get initialSetting() {
    return LanguageSettingsEnum.INITIAL;
  }

  get activeSetting() {
    return languageSettingsComponents[this.activeSettingId];
  }

  get isInitialScreen() {
    return this.activeSettingId === LanguageSettingsEnum.INITIAL;
  }

  constructor(stackStore: IAnimatedStackStore) {
    makeObservable(this, {
      activeSetting: computed,
      isInitialScreen: computed,

      activeSettingId: observable,
      setActiveSettingId: action,
      setNextSettingId: action,
      back: action,
      reset: action,
    });

    this.stackStore = stackStore;
  }

  setActiveSettingId = (id: LanguageSettingsEnum) => {
    if (this.activeSettingId === id) return;
    this.activeSettingId = id;
  };

  setNextSettingId = (setting: LanguageSettingsEnum) => {
    const stackStore = this.stackStore;
    // shouldn't do anything if the animation is running
    if (stackStore.isAnimationRunning) {
      return;
    }
    this.activeSettingId = setting;

    const nextItem = languageSettingsComponents[setting];
    stackStore.next(nextItem.id, nextItem.Component);
  };
  back = () => {
    const stackStore = this.stackStore;
    // shouldn't do anything if the animation is running
    if (stackStore.isAnimationRunning) {
      return;
    }

    const prevSettingId = stackStore.back();

    if (!prevSettingId) return;

    this.activeSettingId = prevSettingId as LanguageSettingsEnum;
  };

  reset = () => {
    this.setActiveSettingId(this.initialSetting);
    this.stackStore.reset();
    this.stackStore.setVisible(
      this.activeSetting.id,
      this.activeSetting.Component
    );
  };
}

type SettingComponentsConfig = typeof languageSettingsComponents;

export interface ILanguageStore {
  stackStore: IAnimatedStackStore;
  activeSettingId: LanguageSettingsEnum;
  readonly initialSetting: LanguageSettingsEnum;
  readonly isInitialScreen: boolean;
  readonly activeSetting: SettingComponentsConfig[keyof SettingComponentsConfig];
  setActiveSettingId: (setting: LanguageSettingsEnum) => void;
  setNextSettingId: (setting: LanguageSettingsEnum) => void;
  back: () => void;
  reset: () => void;
}
