import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { TextButtonPrimary } from '@foundationPathAlias/main';
import { Separator } from '@foundationPathAlias/widgets/sidebar-modal';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  InputField,
  ItemHeader,
  ScreenWrapper,
} from '@mainApp/src/modules/channel/channel-settings/components';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// import { useDebounce } from 'react-use';

import { useMinLoading } from '@foundationPathAlias/components/loaders';
import { IOverviewStore } from './data/Overview.store.local';

import { DeleteModaChannelContent } from '@mainApp/src/components/common/DeleteModaChannelContent';

import { ChannelPayload } from '@10x/foundation/types';
import {
  useGetActiveCommunityDefaultChannelRedirect,
  useIsInExactChannel,
} from '@mainApp/src/hooks';
import { useEffect } from 'react';

export function _Overview() {
  const { channelSettingsStore, interactiveStore } = useMultipleInjection([
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const isInExactChannel = useIsInExactChannel(
    channelSettingsStore.channelModel?.serverData.id
  );
  const redirectToDefaultChannel =
    useGetActiveCommunityDefaultChannelRedirect();

  const { t } = useTranslation(['common', 'channel']);

  const activeStore = channelSettingsStore.activeChildScreen as IOverviewStore;

  useEffect(() => {
    activeStore?.setChannelModel(channelSettingsStore.channelModel);
  }, [channelSettingsStore.channelModel]);

  const channelNameProxy = activeStore?.channelName;

  const availabilityCheckLoading = useMinLoading(
    Boolean(channelNameProxy?.loading),
    500
  );

  // useDebounce(
  //   async () => {
  //     const isAvailable = await activeStore.isChannelNameAvailable();
  //     console.log('is Available ->> ', activeStore);
  //     debugger;
  //     // setNameAction({
  //     //   ...nameField,
  //     //   error: isAvailable ? null : errorMsg,
  //     // });

  //     // setChecking(false);
  //   },
  //   300,
  //   [activeStore.channelName.data]
  // );

  const delChannelTitle = t('channel:deleteChannel');

  return (
    <ScreenWrapper title="channel:channelSettings.label">
      <div className="h-full w-full flex-1">
        <ItemHeader cn="mb-[12px]">{t('channel:channelName')}</ItemHeader>
        <InputField
          successMsg=""
          inputName={t<string>('channel:channelName')}
          placeholder={t('channel:channelSettings.enterChannelName')}
          value={channelNameProxy?.data || ''}
          onChange={(val) => {
            activeStore.setChannelName(val);
          }}
          outerError={
            channelNameProxy?.error ? t<string>(channelNameProxy?.error) : null
          }
          showLoader={availabilityCheckLoading}
          onError={(error) => {
            const existingError = channelNameProxy?.error;
            if (existingError !== error) {
              activeStore?.setChannelName(channelNameProxy.data, error);
            }
          }}
        />
        <Separator cn="mb-[24px]" />
        <div className="flex flex-col items-start">
          <ItemHeader>{delChannelTitle}</ItemHeader>
          <p className="themed-text-secondary text-left text-body16R">
            {t('channel:channelSettings.deleteChannelDescription')}
          </p>

          <TextButtonPrimary
            className="themed-text-error hover-el-opacity animation-classic mt-[16px] text-body16SB"
            onClick={() => {
              channelSettingsStore.setIsShow(false);

              interactiveStore.setActiveContentType(
                InteractiveContentTypesEnum.OTHER,
                true
              );
              interactiveStore.setContent(
                <DeleteModaChannelContent
                  channelServerData={
                    channelSettingsStore.channelModel
                      ?.serverData as ChannelPayload
                  }
                  onCancel={() => {
                    channelSettingsStore.setIsShow(true);
                  }}
                  onProceed={(isError) => {
                    if (!isError && isInExactChannel) {
                      // should redirect to the default only if the user in the channel that he is deleting
                      redirectToDefaultChannel();
                    }
                  }}
                />
              );

              interactiveStore.setInteractiveElementOpen(true);
            }}
          >
            {t('delete')}
          </TextButtonPrimary>
        </div>
      </div>
    </ScreenWrapper>
  );
}

export const Overview = withAnimatedStack(observer(_Overview));
