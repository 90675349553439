import { classNames } from '@foundationPathAlias/utilities';
import 'cropperjs/dist/cropper.css';
import { useRef, useState } from 'react';
import RCropper, { ReactCropperProps } from 'react-cropper';

import { RangeSlider, RangeSliderPropsType } from '../range-slider';

export enum CropperOrientationEnum {
  Portrait = 'Portrait',
  Landscape = 'Landscape',
  Square = 'Square',
  Circle = 'Circle',
}

const orientationRatioRegistry = {
  [CropperOrientationEnum.Portrait]: 2 / 3,
  [CropperOrientationEnum.Landscape]: 3 / 2,
  [CropperOrientationEnum.Square]: 1 / 1,
  [CropperOrientationEnum.Circle]: 1 / 1,
};

export type CropperPropsType = {
  /** src image to crop */
  src: string;
  height?: number | string;
  width?: number | string;
  rangeSliderProps?: Partial<RangeSliderPropsType>;
  reactCropperNativeProps?: Partial<ReactCropperProps>;
  /** an orientation of the cropbox in the cropper area  */
  cropBoxOrientation?: CropperOrientationEnum;
  minCropBoxWidth?: number;
  minCropBoxHeight?: number;
  /** get the cropper instance */
  getCropperRef?: (data: {
    cropperInstance: any;
    resetAction: () => void;
  }) => void;
  /**
   * the cropped canvas will be passed and you can use all the
   * canvas methods to get the final data, eg. toBlob() | toDataURL()
   */
  onCrop: (croppedCanvas: any, currentZoom: number, e: any) => void;
  rangeMin?: number;
  rangeMax?: number;
  /** use it if you want to set some zoom by default */
  initialZoomVal?: number;
  rangeStep?: number;
  rootCn?: string;
  /** you can add some additional UI for the range slider */
  // eslint-disable-next-line no-undef
  renderRangeSlider?: (rangeSlider: JSX.Element) => JSX.Element;
};

export function Cropper(props: CropperPropsType) {
  const {
    rootCn,
    rangeMin = 0,
    rangeMax = 1,
    rangeStep = 0.05,
    initialZoomVal = 0,
    src,
    width = 338,
    height = 334,
    minCropBoxWidth = 208,
    minCropBoxHeight = 310,
    cropBoxOrientation = CropperOrientationEnum.Portrait,
    reactCropperNativeProps = {},
    renderRangeSlider,
    getCropperRef,
    onCrop,
  } = props;
  const cropperRef = useRef<HTMLImageElement | null>(null);
  const initialMarkerRef = useRef<boolean>(false);

  const aspectRatio = orientationRatioRegistry[cropBoxOrientation];

  const [rangeValues, setRangeValues] = useState({ values: [rangeMin] });
  const rangeValuesRef = useRef<number>(rangeMin);

  const resetAction = () => {
    setRangeValues({ values: [rangeMin] });
    setCropperZoom(rangeMin);
  };

  function setCropperZoom(val: number) {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.zoom(val);
  }

  let rangeSlider = (
    <RangeSlider
      range={{
        min: rangeMin,
        max: rangeMax,
      }}
      step={rangeStep}
      values={rangeValues.values}
      onChange={(values) => {
        if (values[0] == 0) return;

        const oldVal = rangeValues.values[0];
        const newVal = values[0];
        const delta = Number((newVal - oldVal).toFixed(2));

        setRangeValues({ values });
        rangeValuesRef.current = values[0];
        setCropperZoom(delta);
      }}
    />
  );

  if (renderRangeSlider) {
    rangeSlider = renderRangeSlider(rangeSlider);
  }

  return (
    <div className={rootCn} style={{ width }}>
      <RCropper
        src={src}
        style={{ width, height }}
        autoCrop
        ready={() => {
          const imageElement: any = cropperRef?.current;
          const cropper: any = imageElement?.cropper;
          cropper?.zoom(initialZoomVal);
          setRangeValues({ values: [initialZoomVal] });

          initialMarkerRef.current = true;
        }}
        viewMode={1}
        checkOrientation={false}
        guides={false}
        className={classNames(
          cropBoxOrientation === CropperOrientationEnum.Circle && 'circle'
        )}
        aspectRatio={aspectRatio}
        zoomOnWheel={false}
        cropBoxResizable={false}
        minCropBoxWidth={minCropBoxWidth}
        minCropBoxHeight={
          cropBoxOrientation === CropperOrientationEnum.Circle
            ? minCropBoxWidth
            : minCropBoxHeight
        }
        crop={(e) => {
          const imageElement: any = cropperRef?.current;
          const cropper: any = imageElement?.cropper;
          const croppedCanvas = cropper.getCroppedCanvas();
          onCrop(croppedCanvas, rangeValuesRef.current, e);
        }}
        ref={(ref) => {
          cropperRef.current = ref;
          if (getCropperRef) {
            getCropperRef({
              cropperInstance: ref,
              resetAction,
            });
          }
        }}
        {...reactCropperNativeProps}
      />
      {rangeSlider}
    </div>
  );
}
